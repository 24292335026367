import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUser,
  User,
  AuthService,
  AppSettingService,
  IProfileMenu,
  IHeaderMenu,
  EmbedService
} from '@galaxy/entity-api';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() appName: string = '';
  @Input() theme: string = '';
  @Input() aRouterLink: string = '';
  @Input() isFull: boolean = false;
  @Input() showGenres: boolean = false;
  @Input() showSearch: boolean = false;
  transparent: boolean = false;
  searchQuery: string = '';
  showSearchBar: boolean = false;

  profileMenu?: IProfileMenu;
  header?: IHeaderMenu;

  userInfo: IUser = new User();
  isCurator: boolean = false;

  isLoggedIn: boolean = false;
  xsAvatar: SafeResourceUrl = '';
  smAvatar: SafeResourceUrl = '';
  searchOptions: { name: string; route: string; }[] = [];
  defaultSearchOption?: any;

  showSearchOptions: boolean = false;
  newOptions?: any;
  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _authService: AuthService,
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    // AppSettings
    this.header = this._appSetting.header;
    this.profileMenu = this._appSetting.profileMenu;
    this.transparent = this._appSetting.headerTransparent;

    this.newOptions = this._appSetting.newOptions;

    // Is this.isLoggedIn
    this.isLoggedIn =
      this._authService.loggedIn;
    // this.isLoggedIn = true;


    if (!this.isLoggedIn) {
      this._authService.initLocalToken().subscribe(res => {
        this.isLoggedIn = res;
        this.setAuthUserInfo();
      });
    }

    // correct this
    if (this.isLoggedIn) {
      this.setAuthUserInfo();

    }


    this.defaultSearchOption = this._appSetting.searchOptions[0];
  }


  setAuthUserInfo() {
    this.userInfo.name = this._authService.currentUser.name;
    this.userInfo.username = this._authService.currentUser.username;

    // if (this._authService.currentUser.curator.length > 0) {
    //   this.isCurator = true;
    //   this.userInfo.curator = this._authService.currentUser.curator;
    // }

    // embed needed images once!
    this.xsAvatar = this.embedImage(this._authService.currentUser.image, 'xs');
    // this.smAvatar = this.embedImage(this._authService.currentUser.image, 'sm');

    // subscribe for changes in userinfo
    this._authService.currentUserLive.subscribe(res => {
      this.userInfo.name = res.name;
      this.userInfo.username = res.username;

      this.xsAvatar = this.embedImage(res.image, 'xs');
      // this.smAvatar = this.embedImage(res.image, 'sm');
    });
  }

  log() {
    // console.log('clicked, but somtgnsvncv');
  }

  onToggleSearchOptions(value: boolean) {
    this.showSearchOptions = value;
  }

  onUploadClicked() {
    switch (this.appName) {
      case 'artist':
        this._router.navigateByUrl('/myartwork');
        break;
      case 'play':
        this._router.navigateByUrl('/creator');
        break;
      case 'lyfeshot':
        this._router.navigateByUrl('/my-pix');
        break;

      default:
        this._router.navigateByUrl('/new');
        break;
    }
  }
  onToggleGenre() {
    this.showGenres = !this.showGenres;
  }


  // search
  onSearch() {
    // check search not emtpy
    if (this.searchQuery.trim() !== '') {
      // get this.defaultSearchOption

      // generate routes

      this._router.navigateByUrl(this.defaultSearchOption.route + '?q=' + this.searchQuery);
      // this._router.
    }
  }

  onSearchOptionChanged(option) {
    // set to default search route
    this.defaultSearchOption = option;
    // now route to that place
    this._router.navigateByUrl(this.defaultSearchOption.route + '?q=' + this.searchQuery);
  }


  onLogOut() {
    this._authService.SignOut();
    this._router.navigateByUrl('/login');
  }
  onSwitchAccount() {
    this._authService.switchAccount();
    this._router.navigateByUrl('/login');
  }

  onShowMiniSocial(navTo: string) { }

  onShowSearchBar() {
    this.showSearchBar = !this.showSearchBar;
  }

  onWindowResize() { }
  onToggleTheme() {
    if (this.theme === 'dark') {
      this._appSetting.setTheme('light');
    } else {
      this._appSetting.setTheme('dark');
    }
  }

  private embedImage(path: string, size = 'default'): SafeResourceUrl {
    return this._embed.imgUrl(path, size);
  }
}
