<div class="img-grid-2">
  <div class="adx-img">
    <div class="nav left">
      <button class="adx-btn adx-flat clear">
        <i class="fal fa-angle-left fa-5x"></i>
      </button>
    </div>

    <div class="art">
      <div class="">
        <!-- <div class="adx-avatar">
          <div class="adx-img bg-pink"></div>
          <h2>Title of the Project</h2>
          <p>
            By Person Name
          </p>
        </div> -->
        <div class="topic-flex">
          <div>
            <galaxy-avatar [size]="'sm'"></galaxy-avatar>
          </div>
          <div class="topic">
            <h1 class="text-normal">
              <i class="fa fa-tasks color-pink"></i> &nbsp; Project Title Here
            </h1>
          </div>
        </div>
      </div>
      <div class="adx-card adx-flat bg-gradient">
        <div class="adx-card adx-full adx-img adx-block">
          <img
            src="http://127.0.0.1/airCloud/src/assets/images/users/julien-kaspar-bg.jpg"
            alt=""
            class="img-responsive"
          />
        </div>
        <p class="text-center">Topic Here</p>
      </div>
      <div class="adx-card adx-flat bg-gradient">
        <div class="adx-card adx-full adx-img adx-block">
          <img
            src="http://127.0.0.1/airCloud/src/assets/images/users/julien-kaspar.jpg"
            alt=""
            class="img-responsive"
          />
        </div>
        <p class="text-center">Topic Here</p>
      </div>
      <div class="adx-card adx-flat bg-gradient">
        <div class="adx-card adx-full adx-img adx-block">
          <img
            src="http://127.0.0.1/airCloud/src/assets/images/users/julien-kaspar-bg.jpg"
            alt=""
            class="img-responsive"
          />
        </div>
        <p class="text-center">Topic Here</p>
      </div>

      <!-- Project Detail -->
      <div class="adx-card bg-gradient adx-flat">
        <div class="detail bg-gradient text-center">
          <div class="padding">
            <p></p>
            <button class="adx-btn adx-icon adx-md adx-round btn-pink">
              <i class="fa fa-thumbs-up"> </i>
            </button>

            <h2>Project Title Here</h2>
            <div class="hits">
              <i class="fal fa-thumbs-up"></i> 176 &nbsp;&nbsp;
              <i class="fal fa-eye"></i> 599 &nbsp;&nbsp;
              <i class="fal fa-comment-alt"></i> 0
            </div>
            <small>
              Published: February 4th, 2019
            </small>
          </div>
          <p></p>
          <persona [isMini]="false"></persona>
        </div>
      </div>
    </div>

    <div class="nav right">
      <button class="adx-btn adx-flat clear">
        <i class="fal fa-angle-right fa-5x"></i>
      </button>
    </div>
  </div>
</div>
