<div *ngIf="isFull; then fullBg; else partialBg"></div>

<ng-template #partialBg>
  <div class="video-flex" (click)='onViewEntity()' >
    <galaxy-adaptive-card
      [showPadding]="false"
      [isBlur]="true"
      [shape]="'rounded'"
      class="cover-art"
    >
      <div
        class="adx-card bg-black adx-round adx-shadow outline art"
        [lazyLoad]="art"
      >
        <!-- <img [lazyLoad]="http://127.0.0.1/airCloud/app/images/" alt=""> -->
      </div>
    </galaxy-adaptive-card>
    <div class="adx-card adx-flat detail">
      <div class="adx-item adx-flat font-lato">
        <!-- media type -->
        <span *ngIf="entityType === 'media'" style="opacity: 0.7;">
          {{ entity.mediaTypeId === 46 ? 'Audio' : 'Video' }}
        </span>
        <!-- entity type or category -->
        <span *ngIf="entityType !== 'media'" style="opacity: 0.7;">
          {{ entity.type.name }}
        </span>
        <h2>
          {{ entity.title | maxLength: 17 }}
        </h2>
        <p>
          By &nbsp;
          {{ entity.person.name }}
          &#64;
          {{ entity.person.username }}
        </p>
      </div>
      <div class="screen-shots">
        <div class="adx-card bg-dark"></div>
        <div class="adx-card bg-dark"></div>
        <div class="adx-card bg-dark"></div>
        <div class="adx-card bg-dark"></div>
      </div>
      <br />
      <div class='count-div'>
        <button
          class="adx-btn adx-xs adx-round"
          [ngClass]="{
            'btn-dark': theme === 'dark'
          }"
        >
          <i class="axr ax-eye"></i>

          {{ entity.hit }}
        </button>
        <button
          class="adx-btn adx-xs adx-round"
          [ngClass]="{
            'btn-dark': theme === 'dark'
          }"
        >
          <i class="axr ax-heart"></i>

          {{ entity.likeCount }}
        </button>
        <button
          class="adx-btn adx-xs adx-round"
          [ngClass]="{
            'btn-dark': theme === 'dark'
          }"
        >
          <i class="axr ax-message"></i>

          {{ entity.commentCount }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullBg>
  <div class="cover">
    <div class="content bg-dark" [lazyLoad]="art">
      <header>
        <h2 class="text-normal">{{ entity.title }}</h2>
      </header>
      <footer>
        <div class="person-info">
          <div class="adx-img">
            <img [src]="avatar" alt="" />
          </div>
          <div class="media-attr personx">
            <div class="property">
              Author
            </div>
            <div class="value">
              {{ entity.person.name }}
            </div>
          </div>
          <div class="media-attr created-date">
            <div class="property">
              Published on
            </div>
            <div class="value">
              {{ entity.created | date }}
            </div>
          </div>
        </div>
        <div class="tag"></div>
      </footer>
    </div>
  </div>
</ng-template>
