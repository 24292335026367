<div class="cl-thumbnail {{ size }} {{ theme }}" (click)="onSaveClicked()" [lazyLoad]="thumbnail">
  <div *ngIf="size === 'sm'" class="thumbnails">
    <!-- image data -->
    <div class="{{ theme }}" *ngFor="let item of imageData" [lazyLoad]="item"></div>
    <!-- placeholder -->
    <div class="{{ theme }}" *ngFor="let item of placeHolderData"></div>
  </div>
</div>
<div class="cl-title {{ size }}" (click)="onSaveClicked()">
  {{ data.title }}
  <span style="font-weight:normal; opacity:.7;" *ngIf="size === 'sm'">
    <br />
    {{ data.description | maxLength: 40 }}
    <br />
    <span class="text-size15 color-tgreen">
      {{ data.entityCount }}
    </span>
  </span>
  <span class="float-right" *ngIf="data.status === 1">
    <i class="axr ax-eye-slash"></i>
    &nbsp;
  </span>
</div>
