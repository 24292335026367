import { Component, OnInit } from '@angular/core';
import {
  IUser,
  UserService,
  AuthService,
  User,
  IdentityApiService,
  AppSettingService,
  ErrorHandlerService
} from '@galaxy/entity-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isLoading: boolean = false;
  personDetail: boolean = true;
  user: IUser = new User;

  appId: number = 0;

  constructor(
    private _identityApi: IdentityApiService,
    private _authService: AuthService,
    private _router: Router,
    private _appSetting: AppSettingService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.appId = this._appSetting.getAppId();
    this.user = new User();
  }

  onUpdateInfo() {
    // const newUser = new User;{
    //   newUser.surname: this.user.surname,
    //   othername: this.user.othername,
    //   gender: this.user.gender,
    //   birthDate: this.user.birthDate,
    //   phoneOne: this.user.phoneOne
    // };

    this.isLoading = true;
    // console.log(
    //   "sending cordinates to ",
    //   this._authService.currentUser.profile.unique,
    //   newUser
    // );

    this._identityApi
      .setParams(null, this._authService.currentUser.unique.toString())
      .put(this.user)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;
            if (res.success) {
              this.personDetail = false;
              // update currentUserInfo
              this._authService.updateCurrentUserInfo(this.user);
              //
              // console.log('redirect to home page');
              // redirect person
              // app specifics?
              if (this.appId === 60) {
                this._router.navigateByUrl('/setup/interest');
              } else {
                this._router.navigateByUrl('/');
              }
            } else {
              // console.log('Somthing went wrong. Please try again.');
            }
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }
}
