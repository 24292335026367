import { Component, OnInit, Input, Output, EventEmitter, SecurityContext, OnDestroy } from '@angular/core';
import { Entity, AppSettingService, EmbedService, IEntity, OriDbService, Product, NotifyService } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-card',
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.scss']
})
export class MediaCardComponent implements OnInit, OnDestroy {

  @Input() entityType: 'photo' | 'video' | 'audio' | 'product' = 'photo';
  @Input() entity: IEntity = new Entity;
  @Input() showDetail: boolean = true;
  @Input() showAvatar: boolean = true;
  @Input() showPerson: boolean = true;
  @Input() size: string = '';
  @Input() isSquare: boolean = false;
  @Input() isPotrait: boolean = false;
  @Input() isComic: boolean = false;

  @Input() showArtworks: boolean = false; // this will switch it to show multiple images
  @Input() showBookmark: boolean = true; // make it false when showing entities from a collection

  @Output() addToCollection = new EventEmitter<IEntity>();

  @Output() shareEntity = new EventEmitter<IEntity>();
  @Output() viewEntity = new EventEmitter<IEntity>();
  art: string = '';
  avatar: SafeResourceUrl = '';


  entityDiscount: number = 0.45;
  theme: string = '';
  themeUpdated$!: Subscription;
  salesPrice: number = 0;
  showDiscount: boolean = false;


  personMediaProfileRoute: string = '/portfolio';


  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _oriDB: OriDbService,
    private _notify: NotifyService
  ) { }

  ngOnInit() {

    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    })



    this.art = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.entity.art, 'md'));
    // if (this.entity.person) {
    //   this.avatar = this._embed.imgUrl(this.entity.person.image, 'xs');
    // }
    // console.log('incoming ', this.entity);
    // console.log('this is the art', this.art);

    if (this.entityType == 'product') {
      this.personMediaProfileRoute = '/store';
      this.processPice();
    }
  }


  processPice() {
    if (this.entity.discount != 0) {
      this.salesPrice = this.entity.price - (this.entity.discount / 100 * this.entity.price);
      this.showDiscount = true;
    } else {
      this.salesPrice = this.entity.price;
      this.showDiscount = false;
    }
  }

  onShare($event) {
    $event.stopPropagation();

    this.shareEntity.emit(this.entity);
  }

  onAddToCart($event) {
    $event.stopPropagation();
    this.entity.myOrderCount = this.entity.minOrderCount;
    if (this._oriDB.addToCart(this.entity as Product)) {
      this._notify.success('Product added to cart')
    } else {
      this._notify.success('Product already exists in cart')
    }


  }


  onCollectionClicked($event) {
    $event.stopPropagation()
    this.addToCollection.emit(this.entity);

  }

  onViewEntity() {
    this.viewEntity.emit(this.entity);

    if (this.entityType === 'product') {
      //record to recently viewed products
      this._oriDB.addToRecentViewedProduct(this.entity as Product);
    }

    // let route = '';
    // switch (this.entityType) {
    //   case 'photo':
    //     route = '/artwork/';
    //     break;
    //   case 'product':
    //     route = '/store/product/';
    //     break;

    //   default:
    //     route = '/artwork/';
    //     break;
    // }

    // this._router.navigateByUrl(route + this.entity.alias + '/' + this.entity.id);
  }

  getImage(link: string) {
    return this._embed.imgUrl(link, 'md');
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
