<div class="adx-card adx-flat" style="overflow: visible;">
  <div class="adx-img"><img [src]="photo" alt="" /></div>
  <div class="file-btn">
    <galaxy-image-tool class="btn-positon" (imageCapture)="onGetImage($event)" [fileAlias]="'suite-person'"
      [icropAspectRatio]="1 / 1" [icropResizeToWidth]="300" [format]="'jpeg'" [saveMedia]="false"></galaxy-image-tool>
  </div>
  <form #entityForm="ngForm" [class.is-loading]="isLoading">
    <div class="form-wrapper">
      <!-- Start of wrapper -->
      <div class="adx-divider">Basic Info</div>
      <div class="adx-input adx-round adx-label">
        <label for="surnname">Surname</label>
        <input type="text" name="surname" (change)="autoGenerateUsername()" [(ngModel)]="data.entity.surname"
          placeholder="eg. John" required [disabled]="isLoading" />
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="othername">Othername</label>
        <input type="text" name="othername" (change)="autoGenerateUsername()" [(ngModel)]="data.entity.othername"
          placeholder="eg. Doe" required [disabled]="isLoading" />
      </div>

      <div class="adx-input-flex">
        <div class="adx-input adx-round adx-label">
          <label for="gender">Gender</label>
          <select [disabled]="isLoading" name="gender" [(ngModel)]="data.entity.gender">
            <option value="1">Male</option>
            <option value="2">Female</option>
            <option value="3">Other (For Business)</option>
          </select>
        </div>
        <div class="adx-input adx-round adx-label">
          <label for="birthdate">Birth Date</label>
          <input type="date" name="birthdate" [(ngModel)]="birthDate" required [disabled]="isLoading" />
        </div>
      </div>

      <div class="adx-divider">Contact Info</div>
      <div class="adx-input adx-round adx-label">
        <label for="email">Email</label>
        <input type="email" name="email" [(ngModel)]="data.entity.email" placeholder="eg. your@domian.com" required
          [disabled]="isLoading" />
      </div>
      <div class="adx-input-flex">
        <div class="adx-input adx-round adx-label">
          <label for="phoneone">Phone Number 1</label>
          <input type="tel" name="phoneone" [(ngModel)]="data.entity.phoneOne" placeholder="eg. 024 XXX XXX" required
            [disabled]="isLoading" />
        </div>
        <div class="adx-input adx-round adx-label">
          <label for="phoneTwo">Phone Number 2</label>
          <input type="tel" name="phonetwo" [(ngModel)]="data.entity.phoneTwo" placeholder="eg. 024 XXX XXX"
            [disabled]="isLoading" />
        </div>
      </div>


      <div class="adx-divider">Residential Info</div>
      <div class="adx-input adx-round adx-label">
        <label for="country">Country</label>
        <select name="country" [(ngModel)]="data.entity.country" (change)="onCountryChanged($event.target.value)"
          [disabled]="isLoading">
          <option *ngFor="let item of countryList" [value]="item.code">{{
            item.name
            }}</option>
        </select>
      </div>

      <div class="adx-input-flex">
        <div class="adx-input adx-round adx-label">
          <label for="state">State/Region</label>
          <input type="text" name="state" [(ngModel)]="data.entity.state" placeholder="eg. Ashanti Region" required
            [disabled]="isLoading" />
        </div>
        <div class="adx-input adx-round adx-label">
          <label for="city">City</label>
          <input type="text" name="city" [(ngModel)]="data.entity.city" placeholder="eg. Kumasi" required
            [disabled]="isLoading" />
        </div>
      </div>

      <!-- other details not needed -->
      <div class='adx-accordian bg-{{theme}} bg-{{theme}}er'
        style="border-radius: 12px; border: 1px solid rgba(128, 128, 128, 0.3)">
        <div class='adx-head adx-divider' style="border-radius: 12px;">
          Optional Info

          <span class="float-right">
            <i class="axl ax-chevron-down"></i>
          </span>
        </div>
        <div class='adx-body'>
          <div class="adx-input-flex">
            <div class="adx-input adx-round adx-label">
              <label for="occupation">Occupation</label>
              <input type="text" name="occupation" [(ngModel)]="data.entity.occupation" placeholder="eg. Medical Doctor"
                [disabled]="isLoading" />
            </div>


            <div class="adx-input adx-round adx-label">
              <label for="placeOfWork">Place Of Work</label>
              <input type="text" name="placeOfWork" [(ngModel)]="data.entity.placeOfWork"
                placeholder="eg. Aiira HealthCare" [disabled]="isLoading" />
            </div>
          </div>




          <div class="adx-input-flex">

            <div class="adx-input adx-round adx-label">
              <label for="maritalStatus">Marital Status</label>
              <select [disabled]="isLoading" name="maritalStatus" [(ngModel)]="data.entity.maritalStatus">
                <option value="1">Single</option>
                <option value="2">Married</option>
                <option value="3">Widow</option>
                <option value="4">Divorced</option>
              </select>
            </div>
            <div class="adx-input adx-round adx-label">
              <label for="bloodGroup">Blood Group</label>
              <select [disabled]="isLoading" name="bloodGroup" [(ngModel)]="data.entity.bloodGroup">
                <option value="1">A +</option>
                <option value="2">A -</option>
                <option value="3">B +</option>
                <option value="4">B -</option>
                <option value="5">AB +</option>
                <option value="6">AB -</option>
                <option value="7">O +</option>
                <option value="8">O -</option>
              </select>
            </div>
          </div>

          <div class="adx-input-flex">
            <div class="adx-input adx-round adx-label">
              <label for="nationality">Nationality</label>
              <input type="text" name="nationality" [(ngModel)]="data.entity.nationality" placeholder="eg. Ghananian"
                [disabled]="isLoading" />
            </div>

            <div class="adx-input adx-round adx-label">
              <label for="memberSince">Member Since</label>
              <input type="date" name="memberSince" [(ngModel)]="memberSince" [disabled]="isLoading" />
            </div>

          </div>

        </div>

      </div>


      <!-- username and password for employee account -->
      <div *ngIf="accountType === 1" class="adx-card adx-ellipse outline adx-full bg-darker">
        <div class="adx-input adx-round adx-label">
          <label for="username">Username</label>
          <input type="text" name="username" [(ngModel)]="data.entity.username" placeholder="eg. johnny" required
            [disabled]="isLoading" [readOnly]="true" />
        </div>
        <p *ngIf="!isEdit">
          <i class="axl ax-send"></i> An email with link to set his/her password will be sent to this person's email
          address.
          <br>
          <button class="adx-btn adx-round" (click)="autoGenerateUsername()">
            Re-Generate Username
          </button>
        </p>
        <!-- <div class="adx-input adx-round adx-label">
          <label for="othername">Password</label>
          <input type="password" name="othername" [(ngModel)]="password1" required placeholder="eg. j0ny123"
            [disabled]="isLoading" />
        </div>
        <div class="adx-input adx-round adx-label">
          <label for="conf-password">Confirm Password</label>
          <input type="password" name="othername" [(ngModel)]="data.entity.hashed" required [disabled]="isLoading" />
        </div> -->


      </div>

      <div *ngIf="accountType === 1" class="adx-card adx-ellipse adx-full bg-darker bg-special">
        <div class="adx-input adx-round adx-label" style="margin-top: 0;">
          <label for="employmentStatus">Employment Status</label>
          <select [disabled]="isLoading" name="employmentStatus" [(ngModel)]="data.status">
            <option [ngValue]="1">Probation</option>
            <option [ngValue]="2">Active Employment</option>
            <option [ngValue]="4">Termination</option>
          </select>
        </div>
      </div>

      <!-- App Sepcific -->
      <ng-content></ng-content>
      <!-- End of wrapper -->
    </div>
    <br />
    <br />

    <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="onCloseDetail()">
      Cancel
    </button>

    <button [disabled]="entityForm.invalid || isLoading" class="adx-btn adx-round adx-sm btn-tgreen float-right"
      (click)="registrationFee > 0 && accountType !== 1 ? showCheckOutModal = true :  onSubmit(2)">
      Save
    </button>
  </form>
</div>



<!-- Invoice -->
<galaxy-modal [showModal]="showCheckOutModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">

  <app-invoice-receipt *ngIf="showCheckOutModal" [appId]="appId"
    [clientName]="data.entity.othername +' '+data.entity.surname"
    [item]="{description:'Registration Fee', amount:registrationFee}" [amount]="registrationFee" [theme]="theme"
    (onCancel)="onCloseModal($event)" (onSubmit)="createClient($event)">
  </app-invoice-receipt>


</galaxy-modal>
