import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

// import * as nsfwjs from 'nsfwjs';

@Injectable({
  providedIn: 'root'
})
export class EmbedService {
  private getImageCDN: string = '/cdn/media/image/'; //type/size/#img.ext
  private downloadImageCDN: string = '/cdn/media/image/'; //type/name/#img.ext
  constructor(private _sanitizer: DomSanitizer, private _http: HttpClient) { }
  onConvertVideoLink(link: string): SafeResourceUrl {

    let url = '';
    if (link.includes("youtube.com/watch?v=")) {
      // its youtube
      // console.log('embeding youtube');
      url = link.replace('youtube.com/watch?v=', 'youtube.com/embed/');
    } else {
      // it vimeo
      // console.log('embeding vimeo video');
      url = link.replace('vimeo.com/', 'player.vimeo.com/video/');

    }

    // console.log('this is the embeded -. ', url);

    // add twitch, facebook, dailymotion amd other chineease video streaming
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);


  }
  onConvertSkectfabLink(link: string): SafeResourceUrl {
    // from -> https://sketchfab.com/3d-models/survival-asset-kit-91eef983352e46f28eadfd7ad7bbac35
    // to -> https://sketchfab.com/models/91eef983352e46f28eadfd7ad7bbac35
    // const url = link.replace(
    //   'sketchfab.com/3d-models/',
    //   'sketchfab.com/models/'
    // );

    //remove name from url,
    const id = link.split('-').slice(-1)[0];
    // const id = linkArray[linkArray.length - 1];
    // console.log('sketchfab id is ', id);
    const url = 'https://sketchfab.com/models/' + id + '/embed';

    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // Images
  readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  imgUrlString(link: string, size = 'default', get = true, prefixUrl = true): string {
    const imageUrl = this._sanitizer.sanitize(SecurityContext.URL, this.imgUrl(link, size, get));
    return imageUrl;
    // return prefixUrl ? imageUrl.replace('/cdn', 'https://cdn-v2.aiira.co') : imageUrl;
  }

  imgUrl(link: string, size = 'default', get = true): SafeResourceUrl {
    if (!link) {
      return null;
    }

    if(link.startsWith('https://')) return link;

    link = link.replace(/\\"/g, '"');
    // console.log(link);

    link = JSON.parse(`"${link}"`);

    // console.log('linking ----------- - ---- ', link);

    if (this.isURL(link) && link.startsWith('https://')) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(decodeURI(link.trim()));
    }

    // check if its a link

    // work on link,
    let imgSize = 'lg';
    switch (size) {
      case 'lg':
        imgSize = 'large';
        break;
      case 'md':
        imgSize = 'medium';
        break;
      case 'sm':
        imgSize = 'small';
        break;
      case 'xs':
        imgSize = 'extra-small';
        break;

      default:
        imgSize = 'default';
        break;
    }

    const alias = link.split('/')[0];
    const img = link.split('/')[1];

    // console.log('alias-> ', alias, img);

    let url = alias + '/' + imgSize + '/' + img;
    url = get ? this.getImageCDN + url : this.downloadImageCDN + url;

    // console.log('urk-> ', url);
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  mediaUrl(link: string): SafeResourceUrl {
    const url = '/cdn/' + link;
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }



  getVideoImage(link): string {
    let videoImageLink = '';
    let thumbnail = '';

    if (link.includes("youtube.com/watch?v=")) {
      videoImageLink = link.replace('www.', '')
        .replace('youtube.com/watch?v=', 'img.youtube.com/vi/');

      // get the corresponding thumbnail images
      thumbnail = videoImageLink + "/maxresdefault.jpg";
      // thumbnail = videoImageLink + "/0.jpg";
    } else {
      // its vimeo
      videoImageLink = link.replace('vimeo.com/', 'vimeo.com/api/oembed.json?url=https%3A//vimeo.com/');
      this._http.get(videoImageLink).subscribe(res => {

        thumbnail = res['thumbnail_url'];
      })
    }
    // console.log('video thumbnail image is -> ', thumbnail);
    return thumbnail;

  }

  getSketchfabImage(link) {
    let thumbnail = '';
    const id = link.split('-').slice(-1)[0];
    // const id = linkArray[linkArray.length - 1];
    // console.log('sketchfab id is ', id);
    const modalApi = 'https://api.sketchfab.com/v3/models/' + id;
    // https://api.sketchfab.com/v3/models/ceb4b8ba11b349b18c039713bccfe9a0
    return this._http.get(modalApi);
  }


  isURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
  }


  censorImage(img) {
    // const img = document.getElementById('img');

    // Load model from my S3.
    // See the section hosting the model files on your site.
    // return nsfwjs.load()
    //   .then(function (model) {
    //     // Classify the image
    //     return model.classify(img)
    //   })
    //   .then(function (predictions) {
    //     console.log('Predictions: ', predictions)
    //   })
  }



}
