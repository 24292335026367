import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EmbedService, SuiteApiService, Client, AuthService, IBusinessEntity, BusinessEntity, AppSettingService, NotifyService, BusinessSocketService, BusinessBranch, ITransaction, Transaction, StatusEnum, SuiteAccountTypeEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { countries } from 'countries-list';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-person-newx',
  templateUrl: './person-new.component.html',
  styleUrls: ['./person-new.component.scss']
})
export class PersonNewComponent implements OnInit, OnDestroy {

  @Input() accountType: number = 0; //0 - all , 41 - users only , 42 - clients only
  @Input() category: string = '';
  @Input() appId: number = 58; // make default the airFinance

  businessId: number = 0;

  data: IBusinessEntity = new BusinessEntity;
  photo: SafeResourceUrl = '';

  isLoading: boolean = false;

  isDataReady: boolean = false;
  isEdit: boolean = false;

  password1: string = '';

  birthDate: string = '';
  memberSince: string = '';


  countryList: any[] = [];


  viewerId: number = 0;

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  showCheckOutModal = false;
  registrationFee = 0;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
    private _notify: NotifyService,
    private _businessSocket: BusinessSocketService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);
    if (this.businessId !== 0) {
      // this.onReloadData();
    }

    if (this.accountType !== 1) {
      this.registrationFee = this._suiteApi.getActiveBusiness().clientRegistrationFee;
    }


    this.convertParamsToArray();

    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res)

    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;

    // check params to make sure it isnt an edit
    this._aRoute.params.subscribe(res => {
      if (res['id']) {
        // console.log('getting entity');
        this.getData(res['id']);
      } else {
        // console.log('creating entity');
        this.newDataInit();
      }
    });

  }


  getData(id: string) {
    let params = {};
    if (this.category) {
      params = {
        category: this.category,
        categoryType: 'setting'
      }
    }

    this._suiteApi
      .setParams(params, id)
      .get(BusinessEntity)
      .subscribe(
        {
          next: (res) => {

            this._suiteApi.DataMap({ from: res.data, to: this.data });

            // console.log('bus entity is ', res, this.data);

            this.data.entity.country = res.data.entity['location'].country;
            this.data.entity.state = res.data.entity['location'].state;
            this.data.entity.city = res.data.entity['location'].city;

            // set birthDate
            this.birthDate = new Date(this.data.entity.birthdate * 1000).toISOString().split('.')[0].split('T')[0];
            this.memberSince = new Date(this.data.entity.memberSince * 1000).toISOString().split('.')[0].split('T')[0];
            // console.log(new Date(this.data.entity.birthdate * 1000).toISOString().split('.')[0].split('T')[0]);


            this.isEdit = true;
            this.photo = this._embed.imgUrl(res.data.entity.image, 'md');
            this.isLoading = false;
            this.isDataReady = true;
            // _$(res).MapTo(this.album);
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }


  newDataInit() {
    this.data = new BusinessEntity();

    this.onGetImage('suite-person/20f6f0c32d8efd61c35cfdb3fc2c5037.png');

    // set user info
    this.data.business.id = this.businessId;

    const trans = new Transaction;
    trans.businessEntity = null;
    trans.businessBranch = null;


    this.data.transaction = trans;

    //  init status to published
    this.data.status = StatusEnum.PUBLISHED;

    // if its a user account
    if (this.accountType === 1) {
      this.data.accountType = SuiteAccountTypeEnum.USER;
    }

    this.isLoading = false;
    this.isDataReady = true;

  }

  onGetImage(image: string) {
    this.data.entity.image = image;
    this.photo = this._embed.imgUrl(image, 'md');
  }

  convertParamsToArray() {
    this.countryList = Object.keys(countries).map(function (i) {
      const value = countries[i];
      value['code'] = i;
      return value;
    });
  }

  onCountryChanged(code: string) {
    // console.log(code);
    // automatically change the timezone & language
    this.data.entity.city = countries[code]['capital'];
    // console.log('c97htr sekecred', countries[code]['capital']);
  }

  onConfirmPassword(): boolean {
    if (this.password1.trim().length < 6) {
      this._notify.info('Password must be more than 6 characters.');
      return false;
    } else if (this.password1.trim() !== this.data.entity.hashed.trim()) {
      this._notify.info('Password does not match.');
      return false;
    }
    return true;
  }


  onCloseModal(value = false) {
    this.showCheckOutModal = false;
  }

  createClient(transaction: ITransaction) {
    this.data.transaction = transaction;
    this.onSubmit(2);
    this.onCloseModal()
  }



  autoGenerateUsername() {
    if (this.isEdit) return;

    const oname = this.data.entity.othername.trim().length > 0 ? this.data.entity.othername.split(' ')[0].trim() : '-';
    const sname = this.data.entity.surname.trim().length > 0 ? this.data.entity.surname.split(' ')[0].trim() : '-';
    this.data.entity.username = (oname + '.' + sname).toLowerCase();

  }

  onSubmit(status: number) {
    if (this.accountType !== 1) {
      this.data.status = status;
    }


    if (this.accountType === 1 && !this.onConfirmPassword) {
      return;
    }
    // console.log(this.data);
    // check to see if title & description is not empty

    if (this.accountType !== 1) {
      // set creator/modifier
      this.data.createdBy = new BusinessEntity();
      this.data.createdBy.id = this.viewerId;

      // set registered branch
      if (!this.data.registeredBusinessBranch || this.data.registeredBusinessBranch?.id === 0) {
        this.data.registeredBusinessBranch = new BusinessBranch;
        this.data.registeredBusinessBranch.id = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;
      }
    }

    // appointment datetime
    this.data.entity.birthdate = new Date(this.birthDate).getTime();

    this.data.entity.memberSince = new Date(this.memberSince).getTime();

    // this.data.branch = [this.businessBranch];

    // console.log(this.data);
    // return;

    this.isLoading = true;

    this.isEdit ? this.updateEntity() : this.createEntity();

  }

  updateEntity() {
    this._suiteApi
      .setParams(null, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  createEntity() {

    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              // web socket
              this._businessSocket.sendRecord(this.data);


              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }
  
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
