<div class="adx-divider">
  Select Services Of Branch
</div>
<div class="adx-input adx-round">
  <input type="search" name="search" [(ngModel)]="searchValue" (keydown.enter)="onReloadData()"
    placeholder="Search Service...">
</div>
<div [class.is-loading]="isLoading" class="adx-list adx-sm adx-flat" style="padding:0 8px">
  <div *ngFor="let item of data" class="adx-item {{theme}} " (click)="onServiceSelect(item)">
    <h4>{{ item.businessService.name | maxLength: 32 }}</h4>
    <p>Fee: GHS {{ item.businessService.price }}</p>
  </div>
</div>

  <!-- data ends -->
  <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Branch Services'"></app-no-data>

<!-- pagination start -->
<div style="display: flex">
  <div style="flex: 1">
    <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0">
      Total: {{ totalData }}
    </button>
  </div>
  <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
    (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
</div>
<!-- pagination ends -->
