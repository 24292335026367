<!--
  <div class="adx-person" [routerLink]="['/profile/person']">
    <div class="adx-img"><img src="http://127.0.0.1/airCloud/app/images/olivia-black.jpg" alt="" /></div>
    <span class="name"> {{ data.name }} </span>
  </div>
-->
<div class="p-wrapper" [class.mini]="isMini">
  <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFull]="true" [isFlat]="true"
    [showShadowOnHover]="!isMini" [transparentBg]="isMini" [autoDimBlack]='true'>
    <div class="cover bg-black" [lazyLoad]="cover"></div>
    <div class="person" (click)="routeToProfile()">
      <div class="adx-img {{ theme }}"><img [src]="avatar" alt="" /></div>
      <h2 class="name text-normal">
        {{
        !isMini ? (personName | maxLength: 23) : (personName | maxLength: 14)
        }}
        <!--
          <sup class="adx-level bg-blue">PRO</sup>-->
        <small *ngIf="data.isVerified" class="adx-level bg-dark"><i class="axr ax-shield-check color-tgreen"></i></small>
      </h2>
      <div class="username">
        <span class="padding-sm" [class.color-tgreen]="!isMini">
          &#64;{{ data.username | maxLength: 18 }}
        </span>
      </div>
      <span class="tagline">{{ data.tagline | maxLength: 18 }}</span>
      <div>
        <button *ngIf="data.id !== viewerId" (click)="onRelateToPerson($event, data.followedByViewer ? 2 : 1)"
          class="adx-btn adx-round" [ngClass]="{
            'btn-pink': !data.followedByViewer,
            'btn-dark': data.followedByViewer && theme === 'light',
            'btn-grey': data.followedByViewer && theme === 'dark',
            'is-loading':isLoading
          }">
          {{
          data.followedByViewer
          ? 'Following'
          : data.followsViewer
          ? 'Follow back'
          : 'Follow'
          }}
        </button>
      </div>
    </div>
  </galaxy-adaptive-card>
</div>
