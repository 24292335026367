import { Group, IGroup } from "../ori/group.model";
import { BusinessEntity } from './business-entity.model';
import { BusinessBranch } from './business-branch.model';
import { Controller } from '../decorators';
import { Business, IBusiness, IBusinessEntity } from "..";
import { MessageScheduleTypeEnum, MessageServiceEnum, MessageTypeEnum, StatusEnum } from "../../enums";
export interface IBusinessMessage {
  id: number;
  subject: string;
  description: string;
  business: IBusiness;
  senderId: IBusinessSenderId;
  service: MessageServiceEnum; // sms, email, voice, sms & email
  type: MessageTypeEnum; // simple message, bulk to group(department,branches etc.) or to contact file
  scheduleType: MessageScheduleTypeEnum; // send now, schedule for later, schedule recurring
  recurringTime?: number;
  repeats?: number; // daily, weekly, monthly
  repeatDays: string[]; // ['sunday'=>true,monday='false'] etc.
  startTime?: number;
  endTime?: number; // null => never
  lastDeliveredTime?: number; // the last time it sent a message. good for recurring messages.
  status: StatusEnum; // draft, delivered, deleted, active
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'Message' })
export class BusinessMessage implements IBusinessMessage {
  constructor(
    public id = 0,
    public subject = '',
    public description = '',
    public business = new Business,
    public senderId = new BusinessSenderId,
    public service = MessageServiceEnum.SMS, // sms, email, voice, sms & email
    public type = MessageTypeEnum.SimpleMessage, // simple message, bulk to group(department,branches etc.) or to contact file
    public scheduleType = MessageScheduleTypeEnum.SendNow, // send now, schedule for later, schedule recurring
    public recurringTime = 0,
    public repeats = 0, // daily, weekly, monthly
    public repeatDays = [], // ['sunday'=>true,monday='false'] etc.
    public startTime = 0,
    public endTime = 0, // null => never
    public status = StatusEnum.PUBLISHED, // draft, delivered, deleted, active
    public created = 0,
    public createdBy = new BusinessEntity,
  ) { }
}



export interface IBusinessSenderId {
  id: number;
  name: string;
  description: string;
  business: IBusiness;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'SenderId' })
export class BusinessSenderId implements IBusinessSenderId {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public business = new Business,
    public status = StatusEnum.UNPUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) { }
}
