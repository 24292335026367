// import { ISlimScrollOptions } from 'ngx-slimscroll';

const client_ids = {
  airMediaApp: 'airMediaApi',
  airPayment: 'airPaymentApi',
  airNews: 'airNewsApi',
  SuiteChurch: 'SuiteChurch'
};
// 'http://ngapp.com/';
const ServerUrls = {
  //Dev. angular base
  AppBaseUrl: 'http://localhost:4200/',

  //Prod. android/cordova base
  // AppBaseUrl: 'file://android_asset/wwww/',

  //Prod. electron base
  // AppBaseUrl: 'file://android_asset/wwww/',

  ResourceServer: [
    'http://127.0.0.1/',
    'http://192.168.137.101/data/',
    'http://data.softcitysuit.com/',
    'http://softcity.host/data/',
    'http://localhost/data/',
    'http://10.58.99.243/data/',
    'http://suiteDataResource.com/',
    'http://suiteDataResource.com:5000/'
  ],
  IdentityServer: [
    'http://127.0.0.1/airdentity/',
    'http://192.168.137.101/Bearer/',
    'http://bearer.softcitysuit.com/',
    'http://softcity.host/identity/',
    'http://softcity.host/Bearer/',
    'http://10.58.99.243/identity/',
    'https://localhost/identity/',
    'https://localhost',
    'https://localhost'
  ],
  IdManagementServer: [
    'http://127.0.0.1/airdentity/',
    'http://192.168.137.101/identityManage/',
    'http://manage.softcitysuit.com/',
    'http://softcity.host/identityManage/',
    'http://localhost/identityManage/',
    'http://10.58.99.243/identityManage/',
    'http://localhost:5000/'
  ]
};

// default image
const defaultPersonImage = './assets/images/default_user.jpg';
const defaultProductImage = './assets/images/product.jpg';
const defaultBusinessImage = './assets/images/product.jpg'; // TODO change

const index = 0;
const resourceBaseUrl =
  ServerUrls.ResourceServer[index] + client_ids.airMediaApp + '/';
const identityManageRootUrl = ServerUrls.IdManagementServer[index];
const identityManageBaseUrl =
  ServerUrls.IdManagementServer[index] + client_ids.airMediaApp + '/';
const SettingsAuthority = ServerUrls.IdentityServer[index];
const SettingsAppBaseUrl = ServerUrls.AppBaseUrl;

export const Codes = {
  DefaultDateFormat: 'yyyy-MM-dd',
  SettingsAuthority: SettingsAuthority,
  SettingsAppBaseUrl: SettingsAppBaseUrl,
  ServerUrls: ServerUrls,
  client_ids: client_ids,
  resourceBaseUrl: resourceBaseUrl,
  identityManageRootUrl: identityManageRootUrl,
  identityManageBaseUrl: identityManageBaseUrl,

  // slimScrollOptions: slimscrollOptions,
  DefaultPersonImage: defaultPersonImage,
  DefaultProductImage: defaultProductImage,
  DefaultBusinessImage: defaultBusinessImage,
  GMapsAPI: 'AIzaSyC2RupriZn_reNJ1dRcuPNh4kf0oUGANnQ'
};
