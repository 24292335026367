import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService, AppSettingService, SuiteApiService, IReservation, Reservation, IClient, Client, BusinessSocketService, ErrorHandlerService } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, OnDestroy {


  @Input() appId: number = 0;
  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];
  @Input() title: string = 'List Of Appointments'
  @Input() baseUrl: string = '/appointment/';
  @Input() isAttend: boolean = false;
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Appointment Detail';
  detailTitle: string = 'Appointment Detail';
  isLoading: boolean = false;

  data: IReservation[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  dataMaxLength: number = 10;
  searchValue: string;
  businessId: number = 0;
  client: IClient = new Client;
  orderBy: string = 'id';
  theme: string = '';

  isAccount = false;
  businessBranchId: number = 0;
  businessBranchDepartmentId: number = 0;
  currency: string;

  themeUpdated$!:Subscription;
  
  constructor(private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _businessSocket: BusinessSocketService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this.currency = this._suiteApi.getActiveBusiness().currency;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);


    // listen for new record
    this._businessSocket.getNewRecords.subscribe(res => {
      if (
        res.appId === this.appId && // same app
        res.branchId === this.businessBranchId && // same branch
        res.classX === 'Reservation' // data type
      ) {
        // check for business branch id
        // upshift if its opd/receiption

        const data = res.content as IReservation;


        switch (this._suiteApi.getActiveRole().id) {
          case 6:
            // Receiption airCare
            this.data.unshift(data)
            break;
          case 19:
            // Receiption airMeet
            this.data.unshift(data)
            break;

          default:
            // check if its the same department
            if (this.businessBranchDepartmentId === data.businessBranchDepartment.id) {
              if (this.isAttend) {
                this.data.push(data)
              } else {
                this.data.unshift(data)
              }
            }

            break;
        }
      }
    });


    if (this._suiteApi.getDataFromSuite('client-profile')) {

      this.client = <IClient>this._suiteApi.getDataFromSuite('client-profile');
      this.isAccount = true;

    } else if (this.isAttend) {
      // business branch
      this.businessBranchDepartmentId = this._suiteApi.getActiveRole().businessBranchDepartment.id;
      this.title = 'Department Appointments';
    } else {
      // business branch
      this.businessBranchId = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;
    }



    this.onReloadData();

    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });
  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.businessBranchDepartmentId,
        categoryType: this.appId,
        id: this.businessBranchId,
        gid: this.client.businessEntityId,
        orderBy: [this.orderBy]

      })
      .getPageOf(Reservation)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <IReservation[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }


  onDetailRoute(link: string, title: string = 'View Detail') {
    this.showDetail = true;
    this.detailTitle = title;
    // console.log(link);
    this._router.navigateByUrl(this.baseUrl + link);

  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }


  ngOnDestroy(): void {
    // unset parameters
    this._suiteApi.setDataToSuite('client-profile', null);
      this.themeUpdated$.unsubscribe();
    }
  


}
