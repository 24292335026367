import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService, Dashboard, ErrorHandlerService, IDashboard, IRoleGrant, RoleGrant, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-metric',
  templateUrl: './app-dashboard-metric.component.html',
  styleUrls: ['./app-dashboard-metric.component.scss']
})
export class AppDashboardMetricComponent implements OnInit, OnDestroy {

  @Input() isApp: boolean = true;
  roleGrant: IRoleGrant = new RoleGrant;

  data: IDashboard = new Dashboard;
  isLoading: boolean;

  categorySelected = 0;
  categoryTypeSelected = 0; // daily=0; weekly = 1; monthly = 2; yearly=3;

  showModal: boolean;

  today = new Date();

  dataDates: any[] = [];

  monthOfYearInput = this.today.getFullYear() + '-' + this.today.getMonth() + 1;
  filterParam: {
    categorySelected: number,
    categoryTypeSelected: number,
    weekSelected: number,
    monthOfYearSelected: number,
    yearSelected: number,
    fromYearSelected: number,
    toYearSelected: number,

  } =
    {
      categorySelected: 1,
      categoryTypeSelected: 0,
      weekSelected: 1,
      monthOfYearSelected: +this.today.getMonth() + 1,
      yearSelected: this.today.getFullYear(),
      fromYearSelected: this.today.getFullYear(),
      toYearSelected: this.today.getFullYear() + 1,

    };

  xAxisData = [];
  xAxisPrefix = 'Day';

  graphData = []
  title: string;

  id: number = 0;


  stats: { title: string; icon: string; color: string; count: number; }[];

  theme: string = 'light';
  themeUpdated$!: Subscription;

  menu: { name: string; icon: string; route: string; }[];
  
  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.menu = this._suiteApi.appletMenuItems;

    this.stats = [
      { title: '---- ----', icon: 'axl ax-circle', color: 'color-tgreen', count: 777 },
      { title: '---- ----', icon: 'axl ax-circle', color: 'color-pink', count: 777 },
      { title: '---- ----', icon: 'axl ax-circle', color: 'color-blue', count: 777 },
      { title: '---- ----', icon: 'axl ax-circle', color: 'color-yellow', count: 777 },
    ];

    this.isLoading = true;


    this.roleGrant = this._suiteApi.getActiveRole();

    if (this.roleGrant.id !== 0) {
      // console.log('sss ',this.roleGrant.id);
      this.getData();
    }

  }


  getData() {
    this.isLoading = true;
    const payload = this.filterParam;

    this._suiteApi
      .setParams(
        {
          category: this.categorySelected,
          categoryType: this.categoryTypeSelected,
        }
        , 'app/' + this.roleGrant.id.toString())
      .putAs(Dashboard, payload).subscribe(
        {
          next: (res) => {
            this.data = res.data;

            // process graph
            this.graphData = this.data.graphs;

            // get Datas
            if (this.graphData) {

              // console.log(this.graphData);

              // get Datas
              this.graphData.forEach(e => e.data = this.getAxisData(e.raw));
            }

            // console.log('graph done')
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }


      );
  }


  onReloadData() {
    this.xAxisData = [];

    switch (+this.categoryTypeSelected) {
      case 1:
        this.xAxisPrefix = 'Week '
        break;

      case 2:
        this.xAxisPrefix = 'Month '
        break;

      case 3:
        this.xAxisPrefix = 'Year '
        break;

      default:
        this.xAxisPrefix = ''
        break;
    }
    this.getData();
  }

  onFilterChanged() {
    let catSelected = 'Daily';

    switch (+this.filterParam.categoryTypeSelected) {
      case 1:

        catSelected = 'Weekly';
        break;
      case 2:

        catSelected = this.filterParam.yearSelected + ' Monthly';
        break;
      case 3:

        catSelected = 'Yearly (' + this.filterParam.fromYearSelected + ' - ' + this.filterParam.toYearSelected + ')';
        break;

      default:
        catSelected = 'Daily (' + this.monthOfYearInput + ')';

        this.filterParam.yearSelected = + this.monthOfYearInput.split('-')[0];
        this.filterParam.monthOfYearSelected = + this.monthOfYearInput.split('-')[1];

        break;
    }

    this.title = catSelected + ' Report';
  }

  onShowFilter() {
    this.showModal = true;
  }

  onCloseModal() {
    this.showModal = false;
  }

  onFilterData() {
    this.onFilterChanged();
    this.getData();
    this.onCloseModal();
  }



  getAxisData(data: any[]) {

    const xAxisData = [];
    const yAxisData = [];


    const xDates = [];
    data.forEach(x => { xDates.push(x['xAxis']) });

    let allDates = []
    // if (xDates.length > 0) {
    //   // console.log('x ', xDates)
    //   allDates = this._getCompleteDateList(xDates, 28);
    // } else {
    allDates = this._getCompleteDateList([(new Date()).toISOString()], 28);
    // }


    // console.log(allDates);

    allDates.forEach(xStringDate => {
      let found = false;
      const formatedDate = this._formatDate(xStringDate);

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element['xAxis'] === xStringDate) {

          xAxisData.push(formatedDate);
          this.xAxisData.push(formatedDate);

          yAxisData.push(element['yAxis']);

          found = true;
          break;
        }
      }

      if (!found) {

        xAxisData.push(formatedDate);
        this.xAxisData.push(formatedDate);
        yAxisData.push(0);

      }

    });

    // remove duplicate values
    this.xAxisData = this.xAxisData.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })

    // console.log(xAxisData)

    return {
      xAxis: xAxisData,
      yAxis: yAxisData
    };
  }

  private _getCompleteDateList(dateStrings: string[], numDates: number): string[] {
    const backdatedDates: string[] = [];
    const date = new Date(dateStrings[dateStrings.length - 1]); // Convert the dateString to a Date object

    // Loop through and generate backdated dates
    for (let i = 0; i < numDates; i++) {
      const backdatedDate = new Date(date.getTime() - i * 24 * 60 * 60 * 1000); // Subtract i days
      const formattedDate = backdatedDate.toISOString().slice(0, 10); // Format the date as YYYY-MM-DD
      backdatedDates.unshift(formattedDate); // Add the formatted date to the beginning of the array
    }

    return backdatedDates;
  }


  private _formatDate(dateString: string): string {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define month and day format options
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];

    // Format the date as "Month Day"
    return `${month} ${day}`;
  }

  
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
