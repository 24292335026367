import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainAppComponent } from './main-app.component';
import { BusinessComponent } from './business/business.component';
import { PlayComponent } from './play/play.component';
import { DeveloperComponent } from './developer/developer.component';
import { NewsComponent } from './news/news.component';
import { AppxComponent } from './appx/appx.component';
import { StudioComponent } from './studio/studio.component';
import { ToolComponent } from './tool/tool.component';
import { SupportComponent } from './support/support.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { HomeInteractiveComponent } from './home-interactive/home-interactive.component';

const routes: Routes = [
  {
    path: '',
    component: MainAppComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeInteractiveComponent

        // canActivate: [AuthGuardService]
      },
      {
        path: 'suite',
        component: AppxComponent
      },
      {
        path: 'business',
        component: BusinessComponent,
      },
      {
        path: 'studio',
        component: StudioComponent,
      },
      {
        path: 'connect',
        component: PlayComponent
      },
      {
        path: 'developer',
        component: DeveloperComponent
      },
      {
        path: 'about',
        component: NewsComponent
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'tos',
        component: TosComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent
      },
      {
        path: 'iforgot',
        component: ResetPasswordComponent
      },

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class MainAppRoutingModule { }
