<div class="adx-divider" *ngIf='showTitle'>
  {{ title }}
</div>
<div echarts [options]="options" [theme]="theme === 'light'?'coolTheme':'dark'" class="demo-chart {{xClass}}"
  [loading]="isLoading"></div>

<div class="empty-data" *ngIf="isDataEmpty">
  <p class="text-center">
    <i class="axl ax-chart-area-line ax-2x"></i>
    <br>
    <br>
    No Gaph Data To Display
  </p>
</div>