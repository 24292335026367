import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galaxy-ui-space',
  templateUrl: './ui-space.component.html',
  styleUrls: ['./ui-space.component.scss']
})
export class UiSpaceComponent implements OnInit {

  @Input() app: string = '';
  @Input() menu = [];
  closed: boolean = true;
  constructor() { }

  ngOnInit() { }

  onToggleClose() {
    this.closed = !this.closed;
  }
}
