import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupType'
})
export class GroupTypePipe implements PipeTransform {
  transform(value: number, args?: string): any {
    if (args) {
      if (args === 'status') {
        return this.status(value);
      }
      if (args === 'access') {
        return this.access(value);
      }
      if (args === 'accessIcons') {
        return this.accessIcons(value);
      }
      if (args === 'disclaimer') {
        return this.disclaimer(value);
      }
    }
    return this.status(value);
  }

  status(id: number): string {
    let name = '';
    switch (id) {
      case 7:
        name = 'Published';
        break;

      case 8:
        name = 'Unpublished';
        break;

      case 9:
        name = 'Unlisted';
        break;

      case 10:
        name = 'Trashed';
        break;

      default:
        name = 'Unpublished';
        break;
    }

    return name;
  }

  access(id: number): string {
    let name = '';
    switch (id) {
      case 64:
        name = 'Public';
        break;

      case 65:
        name = 'Followers';
        break;

      case 66:
        name = 'Unlisted';
        break;

      case 67:
        name = 'Private';
        break;

      default:
        name = ' Private';
        break;
    }

    return name;
  }

  accessIcons(id: number): string {
    let name = '';
    switch (id) {
      case 64:
        name = 'globe-africa';
        break;

      case 65:
        name = 'users';
        break;

      case 66:
        name = 'eye-slashed';
        break;

      case 67:
        name = 'link';
        break;

      default:
        name = ' link';
        break;
    }

    return name;
  }

  disclaimer(id: number): string {
    let name = '';
    switch (id) {
      case 31:
        name = '15';
        break;

      case 32:
        name = 'Family';
        break;

      case 33:
        name = 'PG';
        break;

      case 34:
        name = '18+';
        break;
      case 35:
        name = 'E';
        break;

      default:
        name = 'E';
        break;
    }

    return name;
  }
}
