<app-ui-entity-activity>

  <div id="entity" *ngIf="isAccount">
    <app-person-actvity [person]=" client" [appId]="appId">
    </app-person-actvity>
  </div>

  <!-- account transactions -->
  <ui-data-grid [title]="title" (searchClicked)="onSearchData($event)" (closeDetail)="onCloseRoute()"
    [showDetail]="showDetail" [detail]="detailTitle" [paginatorPage]="page" [paginatorTotal]="totalData"
    [paginatorLimit]="pageSize" (pageinateData)="onPaginateData($event)">
    <div class="header-btns">
      <button class="adx-btn btn-default adx-icon adx-round" (click)="onReloadData()">
        <i class="axl ax-refresh"></i>
      </button>
    </div>
    <div class="data">
      <galaxy-adaptive-card [classes]="'adx-table adx-sm'" [miniPadding]=true [autoFull]=true [shape]="'rounded'"
        [isFlat]="true">
        <div *ngIf="isLoading" class="adx-card adx-flat is-loading">
          <p></p>
        </div>

        <!-- data here -->
        <table [class]="theme">
          <tr *ngFor="let item of data">

            <td *ngIf="!isAccount">
              <div style="display: flex">
                <galaxy-entity-image [image]="item.businessEntity.entity.image" [isRound]="true"></galaxy-entity-image>
                <div>
                  {{ item.businessEntity.entity.name | maxLength: 32 }}
                  <br />
                  <small> <i class="axr ax-phone"></i> : {{ item.businessEntity.entity.phoneOne }} </small>
                </div>
              </div>
            </td>

            <td *ngIf="isAccount">
              <!-- <i class="fa fa-circle-notch color-blue"></i> -->
              {{ item.created *1000 | date }}

              <br />
              <small>
                <i class="axl ax-clock"></i>
                {{ item.created *1000 | date :'h:m:s' }}
              </small>
            </td>

            <!-- status -->
            <td>
              <button class="adx-btn adx-ellipse adx-icon adx-flat disabled adx-iconx" [ngClass]="{
                  'btn-prim': item.status.id === 1,
                  'btn-tgreen': item.status.id === 2,
                  'btn-pink': item.status.id === 3,
                  'btn-yellow': item.status.id === 4
                }" style="margin: 0 !important" [title]="item.status.name">
                <i class="axr ax-tools"></i>
              </button>
            </td>


            <td>
              <!-- <i class="fa fa-star color-blue"></i> -->
              <b>
                {{ item.customerServiceRequestsCount }} requests
              </b>

              <br />
              <small class="highlight bg-black" style="color:white">
                <!-- <i
                  class="fal fa-long-arrow-right"
                  style="opacity: 0.3;"
                ></i> -->
                Plan :
                {{ item.paymentPlan | enumKey:paymentPlanEnum }}
                <!-- {{ item.status.id === 2 ? 'Attended' : 'Pending' }} -->
              </small>
            </td>

            <!-- transaction exists -->
            <td *ngIf="item.transaction" style="font-family: monospace;">
              <!-- <i class="axr ax-circle color-blue"></i> -->
              <b class="color-pink">
                {{currency}} {{ (item.transaction.amount | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.transaction.amount | number: '1.2-2').split('.')[1] }}</sup>
              </b>

              <br />
              <small>
                <!-- <i class="fal fa-long-arrow-right" style="opacity: 0.3;"></i> -->
                <!-- {{ item.city }}, {{ item.country }} -->
                {{currency}} {{ (item.transaction.paid | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.transaction.paid | number: '1.2-2').split('.')[1] }}</sup> paid
              </small>
            </td>

            <!-- no transaction attached -->
            <td *ngIf="!item.transaction" style="font-family: monospace;">
              <!-- <i class="axr ax-circle color-blue"></i> -->
              <b class="color-pink">
                {{currency}} {{ (item.amount | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.amount | number: '1.2-2').split('.')[1] }}</sup>
              </b>

              <br />
              <small>
                <!-- <i class="fal fa-long-arrow-right" style="opacity: 0.3;"></i> -->
                <!-- {{ item.city }}, {{ item.country }} -->
                {{currency}} {{ (item.downPayment | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.downPayment | number: '1.2-2').split('.')[1] }}</sup> downPayment
              </small>
            </td>
            <td class="action">
              <app-action-btns>
                <div class="adx-btn-group adx-round">
                  <button *ngIf="item.status.id === 4" class="adx-btn active adx-round btn-tgreen">
                    <i class="axl ax-check"></i>
                  </button>

                  <button *ngIf="!isAccount && item.status.id !== 4" class="adx-btn adx-icon adx-flat clear" (click)="
                    onDetailRoute(
                    'edit/' + item.id,
                     'Edit Service'
                    )
                  ">
                    <i class="axl ax-edit"></i>
                  </button>

                  <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear" (click)="
                  onDetailRoute(btnItem.route + item.id, btnItem.title)
                ">
                    <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
                  </button>
                </div>
              </app-action-btns>

            </td>
          </tr>
        </table>

        <!-- data ends -->
        <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Requests'"></app-no-data>

      </galaxy-adaptive-card>
      <br>
      <!-- pagination start -->
      <div style="display: flex;">
        <div style="flex: 1;">
          <button class="adx-btn btn-dark adx-round adx-flat" style="margin-top: 0;">
            Total: {{ totalData }}
          </button>
        </div>
        <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
          (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
      </div>
      <!-- pagination ends -->
    </div>

    <div class="detail">
      <ng-content></ng-content>
    </div>
  </ui-data-grid>

</app-ui-entity-activity>
