
export enum SuiteProductCategoryEnum {
  GROCERY = 1,
  PHONES_TABLETS = 2,
  HEALTH_BEAUTY = 3,
  HOME_OFFICE = 4,
  ELECTRONICS = 5,
  COMPUTING = 6,
  FASHION = 7,
  SPORTING_GOODS = 8,
  BABY_PRODUCTS = 9,
  GAMING = 10,
  OTHER = 11,
}
