import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core'

import Quill from 'quill'
import { QuillEditorComponent, QUILL_CONFIG_TOKEN, QuillConfig, QuillViewComponent } from 'ngx-quill'

import { AppSettingService, EmbedService, ErrorHandlerService, Person, SocialApiService } from '@galaxy/entity-api'
import { Observable, Observer } from 'rxjs'
import { map } from 'rxjs/operators'

import "quill-mention/autoregister";

// import "quill-emoji";
// Quill.register("modules/emoji", Emoji);

import AutoLinks from 'quill-auto-links'
Quill.register('modules/autoLinks', AutoLinks);



import { StyledMentionBlot } from "./custom-script";

StyledMentionBlot.blotName = "styled-mention";
Quill.register(StyledMentionBlot);

@Component({
  selector: 'galaxy-quill-editor-tool',
  templateUrl: './quill-editor-tool.component.html',
  styleUrls: ['./quill-editor-tool.component.scss']
})
export class QuillEditorToolComponent implements OnInit, AfterViewInit {

  @Input() oriTheme: string = 'light';

  @Input() maxLength: number = 120;
  @Input() placeholder: string = '';
  @Input() isRequired: boolean = true;
  @Input() isReadOnly: boolean = false;
  @Input() showEmoji: boolean = true;
  @Input() minHeight: number = 0;
  @Input() viewerId: number = 0;
  @Input() setContent: Observable<string>;
  @Output() content: EventEmitter<string> = new EventEmitter<string>();

  @Output() mentions: EventEmitter<Person[]> = new EventEmitter<Person[]>();

  xStyles?: any = {
    color: 'inherit',
    border: 'none',
    borderRadius: '16.5px',
    fontSize: '14px',
    maxWdidth: '100%'
  };
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent
  editorContent: string = ''

  modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user')
      },
      source: async (searchValue, renderList, mentionChar) => {
        if (mentionChar === "@") {
          this.suggestPeople(searchValue).then(() => renderList(this.myPersonsData));
        }
      },
      dataAttributes: ['id', 'value', 'image', 'username', 'name', 'denotationChar', 'link', 'target', 'disabled', 'color'],
      blotName: 'styled-mention',
      minChars: 3,
      maxChars: 8
    },

    // 'emoji-shortname': true,
    // 'emoji-textarea': this.showEmoji,
    // 'emoji-toolbar': true,
    toolbar: false,
    autoLinks: true

  }


  myPersonsData: Person[] = [];

  showEmojiModal = false;

  @ViewChild('editorText') editorText!: QuillViewComponent;

  @ViewChild('containQuill') containQuill: ElementRef;

  constructor(
    @Inject(QUILL_CONFIG_TOKEN) config: QuillConfig,
    private _socialApi: SocialApiService,
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _errorHandlerService: ErrorHandlerService

  ) {

    // this.modules.mention.source =
  }
  ngAfterViewInit(): void {
    const el: HTMLDivElement = this.containQuill.nativeElement
    // auto give max-width
    setTimeout(() => {
      // console.log();
      el.style.maxWidth = el.clientWidth + 'px';
    }, 300);

  }


  ngOnInit() {


    // Auto change content
    this.setContent.subscribe((res: string) => {
      this.editorText.quillEditor.setText(res);
    });
    // it should supportsPassiveEventListeners, @mentions, autolink, link frame and emojis

    // console.log(
    //   // Quill.import('blots/mention'),
    //   StyledMentionBlot
    // );

    this.xStyles.backgroundColor = this.oriTheme === 'light' ?
      'rgba(32,32,32,.05)' :
      'rgba(0, 0, 0, 0.5)';


    if (this.minHeight > 0) {
      this.xStyles.minHeight = this.minHeight + 'px';
    }

    // // remove emoji icon from textarea if its small screen
    // if (this._appSetting.defaultInnerWidth <= 512) {
    //   this.modules['emoji-textarea'] = false
    // }

    // // observe changes
    // this._appSetting.innerWidth.subscribe(res => {
    //   if (res <= 512 && this.showEmoji === true) {
    //     this.modules['emoji-textarea'] = false
    //   } else {
    //     this.modules['emoji-textarea'] = this.showEmoji
    //   }
    // })


    // this.

    // when mentions are clicked, emit mentions output to be tagged to Activity,
    // at html view render of quill Output, observe the click of mention class with data-value attr
    // when its clicked, send to profile of person

  }

  addBindingCreated(quill: Quill) {

    // quill.keyboard.addBinding({
    //   key: 'B',
    //   shortKey: true
    // } as any, (range, context) => { quill.formatTex(range, 'bold', true) })
  }


  async suggestPeople(searchValue): Promise<Person[]> {

    // console.log('get person by name ' + searchValue);

    // const data = new Observable((observer: Observer<Person[]>) => {
    //   const person: Person[] = [];
    //   observer.next(person);
    //   // observer.error();
    //   observer.complete();
    // });

    return this._socialApi
      .setParams({
        page: 1,
        pageSize: 10,
        searchValue: searchValue,
        id: this.viewerId,

      })
      .getPageOf(Person).pipe(
        map(async res => {
          // console.log('responds',res)
          // this.isLoading = false;
          const personsData: Person[] = res.data as Person[];

          // console.log('person data', personsData)

          personsData.forEach(element => {
            element.image = this._embed.imgUrlString(element.image, 'sm');
            element['value'] = element.username;
          });

          this.myPersonsData = personsData;

          return personsData;
          // observer.complete();


        })
      ).subscribe(res => {
        // console.log(res)
      }) as any;


    // get the id of the person
    return await new Observable((observer: Observer<Person[]>) => {


      // console.log('inside the observable');

      this._socialApi
        .setParams({
          page: 1,
          pageSize: 10,
          searchValue: searchValue,
          id: this.viewerId,

        })
        .getPageOf(Person)
        .subscribe(res => {
          // console.log('responds',res)
          // this.isLoading = false;
          const personsData: Person[] = res.data as Person[];

          personsData.forEach(element => {
            element.image = this._embed.imgUrlString(element.image, 'sm');
          });

          observer.next(personsData);
          observer.complete();


        });
    }) as any;

    // return data as any;

    // const allPeople = [
    //   {
    //     id: 1,
    //     value: "Fredrik Sundqvist"
    //   },
    //   {
    //     id: 2,
    //     value: "Patrik Sjölin"
    //   },
    //   {
    //     id: 2,
    //     value: "Esther Boateng"
    //   }, {
    //     id: 2,
    //     value: "Majesty Viiba"
    //   }
    // ];
    // return allPeople.filter(person => person.value.includes(searchValue));
  }



  // ngOnDestroy(){
  //   Quill.off();
  // }
}
