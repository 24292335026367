<div class="media-list-item" (click)="onImageClicked()">
  <div class="adx-img bg-pink" [lazyLoad]='art'>

  </div>
  <div class='detail'>
    <h2>
      {{data.title | maxLength:32}}
    </h2>
    <p>
      {{data.modified * 1000 | date}}
    </p>

  </div>
</div>
