<galaxy-adaptive-card [isFlat]="true" [showShadowOnHover]="true" [showPadding]="false" [shape]="'rounded'"
  [autoDimBlack]='true'>
  <div class="adx-blog">
    <div class="adx-img" (click)="onViewEntity()" [lazyLoad]="cover">
      <div class="padding-ratio"></div>
    </div>
    <div class="adx-detail padding-sm {{theme}}">
      <div style="display: flex;">
        <div style="flex:1">

          <div *ngIf="showPerson" class="adx-avatar adx-xs adx-flat"
            [routerLink]="['/profile/' + data.person.username + '/blog']">
            <div class="adx-img" style="background-color: black;">
              <img [src]="userImage" [alt]="data.person.username" />
            </div>
            <h2>
              {{ data.person.username || 'person name' }}&nbsp;
            </h2>
          </div>
        </div>
        <div>
          <div class="type color-{{ color }}">{{ data.type | enumKey:blogTypeEnum || 'Type' }}</div>
        </div>
      </div>

      <!-- [routerLink]="['/blogs/article/', data.alias, data.id]" -->
      <h2 class="blog-title text-normal" (click)="onViewEntity()">
        {{ data.title | maxLength: maxTitleLength || 'Title of Article' }}
      </h2>
    </div>
  </div>
  <div class="adx-item padding-sm adx-flat adx-sm" *ngIf="showCounts">
    <span> <i class="axr ax-brand-google-analytics"></i> {{ data.hit }} </span>
    <span>&nbsp; <i class="axr ax-heart"></i> {{ data.likeCount }} </span>
    <span>&nbsp; <i class="axr ax-message-dots"></i> {{ data.commentCount }}
    </span>
    <span class="date remove-xxs">
      {{ data.created * 1000 | amTimeAgo: true }} ago &nbsp; &nbsp;</span>
  </div>
</galaxy-adaptive-card>
