<div
  class="cover"
  [ngClass]="{
    'bg-white': theme === 'light',
    'bg-darker': theme === 'dark'
  }"
>
  <!-- <button class="adx-btn adx-round" routerLink="/setup/interest">interest</button>
  <button class="adx-btn adx-round" routerLink="/setup/discover">discover</button> -->
  <section class="adx-section">
    <div class="wrapper">
      <div class="adx-row">
        <div class="adx-colx-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </section>
</div>
