<div class="media-navigate left" *ngIf="showNavBack">
  <button
    class="adx-btn adx-round adx-icon btn-default adx-sm adx-shadow"
    (click)="onNav(false)"
  >
    <i class="axr ax-chevron-left"></i>
  </button>
</div>

<div class="media-navigate right" *ngIf="showNavNext">
  <button
    class="adx-btn adx-round adx-icon btn-default adx-sm adx-shadow"
    (click)="onNav(true)"
  >
    <i class="axr ax-chevron-right"></i>
  </button>
</div>
