import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';



@Component({
  selector: 'ui-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  // @ViewChild('contentArea') private contentArea: ElementRef<HTMLMainElement>;
  @Input() app: string = '';
  @Input() menu: any = [];
  @Input() theme: string = 'dark';themeUpdated$!: Subscription;
  @Input() aRouterLink: string = '';
  @Input() showSearch = true;
  closed = true;
  constructor(private _appSetting: AppSettingService, private _router: Router) {
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
    this._appSetting.color.subscribe(res => {
      this.aRouterLink = res;
    });
  }

  ngOnInit() {

  }

  onToggleClose() {
    this.closed = !this.closed;
  }
  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
