<section class="adx-section" style="padding-top: 0;">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class='entity-cover'>

          <ng-content select="#entity"></ng-content>

        </div>

      </div>
      <div class="adx-cols-12">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</section>
