import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranchService, BusinessBranchService } from './business-service.model';
import { IBusiness, Business } from './business.model';
import { Controller } from '../decorators';
import { CourseCode, DepartmentProgrammeEnrollmentCourseCode, ICourseCode, IDepartmentProgrammeEnrollmentCourseCode, ISemester, Semester } from "./academy.model";
import { IRoleGrant, RoleGrant } from "./role.model";
import { QuizSectionTypeEnum, QuizTypeEnum, StatusEnum } from "../../enums";



export interface IBusinessGrade {
  id: number;
  grade: string;
  point: number;
  minScore: number;
  maxScore: number;
  description: string;
  gradeSystem: IBusinessGradeSystem;
  created: number;
  modified?: number;
}

@Controller({ name: 'Grade' })
export class BusinessGrade implements IBusinessGrade {
  constructor(
    public id = 0,
    public grade = '',
    public point = 0,
    public minScore = 0,
    public maxScore = 0,
    public description = '',
    public gradeSystem = new BusinessGradeSystem,
    public created = 0,

  ) { }
}

export interface IBusinessGradeSystem {
  id: number;
  name: string;
  business: IBusiness;
  created: number;
  modified?: number;
  gradeCount?: number;
}

@Controller({ name: 'GradeSystem' })
export class BusinessGradeSystem implements IBusinessGradeSystem {
  constructor(
    public id = 0,
    public name = '',
    public business = new Business,
    public created = 0
  ) { }
}


export interface IQuiz {
  id: number;
  title: string;
  code: string;
  totalScore: number;
  isExam: boolean;
  type: QuizTypeEnum; // activity, written:string;
  status: StatusEnum; // activity, written:string;
  courseCode: ICourseCode; // subject of qui:string;
  academicSemester: ISemester; // subject of qui:string;
  highestScore?: number;
  averageScore?: number;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  quizSections?: IQuizSection[];
  quizSectionCount?: number;
}



@Controller({ name: 'Quiz' })
export class Quiz implements IQuiz {
  constructor(
    public id = 0,
    public title = '',
    public code = '',
    public totalScore = 0,
    public isExam = false,
    public type = QuizTypeEnum.WRITTEN,
    public status = StatusEnum.PUBLISHED,
    public courseCode = new CourseCode,
    public academicSemester = new Semester,
    public quizSections = [],
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
    // this.status.id = 2;// published
    // this.type.id = 1;
  }
}


export interface IQuizSection {
  id: number;
  title: string;
  quiz: IQuiz;
  numberOfQuestions: number;
  score: number;
  status: StatusEnum;
  type: QuizSectionTypeEnum // theory, multiple choic:string;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}



@Controller({ name: 'QuizSection' })
export class QuizSection implements IQuizSection {
  constructor(
    public id = 0,
    public title = '',
    public quiz = new Quiz,
    public numberOfQuestions = 0,
    public score = 0,
    public status = StatusEnum.PUBLISHED,
    public type = QuizSectionTypeEnum.MULTIPLE_CHOICE,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
    // this.type.id = 1;
  }
}


export interface IQuizScore {
  id: number;
  departmentProgrammeCourseCodeEnrollment: IDepartmentProgrammeEnrollmentCourseCode;
  quiz: IQuiz;
  quizSectionScores: object;
  totalScore: number;
  remarks: string;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'QuizScore' })
export class QuizScore {
  constructor(
    public id = 0,
    public departmentProgrammeCourseCodeEnrollment = new DepartmentProgrammeEnrollmentCourseCode,
    public quiz = new Quiz,
    public quizSectionScores = {},
    public totalScore = 0,
    public remarks = '',
    public created = 0,
    public createdBy = new BusinessEntity,
  ) { }
}



export interface IAttendance {

}


export interface IServiceEnrollment {

}

export interface IDepartmentEnrollment {

}







export interface ITeacherCourseCode {
  id: number;
  courseCode: ICourseCode;
  appRoleGrant: IRoleGrant;
  status: StatusEnum;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'TeacherCourseCode' })
export class TeacherCourseCode implements ITeacherCourseCode {
  constructor(
    public id = 0,
    public courseCode = new CourseCode,
    public appRoleGrant = new RoleGrant,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
    // this.status.id = 2;// published
  }
}
