import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingService, Business, BusinessBranch, FinancialAccount, IBusiness, IStoreInvoiceItem, ITransaction, NotifyService, SuiteApiService, Transaction } from '@galaxy/entity-api';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invoice-receipt',
  templateUrl: './invoice-receipt.component.html',
  styleUrls: ['./invoice-receipt.component.scss']
})
export class InvoiceReceiptComponent implements OnInit {

  @Input() theme: string = 'light'; 
themeUpdated$!:Subscription;

  @Input() clientName: string = 'Customer';
  @Input() storeInvoiceItems: IStoreInvoiceItem[] = [];
  @Input() item: { description: string, amount: number };
  @Input() items: { name: string, amount: number }[] = [];


  @Input() approvedById: number = 0;
  @Input() paidBy: string = 'SELF';
  @Input() transactionTypeId: number = 1; // sales
  @Input() amount: number = 0;
  @Input() paid: number = 0;
  @Input() appId: number = 58; // finance

  @Output() onCancel = new EventEmitter<false>();
  @Output() onSubmit = new EventEmitter<ITransaction>();
  transaction: ITransaction = new Transaction;
  financialAccountId: number;
  invoiceDate: number = 0;
  business: IBusiness = new Business;
  currency: string;

  today = (new Date).getTime()

  constructor(
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService
  ) { }

  ngOnInit() {

    // get business
    this.business = this._suiteApi.getActiveBusiness();

    this.currency = this._suiteApi.getActiveBusiness().currency;


    if (this._suiteApi.verifyBusinessSubscription().limitReached) {
      this._notify.error('Your Subscription has expired and/or app/client limit reached');
      this.onCancelClick()
      return;
    }



    // generate Transaction
    this.invoiceTransaction();
  }

  getFinancialAccount() {
    this.financialAccountId = 0;
  }
  private invoiceTransaction() {
    this.transaction = new Transaction();
    // get business branch
    this.transaction.businessBranch = new BusinessBranch();
    this.transaction.businessBranch.id = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;

    // set app to store
    this.transaction.app.id = this.appId;
    this.transaction.referenceNumber = this.uuidv4(this.appId);

    // set transaction type
    this.transaction.type = this.transactionTypeId;

    // approved by for expense report
    this.transaction.approvedBy.id = this.approvedById;

    // set financial account
    this.transaction.financialAccount = new FinancialAccount();
    this.transaction.financialAccount.id = this.financialAccountId;

    this.transaction.amount = this.amount;
    this.transaction.paid = this.paid;


    this.invoiceDate = new Date().getTime();

  }

  private uuidv4(appId: number): string {
    return ('xxxxxxxx-xxxx-' + appId + 'xxx-yxxx-xxxxxxxxxxxx').replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  onCancelClick() {
    this.onCancel.emit(false);
  }

  onSubmitClick() {
    this.onPrintElem();
    // return;
    this.onSubmit.emit(this.transaction);
  }



  onPrint() {
    const printContents = document.getElementById('invoice-print').outerHTML;
    const originalContents = document.body.outerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }


  onPrintElem() {

    const printStyle = `
    .reciept {
      font-family: monospace;

    }
    .reciept .cart-item {
        display: flex;
        padding: 4px 16px;


      }

      .reciept .cart-item div {
        flex: 1;
      }

      .text-center{
        text-align:center
      }

      `;

    const mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style>' + printStyle + '</style>');
    mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(document.getElementById('invoice-print').outerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }


  openPDF(): void {
    const DATA = document.getElementById('invoice-print');

    html2canvas(DATA).then(canvas => {

      const fileWidth = 297;
      const fileHeight = canvas.height * fileWidth / canvas.width;

      const FILEURI = canvas.toDataURL('image/jpg');
      const PDF = new jsPDF('l', 'mm', [297, 180]);
      const position = 0;
      PDF.setFillColor('white');
      PDF.addImage(FILEURI, 'JPEG', 0, position, fileWidth, fileHeight);

      PDF.save('invoice.pdf');
    });
  }

}
