<div *ngIf="loggedIn" class="p-comment {{ theme }}">
  <div class="img">
    <img [src]="personImage" alt="" />
  </div>
  <div class="content">
    <div class="adx-inputx adx-round outline outline-pink">
      <!-- <input
        *ngIf="!showTextarea"
        type="text"
        placeholder="Add a comment"
        [(ngModel)]="data.description"
        (focus)="showTextarea = true"
      /> -->

      <!-- <textarea
        autofocus
        *ngIf="showTextarea"
        name="description"
        [(ngModel)]="data.description"
      ></textarea> -->
      <galaxy-quill-editor-tool [placeholder]="'Add a comment'" [oriTheme]="theme" [setContent]="setContent" [minHeight]="24"
        [viewerId]='viewerId' bounds="self" ngDefaultControl (content)="onContentChanged($event)">
      </galaxy-quill-editor-tool>
    </div>
    <div class="actions" style="margin: 0 1.6rem;" *ngIf="content.trim().length > 0">
      <div class="btns">
        <small style="opacity: 0.7; padding-top: 1.6rem;">
          {{ content.trim().length + '/' + maxDescriptionLength }}
        </small>
        <span class="float-right">
          <button class="adx-btn adx-xs adx-round btn-{{ theme }} " (click)="onCancelPost(false)">
            Cancel
          </button>
          <button class="adx-btn adx-xs adx-round btn-tgreen" [disabled]="data.description.length == 0"
            (click)="onAddComment()">
            Done
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

<galaxy-modal [showModal]="showModal" (onCloseModalClicked)="showModal = false" [showPadding]="false" >
  <div class="text-center">
    <h3 class="title text-normal">
      Are you sure?
    </h3>
    <p style="opacity: 0.6;">
      Once you delete a draft, you can’t undo it
    </p>
  </div>
  <div>

    <button class="adx-btn btn-grey adx-round" (click)="showModal = false">
      Cancel
    </button>
    <button class="adx-btn btn-pink float-right adx-round" (click)="onCancelPost(true)">
      Delete
    </button>
  </div>
</galaxy-modal>
