import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galaxy-media-banner',
  templateUrl: './media-banner.component.html',
  styleUrls: ['./media-banner.component.scss']
})
export class MediaBannerComponent implements OnInit {

  header?: HTMLElement;
  @Input() title: string = 'Page Title';
  @Input() description: string = 'We made it';
  @Input() showGradient: boolean = false;
  constructor() { }

  ngOnInit() {
    // this.autoHeaderBgColor();
  }



}


