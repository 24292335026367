import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IEntity, EntityCollection, ICollection, Collection, AuthService, ArtistApiService, NotifyService, StoreApiService, PlayApiService, AppSettingService, Entity, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-collection-select',
  templateUrl: './media-collection-select.component.html',
  styleUrls: ['./media-collection-select.component.scss']
})
export class MediaCollectionSelectComponent implements OnInit, OnDestroy {

  @Input() mediaId: number = 0;
  @Input() entity: IEntity = new Entity;
  @Input() collectionType: number = 84; // album = 83, collection = 84

  @Input() entityType: 'photo' | 'media' | 'product' = 'photo';
  @Input() personId: number = 0;
  data: ICollection[] = [];
  isLoading: boolean = true;


  pageSize: number = 10;
  page: number = 1;
  totalData: number = 0;


  searchValue: string = '';

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  private _xApi;
  isCreatingCollection: boolean = false;
  newCollectionIsPrivate: boolean = false;
  viewerId: number = 0;


  constructor(
    private _authService: AuthService,
    private _appSetting: AppSettingService,
    private _artistApi: ArtistApiService,
    private _storeApi: StoreApiService,
    private _playApi: PlayApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // set this.theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.viewerId = this._authService.currentUser.gid.social.u;

    this.isLoading = true;
    this.searchValue = '';

    if (this._authService.loggedIn) {
      switch (this.entityType) {
        case 'photo':
          this._xApi = this._artistApi;

          break;
        case 'media':
          this._xApi = this._playApi;

          break;
        case 'product':
          this._xApi = this._storeApi;
          break;

        default:
          this._xApi = this._artistApi;
          break;
      }
      this.getData();
    } else {
      this._notify.error('Please login to continue');
      this.isLoading = false;
    }
  }

  onSearch() {
    this.onReload();
  }

  onReload() {
    this.isLoading = true;
    this.pageSize = 10;
    this.page = 1;
    this.getData();

  }


  addToCollection(collection: ICollection) {

    this.isLoading = true;

    const etCollection = new EntityCollection;
    etCollection.entity.id = this.entity.id;
    etCollection.collection.id = collection.id;

    this._xApi
      .post(etCollection)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.success('Added to ' + collection.title + ' Collected!');
            } else {
              this._notify.error('Something happened, please try again.');
            }
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )

  }

  getData(): any {
    this._xApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.viewerId,
        category: this.collectionType
      })
      .getPageOf(Collection)
      .subscribe(
        {
          next: (res) => {
            this.data = res.data as Collection[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  createCollection() {

    if (this.searchValue.trim() === '') {
      this._notify.info('Search Value cannot be empty')
      return;
    }

    this.isCreatingCollection = true;

    const newCollection = new Collection;
    newCollection.title = this.searchValue.trim();
    newCollection.tag = this.searchValue.trim().toLowerCase().replace(' ', ',');
    newCollection.person.id = this.viewerId;
    // newCollection.mediaType = this.collectionType;
    newCollection.status = this.newCollectionIsPrivate ? 1 : 2;

    // console.log('is private values is ->', this.newCollectionIsPrivate);


    this._xApi
      .post(newCollection)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.success('New collection created');
              newCollection.id = res.data.id;
              this.data.unshift(newCollection);
            } else {
              this._notify.error('Something happened, please try again.');
            }
            this.isCreatingCollection = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }



  onAddToQueue() {
    this._notify.info('Added to Queue');
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
