import { Entity } from './entity.model';
import { Group } from './group.model';

import { Controller } from './../decorators';
import { MediaTypeEnum, TicketTypeEnum } from '../../enums';

export interface IArtwork {
  // Basic Info
  id: number;
  title: string;
  entity?: Entity;

  // VideoInfo
  description: string;
  thumbnail: string;

  path: string;
  file?;
  // music/video
  type: MediaTypeEnum;

  orderNumber: number;

  created?: number;
}

@Controller({ name: 'Artwork' })
export class Artwork implements IArtwork {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public entity = new Entity(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public orderNumber = 1,
    public file = ''
  ) { }

}
@Controller({ name: 'eArtwork' })
export class eArtwork implements IArtwork {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public entity = null,
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public orderNumber = 1,
    public file = ''
  ) { }
}
@Controller({ name: 'ProductFile' })
export class ProductFile {
  constructor(
    public id = 0,
    public title = '',
    public entity = null,
    public description = null,
    public path = 'assets/images/spring.jpg',
    public type = '',
    public size = 0,
    public orderNumber = 1,
    public file = ''
  ) { }
}

@Controller({ name: 'Ticket' })
export class Ticket {
  constructor(
    public id = 0,
    public title = '',
    public entity = null,
    public description = null,
    public type = TicketTypeEnum.PAID, // type of ticket = free, paid, donation
    public seats = 0, // number of seats available for ticket
    public price = 0, //
  ) { }
}
