
export enum LeaveTypeEnum {
  Annual = 1,
  Bank = 2,
  Casual = 3,
  Compassionate = 4,
  Devet = 5,
  Gardening = 6,
  Maternity = 7,
  Paid_Time_Off = 8,
  Sabatical = 9,
  Sick = 10,
  Time_Off_In_Lieu = 11,
  Unpaid = 12,
  Miscellaneous = 13,
}
