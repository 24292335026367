import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { StartComponent } from './start/start.component';
import { ProfileComponent } from './profile/profile.component';
import { InterestComponent } from './interest/interest.component';
import { DiscoverComponent } from './discover/discover.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  JwtInterceptor,
  ErrorInterceptor,
  AppSettingService,
  AuthService
} from '@galaxy/entity-api';
import { SetupComponent } from './setup.component';
import { UiModule } from '@galaxy/ui';
import { OriUiModule } from '@galaxy/media/ori-ui';
import { Title } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, UiModule, OriUiModule],
  declarations: [
    SetupComponent,
    StartComponent,
    ProfileComponent,
    InterestComponent,
    DiscoverComponent
  ],

  providers: [
    AuthService,
    AppSettingService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } // enable only for ori apps
  ]
})
export class SetupModule { }
