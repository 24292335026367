import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { WebSocketService } from './web-socket.service';
import { NotifyService } from '../notify.service';

@Injectable({
  providedIn: 'root'
})
export class EntitySocketService extends WebSocketService {

  constructor(
    private _authService: AuthService,
    private _storageMap: StorageMap,
    private _notify: NotifyService,

  ) {
    super({ namespace: 'entity', port: 5000 });
  }


  // socketEntity() {
  //   this.initiateEntitySocket();

  //   // disconnect from messegeSocket when user loggs out
  //   this._authService.loggedOut.subscribe(res => {
  //     console.log('loggedOut triggered', res);
  //     if (res) {
  //       this.disconnect();
  //     } else {
  //       this.connect();
  //       this.initiateEntitySocket();
  //     }
  //   })
  // }




  initiateEntitySocket() {
    //connect socket

    // socket my posts for swift notification
    this.socketMyPosts();

    // for checking if im online
    this.listen('online').subscribe(res => {
      const msg = <any>res;
      // this._notify.info(msg.text);
    });


    this.newEntitySocket();
  }


  socketMyPosts() {

    // if (this._oriDB.conversations) {

    //   const conversationIds = [];
    //   this._oriDB.conversations.forEach(element => conversationIds.push(element.id));
    //   // join conversation room
    //   this._messegeSocket.emit('join',
    //     {
    //       username: this._authService.currentUser.username,
    //       conversationIds: conversationIds
    //     }
    //   );
    //   // now listen
    //   this.listToMessengesSent()

    // }

  }


  private newEntitySocket() {
    // for new notiication alert
    this.listen('newNotificationRoom').subscribe(res => {
      const entity = <any>res;


      // if (entity.userId === this._authService.currentUser.gid.social.u) {
      //   // get new conversation
      //   let newConversation: IConversation = new Conversation;
      //   newConversation = <IConversation>entity.conversation;

      //   // join that conversation
      //   this._messegeSocket.emit('newConversation', { userId: 0, conversationId: entity.conversationId, conversation: null });


      //   // add to storage
      //   this._storageMap.get('messenger.conversations')
      //     .subscribe(res => {
      //       let conversation: IConversation[] = [];
      //       if (res) {
      //         conversation = <IConversation[]>res;
      //       }
      //       conversation.unshift(newConversation);
      //       this._storageMap.set('messenger.conversations', conversation).subscribe(() => { })
      //       // emit
      //       this._oriDB.conversations = conversation;
      //       this.conversationUpdated.emit(conversation);

      //     });


      this._notify.info('You have a notification !');
      // }
    });
  }


  private listToMessengesSent() {
    // register socket
    this.listen('sendMessage').subscribe(resx => {

      // console.log('global msg in -- ', resx);

      // const newMessage = <IMessage>resx;
      // this._notify.info('New message!  @' + newMessage.person.username + ' : ' + newMessage.description);

      // // update local conversation
      // this._storageMap.get('messenger.conversations')
      //   .subscribe(res => {
      //     if (res) {

      //       console.log('sorting conversation');

      //       const conversationData = <IConversation[]>res;
      //       for (let index = 0; index < conversationData.length; index++) {
      //         const element = conversationData[index];
      //         if (+element.id === +newMessage.conversation.id) {
      //           element.lastMessage = newMessage.conversation.lastMessage;
      //           element.lastActive = newMessage.conversation.lastActive;

      //           conversationData.splice(index, 1);
      //           conversationData.unshift(element);
      //           // break;
      //         }

      //       }

      //       this._storageMap.set('messenger.conversations', conversationData)
      //         .subscribe(() => {
      //           console.log('messeage conversation stored!', conversationData);
      //           // emit
      //           this._oriDB.conversations = conversationData;
      //           this.conversationUpdated.emit(conversationData);
      //         });
      //     }
      //   });
    });
  }




}
