import { Component, OnInit, Input } from '@angular/core';
import { IEntity, BlogApiService, Blog } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-blog',
  templateUrl: './adx-blog.component.html',
  styleUrls: ['./adx-blog.component.scss']
})
export class AdxBlogComponent implements OnInit {

  @Input() id: number = 0;
  @Input() entity: IEntity = new Blog;

  isLoading: boolean = true;
  showEntity: boolean = false;
  constructor(
    private _blogApi: BlogApiService
  ) { }

  ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      this.getEntity();
    } else {
      this.isLoading = false;
    }
  }

  getEntity() {
    this.isLoading = true;
    this._blogApi.get(Blog).subscribe(res => {
      this.entity = res.data;
      this.isLoading = false;
    })
  }


  onViewEntity() {
    this.showEntity = true;
  }

  onCloseEntity() {
    this.showEntity = false;
  }

}
