import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ArtistApiService, Project, IEntity, EmbedService, AppSettingService, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-person',
  templateUrl: './media-person.component.html',
  styleUrls: ['./media-person.component.scss']
})
export class MediaPersonComponent implements OnInit , OnDestroy{

  searchValue: string = '';

  pageSize: number = 5;
  page: number = 1;
  totalData: number = 0;
  groupType: number = 62;
  categorySelected: number = 39;

  data: IEntity[] = [];

  @Input() personId: number = 0;
  isLoading: boolean = false;


  showImageModal: boolean = false;
  projectId: number = 30;
  prevProjectId: number = 0;
  prevProjectIndex: number = 0;
  nextProjectId: number = 0;
  nextProjectIndex: number = 0;

  theme: string = ''
  themeUpdated$!: Subscription;

  constructor(
    private _embed: EmbedService,
    private _artistApi: ArtistApiService,
    private _appSetting: AppSettingService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });

    this.isLoading = true;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._artistApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        id: this.personId,
        searchValue: this.searchValue,
        category: this.categorySelected
      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            // this.isLoading = false;

            this.data = res.data as IEntity[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onEmbed(link: string) {
    return this._embed.imgUrl(link, 'md');
  }




  onImageClicked(id: number, index: number) {
    // console.log('id @ masonry is ', id)
    // this.showImageModal = false;
    this.projectId = id;
    const len = this.data.length;
    if (len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProjectId = this.data[this.prevProjectIndex].id;

      this.nextProjectIndex = (index + 1) % len;
      this.nextProjectId = this.data[this.nextProjectIndex].id;
      if (index === 0) {
        this.prevProjectId = 0;

      }
      if (index == len - 1) {
        this.nextProjectId = 0;
      }

    }

    // check if there is a prevous & next
    this.showImageModal = true;
  }

  onCloseClicked($event) {
    this.showImageModal = false;
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
