
<section class="adx-section bg-whitex">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size34">Enterprise Apps.</h1>
            <span class="text-size18">
              Sign in to use your favorite productivity apps from any device.
            </span>
          </div>
          <p></p>
          <div class="suite-apps-grid">
            <div class="adx-card adx-flat bg-white adx-ellipse suite-app" *ngFor="let item of apps">
              <div class="adx-img">
                <img src="assets/images/airSuite/apps/{{ item.name }}.png" class="img-responsive" alt="" />
              </div>
              <div class="detail">
                <h3>
                  {{ item.name }}
                </h3>
                <p>
                  {{item.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <p class="text-size18">
            To use airSuite, the comprehensive platform for managing your business, you first need to register your
            business on Qompass. Qompass is a map-based platform that connects businesses with their customers and
            clients, and by registering your business on Qompass, you can make it easy for customers to find you and
            learn about your services.
            <br>
            <br>
            Once you have registered your business on Qompass, you can then access and use
            all of the powerful tools and apps that are included in airSuite, such as the client management app, the
            financial management app, and many others. So if you want to take your business to the next level with
            airSuite, be sure to register on Qompass first <a href="http://business.aiira.co"
              target="_blank">https://business.aiira.co</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
