import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  theme = 'dark';

  currentYear = (new Date()).getFullYear();
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this._appSetting.theme
      .subscribe(res => this.theme = res);

  }

}
