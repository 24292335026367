<div #containQuill class="contain-quill">
  <quill-editor #editorText bounds="self" [(ngModel)]="editorContent" trackChanges="all" [modules]="modules" [sanitize]="true"
    [placeholder]="placeholder" [maxLength]="maxLength" (onEditorCreated)="addBindingCreated($event)" [styles]="xStyles"
    (onContentChanged)="content.emit($event)">
    <!-- (onEditorChanged)="content.emit($event)" -->
  </quill-editor>
</div>
<!-- [required]="isRequired"
[readOnly]="isReadOnly"
[maxLength]="maxLength" -->
<!-- {{ content }} -->

<button (click)="showEmojiModal=true" class="adx-btn adx-icon adx-flat adx-round btn-yellow clear emoji-btn">
  <i class="axr ax-mood-smile"></i>
</button>

<galaxy-modal [showModal]="showEmojiModal" (onCloseModalClicked)="showEmojiModal = false">
  <div class="emoji-container text-center">

    <p class="no-margin">
      Windows and Mac have emojis by default,
      <br>
      to pop-up emojis keyboard press:
      <br>
      <br>
      😍👍😁😘😒👌🤣😂
      <br>
      <br>
      <i class="axl ax-brand-windows"></i> &nbsp; <b>[Win + .]</b> on Windows PC
      <br>
      <i class="axl ax-brand-apple"></i> &nbsp; <b>[CMD + CTRL + Space]</b> on Mac
      <br>
    </p>

    <button (click)="showEmojiModal = false" class="adx-btn adx-round adx-sm btn-default">
      &nbsp;&nbsp; Close &nbsp;&nbsp;
    </button>
  </div>
</galaxy-modal>
