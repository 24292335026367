import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { EmployeeStatusEnum, IClient } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss']
})
export class PersonListComponent implements OnInit {


  @Input() accountType = 0;
  @Input() theme: string = 'light'
  @Input() data: IClient[] = [];
  @Input() actionButtons: { route: string, title: string, icon: string, objectName?: string, object?: any }[] = [];

  @Output() detailRoute = new EventEmitter();

  today = (new Date()).getTime();
  employeeStatusEnum: typeof EmployeeStatusEnum = EmployeeStatusEnum;

  constructor() { }

  ngOnInit() {
  }

  onDetailRoute(link: string, title: string = 'View Detail', objectName?: string, object?: any) {
    const routeData = { link, title, objectName, object };
    this.detailRoute.emit(routeData);

  }

}
