import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'suite-ui-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  @Input() app: string = '';
  @Input() logo: string = '';
  @Input() menu: { name: string; icon: string; route: string; }[] = [];

  @Output() logoClicked = new EventEmitter();
  closed: boolean = true;
  // title = 'artist';
  theme: string = 'light'; 
themeUpdated$!:Subscription;

  constructor(private _appSetting: AppSettingService) {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    })
  }

  ngOnInit() { }

  onLogoClicked() {
    this.logoClicked.emit()
  }

  onToggleClose() {
    this.closed = !this.closed;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
