import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('dark')
  }

}
