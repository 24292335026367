<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Terms and Conditions</h1>

            <p style="font-size: 17px">
              These Terms and Conditions (“Terms”) govern your use of the Aiira
              and any press assets, including but not limited to photographic or
              other audio-visual materials, Aiira's trademarks, logos, trade
              names, service marks, service names, or other distinctive features
              owned by Aiira (Aiira Assets”), made available via the Aiira
              App, located at https://aiira.co, or other Aiira website, or
              otherwise from Aiira Inc. and you (“You”) agree to be bound by
              these Terms. In the event of any conflict between these Terms and
              any applicable written agreement between You and Aiira, the
              written agreement shall prevail.
            </p>

            <h1 class="text-normal">Your Rights To Use Aiira Assets</h1>
            <p style="font-size: 17px">
              Aiira Inc. grants You a limited, non-exclusive, revocable,
              non-sublicensable and non-transferable license to display the
              Aiira Assets in accordance with these Terms. All use by You
              of the Aiira Assets (including any goodwill associated
              therewith) shall inure to the benefit of Aiira. You agree
              that You shall not challenge, or assist others to challenge, the
              Aiira Assets or the registration or ownership thereof by
              Aiira (except to the extent such restriction is prohibited by
              applicable law), or attempt to register any Aiira Assets or
              other names, logos, marks, or materials (including domain names)
              that are confusingly similar in any way (including but not limited
              to, sound, appearance and spelling) to any Aiira Assets. The
              Aiira Assets are provided "as is" and Aiira disclaims
              all express and implied warranties, including warranties of
              noninfringement. Aiira shall not be liable to You under any
              theory of liability for any direct, indirect, incidental, special,
              consequential, punitive, exemplary or other damages arising out of
              the Aiira Assets or Your use thereof. This limitation shall
              apply even if Aiira was advised of, or should have been aware
              of, the possibility of such damages. Some jurisdictions do not
              allow certain exclusions or limitations; in such jurisdictions,
              the exclusions and limitations stated in these Terms shall apply
              to You to the maximum extent permitted by law. Nothing herein
              grants You any right, title or interest in or to the Aiira
              Media Center Assets.
            </p>

            <h1 class="text-normal">Requirements</h1>
            <p style="font-size: 17px">
              All uses of the Aiira Assets must conform to the Aiira Brand
              Guidelines, located at https://brand.aiira.co, as well as
              the following requirements, unless You have prior written
              permission from Aiira:
            </p>
            <p style="font-size: 17px">
              <em>No Modification</em> . The Aiira Assets must be used as
              provided by Aiira with no modifications. Do not remove,
              distort or alter any element of the Aiira Assets or change
              any colors. Do not shorten, abbreviate, or create acronyms out of
              the Aiira Assets.
            </p>
            <p style="font-size: 17px">
              <em> No Generic Use. </em>
              Do not use Aiira Assets in a way that suggests a common,
              descriptive, or generic meaning.
            </p>
            <p style="font-size: 17px">
              <em> No Plural or Possessive Use. </em>
              Do not use the Aiira Assets in the plural or possessive form.
            </p>
            <p style="font-size: 17px">
              <em> No Incorporation. </em>
              Do not incorporate Aiira Assets into Your own product name,
              service names, trademarks, logos, company names, domain names,
              website titles, publication titles, or the like, unless expressly
              permitted by Aiira.
            </p>
            <p style="font-size: 17px">
              <em> No confusingly similar marks. </em>
              Do not use the Aiira Assets, including any Aiira
              trademark, in a manner that might create confusion about the
              ownership of the Aiira Assets.
            </p>

            <p style="font-size: 17px">
              <em> No confusing source. </em>

              Do not use the Aiira Assets, including any Aiira
              trademark, in a manner that implies that Aiira is the source
              of Your products or service, or that otherwise might create
              confusion about the source of the Aiira Assets.
            </p>

            <p style="font-size: 17px">
              <em> Domain names. </em>

              Do not register the Aiira Assets, including any Aiira
              trademark, as domain names or as any part of a domain name.
            </p>

            <p style="font-size: 17px">
              <em> Trade dress. </em>
              Do not copy or imitate Aiira’s website design, typefaces,
              distinctive color, style, graphic designs, or imagery.
            </p>

            <p style="font-size: 17px">
              <em> Endorsement. </em>

              Do not display the Aiira Assets in any manner that (i)
              overstates Your relationship with Aiira; (ii) implies that
              You have a relationship or affiliation with Aiira; (iii)
              implies You sponsored or endorsed by Aiira; (iv) implies that
              any content has been authorized by Aiira; or (v) represents
              the views or opinions of Aiira or Aiira personnel unless
              You have prior written permission from Aiira to do so.
            </p>

            <p style="font-size: 17px">
              <em> Prominence. </em>
              Do not display the Aiira Assets in a manner that makes it the
              most distinctive or prominent feature on Your web page, printed
              material, or other content without express written permission from
              Aiira to do so.
            </p>

            <p style="font-size: 17px">
              <em> Violation of Law. </em>

              Do not display the Aiira Assets on any website that contains,
              displays, or promotes pornography or like adult content, or
              gambling, the sale of tobacco, alcohol, or firearms to persons
              under twenty-one (21) years of age.
            </p>

            <p style="font-size: 17px">
              <em> Objectionable Use. </em>

              Do not display the Aiira Assets in a manner that is
              misleading, unfair, defamatory, infringing, libelous, disparaging,
              obscene, or otherwise objectionable to Aiira.
            </p>

            <p style="font-size: 17px">
              <em> Broadcast. </em>

              The Aiira Assets may not be used in television, film, or
              video without Aiira’s prior written permission.
            </p>

            <p style="font-size: 17px">
              <em> Book or Other Publication Titles. </em>
              The Aiira Assets may not be displayed in titles of
              publications, including books, without Aiira’s prior written
              permission.
            </p>

            <p style="font-size: 17px">
              <em> Merchandise. </em>

              The Aiira Assets may not be used or displayed in or on
              merchandise or manufactured items of any kind without Aiira’s
              prior written permission.
            </p>

            <p style="font-size: 17px">
              <em> Credit Line: </em>

              You will reproduce all credit line information provided for the
              Aiira Assets.
            </p>
            <h1 class="text-normal">Aiira’s Rights</h1>
            <p style="font-size: 17px">
              Aiira will interpret Your compliance with these Terms in its
              sole discretion. Aiira may modify or terminate Your
              permission to display or use the Aiira Assets at any time.
              Aiira may take action against any use of the Aiira
              Assets that does not comply with these Terms or written permission
              from Aiira, infringes any Aiira owned or licensed
              intellectual property or other right, or violates applicable law.
            </p>
            <h1 class="text-normal">General Provisions</h1>
            <p style="font-size: 17px">
              You may not assign Your rights or delegate Your obligations under
              these Terms without Aiira’s prior written consent. These
              Terms do not create any rights in any third party. These Terms
              will be governed and construed in accordance with the laws of the
              Republic of Ghana, without regard to conflict of law principles.
              The venue for any dispute or claim shall be the Supreme Court,
              Ghana. Neither party shall be deemed to be an employee, agent,
              partner, or legal representative of the other. Aiira’s waiver
              of breach of any provision of these Terms shall not be deemed to
              be a waiver of the Terms themselves. If any provision of these
              Terms is held by a court of competent jurisdiction to be illegal,
              void, or unenforceable, such provision shall be changed and
              interpreted so as best to accomplish the objectives of the
              original provision to the fullest extent allowed by law and the
              remaining provisions of these Terms shall remain in full force and
              effect.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
