import { inject, Pipe, PipeTransform } from '@angular/core';
import { EmbedService } from '@galaxy/entity-api';

@Pipe({
  name: 'sanitizeImage'
})
export class SanitizeImagePipe implements PipeTransform {

  private _embedService = inject(EmbedService)
  transform(value: any, args?: any): any {
    return this._embedService.imgUrl(value);
  }

}
