import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { ICollection, IEntity, AuthService, ArtistApiService, StoreApiService, PlayApiService, NotifyService, AppSettingService, IEntityCollection, EntityCollection, Collection, StatusEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-collection-entities',
  templateUrl: './media-collection-entities.component.html',
  styleUrls: ['./media-collection-entities.component.scss']
})
export class MediaCollectionEntitiesComponent implements OnInit, OnChanges, OnDestroy {


  @Input() collection: ICollection = new Collection;
  @Input() entityType: 'photo' | 'media' | 'product' = 'photo';
  @Input() icon: string = '';
  @Input() showEntities: boolean = false;
  @Output() closeColletion = new EventEmitter<boolean>();

  searchValue: string = '';
  data: IEntityCollection[] = [];

  entityData: IEntity[] = [];

  viewerId: number = 0;
  page: number = 1;
  pageSize: number = 50;
  totaltData: number = 50;

  isLoading: boolean = false;
  isLoggedIn: boolean = false;

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  private _xApi;
  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _artistApi: ArtistApiService,
    private _storeApi: StoreApiService,
    private _playApi: PlayApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // set theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.isLoggedIn = this._authService.loggedIn;

    this.isLoading = true;

    this.viewerId = this.isLoggedIn ? this._authService.currentUser.gid.social.u : 0;
    this.getApi(this.entityType);
  }


  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.showEntities;

    if (this.showEntities) {
      // this.getApi(this.entityType);
    }

  }

  getApi(entityType: string) {


    switch (entityType) {
      case 'photo':
        this._xApi = this._artistApi;
        break;
      case 'media':
        this._xApi = this._playApi;
        break;
      case 'product':
        this._xApi = this._storeApi;
        break;

      default:
        this._xApi = this._artistApi;
        break;
    }

    // check if its private(unpublished) or public (published)
    if (this.collection.status === StatusEnum.UNPUBLISHED) {
      if (!(this.isLoggedIn && this.collection.person.id === this.viewerId)) {
        this._notify.error('This collection is private');
        this.isLoading = false;

        return;
      }
    }



    if (this.showEntities) {
      this.getData();
    }


  }

  getData() {
    this.isLoading = true;

    this.entityData = [];

    // this.activeCollection = item;

    this._xApi.setParams({
      page: 1,
      pageSize: 50,
      searchValue: this.searchValue,
      category: this.collection.id
    })
      .getPageOf(EntityCollection)
      .subscribe(
        {
          next: (res) => {
            this.data = res.data as IEntityCollection[];

            this.data.forEach(element => {
              this.entityData.push(element.entity)
            });

            this.totaltData = res.total;
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onCloseViewCollection() {
    this.showEntities = false;

    setTimeout(() => {
      this.closeColletion.emit(true);
    }, 500);
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
