<div class="p-wrapper" [class.mini]="isMini">
  <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFull]="true" [isFlat]="true"
    [showShadowOnHover]="!isMini" [transparentBg]="isMini" [autoDimBlack]='true'>
    <div class="cover"></div>
    <div class="person">
      <div class="adx-img {{ theme }}"></div>
      <h2 class="name text-normal">
      </h2>
      <div class="username">
        <span class="padding-sm" [class.color-tgreen]="!isMini">

        </span>
      </div>
      <span class="tagline"></span>
      <div>
        <button class="adx-btn adx-round">
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
        </button>
      </div>
    </div>
  </galaxy-adaptive-card>
</div>
