<section class="adx-section bg-dark">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <div class="adx-img">
            <img
              src="assets/images/logo/{{ app }}-logo-galaxy.png"
              alt="Galaxy App Logo"
              class="img-responsive"
            />
          </div>
          <div class="adx-list adx-flat adx-sm">
            <div class="adx-item"><i class="fal fa-play"></i> ABOUT</div>
            <div class="adx-item">
              <i class="fal fa-paper-plane"></i> CONTACT
            </div>
            <div class="adx-item"><i class="fal fa-calendar"></i>EVENTS</div>
          </div>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <div class="adx-divider">Platform</div>
          <div class="adx-list adx-flat adx-sm">
            <div class="adx-item">
              airSuite
            </div>
            <div class="adx-item">
              LyfeShot
            </div>
            <div class="adx-item">
              Galaxy Play
            </div>
            <div class="adx-item">
              Blender Galaxy
            </div>
            <div class="adx-item">
              Christ Galaxy
            </div>
          </div>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <div class="adx-divider">Support</div>
          <div class="adx-list adx-flat adx-sm">
            <div class="adx-item">
              Help center
            </div>
            <div class="adx-item">
              Integration
            </div>
            <div class="adx-item">
              IP whitelist
            </div>
          </div>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <div class="adx-divider">Company</div>
          <div class="adx-list adx-flat adx-sm">
            <div class="adx-item">
              Artist Story
            </div>
            <div class="adx-item">
              Careers
            </div>
            <div class="adx-item">
              Store
            </div>
            <div class="adx-item">
              Blog
            </div>
            <div class="adx-item">
              Forum
            </div>
            <div class="adx-item">
              Subscribe
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="btn-special text-center">
  <a href="#" class="adx-btn adx-icon adx-round btn-default clear">
    <i class="fab fa-facebook"></i>
  </a>
  <a href="#" class="adx-btn adx-icon adx-round btn-default clear">
    <i class="fab fa-instagram"></i>
  </a>
  <a href="#" class="adx-btn adx-icon adx-round btn-default clear">
    <i class="fab fa-twitter"></i>
  </a>
  <a href="#" class="adx-btn adx-icon adx-round btn-default clear">
    <i class="fab fa-youtube"></i>
  </a>
</div>
<div class="adx-section padding bg-dark">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <div class="adx-card adx-flat">
          <div class="">
            <span class="float-left">
              <a href="#" class="adx-btn clear">
                SiteMap
              </a>
              <a href="#" class="adx-btn clear">
                Terms of Use
              </a>
              <a href="#" class="adx-btn clear">
                Privacy Policy
              </a>
            </span>
            <span class="float-right">
              Copyright &copy; 2004 - 2019 Galaxy LLC. All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div class="btn-special" style="height:10px;"></div>
