import { Group, IGroup } from './group.model';
import { Person } from './user.model';
import { IEntity, Entity } from './entity.model';
import { Controller } from '../decorators';
import { AccessEnum, DisclaimerEnum, StatusEnum } from '../../enums';

export interface ICollection {
  id: number;
  entityCount: number;
  person?: Person;
  title: string;
  tag: string;

  access: AccessEnum;
  disclaimer: DisclaimerEnum;
  status: StatusEnum;

  description: string;
  year: number;
  hits: number;

  created?: string;

  genre?: IGroup[];
  thumbnails?: string[];
  // entityId: string | number;
  // date: string;
}

@Controller({ name: 'Collection' })
export class Collection implements ICollection {
  constructor(
    public id = 0,
    public entityCount = 0,
    public title = '',
    public tag = '',
    public person = new Person(),
    public access = AccessEnum.PUBLIC, // public
    public disclaimer = DisclaimerEnum.EVERYONE, // Everyone
    public status = StatusEnum.PUBLISHED, // published
    public description = '',
    public hits = 0, // unpublished
    public year = 2018 // public date = '' // public entityId = '',

  ) { }

}


export interface IEntityCollection {
  id: number;
  collection: ICollection;
  entity: IEntity;
  created: string;
  modified: string;
  status: StatusEnum
}


@Controller({ name: 'EntityCollection' })
export class EntityCollection implements IEntityCollection {
  constructor(
    public id = 0,
    public collection = new Collection,
    public entity = new Entity,
    public created = '',
    public modified = '',
    public status = StatusEnum.PUBLISHED
  ) {
  }
}
@Controller({ name: 'Playlist' })
export class Playlist extends Collection { }
