<div class="poll-container {{theme}}" [class.is-loading]="isLoading" >
  <div *ngFor="let item of poll; index as i" (click)="!showVotes ? onVote($event, item.id, i):null" class="voting-btn"
    [class.show-result]="showVotes">
    <div class="vote-measurement bg-special" [style.width]="!showVotes ? 0 :item.voteCount / totalVotes | percent">

    </div>
    <div class="vote-detail">
      <span class="icon">
        <i class="axl ax-{{item.id === iVoteFor?'checks':'circle'}}"></i>
      </span>
      <span class="label">
        {{ item.title }}
      </span>
      <span class="vote-percentage">
        {{ item.voteCount / totalVotes | percent }}
      </span>
    </div>
  </div>
</div>



<div class="poll-metrics">
  <span class="tag">
    {{ totalVotes }} vote(s)
  </span>
  <span *ngIf="!isPollEnded">&nbsp;.&nbsp;
    <!-- {{ pollEnds * 1000 | amDifference: today:'hours':true | number: 0 }} hours -->
    {{
      (pollEnds * 1000 | amDifference: today:'days':true  | number: '1.0-0') > 0 ?
        (pollEnds * 1000 | amDifference: today:'days':true  | number: '1.0-0') + ' days, ' : ''
    }}
    {{
      ((pollEnds * 1000 | amDifference: today:'hours':true) % 24  | number: '1.0-0') > 0 ?
        ((pollEnds * 1000 | amDifference: today:'hours':true) % 24  | number: '1.0-0') + ' hours, ' : ''
    }}
    {{
      ((pollEnds * 1000 | amDifference: today:'minutes':true) % 60  | number: '1.0-0') > 0 ?
        ((pollEnds * 1000 | amDifference: today:'minutes':true) % 60  | number: '1.0-0')  + ' minutes ' : ''
    }}
    left
  </span>
</div>
