import { Component } from '@angular/core';
import { AppSettingService, FirebaseMessagingService } from '@galaxy/entity-api';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'galaxy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'suite-company';
  message;
  theme = 'dark';

  constructor(
    private _appSetting: AppSettingService,
    private _analytics: Analytics
  ) {
    window.addEventListener('click', this.userClickFunction)
  }



  userClickFunction = () => {
    window.removeEventListener('click', this.userClickFunction);
    //app opened!
    logEvent(this._analytics, 'website_opened');
  }


  ngOnInit() {
    const localTheme = localStorage.getItem('ori.theme');
    this.theme = localTheme ? localTheme : this.theme;
    this._appSetting.setTheme(this.theme);
    // this.messagingService.requestPermission()
  }


}
