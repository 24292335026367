import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-appx',
  templateUrl: './appx.component.html',
  styleUrls: ['./appx.component.scss']
})
export class AppxComponent implements OnInit {

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('light')


    // this.apps = this._appSetting.getDexApps();

    // this.apps = [
    //   {
    //     name: 'airStore',
    //     icon: 'axr ax-shopping-cart'
    //   },
    //   {
    //     name: 'airCare',
    //     icon: 'axr ax-stethoscope'
    //   },
    //   {
    //     name: 'airFinance',
    //     icon: 'axr ax-exchange'
    //   },
    //   {
    //     name: 'airMeet',
    //     icon: 'axr ax-clock'
    //   },
    //   {
    //     name: 'airClient',
    //     icon: 'axr ax-headset'
    //   },
    //   {
    //     name: 'airRent',
    //     icon: 'axr ax-key'
    //   },
    //   {
    //     name: 'airGroup',
    //     icon: 'axr ax-users'
    //   },
    //   {
    //     name: 'airMail',
    //     icon: 'axr ax-mail'
    //   },
    // ]
  }

}
