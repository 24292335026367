import { IGroup, Group } from './group.model';
import { User, Person, ICommunity, Community, ICommunityChannel, CommunityChannel } from './user.model';
import { Ticket, eArtwork, ProductFile } from './artwork.model';
import { FeedPoll } from './poll.model';
import { Controller } from '../decorators';
import { AccessEnum, DisclaimerEnum, EntityTypeEnum, FeedTypeEnum, LicenceEnum, MediaTypeEnum, StatusEnum } from '../../enums';

export interface IEntity {
  // Basic Info
  id: number;
  title: string;
  alias?: string;
  description?: string;
  tag?: string;

  episode?: number;

  year?: number;

  art: string;

  created?: number;
  modified?: number;
  createdLocation?: string;
  person?: Person;
  album?: Entity;
  language?: string;

  status?: StatusEnum;
  access?: AccessEnum;
  type?: EntityTypeEnum;
  topic?: FeedTypeEnum; //for feed

  disclaimer?: DisclaimerEnum

  hit?: number;
  engagement?: number;
  cover?: string;
  likeCount?: number;
  commentCount?: number;
  repostCount?: number;
  rateAvg?: number;
  // optionl
  artwork?: eArtwork[];


  liked?: boolean,
  reposted?: boolean,

  featured?:boolean,

  // store
  intro?: string;
  minOrderCount?: number;
  myOrderCount?: number;
  price?: number;
  discount?: number;
  paymentType?: number;
  licence?: LicenceEnum
  productType?: Group;
  productCategory?: Group;
  file?: ProductFile[];
  // artits
  subject?: number[];
  medium?: Group;
  isComic?: boolean;
  software?: Group[];

  // play
  duration?: string;

  mediaType?: MediaTypeEnum
  mediaCategory?: string;
  genre?: string[];
  staring?: string;
  comment?: string;
  path?: string; // url to media
  linkedMedia?: Entity; // related video clip for music

  useAlbumArt?: boolean;
  useAlbumGenre?: boolean;

  isProcessing?: boolean | number;
  gid: number;

  community?: ICommunity;
  communityChannel?: ICommunityChannel;
}

@Controller({ name: 'Entity' })
export class Entity implements IEntity {
  constructor(
    public id = 0,
    public title = '',
    public alias = '',
    public description = '',
    public tag = '',
    public episode = 0,
    public year = 0,
    public art = '',
    public created = Math.round(new Date().getTime() / 1000),
    public modified = Math.round(new Date().getTime() / 1000),
    public person = new Person(),
    public album = null,
    public language = '',
    public status = StatusEnum.PUBLISHED,
    public access = AccessEnum.EVERYONE,
    public type = EntityTypeEnum.FILE,
    public disclaimer = DisclaimerEnum.EVERYONE,
    public gid = 0,
    public likeCount = 0,
    public commentCount = 0,
    public repostCount = 0,
    public engagement = 0,
    public hit = 0,
    public chapters = 0,

    public community = new Community,
    public communityChannelChannel = new CommunityChannel

  ) {
  }
}

@Controller({ name: 'EntityReaction' })
export class EntityReaction {
  constructor(
    public likeCount = 0,
    public commentCount = 0,
    public repostCount = 0,
    public hit = 0,
    public engagement = 0,
  ) {

  }
}
@Controller({ name: 'Feed' })
export class Feed extends Entity implements IEntity {
  // artwork  = new eArtwork;
  public topic = FeedTypeEnum.IDEA;
  public poll: FeedPoll[];
  public pollLength?: { days: number, hours: number, minutes: number };
  public pollEnds: number;


}
@Controller({ name: 'Project' })
export class Project extends Entity implements IEntity {
  // artwork  = new eArtwork;
}
@Controller({ name: 'Album' })
export class Album extends Entity implements IEntity { }
@Controller({ name: 'Blog' })
export class Blog extends Entity implements IEntity { }
@Controller({ name: 'Forum' })
export class Forum extends Entity implements IEntity { }
@Controller({ name: 'EventEntity' })
export class EventEntity extends Entity implements IEntity {
  public startTime = Math.round(new Date().getTime());
  public endTime = Math.round(new Date().getTime());
  public location: string = 'Accra';
  public findUs: string = '';
  public link: string = '';
  public tickets: Ticket[];

  public liked = false;
  public reposted = false;

}
@Controller({ name: 'Media' })
export class Media extends Entity implements IEntity {
  public duration = ''

  public mediaType = MediaTypeEnum.AUDIO;// 46; //audio 47-video
  public mediaCategory = ''
  public staring = ''
  public comment = ''
  public path = '' // url to media
  public linkedMedia?: Entity; // related video clip for music
}
@Controller({ name: 'Product' })
export class Product implements IEntity {


  constructor(
    public id = 0,
    public title = '',
    public alias = '',
    public intro = '',
    public description = '',
    public tag = '',
    public art = '',
    public subject = [],
    public minOrderCount = 1,
    public myOrderCount = 1,
    public price = 0.00,
    public discount = 0.00,
    public paymentType = 1,
    public created = Math.round(new Date().getTime() / 1000),
    public modified = Math.round(new Date().getTime() / 1000),
    public person = new Person(),
    public language = '',
    public status = StatusEnum.PUBLISHED,
    public access = AccessEnum.EVERYONE,
    public type = EntityTypeEnum.FILE,
    public disclaimer = DisclaimerEnum.EVERYONE,
    public licence = LicenceEnum.CREATIVE_COMMON,
    public productCategory = new Group,
    public productType = new Group,
    public gid = 0,
    public likeCount = 0,
    public commentCount = 0,
    public repostCount = 0,
    public orderCount = 0,
    public isDigital = false,
    public liked = false,
    public reposted = false,
    public artwork = [],
    public file = [],

    public community = new Community,
    public communityChannelChannel = new CommunityChannel
  ) {
  }
}
