<div
  class="cover"
  [ngClass]="{
    'bg-white': theme === 'light',
    'bg-darker': theme === 'dark'
  }"
>
  <!-- <button class="adx-btn adx-round" routerLink="/setup/interest">interest</button>
  <button class="adx-btn adx-round" routerLink="/setup/discover">discover</button> -->
  <section class="adx-section">
    <div class="wrapper">
      <div class="adx-row">
        <div class="adx-colx-12">
          <div class="center-box">
            <galaxy-adaptive-card>
              <div class="text-center">
                <div
                  class="adx-logo"
                  [routerLink]="['/']"
                  style="cursor: pointer; position: relative; z-index: 100;"
                >
                  <img [src]="avatar" alt="" />
                </div>
              </div>

              <h3 class="text-normal">
                Two-Way Authentication
              </h3>
              <p>
                Verification code has been sent to your email, please enter the
                code below
              </p>

              <div class="adx-input adx-round">
                <input type="text" inputmode="numeric" />
              </div>
              <p>
                If you did not recieve the confirmation code. Please wait for
                some seconds and click
                <button
                  (click)="onResendCode()"
                  class="adx-btn adx-flat clear btn-blue"
                >
                  Resend Code
                </button>
              </p>

              <div class="text-left">
                <button
                  (click)="onCancel()"
                  class="adx-btn adx-round btn-default"
                >
                  Cancel
                </button>

                <button
                  (click)="onVerify()"
                  class="adx-btn adx-round btn-tgreen float-right"
                >
                  Verify Code
                </button>
              </div>
            </galaxy-adaptive-card>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
