<galaxy-adaptive-card [classes]="'adx-table adx-sm'" [shape]="'rounded'" [miniPadding]=true [autoFull]=true
  [isFlat]="true">
  <!-- data here -->
  <table [class]="theme">
    <tr *ngFor="let item of data">
      <td>
        <div style="display: flex;">
          <galaxy-entity-image [image]="item.product.art"></galaxy-entity-image>
          <div>
            {{ item.product.name }}
            <br />
            <small> #: {{ item.product.code }} </small>
          </div>
        </div>
      </td>
      <td>
        <button class="adx-btn adx-rounder btn-tgreen adx-flat disabled adx-icon">
          {{ item.point }}
        </button>
      </td>
      <td>
        <small>
          <i class="fa fa-circle-notch color-blue"></i>
          Cat.: {{ item.product.category | enumKey:productCategoryEnum | maxLength: 30 }}
        </small>

        <br />
        <small>
          <i class="fal fa-long-arrow-right" style="opacity: 0.3;"></i>
          <!-- {{ item.city }}, {{ item.country }} -->
          Type: {{ item.product.type | enumKey:productTypeEnum | maxLength: 30 }}
        </small>
      </td>

      <td>
        <small>
          {{ storePriceType==='stock'?item.stocks.packs +
          item.packs:item.stocks.packs - item.packs}} Packs
        </small>
        <br />
        <small>
          <!-- {{ item.city }}, {{ item.country }} -->
          {{ storePriceType==='stock'?item.stocks.units +
          item.units:item.stocks.units - item.units}} Units
        </small>
      </td>
      <td>
        <b class="color-pink">
          {{onSetPrice(item.prices[0], storePriceType) | currency:currency}}
        </b>
      </td>
      <td class="action">
        <app-action-btns>
          <div class="adx-btn-group adx-round">

            <button title="add to cart" class="adx-btn adx-icon adx-flat clear" (click)="onAddToCart(item)">
              <i class="axl ax-cart-plus color-pink"></i>
            </button>
            <!--
          <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear"
            (click)="onDetailRoute(btnItem.route + item.id, btnItem.title)">
            <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
          </button> -->
          </div>
        </app-action-btns>
      </td>
    </tr>
  </table>

  <!-- data ends -->
  <app-no-data *ngIf="data.length === 0" [label]="'Products'"></app-no-data>
  <ng-content></ng-content>
</galaxy-adaptive-card>
