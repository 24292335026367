import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  platformShowcase: {
    title: string,
    data: {
      label: string,
      description: string,
      image: string,
      link: string,
      isExternal: boolean
    }[]
  }[] = [
      {
        title: 'Connect Social Platform',
        data: [
          {
            label: '🤣 Communities',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://connect.aiira.co/communities',
            isExternal: true
          },
          {
            label: '🛒 Online Store',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://connect.aiira.co/store',
            isExternal: true
          },
          {
            label: '🎵 Stream Music & Movies',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://connect.aiira.co/play',
            isExternal: true
          },
          {
            label: 'News & Articles',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://connect.aiira.co/communities',
            isExternal: true
          }
        ]
      },
      {
        title: 'Qompass: Interactive Map',
        data: [
          {
            label: 'Business Directory',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://qompass.aiira.co/communities',
            isExternal: true
          },
          {
            label: 'Accomidation & Renting',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://qompass.aiira.co/communities',
            isExternal: true
          },
          {
            label: 'Services',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://qompass.aiira.co/communities',
            isExternal: true
          },
          {
            label: 'Delivery',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: 'https://qompass.aiira.co/communities',
            isExternal: true
          },
          {
            label: 'And More',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/business',
            isExternal: false
          }
        ]

      },
      {
        title: 'airSuite: Business Tools',
        data: [
          {
            label: 'Financial Management System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: 'HR System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: 'School Management System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: 'Hospital Management System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: 'Laboratory Management System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: 'Delivery Management System',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          },
          {
            label: '10+ tools for every business',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/suite',
            isExternal: false
          }
        ]
      },
      {
        title: 'Interverse Engine',
        data: [
          {
            label: 'Select/Build Mode',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/studio',
            isExternal: false
          },
          {
            label: 'Material Editor',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/studio',
            isExternal: false
          },
          {
            label: 'Visual Scripting',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/studio',
            isExternal: false
          },
          {
            label: 'Landscape Editor',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/studio',
            isExternal: false
          },
          {
            label: 'And More',
            description: 'Create/Join a Community on Connect',
            image: '',
            link: '/studio',
            isExternal: false
          }
        ]

      }
    ]

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('dark')
  }

}
