<galaxy-adaptive-card [classes]="'adx-table adx-sm'" [miniPadding]="true" [shape]="'rounded'" [autoFull]=true
  [isFlat]="true">
  <!-- data here -->
  <table [class]="theme">
    <tr *ngFor="let item of data">
      <td>
        <div style="display: flex">
          <!-- <div
            class="adx-icon-letter"
            style="background-color: royalblue;"
          >
            {{ item.name[0] }}
          </div> -->
          <galaxy-entity-image [image]="item.image" [isRound]="true"></galaxy-entity-image>
          <div>
            {{ item.name | maxLength: 32 }}
            <br />
            <small class="highlight bg-black" style="color:white"> ID: {{ item.personId }} </small>
          </div>
        </div>
      </td>
      <td class="remove-xs">
        <i class="axl ax-calendar color-blue"></i>
        {{ today | amDifference: (item.birthdate * 1000) :'years'}} years

        <br />
        <small>
          <i class="axl ax-chevron-right" style="opacity: 0.3"></i>
          <!-- {{ item.city }}, {{ item.country }} -->
          {{ item.birthdate * 1000 | date }}
        </small>
      </td>
      <td [class.remove-xs]="accountType==1">
        <i class="axl ax-phone color-blue"></i> {{ item.phoneOne }}
        <br />
        <small>
          <i class="axl ax-chevron-right" style="opacity: 0.3"></i>
          {{ item.email | maxLength: 20 }}
        </small>
      </td>
      <td *ngIf="accountType == 1">
        <span class="adx-highlight" [ngClass]="{
          'bg-yellow': item.status ===1,
          'bg-tgreen': item.status ===2,
          'bg-pink': item.status ===4,
          }" style="color:black">
          {{item.status | enumKey:employeeStatusEnum}}
        </span>
      </td>
      <td class="action">
        <app-action-btns>
          <div class="adx-btn-group adx-round">
            <button *ngIf="!(item.businessEntityId == 7)" class="adx-btn adx-icon adx-flat clear"
              (click)="onDetailRoute('edit/' + item.id, 'Edit Person Info')">
              <i class="axl ax-edit"></i>
            </button>
            <!-- <button class="adx-btn adx-icon adx-flat clear" (click)="onDetailRoute('edit/' + item.id, 'Print Id Card')">
            <i class="axl ax-id"></i>
          </button> -->
            <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear" (click)="
              onDetailRoute(
                btnItem.route + (btnItem.objectName ? '' : item.businessEntityId),
                btnItem.title,
                btnItem.objectName,
                item
              )
            ">
              <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
            </button>
          </div>
        </app-action-btns>
      </td>
    </tr>
  </table>


</galaxy-adaptive-card>

<ng-content></ng-content>
