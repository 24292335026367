import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adx-quick-stat',
  templateUrl: './ad-quick-stat.component.html',
  styleUrls: ['./ad-quick-stat.component.scss']
})
export class AdQuickStatComponent implements OnInit {

  @Input() data: { title: string, icon: string, color: string, count: number }[] = [];

  constructor() { }

  ngOnInit() {
  }

}
