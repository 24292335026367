import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'adx-gallery-grid',
  templateUrl: './gallery-grid.component.html',
  styleUrls: ['./gallery-grid.component.scss']
})
export class GalleryGridComponent implements OnInit {
  data: any[] = [];

  @Input() app: string = '';

  theme: string = '';
  color: string = '';

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;

    this.data = [
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 1,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 2,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 3,
        title: 'Track Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 5,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 62
      },
      {
        id: 6,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      },
      {
        id: 4,
        title: 'Video Title',
        artist: 'Artist Name Here',
        album: 'Track Album',
        mediaTypeId: 63
      }
    ];
  }
}
