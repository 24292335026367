<div class="adx-track" [class.responsive]="isResponsive" (click)="onPlayClicked()">
  <div class="track-flex {{ size }} {{
      data.mediaType === 3 ? 'video' : 'music'
    }}" [ngClass]="{
      'bg-dark': theme == 'dark',
      'bg-white': theme == 'light',
      transparent: isTransparent
    }">
    <div class="adx-index" *ngIf="index != null">{{ index + 1 }}</div>
    <!--
      <div class="person" *ngIf="size === 'md'">
        <div class="adx-img bg-white" style="background-color: black;">
          <img [src]="avatar" alt="" />
        </div>
      </div>
    -->
    <div class="adx-img" style="background-color: black;">
      <img [lazyLoad]="coverArt" alt="" />
      <div class="playing-status" [class.active]="isPlaying">
        <!-- <i class="axl ax-{{ isLoading ? 'spinner ax-spin' : 'play' }}"></i> -->
      </div>
    </div>
    <div class="detail">
      <h2 [class.text-size24]="size === 'md'">
        {{ data.title | maxLength: 20 }}
      </h2>
      <p>
        <span>
          <i class="fa fa-user-circle"></i>
          {{ data.person?.username || 'Artist' }}
        </span>
        <span class="album-name" *ngIf="size === 'md'">
          <small>
            {{ data.album?.title || 'Single' }} &nbsp;|&nbsp;
            <span> <i class="fal fa-eye"></i> {{ data.hit }} </span> &nbsp;
            <br />
            <small class="tag"> Released On {{ data.year }} </small>
          </small>
        </span>
      </p>
    </div>

    <button *ngIf="showBookmark" class="adx-btn adx-shadows adx-round adx-icon adx-round adx-flat color-tgreen bookmark-btn {{
        size
      }}" [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-default': theme == 'light'
      }" (click)="onAddPlaylist($event, data)">
      <i class="axr ax-bookmark"></i>
    </button>
    &nbsp;
  </div>
</div>
