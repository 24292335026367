<header class="adx-header header-fixed adx-flat top-header {{ navTopClass }}" [ngClass]="{
    'bg-dark': theme == 'dark',
    'bg-white': theme == 'light',
    clear: innerWidth < 512 && scrollY < 5
  }">
  <div class="wrapper-fluid">
    <div class="adx-row">
      <div class="adx-cols-12">
        <!-- header grid -->
        <div class="header-grid" [class.showing-mobile-search]="showMobileSearch">
          <div class="left-header bg-primx">
            <div class="adx-logo" routerLink="/" (contextmenu)="$event.preventDefault(); showSideMenu = true"
              [class.adx-invert]="theme === 'light'">
              <img src="assets/images/logo/artist-logo-color.png" class="img-responsive" alt="" />
            </div>
            <div class="search remove-xs">
              <div class="adx-input adx-round adx-icon input-pink remove-xs" style="transform: scale(0.9)">
                <span> <i class="axl ax-search"></i> </span>
                <input type="search" placeholder="Search for {{ defaultSearchOption.placeholder }}"
                  (keydown.enter)="onSearch()" [(ngModel)]="searchQuery" name="searchQuery" />
              </div>
            </div>
            <!--
              <div class="search-option">
                <galaxy-search-options
                  (optionSelected)="onSearchOptionChanged($event)"
                ></galaxy-search-options>
              </div>
            -->
          </div>
          <div class="menu text-center bg-yellowx">
            <button [routerLink]="['/home']" data-title='Home' routerLinkActive="btn-pink active"
              class="adx-btn adx-icon adx-rounder clear adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-smart-home"></i>
              <!-- <small class="notice empty bg-pink" *ngIf="newFeed"> </small> -->
            </button>
            <!--
              <button class="adx-btn adx-icon adx-rounder adx-flat">
                <i routerLinkActive="fa" class="far fa-star"></i>
              </button>
            -->
            <button *ngIf="isLoggedIn" [routerLink]="['/community']" data-title='Community'
              routerLinkActive="btn-pink active" class="adx-btn clear adx-icon adx-rounder adx-flat remove-sm"
              [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-chart-bubble"></i>
            </button>

            <button [routerLink]="['/media']" data-title='Media' routerLinkActive="btn-pink active"
              class="adx-btn clear adx-icon adx-rounder adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-play"></i>
            </button>
            <button [routerLink]="['/artwork']" data-title='Photos' routerLinkActive="btn-pink active"
              class="adx-btn clear adx-icon adx-rounder adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-photo"></i>
            </button>
            <!-- avatar -->
            <button *ngIf="isLoggedIn && innerWidth > 512" (click)="onProfileClicked()"
              class="avatar adx-btn adx-icon adx-round shine-pink" [ngClass]="{
                'clear adx-flat': innerWidth <= 512,
                'btn-dark': theme == 'dark',
                'btn-grey': theme == 'light'
              }">
              <img [src]=" smAvatar" alt="" *ngIf="innerWidth > 512" />


            </button>

            <!-- menu -->
            <!-- <button *ngIf="isLoggedIn && innerWidth <= 512" (click)="onProfileClicked()"
              class="avatar adx-btn adx-icon adx-round adx-flat clear shine-pink">
              <span>
                <i class="axr ax-menu-2">
                </i>
              </span>
            </button> -->

            <button *ngIf="!isLoggedIn" [routerLink]="['/login']" class="avatar adx-btn adx-icon adx-rounder "
              [ngClass]="{
                'btn-grey ': theme === 'light',
                'btn-dark bg-black': theme === 'dark'
              }">
              <!-- <img [src]="smAvatar" alt="" /> -->
              <i class="axl ax-user" style="opacity: 0.4"></i>
            </button>
            <span class="page-title ">
              <h3 class="title text-normal show-xxs text-size14" style=" translate: -10px 0;">
                <b style="font-weight: 300; font-size: 1.5rem;">
                  {{
                  defaultSearchOption.name === 'People'
                  ? 'Home'
                  : defaultSearchOption.name
                  }}
                </b>
              </h3>
            </span>


            <button [routerLink]="['/events']" data-title='Events' routerLinkActive="btn-pink active"
              class="adx-btn clear adx-icon adx-rounder adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-calendar-event"></i>
              <small class="notice empty bg-pink" *ngIf="isEventNear"> </small>
            </button>
            <button [routerLink]="['/store']" data-title='Store' routerLinkActive="btn-pink active"
              class="adx-btn adx-icon clear adx-rounder adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-shopping-bag"></i>
            </button>
            <button [routerLink]="['/blogs']" data-title='Blogs' routerLinkActive="btn-pink active"
              class="adx-btn clear adx-icon adx-rounder adx-flat remove-xs" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-blog"></i>
            </button>
            <button *ngIf="isLoggedIn" [routerLink]="['/messages']" data-title='Messages'
              routerLinkActive="btn-pink active" class="adx-btn clear adx-icon adx-rounder adx-flat remove-sm"
              [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-message-2"></i>
              <small class="notice bg-pink" *ngIf="msgCount > 0">{{
                msgCount
                }}</small>
            </button>
          </div>

          <div class="text-right bg-pinkx">
            <button *ngIf="!isLoggedIn" [routerLink]="['/login']" routerLinkActive="btn-pink active"
              class="adx-btn adx-round btn-grey" [ngClass]="{
                'btn-dark': theme == 'dark',
                'btn-grey': theme == 'light'
              }">
              Sign In
            </button>
            <!-- contrast -->
            <button *ngIf="profileMenu.showThemeSwitch" (click)="onToggleTheme()"
              class="adx-btn adx-flat adx-round adx-icon clear remove-sm" [ngClass]="{
                'btn-dark': theme == 'light',
                'btn-grey': theme == 'dark'
              }">
              <i class="axl" [ngClass]="{
                  'ax-bulb': theme == 'light',
                  'ax-bulb-off': theme == 'dark'
                }"></i>
            </button>
            <!-- search button -->
            <button class="adx-btn adx-flat adx-icon adx-rounder clear show-xs" [ngClass]="{
                'btn-dark': theme == 'light',
                'btn-grey': theme == 'dark'
              }" (click)="showMobileSearch = true">
              <i class="axl ax-search"></i>
            </button>
            <!-- message -->
            <button *ngIf="isLoggedIn" [routerLink]="['/messages']" routerLinkActive="btn-pink active"
              class="adx-btn clear adx-icon adx-rounder adx-flat show-sm" [ngClass]="{
                'btn-default': theme == 'dark',
                'btn-dark': theme == 'light'
              }">
              <i routerLinkActive="axr" class="axl ax-message-2"></i>
              <small class="notice bg-pink" *ngIf="msgCount > 0">{{
                msgCount
                }}</small>
            </button>
            <!-- cart button -->
            <button class="adx-btn adx-flat adx-icon adx-rounder clear remove-xs" [ngClass]="{
                'btn-dark': theme == 'light',
                'btn-grey': theme == 'dark'
              }" [routerLink]="['/cart']" routerLinkActive="btn-pink active">
              <i routerLinkActive="axr" class="axl ax-shopping-cart"></i>
              <small class="notice bg-pink" *ngIf="cartCount > 0">{{
                cartCount
                }}</small>
            </button>

            <!-- menu button -->
            <!-- <button
              class="adx-btn adx-flat adx-icon adx-rounder clear show-xxs"
              [ngClass]="{
                'btn-dark': theme == 'light',
                'btn-grey': theme == 'dark'
              }"
            >
              <i class="far fa-stream"></i>
            </button> -->
            <!-- dropdown -->
            <div class="adx-dropdown remove-xxs">
              <button class="adx-btn adx-flat adx-icon adx-rounder adx-trigger clear" [ngClass]="{
                  'btn-dark': theme == 'light',
                  'btn-grey': theme == 'dark'
                }">
                <span class="remove-xxs">
                  <i class="axl ax-settings"></i>
                </span>
                <span class="show-xxs">
                  <i class="axl ax-ellipsis-v"></i>
                </span>
              </button>
              <div class="adx-content text-left" style="width: 210px; left: -30px">
                <galaxy-adaptive-card [isBlur]="true" [showPadding]="false" [shape]="'rounded'">
                  <div class="adx-list adx-flat adx-sm">
                    <!-- Content Manager Start -->
                    <!--
                      <div *ngIf="isLoggedIn">
                        <div class="adx-divider">Content Manager</div>
                        <div class="text-center">
                          <button
                            class="adx-btn adx-icon btn-pink  outline adx-rounder"
                            *ngFor="let item of newOptions"
                            [routerLink]="[item.route]"
                            [title]="item.name"
                          >
                            <i class="fa fa-{{item.icon}}"></i>
                          </button>
                        </div>
                        <hr />
                      </div>
                    -->
                    <!-- Content Manager Ends -->
                    <div *ngIf="profileMenu.showCollection" class="adx-item adx-icon" [routerLink]="['/collection']">
                      <span> <i class="axl ax-bookmark"></i> </span> Save
                      Collection
                    </div>
                    <div *ngIf="profileMenu.showCart" class="adx-item adx-icon adx-badge" [routerLink]="['/cart']">
                      <span> <i class="axl ax-shopping-cart"></i> </span> My
                      Cart
                      <span class="badge color-pink" *ngIf="cartCount > 0">
                        {{ cartCount }}</span>
                    </div>
                    <div *ngIf="profileMenu.showCart" class="adx-item adx-icon adx-badge" [routerLink]="['/orders']">
                      <span> <i class="axl ax-list"></i> </span> My
                      Orders

                    </div>
                    <div *ngIf="profileMenu.showSubscription" class="adx-item adx-icon"
                      (click)="onShowMiniSocial('messages')">
                      <span> <i class="axl ax-dollar-sign"></i> </span>
                      Subscriptions
                    </div>

                    <div *ngIf="header.showUpload && isCurator" [routerLink]="['/studio']"
                      routerLinkActive="router-link-active" class="adx-item adx-icon"
                      (click)="onShowMiniSocial('messages')">
                      <span> <i class="axl ax-play"></i> </span> Creator Studio
                    </div>
                  </div>
                </galaxy-adaptive-card>
                <div class="adx-list adx-ellipse adx-shadows adx-sm" style="padding:8px; padding-bottom: 0;">
                  <div (click)="onSwitchAccount()" class="adx-item adx-icon adx-flat"
                    [ngClass]="{'bg-dark': theme==='dark', 'bg-white': theme === 'light'}">
                    <span> <i class="axl ax-users"></i> </span> Switch Account
                  </div>
                  <div (click)="onLogOut()" class="adx-item adx-icon adx-flat"
                    [ngClass]="{'bg-dark': theme==='dark', 'bg-white': theme === 'light'}">
                    <span> <i class="axl ax-logout"></i> </span> Logout
                  </div>
                </div>

                <!-- <hr /> -->
                <!--
                      <div
                        class="adx-item adx-icon"
                        (click)="onShowMiniSocial('messages')"
                      >
                        <span> <i class="fa fa-language"></i> </span> Language:
                        English
                      </div>
                    -->

                <!--
                      <div
                        class="adx-item adx-icon"
                        (click)="onShowMiniSocial('messages')"
                      >
                        <span> <i class="fa fa-comment"></i> </span> Send
                        Feedback
                      </div>
                    -->

                <!-- <hr /> -->

                <!--
                      <div
                        class="adx-item"
                        (click)="onShowMiniSocial('messages')"
                      >
                        Location: Ghana
                      </div>

                      <div
                        class="adx-item"
                        (click)="onShowMiniSocial('messages')"
                      >
                        Restricted Mode
                      </div>
                    -->

                <div style="display: flex; margin-top:-4px;">
                  <button class="adx-btn adx-ellipse adx-icon adx-sm adx-flat"
                    [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}"
                    *ngIf="profileMenu.showThemeSwitch" (click)="onToggleTheme()">
                    <span>
                      <i class="axl ax-{{
                              theme === 'dark'
                                ? 'moon color-tgreen'
                                : 'sun color-pink'
                            }}"></i>
                    </span>
                    <!-- <b style="text-transform: capitalize"> {{ theme }} </b>
                    Theme On -->
                  </button>

                  <button class="adx-btn adx-ellipse adx-icon adx-sm adx-flat"
                    [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}"
                    [routerLink]="['/setting']">
                    <span> <i class="axl ax-settings"></i> </span>
                    <!-- Settings -->
                  </button>

                  <a class="adx-btn adx-ellipse adx-icon adx-sm adx-flat"
                    [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}"
                    href="https://aiira.co/support" target="_blank">
                    <span> <i class="axl ax-help"></i> </span>
                    <!-- Help Center -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- search grid -->
        <div class="mobile-search show-xs" *ngIf="showMobileSearch">
          <div class="search-grid">
            <div class="adx-input adx-round adx-icon input-pink" style="transform: scale(0.9)">
              <span> <i class="axl ax-search"></i> </span>
              <input type="text" placeholder="Search for {{ defaultSearchOption.placeholder }}"
                (keyup.enter)="onSearch()" [(ngModel)]="searchQuery" name="searchQueryMobile" />
            </div>
            <div class="text-center bg-pinkx">
              <button class="adx-btn adx-flat clear adx-round" (click)="showMobileSearch = false">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="adx-header fill-header" [class.adx-flat]="innerWidth < 512"></div>

<!-- small screen header -->
<div class="show-xs text-center" (dblclick)="showSideMenu=true" (contextmenu)="showSideMenu=true"
  (click)='onScrollToTop()'>
  <div class="adx-header adx-shadows bottom-menu xshow-menu-name {{ navTopClass }}" [ngClass]="{
      'bg-dark': theme == 'dark',
      'bg-white': theme == 'light'
    }">
    <div class="adx-btn-group adx-round" [ngClass]="{
      'bg-darker bg-black': theme == 'dark',
      'bg-smoke': theme == 'light'
    }">

      <button [routerLink]="['/home']" routerLinkActive=" active" class="adx-btn adx-iconX adx-round clear adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-smart-home"></i>
        <!-- <small class="notice empty bg-pink" *ngIf="newFeed"> </small> -->
      </button>

      <button [routerLink]="['/media']" routerLinkActive=" active" class="adx-btn clear adx-iconX adx-round adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-play"></i>
      </button>

      <button [routerLink]="['/artwork']" routerLinkActive=" active" class="adx-btn clear adx-iconX adx-round adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-photo"></i>
      </button>

      <button [routerLink]="['/events']" routerLinkActive=" active" class="adx-btn clear adx-iconX adx-round adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-calendar-event"></i>
        <small class="notice empty bg-pink" *ngIf="isEventNear"> </small>
      </button>


      <button [routerLink]="['/store']" routerLinkActive=" active" class="adx-btn adx-iconX clear adx-round adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-shopping-bag"></i>
        <small class="notice bg-pink" *ngIf="cartCount > 0">
          {{ cartCount }}</small>
      </button>

      <button [routerLink]="['/blogs']" routerLinkActive=" active" class="adx-btn clear adx-iconX adx-round adx-flat"
        [ngClass]="{
            'btn-default': theme == 'dark',
            'btn-dark': theme == 'light'
          }">
        <i routerLinkActive="axr" class="axl ax-blog"></i>
      </button>
    </div>

  </div>
</div>






<div class="overlay" *ngIf="showSideMenu" (click)="onCloseSideMenu(false)"></div>



<!-- side-menu -->
<div class="side-menu" [class.show-side-menu]="showSideMenu" (click)="onCloseSideMenu(false)">
  <galaxy-adaptive-card [shape]="'rounded'" [showPadding]="false" style="height: 100vw">
    <div class="padder">

      <div class="profile-info" [class.dark]=" theme == 'dark'">

        <div class="adx-img" (click)="onShowViewerProfile()">
          <img [src]="smAvatar" alt="" />
        </div>
        <h2 class="text-normal" (click)="onShowViewerProfile()">
          {{ userInfo.name }}
        </h2>
        <p>&#64;{{ userInfo.username }}</p>
        <!-- <div> -->
        <button [routerLink]="['/setting/']" class="adx-btn adx-round  btn-special">
          Account Settings
        </button>

      </div>


      <galaxy-ori-shortcut [isMini]="true" [theme]="theme"></galaxy-ori-shortcut>

      <!-- <div class="adx-divider">Content Manager</div>
        <div class="adx-list adx-flat">
          <div *ngFor="let item of newOptions" class="adx-item" [routerLink]="[item.route]"
            [class.not-allowed]="!item.allowed">
            <i [class]="item.icon"></i>
            My {{ item.name }}
          </div>
        </div> -->

      <div style="flex:1"></div>

      <!-- <div class="adx-divider">Setting Manager</div> -->
      <div class="adx-list adx-flat">
        <a href="https://aiira.co/support" target="_blank">
          <div class="adx-item">
            <i class="axr ax-help"></i>
            Help Center
          </div>
        </a>
        <div class="adx-item">
          <i class="axr ax-shield"></i>
          Terms & Conditions
        </div>
      </div>

      <div class="card-list " [class.dark]=" theme == 'dark'">
        <button (click)="onSwitchAccount()" class=" card-item">
          &nbsp;
          <i class="axl ax-users"></i>
          &nbsp;
          <span>
            Switch Users
          </span>
        </button>
        <button (click)="onLogOut()" class=" card-item">
          &nbsp;
          <i class="axl ax-logout"></i>

          &nbsp;
          <span>
            Logout
          </span>
        </button>
        <button (click)="onToggleTheme()" class=" card-item flex-1">
          &nbsp;
          <i class="axl" [ngClass]="{
            'ax-bulb': theme == 'light',
            'ax-bulb-off': theme == 'dark'
          }"></i>
          &nbsp;
          <span>
            Toggle Theme
          </span>
        </button>
      </div>

    </div>
  </galaxy-adaptive-card>
</div>
