import { Component, OnInit, Input, SecurityContext, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AppSettingService, IEntity, ICollection, EmbedService, Collection } from '@galaxy/entity-api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-folder',
  templateUrl: './media-folder.component.html',
  styleUrls: ['./media-folder.component.scss']
})
export class MediaFolderComponent implements OnInit, OnDestroy {
  // if takeUntil, 6 images are displayed, else 3 images
  @Input() isMasonry: boolean = true;
  @Input() showEditBtn: boolean = false;
  @Input() isEven: boolean = false;
  @Input() index: number = 1;
  @Input() data: ICollection = new Collection;
  @Input() theme: string = 'dark';themeUpdated$!: Subscription;
  @Input() showThumbnailSimple: boolean = true;
  numberOfImagesToShow: number = 3;

  @Output() editCollection = new EventEmitter<ICollection>();

  imageData: string[] = [];
  placeHolderData: number[] = [];

  defaultImage = '/assets/images/default-image.jpg';

  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    // set theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // process number of images to show
    this.numberOfImagesToShow = this.showThumbnailSimple ? 3 : 6;


    this.data.thumbnails.forEach(element => {

      if (this.imageData.length < this.numberOfImagesToShow) {
        this.imageData.push(
          this._sanitizer.sanitize(
            SecurityContext.URL, this._embed.imgUrl(element, 'md')
          )
        );
      }


    });



    const imageDataCount = this.imageData.length;
    const placeHolderDataCount = this.numberOfImagesToShow - imageDataCount;

    this.isEven = this.index % 2 === 0;

    for (let index = 0; index < placeHolderDataCount; index++) {
      this.placeHolderData.push(imageDataCount + index);

    }

    // console.log('image Data from media-folder component', this.imageData);


  }

  onEditClicked($event) {
    $event.stopPropagation();
    this.editCollection.emit(this.data);
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
