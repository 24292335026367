import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@galaxy/ui';

import { SetupModule } from './setup/setup.module';
import { LoginRoutes } from './login.router';
import {
  UserService,
  AppSettingService,
  AuthService,
  IdentityApiService,
} from '@galaxy/entity-api';

import { LoginComponent } from './login/login.component';

// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider,
//   AmazonLoginProvider,
// } from 'angularx-social-login';

import { ColdBootComponent } from './cold-boot/cold-boot.component';
import { PwaNotiComponent } from './pwa-noti/pwa-noti.component';
import { TwoWayAuthComponent } from './two-way-auth/two-way-auth.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { Title } from '@angular/platform-browser';

// Configs
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    UiModule,
    SetupModule,
    // SocialLoginModule,
    RouterModule.forRoot(LoginRoutes, {
      scrollPositionRestoration: 'enabled'
    }),
    // provideAuth(() => getAuth()),

  ],
  declarations: [
    LoginComponent,
    ColdBootComponent,
    PwaNotiComponent,
    TwoWayAuthComponent,
    ConnectionStatusComponent,
    PrivacyComponent,
    TermsConditionsComponent
  ],
  exports: [
    PwaNotiComponent,
    ConnectionStatusComponent
  ],
  providers: [
    AuthService,
    UserService,
    IdentityApiService,
    AppSettingService,
    Title
  ]
})
export class LoginModule { }
