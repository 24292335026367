<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">
              Privacy Policy <br />
              <small> Definitions </small>
            </h1>

            <p style="font-size: 17px">
              At Aiira Inc. ; we recognize your privacy is important. This
              Policy explains the Company’s privacy practices on its Website
              (the “Site”) and Applications. This Policy also explains what
              information we collect about our customers and how we use this
              information. This Privacy Policy is designed to be read in
              connection with the Site’s Terms of Use. By accessing or using our
              Site, you agree to be bound by the Terms of Use and this Privacy
              Policy.
            </p>

            <p style="font-size: 17px">
              For the purposes of clarity, “we,” “us,” “our,” the ”Company,” and
              Aiira,” refers to Aiira Inc. The “Services” refers to Aiira's
              Social & Business Platform. “User,” “customer,” or “subscriber,”
              refers to consumers of the service. “Personal Information,”
              “Personal Data,” or “Data” means any information that identifies
              or can be used to identify a user, directly or indirectly,
              including, but not limited to, name, email address, mobile number,
              or IP address.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Data Processing</h1>

            <p style="font-size: 17px">
              All information supplied by Users of Aiira as defined under the
              Terms of Use is covered by the Data Protection Act of 1998, and
              the General Data Protection Regulation of 2018.
            </p>
            <p style="font-size: 17px">
              Voluntarily Submitted Data: When you sign up for Aiira ID, pay for
              a subscription, consult with our customer service team, send us an
              email, post on our blog, comment on a video, or communicate with
              us in any way, you are voluntarily giving us information that we
              process, including, but not limited to; name, username, email
              address, mobile number, IP address, credit card information, bank
              information, and purchase history. By submitting this information,
              you consent to its’ collection, usage, disclosure, and storage by
              us, as described in our Terms of Use and in this Privacy Policy.
            </p>
            <p style="font-size: 17px">
              Automatically Collected Data: When you use the Services or browse
              one of our Websites or Applications, we may collection information
              about your visit, your usage of the Services, and/or your web
              browsing; which may include your IP address, operating system,
              device type, operating system, browser ID, browsing activity, and
              other information about how you interacted with our Websites or
              Applications. We may collect this information as a part of log
              files as well as through the use of cookies or other tracking
              technologies. Our use of cookies is discussed more below.
            </p>
            <p style="font-size: 17px">
              Service Usage Data: We may receive information about how and when
              you use the Services, store it in log files or other types of
              files associated with your account, and link it to other
              information we collect about you. This information may include,
              for example, your IP address, time, date, browser used, and
              actions you have taken within the Website or Applications. This
              type of information helps us to improve our Services for both you
              and for all of our users.
            </p>

            <p style="font-size: 17px">
              Mobile Application Data: When you use our Mobile Applications, we
              may collect certain information in addition to information
              described elsewhere in this Policy. For example, the type of
              device and operating system you use. We may ask you if you want to
              receive push notifications. If you have opted in to these
              notifications and no longer wish to receive them, you may turn
              them off through your device’s operating system. We may use mobile
              analytics software to better understand how people use our
              application. We may collect information about how often you use
              the application and other performance data.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">
              Use and Disclosure of Personal Information
            </h1>

            <p style="font-size: 17px">
              We will not sell, trade, or rent your Personal Information. We
              process Personal Information in the following ways:
            </p>
            <p style="font-size: 17px">
              For Promotional Purposes: For example, if we collect your Personal
              Information when you visit our Website and do not pay for a
              Subscription, we may send you an email inviting you to Subscribe.
              You can stop receiving our promotional emails by following the
              unsubscribe instructions included in every email we send, or by
              adjusting your Marketing Preferences in your profile. This data is
              processed under Consent.
            </p>
            <p style="font-size: 17px">
              For Billing Purposes: This includes sending you emails, invoices,
              receipts, notices of delinquency, and alerting you if we need a
              different credit card number. We use third parties for secure
              credit card transaction processing, and we send billing
              information to those third parties to process your orders and
              credit card payments. This data is processed under Contract.
            </p>

            <p style="font-size: 17px">
              To Provide and Improve Our Services. This includes, for example,
              aggregating information from your use of the Services or visit to
              our Websites or Mobile Applications and sharing this information
              with third parties to improve our Services. This might also
              include sharing your information with third parties in order to
              provide and support our Services. When we do have to share
              Personal Information with third parties, we take steps to protect
              your information by requiring these third parties to enter into a
              contract with us that requires them to use the Personal
              Information we transfer to them in a manner that is consistent
              with this policy. This data is processed under Legitimate
              Interest.
            </p>

            <p style="font-size: 17px">
              For Account and Support Communication: For example, we may inform
              you of subscription payment successes or failures, password reset
              attempts, and other support-related functions. This data is
              processed under Contract.
            </p>
            <p style="font-size: 17px">
              For Platform Alerts: For example, we may inform you of temporary
              or permanent changes to our Services, such as pricing changes,
              planned outages, new features, version updates, releases, abuse
              warnings, and changes to our Privacy Policy. This data is
              processed under Contract.
            </p>

            <p style="font-size: 17px">
              For Legal Purposes: For example, complying with court orders,
              valid discovery requests, valid subpoenas, to prosecute and defend
              a court, arbitration, or similar legal proceeding. To respond to
              lawful requests by public authorities, including to meet national
              security or law enforcement requirements. To provide information
              to representatives and advisors, including attorneys and
              accountants, to help us comply with legal, accounting, or security
              requirements. This data is processed under Legal Obligation.
            </p>

            <p style="font-size: 17px">
              For Transfer Purposes: In the case of a sale, merger,
              consolidation, liquidation, reorganization, or acquisition. In
              that event, any acquirer will be subject to our obligations under
              this Privacy Policy, including your rights to access and choice.
              We will notify you of the change either by sending you an email or
              posting a notice on our Website. This data is processed under
              Legal Obligation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Data Retention</h1>

            <p style="font-size: 17px">
              We may retain Personal Information about the User, as long as it
              is necessary for business and/or legal purposes. Also, we may
              retain aggregated anonymous information indefinitely. In addition,
              we may retain your information for an additional period as is
              permitted or required to, among other things, comply with our
              legal obligations, resolve disputes, and enforce agreements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Data Transfer</h1>

            <p style="font-size: 17px">
              This Site processes information about our Users on servers located
              in a number of countries. The Company may also subcontract
              processing or sharing of information to third parties located in
              other countries, other than your home country. Also, such
              information may be transferred to another company if ownership of
              IPL changes. Therefore, information may be transferred across
              international borders outside the country where you use our
              services, including to countries that do not have laws providing
              specific protection for personal data or those that have different
              legal rules on data protection. You expressly consent to this.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Data Security</h1>

            <p style="font-size: 17px">
              We transmit and store the information we collect using standard
              security techniques. However, given the nature of the Internet and
              the fact that network security measures are not foolproof, we
              cannot guarantee the security of such information.
            </p>

            <p style="font-size: 17px">
              Should a security breach occur, Aiira will notify all affected
              customers as soon as is reasonably possible, and later file a
              report with the appropriate authorities on the actions we took.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Other Websites and Links</h1>

            <p style="font-size: 17px">
              Our Websites may contain links to third party websites (“Linked
              Websites”).This Privacy Policy does not cover collection or use of
              information by Linked Websites. We are not responsible for the
              privacy practices of Linked Websites. If you have questions about
              the privacy policies or practices of a Linked Website; you should
              contact the web administrator of the site directly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Children</h1>

            <p style="font-size: 17px">
              Users must be at least 18 years old, or the age of majority in
              their province, territory or country, to have our permission to
              use this Site. Individuals under the age of 18, or applicable age
              of majority, may utilize the service only with involvement of a
              parent or legal guardian, under such person’s account and
              otherwise subject to the Site’s Terms of use. Our policy is that
              we do not knowingly collect, use or disclose personally
              identifiable information about minor visitors.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Privacy Policy Changes</h1>

            <p style="font-size: 17px">
              We may make changes to this Privacy Policy from time to time, and
              for any reason. You are advised to consult this privacy policy
              regularly for any changes, as we deem your continued use,
              following posting of any amendment, modification or change,
              approval of all changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Your Data Privacy Rights</h1>

            <p style="font-size: 17px">
              Right to Rectification:Users can modify or change their name,
              email password, and mobile login PIN via their profile. For all
              other requests, such as updating email address or mobile number,
              please contact us at support&#64;aiira.co.
            </p>

            <p style="font-size: 17px">
              Right of Access, Right to Erasure, Right to Restrict Processing:
              Users can request access or erasure of their personal information,
              as well as request restriction on further processing of their
              personal information by contacting us at privacy&#64;aiira.co. Please
              allow up to 30 days for requests to be processed. Aiira reserves
              the right to charge a reasonable fee to process excessive or
              repeat requests.
            </p>

            <p style="font-size: 17px">
              Right to Withdraw Consent:Users can stop receiving our promotional
              emails by following the unsubscribe instructions included in every
              email we send, or by adjusting your Marketing Preferences in your
              profile.
            </p>

            <p style="font-size: 17px">
              Right to lodge a complaint with a supervisory authority:Should you
              feel your data privacy rights are not being adequately protected
              by Aiira, you have the right to lodge a formal
              complaint with the appropriate supervisory authority.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
