
import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { IMessage, Message, MessengerSocketService } from '@galaxy/entity-api';
import { StreamSocketService } from './socket/stream-socket.service';


@Injectable({
  providedIn: 'root'
})
export class ConnectCamService {


  configuaration: RTCConfiguration = {
    iceServers: [
      {
        // urls: 'stun:stun.l.google.com:19302'
        urls: [
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
        ]
      }
    ],
    iceCandidatePoolSize: 10
  }
  connection: RTCPeerConnection;


  conversationMessageSignal: IMessage = new Message;

  initiateCall = new EventEmitter<{ status: boolean, isVideo?: boolean, message?: IMessage }>();
  localStream: MediaStream;
  constructor(private _signalingService: StreamSocketService) {
    this.connection = new RTCPeerConnection(this.configuaration);
  }

  private async _initConnection(remoteVideo: ElementRef): Promise<void> {

    if (!this.connection) {
      this.connection = new RTCPeerConnection(this.configuaration);
    }

    await this._getStreams(remoteVideo);

    this._registerConnectionListeners();
  }

  private _registerConnectionListeners() {

    this.connection.onicecandidate = event => {

      if (!event || !event.candidate) return;

      // construct data to message socket

      const message: IMessage = new Message;
      message.type = 1;
      message.conversation.id = this.conversationMessageSignal.conversation.id;
      message.data = {
        type: 'candidate',
        candidate: event.candidate?.toJSON()
      }

      this._signalingService.sendMessage(message);
    }
  }




  public async handleCandidate(candidate: RTCIceCandidate): Promise<void> {
    // if (candidate) {
    // console.log('candidate to process', candidate);
    // console.log('new  - -candidate to process', new RTCIceCandidate(candidate));

    await this.connection.addIceCandidate(new RTCIceCandidate(candidate));
    // }
  }

  private async _getStreams(remoteVideo: ElementRef<HTMLVideoElement>): Promise<void> {

    const localCameraStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    });

    this.localStream = localCameraStream;

    const remoteStream = new MediaStream();

    remoteVideo.nativeElement.srcObject = remoteStream;

    // get and render media from remote
    this.connection.ontrack = event => {
      event.streams[0].getTracks().forEach(track => {
        remoteStream.addTrack(track);
      })
    }


    // get local media and send to remote device
    localCameraStream.getTracks().forEach(track => {
      this.connection.addTrack(track, localCameraStream);
    })

  }


  public notifyCall(isVideo, message) {
    this.conversationMessageSignal = message;

    this.initiateCall.emit({ status: true, isVideo, message });
  }


  public async makeCall(remoteVideo: ElementRef<HTMLVideoElement>): Promise<void> {

    await this._initConnection(remoteVideo);

    const offer = await this.connection.createOffer();

    await this.connection.setLocalDescription(offer);

    this._signalingService.sendMessage({ type: 'offer', offer });
    // console.log(offer);
  }


  public async handleOffer(offer: RTCSessionDescription, remoteVideo: ElementRef<HTMLVideoElement>): Promise<void> {
    await this._initConnection(remoteVideo);

    await this.connection.setRemoteDescription(new RTCSessionDescription(offer));

    const answer = await this.connection.createAnswer();

    await this.connection.setLocalDescription(answer);

    this._signalingService.sendMessage({ type: 'answer', answer });

    console.log(answer)
    // alert('answer sent!');
  }


  public async handleAnswer(answer: RTCSessionDescription): Promise<void> {

    // console.log(answer);
    await this.connection.setRemoteDescription(new RTCSessionDescription(answer));
  }



  public terminateCall() {
    this._signalingService.sendMessage({ type: 'close' });
    // if(this.connection.connectionState)
    this.localStream.getTracks()[0].stop();
    this.connection.close();
  }
}
