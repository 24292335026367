import { Injectable } from '@angular/core';
import { GenericService } from '../GenericService';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdentityApiService extends GenericService {
  constructor(http: HttpClient) {
    const rootUrl = 'https://api-v2.aiira.co';
    const baseUrl = '/identity-api/';
    super(http, baseUrl, rootUrl);
  }

}
