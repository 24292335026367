import { Controller } from '../decorators';
import { BusinessProduct, BusinessEntity } from './business-entity.model';

export interface IPrice {

  id: number;
  businessProduct: BusinessProduct;
  packCostPrice: number;
  unitCostPrice: number;
  packRetailPrice: number;
  unitRetailPrice: number;
  packWholesalePrice: number;
  unitWholesalePrice: number;
  created?: number;
  modified?: number;
  createdBy?: BusinessEntity;
  businessEntity?: BusinessEntity;
}

@Controller({ name: 'Price' })
export class Price implements IPrice {
  constructor(
    public id = 0,
    public businessProduct = new BusinessProduct,
    public packCostPrice = 0,
    public unitCostPrice = 0,
    public packRetailPrice = 0,

    public unitRetailPrice = 0,
    public packWholesalePrice = 0,
    public unitWholesalePrice = 0,
    public createdBy = new BusinessEntity,
  ) { }
}
