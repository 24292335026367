<div *ngIf="isList then list; else category"></div>





<ng-template #list>
  <galaxy-adaptive-card [showPadding]="false" [isFlat]="true" [shape]="'rounded'" [autoDimBlack]='true'>
    <div class="cover" [class.mini]="isMini" (click)="onItemRoute()">
      <div class="adx-img bg-black">
        <img [lazyLoad]="
          'assets/images/category/' + data.alias.toLowerCase() + '.jpg'
        " class="img-responsive" alt="" />
      </div>
      <div class="detail">{{ data.name }}</div>
    </div>
  </galaxy-adaptive-card>

</ng-template>

<ng-template #category>
  <galaxy-adaptive-card [showPadding]="false" [isFlat]="true" [shape]="'rounded'" [autoDimBlack]='true' >

    <div class="adx-item adx-sm adx-flat adx-button cat-title-bar">
      <b style="font-weight: 500;">{{data.name}}</b>

      <button (click)="onItemRoute()" class="adx-btn adx-round adx-flat btn-grey" [class.btn-darken]="theme === 'dark'"
        style="margin-top: -6px; margin-right: 0;">
        View All
      </button>
    </div>

    <div class="cat-images">
      <div class="bg-black">

      </div>
      <div class="bg-black">

      </div>
      <div class="bg-black">

      </div>

    </div>

  </galaxy-adaptive-card>

</ng-template>
