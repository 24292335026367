import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import * as THREE from 'three';

@Component({
  selector: 'app-three-js',
  templateUrl: './three-js.component.html',
  styleUrls: ['./three-js.component.scss']
})
export class ThreeJsComponent implements OnInit, AfterViewInit {


  @ViewChild('canvas') private canvasRef: ElementRef;

  // Cube mesh properties
  @Input() rotateSpeedX: number = 0.05;
  @Input() rotateSpeedY: number = 0.05;
  @Input() size: number = 200;
  @Input() texture: string = 'assets/images/3D/texture.jpg';

  // Stage Properties
  @Input() cameraZ: number = 400;
  @Input() fieldOfView: number = 1;
  @Input() nearClippingPlane: number = 1;
  @Input() farClippingPlane: number = 1000;


  // Helper properties
  private camera!: THREE.PerspectiveCamera;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private loader = new THREE.TextureLoader();
  private geometry = new THREE.BoxGeometry(1, 1, 1);
  private material = new THREE.MeshStandardMaterial({ color: 0xFF8001 });

  private cube: THREE.Mesh = new THREE.Mesh(this.geometry, this.material);
  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;

  constructor() { }
  ngAfterViewInit(): void {
    this.createScene();
    this.startRenderingLoop();
  }

  ngOnInit() {
  }

  /**
   * @private
   * @memberof CubeComponent
   */
  private createScene() {
    // Scene
    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0x000000);
    this.scene.add(this.cube);

    // Camera
    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    );
    this.camera.position.z = this.cameraZ;


    // light
    const pointLight0 = new THREE.PointLight();
    pointLight0.color = new THREE.Color(0x00ffff);
    pointLight0.position.set(2, 2, 2);
    this.scene.add(pointLight0);

    // light
    const pointLight1 = new THREE.PointLight();
    pointLight1.color = new THREE.Color(0xff00ff);
    pointLight1.position.set(-2, -2, 2);
    this.scene.add(pointLight1);
  }

  private getAspectRatio(): number {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }


  private animateCube() {
    this.cube.rotation.x += this.rotateSpeedX;
    this.cube.rotation.y += this.rotateSpeedY;
  }


  /**
   * @private
   * @memberof CubeComponent
   */
  private startRenderingLoop() {
    // Renderer
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    let component = this;

    (function render() {
      requestAnimationFrame(render);
      component.animateCube();
      component.renderer.render(component.scene, component.camera);
    }())
  }

}
