import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBusinessEntity, BusinessEntity, AppSettingService, SuiteApiService, Reservation, IReservation, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';


@Component({
  selector: 'galaxy-reservation-view',
  templateUrl: './reservation-view.component.html',
  styleUrls: ['./reservation-view.component.scss']
})
export class ReservationViewComponent implements OnInit, OnDestroy {


  @Input() reservationId: number = 0;
  @Input() appId: number; // 60 - airCare, 63 - airMeet

  data: IReservation = new Reservation;
  isLoading: boolean = false;
  person: IBusinessEntity = new BusinessEntity;

  theme: string = '';
  themeUpdated$!:Subscription;
  
  constructor(
    private _appSetting: AppSettingService,
    private _aRoute: ActivatedRoute,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // check params to make sure it isnt an edit
    // this._aRoute.params.subscribe(res => {
    if (this.reservationId > 0) {
      // console.log('getting entity');
      // const id = +res.data.id
      this.data = new Reservation;
      this.getData(this.reservationId);

    } else {
      this.onCloseDetail();
    }
    // });
  }

  getData(profileId: number): any {
    // get the id of the person

    this.isLoading = true;

    this._suiteApi
      .setParams(null, profileId.toString())
      .get(Reservation)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            this._suiteApi.DataMap({ from: res.data, to: this.data });
            this.person.entity = res.data.businessEntity.entity;


            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
