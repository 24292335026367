import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, SecurityContext, Output, EventEmitter } from '@angular/core';
import {
  AppSettingService,
  Entity,
  IEntity,
  EmbedService,
  BlogTypeEnum
} from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'adx-blog',
  templateUrl: './ad-blog.component.html',
  styleUrls: ['./ad-blog.component.scss']
})
export class AdBlogComponent implements OnInit {
  @Input() size: 'lg' | 'md' | 'sm' | 'xs' = 'xs';
  @Input() data: IEntity = new Entity();
  @Input() art: string = '';


  @Input() isFeed: boolean = false;
  @Input() showDetail: boolean = false;
  @Input() showPerson: boolean = true;
  @Input() showCounts: boolean = true;
  @Input() maxTitleLength: number = 70;

  userImage: SafeResourceUrl = '';
  color: string = '';
  cover: string =
    'http://127.0.0.1/airCloud/media/images/blog/default/060d02953643b8edf043541fa1e5ddf8.png';

  imageToShowOnError = '/assets/images/no-image-found.jpg';
  defaultImage = '/assets/images/default-image.jpg';
  @Output() shareEntity = new EventEmitter<IEntity>();
  @Output() viewEntity = new EventEmitter<IEntity>();

  blogTypeEnum: typeof BlogTypeEnum = BlogTypeEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;

    this.setDefaults();
  }

  setDefaults() {
    this.userImage = this.imgUrl(this.data.person.image, 'md');
    this.cover = this._sanitizer.sanitize(SecurityContext.URL, this.imgUrl(this.data.art, 'md')) || this.cover;
    // console.log('embeded userImage', this.data.person, this.userImage, this.imgUrl(this.data.person.image, 'xs'));
  }

  imgUrl(link: string, size = 'default') {
    return this._embed.imgUrl(link, size);
  }


  onShare($event) {
    $event.stopPropagation()
    this.shareEntity.emit(this.data);
  }


  onViewEntity() {
    this.viewEntity.emit(this.data);
  }
}
