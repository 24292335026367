<div [class.single-media]='isSingleImage'>

  <!-- begin switch -->
  <div [ngSwitch]="artwork.type" class="coverMedia">
    <!-- image -->
    <div *ngSwitchCase="1" class="coverImage">

      <div class="adx-card adx-flat no-margin no-padding adx-img" [class.is-artwork]='isArtwork'
        [class.is-loading]='isLoading'>
        <img #imgLoad (onStateChange)="onImageLoadState($event)" (load)="onImageLoaded()" [lazyLoad]="render"
          [defaultImage]="defaultImage" alt="render" />
        <div *ngIf="loadError" class='reload text-center' (click)='onReloadImage($event)'>
          <div>
            <i class="axl ax-refresh"></i>
            <br>
            <br>
            Tap to reload
          </div>
        </div>
      </div>


    </div>
    <!-- video -->
    <div *ngSwitchCase="3">
      <div class="adx-card no-margin embed-container">
        <iframe frameborder="0" webkitAllowFullScreen allowfullscreen [src]="render">
        </iframe>
      </div>
    </div>
    <!-- 3d -->
    <div *ngSwitchCase="4">
      <div class="adx-card no-margin embed-container">
        <iframe frameborder="0" webkitAllowFullScreen allowfullscreen [src]="render">
        </iframe>
      </div>
    </div>
  </div>
  <div *ngIf="artwork.description.length > 0" class="caption padding-sm text-center">
    {{ artwork.description }}
  </div>


</div>
