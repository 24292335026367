import { Component, OnInit, Input, SecurityContext, OnDestroy } from '@angular/core';
import { Person, AppSettingService, EmbedService, SocialApiService, NotifyService, AuthService, Connection, ColdBootService, ErrorHandlerService } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Subscription } from 'rxjs';
@Component({
  selector: 'adx-person',
  templateUrl: './ad-person.component.html',
  styleUrls: ['./ad-person.component.scss']
})
export class AdPersonComponent implements OnInit, OnDestroy {
  @Input() data: Person = new Person;
  @Input() isMini: boolean = false;
  avatar: SafeResourceUrl = '';
  cover: string = '';

  viewerId: number = 0;

  theme: string = '';
  themeUpdated$!: Subscription;
  isLoading: boolean = false;
  defaultCover: string = 'assets/images/cover/0';

  personName = '';
  constructor(
    private _router: Router,
    private _socialApi: SocialApiService,
    private _authService: AuthService,
    private _notify: NotifyService,
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
    private _appSetting: AppSettingService,
    private _coldBoot: ColdBootService,

    private _analytics: Analytics,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // set Person name
    this.personName = this.data.name.trim().length > 0 ? this.data.name : this.data.username;


    this.viewerId = this._authService.loggedIn ? this._authService.currentUser.gid.social.u : 0;
    this.avatar = this.imgUrl(this.data.image, 'sm');

    this.cover = this._sanitizer.sanitize(SecurityContext.URL, this.imgUrl(this.data.cover, 'md'));
    // this.cover = this.data.cover.length > 1 ?
    //   this._sanitizer.sanitize(SecurityContext.URL, this.imgUrl(this.data.cover, 'md')) :
    //   this.defaultCover + (Math.floor(Math.random() * 3) + 1) + '.jpg';
  }


  imgUrl(link: string, size = 'default') {
    return this._embed.imgUrl(link, size);
  }


  routeToProfile() {
    this._router.navigateByUrl('/profile/' + this.data.username);
  }


  // action keys
  onRelateToPerson($event, statusId: number = null) {

    $event.stopPropagation();

    if (!this._authService.loggedIn) {
      this._notify.error('Please login to continue action');
      return null;
    }

    this.isLoading = true;

    const connectQuery = new Connection;
    connectQuery.person.id = this.data.id;
    connectQuery.viewer.id = <number>this.viewerId;
    connectQuery.status = statusId;

    // console.log('connectin query goinggggggg', connectQuery);

    this._socialApi
      .setParams(null, null)
      .post(connectQuery)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              let message = '';
              switch (statusId) {

                case 1:
                  message = 'You are now following @' + this.data.username;
                  this.data.followedByViewer = true;
                  this._coldBoot.updateViewerRelationship();
                  break;

                case 2:
                  message = 'You unfollowed @' + this.data.username;
                  this.data.followedByViewer = false;
                  this._coldBoot.updateViewerRelationship();
                  break;

                default:
                  message = 'Something went wrong! Follow @' + this.data.username;
                  break;

              }

              this._notify.success(message);

              logEvent(this._analytics, 'connect_following', {
                type: statusId == 1 ? 'followed' : 'unfollowed',
                post: {
                  viewerUsername: this._authService.currentUser.username,
                  personUsername: this.data.username
                }
              });


            } else {
              this._notify.error('Something went wrong. Please try again');

            }

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
    // 61 - to follow
    // 62 - to unfollow
    // 63 - to follow + notify
    // 64 - to block



  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
