import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {
  @Input() title = 'aiira';
  @Input() bgColor = 'bg-black';
  @Input() isBgLite = false;
  @Input() logo = 'logo';
  @Input() showLogo = true;
  @Input() description = "Push your web development skills to the next level, through expert screencasts on Laravel, Vue, and so much more. Ready to binge?";
  @Input() routes: { name: string; icon: string }[] = [];

  constructor() { }

  ngOnInit() {
  }

}
