import { EventEmitter, Injectable } from '@angular/core';
import { BusinessRecord, IBusinessRecord, IRoleGrant } from '../../model';
import { SuiteApiService } from '../api/suite-api.service';
import { AppSettingService } from '../app-setting.service';
import { AuthService } from '../auth.service';
import { NotifyService } from '../notify.service';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessSocketService extends WebSocketService {

  private businessId: number;
  private grants: IRoleGrant[] = [];

  getNewRecords = new EventEmitter<IBusinessRecord>();
  constructor(private _authService: AuthService, private _suiteApi: SuiteApiService, private _notify: NotifyService) {
    super({ namespace: 'business', port: 5000 });
  }

  socketAirSuite() {

    this.businessId = this._suiteApi.getActiveBusiness().id;
    this.grants = this._authService.currentUser.claims;

    this.initiateSocket();
    // this._entitySocket.connect();

    // disconnect from messegeSocket when user loggs out
    this._authService.loggedOut.subscribe(res => {
      console.log('loggedOut triggered', res);
      if (res) {
        this.disconnect();
      } else {
        // this._messegeSocket.connect();
        this.initiateSocket();

        // this._entitySocket.connect();
      }
    })
  }

  initiateSocket() {
    //connect socket
    // this._messegeSocket.connect();

    if (!this._authService.loggedIn) {
      return;
    }

    // socket my local conversation
    this.socketBusiness();

    // for checking if im online
    this.listen('online').subscribe(res => {
      const msg = <any>res;
      // this._notify.info(msg.text);
    });

  }





  socketBusiness() {


    // join conversation room
    this.emit('join',
      {
        username: this._authService.currentUser.username,
        businessId: this.businessId
      }
    );
    // now listen
    this.listToRecordsSent()

  }


  private listToRecordsSent() {
    // register socket
    this.listen('newRecord').subscribe((resx: IBusinessRecord) => {

      // console.log('global msg in -- ', resx);
      // check if user is under business and has claims to the app
      if (resx.businessId === this.businessId) {

        let authUser = false;
        this.grants.forEach(x => {
          if (x.appRole.app.id === resx.appId) {
            authUser = true;
          }
        })

        if (authUser) {
          this._notify.info('New records for ' + this._getAppName(resx.appId));
          this.getNewRecords.emit(resx);
        }

      }


    });
  }


  private _getAppName(appId: number): string {
    const app = this._suiteApi.getDexApps().find(x => x.id === appId);

    if (app) {
      return app.name;
    }


    return '';
  }




  sendRecord(data: any) {
    const record = new BusinessRecord;

    record.businessId = this.businessId;
    record.branchId = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;
    record.appId = this._suiteApi.getActiveRole().appRole.app.id;
    record.classX = data.constructor.name;
    record.content = data;


    this.emit('sendRecord', record);
  }

}
