import { IBusiness, Business } from './business.model';
import { IGroup, Group } from '../ori/group.model';
import { Controller } from '../decorators';

export interface IClaim {
  id: number;
  business: IBusiness;
  app: IGroup;
  activated: boolean;
  created?: number;
  modified?: number;
}


@Controller({ name: 'Claim'})
export class Claim implements IClaim {
  constructor(
    public id = 0,
    public business = new Business,
    public app = new Group,
    public activated = true
  ) {

  }
}
