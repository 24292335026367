<section class="adx-section" style="padding-top: 0;">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <ui-data-grid [title]="title" (searchClicked)="onSearchData($event)" (closeDetail)="onCloseRoute()"
          [showDetail]="showDetail" [detail]="detailTitle" [paginatorPage]="page" [paginatorTotal]="totalData"
          [paginatorLimit]="pageSize" (pageinateData)="onPaginateData($event)">
          <div class="header-btns">
            <!-- <button
              class="adx-btn adx-icon adx-shadow adx-round"
              (click)="onDetailRoute('new', 'Register New Person')"
              routerLinkActive="btn-prim"
            >
              <i class="fa fa-plus"></i>
            </button> -->
            <button class="adx-btn btn-default adx-icon adx-round" (click)="onReloadData()"
              [class.is-loading]="isLoading">
              <i class="axl ax-refresh"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round" (click)="isList = !isList">
              <i class="axl ax-{{ !isList ? 'list' : 'grid' }}"></i>
            </button>
          </div>
          <div class="data" [class.is-loading]="isLoading">
            <!-- {{ data | json }} -->
            <div *ngIf="isList; then listData; else gridData"></div>
            <div class="text-right">
              <button class="adx-btn adx-ellipse adx-flat btn-special"
                [disabled]="shopType === 'stock' || shopType === 'damages'" (click)="onSalePriceChange()">
                Pricing: {{shopType === 'stock' || shopType === 'damages' ? 'Cost':(storePriceType |
                uppercase)}}
                Price
              </button>
            </div>
          </div>

          <div class="detail">
            <galaxy-store-cart>
              <div>
                Retail Price
              </div>
            </galaxy-store-cart>
          </div>
        </ui-data-grid>
      </div>
    </div>
  </div>
</section>

<ng-template #listData>
  <galaxy-store-list [theme]="theme" [data]="data" [storePriceType]="storePriceType" [shopType]="shopType"
    [actionButtons]="actionButtons" (detailRoute)="onDetailRoute($event)" (addToCart)="onAddToCart($event)">
    <!-- pagination start -->
    <div style="display: flex;">
      <div style="flex: 1;">
        <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0;">
          Total: {{ totalData }}
        </button>
      </div>
      <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
        (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
    </div>
    <!-- pagination ends -->
  </galaxy-store-list>
</ng-template>

<ng-template #gridData>
  <galaxy-store-grid [theme]="theme" [data]="data" [storePriceType]="storePriceType" [shopType]="shopType"
    [actionButtons]="actionButtons" (detailRoute)="onDetailRoute($event)" (addToCart)="onAddToCart($event)">
    <!-- pagination start -->
    <div style="display: flex;">
      <div style="flex: 1;">
        <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0;">
          Total: {{ totalData }}
        </button>
      </div>
      <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
        (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
    </div>
    <!-- pagination ends -->
  </galaxy-store-grid>
</ng-template>
