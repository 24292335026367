import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-single-ad',
  templateUrl: './single-ad.component.html',
  styleUrls: ['./single-ad.component.scss']
})
export class SingleAdComponent implements OnInit, OnDestroy {
  @Input() theme: string = 'dark';
  themeUpdated$!: Subscription;
  constructor(private _appSetting: AppSettingService) {}

  ngOnInit() {
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
  }
  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
