import { Business } from './business.model';
import { Group } from '../ori/group.model';
import { Controller } from '../decorators';
import { BloodGroupEnum, GenderEnum, MaritalStatusEnum } from '../../enums';

export interface IClient {

  id: number;
  businessEntityId?: number;
  personId: string;
  image: string;
  bio: string;
  name?: string;
  username?: string;
  surname: string;
  othername: string;
  gender: GenderEnum;
  birthdate?: number;
  email: string;
  phoneOne: string;
  phoneTwo?: string;

  occupation?: string;
  placeOfWork?: string;
  nationality?: string;
  maritalStatus?: MaritalStatusEnum
  bloodGroup?: BloodGroupEnum
  memberSince?: number;

  emailVerified?: boolean;
  activated?: boolean;
  lockoutEnabled?: boolean;
  lockoutEnd?: number;
  accessFailedCount?: number;
  phoneVerified?: boolean;
  hashed?: string;

  created?: number;
  city?: string;
  state?: string;
  country?: string;

  language?: string;

  business?: Business[];
}
@Controller({ name: 'Client' })
export class Client implements IClient {

  constructor(
    public id = 0,
    public personId = '',
    public image = '',
    public bio = '',
    public surname = '',
    public othername = '',
    public name = '',
    public gender = GenderEnum.MALE,
    public email = '',
    public birthdate = 0,
    public phoneOne = '',
    public phoneTwo = '',

    public maritalStatus = MaritalStatusEnum.SINGLE,
    public bloodGroup = BloodGroupEnum.AB_NEGATIVE,

    public country = 'GH',
    public state = 'Greater Accra',
    public city = 'Accra'

  ) {
    // this.birthdate = (new Date.now()
    // this.gender.id = 45;
    // this.maritalStatus.id = 475;
    // this.bloodGroup.id = 479;

  }

}
