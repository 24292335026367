import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, AuthService, BusinessBranchService, ErrorHandlerService, IBusinessBranchService, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-business-branch-services-select',
  templateUrl: './business-branch-services-select.component.html',
  styleUrls: ['./business-branch-services-select.component.scss']
})
export class BusinessBranchServicesSelectComponent implements OnInit {


  @Input() appId: number = 0;
  @Input() accountType: number = 0; //0 - all , 41 - users only , 42 - clients only
  @Input() businessBranchId: number = 0;

  @Output() serviceSelected = new EventEmitter<IBusinessBranchService>();
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Person Detail';
  detailTitle: string = 'Person Detail';
  isLoading: boolean = false;

  isList: boolean = true;

  data: IBusinessBranchService[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  
  theme: string = '';
  themeUpdated$!: Subscription;

  constructor(private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {

    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);
    if (this.businessId !== 0) {
      this.onReloadData();
    }

  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.businessBranchId,
        orderBy: [this.orderBy]

      })
      .getPageOf(BusinessBranchService)
      .subscribe(
        {
          next: (res) => {
            console.log('responds', res)
            // this.isLoading = false;

            this.data = <IBusinessBranchService[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }

  onServiceSelect(service: IBusinessBranchService) {
    this.serviceSelected.emit(service);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
