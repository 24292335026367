import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from '../GenericService';

@Injectable({
  providedIn: 'root'
})
export class KmaApiService extends GenericService {


  constructor(http: HttpClient) {
    const rootUrl = 'https://api-kma-fgjavwearq-uc.a.run.app';
    const baseUrl = '//127.0.0.1:9501/kma-api/';
    // const baseUrl = '/kma-api/';
    super(http, baseUrl, rootUrl);
  }


}
