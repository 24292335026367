<div class="media-slide bg-black" [lazyLoad]="bgArt">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class='slide-content-flex padding'>
          <div class='slide-detail'>
            <div class="adx-avatar adx-xs adx-flat" [routerLink]="['/profile/'+entity.person.username]">
              <div class="adx-img bg-black">
                <img [src]="personAvatar" alt="">
              </div>
              <h2>
                {{entity.person.name}} &#64; {{entity.person.username}}
              </h2>
            </div>

            <div class='padding'>
              <h1 class='text-normal' style="font-weight:500">{{entity.title}}</h1>
              <span>
                {{ isMedia ?( entity.mediaType=== 2 ? 'Audio' : 'Video') : entity.type.toString() }}
              </span>
              &nbsp; &nbsp;
              <span>
                <i class="axr ax-brand-google-analytics"></i> {{entity.hit}}
              </span>
              &nbsp;
              <span>
                <i class="axr ax-heart" [ngClass]="{'axs color-pink': entity.liked}"></i> {{entity.likeCount}}
              </span>
              &nbsp;
              <span>
                <i class="axr ax-message-dots"></i> {{entity.commentCount}}
              </span>

              <br>
              <br>
              <button (click)='onEntityClicked()' class="adx-btn adx-md btn-default adx-round">
                {{buttonName}}
              </button>
            </div>

          </div>
          <div class='slide-image' (click)='onEntityClicked()'>
            <div class="art">
              <img [src]="art" class='img-responsive' alt="">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <!-- <img src="https://loremflickr.com/g/320/240/paris" alt="Content 1"> -->
</div>
