import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, AuthService, BusinessEntityJobDescription, EmbedService, EmploymentTypeEnum, ErrorHandlerService, IBusinessEntityJobDescription, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-person-job-description',
  templateUrl: './person-job-description.component.html',
  styleUrls: ['./person-job-description.component.scss']
})
export class PersonJobDescriptionComponent implements OnInit, OnDestroy {

  title: string = 'Staff Job Descriptions'
  baseUrl: string = '/airStaff/persons/job-description';
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Job Description Detail';
  detailTitle: string = 'Job Description Detail';
  isLoading: boolean = false;

  data: IBusinessEntityJobDescription[] = [];
  viewerJobDescription: IBusinessEntityJobDescription = new BusinessEntityJobDescription;

  page: number = 1;
  pageSize: number = 2;
  totalData: number = 2;

  dataMaxLength: number = 2;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  theme: string = '';
  themeUpdated$!:Subscription;

  employmentTypeEnum: typeof EmploymentTypeEnum = EmploymentTypeEnum;
  viewerId: number = 0;

  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService,
    private _authService: AuthService,
    private _embed: EmbedService

  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    if (this.viewerId === +this._authService.currentUser.unique) return;


    this.viewerId = +this._authService.currentUser.unique;

    if (this._suiteApi.viewerJobDescription?.businessEntity?.id == this.viewerId) {
      this.viewerJobDescription = this._suiteApi.viewerJobDescription;
      return;
    }

    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);
    if (this.businessId !== 0) {
      this.onReloadData();
    }
    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });
  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.businessId,
        gid: this.viewerId,
        orderBy: [this.orderBy]

      })
      .getPageOf(BusinessEntityJobDescription)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <BusinessEntityJobDescription[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            if (this.pageSize > 0) {
              this.viewerJobDescription = this.data[0];
              this.viewerJobDescription.supervisor.entity.image = this._embed.imgUrlString(this.viewerJobDescription.supervisor.entity.image, 'md');
              this._suiteApi.viewerJobDescription = this.viewerJobDescription;

            }

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }


  onDetailRoute(link: string, title: string = 'View Detail') {
    this.showDetail = true;
    this.detailTitle = title;
    // console.log(link);
    this._router.navigateByUrl(this.baseUrl + link);

  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }

  
  ngOnDestroy(): void {
    this.themeUpdated$.unsubscribe();
  }
}
