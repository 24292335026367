import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmbedService, IClient } from '@galaxy/entity-api';

@Component({
  selector: 'app-adx-client-avatar',
  templateUrl: './ad-client-avatar.component.html',
  styleUrls: ['./ad-client-avatar.component.scss']
})
export class AdClientAvatarComponent implements OnInit, OnChanges {

  @Input() client: IClient;
  @Input() isLoading: boolean;
  @Input() infoToShow: string;

  avatar: SafeResourceUrl | string = '';

  constructor(
    private _embed: EmbedService,
  ) { }

  ngOnInit() {
    this.computeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const client = changes.client;
    if (!client.isFirstChange()) {
      this.computeData();
    }
  }

  private computeData() {
    if (!this.infoToShow) {
      this.infoToShow = this.client.phoneOne;
    }

    this.avatar = this.client.image.trim() !== '' ?
      this._embed.imgUrl(this.client.image, 'md') : '';

  }

}
