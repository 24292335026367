<app-adx-client-avatar [client]="person.entity"></app-adx-client-avatar>

<form #inputForm="ngForm" [class.is-loading]="isLoading">
  <div class="adx-input adx-label adx-round">
    <label for="purpose">Appointment Type</label>
    <select name="purpose" [disabled]="isLoading" required [(ngModel)]="data.type">
      <option value="1">New Treatment</option>
      <option value="2">Follow-up Treatment</option>
      <option value="3">Checkup</option>
      <option value="4">Referral</option>
    </select>
  </div>
  <div class="adx-input adx-label adx-round">
    <label for="deaprtment">Select Department</label>
    <select name="deaprtment" [disabled]="isLoading" [(ngModel)]="data.businessBranchDepartment.id" required>
      <option *ngFor="let item of branchDepartments" [value]="item.id">{{
        item.businessDepartment.name
        }}</option>
    </select>
  </div>
  <div class="adx-input adx-label adx-round">
    <label for="date">Date & Time</label>
    <input type="datetime-local" name="data" [min]="dateTimeNow" [(ngModel)]="appointmentDate" required
      [readOnly]="isLoading" />
  </div>
  <!-- <div class="adx-row">
    <div class="adx-cols-12">
      <div class="adx-input adx-label adx-round">
        <label for="date">Date</label>
        <input type="datetime-local" name="data" required [readOnly]="isLoading" />
      </div>
    </div>
    <div class="adx-cols-5">
      <div class="adx-input adx-label adx-round">
        <label for="deaprtment">Time</label>
        <input type="time" name="time" required [readOnly]="isLoading" />
      </div>
    </div>
  </div> -->
  <div class="clearfix"></div>
  <div class="adx-input adx-label adx-round">
    <label for="fee">Appoinment Fee</label>
    <input type="number" required name="totalAmount" [(ngModel)]="totalAmount" />
  </div>

  <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="onCloseDetail()">
    Cancel
  </button>

  <button *ngIf="!isLoading && branchDepartments.length > 0" [disabled]="isLoading || inputForm.invalid"
    (click)="onSubmit(2)" class="adx-btn adx-round adx-sm btn-tgreen float-right">
    Set Appointment
  </button>
</form>


<galaxy-modal [showModal]="showCheckOutModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">

  <app-invoice-receipt *ngIf="showCheckOutModal" [appId]="appId" [clientName]="person.entity.name"
    [amount]="totalAmount"
    [item]="{description:data.businessBranchDepartment.businessDepartment.name, amount:totalAmount}" [theme]="theme"
    (onCancel)="onCloseModal($event)" (onSubmit)="createEntity($event)"></app-invoice-receipt>


</galaxy-modal>
