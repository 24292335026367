<input type="file" ng2FileSelect [uploader]="uploader" #artworkFile [accept]="uploadType" name="art"
  [(ngModel)]="imageDataFile" style="display: none;" />
<div *ngIf="miniView then flexView;else gridView"></div>

<!-- pop-up modal -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showCreate" [showPadding]="false"
  [fixedWidth]="false" [width]="60">
  <!-- <div>New Artwork</div> -->

  <!-- begin switch -->
  <div [ngSwitch]="itemType">
    <!-- image -->
    <div *ngSwitchCase="45">
      <div class="artwork-flex bg-black">
        <img [src]="activeArtwork.thumbnail" alt="thumbnail" />
      </div>
    </div>
    <!-- video -->
    <div *ngSwitchCase="47">
      <div class="adx-input">
        <input *ngIf="canAddArtwork" type="text" placeholder="Copy & Past your Youtube/Vimeo link "
          [(ngModel)]="newVideoPath" required />
        <div *ngIf="
            canAddArtwork &&
            newVideoPath.trim() !== '' &&
            newVideoPath.length > 20
          " class="embed-container">
          <iframe frameborder="0" webkitAllowFullScreen allowfullscreen [src]="onConvertLink(newVideoPath.trim())">
          </iframe>
        </div>
        <div class="clearfix"></div>
        <div class="padding bg-yellowx" *ngIf="
            canAddArtwork &&
            newVideoPath.trim() !== '' &&
            newVideoPath.length > 20
          ">
          <span class="float-right">
            <button class="adx-btn btn-tgreen adx-round" (click)="onAddArtwork(3, newVideoPath)">
              Add
            </button>
            <button class="adx-btn btn-default adx-round" (click)="onCancelArtwork()">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
    <!-- 3d -->
    <div *ngSwitchCase="48">
      <div class="adx-input">
        <input *ngIf="canAddArtwork" type="text" placeholder="Copy & Past your Sketchfab link " [(ngModel)]="new3DPath"
          required />
        <div *ngIf="
            canAddArtwork && new3DPath.trim() !== '' && new3DPath.length > 20
          " class="embed-container">
          <iframe frameborder="0" webkitAllowFullScreen allowfullscreen [src]="onConvertLink(new3DPath.trim(), false)">
          </iframe>
        </div>
        <div *ngIf="
            canAddArtwork && new3DPath.trim() !== '' && new3DPath.length > 20
          ">
          <span class="float-right">
            <button (click)="onAddArtwork(4, new3DPath)" class="adx-btn btn-tgreen adx-round">
              Add
            </button>
            <button (click)="onCancelArtwork()" class="adx-btn btn-default adx-round">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- end swittch -->

  <div class='controls'>
    <span style="flex: 1; text-align: left;">
      <button (click)="onShowDeleteModal(activeArtwork)" class="adx-btn adx-icon adx-round btn-default adx-iconx">
        <i class="axr ax-trash"></i>
      </button>
      <div class="adx-input adx-flat adx-round" style="display: inline-block; margin:0;">
        <input type="text" [(ngModel)]="activeArtwork.description" style="width: 100%;" placeholder="Caption">
      </div>
      <button (click)="artworkSelected.emit(activeArtwork.path)"
        class="adx-btn adx-icon adx-round adx-iconx btn-{{ theme }}">
        <i class="axr ax-camera"></i>
      </button>
    </span>
    <!-- <span>
      <button [disabled]="!enableSaveImage" (click)="onCloseClicked()"
        class="adx-btn adx-icon adx-round btn-tgreen adx-iconx">
        <i class="axr ax-floppy-disk"></i>
      </button>
    </span> -->
  </div>

</galaxy-modal>

<!-- Warning to remove an artwork modal -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showDeleteModal" [showPadding]="false"
  [fixedWidth]="true">
  <div class="padding text-center">
    <div class="adx-divider">Delete Media?</div>
    <p class="opacity:0.5">Are you sure you want to Delete this photo</p>
  </div>
  <div>
    <button (click)="onCancelArtwork()" class="adx-btn btn-default adx-round">
      Cancel
    </button>
    <button (click)="onRemoveArtwork()" class="adx-btn btn-pink adx-round float-right">
      Delete
    </button>
  </div>
</galaxy-modal>



<ng-template #flexView>
  <div style="height:80px; position: relative;">
    <div class="place-flex" [showTitle]=false cdkDropList (cdkDropListDropped)="onDrop($event)">

      <div *ngFor="let item of sliderData; let i = index" cdkDrag class="flex-image-thumbnail"
        [style.backgroundImage]="'url('+item.thumbnail+')'" (click)="artworkSelected.emit(item.path)"
        [class.active]="entity.art === item.path"  [class.is-loading]="(i == (sliderData.length - 1)) && uploader.isUploading">
        <!-- <img class="thumbnail" [src]="item.thumbnail" /> -->

        <div class="action-btns">
          <div class="type-icon">
            <small *ngIf="item.type == 1" class="padding-sm">
              <i class="axr ax-photo"></i>
            </small>
            <small *ngIf="item.type == 3" class="padding-sm">
              <i class="axr ax-movie"></i>
            </small>

            <small *ngIf="item.type == 4" class="padding-sm">
              <i class="axr ax-cube"></i>
            </small>
          </div>

          <button class="adx-btn adx-icon adx-round btn-default adx-flat"
            (click)="$event.stopPropagation(); onShowArtwork(item.type.id, item)">
            <i class="axl ax-edit"></i>
          </button>
        </div>
      </div>


      <!-- upload section -->
      <div class="flex-image-thumbnail" (click)="artworkFile.click()" [class.is-loading]="uploader.isUploading"
        style="display: flex; align-items: center; justify-content: center;">
        <button class="adx-btn adx-icon adx-round btn-default adx-flat" [class.is-loading]="uploader.isUploading"
          [disabled]="uploader.isUploading">
          <i class="axl ax-plus"></i>
        </button>
      </div>

    </div>
  </div>


</ng-template>







<ng-template #gridView>

  <div class="text-center">
    <div class="adx-btn-group bg-dark adx-round" [ngClass]="{
        'bg-white': theme === 'light',
        'bg-dark': theme === 'dark'
      }">
      &nbsp;
      <button (click)="artworkFile.click()" class="adx-btn">Add Image</button>

      <button *ngIf="allowVideo" (click)="onAddMedia(3)" class="adx-btn">
        Add Video
      </button>

      <button *ngIf="allow3D" (click)="onAddMedia(4)" class="adx-btn">
        Add Sketchfab
      </button>
      &nbsp;
    </div>
    <br />
  </div>

  <div class="loading-media" *ngIf="uploader.isUploading"></div>
  <div ng2FileDrop [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (fileOver)="fileOverBase($event)"
    [uploader]="uploader" class="drop-image-div" (click)="artworkFile.click()">
    <div class="text-center">
      <br>
      <br>
      <i class="axl ax-photos color-tgreen ax-4x" style="opacity: .8;"></i>
      <br>
      <br>
      Drop Images Here
    </div>
    <!-- <input type="file" ng2FileSelect [uploader]="uploader" #artworkFile [accept]="uploadType" name="art"
      [(ngModel)]="imageDataFile" style="display: none;" /> -->
  </div>

  <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="media-grid">
    <galaxy-adaptive-card [showPadding]="false" cdkDrag *ngFor="let item of sliderData; let i = index">
      <span class="closeBtn">
        <button title="Remove" (click)="onShowDeleteModal(item)"
          class="adx-btn adx-xs adx-icon adx-round outline adx-flat btn-pink">
          <i class="axr ax-trash"></i>
        </button>
      </span>

      <div class="adx-img" (click)="onShowArtwork(item.type.id, item)">
        <img [src]="item.thumbnail" alt="" class="img-responsive" />
      </div>

      <div>
        <!-- {{ item.type.id }} -->
        <small *ngIf="item.type == 1" class="padding-sm bg-pink">
          <i class="axr ax-photo"></i> &nbsp; Image
        </small>
        <small *ngIf="item.type == 3" class="padding-sm bg-pink">
          <i class="axr ax-movie"></i> &nbsp; Video
        </small>

        <small *ngIf="item.type == 4" class="padding-sm bg-pink">
          <i class="axr ax-cube"></i> &nbsp;3D
        </small>
      </div>
      <div class="adx-input adx-round">
        <input type="text" name="description" [(ngModel)]="item.description" id="" placeholder="Caption" />
      </div>
    </galaxy-adaptive-card>
  </div>

</ng-template>
