<div class="wrapper">
  <div class="adx-row">
    <div class="adx-cols-12">
      <div class="adx-header adx-flat bg-blackx">
        <div class="app-name bg-pinkx">
          <!-- <button class="adx-btn adx-icon clear adx-flat adx-round">
            <i class="fal fa-bars"></i>
          </button> -->
          <div class="adx-appInfo" >
            <div (click)='onLogoClick()' class="adx-img"><img [src]="appLogo" alt="" /></div>
            <div (click)='onLogoClick()' class="apptitle">
              {{ appName }}
            </div>
          </div>
        </div>
        <div class="bg-yellowx">
          <button (click)="onToggleTheme()" class="adx-btn adx-icon adx-round clear" [ngClass]="{
              'btn-dark': theme == 'light',
              'btn-grey': theme == 'dark'
            }">
            <i class="axr" [ngClass]="{
                'ax-bulb': theme == 'light',
                'ax-bulb-off': theme == 'dark'
              }"></i>
          </button>

          <!--
        <button class="adx-btn adx-icon clear adx-flat adx-round">
          <i class="fal fa-cog"></i>
        </button>
      -->
          <div class="adx-dropdown more-dd">
            <button class="adx-btn btn-dark adx-icon adx-round btn-image adx-trigger">
              <i *ngIf="!isLoggedIn" class="fal fa-user-circle"></i>
              <img *ngIf="isLoggedIn" [src]="xsAvatar" alt="" />
            </button>
            <div class="adx-content" style="margin-left: -170px; width:250px">
              <galaxy-adaptive-card [isBlur]="true" [shape]="'rounded'" [showPadding]="false">
                <div *ngIf="isLoggedIn">
                  <div class="adx-card adx-fullx adx-ellipse adx-flat bg-black outlinex outline-tgreenx"
                    style="margin-top: 0; z-index: 100;">
                    <div class="dex-avatar text-center">
                      <div class="adx-img bg-grey">
                        <img [src]="smAvatar" [alt]="userInfo.username" />
                      </div>

                      <h2 class="text-normal" style="font-weight:500;">{{ userInfo.name }}</h2>
                      <p class="color-tgreen" style="margin-top:0;" *ngIf="!businessDomain">
                        &#64;{{ userInfo.username }}
                      </p>
                      <p *ngIf="businessDomain">
                        <span class="color-tgreen">
                          &#64;{{ userInfo.username }}
                        </span>
                        <br>
                        <span style="color: grey;">
                          {{ businessDomain }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="adx-list adx-sm adx-flat">
                  <ng-content></ng-content>
                  <div class="clearfix"></div>
                  <div class="padding-sm">
                    <div (click)="onLogOut()" class="adx-item adx-icon adx-flat padding-sm adx-ellipse" style="border-radius: 1.2rem;"  [ngClass]="{'bg-darker': theme==='dark', 'bg-grey': theme === 'light'}">
                      <span> <i class="axl ax-logout"></i> </span> Logout
                    </div>

                  </div>

                  <!-- <hr /> -->
                  <!--
                <div
                  class="adx-item adx-icon"
                  (click)="onShowMiniSocial('messages')"
                >
                  <span> <i class="fa fa-language"></i> </span> Language:
                  English
                </div>
              -->
                  <!-- <div class="adx-item adx-icon" (click)="onToggleTheme()">
                    <span>
                      <i class="axl ax-{{
                          theme === 'dark'
                            ? 'moon color-tgreen'
                            : 'sun color-pink'
                        }}"></i>
                    </span>
                    <b style="text-transform: capitalize;"> {{ theme }} </b>
                    Theme On
                  </div>

                  <div class="adx-item adx-icon" [routerLink]="['/setting']">
                    <span> <i class="axl ax-settings"></i> </span> Settings
                  </div>

                  <div class="adx-item adx-icon">
                    <a href="https://aiira.co/support" target="_blank">
                      <span> <i class="axl ax-help"></i> </span> Help Center
                    </a>
                  </div> -->
                </div>
              </galaxy-adaptive-card>
              <div>

              </div>
              <div class ='ui-action-btns' >
                <button class="adx-btn adx-ellipsex adx-icon adx-sm adx-flat"
                  [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}" (click)="onToggleTheme()">
                  <span>
                    <i class="axl ax-{{
                            theme === 'dark'
                              ? 'moon color-tgreen'
                              : 'sun color-pink'
                          }}"></i>
                  </span>
                  <!-- <b style="text-transform: capitalize"> {{ theme }} </b>
                  Theme On -->
                </button>

                <button class="adx-btn adx-ellipsex adx-icon adx-sm adx-flat"
                  [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}" [routerLink]="['/setting']">
                  <span> <i class="axl ax-settings"></i> </span>
                  <!-- Settings -->
                </button>

                <a class="adx-btn adx-ellipsex adx-icon adx-sm adx-flat"
                  [ngClass]="{'btn-dark': theme==='dark', 'btn-white': theme === 'light'}"
                  href="https://aiira.co/support" target="_blank">
                  <span> <i class="axl ax-help"></i> </span>
                  <!-- Help Center -->
                </a>
              </div>
            </div>
          </div>

          <!-- menu -->

          <!-- <div class="menu-btn bg-special">
            <button (click)="onToggleMenu()" class="adx-btn adx-icon adx-round adx-sm " [ngClass]="{
              'btn-dark': theme == 'light',
              'btn-grey': theme == 'dark'
            }">
              <i class="axr ax-menu"></i>
            </button>
          </div> -->


        </div>
      </div>
    </div>
  </div>
</div>
