<!-- <div class="adx-section bg-black banner">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div style="height: 100px"></div>
          <h2 class="text-normal text-size50">Welcome to Aiira Support</h2>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="adx-section ">
  <div class="wrapper">
    <div class="adx-row color-black">
      <div class="adx-cols-6">
        <div class="adx-card adx-flat">
          <br>
          <br>
          <div class='adx-divider'>
            OUR HQ
          </div>
          <h1 class="title text-normal text-size34">
            Kumasi, GH
          </h1>
        </div>
      </div>
      <div class="adx-cols-6">
        <div class="adx-card adx-flat " style="display: flex; margin-top:80px;">
          <div style="flex:1;">

            <p>
              <b style="opacity: .9;"> <span style=" font-size: 8px;"><i class="axr ax-circle color-tgreen "></i></span>
                &nbsp; ADDRESS</b>
              <br>
              <br>
              <span class='text-size18' style="opacity: .7;">
                North Suntreso,
                <br>
                Kumasi, GH
              </span>

            </p>

          </div>
          <div style="flex:1;">

            <p>
              <b style="opacity: .9;"> <span style="font-size: 8px;"> <i class="axr ax-circle color-tgreen"></i></span>
                &nbsp; CONTACT</b>
              <br>
              <br>
              <span class='text-size18' style="opacity: .7;">
                <a href="mailto:hello@aiira.co">hello&#64;aiira.co</a>
                <br>
                +233 (553) 007 658
              </span>

            </p>

          </div>

        </div>
      </div>
    </div>
    <div class="adx-row">
      <div class="adx-cols-9">
        <div style="" class="adx-card adx-img adx-flat bg-smoke">
          <img src="assets/images/support/office.jpg" class='img-responsive'>
          <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.5369910717395!2d-1.6392869852283218!3d6.704117395153108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5932cb59081cb79c!2sAiira%20Technologies!5e0!3m2!1sen!2sgh!4v1638017133503!5m2!1sen!2sgh"
            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-img adx-flat bg-smoke">

          <img src="assets/images/support/greenwall-aiira.jpg" class='img-responsive'>
        </div>
      </div>
    </div>
  </div>
</div>

<app-suite-apps></app-suite-apps>

<!-- support -->
<section class="adx-section bg-whitex">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="contact">
            <a routerLink="/iforgot">
              <i class="axl ax-lock ax-4x"></i>
              <span> Forgoten Aiira ID<br />or password > </span>
            </a>
            <a href="mailto://support@aiira.co">
              <i class="axl ax-mail ax-4x color-pink"></i>
              <span> Send an Email</span>
            </a>
            <a href="mailto://billing@aiira.co">
              <i class="axl ax-brand-paypal ax-4x color-blue"></i>
              <span> Billing and subcription > </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- airSuite -->
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="/assets/images/home/airSuite.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> airSuite </span>
                <h1 class="text-normal">Make the most of your airSuite</h1>
                <span class="text-size18">
                  A collection of powerful productivity apps you can use
                  anywhere. Organize your life, expand your creativity, and
                  protect what’s important
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm" routerLink="/suite">
                  &nbsp;Try airSuite For FREE&nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <!-- <h1 class="text-normal text-size34">Product Support.</h1> -->
            <span class="text-size18"> Stay Connected with Aiira Support </span>
          </div>
          <div class="text-center">
            <a class="adx-btn adx-round adx-sm adx-icon adx-flat btn-special" href="https://aiira.app/profile/aiira"
              target="_blank"><i class="axr ax-brand-aiira"></i></a>
            <a class="adx-btn adx-round adx-sm adx-icon adx-flat btn-prim" href="https://twitter.com/aiira_co"
              target="_blank"><i class="axr ax-brand-twitter"></i></a>

            <a class="adx-btn adx-round adx-sm adx-icon adx-flat btn-pink"
              href="https://www.youtube.com/channel/UC0NZZA9ceYfck0XWMA6I4SA" target="_blank"><i
                class="axr ax-brand-youtube"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
