import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, IClaim, IRoleGrant, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-access-level-bar',
  templateUrl: './access-level-bar.component.html',
  styleUrls: ['./access-level-bar.component.scss']
})
export class AccessLevelBarComponent implements OnInit, OnDestroy {

  @Input() appClaim?: IClaim;
  @Input() appClaimRoles: any[] = [];
  @Input() showAppInfo = true;

  @Output() onCloseModal = new EventEmitter();

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // console.log('app roles', this.appClaimRoles);
  }


  onRoleSelected(role: IRoleGrant) {
    // set actuve role
    this._suiteApi.setActiveRole(role);
    // this._suiteApi.setActiveApp();

    // set app roles
    this._suiteApi.activeAppRoles = this.appClaimRoles;

    //set active app = activeAppClaim;

    this._suiteApi.activeAppClaim = this.appClaim;

    const routeUrl = this.appClaim ? '/' + this.appClaim.app.name : '/dashboard';

    //route to App
    this._router.navigateByUrl(routeUrl);

    // closeModal
    this.onCloseModal.emit(true);

  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
