import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-businessx',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('light')
  }

}
