import { Component, Input, OnInit } from '@angular/core';
import { EmbedService, Person } from '@galaxy/entity-api';

@Component({
  selector: 'adx-person-avatar',
  templateUrl: './ad-person-avatar.component.html',
  styleUrls: ['./ad-person-avatar.component.scss']
})
export class AdPersonAvatarComponent implements OnInit {

  @Input() data: Person = new Person;
  @Input() isMini: boolean = false;
  avatar: any;
  constructor(private _embed: EmbedService) { }

  ngOnInit() {
    this.avatar = this.imgUrl(this.data.image, 'sm');
  }

  imgUrl(link: string, size = 'default') {
    return this._embed.imgUrl(link, size);
  }

}
