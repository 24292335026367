import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-platform-card',
  templateUrl: './platform-card.component.html',
  styleUrls: ['./platform-card.component.scss']
})
export class PlatformCardComponent implements OnInit {

  @Input() data: {
    logo: string,
    name: string,
    description: string,
    link: string,
    image: string
  };
  constructor() { }

  ngOnInit() {
  }

  cardClicked(){
    window.open(
      this.data.link,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
