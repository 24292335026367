import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { SocialApiService, AuthService, Comment, NotifyService, Person, EntitySocketService, AppSettingService, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-comment-system',
  templateUrl: './comment-system.component.html',
  styleUrls: ['./comment-system.component.scss']
})
export class CommentSystemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() entityId: number = 0;

  /**
   * 53 lifeshot/social entity
   * 54 play media entity
   * 55 artwork
   * 56 product
   * 57 blog
   * 58 message
   * 59 forum thread
   * 60 rent space entity
   */

  @Input() entityTypeId: number = 0;
  @Input() pageSize: number = 10;

  @Output() commentAdded = new EventEmitter<number>();
  page: number = 1;
  totalData: number = 0;

  data: Comment[] = [];

  personId: number = 0;
  personInfo: Person = new Person;

  isLoggedIn: boolean = false;
  isLoading: boolean = false;
  isLoadingNewCommment: boolean = false;
  theme: string = '';
  themeUpdated$!: Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _socialApi: SocialApiService,
    private _notify: NotifyService,

    private _entitySocket: EntitySocketService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.isLoggedIn = this._authService.loggedIn;
    if (this.isLoggedIn) {
      // social id
      this.personId = this._authService.currentUser.gid.social.u;

      // name and image for comment
      this.personInfo.id = this.personId;
      this.personInfo.name = this._authService.currentUser.name;
      this.personInfo.image = this._authService.currentUser.image;
    }

    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.getData();
  }




  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.entityId;

    if (!id.firstChange && id.previousValue !== id.currentValue) {
      this.getData();
      this.resetSocket();
    }
  }

  resetSocket() {
    this.registerSocket();
  }


  getData() {
    this._socialApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        id: this.entityId.toString(),
        category: this.entityTypeId.toString()
      })
      .getPageOf(Comment)
      .subscribe(
        {
          next: (res) => {
            this.data = res.data as Comment[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.registerSocket();

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


  registerSocket() {
    // list to socket
    this._entitySocket.listen('newEntityComment').subscribe(res => {
      const newUpdate = <Comment>res;

      if (newUpdate.entityId === this.entityId) {
        // update new data with my status to data
        // newUpdate.liked = this.data.liked;

        this.data.push(newUpdate);

      }
    });
  }




  postComment(newComment: Comment) {

    let comment = new Comment;
    comment = newComment;

    // const description = comment.description;
    // set entity
    comment.entityId = this.entityId;
    // type of entity .. blog, artwork, product, forum thread etc
    comment.entityType = this.entityTypeId;
    // set person too
    comment.person.id = this.personId;

    // console.log('incoming comments', comment);

    // show a loading for comment being inited

    this._socialApi
      .post(comment)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoadingNewCommment = false;

            if ((res as any).success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.increaseCommentCount(res.meta.pagination.totalItems ?? this.totalData + 1);
              // addTo it
              this.addToComment(res.data);

            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
              // show a resend on the comment added
            }

            this.isLoadingNewCommment = false;
          },
          error: (err) => {
            this.isLoadingNewCommment = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );


    this._notify.success('Your comment has successfull been saved');
  }


  addToComment(comment): void {
    // append to comments
    comment.person.name = this._authService.currentUser.name;
    comment.person.image = this._authService.currentUser.image;
    if (comment.description.trim().length == 0) {
      comment.description = 'description disappeared!';

    }

    this.data.push(comment);

    // console.log('data updated', this.data, comment);

    // emit via socket & mark as gone if only it not a new conversation
    this._entitySocket.emit('sendEntityComment', { type: this.getEntityTypeName(this.entityTypeId), data: comment });
  }

  increaseCommentCount(totalCommentCount: number): void {
    this.commentAdded.emit(totalCommentCount);
  }



  getEntityTypeName(typeId: number): string {
    let name = '';

    switch (typeId) {
      case 54:
        name = 'media'

        break;
      case 55:
        name = 'artist';

        break;
      case 56:
        name = 'store'

        break;
      case 57:
        name = 'blog';

        break;
      case 60:
        name = 'event';

        break;
      case 59:
        name = 'social';
        break;

      default:
        name = ''
        break;
    }

    return name;
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
