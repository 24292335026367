import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'app-home-interactive',
  templateUrl: './home-interactive.component.html',
  styleUrls: ['./home-interactive.component.scss']
})
export class HomeInteractiveComponent implements OnInit {

  data: {
    logo: string,
    name: string,
    description: string,
    link: string,
    image: string
  }[] = [
      {
        logo: 'assets/images/logo/qompass.png',
        name: 'Qompass',
        description: 'Business & Rental Platform',
        link: 'https://qompass.aiira.co',
        image: '/assets/images/business/business-apps-home.jpg'
      },
      {
        logo: 'assets/images/logo/airSuite.png',
        name: 'airSuite',
        description: 'Business Management Tools',
        link: 'https://suite.aiira.co',
        image: '/assets/images/home/airSuite-2.jpg'
      },
      {
        logo: 'assets/images/logo/connect.png',
        name: 'Connect',
        description: 'Social & E-Commerce Platform',
        link: 'https://connect.aiira.co',
        image: '/assets/images/home/avatars.png'
      },
      {
        logo: 'assets/images/logo/play.png',
        name: 'Interverse',
        description: 'Web Game Engine',
        link: 'https://play.aiira.co',
        image: '/assets/images/home/airPlay.jpg'
      },
    ]

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('dark')
  }

}
