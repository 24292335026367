import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranch, BusinessBranch } from './business-branch.model';
import { ITransaction, Transaction } from './transaction.model';
import { Controller } from '../decorators';
import { IBusiness, Business } from "./business.model";
import { AssetPaymentPlanEnum, AssetTypeEnum, MediaTypeEnum, StatusEnum } from "../../enums";
import { Person } from "../ori/user.model";



export interface IBusinessAssetMedia {
  // Basic Info
  id: number;
  title: string;
  asset?: IBusinessAsset;

  // VideoInfo
  description: string;
  thumbnail: string;

  path: string;
  file?;
  // music/video
  type: MediaTypeEnum;
  status: StatusEnum;

  orderNumber: number;

  created?: number;
}

@Controller({ name: 'AssetMedia' })
export class BusinessAssetMedia implements IBusinessAssetMedia {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public asset = new BusinessAsset(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public status = StatusEnum.PUBLISHED,
    public orderNumber = 1,
    public file = ''
  ) { }
}
export interface IBusinessAsset {
  id: number;
  image: string;
  title: string;
  description: string;
  ownerInfo: string;
  reference: number; // room number / tool i:string;
  amount: number; // amount per da:string;
  paymentPlan?: AssetPaymentPlanEnum; // amount per da:string;
  units: number;
  availableUnits: number;
  isAvailable: boolean;
  isVerified: boolean;
  isFeatured: boolean;
  assetType: AssetTypeEnum;
  assetLocation: IBusinessAssetLocation;
  geoLocation: { longitude: number, latitude: number }
  status: StatusEnum;
  media?: IBusinessAssetMedia[];
  bookmarks?: IBusinessAssetBookmark[];
  appoitments?: IBusinessAssetAppointment[];
  created: number;
  modified?: number;
  landlord: IBusinessEntity;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  businessBranch: IBusinessBranch;
  rating?: number;
  viewCount?: number;
  bookmarkCount?: number;
  appointmentCount?: number;
  isBookmarked?: boolean;

}

@Controller({ name: 'Asset' })
export class BusinessAsset implements IBusinessAsset {
  constructor(
    public id = 0,
    public image = '',
    public title = '',
    public description = '',
    public ownerInfo = '',
    public reference = 123,
    public amount = 0.00,
    public paymentPlan = AssetPaymentPlanEnum.Daily,
    public units = 1,
    public availableUnits = 1,
    public isAvailable = true,
    public isVerified = false,
    public isFeatured = false,
    public rating = 0,
    public assetType = AssetTypeEnum.APARTMENT,
    public assetLocation = new BusinessAssetLocation,
    public geoLocation = { longitude: -1.6230107134574407, latitude: 6.694190456933313 },
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
    public landlord = new BusinessEntity,
    public businessBranch = new BusinessBranch,

  ) {
    // this.status.id = 2;
    // this.assetType.id = 494;
  }
}


export interface IBusinessAssetBookmark {
  id: number;

  person: Person;
  asset: IBusinessAsset;
  status: StatusEnum;

  created: number;
  modified?: number;
}

@Controller({ name: 'Bookmark/Asset' })
export class BusinessAssetBookmark implements IBusinessAssetBookmark {
  constructor(
    public id = 0,
    public person = new Person,
    public asset = new BusinessAsset,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
  ) { }
}

export interface IBusinessAssetAppointment {
  id: number;
  reference: string;

  person: Person;
  asset: IBusinessAsset;
  fee: number;

  status: StatusEnum;

  created: number;
  modified?: number;
}



@Controller({ name: 'AssetAppointment' })
export class BusinessAssetAppointment implements IBusinessAssetAppointment {
  constructor(
    public id = 0,
    public reference = '',
    public person = new Person,
    public asset = new BusinessAsset,
    public fee = 0.00,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
  ) { }
}

export interface IBusinessAssetRentItem {
  id: number;
  assetRent: IBusinessAssetRent;
  asset: IBusinessAsset;
  units: number;
  status: StatusEnum;

}

export class BusinessAssetRentItem implements IBusinessAssetRentItem {
  constructor(
    public id = 0,
    public assetRent = new BusinessAssetRent,
    public asset = new BusinessAsset,
    public units = 1,
    public status = StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;
  }

}

export interface IBusinessAssetRent {
  id: number;
  businessEntity: IBusinessEntity;
  transaction: ITransaction;
  assetRentItems: IBusinessAssetRentItem[];
  assetRentItemsCount?: number;
  amount: number;

  rentFromDate: number;
  rentToDate: number;
  checkInDate?: number;
  checkOutDate?: number;

  description: string;
  status: StatusEnum;
  businessBranch: IBusinessBranch;

  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;

  bookmarkCount?: number;
  isBookmarked?: boolean;
}

@Controller({ name: 'AssetRent' })
export class BusinessAssetRent implements IBusinessAssetRent {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public transaction = new Transaction,
    public rentFromDate = 0,
    public rentToDate = 0,
    public assetRentItems = [],
    public amount = 0,
    public description = '',
    public status = StatusEnum.PUBLISHED,
    public businessBranch = new BusinessBranch,
    public created = 0,
    public createdBy = new BusinessEntity,

  ) { }
}

export interface IBusinessAssetLocation {
  id: number;
  reference: string;
  name: string;
  locationCategory: IBusinessAssetLocationCategory;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  // businessBranch: IBusinessBranch;
}


@Controller({ name: 'AssetLocation' })
export class BusinessAssetLocation implements IBusinessAssetLocation {
  constructor(
    public id = 0,
    public reference = '',
    public name = '',
    public locationCategory = new BusinessAssetLocationCategory,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
    // public businessBranch = new BusinessBranch,
  ) {
    // this.status.id = 2;
  }
}



export interface IBusinessAssetLocationCategory {
  id: number;
  name: string;
  description: string;
  businessBranch: IBusinessBranch;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'AssetLocationCategory' })

export class BusinessAssetLocationCategory implements IBusinessAssetLocationCategory {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public businessBranch = new BusinessBranch,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
    // this.status.id = 2;
  }
}
