import { Group, IGroup } from "../ori/group.model";
import { BusinessEntity } from './business-entity.model';
import { BusinessBranch } from './business-branch.model';
import { IFinancialAccount, IAuthorizer, FinancialAccount, Authorizer } from './financial-account.model';
import { Controller } from '../decorators';
import { PaymentModeEnum, StatusEnum, TransactionTypeEnum } from "../../enums";


export interface ITransaction {

  id: number;
  amount: number;
  paid: number;
  referenceNumber: string;
  comment: string;
  financialAccount: IFinancialAccount;
  businessEntity: BusinessEntity;
  approvedBy?: BusinessEntity;
  authorizer?: IAuthorizer;
  type: TransactionTypeEnum;
  status: StatusEnum;
  paymentMode?: PaymentModeEnum;
  app: IGroup;
  created: number;
  modified?: number;
  createdBy: BusinessEntity;

  modifiedBy?: BusinessEntity;
  businessBranch: BusinessBranch;
  payments?: IPayment[]

}

@Controller({ name: 'Transaction' })
export class Transaction implements ITransaction {
  constructor(
    public id = 0,
    public amount = 0,
    public paid = 0,
    public paymentMode = PaymentModeEnum.CASH,
    public referenceNumber = '',
    public comment = '',
    public financialAccount = new FinancialAccount,
    public businessEntity = new BusinessEntity, //client.
    public approvedBy = new BusinessEntity, //expense approver.
    public authorizer = new Authorizer,
    public type = TransactionTypeEnum.SALES,
    public status = StatusEnum.PUBLISHED,
    public app = new Group,
    public created = 0,
    public createdBy = new BusinessEntity,
    public businessBranch = new BusinessBranch,
    public payments = [],
  ) {
    // this.status.id = 2;
    // this.type.id = 416; //sales
    // this.paymentMode.id = 410; //cash
  }
}


export interface IPayment {
  id: number;
  reference: string;
  bankDate: number;
  amount: number;
  paidBy: string;
  remark: string;
  businessEntity?: BusinessEntity;
  paymentMode: PaymentModeEnum;
  transaction: ITransaction;
  created: number;
  modified?: number;
  createdBy: BusinessEntity;
  modifiedBy?: BusinessEntity;

}



@Controller({ name: 'Payment' })
export class Payment implements IPayment {
  constructor(

    public id = 0,
    public reference = '',
    public bankDate = 0,
    public amount = 0,
    public paidBy = 'SELF',
    public remark = '',
    public businessEntity = new BusinessEntity,
    public paymentMode = PaymentModeEnum.CASH,
    public transaction = new Transaction,
    public created = 0,
    public createdBy = new BusinessEntity,

  ) {
    // this.paymentMode.id = 410; //cash
  }
}
