import { AccessEnum, AccountTypeEnum, CommunityRoleEnum, CommunityTypeEnum, GenderEnum, SignatureTypeEnum, StatusEnum, MediaTypeEnum } from '../../enums';
import { Controller } from '../decorators';
import { IGalaxyParams } from '../suite/authtoken.model';
import { Group, IGroup } from './group.model';

export interface IUser {
  id?: number;
  username: string;
  email: string;
  name: string;
  image: string;
  cover?: string;
  surname?: string;
  othername?: string;
  gender?: GenderEnum;
  birthDate?: string;
  phoneOne?: string;
  password?: string;
  isVerified?: boolean;

  activated?: boolean;

  tagline?: string;
  location?: { city: string; country: string; continent: string };
  accountType?: AccountTypeEnum; //41 - indivual, 42->organization
  bio?: string;
  language?: string;
  timezone?: string;
  links?: { site: string; twitter: string; facebook: string; linkedin: string };
  // current password
  cpass?: string;
  // new password
  npass?: string;

  signatureType?: SignatureTypeEnum; // 66 - password, 67 - pin, 68 - fingerprint, 69 -faceId

  // signature


  // person
  gid?: IGalaxyParams[],
}

@Controller({ name: 'User' })
export class User implements IUser {
  constructor(
    public id = 0,
    public username = '',
    public name = '',
    public image = '',
    public cover = '',
    public email = '',
    public surname = '',
    public othername = '',
    public gender = GenderEnum.MALE,
    public birthDate = '',
    public phoneOne = '',
    public password = '',
    public tagline = '',
    public accountType = AccountTypeEnum.INDIVIDUAL,
    public bio = '',
    public language = '',
    public timezone = '',
    public cpass = '',
    public npass = '',
    public location = { city: 'Accra', country: 'GH', continent: 'AF' },
    public links = { site: '', twitter: '@', facebook: '', linkedin: '' }
  ) { }
}
@Controller({ name: 'Profile' })
export class Profile implements IUser {
  constructor(
    public id = 0,
    public username = '',
    public name = '',
    public image = '',
    public email = '',
    public surname = '',
    public othername = '',
    public gender = GenderEnum.MALE,
    public birthDate = '',
    public phoneOne = '',
    public password = '',
    public tagline = '',
    public bio = '',
    public links = { site: '', twitter: '@', facebook: '', linkedin: '' },
  ) { }
}
@Controller({ name: 'SocialProfile' })
export class SocialProfile {
  constructor(
    public person = new Person,
    public followers = 0,
    public follows = 0,
    public likes = 0,
    public views = 0,
    public followedByViewer = false,
    public blockedByViewer = false,
    public isNotify = false,
    public followsViewer = false,
    public hasBlockedViewer = false,
  ) { }
}
@Controller({ name: 'Person' })
export class Person implements IUser {
  constructor(
    public id = 0,
    public username = '',
    public name = '',
    public image = '',
    public cover = '',
    public tagline = '',
    public gid = [],
    public email = '',
    public isVerified = false,
    public activated = true,
    public followedByViewer = false,
    public followsViewer = false,
  ) { }




}


export interface IResetPassword {

  id: number;
  requestedIp: string;
  resetIp: string;
  email: string;
  person: IUser;
  password: string;
}

@Controller({ name: 'account/iforgot' })
export class ResetPassword implements IResetPassword {
  constructor(
    public id = 0,
    public requestedIp = '',
    public resetIp = '',
    public person = new Person,
    public email = '',
    public password = '',
  ) { }
}

export interface IPersonMedia {
  id: number;
  alias: string;
  thumbnail: string;
  path: string;
  person: Person;
  type: MediaTypeEnum;
  status: StatusEnum;
  created?: string;
}

@Controller({ name: 'PersonMedia' })
export class PersonMedia implements IPersonMedia {
  constructor(
    public id = 0,
    public alias = '',
    public thumbnail = '',
    public path = '',
    public person = new Person,
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public status = StatusEnum.PUBLISHED
  ) {
    // this.status.id = 2; // published
  }
}

export interface ICommunity {
  id?: number;
  name: string;
  image: string;
  cover?: string;
  location?: string;
  about: string;

  category: CommunityTypeEnum,
  status: StatusEnum,
  access: AccessEnum,
  visible: boolean,
  person: Person,

  role?: CommunityRoleEnum,
  activeChannel?: ICommunityChannel,
  channelCategory?: ICommunityChannel[],
  isMember?: boolean,
  isJoining?: boolean,
  members?: Person[],
  membersCount?: number;
  created?: number

}

@Controller({ name: 'Community' })
export class Community implements ICommunity {
  constructor(
    public id = 0,
    public name = '',
    public image = '',
    public cover = '',
    public about = '',
    public visible = true,
    public membersCount = 1,
    public isMember = false,
    public isJoining = false,

    public category = CommunityTypeEnum.ARTS,
    public status = StatusEnum.PUBLISHED,
    public access = AccessEnum.PRIVATE,
    public role = CommunityRoleEnum.MEMBER,
    public person = new Person(),

  ) {
    // this.status.id = 2; // published
    // this.access.id = 6; // private
    // // this.category.id = 80; // business
    // this.role.id = 79; // member
  }



}

export interface ICommunityChannel {
  id: number;
  name: string;
  community: ICommunity;
  status: StatusEnum
  createdBy: Person,
  parentChannel?: ICommunityChannel;
  channels?: ICommunityChannel[];
}

@Controller({ name: 'CommunityChannel' })
export class CommunityChannel {
  constructor(
    public id = 0,
    public name = '',
    public community = new Community,
    public status = StatusEnum.PUBLISHED,
    public createdBy = new Person
  ) {
    // this.status.id = 2; // published

  }
}
