import { Injectable, EventEmitter } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { BehaviorSubject, merge, fromEvent, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppStatusSwService {
  swUpdates = new BehaviorSubject('');
  updateBTN = new EventEmitter<boolean>();
  installBTN = new EventEmitter<boolean>();

  constructor(private updates: SwUpdate) {
    // programmatically check for updates every 5 seconds
    // interval(5000).subscribe(() => this.updates.checkForUpdate());

    // Notify the service when a new service worker version is available
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );

          this.updateBTN.emit(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });



    // install app
    this.promptInstallPWA();
  }

  promptInstallPWA() {
    // determine if app was installed after prompt
    window.addEventListener('appinstalled', (evt) => {
      console.log('App', 'installed');
      // hideBtn
      this.installBTN.emit(false);

      return null;
    });

    // if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
    //   console.log('display-mode is standalone');

    //   return null;
    // }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can add to home screen
      this.installBTN.emit(true);
    });
  }

  installPWA() {
    // hideBtn
    this.installBTN.emit(false);
    // Show the prompt
    if (deferredPrompt !== undefined) {
      // The user has had a postive interaction with our app and Chrome
      // has tried to prompt previously, so let's show the prompt.
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted App install prompt');
        } else {
          console.log('User dismissed App install prompt');
        }
        deferredPrompt = null;
      });
    }
  }
  // update & reload the application when new assets have been download
  reloadApp() {
    /**
     * Calls post message to the Service Workers inner scope (ACTIVATE_UPDATE)
     * https://developer.mozilla.org/en-US/docs/Web/API/Worker/postMessage
     */
    return this.updates.activateUpdate().then(() => document.location.reload());
  }

  // an observable of available service worker updates
  get updateAvailable() {
    return this.swUpdates.asObservable();
  }

  // the online status of the application
  get online() {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }
}

let deferredPrompt;
