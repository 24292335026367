import { Group } from '../ori/group.model';
import { IBusiness, Business } from './business.model';
import { BusinessBranch, IBusinessBranch } from './business-branch.model';
import { Controller } from '../decorators';
import { IDepartmentProgramme } from '..';
import { StatusEnum } from '../../enums';

export interface IBusinessDepartment {
  // Basic Info
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  created?: number;
  modified?: number;
  status: StatusEnum;
  business: IBusiness;

}

@Controller({ name: 'BusinessDepartment' })
export class BusinessDepartment implements IBusinessDepartment {
  constructor(
    public id = 0,
    public name = '',
    public address = '',
    public phone = '',
    public email = '',
    public status = StatusEnum.PUBLISHED,
    public business = new Business,
  ) {
  }

}


export interface IBusinessBranchDepartment {
  id: number;
  created?: number;
  modified?: number;
  status: StatusEnum;
  businessBranch: IBusinessBranch;
  businessDepartment: IBusinessDepartment;
  departmentProgrammes?: IDepartmentProgramme[];
  departmentProgrammesCount?: number;
}


@Controller({ name: 'BusinessBranchDepartment' })
export class BusinessBranchDepartment implements IBusinessBranchDepartment {
  constructor(
    public id = 0,
    public status = StatusEnum.PUBLISHED,
    public businessBranch = new BusinessBranch(),
    public businessDepartment = new BusinessDepartment(),
  ) {
  }

}
