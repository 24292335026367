import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-connect',
  templateUrl: './home-connect.component.html',
  styleUrls: ['./home-connect.component.scss']
})
export class HomeConnectComponent implements OnInit {

  backImage: number[] = []
  constructor() { }

  ngOnInit() {
    // for (let index = 0; index < 100; index++) {
    //   this.backImage.push(index)
    // }
  }

}
