import { EventEmitter, Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SocialApiService } from './api/social-api.service';
import { Connection, Person, Activity, IActivity, IConversation, Conversation, ICommunity, Community, Feed } from '../model';
import { Observable, Observer, observable } from 'rxjs';
import { OriDbService } from './db/ori-db.service';
import { MessegeApiService } from './api/messege-api.service';

@Injectable({
  providedIn: 'root'
})
export class ColdBootService {

  coldBootError = new EventEmitter<boolean>();
  constructor(
    private _authService: AuthService,
    private _oriDB: OriDbService,
    private _router: Router,
    private _socialApi: SocialApiService,
    private _messengerApi: MessegeApiService,
    private _storageMap: StorageMap
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('$ check app cold booted');
    // console.log('checj the params', route, state);

    if (!this._oriDB.coldStartDone) {
      this._authService.redirectToOnLoggin = state.url;
      // console.log('snapshop taken --- > ', this._authService.redirectToOnLoggin);

      this._router.navigateByUrl('/welcome');
    }

    return this._oriDB.coldStartDone;
  }


  isColdBootExpired(cres: number = null): boolean {

    this._storageMap.set(
      'lastColdBootTimestamp',
      Math.floor((+new Date) / 1000)
    ).subscribe(() => {
    });


    if (cres === null || cres === 0) {
      return true;
    }

    return (+new Date() / 1000) - cres > 86400 // 1 day (24 hrs);
  }

  getRelationship(page: number, pageSize: number): Observable<Person[]> {
    return Observable.create((observer: Observer<Person[]>) => {
      this._socialApi
        .setParams({
          page: page,
          pageSize: pageSize,
          searchValue: '',
          id: this._authService.currentUser.gid.social.u,
          categoryType: 1
        })
        .getAsPageOf<Person, Connection>(Connection)
        .subscribe({
          next: (res) => {
            const total = res.meta.pagination.totalItems;
            this._storageMap.set('relationship.users', res.data).subscribe(() => {
              this._storageMap.set('relationship.total', total).subscribe(() => {
                observer.next(<Person[]>res.data);
                observer.complete();
              });
            });
          },
          error: (error) => {
            console.error(error);
            this.coldBootError.emit(true);
          }
        });
    });

  }

  updateViewerRelationship() {
    // console.log('updating relationship');

    this.getRelationship(1, 100).subscribe(res => {
      if (res) {
        // set relation and its ids in local db
        this._oriDB.setRelationships(<Person[]>res);

        // update Feed.
        // this.getFeed(1, 12, this._oriDB.relationshipIds)
        //   .subscribe(res => {
        //     if (res) {
        //       this._oriDB.setFeedItems(<IActivity[]>res);
        //     }
        //   });
      }
    },
      error => {
        console.error(error);
        this.coldBootError.emit(true);
      });
  }


  getFeed(page: number, pageSize: number, relationshipIds: string): Observable<IActivity[]> {

    return Observable.create((observer: Observer<IActivity[]>) => {
      this._socialApi
        .setParams({
          id: this._authService.currentUser.gid.social.u,
          page: page,
          pageSize: pageSize,
          searchValue: '',
          category: 0,
          categoryType: 0,
          gid: btoa(relationshipIds)
        })
        .getPageOf(Activity)
        .subscribe({
          next: (res) => {
            const pageSize = res.meta.pagination.pageSize;
            this._storageMap.set('feed.items', res.data).subscribe(() => {
              this._storageMap.set('feed.visibleCount', pageSize).subscribe(() => {
                observer.next(<IActivity[]>res.data);
                observer.complete();
              });
            });
          },
          error: (error) => {
            console.error(error);
            this.coldBootError.emit(true);
          }
        });

    });

  }



  getConversation(): Observable<IConversation[]> {
    return Observable.create((observer: Observer<IConversation[]>) => {
      this._messengerApi
        .setParams({
          gid: this._authService.currentUser.gid.social.u,
          page: 1,
          pageSize: 50,
          searchValue: '',
          category: 39,
          orderBy: ['lastActive']
        })
        .getPageOf(Conversation)
        .subscribe({
          next: (res) => {
            this._storageMap.set('messenger.conversations', res.data).subscribe(() => {
              observer.next(<IConversation[]>res.data);
              observer.complete();

            });
          },
          error: (error) => {
            console.error(error);
            this.coldBootError.emit(true);
          }
        });

    });
  }


  updateCommunty() {
    this.getCommunity().subscribe(res => {
      this._oriDB.setCommunities(res);
    });
  }



  getCommunity(): Observable<ICommunity[]> {
    return Observable.create((observer: Observer<ICommunity[]>) => {
      this._socialApi
        .setParams({
          gid: this._authService.currentUser.gid.social.u,
          page: 1,
          pageSize: 50,
          orderBy: ['liked', 'hit', 'id']
        })
        .getPageOf(Community)
        .subscribe({
          next: (res) => {
            this._storageMap.set('community.items', res.data).subscribe(() => {
              observer.next(<ICommunity[]>res.data);
              observer.complete();

            });
          },
          error: (error) => {
            console.error(error);
            this.coldBootError.emit(true);
          }
        });

    });
  }


}
