<div class="adx-divider">
  My Saved Collections
</div>
<div class="adx-list adx-sm adx-flat adx-round collection {{theme}}" [class.mini]="isMini">
  <div class="adx-item adx-icon" [routerLink]="['/collection/playlist']">
    <span class="">
      <i class="btn-specialx axl ax-list-music"></i>
    </span>
    Media Playlists
  </div>
  <div class="adx-item adx-icon" [routerLink]="['/collection/wishlist']">
    <span class="">
      <i class="btn-specialx axl ax-gift"></i>
    </span>
    My Product Wishlist
  </div>

  <div class="adx-item adx-icon" [routerLink]="['/collection/photo']">
    <span class="">
      <i class="btn-specialx axl ax-photos"></i>
    </span>
    Photo Collection
  </div>
  <div class="adx-item adx-icon adx-badge" [routerLink]="['/cart']">
    <span class="">
      <i class="btn-tgreenx axl ax-shopping-cart"></i>
    </span>
    My Shopping Cart
    <!-- <span *ngIf="cartCount > 0" class="badge color-pink"> {{ cartCount }}</span> -->
  </div>
</div>
<div class="adx-card adx-flat aiira-apps  adx-ellipse" [class.mini]="isMini">
  <a href="https://qompass.aiira.co" target="_blank" style="background-image: url(assets/images/qompass.jpg);"></a>
  <a href="https://play.aiira.co" target="_blank" style="background-image: url(assets/images/airplay.jpg);"></a>
</div>
