import { StatusEnum } from "../../enums";
import { Controller } from "../decorators";

export interface IGroup {
  id: number;
  name: string;
  alias?: string;
  status?: StatusEnum;
  description?: string;
  superGroupId?: number;
  foreignId?: number;
  groupTypeId?: number;
  date?: string;
}

@Controller({ name: 'Group'})
export class Group implements IGroup {
  constructor(
    public id = 0,
    public name = '',
    public alias = null,
    public description = null,
    public superGroupId = null,
    public foreignId = null,
    public groupTypeId = null,
    public date = null
  ) {}
}
