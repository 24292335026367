import { Component, OnInit, Input, Output, EventEmitter, SecurityContext, OnDestroy } from '@angular/core';
import { ErrorHandlerService, PlayerService } from '@galaxy/entity-api';
import { AppSettingService, IEntity, Entity, EmbedService, AuthService, PlayApiService, History, NotifyService } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-track',
  templateUrl: './media-track.component.html',
  styleUrls: ['./media-track.component.scss']
})
export class MediaTrackComponent implements OnInit, OnDestroy {

  @Input() data: IEntity = new Entity();
  @Input() index: number = 0;
  @Input() mini: boolean = false;
  @Input() size: 'xs' | 'sm' | 'md';
  isPlaying: boolean = false;
  isLoading: boolean = false;
  @Input() isLive: boolean = false;
  @Input() isResponsive: boolean = true;
  @Input() isTransparent: boolean = false;
  @Input() showBookmark: boolean = true;

  @Output() addToCollection = new EventEmitter<IEntity>();
  @Output() shareEntity = new EventEmitter<IEntity>();
  theme: string = 'dark';themeUpdated$!: Subscription;
  avatar: SafeResourceUrl = '';
  coverArt: string = '';

  constructor(
    private _playerService: PlayerService,
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _authService: AuthService,
    private _playApi: PlayApiService,
    private _notify: NotifyService,
    private _sanitizer: DomSanitizer,
    private _errorHandlerService: ErrorHandlerService

  ) {
    this.theme = this._appSetting.defaultTheme;

    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
    // this._appSetting.color.subscribe(res => {
    //   this.color = res;
    // });
  }

  ngOnInit() {
    this.size = this.mini ? 'sm' : this.size;

    this.isPlaying =
      this._playerService.playingId === this.data.id ? true : false;

    // set cover art
    // this.avatar = this._embed.imgUrl(this.data.person.image, 'sm');
    this.coverArt = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.art, 'md'));

    // console.log('playing id is: ', this._playerService.playingId);

    this._playerService.currentMediaData.subscribe(res => {
      if (res.id === this.data.id) {
        this.isPlaying = true;
      } else {
        this.isPlaying = false;
      }

      // console.log('played is: ', res.id);
    });
  }


  onPlayClicked() {
    // First change play icon to isLoading
    this.isLoading = true;
    // Send info to playerService to process
    this.isLoading = !this._playerService.loadAudioInfo(this.data, this.isLive);
    // Return sucess to false isLoading
    // ** If is Playing, turn play button to pause able to pause
    // console.log('play is clicked at adx-album component');

    // add to history if he is loggedIn
    if (this._authService.loggedIn) {

      const history = new History();
      history.entity.id = this.data.id;
      history.person.id = this._authService.currentUser.gid.social.u;

      this._playApi
        .post(history)
        .subscribe(
          {
            next: (res) => {

            },
            error: (err) => {
              this.isLoading = false;
              this._errorHandlerService.handleError(err)
            },
          }
        );
      // this._notify.success('Now playing ' + this.data.title);
      this._notify.item('Now playing ' + this.data.title, this.coverArt);
    }
  }


  onAddPlaylist($event: Event, data: IEntity) {
    $event.stopPropagation();

    this.addToCollection.emit(data);
  }

  onShareEntity($event: Event, data: IEntity) {
    $event.stopPropagation();
    this.shareEntity.emit(data);
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
