<div class="adx-avatar adx-sm adx-button adx-flat  bg-special" style="border-radius: 16px;">
  <div *ngIf="businessEntity.id === 0 || (businessEntity.id !== 0 && showPersonModal) " class="adx-img bg-black"></div>
  <h2 *ngIf="businessEntity.id === 0">Customer Details</h2>
  <p *ngIf="businessEntity.id === 0">select customer for this cart</p>

  <!-- business entity -->
  <galaxy-entity-image *ngIf="businessEntity.id !== 0 && !showPersonModal" class="adx-img bg-blackx"
    [image]="businessEntity.entity.image" [isRound]="true" [isAvatar]="true">
  </galaxy-entity-image>
  <h2 *ngIf="businessEntity.id !== 0">{{businessEntity.entity.name}}</h2>
  <p *ngIf="businessEntity.id !== 0">{{businessEntity.entity.phoneOne}}</p>


  <button (click)="onSelectPerson()" class="adx-btn adx-round adx-sm btn-default adx-flat"
    style="margin:-1px; scale: .8;">
    select
  </button>

</div>

<div class='min-width:300px;' [class.is-loading]="isLoading">
  <div class="adx-divider"> <i class="axr ax-shopping-cart"></i> Shopping Cart <span
      class="float-right">{{items.length}}
      Items</span></div>
  <div class="clearfix"></div>
  <div class="v-flex">
    <div class="content">
      <app-cart-item *ngFor="let item of items" [theme]="theme" [item]="item" [currency]="currency"
        (removeItem)="onRemoveItem($event)">
      </app-cart-item>
      <!-- {{items | json}} -->
    </div>
    <div class="adx-card bg-black adx-ellipse no-margin">
      <div class="padding-sm">
        <button class="adx-btn  btn-dark adx-round ">
          <b>
            {{currency}} {{ (totalAmount | number: '1.2-2').split('.')[0] }}
            <sup>{{ (totalAmount | number: '1.2-2').split('.')[1] }}</sup>
          </b>
        </button>
        <button [disabled]="items.length === 0 || isLoading" class="adx-btn btn-tgreen outline adx-ellipse float-right"
          (click)="onCheckOut()">
          <i class="axr ax-shopping-bag color-tgreen"></i> Place Order
        </button>

      </div>

    </div>
  </div>
</div>


<!-- Select Customer -->
<galaxy-modal [showModal]="showPersonModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">

  <app-person-select *ngIf="showPersonModal" (personSelected)="onPersonSelected($event)"></app-person-select>
</galaxy-modal>


<galaxy-modal [showModal]="showCheckOutModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">

  <app-invoice-receipt [class.is-loading]="isLoading" *ngIf="showCheckOutModal" [appId]="61"
    [clientName]="businessEntity.entity.name" [amount]="totalAmount" [storeInvoiceItems]="invoice.storeInvoiceItems"
    [theme]="theme" (onCancel)="onCloseModal($event)" (onSubmit)="onSubmit($event)"></app-invoice-receipt>


</galaxy-modal>
