import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
  ) {

  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // console.log('request.url is ----> ,', request.url);
    // API Calls
    if (this._authService.loggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: this._authService.idToken,
          'Content-Type': 'application/json'
        }
      });
    }




    // basic auth for cdn
    if (request.url === '/cdn/upload/*') { // add auth header @ upload component
      request = request.clone({
        setHeaders: {
          Authorization: 'ori' + ' ' + 'qutie'
          // 'Content-Type': 'mutlipart/form-data'
        }
      });
    }


    // send client ip to server
    // if (request.url.includes('api.aiira.co') || request.url.includes('localhost'))
      request = request.clone({
        setHeaders: {
          'X-Spatial-Client-Ip': localStorage.getItem('clientIp') ?? '',
          'X-Spatial-Client-Location': localStorage.getItem('clientLocation') ?? 'Unknown Location'
        }
      });





    return next.handle(request);
  }
}
