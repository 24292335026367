<galaxy-fade-bg [image]="bg">
  <div class="adx-section no-padding " style="display: block;">
    <div class="wapper">
      <div class="adx-row">
        <div class="adx-colx-12">
          <div class="adx-card adx-flat text-center">
            <h1 class="text-normal">
              {{ title }}
            </h1>
            <p class="no-padding">
              {{ description }}
            </p>
            <galaxy-adaptive-card *ngIf="showSearhInput" [showPadding]="false">
              <div class="adx-input adx-icon adx-button adx-round outline">
                <span>
                  <i class="fa fa-search"></i>
                </span>
                <input
                  type="text"
                  name=""
                  id=""
                  [placeholder]="searchPlaceholder"
                />
                <button class="adx-btn btn-pink adx-round">
                  Search
                </button>
              </div>
            </galaxy-adaptive-card>
            <ng-content></ng-content>
            <p *ngIf="showSearhInput"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</galaxy-fade-bg>
