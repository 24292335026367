import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentityApiService, AuthService, WebSocketService, ColdBootService } from './services';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DurationPipe, GroupTypePipe, MaxLengthPipe, CountSuffixPipe, EnumPipe, SanitizeImagePipe } from './pipe';
// import { DurationPipe } from 'ngx-moment';

@NgModule({ declarations: [
        DurationPipe,
        GroupTypePipe,
        MaxLengthPipe,
        CountSuffixPipe,
        EnumPipe,
        SanitizeImagePipe

    ],
    exports: [
        DurationPipe,
        GroupTypePipe,
        MaxLengthPipe,
        CountSuffixPipe,
        EnumPipe,
        SanitizeImagePipe
    ], imports: [CommonModule], providers: [
        IdentityApiService,
        AuthService,
        ColdBootService,
        WebSocketService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class EntityApiModule { }
