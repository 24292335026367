<button
  *ngIf="!showOptions"
  class="adx-btn adx-icon clear adx-round active-btn"
  (click)="onShowOptions()"
  [title]="selected.name"
  [ngClass]="{
    'btn-default': theme == 'dark',
    'btn-dark': theme == 'light'
  }"
>
  <i
    [class]="selected.icon"
    style="transform:sacle(1.2) translateY(-10px); margin-top:5px;"
  ></i>
  <!-- {{selected.name}} -->
</button>
<div [class.close]="!showOptions">
  <div class="search-container">
    <button
      *ngFor="let item of options"
      class="adx-btn adx-icon clear adx-round"
      (click)="onOptionSeleted(item)"
      [title]="item.name"
      [ngClass]="{
        'btn-default': theme == 'dark',
        'btn-dark': theme == 'light'
      }"
    >
      <i
        [class]="item.icon"
        style="transform:sacle(1.2) translateY(-10px); margin-top:5px;"
      ></i>
    </button>
  </div>
</div>
