import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmbedService, Entity, IEntity } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-carousel-item',
  templateUrl: './media-carousel-item.component.html',
  styleUrls: ['./media-carousel-item.component.scss']
})
export class MediaCarouselItemComponent implements OnInit {

  @Input() entity: IEntity = new Entity;
  @Input() buttonName: string = '';
  @Input() isMedia = false;
  art: SafeResourceUrl = '';
  bgArt: string = '';

  personAvatar: SafeResourceUrl = '';


  @Output() viewEntity = new EventEmitter<IEntity>()

  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this.art = this._embed.imgUrl(this.entity.art, 'md');
    this.bgArt = this._embed.imgUrlString(this.entity.art, 'md');

    // person avatar
    this.personAvatar = this._embed.imgUrl(this.entity.person.image, 'xs');
  }

  onEntityClicked() {
    this.viewEntity.emit(this.entity);
    // console.log('clicked @ banner');
  }
}
