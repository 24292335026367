import { Routes } from '@angular/router';
import { SetupComponent } from './setup.component';
import { StartComponent } from './start/start.component';
import { ProfileComponent } from './profile/profile.component';
import { InterestComponent } from './interest/interest.component';
import { DiscoverComponent } from './discover/discover.component';
import { AuthGuardService, ColdBootService } from '@galaxy/entity-api';

export const SetupRoutes: Routes = [
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [ColdBootService],
    children: [
      { path: '', redirectTo: 'start', pathMatch: 'full' },
      {
        path: 'start',
        component: StartComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'interest',
        component: InterestComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'discover',
        component: DiscoverComponent,
        canActivate: [AuthGuardService]
      }
    ]
  }
];
