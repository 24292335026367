
export enum AssetTypeEnum {
  APARTMENT = 1,
  ROOM = 2,
  BED = 3,
  ELECTRONICS = 4,
  VEHICLE = 5,
  INSTRUMENTS = 6,
  CONSTRUCTION_TOOLS = 7,
  DECOR = 8,
}
