import { DeviceTypeEnum, StatusEnum } from '../../enums';
import { Controller } from '../decorators';
import { Group, IGroup } from './group.model';
import { IUser, User } from './user.model';

export interface IPersonDevice {

  id: number;
  person: IUser;
  app: IGroup;
  deviceId: string;
  fcmToken?: string;
  os: string;
  osVersion: string;
  browser: string;
  browserVersion: string;
  deviceType: DeviceTypeEnum;
  device: string;
  status: StatusEnum;
  activated: boolean;
  modified: string;
  created: string;

}


@Controller({ name: 'PersonDevice' })
export class PersonDevice implements IPersonDevice {
  constructor(
    public id = 0,
    public person = new User,
    public app = new Group,
    public deviceId = '',
    public fcmToken = '',
    public os = '',
    public osVersion = '',
    public browser = '',
    public browserVersion = '',
    public deviceType = DeviceTypeEnum.DESKTOP,
    public device = '',
    public status = StatusEnum.PUBLISHED,
    public activated = true,
    public modified = '',
    public created = '',
  ) {
    // this.status.id = 2; // 1 -loggedout, 2-loggedIn, 3 - blocked
    // this.deviceType.id = 70; //70 - desktop, 71 - tablet, 72 - mobile
  }
}
