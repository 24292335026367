import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthService } from '../services'; // for all the apps
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private _router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry({
      //   count: 3,
      //   delay: (_, retryCount) => timer(retryCount * 1000)
      // }),
      catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this._authService.switchAccount();
          this._router.navigateByUrl('/login');
          // location.reload(true);
        } else if (err.status === 500) {

        } else if (err.status === 404) {

        }

        const error = err.error.message || err.statusText;
        return throwError(()=>error);
      })
    );
  }
}
