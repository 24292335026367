import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  team: { photo: string, name: string, position: string }[] = [
    {
      photo: 'assets/images/team/kofi.jpeg',
      name: 'Kofi Owusu-Afriyie',
      position: 'Founder & CEO'
    },
    {
      photo: 'assets/images/team/samuel.jpg',
      name: 'Samuel O. Addo',
      position: 'Chief Operations Officer'
    },
    {
      photo: 'assets/images/team/placeholder.jpg',
      name: 'Praise Asare',
      position: 'Chief Technical Officer'
    },
    {
      photo: 'assets/images/team/conoria.jpg',
      name: 'Conoria T. Koduah',
      position: 'Board Executive'
    },
    {
      photo: 'assets/images/team/roger.jpg',
      name: 'Roger Owusu Sekyere',
      position: 'System Administrator'
    },
    {
      photo: 'assets/images/team/michael.jpg',
      name: 'Michael Dwamenah',
      position: 'Board Executive'
    },
    {
      photo: 'assets/images/team/jeff.jpg',
      name: 'Jeffery Osei Tutu',
      position: 'Board Executive'
    },
    {
      photo: 'assets/images/team/obeng.jpg',
      name: 'Kofi Obeng Afriyie',
      position: 'Head Of Marketing'
    },
    {
      photo: 'assets/images/team/loius.jpg',
      name: 'Luis Akwetey',
      position: 'Software Developer'
    },
    {
      photo: 'assets/images/team/princella.jpg',
      name: 'Princella Oduro',
      position: 'Software Developer'
    }
  ];
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('light')
  }

}
