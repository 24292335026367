import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IArtwork, EmbedService, Artwork, MediaTypeEnum, Media } from '@galaxy/entity-api';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'galaxy-media-artwork',
  templateUrl: './media-artwork.component.html',
  styleUrls: ['./media-artwork.component.scss']
})
export class MediaArtworkComponent implements OnInit {

  @Input() artwork: IArtwork = new Artwork;
  @Input() height: number = 0;
  @Input() isSingleImage = false;
  @Input() isArtwork: boolean = true;
  @ViewChild('imgLoad') imgLoad: ElementRef<HTMLImageElement>;
  render: SafeResourceUrl | string = '';

  loadError: boolean = false;
  isLoading: boolean = false;

  imageToShowOnError = '/assets/images/no-image-found.jpg';
  defaultImage = '/assets/images/default-image.jpg';

  firstImageLoad = false;
  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    if (this.artwork.type === MediaTypeEnum.IMAGE) {
      // if its image
      this.render = this._embed.imgUrlString(this.artwork.path, window.innerWidth <= 520 || !this.isArtwork ? 'md' : 'default');
      this.isLoading = true;
    }
    else {
      this.render = this.convertLink(this.artwork.path, this.artwork.type === MediaTypeEnum.VIDEO)
    }
  }

  convertLink(link: string, isVideo = true) {
    // makle sure the link is correct before attempt embed
    if (isVideo) {
      if (link.includes('youtube.com/watch?v=') || link.includes('vimeo.com/')) {
        return this._embed.onConvertVideoLink(this.artwork.path)
      }

    } else if (link.includes('sketchfab.com/')) {
      return this._embed.onConvertSkectfabLink(this.artwork.path);
    }
    return null;
  }


  onImageLoadState(event: StateChange) {
    switch (event.reason) {
      case 'setup':
        // The lib has been instantiated but we have not done anything yet.
        break;
      case 'observer-emit':
        // The image observer (intersection/scroll/custom observer) has emit a value so we
        // should check if the image is in the viewport.
        // `event.data` is the event in this case.
        break;
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        break;
      case 'mount-image':
        // The image has been loaded successfully so lets put it into the DOM
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.onImageLoaded();
        break;
      case 'loading-failed':
        // The image could not be loaded for some reason.
        // `event.data` is the error in this case
        this.onImageError();
        break;
      case 'finally':
        // The last event before cleaning up
        break;
    }

    // set image to no-image img
    // $event.target.src = '/assets/image/no-image-found.jpg';
  }


  onImageError() {
    this.loadError = true;
    this.isLoading = false;
    // console.log('error occured loading image');
  }
  onImageLoaded() {
    this.loadError = false;
    this.isLoading = !this.firstImageLoad;

    if (this.firstImageLoad && this.imgLoad.nativeElement.currentSrc.endsWith(this.defaultImage)) {
      this.onImageError();
    }

    this.firstImageLoad = true;
  }

  onReloadImage($event) {
    this.render = '';
    this.render = this._embed.imgUrlString(this.artwork.path, window.innerWidth <= 520 || !this.isArtwork ? 'md' : 'default');


    $event.stopPropagation();

    this.isLoading = true;
    this.loadError = false;

    var timestamp = new Date().getTime();


    var queryString = "?t=" + timestamp;

    this.imgLoad.nativeElement.src = this.render.toString() + queryString;

    // const newImage = new Image;
    // newImage.src = this.render;
    // newImage.onload = () => {
    //   console.log(newImage.currentSrc);
    //   this.imgLoad.nativeElement.src = newImage.currentSrc;
    //   this.isLoading = false;
    // };

    this.imgLoad.nativeElement.onerror = () => this.onImageError();

  }
}
