<div id="invoice-print" class="reciept">
  <div class="text-center">
    <div class="adx-divider">
      Invoice Reciept
      <br>
      <div style="font-size: 17px; max-width: 200px; margin:0 auto;">
        {{business.name}}
      </div>
    </div>
  </div>

  <div class="cart-item">
    <div>
      Date
    </div>
    <b>
      {{invoiceDate | date}}
    </b>
  </div>
  <div class="cart-item">
    <div>
      Client
    </div>
    <b>
      {{clientName}}
    </b>
  </div>
  <div class="text-center">
    _______________________________________
    _______________________________________
  </div>
  <br>
  <br>
  <div class="cart-item">
    <div>
      Description
    </div>
    <span>
      Price
    </span>
  </div>
  <br>

  <!-- For Store -->
  <div *ngIf="storeInvoiceItems.length > 0">
    <div class="cart-item" *ngFor="let item of storeInvoiceItems">
      <div>
        {{item.businessProduct.product.name}}

        |
        <span *ngIf="item.units > 0">
          {{item.units}}<sub>uts</sub>
        </span>
        <span *ngIf="item.packs > 0">
          {{item.packs}}<sub>pks</sub>
        </span>
      </div>
      <b>
        {{currency}} {{item.amount | number: '1.2-2'}}
      </b>
    </div>
  </div>




  <!-- items -->
  <div *ngIf="items">
    <div *ngFor="let item of items" class="cart-item">
      <div>
        {{item.name}}
      </div>
      <b>
        {{currency}} {{item.amount | number: '1.2-2'}}
      </b>
    </div>
  </div>


  <!-- For Other Apps/Services with single item -->

  <div *ngIf="item" class="cart-item">
    <div>
      {{item.description}}
    </div>
    <b>
      {{currency}} {{item.amount | number: '1.2-2'}}
    </b>
  </div>

  <br>
  <div class="cart-item">
    <div>
      Total
    </div>
    <b>
      {{currency}} {{transaction.amount | number: '1.2-2'}}
    </b>
  </div>
  <div *ngIf="+transaction.paid > 0">
    <div class="cart-item">
      <div>
        Amount Paid
      </div>
      <b>
        {{currency}} {{transaction.paid | number: '1.2-2'}}
      </b>
    </div>
    <div class="cart-item" *ngIf="transaction.amount > transaction.paid">
      <div>
        Balance
      </div>
      <b>
        {{currency}} {{(transaction.amount - transaction.paid) | number: '1.2-2'}}
      </b>
    </div>
  </div>

  <div class="text-center">
    _______________________________________
    <br>

    <br>
    <span>Thank you for comming!</span>
    <br>
    <br>
    <div class="scannable-code" *ngIf="amount > 0">
      <qr-code [value]="transaction.referenceNumber" size="100" errorCorrectionLevel='Q'></qr-code>
    </div>
    <br>
    <small>{{transaction.referenceNumber}}</small>
    <br>
    <br>
    <small>
      Login at <a href="https://client.aiira.co/">www.client.aiira.co</a> to access all your records.
      <br>
      Powered by: [ AIIRA - airSuite : www.aiira.co/suite ]
      <br>
      Printed Date: {{today|date}}
    </small>
  </div>

</div>

<div class="quick-transaction {{theme}}">
  <div class="input-flex" [class.is-paid]="+transaction.paid > 0" *ngIf="amount > 0">

    <div class="adx-input adx-label adx-round">
      <label for="">Amount Paid</label>
      <input type="number" min='0' step="0.10" [max]="transaction.amount" [(ngModel)]="transaction.paid" />
    </div>

    <div *ngIf="+transaction.paid > 0" class="adx-input adx-round adx-label">
      <label for="">Paid With</label>
      <select name="paymentMode" [(ngModel)]="transaction.paymentMode">
        <option value='1'>Cash</option>
        <option value='2'>Card Payment</option>
        <option value='3'>Bank Transaction</option>
        <option value='4'>Bank Cheque</option>
        <option value='5'>Mobile Money</option>
        <option value='6'>Crypto-Currency</option>

      </select>
    </div>
  </div>


  <div>
    <button class="adx-btn adx-round adx-sm btn-default" (click)="onCancelClick()">
      Cancel
    </button>

    <button class="adx-btn adx-round adx-sm  btn-tgreen float-right" (click)="onSubmitClick()">
      Check Out & Print
    </button>
  </div>
</div>
