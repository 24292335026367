<section class="adx-section" style="padding-top: 0;">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <ui-data-grid [title]="title" (searchClicked)="onSearchData($event)" (closeDetail)="onCloseRoute()"
          [showDetail]="showDetail" [detail]="detailTitle" [paginatorPage]="page" [paginatorTotal]="totalData"
          [paginatorLimit]="pageSize" (pageinateData)="onPaginateData($event)">
          <div class="header-btns">
            <button class="adx-btn adx-icon btn-tgreen  adx-shadow adx-round"
              (click)="isSubscriptionActive() ? onDetailRoute('new', 'Add New Product') : null"
              routerLinkActive="btn-prim">
              <i class="axl ax-plus"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round" (click)="onReloadData()">
              <i class="axl ax-refresh"></i>
            </button>
          </div>
          <div class="data">
            <galaxy-adaptive-card [classes]="'adx-table adx-sm'" [miniPadding]=true [autoFull]=true [isFlat]="true">
              <div *ngIf="isLoading" class="adx-card adx-flat is-loading">
                <p></p>
              </div>

              <!-- data here -->
              <table [class]="theme">
                <tr *ngFor="let item of data">
                  <td>
                    <div style="display: flex;">
                      <galaxy-entity-image [image]="item.product.art"></galaxy-entity-image>
                      <div>
                        {{ item.product.name | maxLength: 32 }}
                        <br />
                        <small class="highlight bg-black"> #: {{ item.product.code }} </small> - <small
                          class="highlight bg-tgreen"> {{ item.point }}pts </small>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <button class="adx-btn adx-rounder btn-tgreen adx-flat disabled adx-iconx" style="width:42px">
                      {{ item.point }}pts
                    </button>
                  </td> -->
                  <td>
                    <small>
                      <i class="fa fa-circle-notch color-blue"></i>
                      Cat.: {{ item.product.category  |enumKey:productCategoryEnum  | maxLength: 30 }}
                    </small>

                    <br />
                    <small>
                      <i class="fal fa-long-arrow-right" style="opacity: 0.3;"></i>
                      <!-- {{ item.city }}, {{ item.country }} -->
                      Type: {{ item.product.type  |enumKey:productTypeEnum | maxLength: 30 }}
                    </small>
                  </td>

                  <td>
                    <small>
                      {{ item.stocks.packs }} Packs
                    </small>
                    <br />
                    <small>
                      <!-- {{ item.city }}, {{ item.country }} -->
                      {{ item.stocks.units }} Units
                    </small>
                  </td>

                  <td>
                    <small>

                      Cost:
                      {{ item.prices[0].unitCostPrice | currency:currency }}
                    </small>

                    <br />
                    <small class="highlight bg-pink">
                      Retail:
                      <!-- {{ item.city }}, {{ item.country }} -->
                      {{ item.prices[0].unitRetailPrice | currency:currency }}
                    </small>
                  </td>

                  <td class="action">
                    <app-action-btns>
                      <div class="adx-btn-group adx-round">

                        <button class="adx-btn adx-icon adx-flat clear" (click)="
                            onDetailRoute('edit/' + item.id, 'Edit Product Info')
                          ">
                          <i class="axl ax-edit"></i>
                        </button>

                        <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear" (click)="
                            onDetailRoute(btnItem.route + item.id, btnItem.title)
                          ">
                          <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
                        </button>
                      </div>

                    </app-action-btns>

                  </td>
                </tr>
              </table>

              <!-- data ends -->
              <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Products'"></app-no-data>

            </galaxy-adaptive-card>

            <br>
            <!-- pagination start -->
            <div style="display: flex;">
              <div style="flex: 1;">
                <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0;">
                  Total: {{ totalData }}
                </button>
              </div>
              <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
                (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
            </div>
            <div class="text-right" style="font-family: monospace;">
              <div>Total Stocks: {{sumTotal.units}}units | {{sumTotal.packs}}packs | {{sumTotal.stocks}}stocks</div>
              <div>Total Cost: {{sumTotal.cost | currency:currency}}</div>
              <div>Expected Retail: {{sumTotal.retail | currency:currency}}</div>
              <div>Expected Profit: {{sumTotal.retail - sumTotal.cost | currency:currency}}</div>
            </div>
            <!-- pagination ends -->
          </div>

          <div class="detail">
            <ng-content></ng-content>
          </div>
        </ui-data-grid>
      </div>
    </div>
  </div>
</section>
