import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { ICustomerService, CustomerService, AppSettingService, AuthService, SuiteApiService, EmbedService, NotifyService, BusinessEntity, IBusinessEntity, BusinessBranchService, IBusinessBranchService, ITransaction, BusinessSocketService, ICustomerServiceRequest, IClient, CustomerServiceRequest, IBusinessService, StatusEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';


@Component({
  selector: 'galaxy-person-customer-care',
  templateUrl: './person-customer-care.component.html',
  styleUrls: ['./person-customer-care.component.scss']
})
export class PersonCustomerCareComponent implements OnInit, OnChanges, OnDestroy {

  businessId: number = 0;
  person: IBusinessEntity = new BusinessEntity;

  data: ICustomerService = new CustomerService;
  branchServices: IBusinessBranchService[] = [];

  isLoading: boolean = false;

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;

  searchValue: string = '';
  orderBy: string = 'id';
  isDataReady: boolean = false;
  @Input() appId: number = 59; // 59 - airClient, 487 - airLab
  @Input() businessEntityId: number = 0;
  @Input() isEdit: boolean = false;

  dateTimeNow: string = '';
  expectedCompleteDate: string = '';
  viewerId: number = 0;

  branchId: string | number = '';

  showServiceModal = false;
  showCheckOutModal = false;
  
  theme: string;
  themeUpdated$!: Subscription;
  
  requests: { name: string, amount: number }[] = [];

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _sanitizer: DomSanitizer,
    private _notify: NotifyService,
    private _businessSocket: BusinessSocketService,
    private _errorHandlerService: ErrorHandlerService

  ) { }


  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);


    this.branchId = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;

    // console.log('current User', this._authService.currentUser);
    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;

    // set Date
    this.dateTimeNow = new Date().toISOString().split('.')[0];
    this.expectedCompleteDate = this.dateTimeNow;





    this.isLoading = true;
    // check params to make sure it isnt an edit
    if (this.businessEntityId !== 0) {
      this._getClient();
      // console.log('creating entity with bEid', this.businessEntityId);
      this.newDataInit();
    } else {
      // check route param for edit
      this.checkActivatedRoute();

    }

  }


  private _getClient() {
    const client: IClient = this._suiteApi.getDataFromSuite('client');
    this.person.id = client.businessEntityId;
    this.person.entity = client;
  }

  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.businessEntityId;

    if (!this.isEdit && !id.firstChange && id.currentValue !== 0 && id.previousValue !== id.currentValue) {
      this.isLoading = true;
      this._getClient();
      // console.log('creating entity with bEid', this.businessEntityId);
      this.newDataInit();
    }

  }


  checkActivatedRoute() {
    // check params to make sure it isnt an edit
    this._aRoute.params.subscribe(res => {
      if (res['id']) {
        // console.log('getting entity');
        const customercare = res['id']
        this.getData(customercare);

      } else {
        this.onCloseDetail();
      }
    });
  }



  getData(customerserviceId: number): any {
    // get the id of the person
    this.isLoading = true;


    this._suiteApi
      .setParams(null, customerserviceId.toString())
      .get(CustomerService)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            this._suiteApi.DataMap({ from: res.data, to: this.data });
            this.person.entity = res.data.businessEntity.entity;
            this.isEdit = true;
            this.isLoading = false;
            // set appointmentDateTime
            this.expectedCompleteDate = new Date(this.data.expectedDate * 1000).toISOString().split('.')[0];

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }



  onAddService(service: IBusinessBranchService) {

    let found = false;
    this.data.customerServiceRequests.forEach(x => {
      if (x.businessBranchService.id === service.id) {
        found = true;
      }
    });


    if (found) {
      this._notify.info('Service ' + service.businessService.name + ' is already added to request');
      return;
    }

    const customerServiceRequests = new CustomerServiceRequest;
    customerServiceRequests.businessBranchService = service as BusinessBranchService;

    this.data.customerServiceRequests.push(customerServiceRequests);
    this.calculateAmount();
  }

  onRemoveService(serviceRequest: ICustomerServiceRequest) {
    // remove item from cart
    this.data.customerServiceRequests.splice(this.data.customerServiceRequests.indexOf(serviceRequest), 1);
    this.calculateAmount();
  }


  calculateAmount() {
    this.data.amount = 0;
    this.data.customerServiceRequests.forEach(item => {
      this.data.amount += item.businessBranchService.businessService.price;
    });
  }


  onServiceChange(serviceId: number) {

    // console.log('business service changed', serviceId);

    for (let index = 0; index < this.branchServices.length; index++) {
      const element = this.branchServices[index];
      if (+serviceId === element.id) {
        // console.log('found businessBranchService ', element);
        this.data.amount = element.businessService.price;
        break;
      }

    }
  }


  newDataInit() {
    this.data = new CustomerService();
    this.data.app.id = this.appId;
    this.data.businessBranch.id = +this.branchId;

    //  init status to published
    this.data.status = StatusEnum.PUBLISHED;

    this.isLoading = false;
    this.isDataReady = true;

  }

  // getPersonDetail(businessEntityId: number) {
  //   this.isLoading = true;
  //   this.person.id = businessEntityId;



  //   this._suiteApi.setParams(null, businessEntityId.toString())
  //     .get(BusinessEntity).subscribe(res => {
  //       this.person.entity = res.data.entity;

  //       // only reload department if its empty, else reset it
  //       // if (this.branchServices.length === 0) {
  //       //   this.onReloadServiceData();
  //       // } else {
  //       //   // this.data.businessBranchService.id = this.branchServices[0].id;
  //       // }

  //     });

  // }

  onCloseModal(value) {
    this.showCheckOutModal = false;
  }



  onSubmit(status: number) {

    // set creator/modifier
    this.data.createdBy.id = this.viewerId;

    // set to data.businessEntity
    this.data.businessEntity.id = this.businessEntityId;

    // 1 - unpublised => pending, 2 - published - onging , 3 - trashed - cancled  4 - finishsed
    this.data.status = status;

    // console.log(this.data);
    // check to see if title & description is not empty

    // expected datetime
    this.data.expectedDate = new Date(this.expectedCompleteDate).getTime();


    // this.data.branch = [this.businessBranch];

    console.log(this.data);
    // return;


    if (this.isEdit) {
      // this.isLoading = true;
      this.updateServiceRequest()
    } else {
      // set department name
      // const index = this.branchServices.findIndex(x => +x.id === +this.data.businessBranchService.id);
      // this.data.businessBranchService.businessService.name = this.branchServices[index].businessService.name;
      this.requests = [];
      this.requests = this.data.customerServiceRequests.map<{ name: string, amount: number }>(x => <{ name: string, amount: number }>{
        name: x.businessBranchService.businessService.name,
        amount: x.businessBranchService.businessService.price
      }
      );
      this.showCheckOutModal = true;
    }


  }

  updateServiceRequest() {
    this.isLoading = true;
    this._suiteApi
      .setParams({}, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }

  createServiceRequest(transaction: ITransaction) {
    this.isLoading = true;

    this.data.transaction = transaction;



    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              // web socket
              this._businessSocket.sendRecord(this.data);


              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}

