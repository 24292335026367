import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppSettingService,
  AuthService,
  BusinessEntity,
  ITransaction,
  NotifyService,
  SuiteApiService,
  IStoreInvoiceItem,
  IStoreInvoice,
  StoreInvoice,
  IBusinessEntity,
  IGroup,
  Group,
  BusinessSocketService,
  StoreTypeEnum,
  ErrorHandlerService
} from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-store-cart',
  templateUrl: './store-cart.component.html',
  styleUrls: ['./store-cart.component.scss']
})
export class StoreCartComponent implements OnInit, OnDestroy {

  showPersonModal = false

  businessEntity: IBusinessEntity = new BusinessEntity;

  invoice: IStoreInvoice = new StoreInvoice;
  items: IStoreInvoiceItem[] = [];
  isLoading: boolean;

  totalAmount: number = 0;

  theme: string = '';

  showCheckOutModal: boolean = false;

  storeType: StoreTypeEnum = StoreTypeEnum.SALES;
  currency: string;

  themeUpdated$!:Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _businessSocket: BusinessSocketService,
    private _errorHandlerService:ErrorHandlerService

  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // set defaults
    this.storeType = StoreTypeEnum.SALES;
    this.resetData();

    this.currency = this._suiteApi.getActiveBusiness().currency;


    // listen to store type
    this._appSetting.shopType.subscribe(res => {

      switch (res) {
        case 'returns':
          this.storeType = StoreTypeEnum.RETURNS;
          break;
        case 'stock':
        case 'stocks':
          this.storeType = StoreTypeEnum.STOCKS;
          break;
        case 'damages':
          this.storeType = StoreTypeEnum.DAMAGES;
          break;

        default:
          this.storeType = StoreTypeEnum.SALES;
          break;
      }


      // console.log('type is ',res, this.storeType);

      // reset cart
      this.resetData();

      // console.log(this.storeType);
    });



    this._appSetting.storeCart.subscribe(data => {
      this.pushToCart(data);
    });
  }


  private pushToCart(item: IStoreInvoiceItem) {

    // check if item exits in cart
    const index = this.items.findIndex(x => x.businessProduct.id === item.businessProduct.id);
    if (index === -1) {
      this.items.push(item);
    } else {
      this.items[index].units += item.units;
      this.items[index].packs += item.packs;
      this.items[index].amount += item.amount;

      // wobble item to know it increased
    }

    this.calculateAmount();

  }

  onRemoveItem(item: IStoreInvoiceItem) {
    // remove item from cart
    this.items.splice(this.items.indexOf(item), 1);
    this.calculateAmount();
  }


  calculateAmount() {
    this.totalAmount = 0;
    this.items.forEach(item => {
      this.totalAmount += item.amount;
    });
  }



  onSelectPerson() {
    this.showPersonModal = true;
  }
  onPersonSelected(person: BusinessEntity) {
    this.businessEntity = person;
    this.showPersonModal = false;
  }

  onCloseModal(item) {
    this.showPersonModal = false;
    this.showCheckOutModal = false;
  }


  onCheckOut() {
    if (this.businessEntity.id === 0) {
      this._notify.error('Please select a person');
      return;
    }

    this.invoice = new StoreInvoice();
    this.invoice.businessEntity = this.businessEntity;

    this.invoice.createdBy = new BusinessEntity;
    this.invoice.createdBy.id = this._authService.currentUser.unique as number;

    //branch
    this.invoice.businessBranch.id = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;

    //store type
    this.invoice.type = this.storeType;

    // this.isLoading = true;
    this.invoice.storeInvoiceItems = this.items;




    this.showCheckOutModal = true;
  }


  onSubmit(transaction: ITransaction) {
    this.isLoading = true;
    this.invoice.transaction = transaction;

    this._suiteApi
      .post(this.invoice)
      .subscribe(
        {
          next: (res) => {

            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              // this.invoice.id = res.data.id

              this.showCheckOutModal = false;

              // web socket
              this._businessSocket.sendRecord(this.invoice);

              this.resetData();

            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );

    // send to db
    this.onCloseModal(null);
  }


  private resetData() {
    this.items = [];
    this.totalAmount = 0;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
