import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedService, EventEntity, IEntity, Project } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-toon-item',
  templateUrl: './media-toon-item.component.html',
  styleUrls: ['./media-toon-item.component.scss']
})
export class MediaToonItemComponent implements OnInit {


  @Input() data: IEntity = new Project;

  @Output() viewEntity = new EventEmitter();
  art: string = '';
  constructor(
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.art = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.art, 'md'));
  }


  onImageClicked() {
    this.viewEntity.emit(this.data.id)
  }

}
