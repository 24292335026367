<div class="adx-section bg-white">
  <div class="centered">
    <i class="axl ax-lock ax-5x"></i>
    <br>
    <span class="text-size18">
      One Account For All
    </span>
    <br>
    <br>
    <galaxy-adaptive-card [autoDimBlack]='true' [shape]='"rounded"'>
      <div class="adx-card adx-flat" [class.is-loading]="isLoading">
        <div *ngIf="showForgot then iforgot;else resetPassword"></div>
      </div>
    </galaxy-adaptive-card>
  </div>
</div>



<ng-template #resetPassword>
  <!-- <div class="adx-card adx-flat"> -->
  <div class="user-flex2">
    <div class="user-circle">
      <div class="user-image">
        <img [src]="activeUserAvatar" alt="" />
      </div>
    </div>
    <span class="name">
      <!-- {{ activeAirUser.name }} -->
      <i class="color-tgreen">&#64;</i>{{ data.person.username }}
    </span>
    <div class="adx-input adx-round adx-icon">
      <span> <i class="axr ax-lock"></i> </span>
      <input [(ngModel)]="newPass" placeholder="Enter New Password" #airPassword name="newPass" type="password" />
    </div>
    <div class="adx-input adx-round adx-icon">
      <span> <i class="axr ax-lock"></i> </span>
      <input [(ngModel)]="newPass1" placeholder="Re-Enter New Password" name="newPass1" #airPassword1 type="password" />
    </div>
    <p style="padding: 0 16px">
      <button (click)="resetPass()" class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
        [disabled]="isLoading || (airPassword.value.length < 4 && airPassword1.value.length < 4 )">
        Reset Password
      </button>
    </p>
  </div>
</ng-template>





<!-- reset password request -->
<ng-template #iforgot>
  <form (ngSubmit)="sendPassWordReset()" #requestForm="ngForm" class="adx-form input-tgreen">
    <p>
      Enter your email to request for password reset
    </p>
    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span> <i class="axr ax-mail"></i> </span>
      <input type="email" [(ngModel)]="userName" name="email" placeholder="Enter your email" [pattern]="emailPattern"
        required [readOnly]="isLoading" />
    </div>

    <p style="padding: 0 16px">
      <button class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
        [disabled]="isLoading || !requestForm.valid" (click)="onRequestReset()">
        Send Request
      </button>

    </p>
  </form>
</ng-template>
