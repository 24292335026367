<div class="flex">
  <div class="media adx-card bg-darker adx-round adx-flat" style="margin:0 !important" *ngIf="entity">
    <galaxy-media-preview [entity]="entity"></galaxy-media-preview>
  </div>
  <br />
  <ng-content></ng-content>

  <div style="padding:0 16px">
    <div class="adx-input adx-button adx-round input-tgreen outline outline-tgreen" [class.is-loading]="isLoading"
      style="margin:16px 0;">
      <input type="text" placeholder="Search / Create Collection" [(ngModel)]="searchValue" (keydown.enter)="onSearch()"
        [disabled]="isCreatingCollection" />
      <button *ngIf="!isLoading && data.length === 0 && searchValue.trim().length > 3"
        class="adx-btn adx-round btn-tgreen" (click)="createCollection()" [disabled]="isCreatingCollection"
        [class.is-loading]="isCreatingCollection">
        New
      </button>
    </div>

    <div class="adx-item" *ngIf="!isLoading && data.length === 0 && searchValue.trim().length > 3">
      <div style="display: flex; width: 100%;">
        <strong class="text-normal" style="flex:1;">Keep This Private</strong>
        <div>
          <div class="adx-input" style="margin:0; padding:0;">
            <input type="checkbox" name="isPrivate" id="isPrivate" style="visibility: collapse;"
              [(ngModel)]="newCollectionIsPrivate" />
            <label style="margin:0;" class="adx-toggle" for="isPrivate"></label>
          </div>
        </div>
      </div>
    </div>

    <div class="adx-card adx-flat is-loading" *ngIf="isLoading">
      <p></p>
    </div>
    <!-- queue btn -->
    <button *ngIf="entityType === 'media'" (click)="onAddToQueue()"
      class="adx-btn adx-md btn-tgreen adx-full adx-rounder adx-shadows">
      <i class="axr ax-plus"></i> <b> Add To Queue</b>
    </button>

    <div *ngIf="!isLoading || data.length > 0">
      <div class="adx-list adx-card adx-full adx-flat adx-roundx adx-shadow collection-item"
        style="max-height: 250px; overflow: auto;">
        <galaxy-media-collection-item *ngFor="let item of data" [data]="item"
          (addToCollection)="addToCollection($event)"></galaxy-media-collection-item>
      </div>
      <!-- <p *ngIf="data.length > 0">
        Page {{ page }} &nbsp; &nbsp; Showing {{ pageSize }} of {{ totalData }}
      </p> -->
    </div>

    <div class="adx-card adx-flat" *ngIf="!isLoading && data.length === 0">
      <p class="text-center text-size18" style="opacity:.4;">
        <i class="ax; ax-folder ax-2x"></i> <br />
        <br />
        No Collection Found
      </p>
    </div>
    <!--
    <span class="">
      <br />
      <button class="adx-btn adx-sm btn-tgreen adx-shadows adx-full adx-rounder">
        Create New Collection
      </button>
      <br />
    </span> -->
  </div>
</div>
