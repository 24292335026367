import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppSettingService, AuthService, EntityApiModule } from '@galaxy/entity-api';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MomentModule } from 'ngx-moment';
import { NgxMasonryModule } from 'ngx-masonry';

import { AdBlogSideComponent } from './ad-components/ad-blog-side/ad-blog-side.component';
import { AdBlogComponent } from './ad-components/ad-blog/ad-blog.component';

import { AdChatFileComponent } from './ad-components/ad-chat-file/ad-chat-file.component';
import { AdxArtistComponent } from './ad-components/ad-chat-file/adx-artist/adx-artist.component';
import { AdxBlogComponent } from './ad-components/ad-chat-file/adx-blog/adx-blog.component';
import { AdxEventComponent } from './ad-components/ad-chat-file/adx-event/adx-event.component';
import { AdxMediaComponent } from './ad-components/ad-chat-file/adx-media/adx-media.component';
import { AdxPlayComponent } from './ad-components/ad-chat-file/adx-play/adx-play.component';
import { AdxStoreComponent } from './ad-components/ad-chat-file/adx-store/adx-store.component';
import { AdChatComponent } from './ad-components/ad-chat/ad-chat.component';
import { AdDiscoverComponent } from './ad-components/ad-discover/ad-discover.component';
import { AdEntityComponent } from './ad-components/ad-entity/ad-entity.component';
import { AdEventComponent } from './ad-components/ad-event/ad-event.component';

import { AdFooterComponent } from './ad-components/ad-footer/ad-footer.component';
import { AdGridComponent } from './ad-components/ad-grid/ad-grid.component';
import { AdPersonComponent } from './ad-components/ad-person/ad-person.component';
import { AdSponsorComponent } from './ad-components/ad-sponsor/ad-sponsor.component';
import { FileBuilderComponent } from './builder-ui/file-builder/file-builder.component';
import { PollBuilderComponent } from './builder-ui/poll-builder/poll-builder.component';
import { PollComponent } from './builder-ui/poll/poll.component';
import { SliderBuilderComponent } from './builder-ui/slider-builder/slider-builder.component';
import { SliderComponent } from './builder-ui/slider/slider.component';
import { MasonryGridComponent } from './media-ui/masonry-grid/masonry-grid.component';
import { MediaAlbumCardComponent } from './media-ui/media-album-card/media-album-card.component';
import { MediaArtworkComponent } from './media-ui/media-artwork/media-artwork.component';

import { MediaCardLgComponent } from './media-ui/media-card-lg/media-card-lg.component';
import { MediaCardModalComponent } from './media-ui/media-card-modal/media-card-modal.component';
import { MediaCardPostComponent } from './media-ui/media-card-post/media-card-post.component';
import { MediaCardComponent } from './media-ui/media-card/media-card.component';
import { MediaCollectionEntitiesComponent } from './media-ui/media-collection-entities/media-collection-entities.component';
import { MediaCollectionItemComponent } from './media-ui/media-collection-item/media-collection-item.component';
import { MediaCollectionSelectComponent } from './media-ui/media-collection-select/media-collection-select.component';
import { MediaCollectionComponent } from './media-ui/media-collection/media-collection.component';
import { MediaCommentPostComponent } from './media-ui/media-comment-post/media-comment-post.component';
import { MediaCommentComponent } from './media-ui/media-comment/media-comment.component';
import { MediaFeaturedComponent } from './media-ui/media-featured/media-featured.component';
import { MediaFolderFeaturedComponent } from './media-ui/media-folder-featured/media-folder-featured.component';
import { MediaFolderComponent } from './media-ui/media-folder/media-folder.component';
import { MediaGridComponent } from './media-ui/media-grid/media-grid.component';
import { MediaItemComponent } from './media-ui/media-item/media-item.component';
import { MediaPersonComponent } from './media-ui/media-person/media-person.component';
import { MediaPostCardComponent } from './media-ui/media-post-card/media-post-card.component';
import { MediaProductModalComponent } from './media-ui/media-product-modal/media-product-modal.component';
import { MediaTileComponent } from './media-ui/media-tile/media-tile.component';
import { UiModule } from '@galaxy/ui';
import { CommentSystemComponent } from './comment-system/comment-system.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { QuillModule } from 'ngx-quill';


import { GooglePayButtonModule } from "@google-pay/button-angular";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MyCollectionComponent } from './media-ui/my-collection/my-collection.component';
import { Title } from '@angular/platform-browser';
import { MediaToonSectionComponent } from './media-ui/media-toon-section/media-toon-section.component';
import { MediaAlbumChapterComponent } from './media-ui/media-album-chapter/media-album-chapter.component';
import { MediaToonItemComponent } from './media-ui/media-toon-item/media-toon-item.component';
import { ActivityPlaceholderComponent } from './loading-uis/activity-placeholder/activity-placeholder.component';
import { PersonPlaceholderComponent } from './loading-uis/person-placeholder/person-placeholder.component';
import { MediaCarouselComponent } from './media-ui/media-carousel/media-carousel.component';
import { MediaCarouselItemComponent } from './media-ui/media-carousel/media-carousel-item/media-carousel-item.component';
import { MediaTrackComponent } from './media-ui/media-track/media-track.component';
import { AdPersonAvatarComponent } from './ad-components/ad-person-avatar/ad-person-avatar.component';
import { SocketStreamComponent } from './stream-ui/socket-stream/socket-stream.component';
import { ThreeJsComponent } from './three-js/three-js.component';

@NgModule({ declarations: [
        AdPersonComponent,
        AdDiscoverComponent,
        AdBlogComponent,
        AdFooterComponent,
        AdBlogSideComponent,
        AdEventComponent,
        SliderBuilderComponent,
        MediaCardComponent,
        MediaFolderComponent,
        MediaFolderFeaturedComponent,
        MasonryGridComponent,
        MediaCardPostComponent,
        MediaAlbumCardComponent,
        MediaCollectionSelectComponent,
        // MaxLengthPipe,
        MediaCommentComponent,
        MediaCommentPostComponent,
        MediaArtworkComponent,
        MediaCardModalComponent,
        FileBuilderComponent,
        SliderComponent,
        AdGridComponent,
        MediaPersonComponent,
        PollBuilderComponent,
        PollComponent,
        MediaPostCardComponent,
        AdChatComponent,
        AdChatFileComponent,
        AdxArtistComponent,
        AdxBlogComponent,
        AdxEventComponent,
        AdxMediaComponent,
        AdxPlayComponent,
        AdxStoreComponent,
        AdEntityComponent,
        MediaTileComponent,
        MediaItemComponent,
        MediaCollectionComponent,
        MediaGridComponent,
        MediaProductModalComponent,
        MediaCollectionItemComponent,
        MediaCollectionEntitiesComponent,
        MediaCardLgComponent,
        AdSponsorComponent,
        MediaFeaturedComponent,
        CommentSystemComponent,
        MyCollectionComponent,
        MediaToonSectionComponent,
        MediaAlbumChapterComponent,
        MediaToonItemComponent,
        ActivityPlaceholderComponent,
        PersonPlaceholderComponent,
        MediaCarouselComponent,
        MediaCarouselItemComponent,
        MediaTrackComponent,
        AdPersonAvatarComponent,
        SocketStreamComponent,
        ThreeJsComponent
    ],
    exports: [
        AdPersonComponent,
        AdDiscoverComponent,
        AdBlogComponent,
        AdFooterComponent,
        AdBlogSideComponent,
        MediaFolderComponent,
        MediaFolderFeaturedComponent,
        AdEventComponent,
        SliderBuilderComponent,
        MediaCardComponent,
        MasonryGridComponent,
        MediaCardPostComponent,
        MediaAlbumCardComponent,
        MediaCommentComponent,
        MediaCommentPostComponent,
        MediaArtworkComponent,
        MediaCardModalComponent,
        FileBuilderComponent,
        SliderComponent,
        AdGridComponent,
        MediaPersonComponent,
        PollBuilderComponent,
        PollComponent,
        MediaPostCardComponent,
        AdChatComponent,
        AdChatFileComponent,
        AdEntityComponent,
        MediaTileComponent,
        MediaItemComponent,
        MediaCollectionSelectComponent,
        MediaCollectionComponent,
        MediaGridComponent,
        MediaProductModalComponent,
        MediaCollectionEntitiesComponent,
        MediaCollectionItemComponent,
        MediaCardLgComponent,
        AdSponsorComponent,
        MediaFeaturedComponent,
        CommentSystemComponent,
        MyCollectionComponent,
        MediaToonSectionComponent,
        ActivityPlaceholderComponent,
        PersonPlaceholderComponent,
        MediaCarouselComponent,
        AdPersonAvatarComponent,
        SocketStreamComponent,
        ThreeJsComponent
    ], imports: [CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        EntityApiModule,
        UiModule,
        MomentModule,
        DragDropModule,
        LazyLoadImageModule,
        NgxMasonryModule,
        FileUploadModule,
        EmojiModule,
        QuillModule.forRoot(),
        GooglePayButtonModule,
        CarouselModule], providers: [AuthService, AppSettingService, Title, provideHttpClient(withInterceptorsFromDi())] })
export class OriUiModule { }
