import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmbedService, IEntity } from '@galaxy/entity-api';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'galaxy-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss']
})
export class MediaCarouselComponent implements OnInit {

  @Input() data: IEntity[] = [];
  @Input() isMedia = false;
  @Input() buttonName = 'Quick View';

  @Output() viewEntity = new EventEmitter<{ entity: IEntity, index: number }>()
  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay:true,
    // navSpeed: 700,
    // navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      767: {
        items: 1
      },
      1024: {
        items: 1
      }
    },
    // nav: true
  }
  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {

  }


  onEmbed(photo: string, safeUrl = true): SafeResourceUrl | string {
    return safeUrl ? this._embed.imgUrl(photo, 'md')
      : this._embed.imgUrlString(photo, 'md');
  }


  onEntityClicked(entity: IEntity, index: number) {
    this.viewEntity.emit({ entity, index });
    // console.log('clicked @ banner ', id, index);
  }



}
