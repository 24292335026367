import { IGroup, Group } from '../ori/group.model';
import { IDocumentClaim } from './document.model';
import { IBusiness } from './business.model';
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranchDepartment, BusinessBranchDepartment } from './business-department.model';
import { Controller } from '../decorators';
import { ITeacherCourseCode } from './school.model';
import { StatusEnum } from '../../enums';

export interface IRole {

  id: number;
  name: string;
  description: string;
  business?: IBusiness;
  app: IGroup;
  status: StatusEnum;
  created?: number;
  modified?: number;
  appDocumentClaims?: IDocumentClaim[];

}

@Controller({ name: 'Role' })
export class Role implements IRole {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public app = new Group(),
    public status =  StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;
  }
}


export interface IRoleGrant {
  id: number;
  businessEntity: IBusinessEntity;
  businessBranchDepartment: IBusinessBranchDepartment;
  roleName?: string;
  appRole: IRole;
  documentClaim: IGroup;
  isActive: boolean;
  created?: number;
  modified?: number;
  teacherCourseCodes?: ITeacherCourseCode[];
  teacherCourseCodesCount?: number;
}
@Controller({ name: 'RoleGrant' })
export class RoleGrant implements IRoleGrant {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public businessBranchDepartment = new BusinessBranchDepartment,
    public appRole = new Role,
    public documentClaim = new Group,

    public isActive = true,
    public teacherCourseCodes = [],
    public teacherCourseCodesCount = 0,
  ) {

  }
}
