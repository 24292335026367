<div class="clearfix" *ngIf="isLoading"></div>
<div class="adx-card adx-flat is-loading" *ngIf="isLoading">
  <p></p>
</div>

<section class="padding">
  <div class="wrapper-fluid">
    <div class="adx-row">
      <div class="adx-colx-12">
        <div class="media-card-grid">
          <div class="media-item" *ngFor="let item of data; index as i">
            <galaxy-media-card (addToCollection)="onShowModalClicked($event, 2)"
              (shareEntity)="onShowModalClicked($event, 1)" (viewEntity)="onEntityClicked($event, i)"
              [entityType]="entityType" [showBookmark]="showBookmark" [showDetail]="showDetail"
              [showPerson]="showPerson" [entity]="item"></galaxy-media-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="adx-card adx-flat" *ngIf="!isLoading && data.length === 0">
  <p class="text-center text-size18" style="opacity: 0.4;">
    <i class="axr ax-shopping-bag ax-2x"></i>
    <br />
    <br />
    No {{ entityType }} Found
  </p>
</div>

<!-- modals -->

<!-- share & add to collection -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showModal">
  <div *ngIf="modalNumber === 1; then shareModal; else collectionModal"></div>
</galaxy-modal>

<!-- share & add to collection -->
<ng-template #shareModal>
  <galaxy-media-share *ngIf="showModal" [title]="entity.title" [entity]="entity" [link]="getEntityLink()">
  </galaxy-media-share>
</ng-template>

<ng-template #collectionModal>
  <galaxy-media-collection-select *ngIf="showModal" [entity]="entity" [collectionType]="84" [entityType]="entityType"
    [personId]="viewerId"></galaxy-media-collection-select>
</ng-template>

<!-- view Entity -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showPadding]=false [showModal]="showEntityModal"
  [fixedWidth]="false" [width]="80">
  <div class="paddingx" *ngIf="entityType === 'photo'; then photoView; else productView"></div>

  <galaxy-media-navigator [showNavBack]="prevProject" [showNavNext]="nextProject"
    (navBack)="onEntityClicked(prevProject, prevProjectIndex)"
    (navNext)="onEntityClicked(nextProject, nextProjectIndex)"></galaxy-media-navigator>
</galaxy-modal>

<!-- product -->

<ng-template #productView>
  <galaxy-media-product-modal *ngIf="showEntityModal" [entity]="entitySelected"></galaxy-media-product-modal>
</ng-template>
<!-- product -->

<ng-template #photoView>
  <galaxy-media-card-modal *ngIf="showEntityModal" [entity]="entitySelected"></galaxy-media-card-modal>
</ng-template>
