import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranch, BusinessBranch } from './business-branch.model';
import { ITransaction, Transaction } from './transaction.model';
import { Controller } from '../decorators';
import { DeliveryItemTypeEnum, DeliveryOrderTypeEnum, StatusEnum } from "../../enums";

export interface IDeliveryItem {

  id: number;
  name: string;
  type: DeliveryItemTypeEnum;
  amount: number;
  deliveryOrder: IDeliveryOrder;

}

export class DeliveryItem implements IDeliveryItem {
  constructor(
    public id = 0,
    public name = '',
    public type = DeliveryItemTypeEnum.CLOTHING,
    public amount = 0,
    public deliveryOrder = new DeliveryOrder,
  ) {
    // this.type.id = 443;
  }
}
export interface IDeliveryOrder {
  id: number;
  businessEntity: IBusinessEntity;
  businessBranch: IBusinessBranch;
  addressedBusinessBranch: IBusinessBranch;
  transaction: ITransaction;

  recipientFullName: string;
  recipientAddress: string;
  amount: number;
  status: StatusEnum;
  description: string;

  type: DeliveryOrderTypeEnum;
  businessVehicle?: IGroup;

  deliveryItems: IDeliveryItem[];
  deliveryItemsCount?: number;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'DeliveryOrder' })
export class DeliveryOrder implements IDeliveryOrder {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public businessBranch = new BusinessBranch,
    public addressedBusinessBranch = new BusinessBranch,
    public transaction = new Transaction,
    public recipientFullName = '',
    public recipientAddress = '',
    public amount = 0,
    public status = StatusEnum.UNLISTED,
    public type = DeliveryOrderTypeEnum.STANDARD,
    // public businessVehicle = new Group,
    public description = '',

    public deliveryItems = [],
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
  ) {
    // this.status.id = 4; // 2 - published/ retrieved by reciepient, 1 - unpublished/arrived @ branch, 3 - trash/cancelled, 4 - sending
    // this.type.id = 1; // standard / express / free
  }
}


export interface IDeliveryType {

}

@Controller({ name: 'DeliveryType' })
export class DeliveryType implements IDeliveryType {
  constructor() { }
}
