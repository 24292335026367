<section class="adx-section {{ bgClass }}">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size34">Our Clients.</h1>
            <!-- <span class="text-size18">
              Sign in to use your favorite productivity apps from any device.
            </span> -->
          </div>
          <p></p>
          <div class="suite-apps-flex">
            <div class="suite-app" *ngFor="let item of clients">
              <a [href]="item.url" target="_blank">
                <div class="adx-img">
                  <img [src]="item.logo" class="img-responsive" alt="" />
                </div>
              </a>
              <span class="name">
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
