<div class='cover'>

  <div class="slider" [class.is-loading]="isLoading">

    <div class="slide">
      <!-- <owl-carousel-o [options]="owlOptions" *ngIf="!isLoading">
        <ng-container *ngFor="let item of data; index as i">
          <ng-template carouselSlide class="render"> -->

      <galaxy-media-artwork *ngIf="!isLoading" class='adx-card adx-flat adx-round no-margin no-padding'
        style="max-width: 100%; max-height: 100%; overflow: hidden; margin:0;" [artwork]="selectedArtwork"
        [isArtwork]="false">
      </galaxy-media-artwork>

      <!-- </ng-template>
        </ng-container>

      </owl-carousel-o> -->
    </div>




    <div class="adx-card adx-flat adx-round thumbnails" *ngIf="data.length > 1">
      <div class="clearfix"></div>
      <div class="artworks-flex">
        <div *ngFor="let item of processedData; let i = index" class="adx-card bg-black adx-img adx-full adx-round"
          (click)="onArtworkSelect(data[i])">
          <img class="img-responsive" [errorImage]="imageToShowOnError" [lazyLoad]="item" alt="item.thumbnail" />
        </div>
      </div>
    </div>
  </div>
</div>
