import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent implements OnInit {

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this._appSetting.setTheme('light');
  }
}
