<div class="padding-sm">
  <div class="adx-avatar adx-flat bg-black " *ngIf="showAppInfo">
    <div class="adx-img">
      <img src="/assets/images/logo/{{appClaim.app.name}}.png" alt="" />
    </div>
    <h2>
      {{appClaim.app.name}}
    </h2>
    <p>
      {{appClaim.app.description}}
    </p>
  </div>
</div>

<div class="adx-divider">My Access Level</div>

<div class="adx-list adx-sm adx-flat adx-ellipse padding-sm" style="min-height: 200px; padding-top: 0;">

  <div class='adx-item {{theme}}' *ngFor="let role of appClaimRoles" (click)='onRoleSelected(role)'>
    <h3 class="color-tgreen text-normal" [class.color-pink]="theme ==='light'">
      <i class="axr ax-user color-tgreen" [class.color-pink]="theme ==='light'" style="opacity: 0.4;"></i>

      {{ role.roleName | maxLength: 24 }}
    </h3>

    <p>
      <i class="axr ax-map-pin" style="opacity: 0.4;"></i>
      {{
      role.businessBranchDepartment.businessDepartment.name
      | maxLength: 15
      }}
      -
      {{
      role.businessBranchDepartment.businessBranch.name
      | maxLength: 15
      }}
    </p>

  </div>
</div>
