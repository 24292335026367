<div class="adx-section bg-black">
  <div class="title-bar">
    <div class="title">
      {{title}}
    </div>
    <br>
    <div class="control">
      <button class="adx-btn adx-icon clear adx-flat adx-round" (click)="onScroll(-1)">
        <i class="axl ax-chevron-left"></i>
      </button>
      <button class="adx-btn adx-icon clear adx-flat adx-round" (click)="onScroll(1)">
        <i class="axl ax-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="slider-container" [id]="'CardScroller-'+index">
    <div class="slide-list">
      <div class="slide-item" *ngFor="let item of data" (click)="linkClicked(item)">
        <img [src]="item.image" alt="">
        <div class="detail">
          <div class="top">{{item.label}}</div>
          <div class="center"></div>
          <div class="bottom">{{item.description}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
