import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKey'
})
export class EnumPipe implements PipeTransform {

  transform(value: number, enumType: object): string {

    let key = '';
    let keyArray: string[] = (enumType[value] ?? '??').split('_');

    keyArray.forEach((element: string) => {
      const word = element.toLowerCase();

      key += ' ' + word[0].toUpperCase() + word.substring(1);
    });


    return key;
  }

}
