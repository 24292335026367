import { Controller } from "../decorators";

export interface xIBlog {
  id: number;
  content: string;

  image?: string[];
  video?: string;
  link?: string;

  date: string;
  comments: number;
  views: number;
  likes: number;

  personId: number;
  personName: string;
  personUsername: string;
  personVerified: boolean;
}

@Controller({ name: 'xBlog'})
export class xBlog implements xIBlog {
  constructor(
    public id = 0,
    public content = '',
    public date = '',
    public comments = 0,
    public views = 0,
    public likes = 0,
    public personId = 0,
    public personName = '',
    public personUsername = '',
    public personVerified = false
  ) {}
}
