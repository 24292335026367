import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-btns',
  templateUrl: './action-btns.component.html',
  styleUrls: ['./action-btns.component.scss']
})
export class ActionBtnsComponent implements OnInit, OnDestroy {

  showBtns = false;
  theme: string = 'light'; 
themeUpdated$!:Subscription;

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);
  }


  onToggleShowBtns() {
    this.showBtns = !this.showBtns;
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
