import { Component, OnInit, Input } from '@angular/core';
import { IEntity, Entity, AppSettingService, AuthService, EmbedService, ArtistApiService, Project, ErrorHandlerService } from '@galaxy/entity-api';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'galaxy-media-card-post',
  templateUrl: './media-card-post.component.html',
  styleUrls: ['./media-card-post.component.scss']
})
export class MediaCardPostComponent implements OnInit {
  @Input() entityType: 'photo' | 'video' | 'audio' | 'product' = 'photo';

  entity: IEntity = new Entity;
  descriptionFullMode: boolean = true;

  description: string = '';

  modalNumber: number = 1;
  showModal: boolean = false;
  isLoading: boolean = true;
  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _embed: EmbedService,
    private _aRoute: ActivatedRoute,

    private _artistApi: ArtistApiService,
    private _location: Location,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // this.entity.person.name = 'Person Name';
    // this.entity.description = "This red curry coconut cauliflower rice is packed with flavour and nutrition but comes in at only 170 calories and 27 grams of carbs for a large and filling serving! It's low in fat, has 8 gram of protein and 10 grams of fibre per serving and is gluten-free and vegan. Try adding your favourite protein source to boost the protein even further! Tofu, edamame, chickpeas or lentils would all be excellent options.";
    this.isLoading = true;
    let id: string;
    this._aRoute.params.subscribe(res => {
      id = res.data.id
      this.getData(id);
    })


  }

  getData(id: string) {
    this._artistApi
      .setParams({}, 'stand/open/' + id)
      .get(Project)
      .subscribe(
        {
          next: (res) => {
            // console.log('this is the reply', res);
            this.entity = res.data;
            // this._artistApi.DataMap({ from: res, to: <Project>this.entity });
            // this.content = this._sanitizer.bypassSecurityTrustHtml(this.data.description);
            // this.art = this.embedImage(this.data.art);
            // console.log('data is now', this.data, res);
            // this.art = res.art;
            this.onFormatDescription();
            this.isLoading = false;
            // console.log(this.entity);

            // _$(res).MapTo(this.album);

            // this.getDataComments();
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  getEntityLink() {
    return 'localhost:4200/artwork/id';
  }

  onBack() {
    this._location.back();
    // window.history.back()
  }

  onShowModalClicked(type: number) {
    this.modalNumber = type;
    this.showModal = true;
  }

  onCloseClicked($event) {
    this.showModal = false;
  }

  onFormatDescription() {
    if (!this.descriptionFullMode) {
      this.description = this.entity.description;
      this.descriptionFullMode = true;
    } else {
      if (this.entity.description.length > 200) {
        this.description = this.entity.description.slice(0, 200) + '...';
        this.descriptionFullMode = false;
      } else {
        this.description = this.entity.description;
      }
    }

    // console.log('from et to d', this.entity.description, this.description);
  }


  getImage(link) {
    return this._embed.imgUrl(link);
  }

}

