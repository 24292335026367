import { Component, OnInit, Input, SecurityContext, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Product, EmbedService, OriDbService, NotifyService, IEntity, AppSettingService } from '@galaxy/entity-api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss']
})
export class MediaItemComponent implements OnInit, OnDestroy {

  @Input() data: Product = new Product;
  @Input() entityType: string = '';
  @Input() isCart: boolean = true;

  cover: string = '';
  salesPrice: number = 0;
  showDiscount: boolean = false;
  @Output() addToCollection = new EventEmitter<IEntity>();
  @Output() viewEntity = new EventEmitter<IEntity>();
  @Output() orderCountChanged = new EventEmitter<number>();
  @Output() removeProduct = new EventEmitter<number>();

  theme: string = 'dark';themeUpdated$!: Subscription;
  showModal: any;

  constructor(
    private _embed: EmbedService, private _sanitizer: DomSanitizer,
    private _appSetting: AppSettingService,
    private _oriDB: OriDbService,
    private _notify: NotifyService
  ) { }

  ngOnInit(): void {
    // theme
    this.theme = this._appSetting.defaultTheme;

    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });

    this.cover = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.art, 'md'));
    this.processPice();
  }
  processPice(): void {
    if (this.data.discount != 0) {
      this.salesPrice = this.data.price - (this.data.discount / 100 * this.data.price);
      this.showDiscount = true;
    } else {
      this.salesPrice = this.data.price;
      this.showDiscount = false;
    }
  }


  onAddToCart($event): void {
    $event.stopPropagation();
    this.data.myOrderCount = this.data.minOrderCount;

    if (this._oriDB.addToCart(this.data as Product)) {
      this._notify.success('Product added to cart')
    } else {
      this._notify.success('Product already exists in cart')
    }


  }

  onViewEntity(): void {
    this.viewEntity.emit(this.data);
    //record to recently viewed products
    this._oriDB.addToRecentViewedProduct(this.data as Product);

  }


  onCollectionClicked($event): void {
    $event.stopPropagation()
    this.addToCollection.emit(this.data);

  }


  onChangeMyOrderCount(increaseCount: boolean) {
    increaseCount ? ++this.data.myOrderCount : --this.data.myOrderCount;
    this.orderCountChanged.emit(this.data.myOrderCount)
  }


  onRemoveProductFromCart() {
    this.removeProduct.emit(this.data.id)
    this.showModal = false;
  }
  onShowDelete() {
    this.showModal = true;
  }

  onCloseClicked($event) {
    this.showModal = false;
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
