import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'galaxy-adx-flex',
  templateUrl: './ad-flex.component.html',
  styleUrls: ['./ad-flex.component.scss']
})
export class AdFlexComponent implements OnInit {

  @ViewChild('flexContainer') flexContainer: ElementRef;

  @Input() title: string = '';

  @Input() showTitle: boolean = true;
  @Input() miniPaddingForItems: boolean = false;


  @Input() scrollAmount: number = 200;

  constructor() { }

  ngOnInit() {
  }


  onScroll(direction: number) {
    // const scrollableDiv: HTMLDivElement = document.querySelector('#CardScroller-' + this.index) as HTMLDivElement;
    if (this.flexContainer)
      this.flexContainer.nativeElement.scrollBy({
        left: this.scrollAmount * direction,
        behavior: 'smooth'
      })
  }

}
