import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './GenericService';

@Injectable()
export class UserService extends GenericService {
  constructor(http: HttpClient) {
    const baseUrl = '//127.0.0.1/airStudioAPI/identityApi';
    super(http, baseUrl);
  }
  // private get(
  //   date?: string,
  //   page?: number | string,
  //   pageSize?: number | string,
  //   id?: number | string,
  //   category?: string,
  //   searchValue?: string,
  //   searchFields?: string[],
  //   uri?: string
  // ): any {
  //   const search = new HttpParams();
  //   if (date) {
  //     search.set('date', date);
  //   }
  //   if (page) {
  //     search.set('page', page.toString());
  //   }
  //   if (id) {
  //     search.set('id', id.toString());
  //   }
  //   if (category) {
  //     search.set(
  //       'category',
  //       category.charAt(0).toUpperCase() + category.slice(1)
  //     );
  //   }

  //   if (searchValue) {
  //     search.set('searchValue', searchValue);
  //   }
  //   if (searchFields) {
  //     searchFields.forEach(field => search.set('searchFields', field));
  //   }

  //   const serviceUrl = uri ? uri : this.serviceUrl;
  //   // console.log('service get here', searchValue, this.serviceUrl, this._authService.RequestOptions(search));

  //   return this._http
  //     .get<any>(serviceUrl, this._authService.RequestOptions(search))
  //     .pipe(catchError(this.errorHandler));
  // }

  // errorHandler(error: HttpErrorResponse) {
  //   return observableThrowError(error.message || 'Server Error');
  // }

  // paramExists(field: string, value: string): Observable<any> {
  //   // this.get(null, null, null, null, null, value, [field], null);
  //   // return this.ObservableResults;
  //   // let params = { searchFields: [field], searchValue: value }
  //   const params = '?searchField=' + field + '&searchValue=' + value;
  //   return this._http.get<any>(this.serviceUrl + params);
  // }

  // usernameExists(username: string) {}

  // signUp(user: any): Observable<any> {
  //   console.log(JSON.stringify(user));
  //   return this._http.post<any>(this.serviceUrl, JSON.stringify(user));
  // }

  // updateInfo(user: any, id: number): Observable<any> {
  //   console.log(JSON.stringify(user));
  //   return this._http.put<any>(
  //     this.serviceUrl + '/' + id,
  //     JSON.stringify(user)
  //   );
  // }
}
