import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-content-slide',
  templateUrl: './content-slide.component.html',
  styleUrls: ['./content-slide.component.scss']
})
export class ContentSlideComponent implements OnInit {

  @ViewChild('contentContainer') contentContainer: ElementRef;

  scrollAmount: number = 200;
  constructor() { }

  ngOnInit() {
  }

  onScroll(direction: number) {
    // const scrollableDiv: HTMLDivElement = document.querySelector('#CardScroller-' + this.index) as HTMLDivElement;
    if (this.contentContainer)
      this.contentContainer.nativeElement.scrollBy({
        left: this.scrollAmount * direction,
        behavior: 'smooth'
      })
  }

}
