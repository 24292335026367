import { StatusEnum } from '../../enums';
import { Controller } from '../decorators';
import { Group } from '../ori/group.model';
import { IBusiness, Business } from './business.model';

export interface IBusinessBranch {
  // Basic Info
  id: number;

  name: string;
  continent: string;
  country: string;
  state: string;
  city: string;
  location: string;
  address: string;
  phone: string;
  email: string;
  isHq: boolean;
  created?: number;
  modified?: number;
  status: StatusEnum;
  isOpen: boolean;

  geoLocation: { longitude: number, latitude: number }

  business: IBusiness;

}

@Controller({ name: 'BusinessBranch' })
export class BusinessBranch implements IBusinessBranch {
  constructor(
    public id = 0,
    public name = '',
    public continent = 'AF',
    public country = 'GH',
    public state = '',
    public city = 'Accra',
    public location = '',
    public address = '',
    public phone = '',
    public email = '',
    public isHq = false,
    public status = StatusEnum.PUBLISHED,
    public isOpen = false,
    public geoLocation = { longitude: -1.6230107134574407, latitude: 6.694190456933313 },

    public business = new Business,
  ) {

  }

}

@Controller({ name: 'BranchOpenStatusData' })
export class BranchOpenStatusData {
  constructor(
    public status = 0,
    public businessBranchId = 0
  ) { }
}

