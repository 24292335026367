<!-- <div class="adx-card adx-ellipse outline adx-flat adx-full"> -->
<div class="poll-flex">
  <div class="pollz">
    <div *ngFor="let poll of polls; index as i" class="adx-input adx-label adx-flat adx-round" style="margin: 8px 0;">
      <!-- <label for="choice{{ i + 1 }}">Choice {{ i + 1 }} <em *ngIf="i > 1">(Optional)</em></label> -->
      <input type="text" (change)="onPollUpdated()" maxlength="20"
        [placeholder]="'Choice '+ (i + 1) + (i > 1 ? ' (Optional)':'')" [(ngModel)]="poll.title" />
    </div>
  </div>
  <div class="btnz" style="display: flex; flex-direction: column;">
    <div style="flex:1"></div>
    <button *ngIf="polls.length < maxPollLength" class="adx-btn adx-icon btn-tgreen adx-flat adx-round add-btn"
      (click)="onAddPoll()" style="margin-top:0">
      <i class="axr ax-plus"></i>
    </button>
  </div>
</div>
<!-- </div> -->

<!-- <div class="adx-divider" style="margin-bottom: 0">Poll length</div> -->
<div class="input-flex">

  <div class="adx-input adx-label adx-flat adx-round">
    <label for="days">Days</label>
    <select name="days" [(ngModel)]="daysSelected" (change)="onPollLengthChange('days')">
      <option *ngFor="let item of days" [value]="item">{{ item }}</option>
    </select>
  </div>


  <div class="adx-input adx-label adx-flat adx-round">
    <label for="hours">Hours</label>
    <select name="hours" [(ngModel)]="hoursSelected" (change)="onPollLengthChange('hours')">
      <option *ngFor="let item of hours" [value]="item">{{ item }}</option>
    </select>
  </div>


  <div class="adx-input adx-label adx-flat adx-round">
    <label for="minutes">Minutes</label>
    <select name="minutes" [(ngModel)]="minutesSelected" (change)="onPollLengthChange('minutes')">
      <option *ngFor="let item of minutes" [value]="item">
        {{ item }}
      </option>
    </select>

  </div>
</div>
