<galaxy-adaptive-card [showPadding]="false">
  <div class="event">
    <div class="bg-special"></div>
    <div class="date">
      <div class="month">
        MAY
      </div>
      <div class="day">
        13
      </div>
    </div>
    <div class="art">
      <img [src]="art" class="img-responsive" />
    </div>
    <div class="topic">
      {{ entity.title }}
    </div>
    <div class="action">
      <i class="fal fa-angle-right"></i>
    </div>
  </div>
</galaxy-adaptive-card>
