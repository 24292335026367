import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-quick-menu',
  templateUrl: './quick-menu.component.html',
  styleUrls: ['./quick-menu.component.scss']
})
export class QuickMenuComponent implements OnInit {
  color: string = '';
  activeCard: number = 0;
  constructor(private _appSetting: AppSettingService) {
    this.color = this._appSetting.defaultColor;
    // this._appSetting.color.subscribe(res => {
    //   this.color = res;
    // });
  }

  ngOnInit() { }

  onHoverCard(cardNumber: number) {
    this.activeCard = cardNumber;
  }
}
