import { Controller } from "../decorators";

export interface IMetric {
  title: string;
  icon: string;
  color: string;
  count: number
}

export class Metric implements IMetric {
  constructor(
    public title = '',
    public icon = '',
    public color = '',
    public count = 0
  ) { }
}



export interface IGraph {
  name: string,
  type: string,
  data: {}
  raw: any[]
}


export class Graph implements IGraph {
  constructor(
    public name = '',
    public type = 'line',
    public data = {},
    public raw = []
  ) { }
}


export interface IDashboard {
  metrics: IMetric[],
  graphs: IGraph[]

}


@Controller({ name: 'dashboard' })
export class Dashboard implements IDashboard {
  constructor(
    public metrics: IMetric[] = [],
    public graphs: IGraph[] = []
  ) { }
}
