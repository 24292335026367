import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IEntity, EmbedService, Entity } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit {

  @Input() entity: IEntity = new Entity;
  art: SafeResourceUrl = '';
  constructor(
    private _embed: EmbedService,
  ) { }

  ngOnInit() {
    this.art = this._embed.imgUrl(this.entity.art, 'md');
  }

}
