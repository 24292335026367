import { Routes } from '@angular/router';
import { SetupRoutes } from './setup/setup.router';
import { LoginComponent } from './login/login.component';
import { ColdBootComponent } from './cold-boot/cold-boot.component';
import { ColdBootService } from '@galaxy/entity-api';
import { TwoWayAuthComponent } from './two-way-auth/two-way-auth.component';

export const LoginRoutes: Routes = [
  {
    path: 'unauthorized',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [ColdBootService] // only enable for ori ... for now
  },
  {
    path: 'confirm',
    component: TwoWayAuthComponent,
    // canActivate: [ColdBootService] // only enable for ori ... for now
  },
  {
    path: 'welcome',
    component: ColdBootComponent
  },
  ...SetupRoutes
];
