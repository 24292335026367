import { IBusiness, Business } from './business.model';
import { IGroup, Group } from '../ori/group.model';
import { Controller } from '../decorators';
import { BusinessBranch, IBusinessBranch } from '..';
import { StatusEnum } from '../../enums';

export interface IBusinessRecruit {
  id: number;
  title: string;
  tag: string;
  description: string;
  isRemote: boolean;
  link: string;
  location: string;
  business: IBusiness;
  businessBranch: IBusinessBranch;
  created?: number;
  modified?: number;
  status: StatusEnum,
  hit: number;
  applyCount: number;
  applyTargetCount: number;
}

@Controller({ name: 'BusinessRecruit' })
export class BusinessRecruit implements IBusinessRecruit {
  constructor(
    public id = 0,
    public title = '',
    public tag = '',
    public description = '',
    public isRemote = false,
    public link = '',
    public location = '',
    public business = new Business,
    public businessBranch = new BusinessBranch,
    public status = StatusEnum.PUBLISHED,
    public hit = 0,
    public applyCount = 0,
    public applyTargetCount = 100,

  ) {
  }

}
