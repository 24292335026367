import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { IEntity, Entity, ArtistApiService, Project, EmbedService, AuthService, NotifyService, AppSettingService, Group, ErrorHandlerService } from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-masonry-grid',
  templateUrl: './masonry-grid.component.html',
  styleUrls: ['./masonry-grid.component.scss']
})
export class MasonryGridComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isGrid: boolean = false;
  @Input() showOptions: boolean = false;
  @Input() showDetail: boolean = true;
  @Input() isMini: boolean = false;
  @Input() isLoading: boolean = true;

  @Input() showBookmark: boolean = true;
  @Input() data: IEntity[] = [];

  @Input() title: string = '__';
  @Input() sortBy: string = 'latest';
  @Input() searchValue: string = '';
  @Input() personId: number = 0;
  @Input() dataLimit: number = 50;

  pageUrl: string = 'https://aiira.app/artwork/?id=';
  entityPageUrl: string = '';



  showModal: boolean = false;
  showImageModal: boolean = false;
  modalNumber: number = 1;

  pageSize: number = 50;
  page: number = 1;
  totalData: number = 0;

  entity: IEntity = new Entity;
  currentMenu: string = 'discover'
  projectId: number = 30;
  prevProject?: IEntity = null;
  prevProjectIndex: number = 0;
  nextProject?: IEntity = null;
  nextProjectIndex: number = 0;

  isLoggedIn: boolean = false;
  theme: string = 'dark';
  themeUpdated$!: Subscription;

  orderByArr = {
    newEntity: 'featured, likeCount, commentCount, id',
    hotEntity: 'featured, hit, id',
    topEntity: 'hit, id, created'

  };
  orderBy: string = 'id';
  @Input() mediumId: number = 0;
  @Input() subjectId: number = 0;
  subjectMatter: any[];


  viewerId: number = 0;
  menu: { name: string; icon?: string; route?: string }[] = [];

  masonryOptions = {
    transitionDuration: '0.35s',
    gutter: 15,
    horizontalOrder: true,
    percentPosition: true
  };
  entitySelected: IEntity;
  isFirstData = false;

  constructor(
    private _artistApi: ArtistApiService,
    private _authService: AuthService,
    private _notify: NotifyService,
    private _appSetting: AppSettingService,
    private _storageMap: StorageMap,
    private _errorHandlerService: ErrorHandlerService


  ) {
    this.theme = this._appSetting.defaultTheme;

    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });

    // change grid gaps at small screen
    if (this._appSetting.defaultInnerWidth < 620) {
      this.masonryOptions.gutter = 5;
    }
    this._appSetting.innerWidth.subscribe(res => {
      if (res < 620) {
        this.masonryOptions.gutter = 5;
      }
    })

  }

  ngOnInit() {
    this.isLoggedIn = this._authService.loggedIn;
    if (this.isLoggedIn) {
      this.viewerId = this._authService.currentUser.gid.social.u;
    }

    this.pageSize = this.dataLimit;




    // set btn group for nav
    this.menu = [
      {
        name: 'Discover',
      },
      {
        name: 'Trending',
      },
      {
        name: 'Latest',
      },
      {
        name: 'Popular',
      },
    ]


    this.subjectMatter = [
      { id: 49, name: "Animation" },
      { id: 50, name: "Architectural Visualization" },
      { id: 51, name: "Architecture" },
      { id: 52, name: "Characters" },
      { id: 53, name: "Children's" },
      { id: 54, name: "Comic Art" },
      { id: 55, name: "Concept Art" },
      { id: 56, name: "Creatures" },
      { id: 57, name: "Environments" },
      { id: 58, name: "Fantasy" },
      { id: 59, name: "Game Art" },
      { id: 60, name: "Illustration" },
      { id: 61, name: "Industrial Design" },
      { id: 62, name: "Matte Painting" },
      { id: 63, name: "Mecha" },
      { id: 64, name: "Motion Graphics" },
      { id: 65, name: "Product Design" },
      { id: 66, name: "Pops" },
      { id: 67, name: "Science Fiction" },
      { id: 68, name: "Still Life" },
      { id: 69, name: "Storyboard" },
      { id: 70, name: "Storytelling" },
      { id: 71, name: "Surreal" },
      { id: 72, name: "Textures & Materials" },
      { id: 73, name: "Transport & Vehicles" },
      { id: 74, name: "Tutorial" },
      { id: 75, name: "User Interface" },
      { id: 76, name: "Virtual Reality" },
      { id: 77, name: "Weapons" },
      { id: 78, name: "Whimsical" },
    ] as Group[];

    this._getStorageData();
    if (this.data.length === 0) {

      this.onReload();
    } else {
      this.page = this.data.length;
      this.totalData = this.page;
      this.isLoading = false;
    }

  }



  private _resetData() {
    this.data = [];
    this.isFirstData = true;

  }
  private _getStorageData() {
    if (this.personId > 0) return;

    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_artWK';

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        if (res) {
          this.data = res.get(this.title) ?? [];
        }
      });

  }


  private _storeStorageData() {
    if (this.personId > 0) return;

    const domain = '_artWK';
    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        let dataMap = new Map<string, IEntity[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(this.title, this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }


  ngOnChanges(changes: SimpleChanges) {
    const search: SimpleChange = changes.searchValue;

    if (search && !search.firstChange && search.previousValue !== search.currentValue) {
      this.onReload();
    }

  }

  onReload() {
    this.isLoading = true;
    this.pageSize = this.dataLimit;
    this.page = 1;



    this.getData();

  }


  getData(): any {
    this._artistApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        category: this.subjectId.toString(),
        categoryType: this.mediumId,
        searchFields: [this.sortBy],
        searchValue: this.searchValue,
        id: this.personId,
        orderBy: [this.orderBy]
      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            const data = res.data as Project[];
            this.data.push(...data);
            this.page = res.meta.pagination.currentPage;
            // this.pageSize = res.meta.pagination.pageSize;
           this.totalData = res.meta.pagination.totalItems;;

            if (this.isFirstData) {
              this.data = data;
              this._storeStorageData();
              this.isFirstData = false;
            }

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onPaginateData(page: number) {
    this.isLoading = true;
    this.data = [];

    this.page = page;
    this.getData();

  }

  onSortData(sort: string, orderBy = 'id') {
    // reset limit value to default if its lower than 5
    this.isLoading = true;
    this.sortBy = sort;
    this.orderBy = orderBy;
    this.getData();
  }






  onShowModalClicked(entity: IEntity, type: number) {
    this.entity = entity;
    this.modalNumber = type;
    if (type === 2) {
      // if its collection
      if (this.isLoggedIn) {
        this.showModal = true;
      } else {
        this._notify.error('Please login to continue');
      }

    } else {
      // web share api
      if (type === 1 && navigator.share) {
        navigator.share({
          title: entity.title,
          text: entity.description,
          url: window.location.href
        }).then(() => {
          // console.log('Thanks for sharing!');
        })
          .catch(err => {
            // console.log(`Couldn't share because of`, err.message);
          });
      } else {
        // console.log('web share not supported');
        this.showModal = true;
      }

    }
  }

  onCloseClicked($event) {
    this.showModal = false;
    this.showImageModal = false;
  }
  getEntityLink() {
    return this.pageUrl + this.projectId;
  }

  onImageClicked(entity: IEntity, index: number) {
    // console.log('id @ masonry is ', entity)
    // this.showImageModal = false;
    this.entitySelected = entity;
    this.projectId = entity.id;
    const len = this.data.length;
    if (len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProject = this.data[this.prevProjectIndex];

      this.nextProjectIndex = (index + 1) % len;
      this.nextProject = this.data[this.nextProjectIndex];
      if (index === 0) {
        this.prevProject = null;

      }
      if (index == len - 1) {
        this.nextProject = null;
      }

    }

    // check if there is a prevous & next
    this.showImageModal = true;
  }



  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }


}
