<div
  class="photo"
  [routerLink]="['/artwork/id']"
  [ngClass]="{
    'bg-dark': theme == 'dark',
    'bg-white': theme == 'light',
    'adx-flat': isFlat,
    round: isRound
  }"
  style="background-image: url(http://127.0.0.1/airCloud/src/assets/images/users/julien-kaspar.jpg)"
>
  <div class="icons">
    <i class="fa fa-images"></i>
    <i class="fal fa-cube"></i>
    <i class="fa fa-video"></i>
  </div>
  <div
    *ngIf="showDetail"
    class="adx-card adx-flat"
    [ngClass]="{
      'bg-dark': theme == 'dark',
      'bg-white': theme == 'light'
    }"
  >
    <div class="position-content ">
      <h3 class="adx-title text-normal">Project TItle</h3>
      <br />
      <galaxy-avatar *ngIf="showPerson" [size]="'xs'"></galaxy-avatar>
    </div>
  </div>
</div>
