<div class="ui-status-bar">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="status-bar-flex">
          <div class="left-side">
            <span class="role">
              <span>
                Business > {{appClaim.app.name}} >
                {{activeRole.businessBranchDepartment.businessBranch.name}} >
              </span>
              {{activeRole.businessBranchDepartment.businessDepartment.name}} >
              {{activeRole.roleName}}
            </span>
          </div>
          <div class="right-side">
            <div class='float-right'>

              <span class="x-btn" (click)='onShowAccessModal()'>
                <i class="axl ax-fingerprint"></i>
                Access Level
                &nbsp;
              </span>
              <span class="x-btn">
                <i class="axl ax-brand-aiira"></i>
              </span>

              <span class="x-btn">
                <i class="axl ax-question-mark"></i>
              </span>

              <span class="x-btn">
                <i class="axl ax-notification"></i>
              </span>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>


<galaxy-modal [showModal]='showAccessModal' [showPadding]="false" (onCloseModalClicked)='onCloseAccessModal($event)'>
  <app-access-level-bar *ngIf="showAccessModal" [appClaimRoles]='appRoles' [appClaim]='appClaim' [showAppInfo]=false
    (onCloseModal)='onCloseAccessModal($event)'>
  </app-access-level-bar>
</galaxy-modal>
