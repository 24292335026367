<div class="adx-card adx-flat outlinex adx-round ux-left-menu" [ngClass]="{
    'show-menu': showMenu,
    'bg-dark':theme == 'dark',
    'bg-white':theme == 'light'
  }">
  <!--
    <div class="adx-logo">
      <div class="wrapper-fluid">
        <div class="adx-row">
          <div class="adx-colx-4">
            <div class="logo "><img src="" class="img-responsive" alt="" /></div>
          </div>
          <div class="adx-colx-8">
            <h3 class="title" style="text-transform:none;">{{ appName }}</h3>
            <small><i>version 0.9.5 Beta</i></small>
          </div>
        </div>
      </div>
    </div>
  -->
  <div class="menu-flex" (click)="onToggleMenu()">
    <div class="top part">
      <button class="adx-btn adx-icon adx-flat clear adx-round" [routerLink]="['/']">
        <i class="axl ax-brand-aiira"></i>
        <!-- <span>pps</span> -->
      </button>
    </div>

    <div class="menu" (click)="onMenuClicked($event)">
      <div class="menu-items {{theme}}">
        <button *ngFor="let item of menu" [routerLink]="[item.route]" routerLinkActive="active"
          class="adx-btn adx-icon adx-flat clear adx-rounder" [title]="item.name">
          <i [class]="item.icon" routerLinkActive="axr color-pink"></i>
          <span routerLinkActive="color-pink">
            {{item.name}}
          </span>
        </button>
      </div>
    </div>
    <div class="bottom-part">
      <!-- <button class="adx-btn adx-icon clear adx-flat adx-round" [routerLink]="['settings']">
        <i class="axl ax-settings"></i>
      </button> -->
      <button class="adx-btn adx-icon btn-white adx-round" style="position:relative" [routerLink]="['settings']"
        style="overflow: hidden;">
        <img [src]="image" style="width: 100%; height: 100%; object-fit: cover;">
        <!-- <span class="iconx">
          <i class="axl ax-settings"></i>
        </span> -->
      </button>
    </div>
  </div>
</div>



<galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" class="bottom-menu">
  <div class="flex-container">

    <div class="bottom-menu-flex">
      <button class="adx-btn adx-flat adx-ellipse home-btn " [ngClass]="{
        'btn-grey': theme === 'light',
        'btn-dark': theme === 'dark'
      }" [routerLink]="['/']" routerLinkActive="axr">
        <i class="axl ax-brand-aiira"></i>
        <span>
          Home
        </span>
      </button>


      <button *ngFor="let item of menu" [routerLink]="[item.route]" routerLinkActive="btn-special"
        class="adx-btn  adx-flat  adx-ellipse" [ngClass]="{
          'btn-grey': theme === 'light',
          'btn-dark': theme === 'dark'
        }">
        <i [class]="item.icon" routerLinkActive="axr"></i>
        <span>
          {{item.name}}
        </span>
      </button>


      <button class="adx-btn adx-ellipse" style="position:relative" routerLinkActive="btn-special"
        [routerLink]="['settings']" [ngClass]="{
        'btn-grey': theme === 'light',
        'btn-dark': theme === 'dark'
      }" style="overflow: hidden;">
        <span class="profile-img">
          <img [src]="image" style="width: 100%; height: 100%; object-fit: cover;">
        </span>
        <!-- <span class="iconx">
            <i class="axl ax-settings"></i>
          </span> -->
      </button>
    </div>


  </div>
</galaxy-adaptive-card>





<div class="adx-overlayX" (click)="showMenu=false" [class.show-overlay]="showMenu"></div>
