import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  following = false;

  @Input() isMini: boolean = true;
  @Input() size: string = 'sm';
  @Input() showLikes = true;
  @Input() data?: any;
  @Input() id: number = 0;
  color: string = '';

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;
  }

  onFollowClick() {
    this.following = !this.following;
  }
}
