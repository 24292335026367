<div class="menu adx-btn-group adx-{{ shape }}" [ngClass]="{
    'bg-darker': theme == 'dark',
    'bg-white': theme == 'light',
    'bg-transparent': !showBackgroundColor
  }">
  <span *ngIf="routeByEvent; then byClickEvent; else byRoute"></span>
</div>

<ng-template #byClickEvent>
  <button *ngFor="let item of menu; index as i" [ngClass]="{
      'btn-default':
        (reverseActiveColor && theme == 'dark') ||
        (!reverseActiveColor && theme == 'light'),
      'btn-dark':
        (reverseActiveColor && theme == 'light') ||
        (!reverseActiveColor && theme == 'dark'),
      'adx-shadow': !reverseActiveColor
    }" class="adx-btn activeClass" [class.active]="activeId === i" (click)="onMenuClicked(item, i)">
    <i *ngIf="autoShowIcons || (showIcons && item.icon)" [class.auto]="autoShowIcons" [class]="item.icon"></i>
    {{ item.name }}
  </button>
</ng-template>

<ng-template #byRoute>
  <button *ngFor="let item of menu" class="adx-btn activeClass" [ngClass]="{
      'btn-default':
        (reverseActiveColor && theme == 'dark') ||
        (!reverseActiveColor && theme == 'light'),
      'btn-dark':
        (reverseActiveColor && theme == 'light') ||
        (!reverseActiveColor && theme == 'dark'),
      'adx-shadow': !reverseActiveColor
    }" [routerLink]="[item.route]" routerLinkActive="active">
    <i *ngIf="autoShowIcons || (showIcons && item.icon)" [class.auto.ax-2x]="autoShowIcons" [class]="item.icon"></i>
    {{ item.name }}
  </button>
</ng-template>
