import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'galaxy-chart-tool',
  templateUrl: './chart-tool.component.html',
  styleUrls: ['./chart-tool.component.scss']
})
export class ChartToolComponent implements OnInit {

  @Input() theme: string = '';
  @Input() isLoading = true;
  @Input() xClass: string = '';
  @Input() title: string = 'Chat Tools';
  @Input() showTitle: boolean = true;
  @Input() chartType: string = '';
  @Input() xAxisData: string[] = [];

  @Input() data = [
    { name: 'No Data 1', type: 'line', data: { xAxis: [], yAxis: [] } },
    { name: 'No Data 2', type: 'line', data: { xAxis: [], yAxis: [] } }
  ];

  @Input() chartAlign: 'left' | 'auto' | 'right' = 'left';

  options: EChartsOption = {};
  private seriesData = [];
  isDataEmpty: boolean;
  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.data.length &&
      this.data[0].data.yAxis.length > 0) {
      this.isDataEmpty = false;
      this.setChartData()
    } else {
      this.isDataEmpty = true;
    }
  }

  setChartData() {

    // const xAxisData = [];

    const dataTypesLegend = [];

    this.data.forEach(element => {
      // set legend of chart
      dataTypesLegend.push(element.name);


      const yAxisData = [];

      this.xAxisData.forEach((x, index) => {
        // console.log('comparing x=>', x, element.data['xAxis'][index]);

        let found = false;
        for (let ii = 0; ii < element.data.xAxis.length; ii++) {
          const xx = element.data.xAxis[ii];
          if (xx == x) {
            found = true;
            yAxisData.push(element.data.yAxis[ii]);
            break;
          }

        }
        // element.data['xAxis'].forEach((xx, ii) => {
        //   if (xx == x) {
        //     found = true;
        //     yAxisData.push(element.data['yAxis'][index]);
        //   }
        // });

        if (!found) { yAxisData.push(0); }
        found = false;

      });


      // Set Series
      this.seriesData.push({
        name: element.name,
        type: element.type,
        data: yAxisData,
        itemStyle: {
          emphasis: {
            barBorderRadius: [12, 12]
          },
          normal: {
            barBorderRadius: [12, 12, 0, 0]
          }
        },
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        },
        animationDelay: function (idx) {
          return idx * 10;
        }
      });

      // console.log(this.seriesData);

    });




    this.options = {
      legend: {
        data: dataTypesLegend,
        align: this.chartAlign
      },
      grid: {
        left: 32,
        top: 32,
        right: 32,
        bottom: 32
      },
      tooltip: {},
      xAxis: {
        data: this.xAxisData,
        silent: false,
        splitLine: {
          show: false
        }
      },
      yAxis: {
      },
      series: this.seriesData,
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };
  }

  autoFillData() {

    const data1 = [];
    const data2 = [];

    this.data = [
      { name: 'No Data 1', type: 'line', data: { xAxis: [], yAxis: [] } },
      { name: 'No Data 2', type: 'line', data: { xAxis: [], yAxis: [] } }
    ];

    for (let i = 0; i < 12; i++) {
      this.xAxisData.push('category' + i);
      data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
      data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
    }

    this.data[0].data.xAxis = this.xAxisData;
    this.data[0].data.yAxis = data1;

    this.data[1].data.xAxis = this.xAxisData;
    this.data[1].data.yAxis = data2;

    // console.log('quick chart data view ', this.data);

    this.setChartData();
  }

}
