import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ICollection, AuthService, AppSettingService, ArtistApiService, StoreApiService, PlayApiService, NotifyService, Collection, ProfileService, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-my-collection',
  templateUrl: './my-collection.component.html',
  styleUrls: ['./my-collection.component.scss']
})
export class MyCollectionComponent implements OnInit, OnDestroy {


  @Input() collectionType: number = 84; // album = 83, collection = 84

  @Input() entityType: 'photo' | 'media' | 'product' = 'photo';
  @Input() icon: string = 'bookmark';
  @Input() isList: boolean = true;
  @Input() listSize: string = 'xs';
  @Input() isProfile: boolean = true;
  data: ICollection[] = [];
  isLoading: boolean = true;
  personId: number = 0;


  pageSize: number = 10;
  page: number = 1;
  totalData: number = 0;


  searchValue: string = '';

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  private _xApi;
  isCreatingCollection: boolean = false;
  newCollectionIsPrivate: boolean = false;
  activeCollection: ICollection = new Collection;
  showEntityCollection: boolean = false;



  constructor(
    private _authService: AuthService,
    private _appSetting: AppSettingService,
    private _artistApi: ArtistApiService,
    private _storeApi: StoreApiService,
    private _playApi: PlayApiService,
    private _profileService: ProfileService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // set this.theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.isLoading = true;
    this.searchValue = '';


    this.personId = this.isProfile ? this._profileService.defaultSocialProfile.person.id : 0;


    if (this._authService.loggedIn) {
      switch (this.entityType) {
        case 'photo':
          this._xApi = this._artistApi
          break;
        case 'media':
          this._xApi = this._playApi;
          break;

        case 'product':
          this._xApi = this._storeApi;
          break;

        default:
          this._xApi = this._artistApi;
          break;

      }
      this.getData();
    } else {
      this._notify.error('Please login to continue');
      this.isLoading = false;
    }
  }

  onSearch() {
    this.onReload();
  }

  onReload() {
    this.isLoading = true;
    this.pageSize = 10;
    this.page = 1;
    this.getData();

  }


  getData(): any {
    this._xApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.personId,
        category: this.collectionType,
        categoryType: 2 // get only public
      })
      .getPageOf(Collection)
      .subscribe(
        {
          next: (res) => {
            this.data = <Collection[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  // viewing the entities of a  collection

  onViewCollection(item: ICollection) {

    if (item.entityCount === 0) {
      this._notify.info('Sorry, ' + item.title + ' Collection is empty');
      return;
    }
    this.activeCollection = item;
    this.showEntityCollection = true;

  }

  onCloseClicked() {
    this.showEntityCollection = false;
  }


  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }


}
