<div class="device">
  <div class="adx-card adx-ellipse bg-black adx-image">
    <!-- <img [src]="activeSection" alt=""> -->
  </div>
</div>
<div class="info-section">
  <!-- intro to aiira -->
  <div class="adx-card adx-flat adx-ellipse bg-whitex text-center text-size18" #introSection>
    <p>
      <i class="axl ax-brand-aiira" style="opacity: .5;"></i>
    </p>

    <p>
      Aiira is a software company that is dedicated to developing innovative and user-friendly solutions for
      businesses and individuals.
      <br>
      <br>
      With a focus on providing integrated and comprehensive platforms, aiira offers a
      range of products and services that are designed to support and enhance a wide range of activities, from
      managing businesses and clients, to sharing content and engaging in online gaming.
      <br>
      <br>
      Our products are designed to be easy to use and highly effective, and we have a proven track record of success
      with a wide range of businesses and organizations. We believe that Aiira Technologies is well-positioned to
      support and enhance your operations, and we would be honored to have the opportunity to earn your trust and
      business.
    </p>

    <p>

    </p>

  </div>


  <p></p>
  <p></p>

  <div class="adx-section  bg-xblack" #qompassSection>
    <div class="wrapper-fluid">
      <!-- <div class="adx-row">
        <div class="adx-cols-12"> -->
      <div class="adx-card bg-white adx-ellipse adx-flat text-center ">
        <div class="adx-card adx-img adx-round adx-flat" style="border-radius: 24px" [routerLink]="['/business']">
          <img src="assets/images/business/business-apps-home.jpg" class="img-responsive" />
        </div>
        <br>
        <h2 class='text-normal text-size34' style="max-width: 600px; margin:0 auto;">Qompass: Business Services and
          Business management tools.</h2>
        <p class="text-size18 no-margin">
          At the heart of aiira's offerings is Qompass, a platform that connects businesses with their customers and
          clients by displaying them on a convenient and interactive map. With Qompass, businesses can easily
          register
          and promote their services, and customers can quickly and easily find the businesses and services that
          they
          need.</p>
        <button class="adx-btn btn-dark adx-sm  adx-round adx-flat clearx" routerLink="/business">
          Learn more >>
        </button>
      </div>
      <!-- </div>
      </div> -->

      <div class="col-grid">
        <!-- <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">

            Browse through categories of businesses near you

          </h2>

          <img src="/assets/images/home/directory.jpg" alt="" class="img-responsive">
        </div> -->

        <!-- <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">

            Enjoy the power of airSuite to manage your business

          </h2>
          <img src="/assets/images/home/tools.jpg" alt="" class="img-responsive">
        </div> -->

        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">

            Provide and Order for local services and products.

          </h2>
          <img src="/assets/images/home/services.jpg" alt="" class="img-responsive">
        </div>

        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">

            Register your business and get discovered by the community

          </h2>
          <img src="/assets/images/home/discover.jpg" alt="" class="img-responsive">
        </div>
      </div>



    </div>
  </div>

  <p></p>
  <p></p>

  <div class="adx-section  bg-xblack" #airSuiteSection>
    <div class="wrapper-fluid">
      <!-- <div class="adx-row">
        <div class="adx-cols-12"> -->
      <div class="adx-card adx-flat adx-ellipse bg-white text-center ">

        <div class="adx-card adx-img adx-round adx-flat" style="border-radius: 24px" [routerLink]="['/suite']">
          <img src="/assets/images/home/airSuite.jpg" class="img-responsive" />
        </div>
        <br>
        <h2 class='text-normal text-size34' style="max-width: 600px; margin:0 auto;">Get the power to take your
          business to the next level.</h2>
        <p class="text-size18">
          Building on the success of Qompass, aiira has also developed airSuite, a hybrid system for managing
          businesses that includes powerful apps for client management, financial management, hospital management,
          school management, and many other key areas.
        </p>
        <button class="adx-btn btn-dark adx-sm  adx-round adx-flat clearx" routerLink="/suite">
          Learn more >>
        </button>
      </div>
      <!-- </div>
      </div> -->


      <div class="col-grid">
        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">
            Easily control your
            <br>
            business from any place.
          </h2>
          <img src="/assets/images/home/control.jpg" alt="" class="img-responsive">
        </div>

        <!-- <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">
            Seamlessly connected workplace everywhere.
          </h2>
          <img src="/assets/images/home/connected.jpg" alt="" class="img-responsive">
        </div>

        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">
            Runs on all Operating Systems
          </h2>
          <img src="/assets/images/home/os.jpg" alt="" class="img-responsive">
        </div> -->

        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">
            All with the security
            <br>
            and privacy of Aiira.
          </h2>
          <img src="/assets/images/home/privacy.jpg" alt="" class="img-responsive">
        </div>
      </div>



    </div>
  </div>


  <div class="adx-section bg-xblack" #connectSection>
    <div class="wrapper-fluid">
      <!-- <div class="adx-row">
        <div class="adx-cols-12"> -->
      <div class="adx-card bg-white adx-ellipse adx-flat text-center ">
        <div class="adx-card adx-img adx-round adx-flat" style="border-radius: 24px" [routerLink]="['/connect']">
          <img src="/assets/images/play/aiira-apps-home.jpg" class="img-responsive" />
        </div>
        <br>
        <h2 class='text-normal text-size34' style="max-width: 600px; margin:0 auto;">Social & Distribution
          Platform.</h2>
        <p class="text-size18 no-margin">
          Connect is a powerful platform that brings together social media, content sharing, and e-commerce capabilities
          in one convenient and user-friendly package. With Connect, users can post feeds, blog articles, photos and
          artworks, and share them with their friends and followers. Connect also includes tools for organizing and
          promoting events, such as the ability to sell tickets and manage attendee lists..</p>
        <button class="adx-btn btn-dark adx-sm  adx-round adx-flat clearx" routerLink="/connect">
          Learn more >>
        </button>
      </div>
      <!-- </div>
      </div> -->

      <div class="col-grid">
        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">

            Everything from Digital
            <br> Products to Physical Goods.

          </h2>

          <img src="/assets/images/home/shop.jpg" alt="" class="img-responsive">
        </div>

        <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
          <h2 class="text-normal text-size18 padding">
            Stream high-definition
            <br> music and videos

          </h2>
          <img src="/assets/images/home/stream.jpg" alt="" class="img-responsive">
        </div>

      </div>



    </div>
    <!-- <div class="wrapper">
      <div class="adx-row">
        <div class="adx-cols-6">
          <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
            <h2 class="text-normal text-size18 padding">
              <small style="display: inline-block;" class="padding-sm">
                Online Store
                6</small>
              <br>
              Everything from Digital
              <br> Products to Physical Goods.
            </h2>
            <a href="https://aiira.app/store" target="_blank" class="adx-btn adx-round adx-flat btn-pink adx-sm">
              Start Shopping
            </a>
            <img src="/assets/images/home/shop.jpg" alt="" class="img-responsive">
          </div>
        </div>
        <div class="adx-cols-6">
          <div class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
            <h2 class="text-normal text-size18 padding">
              <small style="display: inline-block;" class="padding-sm">
                <span
                  style="display: inl6ne-block; vertical-align: top; width: 30px; margin-right: -8px; margin-top:-2px; filter: invert(100%);">
                  <img class="img-responsive" src="/assets/images/logo/logo.png" alt="" />
                </span>
                <b>
                  play
                  <span class="color-special">
                    UHD
                  </span>
                </b>

              </small>
              <br>
              Stream high-definition
              <br> music and videos
            </h2>
            <a href="https://aiira.app/play" target="_blank" class="adx-btn adx-round adx-flat btn-pink adx-sm">
              Start Streaming
            </a>
            <img src="/assets/images/home/stream.jpg" alt="" class="img-responsive">
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="adx-row">
        <div class="adx-cols-12">
          <div style="min-height: 400px; margin-top: 16px;"
            class="adx-card bg-white adx-flat adx-ellipse no-margin text-center">
            <div class="adx-row">
              <div class=' adx-cols-8 adx-img'>
                <div class="padding" style="margin-left: 16px; padding-bottom: 0; margin-bottom: -20px;">
                  <img src="/assets/images/home/your_home_at_your_command__b1w2tomy2wia_large.jpg" alt=""
                    class="img-responsive">
                </div>
              </div>
              <div class='adx-cols-4  text-center'>
                <div style="height: 150px;">

                </div>
                <div class="adx-img" style="width: 80px; margin:0 auto; margin-bottom: -16px;">
                  <img src="assets/images/play/social.png" class="img-responsive" alt="" />
                </div>
                <h2 class="text-normal text-size30">
                  One place
                  <br>
                  for everything
                </h2>
                <a href='https://aiira.app' target="_blank" class="adx-btn btn-pink  clear adx-flat adx-sm adx-round">
                  <b style="letter-spacing: 2px;">
                    Explore LinkApp >
                  </b>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
