<section class="adx-section bg-black bg-x">
  <div class="bg-special2">

  </div>
</section>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center" style="margin-top:-150px">
          <a href="https://suite.aiira.co" target="_blank" class="adx-logo">
            <img src="assets/images/play/airplay.png" class="img-responsive" alt="" />
          </a>
          <h2 class=" text-normal text-size24">Web Interverse</h2>
          <!-- <div>
            <img src="assets/images/play/aiira-apps-home.jpg" class="img-responsive" alt="" />
          </div> -->
          <div class="text-center bg-pinkx">
            <span class="text-size16">
              Web Interverse Game Engine
            </span>
            <br />
            <a class="adx-btn adx-round adx-sm btn-prim outline" href="https://connect.aiira.co" target="_blank">
              &nbsp;&nbsp;&nbsp; Get Connected &nbsp;&nbsp;&nbsp;
            </a>
            <a class="adx-btn adx-round adx-sm btn-prim outline" href="https://play.aiira.co" target="_blank">
              Develop Your Game
            </a>
          </div>
        </div>

        <div class="adx-card adx-flat text-center text-size14">
          <p>

            Introducing the web-based game engine for the Web Interverse! Create games directly from the web,
            no downloads required. Experience seamless performance across all platforms with our <b
              class="color-special">Progressive Web App</b>.
            <br>
            Harness the power
            of <b class="color-special">Three.js</b> rendering and <b class="color-special">Rapier.js</b> physics to
            bring your gaming visions to
            life. Unleash your creativity
            with our Material Editor, Blueprint system, Build Mode, Foliage Mode, Visual Scripting, Asset Browser, and
            more.
            <br>
            <br>
            Join the revolution of game development with our groundbreaking web-based engine.
            <br>
            <a href="https://play.aiira.co" target="_blank">
              <i class="axl ax-external-link"></i> https://play.aiira.co
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<div class="adx-section  bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class='bg-black' style="height: 500px;">
          <iframe width="100%" height="500px" src="https://www.youtube.com/embed/hZ0LZwk-UXs?si=EFrBFZI4kENrz2Z5"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
        <p></p>
      </div>
    </div>
    <div class="clearfix"></div>

  </div>
</div>


<div class="bg-dark">
  &nbsp;
</div>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <p></p>
          <div class="flexx">
            <div class="adx-img">
              <img src="/assets/images/studio/brand.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> Graphic Design </span>
                <h1 class="text-normal">Brand Identity.</h1>
                <span class="text-size14">
                  Logo, LetterHead &<br />
                  Business Card.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Order Now&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- clients -->
<app-clients [bgClass]="'bg-black'"></app-clients>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img">
              <img src="/assets/images/studio/video.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> Multimedia </span>
                <h1 class="text-normal">Motion Graphics & Editing.</h1>
                <span class="text-size14">
                  The best for the <br />
                  brightest.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Order&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="/assets/images/studio/game.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> CGI </span>
                <h1 class="text-normal">Game Development.</h1>
                <span class="text-size14">
                  Interavtive 3D with <br />
                  Unreal Engine.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Play&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img">
              <img src="/assets/images/studio/music.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> Music </span>
                <h1 class="text-normal">Record Label</h1>
                <span class="text-size14">
                  Let grow togther <br />
                  with music.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Join Us&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
