<!--  45 image, 46-audio, 47-video, 48-3D
   54-play media  55-Artwork  56 - Store Product  57 - Blog 53 - Event -->
<div *ngFor="let item of data">
  <div [ngSwitch]="item.mediaType.id">
    <div *ngSwitchCase="45">
      <!-- render image -->
      <galaxy-adx-media [path]="item.path" [type]="item.mediaType.id"></galaxy-adx-media>
    </div>

    <div *ngSwitchCase="46">
      <!-- render audio -->
      <galaxy-adx-media [path]="item.path" [type]="item.mediaType.id"></galaxy-adx-media>
    </div>

    <div *ngSwitchCase="47">
      <!-- render video -->
      <galaxy-adx-media [path]="item.path" [type]="item.mediaType.id"></galaxy-adx-media>
    </div>

    <div *ngSwitchCase="54">
      <!-- render app play -->
      <galaxy-adx-play [id]="item.path.ToInt()"></galaxy-adx-play>
    </div>

    <div *ngSwitchCase="55">
      <!-- render app artwork -->
      <galaxy-adx-artist [id]="item.path.ToInt()"></galaxy-adx-artist>
    </div>

    <div *ngSwitchCase="56">
      <!-- render app product -->
      <galaxy-adx-store [id]="item.path.ToInt()"></galaxy-adx-store>
    </div>

    <div *ngSwitchCase="57">
      <!-- render app blog -->
      <galaxy-adx-blog [id]="item.path.ToInt()"></galaxy-adx-blog>
    </div>
    <div *ngSwitchCase="58">
      <!-- render app event -->
      <galaxy-adx-event [id]="item.path.ToInt()"></galaxy-adx-event>
    </div>

    <div *ngSwitchDefault>
      <!-- frame render link -->
       <div class="image-frame">
         <img [src]="embedImage(item.path)" alt="">
       </div>

    </div>
  </div>
</div>
