import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, OnDestroy } from '@angular/core';
import { IEntity, Group, Media, Product, AppSettingService, StoreApiService, AuthService, Entity, IGroup, ArtistApiService, PlayApiService, NotifyService, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss']
})
export class MediaGridComponent implements OnInit, OnChanges, OnDestroy {

  color: string = '';
  theme: string = '';
  themeUpdated$!: Subscription;
  @Input() searchValue: string = '';
  @Input() personId: number = 0;
  @Input() dataLimit: number = 50;


  @Input() entityType: 'photo' | 'media' | 'product' = 'photo';
  @Input() showOptions: boolean = false;
  @Input() showDetail: boolean = true;
  @Input() showPerson: boolean = true;
  @Input() isLoading: boolean = true;
  @Input() data: IEntity[] = [];
  @Input() showBookmark: boolean = true;

  productCategory: IGroup[] = [];
  productType: IGroup[];

  categorySelected: number = 0;
  typeSelected: number = 0;
  ratingSelected: number = 0;
  licenceSelected: number = 0;


  showModal: boolean = false;
  showEntityModal: boolean = false;
  modalNumber: number = 1;

  pageSize: number = 50;
  page: number = 1;
  totalData: number = 0;

  entity: IEntity = new Entity;
  currentMenu: string = 'popular'
  entityId: number = 30;
  prevProject?: IEntity = null;
  prevProjectIndex: number = 0;
  nextProject?: IEntity = null;
  nextProjectIndex: number = 0;
  viewerId: number = 0;


  private _xApi;
  dataType: IEntity = new Entity;
  entitySelected: IEntity;
  constructor(
    private _appSetting: AppSettingService,
    private _artistApi: ArtistApiService,
    private _storeApi: StoreApiService,
    private _playApi: PlayApiService,
    private _notify: NotifyService,
    private _authService: AuthService,
    private _errorHandlerService: ErrorHandlerService

  ) {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });

    // this.color = this._appSetting.defaultColor;
    // this._appSetting.color.subscribe(res => {
    //   this.color = res;
    // });
  }

  ngOnInit() {
    if (this._authService.loggedIn) {
      this.viewerId = this._authService.currentUser.gid.social.u;
    }

    this.isLoading = true;
    this.pageSize = this.dataLimit;
    // this.isAlbumLoading = true;
    // get dependencies

    if (this.data.length === 0) {

      this.getApi(this.entityType);
    } else {
      this.page = this.data.length;
      this.totalData = this.page;
      this.isLoading = false;
    }
  }

  getApi(entityType: string) {

    if (this._authService.loggedIn) {
      switch (entityType) {
        case 'photo':
          this._xApi = this._artistApi;
          this.dataType = new Entity;

          break;
        case 'media':
          this._xApi = this._playApi;
          this.dataType = new Media;
          break;
        case 'product':
          this._xApi = this._storeApi;
          this.dataType = new Product;
          break;

        default:
          this._xApi = this._artistApi;
          this.dataType = new Entity;
          break;
      }

      this.onReload();
    } else {
      this._notify.error('Please login to continue');
      this.isLoading = false;
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    const search: SimpleChange = changes.searchValue;

    if (!search.firstChange && search.previousValue !== search.currentValue) {
      this.onReload();
    }

  }

  onReload() {
    this.isLoading = true;
    this.data = [];
    this.pageSize = this.dataLimit;
    this.page = 1;
    this.getData();

  }


  getData(): any {
    this._xApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.typeSelected, // product type
        categoryType: this.categorySelected, // product type category
        gid: this._appSetting.getAppId(),
        id: this.personId,

      }, 'stand/open')
      .getPageOf(Product)
      .subscribe(
        {
          next: (res) => {
            this.data.push(...res.data as IEntity[]);
            this.page = res.meta.pagination.currentPage;
            // this.pageSize = res.meta.pagination.pageSize;
           this.totalData = res.meta.pagination.totalItems;;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  onSortData() {
    // reset limit value to default if its lower than 5
    this.isLoading = true;
    // this.typeSelected = sort.type;
    // this.categorySelected = sort.category;

    // this.rating = sort.rating;
    // this.licence = sort.category;

    this.getData();
  }





  onShowModalClicked(entity: IEntity, type: number) {
    this.entity = entity;
    this.modalNumber = type;
    this.showModal = true;
  }
  onCloseClicked($event) {
    this.showModal = false;
    this.showEntityModal = false;
  }
  getEntityLink() {
    return 'localhost:4200/store/product/alias/id';
  }


  onEntityClicked(entity: IEntity, index: number) {
    // console.log('id @ masonry is ', entity.id)
    // this.showImageModal = false;
    this.entitySelected = entity;
    this.entityId = entity.id;
    const len = this.data.length;
    if (len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProject = this.data[this.prevProjectIndex];

      this.nextProjectIndex = (index + 1) % len;
      this.nextProject = this.data[this.nextProjectIndex];
      if (index === 0) {
        this.prevProject = null;

      }
      if (index == len - 1) {
        this.nextProject = null;
      }

    }

    // check if there is a prevous & next
    this.showEntityModal = true;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
