import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit {


  @Input() width: number = 512;
  @Input() height: number = 512;
  @Input() allowCameraSwitch = false;


  @Input() switchCamera = new EventEmitter<boolean>();
  @Input() trigger = new EventEmitter<boolean>();

  @Output() initError = new EventEmitter();
  @Output() imageCapture = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
