<app-banner></app-banner>


<app-platform-showcase *ngFor="let item of platformShowcase; let i =index" [index]="i" [title]="item.title"
  [data]="item.data"></app-platform-showcase>

<div class="clearfix"></div>


<!-- <app-platforms></app-platforms> -->



<div class="adx-section bg-black  ">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center " style="max-width: 600px; margin:0 auto;">
          <h2 class='text-normal text-size34'>We are ready to deliver professional work for you.</h2>
        </div>
        <div class='bg-black' style="height: 500px;">
          <iframe width="100%" height="500px"
            src="https://www.youtube.com/embed/_mjATO3JYYU?si=o4rS9Z3U8gsswHuh&amp;controls=0"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
        <p></p>
      </div>
    </div>
    <div class="clearfix"></div>
    <!-- <div class="adx-row">
      <div class="adx-cols-3">
        <div class="adx-card bg-transparent text-center adx-flat service-tab">
          <div class='title-flex'>
            <div class='adx-img'>
              <img class="img-responsive" src="/assets/images/logo/logo.png" alt="" />
            </div>
            <div>
              Design+
            </div>
          </div>
          <p class='text-size18'>
            Brand to design, to motion graphics and video editing.
          </p>
          <button class="adx-btn adx-round adx-sm adx-flat btn-tgreen" [routerLink]="['/support']">
            Get Service
          </button>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card bg-transparent text-center adx-flat service-tab">
          <div class='title-flex'>
            <div class='adx-img'>
              <img class="img-responsive" src="/assets/images/logo/logo.png" alt="" />
            </div>
            <div>
              AppDev
            </div>
          </div>
          <p class='text-size18'>
            Software development for your business.
          </p>
          <button class="adx-btn adx-round adx-sm adx-flat btn-tgreen" [routerLink]="['/support']">
            Get Service
          </button>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card bg-transparent text-center adx-flat service-tab">
          <div class='title-flex'>
            <div class='adx-img'>
              <img class="img-responsive" src="/assets/images/logo/logo.png" alt="" />
            </div>
            <div>
              3D
            </div>
          </div>
          <p class='text-size18'>
            Interactive 3D games, video games, and more.
          </p>
          <button class="adx-btn adx-round adx-sm adx-flat btn-tgreen" [routerLink]="['/support']">
            Get Service
          </button>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card bg-transparent text-center adx-flat service-tab">
          <div class='title-flex'>
            <div class='adx-img'>
              <img class="img-responsive" src="/assets/images/logo/logo.png" alt="" />
            </div>
            <div>
              Music
            </div>
          </div>
          <p class='text-size18'>
            Using industry standard tools to create music.
          </p>
          <button class="adx-btn adx-round adx-sm adx-flat btn-tgreen" [routerLink]="['/support']">
            Get Service
          </button>
        </div>
      </div>
    </div> -->


  </div>
</div>

<!-- clients -->
<app-clients [bgClass]="'bg-black'"></app-clients>


<section class="adx-section bg-black ">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-dark" style="border-radius: 24px">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal" style="font-size: 4rem;">airSuite for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/suite">
              Learn about airSuite in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/business">
              Add your business to the directory>
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
