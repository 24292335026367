import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


import Map from 'ol/Map';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import * as olGeom from 'ol/geom';

import olms from 'ol-mapbox-style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';


import { Icon, Style } from 'ol/style';
// import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { HttpClient } from '@angular/common/http';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-map-tool',
  templateUrl: './map-tool.component.html',
  styleUrls: ['./map-tool.component.scss']
})
export class MapToolComponent implements OnInit {


  private uri = 'https://nominatim.openstreetmap.org/reverse';

  @Input() location: { geo: { longitude: number, latitude: number }, info?: any } = { geo: { longitude: 0, latitude: 0 } };
  @Input() allowPickLocation = false;
  @Output() locationPicked = new EventEmitter<{ geo: { longitude: number, latitude: number }, info?: any }>();
  map: Map;
  // = new Map({
  //   target: 'map_tool',
  //   layers: [
  //     new TileLayer({
  //       source: new XYZ({
  //         url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  //       })
  //     })
  //   ],
  //   view: new View({
  //     center: [0, 0],
  //     zoom: 2
  //   })
  // });


  isLoading: boolean = false;

  branchLocationLayer: VectorLayer<VectorSource<Feature<olGeom.Geometry>>>;

  constructor(
    private _appSetting: AppSettingService,
    private _http: HttpClient) { }


  ngOnInit() {

    setTimeout(() => {
      this.initMap()

    }, 1000);
  }


  private initMap() {

    const longitude = 0; //Math.round((this.userLocation.longitude + Number.EPSILON) * 100) / 100;
    const latitude = 0; // Math.round((this.userLocation.latitude + Number.EPSILON) * 100) / 100;

    this.branchLocationLayer = this.drawVectorLayer(
      'Branch Location', this.location.geo
    );

    olms('map', '/assets/data/osm_aiira.json').then((map: any) => {
      // do anything with the passed `ol.Map` instance here.
      map.setTarget('ol-map');
      map.setView(new View({
        center: olProj.fromLonLat([this.location.geo.longitude, this.location.geo.latitude]),
        zoom: 12,
      }));

      map.addLayer(this.branchLocationLayer);

      this.map = map as Map;
      if (this.allowPickLocation) {
        this.map.on('click', (e) => {

          // console.info(e.pixel);
          // console.info(map.getPixelFromCoordinate(e.coordinate));
          // console.info(olProj.toLonLat(e.coordinate));
          var coords = olProj.toLonLat(e.coordinate);

          // reset pointer on map
          this.map.removeLayer(this.branchLocationLayer);


          this.location.geo = {
            longitude: coords[0],
            latitude: coords[1]
          };

          this.branchLocationLayer = this.drawVectorLayer('Branch Location', this.location.geo);
          this.map.addLayer(this.branchLocationLayer);

          // get country, city and location
          this.getLocationInfo();


        });
      }
    });
  }


  setMapView() {
    // reflect loc on map
    this.map.removeLayer(this.branchLocationLayer);

    this.branchLocationLayer = this.drawVectorLayer('Branch Location', {
      longitude: this.location.geo.longitude,
      latitude: this.location.geo.latitude,
    });

    const longitude =
      Math.round((this.location.geo.longitude + Number.EPSILON) * 100) /
      100;
    const latitude =
      Math.round((this.location.geo.latitude + Number.EPSILON) * 100) /
      100;

    if (this.branchLocationLayer) {
      this.map.setView(
        new View({
          center: olProj.fromLonLat([longitude, latitude]),
          zoom: 10,
        })
      );
      this.map.addLayer(this.branchLocationLayer);
    }

    this.map.setView(new View({
      center: olProj.fromLonLat([this.location.geo.longitude, this.location.geo.latitude]),
      zoom: 12,
    }));
  }


  getLocationInfo() {

    const url = this.uri + '?format=json&lat=' + this.location.geo.latitude + '&lon=' + this.location.geo.longitude;

    this.isLoading = true;
    this._http.get(url).subscribe(res => {
      // this.locationInfo = res;
      // console.log(res);
      this.location.info = res;
      this.locationPicked.emit(this.location);
      this.isLoading = false;
    });

  }

  private drawVectorLayer(
    name: string,
    loc: { longitude: number, latitude: number },
    icon?: string
  ): VectorLayer<VectorSource<Feature<olGeom.Geometry>>> {
    const iconFeature = new Feature({
      geometry: new olGeom.Point(olProj.fromLonLat([loc.longitude, loc.latitude])),
      name: name,
      population: 4000,
      rainfall: 500,
    });

    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 24],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: icon ?? '/assets/data/map-pin.png',
      }),
    });

    iconFeature.setStyle(iconStyle);

    return new VectorLayer({
      source: new VectorSource({
        features: [iconFeature],
      })
    });
  }


  getGeoLocation() {

    this.isLoading = true;
    this._appSetting.getLocation();
    setTimeout(() => {
      this.isLoading = false;
      this.location.geo = this._appSetting.userLocation;
      if (this.location.geo?.longitude !== 0) {
        this.setMapView();
        this.getLocationInfo();
      } else {
        alert('Please Enable GPS Location');
      }
    }, 3000); // 3s
  }

}
