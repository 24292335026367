<div class="new-btn">
  <button
    (click)="onAddClicked()"
    class="adx-btn adx-shadow adx-icon adx-round btn-{{ theme }}"
  >
    <i class="fa fa-plus"></i>
  </button>
  <br />
  <button class="adx-btn adx-shadow adx-icon adx-round btn-{{ theme }}">
    <i class="fa fa-question"></i>
  </button>
</div>

<galaxy-modal
  (onCloseModalClicked)="onCloseClicked($event)"
  [showModal]="showCreate"
>
  <div class="padding">
    <div class="text-center">
      <h3 class="title text-normal ">Create New</h3>
    </div>
    <hr style="opacity: .05;" />

    <div class="adx-list adx-flat">
      <div class="adx-item adx-button adx-icon">
        <i class="fa fa-images"></i>
        My Projects

        <div class="adx-btn-group">
          <button
            (click)="onRoute('/myartwork/projects')"
            class="adx-btn adx-icon adx-round btn-dark"
          >
            <i class="fal fa-list"></i>
          </button>
          &nbsp; &nbsp;
          <button
            (click)="onRoute('/myartwork/projects/new')"
            class="adx-btn adx-icon btn-tgreen outline adx-flat adx-round"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="adx-item adx-button adx-icon">
        <i class="fa fa-tag"></i>
        My Store

        <div class="adx-btn-group">
          <button
            (click)="onRoute('/store/u/products/')"
            class="adx-btn adx-icon adx-round btn-dark"
          >
            <i class="fal fa-list"></i>
          </button>
          &nbsp; &nbsp;
          <button
            (click)="onRoute('/store/u/products/new')"
            class="adx-btn adx-icon btn-tgreen outline adx-flat adx-round"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="adx-item adx-button adx-icon">
        <i class="fa fa-share-alt"></i>
        My Blogs

        <div class="adx-btn-group">
          <button
            (click)="onRoute('/blogs/u/articles')"
            class="adx-btn adx-icon adx-round btn-dark"
          >
            <i class="fal fa-list"></i>
          </button>
          &nbsp; &nbsp;
          <button
            (click)="onRoute('/blogs/u/article/new')"
            class="adx-btn adx-icon btn-tgreen outline adx-flat adx-round"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="adx-item adx-button adx-icon">
        <i class="fa fa-comments-alt"></i>
        My Threads

        <div class="adx-btn-group">
          <button
            (click)="onRoute('/forum/u/threads')"
            class="adx-btn adx-icon adx-round btn-dark"
          >
            <i class="fal fa-list"></i>
          </button>
          &nbsp; &nbsp;
          <button
            (click)="onRoute('/forum/u/threads/new')"
            class="adx-btn adx-icon btn-tgreen outline adx-flat adx-round"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</galaxy-modal>
