<div *ngIf="isLoading" class="adx-card adx-flat is-loading">
  <p></p>
</div>
<div #topMark id='topMark'></div>
<div class="product-view-grid">
  <div class="left-area" [ngClass]="{
      'bg-black': theme === 'dark',
      'bg-smoke': theme === 'light'
    }">
    <span class="save-btn">
      <button (click)="onShowModal(0)" class="adx-btn btn-tgreen adx-rounder adx-shadow adx-icon">
        <!-- <i class="fa fa-cabinet-filing"></i> -->
        <i class="axr ax-bookmark"></i>
      </button>
    </span>
    <div class="adx-card adx-round no-margin no-padding bg-black">
      <galaxy-slider *ngIf="!isLoading" [data]="entity.artwork">
      </galaxy-slider>
      <br />
    </div>
    <!-- description -->
    <galaxy-adaptive-card [shape]="'rounded'" [isFull]="true" [isFlat]="true" [transparentBg]="true" class="remove-xxs">
      <span class="zero-p" style="font-size: 15px; max-width: 100%">
        <quill-view-html [content]="entity.description"></quill-view-html>
      </span>
    </galaxy-adaptive-card>
    <br />
  </div>

  <div class="right-area">
    <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFull]="true" [isFlat]="true"
      [autoDimBlack]="true">
      <!-- <div class="adx-card adx-flat"> -->
      <div class="padding-sm">

        <galaxy-person-avatar *ngIf="!isLoading" [data]="entity" [showAnalytics]="showAnalytics"></galaxy-person-avatar>
        <h3 class="title text-normal text-size15" style="font-weight: 500; ">{{ entity.title }}</h3>
        <p class="text-size16" style="opacity: 0.7; line-height: 20px">
          {{ entity.intro }}
        </p>
        <!-- </div> -->


        <galaxy-post-acions [data]="entity" [showAnalytics]="showAnalytics" [entityId]="entity.id"
          [entityTypeName]="'store'" (likeClicked)="onLikeRepostClick($event, 2)"
          (repostClicked)="onLikeRepostClick($event, 4)" (shareClicked)="onShowModal(1)"
          (countUpdated)='updateEntityCount($event)'></galaxy-post-acions>
      </div>
    </galaxy-adaptive-card>
    <!-- <br /> -->


    <!-- Product Pricing and Details -->
    <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFull]="true" [isFlat]="true"
      [autoDimBlack]="true">
      <div class="padding-sm">
        <div class="adx-row">
          <div class="adx-colx-7">
            <div>
              <p class="text-size24 text-right" style="padding-bottom: 0; margin-bottom: 0">
                <!-- GHS {{ salesPrice | number: '1.2-2' }} -->
                GHS {{( salesPrice*orderCount | number: '1.2-2').split('.')[0] }} <sup>{{(
                  salesPrice*orderCount | number:
                  '1.2-2').split('.')[1] }}</sup>
                <span *ngIf="showDiscount">
                  <br />
                  <small style="padding:4px; border-radius: 4px; font-size: 1.1rem;" class='bg-yellow'>
                    -{{ entity.discount }}%
                  </small> &nbsp;
                  <del style="opacity: 0.5; font-size: 1.4rem">GHS {{ entity.price }}</del>
                </span>
              </p>
              <!-- <div class="adx-input adx-label">
              <label for="price">Leave a tip</label>
              <input
                type="number"
                [(ngModel)]="price"
                [min]="entity.price"
                [value]="entity.price"
              />
            </div> -->
              <div style="padding: 0 16px">
                <button (click)="onAddToCart()" class="adx-btn adx-full btn-tgreen adx-rounder adx-sm">
                  Add To Cart
                </button>
                <!-- <br> -->
                <!-- <button class="adx-btn adx-full btn-yellow adx-rounder adx-sm">
                Buy
              </button> -->

              </div>
            </div>
          </div>
          <div class="adx-colx-5">
            <div class="adx-divider">Type</div>
            <span style="display: inline-block; padding: 0 16px; margin-top: -10px">
              {{ productType.name}}
            </span>
            <div class="adx-divider">Category</div>
            <span style="display: inline-block; padding: 0 16px; margin-top: -10px">
              {{ entity.productCategory.name }}
            </span>
            <!-- <div class="adx-divider">Lincence</div>
          <small style="display: inline-block; padding: 16px; margin-top: -10px">
            {{ entity.licence.name }}
          </small> -->
          </div>
        </div>
        <div class="clearfix"></div>
        <div class='adx-row'>
          <div class='adx-cols-12'>
            <div class='adx-input adx-flex adx-label adx-round input-tgreen'>
              <label># of Items ( <b class='color-pink'>GHS {{ salesPrice | number: '1.2-2' }}</b> per
                Item)</label>
              <input type='number' step='1' name='minOrderCount' [min]='entity.minOrderCount' [max]='10000'
                minlength="1" maxlength="12" [(ngModel)]="orderCount" />
            </div>
          </div>
        </div>

      </div>

    </galaxy-adaptive-card>

    <!-- description -->
    <galaxy-adaptive-card [shape]="'rounded'" [isFull]="true" [isFlat]="true" class="show-xxs" [autoDimBlack]="true"
      [showPadding]="false">
      <span class="zero-p" style="font-size: 15px; max-width: 100%" [innerHTML]="entity.description"></span>
    </galaxy-adaptive-card>
    <!-- <br /> -->
    <!-- Product Files if its digital -->
    <galaxy-adaptive-card *ngIf='entity.isDigital' [showPadding]="false" [shape]="'rounded'" [isFull]="true"
      [isFlat]="true" [autoDimBlack]="true">
      <div class="adx-divider">Product Files</div>
      <div class="adx-list">
        <div class="adx-item adx-badge" *ngFor="let item of entity.file; let i = index">
          {{ item.title }}
          <span class="badge"> {{ item.type }} | {{ item.size }} </span>
        </div>
      </div>
    </galaxy-adaptive-card>
    <!-- <br /> -->
    <!-- comments -->
    <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFull]="true" [autoDimBlack]="true"
      [isFlat]="true">
      <div class="padding-sm">
        <small class="adx-divider">Comments</small>
        <galaxy-comment-system *ngIf="!isLoading" [entityId]="entity.id" [entityTypeId]="entityTypeId"
          (commentAdded)="increaseCommentCount($event)"></galaxy-comment-system>
      </div>
    </galaxy-adaptive-card>
    <!-- comments ends-->
  </div>
</div>

<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showModal" [showPadding]="false">
  <div *ngIf="modalNumber === 1; then shareModal; else collectionModal"></div>
</galaxy-modal>

<ng-template #shareModal>
  <galaxy-media-share *ngIf="showModal" [title]="entity.title" [link]="entityPageUrl"></galaxy-media-share>
</ng-template>

<ng-template #collectionModal>
  <galaxy-media-collection-select *ngIf="showModal" [collectionType]="84" [entity]="entity" [entityType]="'product'"
    [personId]="personId">
    Save To WishList
  </galaxy-media-collection-select>
</ng-template>
