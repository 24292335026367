<section class="adx-section bg-white banner">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <a href="https://qompass.aiira.co" target="_blank" class="adx-logo">
            <img src="assets/images/business/app-icon.png" class="img-responsive" alt="" />
          </a>
          <h2 class="text-normal text-size30">Qompass</h2>
          <div style="border-radius: 24px; overflow: hidden;">
            <img src="assets/images/business/business-apps-home.jpg" class="img-responsive" alt="" />
          </div>

          <p class="text-size18">
            Qompass is a revolutionary platform that connects businesses with their customers and clients. With Qompass,
            you can easily and quickly find businesses and services near you, all displayed on a convenient and
            interactive map.
            <br>
            <br>
            Whether you're looking for a restaurant, a hair salon, a mechanic, or any other type of
            business, Qompass has you covered. And if you're a business owner, you can register your business and
            services on Qompass, making it easy for customers to find you and learn about what you have to offer. Plus,
            Qompass also includes a job board where you can post or browse through job offers, making it a valuable
            resource for job seekers and employers alike. Try Qompass today and discover the power of our map-based
            platform for connecting businesses and customers.
          </p>

          <div class="text-center bg-pinkx">
            <br>
            <a href="https://play.google.com/store/apps/details?id=co.aiira.business.twa" target="_blank"
              class="adx-btn adx-round btn-default adx-sm btn-dark">
              <i class="color-tgreen axr ax-brand-google-play"></i> Google Playstore
            </a>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <a href="https://qompass.aiira.co" target="_blank" class="adx-btn adx-round btn-default btn-dark adx-sm">
              <i class="color-tgreen axr ax-rocket"></i> Install Web App
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="assets/images/business/directory.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> Business & Job </span>
                <h1 class="text-normal">Business Directory</h1>
                <span class="text-size18">
                  Browse through Businesses & Job Oppourtunities
                </span>
                <br />
                <a href="https://qompass.aiira.co/" target="_blank" class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Browse Directory&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img">
              <img src="assets/images/business/service.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> B2B Services </span>
                <h1 class="text-normal">Business Services</h1>
                <span class="text-size18">
                  Order for Tolling, Delivery & Cleaning Services
                </span>
                <br />
                <a href="https://qompass.aiira.co/app/delivery" target="_blank"
                  class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Order Service&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="assets/images/business/tools.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> <br /> </span>
                <h1 class="text-normal">Business Management Tools</h1>
                <span class="text-size18">
                  Install Apps in your Business to Manage it
                </span>
                <br />
                <a href="https://qompass.aiira.co" target="_blank" class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Get Started&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-grey">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">
              Service At Your Doorstep
            </h1>
            <!--
              <span class="text-size18">
                Our most powerful pro lineup ever — MacBook Pro, Mac Pro, and Pro
                Display XDR. Ready for your best work ever.
              </span>
            -->
          </div>
          <p></p>
        </div>
        <div class="clearfix"></div>
        <div class="adx-card adx-flat text-center">
          <div class="contact">
            <a href="https://qompass.aiira.co/app/delivery" target="_blank">
              <i class="axl ax-truck-delivery ax-4x"></i>
              <span> airDelivery<br />Delivery Service </span>
            </a>
            <a href="https://qompass.aiira.co/app/clean" target="_blank">
              <i class="axl ax-stars ax-4x color-pink"></i>
              <span>
                airKlin<br />
                Cleaning Services For Homes & Offices</span>
            </a>
            <a href="https://qompass.aiira.co/app/toll" target="_blank">
              <i class="axl ax-car-crane ax-4x color-blue"></i>
              <span> airToll<br />Vehicle Repair & Tolling Services </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- others -->
<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="contact">
            <a href="https://iforgot.aiira.co" target="_blank">
              <i class="axl ax-lock ax-4x"></i>
              <span> Forgoten Aiira ID<br />or password > </span>
            </a>
            <a href="mailto://support@aiira.co">
              <i class="axl ax-mail ax-4x color-pink"></i>
              <span> Send an Email</span>
            </a>
            <a href="mailto://billing@aiira.co">
              <i class="axl ax-brand-paypal ax-4x color-blue"></i>
              <span> Billing and subcription > </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-black" style="border-radius: 24px">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">airSuite for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/suite">
              Learn about airSuite in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/business">
              Add your business to the directory>
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
