import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  slider: {
    label: string,
    description: string,
    image: string,
    video: string,
    link: string
  }[] = [
      {
        label: 'Qompass',
        description: 'Find Businesses, Rents, Services & More',
        image: 'assets/images/logo/qompass.png',
        video: '',
        link: 'https://qompass.aiira.co'
      },
      {
        label: 'airSuite',
        description: 'Enterprise & Business Management Tools',
        image: 'assets/images/logo/airSuite.png',
        video: '',
        link: 'https://suite.aiira.co'
      },
      {
        label: 'Connect',
        description: 'Social Media & Distribution Platform',
        image: 'assets/images/logo/connect.png',
        video: '',
        link: 'https://connect.aiira.co'
      },
      {
        label: 'Interverse Engine',
        description: 'Web-Based Game Engine',
        image: 'assets/images/logo/play.png',
        video: '',
        link: 'https://play.aiira.co'
      },

    ]
  constructor() { }

  ngOnInit() {
  }


  linkClicked(link: string) {
    window.open(link, '_blank').focus();
  }

}
