import { Injectable } from '@angular/core';
import { MessagePayload, Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
// import { onBackgroundMessage } from "firebase/messaging/sw"
import { NotifyService } from './notify.service';
import { IdentityApiService } from './api/identity-api.service';
import { PersonDevice } from '../model';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {


  messaging = getMessaging();

  constructor(
    private _notify: NotifyService,
    private _messaging: Messaging,
    private _identityApi: IdentityApiService,
    private _authService: AuthService,
    private _storage: StorageMap,
  ) {

  }

  async init(vapidKey: string): Promise<void> {
    const permission = await Notification.requestPermission();
    try {
      if (permission === 'granted') {

        this._getDeviceToken(vapidKey);
        this._onMessage();

      } else {
        console.error('notification not granted');
      }
    } catch (error) {
      console.error('Unable to get permission for Notificaiton ', error);
    }

  }

  requestPermission() {
    Notification.requestPermission();
  }

  triggerNotification(title: string, options: NotificationOptions): void {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {

        new Notification(title, options);

      } else {
        console.error('notification not granted');
      }
    });
  }


  private _getDeviceToken(vapidKey: string): void {

    getToken(this.messaging, { vapidKey: vapidKey })
      .then((token) => {
        console.log(token);
        // save the token in the server, or do whathever you want

        if (token) {
          // Send the token to your server and update the UI if necessary
          // ...
          setTimeout(() => {
            this._updateDeviceToken(token);
          }, 3000);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((error) => console.error('Token error', error));
  }


  private _onMessage(): void {
    onMessage(this.messaging, {
      next: (payload: MessagePayload) => {
        // console.log('Message', payload);
        this._notify.info(payload.notification.body);
        //  this.triggerNotification('payload.notification.body',{});
      },
      error: (error) => console.log('Message error', error),
      complete: () => console.log('Done listening to messages'),
    });
  }



  private _updateDeviceToken(token: string) {
    const device = new PersonDevice();
    this._storage.get('ori_did').subscribe((res: string) => {
      if (res) {


        device.id = this._authService.currentUser.deviceId;
        device.fcmToken = token;


        this._identityApi
          .setParams({}, 'token/' + token)
          .put(PersonDevice).subscribe({
            next: (res) => console.log('fcm updated'),
            error(err) {
              console.error('fcm could not be updated');
            },
          });

      }
    });

  }
}
