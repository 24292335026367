import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, AuthService, BusinessEntity, Client, ErrorHandlerService, IClient, SuiteApiService } from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-person-select',
  templateUrl: './person-select.component.html',
  styleUrls: ['./person-select.component.scss']
})
export class PersonSelectComponent implements OnInit, OnDestroy {

  @Input() appId: number = 0;
  @Input() accountType: number = 0; //0 - all , 41 - users only , 42 - clients only
  @Input() businessBranchId: number = 0;

  @Output() personSelected = new EventEmitter<BusinessEntity>();
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Person Detail';
  detailTitle: string = 'Person Detail';
  isLoading: boolean = false;

  isList: boolean = true;

  data: IClient[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  theme: string = '';
  isFirstData = false;

  themeUpdated$!: Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _storageMap: StorageMap,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {

    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => {
      this.businessId = res.id;
      this._getStorageData();
    });
    if (this.businessId !== 0) {
      this._getStorageData();
      this.onReloadData();
    }

  }

  private _resetData() {
    this.data = [];
    this.isFirstData = true;

  }


  private _getStorageData() {
    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.businessBranchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        if (res) {
          this.data = res.get(key) ?? [];
        }
      });

  }

  private _storeStorageData() {

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.businessBranchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        let dataMap = new Map<string, IClient[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(key, this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.accountType,
        id: this.businessId,
        categoryType: this.businessBranchId,
        orderBy: [this.orderBy]

      })
      .getPageOf(Client)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <IClient[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            if (this.isFirstData) {
              this._storeStorageData();
              this.isFirstData = false;
            }

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }

  onPersonSelect(person: IClient) {
    const businessEntity = new BusinessEntity();
    businessEntity.id = person.businessEntityId;
    businessEntity.entity.name = person.name;
    // businessEntity.entity.othername = person.name;
    businessEntity.entity.personId = person.personId;
    businessEntity.entity.phoneOne = person.phoneOne;
    businessEntity.entity.email = person.email;
    businessEntity.entity.image = person.image;
    this.personSelected.emit(businessEntity);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
