<div class="adx-card adx-flat is-loading" *ngIf="isLoading">
  <p></p>
</div>
<section class="adx-section" *ngIf="!isLoading">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <!-- product -->
        <div
          *ngIf="entityType === 'product'"
          class="adx-card adx-flat text-center"
        >
          <h2 class="title text-normal text-size24">
            The Female Planar Statue
          </h2>
          <p></p>
        </div>

        <div class="adx-row">
          <div class="adx-cols-8">
            <!-- artwork -->
            <div *ngIf="entityType === 'photo'">
              <galaxy-media-artwork
                *ngFor="let item of entity.artwork"
                [artwork]="item"
              ></galaxy-media-artwork>
            </div>

            <!-- product -->
            <div *ngIf="entityType === 'product'">
              <div class="adx-card adx-img">
                <img
                  src="assets/images/galaxy.jpg"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </div>
          </div>
          <div class="adx-cols-4">
            <div
              *ngIf="
                entityType === 'photo';
                then photoDescription;
                else productDescription
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- more of artworks -->
<div *ngIf="false || entityType === 'photo'">
  <div class="adx-card adx-flat text-center">
    <h3 class="text-normal" style="letter-spacing: 1px;">
      More like this
    </h3>
  </div>
  <galaxy-masonry-grid></galaxy-masonry-grid>
</div>

<ng-template #photoDescription>
  <galaxy-adaptive-card>
    <div class="adx-header">
      <button
        (click)="onShowModalClicked(1)"
        class="adx-btn  btn-grey"
        style="margin-left:0"
      >
        <i class="fa fa-upload"></i> Share
      </button>
      <span class="float-right" (click)="onShowModalClicked(2)">
        <button class="adx-btn adx-round btn-grey">
          <i class="fa fa-folder"></i> Add to Collection
        </button>
      </span>
    </div>
    <h2 class="project-title text-normal" style="letter-spacing: 1px">
      {{ entity.title }}
    </h2>
    <!-- <persona [size]="'xs'"></persona> -->
    <div class="person-flex">
      <div class="person">
        <galaxy-avatar
          [tagline]="'Created by'"
          [size]="'sm'"
          [data]="entity"
        ></galaxy-avatar>
      </div>
      <div class="action">
        <button class="adx-btn btn-pink">
          <span>
            232K
          </span>
          &nbsp; Follow
        </button>
      </div>
    </div>
    <p class="" style="padding:0 16px">
      <button class="adx-btn  btn-grey adx-round adx-full">
        Like Artwork
      </button>
    </p>

    <p class="text-size14" style="line-height: 20px;">
      {{ description }}
      <button
        (click)="onFormatDescription()"
        class="adx-btn btn-pink clear adx-flat"
        style="padding:0; margin:0 ;font-size: 14px;"
      >
        <b> &nbsp; {{ descriptionFullMode ? 'Less' : 'More' }}</b>
      </button>
    </p>

    <div class="adx-divider">
      Comments
    </div>
    <galaxy-media-comment></galaxy-media-comment>
    <galaxy-media-comment-post></galaxy-media-comment-post>
    <!-- <galaxy-comment [size]="'sm'"></galaxy-comment> -->
  </galaxy-adaptive-card>
</ng-template>

<ng-template #productDescription>
  <galaxy-adaptive-card>
    <p class="text-size14" style="line-height: 20px;">
      This red curry coconut cauliflower rice is packed with flavour and
      nutrition but comes in at only 170 calories and 27 grams of carbs for a
      large and filling serving! It's low in fat, has 8 gram of
    </p>
    <p>
      <button class="adx-btn adx-lg btn-pink">
        Pre-Order!
      </button>
    </p>
    <div class="person-flex">
      <div class="person">
        <galaxy-avatar
          [tagline]="'Created by'"
          [size]="'sm'"
          [data]="entity"
        ></galaxy-avatar>
      </div>
      <div class="actiono">
        <button class="adx-btn btn-pink">
          <span>
            232K
          </span>
          &nbsp; Follow
        </button>
      </div>
    </div>
    <!-- <p class="" style="padding:0 16px">
      <button class="adx-btn  btn-grey adx-round adx-full">
        Like Product
      </button>
    </p> -->
    <!-- <galaxy-comment [size]="'sm'"></galaxy-comment> -->
  </galaxy-adaptive-card>
</ng-template>

<div class="return">
  <button (click)="onBack()" class="adx-btn adx-round adx-flat outline btn-grey">
    <i class="fa fa-angle-left"> </i> Back
  </button>
</div>

<galaxy-modal
  (onCloseModalClicked)="onCloseClicked($event)"
  [showModal]="showModal"
>
  <div *ngIf="modalNumber === 1; then shareModal; else collectionModal"></div>
</galaxy-modal>

<ng-template #shareModal>
  <galaxy-media-share
    [title]="entity.title"
    [link]="getEntityLink()"
  ></galaxy-media-share>
</ng-template>

<ng-template #collectionModal>
  <galaxy-media-collection-select></galaxy-media-collection-select>
</ng-template>
