import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showMenu: boolean = false;
  theme = 'dark';
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this._appSetting.theme
      .subscribe(res => this.theme = res);

  }

  onToggleMenu() {
    this.showMenu = !this.showMenu;
  }

}
