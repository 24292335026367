
export enum ExpenseCategoryEnum {
  ADVERTISING = 1,
  CAR_AND_TRUCK_EXPENSE = 2,
  COMMISSION_FEES = 3,
  CONTRACT_LABOR = 4,
  EMPLOYEE_BENEFIT_PROGRAMS = 5,
  INSURANCE = 6,
  INTEREST = 7,
  MORTGAGE = 8,
  LEGAL_PROFESSIONAL_SERVICE = 9,
  OFFICE_EXPENSES = 10,
  PENSIONS_PROFIT_SHARING_PLANS = 11,
  RENT_LEASE = 12,
  REPAIRS_MAINTENANCE = 13,
  TAXES_LINCENSES = 14,
  TRAVEL_MEALS = 15,
  UTILITIES = 16,
  WAGES = 17,

}
