<div class="adx-subheader adx-flat et-header">
  <div class="et-types">
    <button
      class="adx-btn adx-round btn-default"
      [class.btn-pink]="defaultEntityType === 54"
    >
      Media
    </button>
    <button
      class="adx-btn adx-round btn-default"
      [class.btn-pink]="defaultEntityType === 55"
    >
      Artwork
    </button>
    <button
      class="adx-btn adx-round btn-default"
      [class.btn-pink]="defaultEntityType === 56"
    >
      Product
    </button>
    <button
      class="adx-btn adx-round btn-default"
      [class.btn-pink]="defaultEntityType === 53"
    >
      Event
    </button>
    <button
      class="adx-btn adx-round btn-default"
      [class.btn-pink]="defaultEntityType === 57"
    >
      Article
    </button>
  </div>

  <div class="adx-input adx-round outline no-margin " style="padding-top:0;">
    <span>
      <i class="fal fa-search"></i>
    </span>
    <input type="search" name="" id="" placeholder="Search" />
  </div>
</div>
<!-- <hr /> -->
<div style="min-height:400px; height: 60vh;">
  <div [ngSwitch]="defaultEntityType">
    <div *ngSwitchCase="54">
      <!-- media -->
    </div>
    <div *ngSwitchCase="55">
      <!-- artwork -->
    </div>
    <div *ngSwitchCase="56">
      <!-- product -->
    </div>
    <div *ngSwitchCase="53">
      <!-- event -->
    </div>
    <div *ngSwitchCase="57">
      <!-- article -->
    </div>
    <div *ngSwitchDefault>output2</div>
  </div>
</div>
