import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from '@galaxy/entity-api';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  isLoading: boolean = false;

  userName: string = '';
  userPassword: string = '';
  userEmail: string = '';
  reason: string = '';
  emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  constructor(
    private _router: Router,
    private _notify: NotifyService,
  ) { }

  ngOnInit() {
  }

  resetPass() {

  }



  onToggleForgotPassword(value: boolean) {

  }

  onRequestReset() {
    this._notify.info('Account Deletion Request has been sent!');
    this._router.navigateByUrl('/');
  }


}
