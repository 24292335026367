import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedService, Entity, IEntity } from '@galaxy/entity-api';

@Component({
  selector: 'app-post-analytic',
  templateUrl: './post-analytic.component.html',
  styleUrls: ['./post-analytic.component.scss']
})
export class PostAnalyticComponent implements OnInit {

  @Input() data: IEntity = new Entity;

  engagementCount: number = 49;
  art: string = '';
  constructor(

    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (this.data.art) {
      this.art = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.art, 'md'));
    }

    this.getEngagementCount();
  }


  /**
   * This method counts the number of history views, that is the real engagement
   */
  getEngagementCount() {

  }

}
