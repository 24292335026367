import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy {
  theme: string = 'light'; 
themeUpdated$!:Subscription;
  constructor(
    private _appSetting: AppSettingService,
    private _titleService: Title
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this._titleService.setTitle('Aiira | Set Up');
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
