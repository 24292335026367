import { Component, OnInit, Input, SecurityContext, OnDestroy } from '@angular/core';
import { AppSettingService, IBusinessEntity, EmbedService, IPrice } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent implements OnInit, OnDestroy {

  // @Input() businessData: IBusinessEntity;
  @Input() isImageRound: boolean = false;

  @Input() image: string = '';
  @Input() name: string = '';
  @Input() code: string = '';
  @Input() description: string = '';

  @Input() alias: string = '';
  @Input() price?: IPrice = null;
  @Input() isProduct: boolean = false;

  photo: SafeResourceUrl = '';
  photoBlur: string = '';

  showDetail: boolean = false;
  
  theme: string = '';
  themeUpdated$!: Subscription;

  storePriceType: string = 'sales';
  defaultPrice: number = 0;

  @Input() currency= 'USD';
  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.photo = this._embed.imgUrl(this.image, 'sm');
    this.photoBlur = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.image, 'xs'));

    if (this.price) {
      this.setPrice();

      this._appSetting.storePriceChange.subscribe(res => {
        this.storePriceType = res;
        this.setPrice();
      });
    }


  }


  private setPrice() {
    switch (this.storePriceType) {
      case 'cost':
        this.defaultPrice = this.price.unitCostPrice;
        break;

      case 'wholesale':
        this.defaultPrice = this.price.unitWholesalePrice;
        break;

      default:
        this.defaultPrice = this.price.unitRetailPrice;
        break;
    }

  }


  onShowDetail() {
    this.showDetail = !this.showDetail;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
