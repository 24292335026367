import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from '../../GenericService';
@Injectable({
  providedIn: 'root'
})
export class PerfectIdentityApiService extends GenericService {
  constructor(http: HttpClient) {
    // const baseUrl = '//127.0.0.1:8080/identity-api/';
    const rootUrl = 'https://perfectcloud.xyz';
    const baseUrl = 'https://perfectcloud.xyz/identity-api/';
    super(http, baseUrl, rootUrl);
  }

}
