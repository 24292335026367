<div [ngSwitch]="data.type">
  <div *ngSwitchCase="1">
    <div class="text-center">
      <div style="display: inline-block;" class="padding-sm">
        <i class="axr ax-phone-calling"></i> {{data.description}}
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="adx-chat bg-primx" [ngClass]="{
  reverse: isMe,
  hasFile: data.messageFiles.length > 0
}">
      <div class="person-image" [class.text-right]="isMe">
        <div class="space"></div>
        <div class="adx-img" *ngIf="showImage"><img [src]="avatar" alt="" /></div>
      </div>
      <div class="chat-detail">
        <small class="name" *ngIf="!isMe || isGroupChat" style="opacity: 0.4;">
          &#64;{{ data.person.username }}
        </small>
        <div class="chat-message {{ customColor ? bgColor : '' }}" [ngClass]="{
      reverse: isMe,
      'bg-light': !customColor && theme === 'light',
      'bg-dark': !customColor && theme === 'dark'
    }">
          <!-- load message files -->
          <galaxy-adx-chat-file [data]="data.messageFiles"></galaxy-adx-chat-file>

          <!-- load message -->
          <p class="no-margin" *ngIf="data.description.trim().length > 0">
            <!-- load emojis -->
            <ngx-emoji *ngIf="data.description[0] === ':'" emoji="{{ data.description }}" set="emojione" size="64">
            </ngx-emoji>

            <!-- {{ data.description }} -->
            <quill-view-html *ngIf="data.description[0] !== ':'" [content]="data.description"
              class="chat-msg"></quill-view-html>
          </p>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div [class.text-right]="isMe" style="padding: 0 48px; opacity: 0.4;">
      <small>
        {{ data.created * 1000 | amTimeAgo: true }}
      </small>
    </div>

  </div>
</div>
