import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, AuthService, Client, CustomerService, ErrorHandlerService, IClient, PaymentTypeEnum, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-request',
  templateUrl: './customer-request.component.html',
  styleUrls: ['./customer-request.component.scss']
})
export class CustomerRequestComponent implements OnInit, OnDestroy {


  @Input() title: string = 'Service'
  @Input() appId: number = 59; // 59 - airClient, 487 - airLab
  @Input() baseUrl: string = '/airLab/profile/';
  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];

  showDetail: boolean = false;
  defaultDetailTitle: string = 'Request Detail';
  detailTitle: string = 'Request Detail';
  isLoading: boolean = false;

  data: CustomerService[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;

  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  businessBranchId: number = 0;

  client: IClient = new Client;
  orderBy: string = 'id';
  
  theme: string = '';
  themeUpdated$!: Subscription;

  isAccount: boolean = false;
  currency: string;

  paymentPlanEnum: typeof PaymentTypeEnum = PaymentTypeEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // this.actionButtons = [
    //   { icon: 'axr ax-headset', title: 'Order Details', route: 'view/' },
    // ]


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this.currency = this._suiteApi.getActiveBusiness().currency;

    this._suiteApi.activeBusiness.subscribe(res => {
      this.businessId = res.id;
      this.currency = res.currency
    });


    if (this._suiteApi.getDataFromSuite('client-profile')) {

      this.client = <Client>this._suiteApi.getDataFromSuite('client-profile');

      this.title = 'Customer Requests';// + this.title;
      this.isAccount = true;

    } else {
      // business branch
      this.businessBranchId = this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id;

    }

    this.onReloadData();

    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });
  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.appId,
        categoryType: this.businessBranchId,
        gid: this.client.businessEntityId,
        orderBy: [this.orderBy]

      })
      .getPageOf(CustomerService)
      .subscribe(
        {
          next: (res) => {

            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <CustomerService[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }


  onDetailRoute(link: string, title: string = 'View Detail') {
    this.showDetail = true;
    this.detailTitle = title;
    // console.log(link);
    this._router.navigateByUrl(this.baseUrl + link);

  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }

  ngOnDestroy(): void {
    // unset parameters
    this._suiteApi.setDataToSuite('client-profile', null);
      this.themeUpdated$.unsubscribe();
    }

}
