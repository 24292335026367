import { IBusinessBranch } from './business-branch.model';
import { Controller } from '../decorators';
import { IGroup, Group } from '../ori/group.model';
import { BusinessEntity, BusinessProduct, IBusinessEntity } from './business-entity.model';
import { ITransaction, Transaction } from "./transaction.model";
import { BusinessBranch } from '..';
import { StoreTypeEnum } from '../../enums';

export interface IStoreInvoice {
  id: number;
  businessEntity: IBusinessEntity;
  transaction: ITransaction;
  businessBranch: IBusinessBranch;
  discount: number;
  type: StoreTypeEnum;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;

  storeInvoiceItems?: IStoreInvoiceItem[];
  storeInvoiceItemsCount?: number;
}

@Controller({ name: 'StoreInvoice' })
export class StoreInvoice implements IStoreInvoice {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public transaction = new Transaction,
    public businessBranch = new BusinessBranch,
    public discount = 0,
    public type = StoreTypeEnum.SALES,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,

    public storeInvoiceItems = [],

  ) {

  }
}


export interface IStoreInvoiceItem {
  id: number;
  storeInvoices: IStoreInvoice;
  businessProduct: BusinessProduct;
  packs: number;
  units: number;
  amount: number;
}

@Controller({ name: 'StoreInvoiceItem' })
export class StoreInvoiceItem implements IStoreInvoiceItem {
  constructor(
    public id = 0,
    public storeInvoices = new StoreInvoice,

    public businessProduct = new BusinessProduct,
    public packs = 0,
    public units = 0,
    public amount = 0,

  ) { }
}
