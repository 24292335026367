import { Component, OnInit, Output, Input, SecurityContext, EventEmitter } from '@angular/core';
import { AppSettingService, EmbedService, IEntity, Entity, BlogTypeEnum } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-post-card',
  templateUrl: './media-post-card.component.html',
  styleUrls: ['./media-post-card.component.scss']
})
export class MediaPostCardComponent implements OnInit {

  @Input() size: 'lg' | 'md' | 'sm' | 'xs' = 'xs';
  @Input() data: IEntity = new Entity();
  @Input() art: string = '';

  @Input() showDetail: boolean = false;
  @Input() showPerson: boolean = true;
  @Input() showCounts: boolean = true;
  @Input() maxTitleLength: number = 70;

  @Input() theme: string = 'light'; 
themeUpdated$!:Subscription;

  userImage: SafeResourceUrl = '';
  color: string = '';
  cover: string = '';
  avatar: string = '';

  @Output() shareEntity = new EventEmitter<IEntity>();
  @Output() viewEntity = new EventEmitter<number>();

  blogTypeEnum: typeof BlogTypeEnum = BlogTypeEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;

    this.setDefaults();
  }


  setDefaults() {
    this.userImage = this.imgUrl(this.data.person.image, 'xs');
    this.cover = this._sanitizer.sanitize(SecurityContext.URL, this.imgUrl(this.data.art, 'md')) || this.cover;
    // console.log('embeded userImage', this.data.person, this.userImage, this.imgUrl(this.data.person.image, 'xs'));
  }

  imgUrl(link: string, size = 'default') {
    return this._embed.imgUrl(link, size);
  }

  i

  onShare($event) {
    $event.stopPropagation()
    this.shareEntity.emit(this.data);
  }


  onViewEntity() {
    this.viewEntity.emit(this.data.id);
  }

}
