<div class="adx-section no-padding">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <!-- <div class="adx-card adx-flat bg-white adx-round"> -->
        <div class='stats-flex'>

          <galaxy-adaptive-card *ngFor="let item of data" [isFlat]=true [showPadding]=false [shape]="'rounded'">
            <div class="stat">
              <div class="detail">
                <div class="adx-divider">{{item.title}}</div>
                <span class="padding-sm text-size18"> &nbsp;
                  <b style="font-weight: 500;">
                    {{item.count}}
                  </b>
                </span>
              </div>
              <div class="icon">
                <span class="{{item.color}}">
                  <i class="{{item.icon}} ax-3x"></i>
                </span>
              </div>
            </div>
          </galaxy-adaptive-card>
        </div>

      </div>

    </div>
  </div>
</div>
