<section class="adx-section" style="padding-top: 0">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <ui-data-grid [title]="title" (searchClicked)="onSearchData($event)" (closeDetail)="onCloseRoute()"
          [showDetail]="showDetail" [detail]="detailTitle" [paginatorPage]="page" [paginatorTotal]="totalData"
          [paginatorLimit]="pageSize" (pageinateData)="onPaginateData($event)">
          <div class="header-btns">
            <button class="adx-btn adx-icon btn-tgreen adx-shadow adx-round" (click)="isSubscriptionActive() ?
                onDetailRoute({ link: 'new', title: 'Register New Person' }) : null
              " routerLinkActive="btn-prim">
              <i class="axl ax-plus"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round" (click)="onReloadData()">
              <i class="axl ax-refresh"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round" (click)="isList = !isList">
              <i class="axl ax-{{ !isList ? 'list' : 'grid' }}"></i>
            </button>
          </div>
          <div class="data" [class.is-loading]="isLoading">
            <!-- {{ data | json }} -->
            <div *ngIf="isList; then listData; else gridData"></div>

          </div>

          <div class="detail">
            <ng-content></ng-content>
          </div>
        </ui-data-grid>
      </div>
    </div>
  </div>
</section>

<ng-template #listData>
  <galaxy-person-list [theme]="theme" [data]="data" [actionButtons]="actionButtons" [accountType]="accountType"
    (detailRoute)="onDetailRoute($event)">
    <!-- data ends -->
    <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Persons'"></app-no-data>

    <!-- pagination start -->
    <div style="display: flex">
      <div style="flex: 1">
        <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0">
          Total: {{ totalData }}
        </button>
      </div>
      <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
        (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
    </div>
    <!-- pagination ends -->
  </galaxy-person-list>
</ng-template>
<ng-template #gridData>
  <galaxy-person-grid [theme]="theme" [data]="data" [actionButtons]="actionButtons"
    (detailRoute)="onDetailRoute($event)">
    <!-- data ends -->
    <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Persons'"></app-no-data>
    <!-- pagination start -->
    <div style="display: flex">
      <div style="flex: 1">
        <button class="adx-btn btn-dark adx-round adx-flat" style="margni-top: 0">
          Total: {{ totalData }}
        </button>
      </div>
      <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
        (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
    </div>
    <!-- pagination ends -->
  </galaxy-person-grid>
</ng-template>
