<!-- <div class="adx-section bg-black ">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12"> -->
<!-- <div class="media-container"> -->
<owl-carousel-o [options]="owlOptions" class="slide">
  <ng-template carouselSlide *ngFor="let item of data; index as i">
    <galaxy-media-carousel-item [entity]='item' [buttonName]='buttonName' [isMedia]='isMedia'
      (viewEntity)='onEntityClicked($event,i)'></galaxy-media-carousel-item>
  </ng-template>
</owl-carousel-o>

<div class="fill"></div>
<!-- </div> -->

<!-- </div>
    </div>
  </div>
</div> -->
