import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  EmbedService,
  ProductFile,
  Artwork,
  IEntity,
  Entity,
  NotifyService,
  AppSettingService,
  Product,
  ErrorHandlerService,
} from '@galaxy/entity-api';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const URL = '/cdn/cloud-api/upload/file';
@Component({
  selector: 'galaxy-file-builder',
  templateUrl: './file-builder.component.html',
  styleUrls: ['./file-builder.component.scss']
})
export class FileBuilderComponent implements OnInit {
  @Input() data: ProductFile[] = []; //slydes of images/video/3d
  @Input() project: string = 'file';
  @Input() entity: IEntity = new Product;

  @Input() maxFileSize: number = 500 * 1024 * 1024;

  @Output() fileChanged = new EventEmitter<ProductFile[]>();
  @Output() delFile = new EventEmitter<number>();
  @Output() projectThumbnail = new EventEmitter();
  productData: ProductFile[] = []; //slydes of images/video/3d
  uploadType: string = 'image/jpeg,image/gif';
  hasBaseDropZoneOver: boolean = false;
  hasAnotherDropZoneOver: boolean = false;


  productDataFile?: any;

  itemType: number = 45; //45image, 46-audio, 47-video, 48-3D
  showCreate: boolean = false;
  showDeleteModal: boolean = false;
  uploader: FileUploader = new FileUploader({
    url: URL,
    headers: [{ name: 'Accept', value: 'application/json' }],
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: this.project,
    maxFileSize:this.maxFileSize
  });
  newImageData?: any;
  activeArtwork: Artwork = new Artwork;
  canAddArtwork: boolean = true;
  theme: string = '';
  deleteFile: ProductFile = new ProductFile;

  constructor(
    private _sanitizer: DomSanitizer,
    private _embed: EmbedService,
    private _notifyService: NotifyService,
    private _appSettings: AppSettingService,
    private _errorHandlerService:ErrorHandlerService

  ) {

    this.theme = this._appSettings.defaultTheme;
    this._appSettings.theme.subscribe(res => {
      this.theme = res;
    })

  }

  ngOnInit() {
    this.productData = this.data;
    // autoUpload Artwork Status

    // error when adding file
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Warning! The uploaded file ' + item.name + 'of size ' + this._appSettings.formatBytes(item.size) + ' exceeds the maximun allowed size of ' + this._appSettings.formatBytes(this.maxFileSize);
          break;

        default:
          message = 'Error trying to upload file ' + item.name
          break;
      }

      this._notifyService.error(message);
    }

    this.uploader.onErrorItem = (item, response, status, headers) =>
      this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) =>
      this.onSuccessItem(item, response, status, headers);

  }
  // ng2-file-upload mehtods
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];

    // console.log(file);

    this._embed
      .readBase64(file)
      .then(data => (this.newImageData.path = data));
    // this.mediaChanged.emit(this.sliderData);
  }
  onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {

    const res = JSON.parse(response);
    if (res.success) {
      this.onAddFile(res.info as { name: string, type: string, size: number }, res.url);

      // console.log('product file input print here', this.productDataFile);
      // this.newImagePath = url;
      // this.newImagePathThumbnail = this._embed.imgUrl(url, 'md');

      this._notifyService.success('Your File was sucessfully uploaded');
    } else {
      this._notifyService.error(res.message);
    }
  }

  onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let error = JSON.parse(response); //error server response
  }

  onAddFile(info: { name: string, type: string, size: number }, path: string) {
    const data = new ProductFile();
    // data.entity = <Entity>this.entity;
    data.entity = null;
    data.path = path;

    data.title = info.name;
    data.type = info.type;
    data.size = info.size;



    // if (this.data.length === 1) {
    //   this.projectThumbnail.emit(data.thumbnail);
    // }

    this.data.push(data);
    // console.log('all data is', this.data);
    // this.fileChanged.emit(this.data);

    // clean the path & close modal
    this.onCancelFile();

  }

  onCancelFile() {
    // now close the modal
    this.showDeleteModal = false;
  }

  onReorderArtwork() {
    // something here
  }


  onShowDeleteModal(file: ProductFile) {
    this.deleteFile = file;
    this.showDeleteModal = true;
  }

  onRemoveFile() {
    // console.log('deleting a row from the data', this.deleteFile);
    // this.data = this.data.slice(index, 1);
    this.data = this.data.filter((obj) => {
      // lets see the data
      // console.log('in the filder', obj)
      return obj !== this.deleteFile
    });

    // console.log('data has been reduced', this.data)
    // update the removed data
    this.fileChanged.emit(this.data);
    this.productData = this.data;

    // if its an edit & id != 0
    if (this.deleteFile.id !== 0) {
      this.delFile.emit(this.deleteFile.id);
    }
    this.showDeleteModal = false;
  }

  // Embed


  onDrop(event: CdkDragDrop<ProductFile[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }


  onCloseClicked($event) {
    this.showCreate = false;
    this.showDeleteModal = false;
  }

  onAddMedia(type) {
    this.itemType = type;
    this.showCreate = true;
  }




}
