import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'adx-discover',
  templateUrl: './ad-discover.component.html',
  styleUrls: ['./ad-discover.component.scss']
})
export class AdDiscoverComponent implements OnInit {
  discoverData: any[] = [];
  @Input() theme: string = '';
  constructor() { }

  ngOnInit() {
    this.discoverData = [
      {
        id: 1,
        photo: ' ',
        name: 'Olivia'
      },
      {
        id: 2,
        photo: ' ',
        name: 'Azto'
      },
      {
        id: 3,
        photo: ' ',
        name: 'Luxis'
      },
      {
        id: 4,
        photo: ' ',
        name: 'Shatta'
      },
      {
        id: 5,
        photo: ' ',
        name: 'Calvin'
      }
    ];
  }
}
