import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranch, BusinessBranch } from './business-branch.model';
import { ITransaction, Transaction } from './transaction.model';
import { Controller } from '../decorators';
import { ExpenseCategoryEnum, StatusEnum } from "../../enums";

export interface IExpenseReportItem {

  id: number;
  dateIncurred: number;
  name: string;
  clientName: string;
  purpose: string;
  summary: string;
  category: ExpenseCategoryEnum;
  receiptImage: string;
  amount: number;
  expenseReport: IExpenseReport;

}
@Controller({ name: 'ExpenseReportItem' })
export class ExpenseReportItem implements IExpenseReportItem {
  constructor(
    public id = 0,
    public dateIncurred = 0,
    public name = '',
    public clientName = '',
    public purpose = '',
    public summary = '',
    public category = ExpenseCategoryEnum.ADVERTISING,
    public receiptImage = '',
    public amount = 0,
    public expenseReport = new ExpenseReport,
  ) {
    // this.category.id = 458;
  }
}
export interface IExpenseReport {
  id: number;
  businessEntity: IBusinessEntity;
  businessBranch: IBusinessBranch;
  transaction: ITransaction;

  title: string;
  description: string;
  periodStart: number;
  periodEnd: number;
  status: StatusEnum
  reason?: string;
  submittedOn?: number;
  amount: number;

  expenseReportItems: IExpenseReportItem[];
  expenseReportItemsCount?: number;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'ExpenseReport' })
export class ExpenseReport implements IExpenseReport {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public businessBranch = new BusinessBranch,
    public transaction = new Transaction,
    public title = '',
    public recipientAddress = '',
    public description = '',
    public periodStart = 0,
    public periodEnd = 0,
    public status = StatusEnum.UNPUBLISHED,
    public reason = '',
    public submittedOn = 0,
    public amount = 0,
    public expenseReportItems = [],
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
  ) {
    // this.status.id = 1; // 1 - unpublished/opened, 2 - published/ approved,  3 - trash/rejected, 4 - submitted for approval

  }
}


