import { Group } from './../ori/group.model';
import { Person } from './../ori/user.model';
import { IBusinessBranch } from './business-branch.model';
import { IBusinessService } from './business-service.model';
import { IBusinessRecruit } from './business-recruit.model';
import { Controller } from '../decorators';
import { IBusinessSubscription } from './business-subscription.model';
import { MediaTypeEnum, StatusEnum } from '../../enums';

export interface IBusiness {
  // Basic Info
  id: number;
  businessId: string;
  isVerified?: boolean;
  name: string;
  logo: string;
  slogan: string;
  website: string;
  description: string;
  created?: number;
  modified?: number;
  category: Group;
  subCategory: Group;
  status: StatusEnum;

  person: Person;

  acronym?: string;
  currency?: string;
  clientRegistrationFee?: number;

  branch?: IBusinessBranch[];
  service?: IBusinessService[];
  subscriptions?: IBusinessSubscription[];
  media?: IBusinessMedia[];
  recruit?: IBusinessRecruit[];
  bookmarks?: IBusinessBookmark[];
  branchCount?: number;
  entitiesCount?: number;
  viewCount?: number;
  rating?: number;

  bookmarkCount?: number;
  isBookmarked?: boolean;
}

@Controller({ name: 'Business' })
export class Business implements IBusiness {
  constructor(
    public id = 0,
    public name = '',
    public businessId = '',
    public isVerified = false,
    public logo = '',
    public slogan = '',
    public website = '',
    public description = '',
    public acronym = 'DEX',
    public currency = 'GHS',
    public clientRegistrationFee = 0,
    public category = new Group,
    public subCategory = new Group,
    public status = StatusEnum.PUBLISHED,
    public person = new Person,
    public branchCount = 0,
    public entitiesCount = 0,
    public rating = 0,
  ) {
    // this.status.id = 2;
    this.category.id = 66;
  }

}


export interface IBusinessMedia {
  // Basic Info
  id: number;
  title: string;
  business?: IBusiness;

  // VideoInfo
  description: string;
  thumbnail: string;

  path: string;
  file?;
  // music/video
  type: MediaTypeEnum

  orderNumber: number;

  created?: number;
}

export class BusinessMedia implements IBusinessMedia {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public business = new Business(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public orderNumber = 1,
    public file = ''
  ) { }
}


export interface IBusinessRecord {
  businessId: number;
  branchId: number;
  appId: number;
  classX: string;
  content: any
}


export class BusinessRecord implements IBusinessRecord {
  constructor(
    public businessId = 0,
    public branchId = 0,
    public appId = 0,
    public classX = '',
    public content = ''
  ) { }
}

export interface IBusinessBookmark{
  id: number;

  person: Person;
  business: IBusiness;
  status: StatusEnum;

  created: number;
  modified?: number;
}

@Controller({ name: 'Bookmark/Business' })
export class BusinessBookmark implements IBusinessBookmark{
constructor(
  public id = 0,
  public person = new Person,
  public business = new Business,
  public status = StatusEnum.PUBLISHED,
  public created = 0,
){}
}
