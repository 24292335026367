import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUser,
  User,
  AuthService,
  AppSettingService,
  IProfileMenu,
  IHeaderMenu,
  EmbedService,
  IBusiness,
  Business,
  SuiteApiService
} from '@galaxy/entity-api';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-uix-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.scss']
})
export class UiHeaderComponent implements OnInit, OnDestroy {

  @Input() appName: string = '';
  @Input() logo: string = '';
  @Input() theme: string = '';
  userInfo: IUser = new User();
  xsAvatar: SafeResourceUrl = '';
  smAvatar: SafeResourceUrl = '';

  isLoggedIn: boolean = false;
  business: IBusiness = new Business;
  appLogo: SafeResourceUrl | string = '';

  showMenu: boolean = false;

  businessDomain: string;

  @Output() logoClicked = new EventEmitter();

  themeUpdated$!:Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _router: Router,
    private _authService: AuthService,
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => { this.theme = res; });
    this.business = this._suiteApi.getActiveBusiness();
    if (this.business.businessId) {
      this.businessDomain = atob(this.business.businessId)
    }
    this._suiteApi.activeBusiness.subscribe(res => this.business = res);
    this._appSetting.showMenu.subscribe(res => this.showMenu = res);

    if (this.logo) {
      this.appLogo = this._embed.imgUrl(this.logo, 'sm')
    } else {
      this.appLogo = '/assets/images/logo/' + this.appName + '.png';
    }

    // console.log('logo uri is ', this.appLogo);

    this.isLoggedIn = this._authService.loggedIn;
    if (this.isLoggedIn) {
      this.setAuthUserInfo();
    }
  }

  setAuthUserInfo() {
    this.userInfo.name = this._authService.currentUser.name;
    this.userInfo.username = this._authService.currentUser.username;

    // if (this._authService.currentUser.curator.length > 0) {
    //   this.isCurator = true;
    //   this.userInfo.curator = this._authService.currentUser.curator;
    // }

    // embed needed images once!
    this.xsAvatar = this.embedImage(this._authService.currentUser.image, 'xs');
    this.smAvatar = this.embedImage(this._authService.currentUser.image, 'sm');

    // subscribe for changes in userinfo
    this._authService.currentUserLive.subscribe(res => {
      this.userInfo.name = res.name;
      this.userInfo.username = res.username;

      this.xsAvatar = this.embedImage(res.image, 'xs');
      this.smAvatar = this.embedImage(res.image, 'sm');
    });
  }


  onLogoClick() {
    this.logoClicked.emit()
  }

  onToggleTheme() {
    if (this.theme === 'dark') {
      this._appSetting.setTheme('light');
    } else {
      this._appSetting.setTheme('dark');
    }
  }


  onToggleMenu() {
    this.showMenu = !this.showMenu;
    this._appSetting.showMenu.emit(this.showMenu);
  }
  onLogOut() {

    this._authService.SignOut();
    this._router.navigateByUrl('/login');
  }
  onSwitchAccount() {
    this._authService.switchAccount();
    this._router.navigateByUrl('/login');
  }

  private embedImage(path: string, size = 'default'): SafeResourceUrl {
    return this._embed.imgUrl(path, size);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
