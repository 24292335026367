import { Component, OnInit, Input } from '@angular/core';
import { AuthService, SocialApiService, History, IPoll, Poll, NotifyService, ActivityTypeEnum, ErrorHandlerService } from '@galaxy/entity-api';


@Component({
  selector: 'galaxy-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {
  isLoggedIn: boolean = false;
  showVotes: boolean = false;

  personId: number = 0;

  totalVotes: number = 0;

  iVoteFor: number = 0;

  isPollEnded = false;
  isLoading = false;

  @Input() entityId: number = 0;
  @Input() pollEnds: number = 0;
  @Input() poll: IPoll[] = [];
  @Input() theme = '';

  constructor(
    private _authSetting: AuthService,
    private _socialApi: SocialApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    // console.log('data is ', Date.now(), this.pollEnds * 1000);
    this.isPollEnded = (this.pollEnds * 1000 <= Date.now());
    this.isLoggedIn = this._authSetting.loggedIn;

    if (!this.isLoggedIn || this.isPollEnded) {
      this.showVotes = true;
      this.isLoading = false;
    } else {
      this.personId = this._authSetting.currentUser.gid.social.u;
      this.checkUserVote(this.personId);
    }

    for (let index = 0; index < this.poll.length; index++) {
      this.totalVotes = this.totalVotes + this.poll[index].voteCount;

    }


  }

  checkUserVote(id: number) {
    this._socialApi.setParams({
      id: this.entityId, //entity
      gid: id, //person
      category: ActivityTypeEnum.VOTE, //activity
      searchFields: ['single']
    }, 'check')
      .get(History)
      .subscribe(
        {
          next: (res) => {
            this.isLoading = false;
            if (res.data.id) {
              this.showVotes = true;
              this.iVoteFor = res.data.params * 1;
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onVote(event: MouseEvent, id: number, index: number) {
    event.stopPropagation();

    if (this.isLoading) {
      this._notify.info('Poll is still checking if you\'ve voted');
      return;
    }

    if (this.pollEnds * 1000 <= Date.now()) {
      this.showVotes = true;
      this.isLoading = false;
      this._notify.info('Vote for the poll has already ended');
      return;
    }

    // this.showVotes = !this.showVotes;
    // return;
    this.isLoading = true;

    const pollz = new Poll;
    pollz.id = id;
    this._socialApi
      .setParams(null, id.toString())
      .put(pollz).subscribe(
        {
          next: (res) => {
            // console.log('recorded to poll', res);

            if (res.success) {

              const vote = new History();
              vote.params = id;
              vote.person.id = this.personId;
              vote.entity.id = this.entityId;
              vote.activityType = ActivityTypeEnum.VOTE; // vote group id
              this._socialApi.post(vote).subscribe(res => {
                // console.log('what happed to historyyyyyyyyyyyyy', res);
                this.iVoteFor = id;
                this.showVotes = true;
                this.isLoading = false;
                this.poll[index].voteCount += 1;
                this.totalVotes += 1;
                // show where ii voted
                this.isLoading = false;


                // store it to the poll data
              }, error => this.isLoading = false);
            } else {
              this.isLoading = false;
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );

  }




}
