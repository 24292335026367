<div class="wrapper-fluid">
  <div class="adx-row">
    <div class="adx-colx-12">
      <div class="grid-header">
        <div class="adx-card bg-dark bg-gradient adx-flat">
          <div class="menu-flex">
            <div class="menu">
              <button class="adx-btn adx-round btn-dark">Discover</button>
              <button class="adx-btn adx-round btn-dark">Trending</button>
              <button class="adx-btn adx-round btn-dark">Latest</button>
              <button class="adx-btn adx-round btn-dark">Picks</button>
              <button class="adx-btn adx-round btn-dark">Popular 2018</button>
              <button class="adx-btn adx-round btn-dark">Following</button>
            </div>
            <div class="action">
              <span class="float-right input-flex">
                <button
                  class="adx-btn adx-round adx-icon btn-default outline adx-flat"
                >
                  <i class="fa fa-repeat"></i>
                </button>
                <div class="adx-input adx-round">
                  <select name="" id="">
                    <option value="">All Media</option>
                    <option value="">Music</option>
                    <option value="">Videos</option>
                    <option value="">Album/Series</option>
                  </select>
                </div>
                <div class="adx-input adx-round">
                  <select name="" id="">
                    <option value="">All Generes</option>
                    <option value="">Gosple</option>
                    <option value="">Hip Hop</option>
                  </select>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="adx-card adx-flat">
        <div class="gallery-grid">
          <div *ngIf="app === 'artist'; then artwork; else media"></div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</div>

<ng-template #artwork>
  <div *ngFor="let media of data">
    <galaxy-photo-card [isFlat]="true"></galaxy-photo-card>
    <br />
  </div>
</ng-template>

<ng-template #media>
  <div *ngFor="let media of data">
    <!-- <adx-album [data]="media" [isMedia]="true"></adx-album> -->
  </div>
</ng-template>
