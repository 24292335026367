import { Controller } from '../decorators';
import { IBusiness } from './business.model';
import { IRoleGrant } from './role.model';

export interface IAuthToken {
  unique?: number | string;
  username: string;
  password?: string;
  email: string;
  name: string;
  image: string,
  business?: IBusiness;
  gid?: IGalaxyParams,
  claims?: IRoleGrant[],
  idToken: string;
  tokenType: string;
  expiresAt: number,
  deviceId: number,
  senderId: string,
  requestConfirmation: boolean,
}

@Controller({ name: 'AuthToken' })
export class AuthToken implements IAuthToken {
  constructor(
    public unique = 0,
    public username = '',
    public email = '',
    public name = '',
    public image = '',
    public idToken = '',
    public tokenType = 'Bearer',
    public expiresAt = 3600,
    public deviceId = 0,
    public senderId = 'connect.aiira',
    public requestConfirmation = false
  ) {

  }

}



@Controller({ name: 'ClientAuth' })
export class ClientAuth implements IAuthToken {
  constructor(
    public unique = 0,
    public username = '',
    public email = '',
    public name = '',
    public image = '',
    public idToken = '',
    public tokenType = 'Bearer',
    public expiresAt = 3600,
    public deviceId = 0,
    public senderId = 'connect.aiira',
    public requestConfirmation = false
  ) {

  }

}


export interface IGalaxyParams {
  social?: IDparams;
  // play?: IDparams;
  // artist?: IDparams;
  // blog?: IDparams;
  // event?: IDparams;
  // store?: IDparams;
  suite?: IDparams;
  rent?: IDparams;
}

interface IDparams {
  u: number;
  p: number;
}
