<div class="adx-avatar adx-flat bg-special" [class.is-loading]="isLoading" style="border-radius: 20px;">
  <div class="adx-img" [class.adx-icon-letter]="avatar !== ''">
    <img *ngIf="avatar !== ''" [src]="avatar" alt="" />
    <span *ngIf="avatar === ''">
      {{ client.name[0] }}
    </span>
  </div>
  <h2>{{ client.name }}</h2>
  <p>
    <!-- {{ person.client.personId }}  -->
    {{infoToShow }}
  </p>
</div>
