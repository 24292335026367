import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BusinessEntity, IClient } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-person-grid',
  templateUrl: './person-grid.component.html',
  styleUrls: ['./person-grid.component.scss']
})
export class PersonGridComponent implements OnInit {

  @Input() theme: string = 'light'
  @Input() data: IClient[] = [];
  @Input() actionButtons: { route: string, title: string, icon: string, objectName?: string, object?: any }[] = [];

  @Output() detailRoute = new EventEmitter();

  today = (new Date()).getTime();

  constructor() { }

  ngOnInit() {
  }

  onDetailRoute(link: string, title: string = 'View Detail', objectName?: string, object?: any) {
    const routeData = { link, title, objectName, object };
    this.detailRoute.emit(routeData);

  }

}
