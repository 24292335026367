import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IMessagex, Comment, AppSettingService, AuthService, EmbedService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-comment-post',
  templateUrl: './media-comment-post.component.html',
  styleUrls: ['./media-comment-post.component.scss']
})
export class MediaCommentPostComponent implements OnInit {
  showModal: boolean = false;
  data: IMessagex = new Comment;

  @Output() addComment = new EventEmitter<IMessagex>();

  color: string = '';
  theme: string = '';

  showTextarea: boolean = false;

  maxDescriptionLength: number = 120;

  personImage: SafeResourceUrl = '';
  loggedIn: boolean = false;

  content: string = '';

  viewerId: number = 0;

  setContent = new EventEmitter<string>();
  constructor(
    private _appSettings: AppSettingService,
    private _authSevice: AuthService,

    private _embed: EmbedService,
  ) {
    this.color = this._appSettings.defaultColor;
    this.theme = this._appSettings.defaultTheme;

    this._appSettings.theme.subscribe(res => this.theme = res);
  }

  ngOnInit() {
    // get user image
    this.loggedIn = this._authSevice.loggedIn;
    if (this.loggedIn) {
      this.personImage = this._embed.imgUrl(this._authSevice.currentUser.image, 'sm');
      this.viewerId = this._authSevice.currentUser.gid.social.u;
    }
  }


  onContentChanged($event: any) {
    this.content = $event.text;
    this.data.description = $event.html;

  }

  onSwapInputs() {
    if (this.data.description.trim().length) { this.showTextarea = true; }
    else { this.showTextarea = false; }
  }

  onCancelPost(force = false) {
    // if description is filled, show waingin
    if (this.data.description?.trim().length && !force) {
      this.showModal = true;
      return;
    }

    this.data.description = '';
    this.content = '';
    this.setContent.emit('');
    this.showTextarea = false;
    this.showModal = false
  }

  onAddComment() {
    if (this.data.description.trim().length)
      this.addComment.emit(this.data);
    this.data = new Comment;
    this.setContent.emit('');

    this.onCancelPost(true);
  }
}
