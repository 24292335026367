import { Controller } from "../decorators";

export interface ITrack {
  id?: number;
  title: string;
  album: string;
  artist: string;
}

@Controller({ name: 'Track'})
export class Track implements ITrack {
  constructor(
    public id: 0,
    public title: '',
    public album: '',
    public artist: ''
  ) {}
}
