import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'adx-footer',
  templateUrl: './ad-footer.component.html',
  styleUrls: ['./ad-footer.component.scss']
})
export class AdFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
