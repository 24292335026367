import Quill from "quill";
import {Mention, MentionBlot} from "quill-mention";

const QuillMentionBlot: typeof MentionBlot = Quill.import("blots/mention")  as any;

export class StyledMentionBlot extends QuillMentionBlot {


  static render(data) {
    const element = document.createElement('div');
    element.classList.add('adx-avatar', 'adx-flat', 'adx-xs');

    // Image container
    const imgContainer = document.createElement('div');
    imgContainer.classList.add('adx-img', 'bg-black');

    // Image element
    const image = document.createElement('img');
    image.src = data.image;
    imgContainer.appendChild(image);

    // Username container
    const usernameContainer = document.createElement('h2');
    usernameContainer.style.marginTop = '-1px';

    // Username text
    const usernameText = document.createTextNode(`@${data.username}`);
    usernameContainer.appendChild(usernameText);

    // Username line break
    const usernameLineBreak = document.createElement('br');
    usernameContainer.appendChild(usernameLineBreak);

    // Name text (small)
    const nameText = document.createElement('small');
    nameText.textContent = data.name;
    nameText.style.opacity = '0.5';
    usernameContainer.appendChild(nameText);

    // Add elements to main container
    element.appendChild(imgContainer);
    element.appendChild(usernameContainer);

    return element;
    }
    }




