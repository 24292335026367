<div class="adx-btn-group" style="margin-top: 0px;">
  <button
    *ngIf="page > 1"
    (click)="onPageClicked(1)"
    class="adx-btn btn-dark adx-round adx-icon"
    style="margin-right: 6px;"
  >
    <i class="axl ax-chevrons-left"></i>
  </button>
  <button
    *ngIf="page > 1"
    (click)="onPageClicked(page - 1)"
    class="adx-btn btn-dark adx-round adx-icon"
    style="margin-right: 6px;"
  >
    <i class="axl ax-chevron-left"></i>
  </button>

  <span *ngIf="pagingType === 'button'; then buttonPager; else comboPager">
  </span>

  <button
    *ngIf="lastPage > visible"
    (click)="onPageClicked(visible)"
    class="adx-btn btn-dark adx-round adx-icon"
    style="margin-right: 6px;"
  >
    <i class="axl ax-dots"></i>
  </button>

  <button
    *ngIf="lastPage > 1 && page < lastPage"
    (click)="onPageClicked(page + 1)"
    class="adx-btn btn-dark adx-round adx-icon"
    style="margin-right: 6px;"
  >
    <i class="axl ax-chevron-right"></i>
  </button>

  <button
    *ngIf="lastPage > page"
    (click)="onPageClicked(lastPage)"
    class="adx-btn btn-{{
      page == lastPage ? 'pink active' : 'dark'
    }} adx-round adx-icon"
    style="margin-right: 6px;"
  >
    <i class="axl ax-chevrons-right"></i>
  </button>
</div>

<ng-template #buttonPager>
  <button
    *ngFor="let pager of data"
    (click)="onPageClicked(pager[0])"
    class="adx-btn btn-{{
      page == pager ? 'pink active' : 'dark'
    }} adx-round adx-icon"
    style="margin-right: 6px;"
  >
    {{ pager }}
  </button>
</ng-template>

<ng-template #comboPager>
  <div
    *ngIf="data.length"
    class="adx-input adx-round no-margin no-padding"
    style="
      width: 50px;
      padding-top: 0;
      margin-top: 0;
      display: inline-block;
      vertical-align: top;
    "
  >
    <select (change)="onPageClicked($event.target.value)" [value]="page">
      <option *ngFor="let pager of data">
        {{ pager }}
      </option>
    </select>
  </div>
</ng-template>
