import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import {  NotifyService, AppSettingService, SocialApiService, AuthService, IPersonMedia, PersonMedia, IdentityApiService, MediaTypeEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

const RootURL = 'https://cdn-v2.aiira.co';
// const RootURL = 'https://cdn-kma-fgjavwearq-uc.a.run.app';
const URL = '/cloud-api/upload/image';

@Component({
  selector: 'galaxy-image-tool',
  templateUrl: './image-tool.component.html',
  styleUrls: ['./image-tool.component.scss']
})
export class ImageToolComponent implements OnInit, OnDestroy {

  @Input() btnIcon: string = 'axr ax-camera';
  @Input() btnName: string = 'Upload';
  @Input() btnShape: string = 'round';
  @Input() styles: string = '';
  @Input() isButtonIcon: boolean = true;
  @Input() uploadOnly: boolean = false;
  @Input() maxFileSize: number = 5 * 1024 * 1024;
  @Input() fileAlias: string = 'others';
  @Input() uploadType: string = 'jpeg';
  @Input() icropMaintainAspectRatio: boolean = true;
  @Input() icropAspectRatio: number = 4 / 3;
  @Input() icropResizeToWidth: number = 300;
  @Input() format: string = 'png';

  @Input() allowWebCam: boolean = true;
  @Input() saveMedia: boolean = true;
  @Input() sortPersonMediaByAlias: boolean = true;

  @Output() imageCapture = new EventEmitter<string>();

  uploader: FileUploader;

  // personMedia
  showPersonMediaModal: boolean = false;
  isLoadingPersonMedia: boolean = false;


  showCameraModal: boolean = false;
  showCamPhoto: boolean = false;
  webcamCapture: EventEmitter<boolean> = new EventEmitter<boolean>();
  switchCamera: EventEmitter<boolean> = new EventEmitter<boolean>();

  photo: string | Blob = '';
  capturedImage: any;// WebcamImage;
  enableSaveImage: boolean = false;

  showICropModal: boolean = false;
  imageChangedEvent?: any;
  croppedImage?: any;

  inputFile?: any;

  theme: string = '';
  themeUpdated$!: Subscription;
  personMediaData: IPersonMedia[] = [];
  personId: number = 0;
  showOptions: boolean = false;
  constructor(
    private _appSetting: AppSettingService,
    private _socialApi: SocialApiService,
    private _authService: AuthService,
    private _notify: NotifyService,
    private _identityApi: IdentityApiService,
    private _errorHandlerService: ErrorHandlerService

  ) {

    this.uploader = new FileUploader({
      url: this._identityApi.enableProd ? RootURL + URL : '/cdn' + URL,
      headers: [{ name: 'Accept', value: 'application/json' }],
      disableMultipart: false,
      autoUpload: false,
      method: 'post',
      itemAlias: 'cover',
      allowedFileType: ['image'],
      maxFileSize: this.maxFileSize

    });
  }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // personId
    if (this.saveMedia) {
      this.personId = this._authService.currentUser.gid.social.u;
    }



    this.uploader.options.itemAlias = this.fileAlias;

    // error when adding file
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Warning! The uploaded file ' + item.name + 'of size ' + this._appSetting.formatBytes(item.size) + ' exceeds the maximun allowed size of ' + this._appSetting.formatBytes(this.maxFileSize);
          break;

        default:
          message = 'Error trying to upload file ' + item.name
          break;
      }

      this._notify.error(message);
    }

    // limit upload to a single file
    this.uploader.onAfterAddingFile = f => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
    };

    // autoUpload Status
    this.uploader.onErrorItem = (item, response, status, headers) =>
      this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) =>
      this.onSuccessItem(item, response, status, headers);
  }

  print(value: string) {
    // console.log(value);
    this.showOptions = true;
  }

  onFileSeleted(event: any): void {
    this.imageChangedEvent = event;
    this.showICropModal = true;
    // console.log('input file changedi', event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 ?? event.blob;
    this.photo = event.base64 ?? event.blob;
    // this.showICropModal = false;

    console.log('even yooo', event);

    // this.sendImageToCloud();
  }

  imageLoaded() {
    // show cropper
    // this.showICropModal = true;
  }
  cropperReady() {
    // cropper ready
    this.enableSaveImage = true;
    // console.log('cropper is ready');
    this._notify.info('Crop Image if you want to');
  }
  loadImageFailed() {
    // show message
    this._notify.error('Image could not be loaded. Please try again');
  }

  onCloseICropModal(event) {

    this.showICropModal = false;
    this.enableSaveImage = false;
    this.showPersonMediaModal = false;
    this.inputFile = null;
  }

  onSaveCroppedImage() {
    this.onCloseICropModal('');
    this.sendImageToCloud();
  }
  // Camera
  onShowCamera(value: boolean) {
    this.showCameraModal = value;
  }

  onShowPersonMedia() {
    this.isLoadingPersonMedia = true;
    this.showPersonMediaModal = true;

  }

  onCaptureWebcamImage() {
    this.webcamCapture.emit(true);
    this.enableSaveImage = true;
    this.showCamPhoto = true;
    // this.onShowCamera = false;

  }

  cropCamImage() {
    this.imageChangedEvent = this.photo;
    this.showCamPhoto = false;
    this.showICropModal = true;
  }
  onSaveCamImage() {
    this.showCameraModal = false;
    this.enableSaveImage = false;
    this.photo = this.capturedImage.imageAsDataUrl;

    // const newFile = new Event<File>();
    // this.onFileSeleted(this.convertImageToFile('webcam'));
    this.sendImageToCloud();
  }


  onSwitchCamera() {
    // this.showCamPhoto = false;
    this.switchCamera.emit(true);
  }
  handleInitError(error: any): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      // console.warn("Camera access was not allowed by user!");
      this._notify.error("Camera access was not allowed by user!");
    }
  }

  onImageCaptured(capture) {
    // console.log(capture);
    this.capturedImage = capture;
    // this._embed.readBase64(capture.imageAsDataUrl).then(data => (this.photo = data));
    this.photo = capture.imageAsDataUrl;
  }

  sendImageToCloud() {
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    const imageFile = this.convertImageToFile(date + '.' + text);
    // return;
    this.uploader.addToQueue([imageFile]);
    this.uploader.uploadAll();

  }

  dataURItoBlob(dataURI: string): Blob {
    // console.log('dataUri', dataURI);

    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  private convertImageToFile(imageFileName: string): File {
    const imageBlob = this.photo instanceof Blob ? this.photo : this.convertDataUrlToBlob(this.photo);
    const imageName = imageFileName + '.' + imageBlob.type.split('/')[1];
    // console.log('the Blog here', imageBlob);
    // const imageFile = new File([imageBlob], imageName, { type: `${imageBlob.type}` });
    // console.log('imaeg File here', imageFile);
    return new File([imageBlob], imageName, { type: `${imageBlob.type}` });
  }

  private convertDataUrlToBlob(dataUrl: string): Blob {

    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
  //  SendImage to Cloud
  onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    // get url and set to avatar value
    const res = JSON.parse(response);
    if (res.success) {

      this.saveMedia ? this.savePersonImage(res.url[0]) : this.imageCapture.emit(res.url[0]);

      this._notify.success('Image is sucessfully uploaded');

    } else {
      this._notify.error(res.message);
    }
    //this gets triggered only once when first file is uploaded
  }

  onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    const error = JSON.parse(response); //error server response
    this._notify.error(error.message);
  }


  savePersonImage(path: string) {
    if (!this.saveMedia)
      return;

    const personMedia = new PersonMedia;
    personMedia.path = path;
    personMedia.alias = this.fileAlias;

    personMedia.type = MediaTypeEnum.IMAGE; // 45image, 46-audio, 47-video, 48-3D
    personMedia.person.id = this._authService.currentUser.gid.social.u;

    this._socialApi
      .post(personMedia)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              // console.log('media saved to db');
            }
            this.imageCapture.emit(path);

          },
          error: (err) => {
            // this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


  onPersonMediaSelected($event: string) {
    this.imageCapture.emit($event);
    this.showPersonMediaModal = false;
  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
