<galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFlat]="true">
  <div class="et-card">
    <div class="front-x " [class.rollup]="showDetail" (click)="onShowDetail()">
      <div class="image-x bg-specialx {{theme}}">
        <div class="adx-img" [class.adx-round]="isImageRound">
          <img [src]="photo" alt="" />
        </div>
      </div>
      <div class="detail-x">
        <div class="alias bg-tgreen">
          {{ alias }}
        </div>
        <div class="padding-sm">
          {{ name | maxLength: 12 }}
          <!-- <br />
          <small>
            {{ code }}
          </small> -->
        </div>
        <div style="margin-top:-6px;">
          <small class="highlight bg-dark bg-black">
            {{ description }}
          </small>
          <br />
          <strong class="color-pink " *ngIf="isProduct">
            {{ (defaultPrice | currency:currency:'symbol').split('.')[0] }}
            <sup>{{ (defaultPrice | number: '1.2-2').split('.')[1] }}</sup>
          </strong>

          <b *ngIf="!isProduct">
            #: {{code}}
          </b>
        </div>
      </div>
    </div>
    <div class="back-x text-center">
      <div class="close-div">
        <button (click)="onShowDetail()" class="adx-btn adx-icon adx-round btn-pink">
          <i class="axr ax-chevron-down"></i>
        </button>
      </div>
      <div class="padding highlight back-name" [ngClass]="{'bg-grey': theme === 'light','bg-black': theme === 'dark'}">
        {{ name | maxLength:12 }}
      </div>


      <ng-content></ng-content>
    </div>
  </div>
</galaxy-adaptive-card>
