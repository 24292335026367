import { BusinessBranch, IBusinessBranch } from "./business-branch.model";
import { BusinessEntity, IBusinessEntity } from "./business-entity.model";
import { Business, IBusiness } from "./business.model";
import { ITransaction } from "./transaction.model";
import { Controller } from '../decorators';
import { StatusEnum } from "../../enums";

export interface ILeadershipPosition {

  id: number;
  name: string;
  alias: string;
  description: string;
  created: number;
  status: StatusEnum;
  business: IBusiness;
  members?: IMemberPosition[];
  membersCount?: number
}

@Controller({ name: 'churchLeadershipPosition' })
export class LeadershipPosition implements ILeadershipPosition {
  constructor(
    public id = 0,
    public name = '',
    public alias = '',
    public description = '',
    public created = 0,
    public status = StatusEnum.PUBLISHED,
    public business = new Business,
  ) {

  }
}


export interface IMemberPosition {

  id: number;
  position: ILeadershipPosition;
  businessEntity: IBusinessEntity;
  businessBranch: IBusinessBranch;
  status: StatusEnum;
  effectiveTime: number;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'churchPositionMember' })
export class MemberPosition implements IMemberPosition {
  constructor(
    public id = 0,
    public position = new LeadershipPosition,
    public businessEntity = new BusinessEntity,
    public businessBranch = new BusinessBranch,
    public status = StatusEnum.PUBLISHED,
    public effectiveTime = 0,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {


  }
}


export interface IChurchEvent {

  id: number;
  title: string;
  description: string;
  startTime: number;
  endTime: number;
  transaction?: ITransaction;
  businessBranch: IBusinessBranch;
  location: string;
  attendance: number;
  visitors?: number;
  newMembers?: number;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  attendees?: IChurchEventAttendance[];
  attendeesCount?: number;
}


@Controller({ name: 'churchEvent' })
export class ChurchEvent implements IChurchEvent {
  constructor(
    public id = 0,
    public title = '',
    public description = '',
    public startTime = 0,
    public endTime = 0,
    public businessBranch = new BusinessBranch,
    public location = '',
    public attendance = 0,
    public attendeesCount = 0,
    public visitors = 0,
    public newMembers = 0,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {

  }
}


export interface IChurchEventAttendance {

  id: number;
  event: IChurchEvent;
  businessEntity: IBusinessEntity;
  status: StatusEnum;
  created: number;
  modified?: number;
}


@Controller({ name: 'churchEventAttendance' })
export class ChurchEventAttendance implements IChurchEventAttendance {
  constructor(
    public id = 0,
    public event = new ChurchEvent,
    public businessEntity = new BusinessEntity,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
  ) {


  }
}
