<galaxy-adaptive-card [transparentBg]="true">
  <div class="post-flex {{ size }} adx-card adx-flat padding">
    <div class="img"><div class="adx-img"></div></div>
    <div class="detail">
      <!-- <div class="person padding-">
        <span class="name"> Kwesi Melt </span>
      </div> -->
      <div class="adx-input adx-round adx-button">
        <textarea
          [placeholder]="placeholder"
          name=""
          style="width:100%"
        ></textarea>

        <!-- <input type="text" placeholder="Leave a comment" /> -->
      </div>
      <p style="opacity: .5;">
        <small>
          Basic HTML supported: strong, em, a, img
        </small>
      </p>
    </div>
    <div class="action">
      <span class="float-right">
        <button class="adx-btn adx-md btn-tgreen adx-icon adx-round btn-special">
          <i class="fal fa-comment-alt"></i>
        </button>
      </span>
    </div>
  </div>
</galaxy-adaptive-card>
