import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ArtistApiService, Project, EmbedService, AppSettingService, IEntity, ErrorHandlerService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-folder-featured',
  templateUrl: './media-folder-featured.component.html',
  styleUrls: ['./media-folder-featured.component.scss']
})
export class MediaFolderFeaturedComponent implements OnInit {

  @Input() theme: string = '';
  @Input() personId: number = 0;


  data: Project[] = [];

  placeholder: number[] = Array(6);

  images: SafeResourceUrl[] = [];
  isLoading: boolean = true;

  showImageModal: boolean = false;
  entity: Project = new Project;
  currentMenu: string = 'discover'
  projectId: number = 30;
  prevProject?: IEntity = null;
  prevProjectIndex: number = 0;
  nextProject?: IEntity = null;
  nextProjectIndex: number = 0;
  projectSelected: any;
  constructor(
    private _artistApi: ArtistApiService,
    private _embed: EmbedService,
    private _appSetting: AppSettingService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.getData();
  }

  getData(): any {
    this._artistApi
      .setParams({
        page: 1,
        pageSize: 6,
        category: 1,
        id: this.personId,
        orderBy: ['featured, likeCount, commentCount, home, id']
      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            this.data = res.data as Project[];
            this.placeholder = Array(6 - this.data.length)

            this.data.forEach(element => {
              this.images.push(this._embed.imgUrl(element.art, 'md'));
            });

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onEmbedImage(link) {
    // return this._embed.imgUrl(link, 'md');
  }


  // modal

  onCloseClicked($event) {
    this.showImageModal = false;
    this._appSetting.isFeaturedViewingEntity.emit(false);
  }
  getEntityLink() {
    return 'localhost:4200/artwork/id';
  }

  onImageClicked(entity: IEntity, index: number) {
    // console.log('id @ masonry is ', entity.id)
    // this.showImageModal = false;
    this.projectSelected = entity;
    this.projectId = entity.id;
    const len = this.data.length;
    if (len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProject = this.data[this.prevProjectIndex];

      this.nextProjectIndex = (index + 1) % len;
      this.nextProject = this.data[this.nextProjectIndex];
      if (index === 0) {
        this.prevProject = null;

      }
      if (index == len - 1) {
        this.nextProject = null;
      }

    }

    // check if there is a prevous & next
    this.showImageModal = true;
    this._appSetting.isFeaturedViewingEntity.emit(true);
  }


}
