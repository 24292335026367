<galaxy-adaptive-card *ngIf="productData.length">
  <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="adx-list ">
    <div
      cdkDrag
      class="adx-item adx-badge"
      *ngFor="let item of productData; let i = index"
    >
      {{ item.title }}
      <span class="badge"> {{ item.type }} | {{ item.size }} </span>
    </div>
  </div>
</galaxy-adaptive-card>

<button (click)="productFile.click()" class="adx-btn">
  Add Files
</button>

<div class="loading-media" *ngIf="uploader.isUploading"></div>
<div
  ng2FileDrop
  [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  class="drop-file-div"
  (click)="productFile.click()"
>
  <input
    type="file"
    ng2FileSelect
    [uploader]="uploader"
    #productFile
    name="file"
    [(ngModel)]="productDataFile"
    style="display: none;"
  />
</div>

<!-- Warning to remove an artwork modal -->
<galaxy-modal
  (onCloseModalClicked)="onCloseClicked($event)"
  [showModal]="showDeleteModal"
  [fixedWidth]="true"
>
  <div class="padding text-center">
    <div class="text-center">
      Delete File?
    </div>
    <p class="opacity:0.5">Are ou sure you want to Delete this File</p>
    <button (click)="onCancelFile()" class="adx-btn btn-dark adx-round">
      Cancel
    </button>
    <button (click)="onRemoveFile()" class="adx-btn btn-pink adx-round">
      Delete
    </button>
  </div>
</galaxy-modal>
