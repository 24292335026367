<div class="ad-list adx-sm adx-flat adx-ellipse flex-div {{theme}}">
  <div class="adx-avatar adx-sm adx-flat" style="flex:1;">
    <div class="adx-img bg-whitesmoke" [class.is-loading]="isLoading">
      <img [src]="viewerJobDescription.supervisor.entity.image">
    </div>
    <h2 class="color-pink">Supervisor</h2>
    <p>{{viewerJobDescription.supervisor.entity.name}}</p>
  </div>

  <div class="adx-avatar  adx-sm adx-flat">
    <div class="adx-img adx-icon-letter bg-special">
      <i class="axl ax-briefcase"></i>
    </div>
    <h2 class="color-pink">Job Description</h2>
    <p>{{viewerJobDescription.jobDescription.title | maxLength:16}} - {{viewerJobDescription.jobDescription.abbreviation}}</p>
  </div>


  <div class="adx-avatar  adx-sm adx-flat">
    <div class="adx-img adx-icon-letter bg-special">
      <i class="axl ax-map-pin"></i>
    </div>
    <h2 class="color-pink">Department</h2>
    <p>{{viewerJobDescription.businessBranchDepartment.businessDepartment.name}}</p>
  </div>

  <div class="adx-avatar  adx-sm adx-flat">
    <div class="adx-img adx-icon-letter bg-special">
      <i class="axl ax-certificate"></i>
    </div>
    <h2 class="color-pink">Employment Type</h2>
    <p>{{viewerJobDescription.employmentType | enumKey: employmentTypeEnum}} - {{viewerJobDescription.created * 1000 |date }}
    </p>
  </div>
</div>
