import { Component, OnInit, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppSettingService, AuthService, EmbedService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-two-way-auth',
  templateUrl: './two-way-auth.component.html',
  styleUrls: ['./two-way-auth.component.scss']
})
export class TwoWayAuthComponent implements OnInit, OnDestroy {

  confirmationCode: number = 0;
  genCode: number = 0;

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  avatar: SafeResourceUrl = '';

  constructor(
    private _authService: AuthService,
    private _appSetting: AppSettingService,
    private _router: Router,
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    if (!this._authService.currentUser) {
      this._router.navigateByUrl('/login');
    }

    if (!this._authService.currentUser.requestConfirmation) {
      this._router.navigateByUrl('/login');
    }

    this.setPersonInfo();

  }
  setPersonInfo() {
    this.avatar = this._embed.imgUrl(this._authService.currentUser.image, 'default');
  }


  onVerify() {

    if (this.genCode === this.confirmationCode) {

      this._authService.currentUser.requestConfirmation = false;
      this._authService.pendingAuth.requestConfirmation = false;

      this._authService.authDevice(this._authService.pendingAuth);
      if (this._authService.loggedIn) {
        this._router.navigateByUrl('/home');
      }
    }

  }

  onResendCode() {

  }

  onCancel() {

  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
