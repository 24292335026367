<section class="adx-section bg-black bg-x">
  <div class="bg-special">

  </div>
</section>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center" style="margin-top:-150px">
          <a href="https://connect.aiira.co" target="_blank" class="adx-logo">
            <img src="assets/images/logo/connect.png" style="filter: invert(100%);" class="img-responsive" alt="" />
          </a>
          <h2 class=" text-normal text-size24">Play . Connect . Enjoy</h2>
          <!-- <div>
            <img src="assets/images/play/aiira-apps-home.jpg" class="img-responsive" alt="" />
          </div> -->
          <div class="text-center bg-pinkx">
            <br>
            <a href="https://play.google.com/store/apps/details?id=app.aiira.twa" target="_blank"
              class="adx-btn adx-rounder adx-icon btn-default adx-sm btn-default outline">
              <i class="color-special axr ax-brand-google-play"></i>
            </a>
            &nbsp;
            &nbsp;
            <a href="https://connect.aiira.co" target="_blank"
              class="adx-btn adx-rounder adx-icon btn-default btn-default outline adx-sm">
              <i class="color-special axr ax-brand-apple"></i>
            </a>
            &nbsp;
            &nbsp;
            <a href="https://connect.aiira.co" target="_blank"
              class="adx-btn adx-rounder adx-icon btn-default btn-default outline adx-sm">
              <i class="color-special axr ax-rocket"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <p class="text-size16">
            Aiira Connect is a powerful platform that brings together social media, content sharing, and e-commerce
            capabilities in one convenient and user-friendly package. With Connect, users can post feeds, blog
            articles,
            photos and artworks, and share them with their friends and followers. Connect also includes tools for
            organizing and promoting events, such as the ability to sell tickets and manage attendee lists. And with the
            Connect store, users can easily sell and purchase products, as well as stream or download music and
            movies.
            <br>
            <br>
            Connect is a versatile and engaging platform that offers a wide range of features and benefits for
            users, and
            is a valuable addition to the suite of products and services offered by aiira. Try Connect today and
            discover
            the power of social media, content sharing, and e-commerce all in one place.
          </p>
        </div>
        <div class="adx-card adx-flat bg-whitex">
          <div class="flexx">
            <div class="adx-img">
              <img src="assets/images/play/shopping.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> Store </span>
                <h1 class="text-normal">Enjoy Online Shopping</h1>
                <span class="text-size18">
                  Everything from Digital Products to Physical Goods.
                </span>
                <br />
                <a href="https://connect.aiira.co/store" target="_blank" class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Start Shopping&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img">
              <img src="assets/images/play/play.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <!-- <span> 16-inch model </span> -->
                <h1 class="text-normal">Play Music & Videos</h1>
                <span class="text-size18">
                  Support your favourite Artiste.
                </span>
                <br />
                <a href="https://connect.aiira.co/media" target="_blank" class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Listen Now&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="assets/images/play/connect.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> <br /> </span>
                <h1 class="text-normal">Connect with Friends & Family</h1>
                <span class="text-size18">
                  Messenger, Community and Events.
                </span>
                <br />
                <a href="https://connect.aiira.co/home" target="_blank" class="adx-btn btn-dark adx-round adx-sm adx-flat">
                  &nbsp;Get Connected&nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-darker" style="border-radius: 20px;">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">
              Make Friends and Stay Connected
            </h1>
            <!--
              <span class="text-size18">
                Our most powerful pro lineup ever — MacBook Pro, Mac Pro, and Pro
                Display XDR. Ready for your best work ever.
              </span>
            -->
          </div>
          <p></p>
        </div>
        <div class="clearfix"></div>
        <div class="adx-card adx-flat text-center">
          <div class="contact">
            <a class='bg-darker' href="https://connect.aiira.co/community" target="_blank">
              <i class="axl ax-circle ax-4x"></i>
              <span> Community<br />Create and Join Groups/Communities </span>
            </a>
            <a class='bg-darker' href="https://connect.aiira.co/events" target="_blank">
              <i class="axl ax-calendar-event ax-4x color-pink"></i>
              <span>
                Events<br />
                Hangout with Community Members</span>
            </a>
            <a class='bg-darker' href="https://connect.aiira.co/messeges" target="_blank">
              <i class="axl ax-message-2 ax-4x color-blue"></i>
              <span> Messenger<br />A great way to start a conversation </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- others -->
<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="contact">
            <a href="https://iforgot.aiira.co" target="_blank">
              <i class="axl ax-lock ax-4x"></i>
              <span> Forgoten Aiira ID<br />or password > </span>
            </a>
            <a href="mailto://support@aiira.co">
              <i class="axl ax-mail ax-4x color-pink"></i>
              <span> Send an Email</span>
            </a>
            <a href="mailto://billing@aiira.co">
              <i class="axl ax-brand-paypal ax-4x color-blue"></i>
              <span> Billing and subcription > </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-black" style="border-radius: 24px">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">airSuite for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/suite">
              Learn about airSuite in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/business">
              Add your business to the directory>
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
