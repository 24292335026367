<div class="adx-card adx-flat is-loading" *ngIf="isLoading">
  <p></p>
</div>

<!-- <div class="adx-list">

            <div class="adx-item adx-button" *ngFor="let item of data">
              {{ item.title }} <button class="adx-btn btn-pink">Save</button>
            </div>
          </div> -->

<div class="board-grid " *ngIf="!isLoading">
  <div class="board" *ngFor="let item of data; index as i">
    <galaxy-media-folder
      (click)="onViewCollection(item)"
      (editCollection)="onEditCollection($event)"
      [data]="item"
      [index]="i"
      [showEditBtn]="true"
    ></galaxy-media-folder>
  </div>
</div>

<!-- <p *ngIf="data.length > 0">
  Page {{ page }} &nbsp; Showing {{ pageSize }} of {{ totalData }}
</p> -->

<div class="adx-card adx-flat" *ngIf="!isLoading && data.length === 0">
  <p class="text-center text-size18" style="opacity:.4;">
    <i class="axl ax-{{ icon }} ax-2x"></i> <br />
    <br />
    No {{ title }} Found
  </p>
</div>

<!-- entity collection -->

<galaxy-media-collection-entities
  *ngIf="showEntityCollection"
  [icon]="icon"
  [entityType]="entityType"
  [collection]="activeCollection"
  [showEntities]="showEntityCollection"
  (closeColletion)="onCloseClicked()"
>
</galaxy-media-collection-entities>

<!-- edit collection -->

<galaxy-modal
  (onCloseModalClicked)="onCloseClicked()"
  [showModal]="showEditModal"
>
  <h3 class="title text-normal">Edit Collection</h3>
  <form #collectionForm="ngForm" [class.is-loading]="isLoading">
    <div class="adx-input input-pink adx-round">
      <input
        type="text"
        name="title"
        required
        [(ngModel)]="editCollection.title"
        [disabled]="isUpdating"
      />
    </div>

    <div class="adx-input input-pink adx-round">
      <select
        name="status"
        required
        [disabled]="isUpdating"
        [(ngModel)]="editCollection.status"
      >
        <option value="2">Public</option>
        <option value="1">Private</option>
      </select>
    </div>
    <div class="adx-input input-pink adx-round">
      <textarea
        name="description"
        [(ngModel)]="editCollection.description"
      ></textarea>
    </div>

    <button
      [disabled]="
        (collectionForm.invalid && collectionForm.untouched) || isUpdating
      "
      [class.is-loading]="isLoading"
      (click)="onUpdateCollection()"
      class="adx-btn adx-rounder adx-shadows btn-tgreen adx-sm float-right"
    >
      Update
    </button>
    <br />
  </form>
</galaxy-modal>
