<header
  class="air-header adx-nav"
  [ngClass]="{
    'bg-darker': theme == 'dark',
    'bg-white': theme == 'light',
    full: isFull,
    transparent: transparent
  }"
>
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <div class="header-grid " [class.showSearchBar]="showSearchBar">
          <div class="logoGrid bg-yello">
            <div class="adx-logo " routerLink="/">
              <img
                src="assets/images/logo/{{ appName + '-' + theme }}.png"
                alt=""
              />
            </div>
          </div>

          <div class="searchGrid bg-tgree">
            <form action="" *ngIf="showSearch">
              <button
                (click)="onShowSearchBar()"
                class="adx-btn adx-icon btn-default clear adx-round adx-shadow search-button"
              >
                <i class="fa fa-angle-left"></i>
              </button>
              <div class="search-flex">
                <div
                  class="adx-input adx-icon outline input-{{
                    aRouterLink
                  }} adx-round "
                >
                  <span> <i class="fal fa-search"></i> </span>
                  <input
                    type="text"
                    (keyup.enter)="onSearch()"
                    [(ngModel)]="searchQuery"
                    name="searchQuery"
                    [placeholder]="header.searchPlaceholder"
                    #searchInput
                    class="{{ theme }}"
                  />
                  <div class="search-option">
                    <galaxy-search-options
                      (optionSelected)="onSearchOptionChanged($event)"
                    ></galaxy-search-options>
                  </div>
                </div>
                <!--
                  <div
                    class="adx-dropdown"
                    *ngIf="searchQuery.trim().length > 0 || showSearchOptions"
                  >
                    <button
                      type="button"
                      class="adx-btn btn-{{
                        theme == 'dark' ? 'dark' : 'grey'
                      }} adx-round adx-trigger"
                    >
                      {{ defaultSearchOption.name }} &nbsp;
                      <i class="fa fa-angle-down" style="margin:0;"></i>
                    </button>
                    <div
                      class="adx-content"
                      style="width:140px; margin-left: -80px;"
                    >
                      <galaxy-adaptive-card [showPadding]="false">
                        <div class="adx-list search-options">
                          <div
                            (click)="onSearchOptionChanged(item)"
                            *ngFor="let item of searchOptions"
                            class="adx-item"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </galaxy-adaptive-card>
                    </div>
                  </div>
                -->
              </div>
            </form>
          </div>

          <div class="optionGrid bg-pin">
            <span class="rFloat">
              <div
                *ngIf="isLoggedIn; then: headerActions; else: loginActions"
              ></div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="fill-space"></div>

<galaxy-announcement [color]="aRouterLink"></galaxy-announcement>

<ng-template #loginActions>
  <!--
    <button
      class="adx-btn adx-round adx-flat clear"
      [ngClass]="{
        'btn-default': theme == 'dark',
        'btn-dark': theme == 'light'
      }"
      [routerLink]="['/login']"
    >
      Join Us
    </button>
  -->
  <!-- Messages -->
  <button
    *ngIf="header.showMessenger"
    class="adx-btn adx-icon adx-round adx-flat outline default"
    [ngClass]="{
      'btn-default': theme == 'dark',
      'btn-dark': theme == 'light'
    }"
    [routerLink]="['/messages']"
    [routerLinkActive]="'btn-' + aRouterLink + ' active'"
  >
    <i class="fa fa-comment-dots"></i>
  </button>
  <!-- Cart -->
  <button
    *ngIf="header.showCart"
    class="adx-btn adx-icon adx-round adx-flat outline default"
    [ngClass]="{
      'btn-default': theme == 'dark',
      'btn-dark': theme == 'light'
    }"
    [routerLink]="['/cart']"
    [routerLinkActive]="'btn-' + aRouterLink + ' active'"
  >
    <i class="fa fa-shopping-cart"></i>
  </button>

  <!-- More -->
  <div class="adx-dropdown more-dd">
    <button
      class="adx-btn adx-round shine-{{ aRouterLink }} adx-shadow adx-trigger"
      [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-default': theme == 'light'
      }"
    >
      <span class="user-pic">
        <!--
          <img
            [src]="xsAvatar"
            alt=""
            class="img-responsive"
          />
        -->
      </span>
      &nbsp; <small> Guest </small> &nbsp;
      <!-- Majesty -->
      <span [class]="'color-' + aRouterLink">
        <i class="fa fa-angle-down" style="margin:0;"></i>
      </span>
    </button>

    <div class="adx-content">
      <div
        class="adx-card"
        [ngClass]="{
          'bg-dark': theme == 'dark',
          'bg-white': theme == 'light'
        }"
      >
        <div class="adx-list  adx-flat adx-hover hover-{{ aRouterLink }}">
          <div class="adx-card adx-full  " style="margin-top:0; z-index:100;">
            <div [routerLink]="['/login']" class="adx-item adx-icon adx-flat">
              <span> <i class="fa fa-sign-in"></i> </span> Sign in
            </div>

            <hr />

            <div
              class="adx-item adx-icon"
              *ngIf="profileMenu.showThemeSwitch"
              (click)="onToggleTheme()"
            >
              <span>
                <i
                  class="fa fa-{{
                    theme === 'dark' ? 'moon color-tgreen' : 'sun color-pink'
                  }}"
                ></i>
              </span>
              <b style="text-transform: capitalize"> {{ theme }} </b> Theme On
            </div>

            <div class="adx-item adx-icon" (click)="onShowMiniSocial('messages')">
              <span> <i class="fa fa-question"></i> </span> Help Center
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #headerActions>
  <button
    (click)="onShowSearchBar()"
    class="adx-btn adx-icon adx-round adx-shadow search-button"
    [ngClass]="{
      'btn-dark': theme == 'dark',
      'btn-default': theme == 'light'
    }"
  >
    <i class="fal fa-search"></i>
  </button>
  <!--
    <div class="adx-dropdown">
      <button
        *ngIf="header.showUpload"
        class="adx-btn adx-icon clear adx-flat adx-trigger"
        [ngClass]="{
          'btn-default': theme == 'dark',
          'btn-dark': theme == 'light'
        }"
      >
        <i class="fal fa-upload"></i>
      </button>
      <div class="adx-content" style="width:220px;">
        <galaxy-adaptive-card [showPadding]="false">
          <div class="adx-list adx-flat adx-hover hover-{{ aRouterLink }}">
            <div
              class="adx-item"
              *ngIf="appName == 'artist'"
              [routerLink]="['/myartwork/projects']"
            >
              <i class="color-tgreen fal fa-images"></i> Artworks
            </div>
            <div
              class="adx-item"
              *ngIf="appName == 'play'"
              [routerLink]="['/myartwork/projects']"
            >
              <i class="color-tgreen fal fa-play"></i> Creater's Studio
            </div>
            <div
              *ngIf="header.showCart"
              class="adx-item"
              [routerLink]="['/store/u']"
            >
              <i class="color-tgreen fal fa-store"></i> Products
            </div>
            <div class="adx-item" [routerLink]="['/blogs/u']">
              <i class="color-tgreen fal fa-file"></i> Blogs
            </div>
            <div class="adx-item" [routerLink]="['/forum/u']">
              <i class="color-tgreen fal fa-comment-alt"></i> Threads
            </div>
          </div>
        </galaxy-adaptive-card>
      </div>
    </div>
  -->

  <!-- Notifications -->
  <div class="adx-dropdown">
    <button
      class="adx-btn adx-icon adx-round clear adx-flat adx-trigger default"
      [ngClass]="{
        'btn-default': theme == 'dark',
        'btn-dark': theme == 'light'
      }"
      adx-tip="Notifications"
    >
      <i class="fa fa-plus"></i>
    </button>
    <div class="adx-content text-center">
      <div class="adx-btn-group bg-dark adx-round adx-shadow padding-xs">
        <button
          *ngFor="let item of newOptions"
          class="adx-btn"
          [routerLink]="[item.route]"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
  </div>

  <button
    class="adx-btn adx-icon adx-round clear adx-flat default"
    [ngClass]="{
      'btn-default': theme == 'dark',
      'btn-dark': theme == 'light'
    }"
    adx-tip="Notifications"
  >
    <i class="fa fa-bell"></i>
  </button>

  <!-- Messages -->
  <button
    *ngIf="header.showMessenger"
    class="adx-btn adx-icon adx-round adx-flat outline default"
    [ngClass]="{
      'btn-default': theme == 'dark',
      'btn-dark': theme == 'light'
    }"
    [routerLink]="['/messages']"
    [routerLinkActive]="'btn-' + aRouterLink + ' active'"
  >
    <i class="fa fa-comment-dots"></i>
  </button>
  <!-- Cart -->
  <button
    *ngIf="header.showCart"
    class="adx-btn adx-icon adx-round adx-flat outline default"
    [ngClass]="{
      'btn-default': theme == 'dark',
      'btn-dark': theme == 'light'
    }"
    [routerLink]="['/cart']"
    [routerLinkActive]="'btn-' + aRouterLink + ' active'"
  >
    <i class="fa fa-shopping-cart"></i>
  </button>

  <!-- More -->
  <div class="adx-dropdown more-dd">
    <button
      class="adx-btn adx-round shine-{{ aRouterLink }} adx-shadow adx-trigger"
      [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-default': theme == 'light'
      }"
    >
      <span class="user-pic">
        <img [src]="xsAvatar" alt="" class="img-responsive" />
      </span>
      &nbsp;
      <!-- Majesty -->
      <span [class]="'color-' + aRouterLink">
        <i class="fa fa-angle-down" style="margin:0;"></i>
      </span>
    </button>

    <div class="adx-content">
      <galaxy-adaptive-card [isBlur]="true" [showPadding]="false">
        <div class="adx-list  adx-flat">
          <div
            class="adx-card adx-full adx-round  outline outline-{{ aRouterLink }}"
            style="margin-top:0; z-index:100;"
          >
            <div
              class="adx-avatar "
              [routerLink]="['/profile/' + userInfo.username]"
            >
              <div class="adx-img" style="background-color:black">
                <img [src]="smAvatar" [alt]="userInfo.username" />
              </div>
              <h2>{{ userInfo.name }}</h2>
              <p>&#64;{{ userInfo.username }}</p>
            </div>
          </div>
          <!--
            <div
              class="adx-item adx-icon"
              (click)="onShowMiniSocial('messages')"
            >
              <span> <i class="fa fa-user-circle"></i> </span> My
              Channel
            </div>
          -->

          <div
            *ngIf="profileMenu.showCollection"
            class="adx-item adx-icon"
            [routerLink]="['/collection']"
          >
            <span> <i class="fa fa-images"></i> </span> Collection
          </div>
          <div
            *ngIf="profileMenu.showCart"
            class="adx-item adx-icon"
            [routerLink]="['/cart']"
          >
            <span> <i class="fa fa-shopping-cart"></i> </span> Cart
          </div>
          <div
            *ngIf="profileMenu.showSubscription"
            class="adx-item adx-icon"
            (click)="onShowMiniSocial('messages')"
          >
            <span> <i class="fa fa-dollar-sign"></i> </span> Subscriptions
          </div>

          <div
            *ngIf="header.showUpload && isCurator"
            [routerLink]="['/studio']"
            routerLinkActive="router-link-active"
            class="adx-item adx-icon"
            (click)="onShowMiniSocial('messages')"
          >
            <span> <i class="fa fa-play"></i> </span> Creator Studio
          </div>

          <div (click)="onSwitchAccount()" class="adx-item adx-icon adx-flat">
            <span> <i class="fa fa-users"></i> </span> Switch Account
          </div>
          <div (click)="onLogOut()" class="adx-item adx-icon adx-flat">
            <span> <i class="fa fa-sign-out"></i> </span> Logout
          </div>

          <hr />
          <!--
            <div
              class="adx-item adx-icon"
              (click)="onShowMiniSocial('messages')"
            >
              <span> <i class="fa fa-language"></i> </span> Language:
              English
            </div>
          -->
          <div
            class="adx-item adx-icon"
            *ngIf="profileMenu.showThemeSwitch"
            (click)="onToggleTheme()"
          >
            <span>
              <i
                class="fa fa-{{
                  theme === 'dark' ? 'moon color-tgreen' : 'sun color-pink'
                }}"
              ></i>
            </span>
            <b style="text-transform: capitalize"> {{ theme }} </b> Theme On
          </div>

          <div class="adx-item adx-icon" [routerLink]="['/setting']">
            <span> <i class="fa fa-cog"></i> </span> Settings
          </div>

          <div class="adx-item adx-icon" (click)="onShowMiniSocial('messages')">
            <span> <i class="fa fa-question"></i> </span> Help Center
          </div>
          <!--
            <div
              class="adx-item adx-icon"
              (click)="onShowMiniSocial('messages')"
            >
              <span> <i class="fa fa-comment"></i> </span> Send
              Feedback
            </div>
          -->

          <!-- <hr /> -->

          <!--
            <div
              class="adx-item"
              (click)="onShowMiniSocial('messages')"
            >
              Location: Ghana
            </div>

            <div
              class="adx-item"
              (click)="onShowMiniSocial('messages')"
            >
              Restricted Mode
            </div>
          -->
        </div>
      </galaxy-adaptive-card>
    </div>
  </div>
</ng-template>
