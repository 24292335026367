import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { Controller } from '../decorators';
import { StatusEnum } from "../../enums";
export interface IBusinessEntityNote {
  id: number;
  title: string;
  description: string;
  status: StatusEnum;
  businessEntity: IBusinessEntity;
  created: number;
  modified: number;
}


@Controller({ name: 'Note' })
export class BusinessEntityNote implements IBusinessEntityNote {
  constructor(
    public id = 0,
    public title = '',
    public description = '',
    public status = StatusEnum.PUBLISHED,
    public businessEntity = new BusinessEntity,
    public created = 0,
    public modified = 0,

  ) { }
}

export interface IBusinessEntityReminder {
  id: number;
  description: string;
  status: StatusEnum;

  remindDate: number;
  businessEntity: IBusinessEntity;
  created: number;
  modified: number;
}


@Controller({ name: 'Reminder' })
export class BusinessEntityReminder implements IBusinessEntityReminder {
  constructor(
    public id = 0,
    public description = '',
    public status = StatusEnum.PUBLISHED,

    public remindDate = 0,
    public businessEntity = new BusinessEntity,
    public created = 0,
    public modified = 0,
  ) { }
}
