import { Component, OnInit, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Analytics, logEvent } from '@angular/fire/analytics';
// import {
//   SocialAuthService,
//   FacebookLoginProvider,
//   GoogleLoginProvider,
//   // LinkedInLoginProvider
// } from 'angularx-social-login';


import {
  IUser,
  User,
  IPersonDevice,
  PersonDevice,
  AuthService,
  AppSettingService,
  IdentityApiService,
  EmbedService,
  NotifyService,
  SendMail,
  ResetPassword,
  DeviceTypeEnum,
  SignatureTypeEnum,
  ProviderEnum,
  AuthToken,
  ErrorHandlerService,
  IServerResponse,
  IAuthToken
} from '@galaxy/entity-api';
import { SafeResourceUrl, DomSanitizer, Title } from '@angular/platform-browser';
import { StorageMap } from '@ngx-pwa/local-storage';

import { DeviceDetectorService } from 'ngx-device-detector';

// import 'reserved-usernames';
import { Auth, getAuth, OAuthProvider, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from '@angular/fire/auth';

@Component({
  selector: 'galaxy-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  personDetail: boolean = false;
  isCheckingUsername: boolean = false;
  isCheckingEmail: boolean = false;
  isAvailable: boolean = false;

  userId: number = 0;

  message?: {
    email: string;
    emailAvailable: boolean;
    emailChecked: boolean;
    username: string;
    usernameAvailable: boolean;
    usernameChecked: boolean;
    passError: boolean;
    pass: string;
    pass1Error: boolean;
    pass1: string;
    notify: string;
    loginError: string;
  };

  user: IUser = new User;
  pass1: string = '';
  pass2: string = '';

  loginMode: boolean = true;
  isLoading: boolean = false;
  userName: string = '';
  userPass: string = '';
  userRemember: boolean = false;

  userEmail: string = '';
  // currentUser: any;

  isSelectAccount: boolean = false;
  airUsers?: any;
  activeAirUser: User = new User;

  usernames?: any;

  theme: string = '';
  color: string = '';
  app: string = '';
  activeUserAvater: SafeResourceUrl = '';


  deviceInfo = null;
  personDevice: IPersonDevice = new PersonDevice;

  usernamePattern: RegExp = /^[a-zA-Z0-9._-]+$/;
  emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  termsValue: number = 0;
  modalType: number = 0;
  showTermsModal: boolean = false;
  verificationCode: number = 0;
  enteredCode: number = 0;
  appId: number;
  showForgot: boolean = false;


  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _identityApi: IdentityApiService,
    private _location: Location,
    private _appSetting: AppSettingService,
    // private socialAuthService: SocialAuthService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
    private _storageMap: StorageMap,
    private _deviceService: DeviceDetectorService,
    private _notify: NotifyService,
    private _titleService: Title,
    private _auth: Auth,
    private _analytics: Analytics,
    private _errorHandlerService: ErrorHandlerService


  ) {
    this.deviceDetect();
  }

  deviceDetect() {
    // console.log('Detecting device now');
    this.deviceInfo = this._deviceService.getDeviceInfo();
    const isMobile = this._deviceService.isMobile();
    const isTablet = this._deviceService.isTablet();
    const isDesktopDevice = this._deviceService.isDesktop();

    // console.log(this.deviceInfo);
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.

    this.personDevice.app.id = this._appSetting.getAppId();

    this.personDevice.browser = this.deviceInfo.browser;
    this.personDevice.browserVersion = this.deviceInfo.browser_version;

    this.personDevice.os = this.deviceInfo.os;
    this.personDevice.osVersion = this.deviceInfo.os_version;

    this.personDevice.device = this.deviceInfo.device;
    this.personDevice.deviceId = this._appSetting.getDeviceId();

    if (this.personDevice.deviceId === undefined || this.personDevice.deviceId === null || this.personDevice.deviceId.length < 1) {
      // console.log('tring to get did generated again!');
      this._appSetting.checkDeviceId();
    }

    if (isDesktopDevice) {
      this.personDevice.deviceType = DeviceTypeEnum.DESKTOP;
    } else if (isTablet) {
      this.personDevice.deviceType = DeviceTypeEnum.TABLET;
    } else if (isMobile) {
      this.personDevice.deviceType = DeviceTypeEnum.MOBILE;
    }

    this._appSetting.emitDeviceId.subscribe(res => {
      this.personDevice.deviceId = res;
    })

  }

  ngOnInit() {

    this._titleService.setTitle('Aiira - Login');

    this.usernames = 'reserved-usernames';

    this.user = new User();
    this.userId = 0;

    this.message = {
      email: '',
      emailAvailable: false,
      emailChecked: false,
      username: '',
      usernameAvailable: false,
      usernameChecked: false,
      pass1Error: false,
      pass1: '',
      passError: false,
      pass: '',
      notify: '',
      loginError: ''
    };


    //capture redirect if any

    // check auth
    if (this._authService.loggedIn) {
      this._router.navigateByUrl('/' + this._authService.redirectToOnLoggin);
    } else {
      this._authService.initLocalToken().subscribe(res => {
        if (res) this._router.navigateByUrl('/' + this._authService.redirectToOnLoggin);
      });
    }

    // airUser
    this.initAirUsers();

    // App Setting
    this.theme = this._appSetting.defaultTheme;
    // this.color = this._appSetting.defaultColor;
    this.app = this._appSetting.app;
    this.appId = this._appSetting.getAppId();
    this.personDevice.app.id = this.appId;

    // set device ID

    if (this.personDevice.deviceId === undefined || this.personDevice.deviceId === null || this.personDevice.deviceId.length < 1) {
      this.deviceDetect();
    }


    this.appId = this._appSetting.getAppId();
  }

  socialSignIn(socialPlatform: string) {
    let provider;

    // use only google login for now
    // because it provides email

    // alert('provider selected -> ' + socialPlatform);
    switch (socialPlatform) {
      case 'google':
        provider = new GoogleAuthProvider();
        break;
      case 'apple':
        provider = new OAuthProvider('apple.com');
        break;
      case 'microsoft':
        provider = new OAuthProvider('microsoft.com');
        break;
      case 'twitter':
        provider = new TwitterAuthProvider();
        break;

      default:
        provider = new GoogleAuthProvider();
        break;
    }

    const auth = getAuth();
    signInWithPopup(auth, provider).then(userData => {

      // console.log(socialPlatform + ' sign in data : ', userData);
      // Now sign-in with userData
      this.isLoading = true;
      // console.log('stuffs', userData);

      // // This gives you a Google Access Token. You can use it to userData the Google API.
      // const credential = provider.credentialFromResult(userData);
      // // const credebtial = provider
      // const token = credential.accessToken;
      // // The signed-in user info.
      // const user = userData.user;

      // let loginModel: any = userData;
      let loginModel: any = {
        email: userData.user.email,
        emailVerified: userData.user.emailVerified,
        displayName: userData.user.displayName,
        photoUrl: userData.user.photoURL,
        provider: ProviderEnum.GOOGLE,

        rememberMe: false,
        portal: 'Galaxy',
        app: this.app,
        device: this.personDevice
      };
      loginModel.device.person = null;

      this._authService
        .RequestPasswordToken(JSON.stringify(loginModel))
        .subscribe({
          next: (user) => {
            logEvent(
              this._analytics,
              'SSO_signin',
              {
                email: loginModel.email
              }
            )
            // this.getSelectedGroup();
            this.isLoading = false;
            this.redirectHomeAfterSignIn();
          },
          error: (error) => {
            this.isLoading = false;
            this.message.loginError =
              'The username and/or password is incorrect. Please try again.';
            // console.log('notify that password was incorrect');
          }
        }
        );
    }).catch(error => {
      // console.log(error);
      // Handle Errors here.
      this.isLoading = false;
      const errorCode = error.code;
      const errorMessage = error.message;
      this.message.loginError = 'Action not Completed';
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      // const credential = provider.credentialFromError(error);
    });
  }

  onSignIn(googleUser) {
    const profile = googleUser.getBasicProfile();
    // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + profile.getName());
    // console.log('Image URL: ' + profile.getImageUrl());
    // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  login() {
    this.passwordLogin();
  }

  goBack() {
    this._location.back();
  }

  passwordLogin(): any {
    const loginModel = {
      email: this.userName,
      password: this.userPass,
      rememberMe: false,
      portal: 'Galaxy',
      app: this.app,
      signatureType: SignatureTypeEnum.PASSWORD, // 66 - password, 67 - pin, 68 - fingerprint, 69 -faceId
      device: this.personDevice
    };

    this.isLoading = true;
    // console.log('stuffs', loginModel);
    this._authService.RequestPasswordToken(loginModel).subscribe({

      next: (res:IServerResponse<IAuthToken>) => {
        // this.getSelectedGroup();
        this.isLoading = false;
        // console.log(user.idToken, user);
        if (res.data.idToken) {
          // check for two auth request

          logEvent(
            this._analytics,
            'signIn',
            {
              username: loginModel.email
            }
          )

          if (res.data.requestConfirmation) {
            this.redirectHomeAfterSignIn('/confirm');
          }

          if (this._appSetting.getAppId() === 60 && res.data.name.trim() === '') {
            this.redirectHomeAfterSignIn('/setup');
            return;
          } else {
            // activate timout
            this._appSetting.activateAutoLogout();
          }


          this.redirectHomeAfterSignIn();
        } else {
          this.message.loginError = res.message;
          this._notify.error(res.message)
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.message.loginError =
          'The Aiira ID and/or password is incorrect. Please try again.';
        this._notify.error('The username and/or password is incorrect. Please try again.')
        // console.log('notify that password was incorrect', error);
      }
    });
  }

  redirectHomeAfterSignIn(route = '/') {

    // if (this._appSetting.getAppId() === 60) {

    //   this._coldBootService.getRelationship(1, 100).subscribe(res => {
    //     if(res)
    //     this._authService.relationship = res;
    //     this._router.navigateByUrl(route);
    //   });
    // } else {
    this._router.navigateByUrl(route);

    // }
  }

  getSelectedGroup() {
    // let selectedGroup = null;
    this.isLoading = true;
  }

  switchMode() {
    this.loginMode = !this.loginMode;
    this._titleService.setTitle('Aiira - ' + this.loginMode ? 'Login' : 'Sign Up');
  }

  /**
   * Air Users Methods
   */
  initAirUsers() {
    this._storageMap.get('users.users')
      .subscribe(res => {
        // console.log('users.users --> ', res);
        if (res) {
          this.airUsers = res;
          this.airUsers.forEach(element => {
            element['avatar'] = this.embedImage(element['image'], 'sm');

          });
        }

      });


  }

  onSelectAccount(userInfo: User) {
    this.activeAirUser = userInfo;
    this.activeUserAvater = this.embedImage(userInfo.image, 'md');
    this.userName = userInfo.email;
    this.isSelectAccount = true;
  }

  onRemoveAirUser() {
    if (!this._authService.currentUser) this._authService.currentUser = new AuthToken;

    this._authService.currentUser.unique = this.activeAirUser.id;
    this._authService.SignOut();

    this.initAirUsers();

    this.isSelectAccount = false;

  }

  // End Air Users Methods


  onSwitchToLogin() {
    this.userName = '';
    this.isSelectAccount = false;
  }
  onLogin() { }

  showInputLoading(field: string, value: string): boolean {
    if (field === 'email') {
      this.message.email = '';
      // console.log('checking email', value.search('@'));
      if (value.search('@') === -1) {
        this.message.email = 'This is an invalid email';

        return false;
      } else {
        this.isCheckingEmail = true;
        return true;
      }
    } else {
      this.message.username = '';
      if (value.length < 4) {
        this.message.username =
          'Aiira ID Should Be (Min = 4 and Max = 8) Letter';
        return false;
      } else {
        this.isCheckingUsername = true;
        return true;
      }
    }
  }

  onCheckValue(field: string, value: string) {
    // console.log(field, value);


    // validate username
    if (value.length === 0 && !this.showInputLoading(field, value)) return;
    // check if username is reserved

    const isInputValid = field === 'username' ? this.isUsernameValid(value) : this.isEmailValid(value);
    if (!isInputValid) {
      return;
    }

    this._identityApi
      .postAs<any, User>(User, { field, value })
      .subscribe(
        {
          next: (res) => {
            // console.log('responsss pafram ', res);
            this.isAvailable = res.data['paramExist'];
            this.showInputLoading(field, value);
            if (!this.isAvailable) {
              if (field === 'email') {
                this.message.email = 'Email Is Availble To Use';
                this.message.emailAvailable = this.isAvailable;
                this.message.emailChecked = true;
                this.isCheckingEmail = false;
              } else {
                this.message.username = 'ID Is Availble To Use';
                this.message.usernameAvailable = this.isAvailable;
                this.message.usernameChecked = true;
                this.isCheckingUsername = false;
              }
            } else {
              if (field === 'email') {
                this.message.email = 'Email Already Exist';
                this.message.emailAvailable = this.isAvailable;
                this.message.emailChecked = true;
                this.isCheckingEmail = false;
              } else {
                this.message.username = 'Aiira ID Already Exists';
                this.message.usernameAvailable = this.isAvailable;
                this.message.usernameChecked = true;
                this.isCheckingUsername = false;
              }
            }

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err);
            this.isCheckingEmail = false;
            this.message.email = 'Something went wrong. Please try again in a moment.'
          },
        }
      );

  }


  isUsernameValid(value: string): boolean {
    if (!this.usernamePattern.test(value)) {

      this.message.username = 'Aiira ID only accepts a-z 0-9 . and _';
      this.message.usernameAvailable = true;
      this.message.usernameChecked = true;
      this.isCheckingUsername = false;
      return false;

    }

    if (this.checkReservedUsernames(value)) {
      this.message.username = 'Aiira ID Is Availble To Use';
      this.message.usernameAvailable = this.isAvailable;
      this.message.usernameChecked = true;
      this.isCheckingUsername = false;

      return false;
    }

    return true;

  }

  isEmailValid(value: string): boolean {
    if (!this.emailPattern.test(value)) {

      this.message.email = 'Email only accepts alphanumeric with . _ and @';
      this.message.emailAvailable = true;
      this.isCheckingEmail = false;
      return false;

    }

    return true;

  }
  confirmPassword() {
    // console.log('password is ', this.pass1);
    return this.pass1 === this.pass2;
  }

  checkReservedUsernames(value: string): boolean {
    // console.log('checking reserved usernames');
    if (this.usernames.indexOf(value) !== -1) {
      this.message.username = 'Aiira ID is not correct, please change it';

      this.isCheckingUsername = false;

      return true;
    }

    return false;
  }

  onCheckPassLength() {
    // console.log('pass length', this.pass1.length);
    if (this.pass1.length < 6) {
      this.message.pass1Error = true;
      this.message.pass1 = 'Password must be at least 6 characters';
    } else {
      this.message.pass1Error = false;
      this.message.pass1 = '';
    }
  }

  onSignup() {
    if (this.confirmPassword()) {
      this.message.passError = false;
      this.message.pass = '';

      const newUser = new User();
      newUser.email = this.user.email;
      newUser.username = this.user.username;
      newUser.password = this.pass1;


      this.isLoading = true;

      this._identityApi.postAs<any, User>(User, newUser)
        .subscribe(
          {
            next: (res) => {
              // console.log('creating new usereeeeee', res);


              if (res.success) {
                // this.userId = res.me;

                const loginModel = {
                  email: newUser.username,
                  password: newUser.password,
                  rememberMe: false,
                  portal: 'Galaxy',
                  app: this.app,
                  signatureType: SignatureTypeEnum.PASSWORD, // 66 - password, 67 - pin, 68 - fingerprint, 69 -faceId
                  device: this.personDevice
                };

                logEvent(this._analytics, 'new_signup')

                // login/get Token
                this._authService.RequestPasswordToken(loginModel, true).subscribe(user => {

                  // this.getSelectedGroup();
                  this.isLoading = false;
                  if (user.idToken) {
                    // only route to setup if on social platform
                    this._appSetting.getAppId() === 60 ?
                      this._router.navigateByUrl('/setup') :
                      this.redirectHomeAfterSignIn();

                  } else {
                    this.message.pass =
                      'An Error Occured. Please try Loging in with your credentials.';
                    this._notify.error('An Error Occured. Please try Loging in with your credentials.');
                  }
                });
              } else {
                this.isLoading = false;
                this.message.pass = 'An Error Occured. Please try again.';
                this._notify.error('An Error Occured. Please try .')
              }
            },
            error: (err) => {
              this.isLoading = false;
              this._errorHandlerService.handleError(err);
              this._notify.error('An Error Occured. Please try .')

              // console.log('err occured on signing up', err);
            },
          }
        );

      // this._userService.signUp(newUser).subscribe(res => {
      //   // console.log('responds',res)
      //   this.isLoading = false;
      //   if (res.result) {
      //     // this.userId = res.me;

      //     const loginModel = {
      //       email: newUser.username,
      //       password: newUser.hashed,
      //       rememberMe: false
      //     };

      //     // login/get Token
      //     this._authService.RequestPasswordToken(loginModel).subscribe(user => {
      //       // this.getSelectedGroup();
      //       this.isLoading = false;
      //       // this.personDetail = true;
      //       this._router.navigateByUrl('/setup');
      //     });
      //   } else {
      //     this.message.pass = 'An Error Occured. Please try again.';
      //   }
      // });
      // console.log('password matcheded');
    } else {
      this.message.passError = true;
      this.message.pass = 'Password does not match';
    }
  }

  onEmbedImage(path: string, size = 'default') {
    return this._embed.imgUrl(path, size);
  }
  private embedImage(path: string, size = 'default') {
    return this._embed.imgUrl(path, size);
  }

  swapCoverImage(image): SafeResourceUrl {
    return this._sanitizer.sanitize(SecurityContext.URL, image);
  }



  switchTerms(value: number) {
    this.termsValue = value;
  }


  onToggleTermsModal() {
    this.showTermsModal = !this.showTermsModal;
    this.modalType = 0;
  }

  onAgreeTerms() {
    // close Terms Model
    // this.showTermsModal = false;

    // Ask For Confirmation
    // this.modalType = 1;
    // this.sendVerificationCode();

    this.onSignup();
    this.showTermsModal = false;


  }


  // Verfication Code
  sendVerificationCode() {
    this.verificationCode = Math.floor(1000 + Math.random() * 9000);
    const msg = "Hi " + this.user.username + ", <br/> Your Aiira Verification Code is: <b>" + this.verificationCode + "</b>. Please verify your email";

    const mail = new SendMail;
    mail.title = 'Confirm Your Email Address'
    mail.description = window.btoa(msg);
    mail.to.push({
      name: this.user.username,
      email: this.user.email
    });
    // mail.to.push('koathecedi@gmail.com');
    mail.from.email = 'verify@aiira.co';
    mail.from.name = 'Aiira Team';

    this._identityApi.post(mail)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.info('Email Sent!');
            } else {
              this._notify.error('Something went wrong, Please resend the code!');
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )

    // console.log(msg)


    // api to send mail

  }

  onVerify() {
    if (this.verificationCode === this.enteredCode) {
      this.user.isVerified = true;
      this.onSignup();
      this.showTermsModal = false;
      this.modalType = 0;
      this._notify.success('Account Verified!');

    } else {
      this._notify.error('Account Not Verified!');
    }

  }


  onToggleForgotPassword(value: boolean) {
    this.showForgot = value;
  }

  onRequestReset() {
    const req = new ResetPassword;
    req.email = this.userName;
    req.requestedIp = this._appSetting.ipAddress;

    this.isLoading = true;
    this._identityApi
      .post(req)
      .subscribe(
        {
          next: (res) => {
            this._notify.info(res.message);

            this.userName = '';
            this.isLoading = false;
            this.showForgot = false;

            logEvent(this._analytics, 'reset_requested', {
              ip: req.requestedIp,
              email: this.userName
            });
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


}
