import { Component, Input, OnInit, SecurityContext, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppSettingService, EmbedService, Entity, IEntity } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-post-mini',
  templateUrl: './post-mini.component.html',
  styleUrls: ['./post-mini.component.scss']
})
export class PostMiniComponent implements OnInit, OnDestroy {

  @Input() data: IEntity = new Entity;
  @Input() showBg: boolean = true;

  art: SafeResourceUrl = '';
  theme: string;themeUpdated$!: Subscription;
  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {

    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    if (this.data.art) {
      this.art = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.art, 'md'));
    }

  }


  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
