<div class="cover-flex {{theme}}">
  <galaxy-entity-image [image]="item.businessProduct.product.art"></galaxy-entity-image>
  <div class="product-info">
    {{ item.businessProduct.product.name | maxLength:15 }}
    <br />
    <small style="opacity: .5;"> {{ item.businessProduct.product.category.toString() | maxLength: 15 }} </small>
    <br>
    <small> pts: {{ item.businessProduct.point }} </small>
  </div>
  <div class="item-number">

  </div>

  <div class="price-info">
    <div style="padding-right: 8px;">
      <small>{{currency}}</small> {{item.amount | number: '1.2-2'}}
    </div>
    <div>
      <small class="item-numbers">
        {{item.units}} units, {{item.packs}} packs
      </small>
    </div>
  </div>

  <button class="adx-btn adx-ellipse adx-icon btn-pink outline adx-flat" *ngIf="allowTrash" (click)="onRemove()">
    <i class="axr ax-trash"></i>
  </button>
</div>
