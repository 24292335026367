import { EventEmitter, Injectable } from '@angular/core';
import { GenericService } from '../GenericService';
import { HttpClient } from '@angular/common/http';
import { Business, BusinessEntity, BusinessSubscription, Group, IBusiness, IBusinessEntity, IBusinessEntityJobDescription, IBusinessSubscription, IClaim, IGroup, IRoleGrant, ISemester, IServerResponse, ITeacherCourseCode, RoleGrant, Subscription } from '../../model';
import { firstValueFrom } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { StatusEnum } from '../../enums';



const businessCategory = [
  { 'id': 66, 'name': 'Arts, crafts, and collectibles.', 'alias': 'biz_arts' },
  { 'id': 67, 'name': 'Baby.', 'alias': 'biz_baby' },
  { 'id': 68, 'name': 'Beauty and fragrances.', 'alias': 'biz_beauty' },
  { 'id': 69, 'name': 'Books and magazines.', 'alias': 'biz_books' },
  { 'id': 70, 'name': 'Business to business.', 'alias': 'biz_business' },
  { 'id': 71, 'name': 'Clothing, accessories, and shoes.', 'alias': 'biz_clothing' },
  { 'id': 72, 'name': 'Computers, accessories, and services.', 'alias': 'biz_computers' },
  { 'id': 73, 'name': 'Education.', 'alias': 'biz_education' },
  { 'id': 74, 'name': 'Electronics and telecom.', 'alias': 'biz_electronics' },
  { 'id': 75, 'name': 'Entertainment and media.', 'alias': 'biz_entertainment' },
  { 'id': 76, 'name': 'Financial services and products.', 'alias': 'biz_financial' },
  { 'id': 77, 'name': 'Food retail and service.', 'alias': 'biz_food' },
  { 'id': 78, 'name': 'Gifts and flowers.', 'alias': 'biz_gift' },
  { 'id': 79, 'name': 'Government.', 'alias': 'biz_government' },
  { 'id': 80, 'name': 'Health and personal care.', 'alias': 'biz_health' },
  { 'id': 81, 'name': 'Home and garden.', 'alias': 'biz_home' },
  { 'id': 82, 'name': 'Nonprofit.', 'alias': 'biz_nonprofit' },
  { 'id': 83, 'name': 'Pets and animals.', 'alias': 'biz_pets' },
  { 'id': 84, 'name': 'Religion and spirituality(for profit).', 'alias': 'biz_religion' },
  { 'id': 85, 'name': 'Retail(not elsewhere classified).', 'alias': 'biz_retail' },
  { 'id': 86, 'name': 'Services - other.', 'alias': 'biz_services' },
  { 'id': 87, 'name': 'Sports and outdoors.', 'alias': 'biz_sports' },
  { 'id': 88, 'name': 'Toys and hobbies.', 'alias': 'biz_toy' },
  { 'id': 89, 'name': 'Travel.', 'alias': 'biz_travel' },
  { 'id': 90, 'name': 'Vehicle sales.', 'alias': 'biz_vehicle' },
  { 'id': 91, 'name': 'Vehicle service and accessories.', 'alias': 'biz_vehicle_service' },
];

const businessSubCategory = [
  { 'id': 92, 'name': 'Antiques', 'superGroupId': 66 },
  { 'id': 93, 'name': 'Art and craft supplies', 'superGroupId': 66 },
  { 'id': 94, 'name': 'Art dealers and galleries', 'superGroupId': 66 },
  { 'id': 95, 'name': 'Camera and photographic supplies', 'superGroupId': 66 },
  { 'id': 96, 'name': 'Digital art', 'superGroupId': 66 },
  { 'id': 97, 'name': 'Memorabilia', 'superGroupId': 66 },
  { 'id': 98, 'name': 'Music store(instruments and sheet music)', 'superGroupId': 66 },
  { 'id': 99, 'name': 'Sewing, needlework, and fabrics', 'superGroupId': 66 },
  { 'id': 100, 'name': 'Stamp and coin', 'superGroupId': 66 },
  { 'id': 101, 'name': 'Stationary, printing and writing paper', 'superGroupId': 66 },
  { 'id': 102, 'name': 'Vintage and collectibles', 'superGroupId': 66 },
  { 'id': 103, 'name': 'Clothing', 'superGroupId': 67 },
  { 'id': 104, 'name': 'Furniture', 'superGroupId': 67 },
  { 'id': 105, 'name': 'Baby products (other)', 'superGroupId': 67 },
  { 'id': 106, 'name': 'Safety and health', 'superGroupId': 67 },
  { 'id': 107, 'name': 'Bath and body', 'superGroupId': 68 },
  { 'id': 108, 'name': 'Fragrances and perfumes', 'superGroupId': 68 },
  { 'id': 109, 'name': 'Makeup and cosmetics', 'superGroupId': 68 },
  { 'id': 110, 'name': 'Audio books', 'superGroupId': 69 },
  { 'id': 111, 'name': 'Digital content', 'superGroupId': 69 },
  { 'id': 112, 'name': 'Educational and textbooks', 'superGroupId': 69 },
  { 'id': 113, 'name': 'Fiction and nonfiction', 'superGroupId': 69 },
  { 'id': 114, 'name': 'Magazines', 'superGroupId': 69 },
  { 'id': 115, 'name': 'Publishing and printing', 'superGroupId': 69 },
  { 'id': 116, 'name': 'Rare and used books', 'superGroupId': 69 },
  { 'id': 117, 'name': 'Accounting', 'superGroupId': 70 },
  { 'id': 118, 'name': 'Advertising', 'superGroupId': 70 },
  { 'id': 119, 'name': 'Agricultural', 'superGroupId': 70 },
  { 'id': 120, 'name': 'Architectural, engineering, and surveying services', 'superGroupId': 70 },
  { 'id': 121, 'name': 'Chemicals and allied products', 'superGroupId': 70 },
  { 'id': 122, 'name': 'Commercial photography, art, and graphics', 'superGroupId': 70 },
  { 'id': 123, 'name': 'Construction', 'superGroupId': 70 },
  { 'id': 124, 'name': 'Consulting services', 'superGroupId': 70 },
  { 'id': 125, 'name': 'Educational services', 'superGroupId': 70 },
  { 'id': 126, 'name': 'Equipment rentals and leasing services', 'superGroupId': 70 },
  { 'id': 127, 'name': 'Equipment repair services', 'superGroupId': 70 },
  { 'id': 128, 'name': 'Hiring services', 'superGroupId': 70 },
  { 'id': 129, 'name': 'Industrial and manufacturing supplies', 'superGroupId': 70 },
  { 'id': 130, 'name': 'Mailing lists', 'superGroupId': 70 },
  { 'id': 131, 'name': 'Marketing', 'superGroupId': 70 },
  { 'id': 132, 'name': 'Multi-level marketing', 'superGroupId': 70 },
  { 'id': 133, 'name': 'Office and commercial furniture', 'superGroupId': 70 },
  { 'id': 134, 'name': 'Office supplies and equipment', 'superGroupId': 70 },
  { 'id': 135, 'name': 'Publishing and printing', 'superGroupId': 70 },
  { 'id': 136, 'name': 'Quick copy and reproduction services', 'superGroupId': 70 },
  { 'id': 137, 'name': 'Shipping and packing', 'superGroupId': 70 },
  { 'id': 138, 'name': 'Stenographic and secretarial support services', 'superGroupId': 70 },
  { 'id': 139, 'name': 'Wholesale', 'superGroupId': 70 },
  { 'id': 140, 'name': 'Children\'s clothing', 'superGroupId': 71 },
  { 'id': 141, 'name': 'Men\'s clothing', 'superGroupId': 71 },
  { 'id': 142, 'name': 'Women\'s clothing', 'superGroupId': 71 },
  { 'id': 143, 'name': 'Shoes', 'superGroupId': 71 },
  { 'id': 144, 'name': 'Military and civil service uniforms', 'superGroupId': 71 },
  { 'id': 145, 'name': 'Accessories', 'superGroupId': 71 },
  { 'id': 146, 'name': 'Retail (fine jewelry and watches)', 'superGroupId': 71 },
  { 'id': 147, 'name': 'Wholesale (precious stones and metals)', 'superGroupId': 71 },
  { 'id': 148, 'name': 'Fashion jewelry', 'superGroupId': 71 },
  { 'id': 149, 'name': 'Computer and data processing services', 'superGroupId': 72 },
  { 'id': 150, 'name': 'Desktops, laptops, and notebooks', 'superGroupId': 72 },
  { 'id': 151, 'name': 'Digital content', 'superGroupId': 72 },
  { 'id': 152, 'name': 'eCommerce services', 'superGroupId': 72 },
  { 'id': 153, 'name': 'Maintenance and repair services', 'superGroupId': 72 },
  { 'id': 154, 'name': 'Monitors and projectors', 'superGroupId': 72 },
  { 'id': 155, 'name': 'Networking', 'superGroupId': 72 },
  { 'id': 156, 'name': 'Online gaming', 'superGroupId': 72 },
  { 'id': 157, 'name': 'Parts and accessories', 'superGroupId': 72 },
  { 'id': 158, 'name': 'Peripherals', 'superGroupId': 72 },
  { 'id': 159, 'name': 'Software', 'superGroupId': 72 },
  { 'id': 160, 'name': 'Training services', 'superGroupId': 72 },
  { 'id': 161, 'name': 'Web hosting and design', 'superGroupId': 72 },
  { 'id': 162, 'name': 'Business and secretarial schools.', 'superGroupId': 73 },
  { 'id': 163, 'name': 'Child daycare services', 'superGroupId': 73 },
  { 'id': 164, 'name': 'Colleges and universities', 'superGroupId': 73 },
  { 'id': 165, 'name': 'Dance halls, studios, and schools', 'superGroupId': 73 },
  { 'id': 166, 'name': 'Elementary and secondary schools', 'superGroupId': 73 },
  { 'id': 167, 'name': 'Vocational and trade schools', 'superGroupId': 73 },
  { 'id': 168, 'name': 'Cameras, camcorders, and equipment', 'superGroupId': 74 },
  { 'id': 169, 'name': 'Cell phones, PDAs, and pagers', 'superGroupId': 74 },
  { 'id': 170, 'name': 'General electronic accessories', 'superGroupId': 74 },
  { 'id': 171, 'name': 'Home audio', 'superGroupId': 74 },
  { 'id': 172, 'name': 'Home electronics', 'superGroupId': 74 },
  { 'id': 173, 'name': 'Security and surveillance', 'superGroupId': 74 },
  { 'id': 174, 'name': 'Telecommunication equipment and sales', 'superGroupId': 74 },
  { 'id': 175, 'name': 'Telecommunication services', 'superGroupId': 74 },
  { 'id': 176, 'name': 'Telephone cards', 'superGroupId': 74 },
  { 'id': 177, 'name': 'Memorabilia', 'superGroupId': 75 },
  { 'id': 178, 'name': 'Movie tickets', 'superGroupId': 75 },
  { 'id': 179, 'name': 'Movies (DVDs, videotapes)', 'superGroupId': 75 },
  { 'id': 180, 'name': 'Music (CDs, cassettes and albums)', 'superGroupId': 75 },
  { 'id': 181, 'name': 'Cable, satellite, and other pay TV and radio', 'superGroupId': 75 },
  { 'id': 182, 'name': 'Adult digital content', 'superGroupId': 75 },
  { 'id': 183, 'name': 'Concert tickets', 'superGroupId': 75 },
  { 'id': 184, 'name': 'Theater tickets', 'superGroupId': 75 },
  { 'id': 185, 'name': 'Toys and games', 'superGroupId': 75 },
  { 'id': 186, 'name': 'Slot machines', 'superGroupId': 75 },
  { 'id': 187, 'name': 'Digital content', 'superGroupId': 75 },
  { 'id': 188, 'name': 'Entertainers', 'superGroupId': 75 },
  { 'id': 189, 'name': 'Gambling', 'superGroupId': 75 },
  { 'id': 190, 'name': 'Online games', 'superGroupId': 75 },
  { 'id': 191, 'name': 'Video games and systems', 'superGroupId': 75 },
  { 'id': 192, 'name': 'Acounting', 'superGroupId': 76 },
  { 'id': 193, 'name': '	Collection agency', 'superGroupId': 76 },
  { 'id': 194, 'name': '	Commodities and futures exchange', 'superGroupId': 76 },
  { 'id': 195, 'name': '	Consumer credit reporting agencies', 'superGroupId': 76 },
  { 'id': 196, 'name': '	Debt counseling service', 'superGroupId': 76 },
  { 'id': 197, 'name': '	Credit union', 'superGroupId': 76 },
  { 'id': 198, 'name': '	Currency dealer and currency exchange', 'superGroupId': 76 },
  { 'id': 199, 'name': '	Escrow', 'superGroupId': 76 },
  { 'id': 200, 'name': '	Finance company', 'superGroupId': 76 },
  { 'id': 201, 'name': '	Financial and investment advice', 'superGroupId': 76 },
  { 'id': 202, 'name': '	Insurance (auto and home)', 'superGroupId': 76 },
  { 'id': 203, 'name': '	Insurance (life and annuity)', 'superGroupId': 76 },
  { 'id': 204, 'name': '	Investments (general)', 'superGroupId': 76 },
  { 'id': 205, 'name': '	Money service business', 'superGroupId': 76 },
  { 'id': 206, 'name': '	Mortgage brokers or dealers', 'superGroupId': 76 },
  { 'id': 207, 'name': '	Online gaming currency', 'superGroupId': 76 },
  { 'id': 208, 'name': '	Paycheck lender or cash advance', 'superGroupId': 76 },
  { 'id': 209, 'name': '	Prepaid and stored value cards', 'superGroupId': 76 },
  { 'id': 210, 'name': '	Real estate agent', 'superGroupId': 76 },
  { 'id': 211, 'name': '	Remittance', 'superGroupId': 76 },
  { 'id': 212, 'name': '	Rental property management', 'superGroupId': 76 },
  { 'id': 213, 'name': '	Security brokers and dealers', 'superGroupId': 76 },
  { 'id': 214, 'name': '	Wire transfer and money order', 'superGroupId': 76 },
  { 'id': 215, 'name': 'Alcoholic beverages', 'superGroupId': 77 },
  { 'id': 216, 'name': 'Catering services', 'superGroupId': 77 },
  { 'id': 217, 'name': 'Coffee and tea', 'superGroupId': 77 },
  { 'id': 218, 'name': 'Gourmet foods', 'superGroupId': 77 },
  { 'id': 219, 'name': 'Specialty and miscellaneous food stores', 'superGroupId': 77 },
  { 'id': 220, 'name': 'Restaurant', 'superGroupId': 77 },
  { 'id': 221, 'name': 'Tobacco', 'superGroupId': 77 },
  { 'id': 222, 'name': 'Vitamins and supplements', 'superGroupId': 77 },
  { 'id': 223, 'name': 'Florist', 'superGroupId': 78 },
  { 'id': 224, 'name': 'Gift, card, novelty, and souvenir shops', 'superGroupId': 78 },
  { 'id': 225, 'name': 'Gourmet foods', 'superGroupId': 78 },
  { 'id': 226, 'name': 'Nursery plants and flowers', 'superGroupId': 78 },
  { 'id': 227, 'name': 'Party supplies', 'superGroupId': 78 },
  { 'id': 228, 'name': 'Government services (not elsewhere classified)', 'superGroupId': 79 },
  { 'id': 229, 'name': 'Drugstore (excluding prescription drugs)', 'superGroupId': 80 },
  { 'id': 230, 'name': 'Drugstore (including prescription drugs)', 'superGroupId': 80 },
  { 'id': 231, 'name': 'Dental care', 'superGroupId': 80 },
  { 'id': 232, 'name': 'Medical care', 'superGroupId': 80 },
  { 'id': 233, 'name': 'Medical equipment and supplies', 'superGroupId': 80 },
  { 'id': 234, 'name': 'Vision care', 'superGroupId': 80 },
  { 'id': 235, 'name': 'Vitamins and supplements', 'superGroupId': 80 },
  { 'id': 236, 'name': 'Antiques', 'superGroupId': 81 },
  { 'id': 237, 'name': 'Appliances', 'superGroupId': 81 },
  { 'id': 238, 'name': 'Art dealers and galleries', 'superGroupId': 81 },
  { 'id': 239, 'name': 'Bed and bath', 'superGroupId': 81 },
  { 'id': 240, 'name': 'Construction material', 'superGroupId': 81 },
  { 'id': 241, 'name': 'Drapery, window covering, and upholstery', 'superGroupId': 81 },
  { 'id': 242, 'name': 'Exterminating and disinfecting services', 'superGroupId': 81 },
  { 'id': 243, 'name': 'Fireplace, and fireplace screens', 'superGroupId': 81 },
  { 'id': 244, 'name': 'Furniture', 'superGroupId': 81 },
  { 'id': 245, 'name': 'Garden supplies', 'superGroupId': 81 },
  { 'id': 246, 'name': 'Glass, paint, and wallpaper', 'superGroupId': 81 },
  { 'id': 247, 'name': 'Hardware and tools', 'superGroupId': 81 },
  { 'id': 248, 'name': 'Home decor', 'superGroupId': 81 },
  { 'id': 249, 'name': 'Housewares', 'superGroupId': 81 },
  { 'id': 250, 'name': 'Kitchenware', 'superGroupId': 81 },
  { 'id': 251, 'name': 'Landscaping', 'superGroupId': 81 },
  { 'id': 252, 'name': 'Rugs and carpets', 'superGroupId': 81 },
  { 'id': 253, 'name': 'Security and surveillance equipment', 'superGroupId': 81 },
  { 'id': 254, 'name': 'Swimming pools and spas', 'superGroupId': 81 },
  { 'id': 255, 'name': 'Charity', 'superGroupId': 82 },
  { 'id': 256, 'name': 'Political', 'superGroupId': 82 },
  { 'id': 257, 'name': 'Religious', 'superGroupId': 82 },
  { 'id': 258, 'name': 'Other', 'superGroupId': 82 },
  { 'id': 259, 'name': 'Personal', 'superGroupId': 82 },
  { 'id': 260, 'name': 'Educational', 'superGroupId': 82 },
  { 'id': 261, 'name': 'Medication and supplements', 'superGroupId': 83 },
  { 'id': 262, 'name': 'Pet shops, pet food, and supplies', 'superGroupId': 83 },
  { 'id': 263, 'name': 'Specialty or rare pets', 'superGroupId': 83 },
  { 'id': 264, 'name': 'Veterinary services', 'superGroupId': 83 },
  { 'id': 265, 'name': 'Membership services.', 'superGroupId': 84 },
  { 'id': 266, 'name': 'Merchandise', 'superGroupId': 84 },
  { 'id': 267, 'name': 'Services (not elsewhere classified)', 'superGroupId': 84 },
  { 'id': 268, 'name': 'Chemicals and allied products', 'superGroupId': 85 },
  { 'id': 269, 'name': 'Department store', 'superGroupId': 85 },
  { 'id': 270, 'name': 'Discount store', 'superGroupId': 85 },
  { 'id': 271, 'name': 'Durable goods', 'superGroupId': 85 },
  { 'id': 272, 'name': 'Non-durable goods', 'superGroupId': 85 },
  { 'id': 273, 'name': 'Used and secondhand store', 'superGroupId': 85 },
  { 'id': 274, 'name': 'Variety store', 'superGroupId': 85 },
  { 'id': 275, 'name': 'Advertising', 'superGroupId': 86 },
  { 'id': 276, 'name': 'Shopping services and buying clubs', 'superGroupId': 86 },
  { 'id': 277, 'name': 'Career services', 'superGroupId': 86 },
  { 'id': 278, 'name': 'Carpentry', 'superGroupId': 86 },
  { 'id': 279, 'name': 'Child care services', 'superGroupId': 86 },
  { 'id': 280, 'name': 'Cleaning and maintenance', 'superGroupId': 86 },
  { 'id': 281, 'name': 'Commercial photography', 'superGroupId': 86 },
  { 'id': 282, 'name': 'Computer and data processing services', 'superGroupId': 86 },
  { 'id': 283, 'name': 'Computer network services', 'superGroupId': 86 },
  { 'id': 284, 'name': 'Consulting services', 'superGroupId': 86 },
  { 'id': 285, 'name': 'Counseling services', 'superGroupId': 86 },
  { 'id': 286, 'name': 'Courier services', 'superGroupId': 86 },
  { 'id': 287, 'name': 'Dental care', 'superGroupId': 86 },
  { 'id': 288, 'name': 'eCommerce services', 'superGroupId': 86 },
  { 'id': 289, 'name': 'Electrical and small appliance repair', 'superGroupId': 86 },
  { 'id': 290, 'name': 'Entertainment', 'superGroupId': 86 },
  { 'id': 291, 'name': 'Equipment rental and leasing services', 'superGroupId': 86 },
  { 'id': 292, 'name': 'Event and wedding planning', 'superGroupId': 86 },
  { 'id': 293, 'name': 'Gambling', 'superGroupId': 86 },
  { 'id': 294, 'name': 'General contractors', 'superGroupId': 86 },
  { 'id': 295, 'name': 'Graphic and commercial design', 'superGroupId': 86 },
  { 'id': 296, 'name': 'Health and beauty spas', 'superGroupId': 86 },
  { 'id': 297, 'name': 'IDs, licenses, and passports', 'superGroupId': 86 },
  { 'id': 298, 'name': 'Importing and exporting', 'superGroupId': 86 },
  { 'id': 299, 'name': 'Information retrieval services', 'superGroupId': 86 },
  { 'id': 300, 'name': 'Insurance - auto and home', 'superGroupId': 86 },
  { 'id': 301, 'name': 'Insurance - life and annuity', 'superGroupId': 86 },
  { 'id': 302, 'name': 'Landscaping and horticultural', 'superGroupId': 86 },
  { 'id': 303, 'name': 'Legal services and attorneys', 'superGroupId': 86 },
  { 'id': 304, 'name': 'Local delivery service', 'superGroupId': 86 },
  { 'id': 305, 'name': 'Lottery and contests', 'superGroupId': 86 },
  { 'id': 306, 'name': 'Medical care', 'superGroupId': 86 },
  { 'id': 307, 'name': 'Membership clubs and organizations', 'superGroupId': 86 },
  { 'id': 308, 'name': 'Misc', 'superGroupId': 86 },
  { 'id': 309, 'name': 'Moving and storage', 'superGroupId': 86 },
  { 'id': 310, 'name': 'Online dating', 'superGroupId': 86 },
  { 'id': 311, 'name': 'Photofinishing', 'superGroupId': 86 },
  { 'id': 312, 'name': 'Photographic studios - portraits', 'superGroupId': 86 },
  { 'id': 313, 'name': 'Protective and security services', 'superGroupId': 86 },
  { 'id': 314, 'name': 'Quick copy and reproduction services', 'superGroupId': 86 },
  { 'id': 315, 'name': 'Radio, television, and stereo repair', 'superGroupId': 86 },
  { 'id': 316, 'name': 'Real estate agent', 'superGroupId': 86 },
  { 'id': 317, 'name': 'Rental property management', 'superGroupId': 86 },
  { 'id': 318, 'name': 'Reupholstery and furniture repair', 'superGroupId': 86 },
  { 'id': 319, 'name': 'Services (not elsewhere classified)', 'superGroupId': 86 },
  { 'id': 320, 'name': 'Shipping and packing', 'superGroupId': 86 },
  { 'id': 321, 'name': 'Swimming pool services', 'superGroupId': 86 },
  { 'id': 322, 'name': 'Tailors and alterations', 'superGroupId': 86 },
  { 'id': 323, 'name': 'Telecommunication service', 'superGroupId': 86 },
  { 'id': 324, 'name': 'Utilities', 'superGroupId': 86 },
  { 'id': 325, 'name': 'Vision care', 'superGroupId': 86 },
  { 'id': 326, 'name': 'Watch, clock, and jewelry repair', 'superGroupId': 86 },
  { 'id': 327, 'name': 'Athletic shoes', 'superGroupId': 87 },
  { 'id': 328, 'name': 'Bicycle shop, service, and repair', 'superGroupId': 87 },
  { 'id': 329, 'name': 'Boating, sailing and accessories', 'superGroupId': 87 },
  { 'id': 330, 'name': 'Camping and outdoors', 'superGroupId': 87 },
  { 'id': 331, 'name': 'Dance halls, studios, and schools', 'superGroupId': 87 },
  { 'id': 332, 'name': 'Exercise and fitness', 'superGroupId': 87 },
  { 'id': 333, 'name': 'Fan gear and memorabilia', 'superGroupId': 87 },
  { 'id': 334, 'name': 'Firearm accessories', 'superGroupId': 87 },
  { 'id': 335, 'name': 'Firearms', 'superGroupId': 87 },
  { 'id': 336, 'name': 'Hunting', 'superGroupId': 87 },
  { 'id': 337, 'name': 'Knives', 'superGroupId': 87 },
  { 'id': 338, 'name': 'Martial arts weapons', 'superGroupId': 87 },
  { 'id': 339, 'name': 'Sport games and toys', 'superGroupId': 87 },
  { 'id': 340, 'name': 'Sporting equipment', 'superGroupId': 87 },
  { 'id': 341, 'name': 'Swimming pools and spas', 'superGroupId': 87 },
  { 'id': 342, 'name': 'Arts and crafts', 'superGroupId': 88 },
  { 'id': 343, 'name': 'Camera and photographic supplies', 'superGroupId': 88 },
  { 'id': 344, 'name': 'Hobby, toy, and game shops', 'superGroupId': 88 },
  {
    'id': 345, 'name': 'Memorabilia', 'superGroupId': 88
  },
  { 'id': 346, 'name': 'Music store - instruments and sheet music', 'superGroupId': 88 },
  { 'id': 347, 'name': 'Stamp and coin', 'superGroupId': 88 },
  { 'id': 348, 'name': 'Stationary, printing, and writing paper', 'superGroupId': 88 },
  { 'id': 349, 'name': 'Vintage and collectibles', 'superGroupId': 88 },
  { 'id': 350, 'name': 'Video games and systems', 'superGroupId': 88 },
  { 'id': 351, 'name': 'Airline', 'superGroupId': 89 },
  { 'id': 352, 'name': 'Auto rental', 'superGroupId': 89 },
  { 'id': 353, 'name': 'Bus line', 'superGroupId': 89 },
  { 'id': 354, 'name': 'Cruises', 'superGroupId': 89 },
  { 'id': 355, 'name': 'Lodging and accommodations', 'superGroupId': 89 },
  { 'id': 356, 'name': 'Luggage and leather goods', 'superGroupId': 89 },
  { 'id': 357, 'name': 'Recreational services', 'superGroupId': 89 },
  { 'id': 358, 'name': 'Sporting and recreation camps', 'superGroupId': 89 },
  { 'id': 359, 'name': 'Taxicabs and limousines', 'superGroupId': 89 },
  { 'id': 360, 'name': 'Timeshares', 'superGroupId': 89 },
  { 'id': 361, 'name': 'Tours', 'superGroupId': 89 },
  { 'id': 362, 'name': 'Trailer parks or campgrounds', 'superGroupId': 89 },
  { 'id': 363, 'name': 'Transportation services - other', 'superGroupId': 89 },
  { 'id': 364, 'name': 'Travel agency', 'superGroupId': 89 },
  { 'id': 365, 'name': 'Auto dealer - new and used', 'superGroupId': 90 },
  { 'id': 366, 'name': 'Auto dealer - used only', 'superGroupId': 90 },
  { 'id': 367, 'name': 'Aviation', 'superGroupId': 90 },
  { 'id': 368, 'name': 'Boat dealer', 'superGroupId': 90 },
  { 'id': 369, 'name': 'Mobile home dealer', 'superGroupId': 90 },
  { 'id': 370, 'name': 'Motorcycle dealer', 'superGroupId': 90 },
  { 'id': 371, 'name': 'Recreational and utility trailer dealer', 'superGroupId': 90 },
  { 'id': 372, 'name': 'Recreational vehicle dealer', 'superGroupId': 90 },
  { 'id': 373, 'name': 'Vintage and collectibles', 'superGroupId': 90 },
  { 'id': 374, 'name': 'New parts and supplies - motor vehicle', 'superGroupId': 91 },
  { 'id': 375, 'name': 'Used parts - motor vehicle', 'superGroupId': 91 },
  { 'id': 376, 'name': 'Audio and video', 'superGroupId': 91 },
  { 'id': 377, 'name': 'Auto body repair and paint', 'superGroupId': 91 },
  { 'id': 378, 'name': 'Auto rental', 'superGroupId': 91 },
  { 'id': 379, 'name': 'Auto service', 'superGroupId': 91 },
  { 'id': 380, 'name': 'Automotive tire supply and service', 'superGroupId': 91 },
  { 'id': 381, 'name': 'Boat rental and leases', 'superGroupId': 91 },
  { 'id': 382, 'name': 'Car wash', 'superGroupId': 91 },
  { 'id': 383, 'name': 'Motor home and recreational vehicle rental', 'superGroupId': 91 },
  { 'id': 384, 'name': 'Tools and equipment', 'superGroupId': 91 },
  { 'id': 385, 'name': 'Towing service', 'superGroupId': 91 },
  { 'id': 386, 'name': 'Truck and utility trailer rental', 'superGroupId': 91 },
  { 'id': 387, 'name': 'Accessories', 'superGroupId': 91 },
];

const dexApps = [
  { id: 63, name: "airMeet", alias: "airMeet", description: "Appointment Management System" },
  { id: 60, name: "airCare", alias: "airCare", description: "Hospital Management System" },
  { id: 61, name: "airStore", alias: "airStore", description: "Inventory Management System" },
  { id: 58, name: "airFinance", alias: "airFinance", description: "Financial Management System" },
  { id: 59, name: "airClient", alias: "airClient", description: "Client Management System" },
  { id: 64, name: "airGroup", alias: "airGroup", description: "Group Management System" },
  { id: 62, name: "airSchool", alias: "airSchool", description: "School Management System" },
  { id: 65, name: "airRent", alias: "airRent", description: "Asset Rental Management System" },
  { id: 408, name: "airMail", alias: "airMail", description: "SMS & Mailing System" },
  { id: 409, name: "airDelivery", alias: "airDelivery", description: "Shipping & Delivery System" },
  { id: 457, name: "airXpense", alias: "airXpense", description: "Expense Report System" },
  { id: 487, name: "airLab", alias: "airLab", description: "Laboratory Management System" },
  { id: 502, name: "airStaff", alias: "airStaff", description: "Human Resource Management System" },
  { id: 503, name: "airFleet", alias: "airFleet", description: "Fleet Management System" },

];


const assetType = [
  { id: 494, name: "Apartment", alias: "apartment", description: "" },
  { id: 495, name: "Room", alias: "room", description: "" },
  { id: 496, name: "Bed", alias: "bed", description: "" },
  { id: 497, name: "Electronics", alias: "electronics", description: "" },
  { id: 498, name: "Vehicle", alias: "vehicle", description: "" },
  { id: 499, name: "Instruments", alias: "instruments", description: "" },
  { id: 500, name: "Construction Tools", alias: "construction_tools", description: "" },
  { id: 501, name: "Decor (Lighting/Canopies/Chairs)", alias: "decor", description: "" },

];

@Injectable({
  providedIn: 'root'
})
export class SuiteApiService extends GenericService {

  appletMenuItems: {
    name: string,
    icon: string,
    route: string
  }[] = [];
  viewerJobDescription: IBusinessEntityJobDescription;
  qompassPage$ = new EventEmitter<string>();

  qompassPageLoading$ = new EventEmitter<boolean>();

  myBusiness: IBusiness[] = [];
  myBusinessList = new EventEmitter<IBusiness[]>();


  defaultActiveBusiness: IBusiness = new Business;
  activeBusiness = new EventEmitter<IBusiness>();

  defaultActiveRole: IRoleGrant = new RoleGrant;
  activeRole = new EventEmitter<IRoleGrant>();

  activeAppRoles: IRoleGrant[] = [];
  activeAppClaim: IClaim;

  private businessAppClaims: IClaim[] = [];
  businessAppClaims$ = new EventEmitter<IClaim[]>();

  semester: ISemester;
  activeTeacherCourseCode: ITeacherCourseCode;
  activeTeacherCourseCode$ = new EventEmitter<ITeacherCourseCode>();

  private suiteData: any[] = [];

  private today = new Date();
  private defaultStarterPlan = new BusinessSubscription(
    0,
    this.defaultActiveBusiness as Business,
    new Subscription(0, 'Starter Plan', 'Freemium', 2, 100, 0),
    0,
    'GHS',
    'GooglePay',
    '',
    null,
    StatusEnum.PUBLISHED,
    12,
    this.today.getTime(),
    0
  )


  activeAirSuiteApp = new EventEmitter<IGroup>();
  activeBusinessEntity = new EventEmitter<IBusinessEntity>();


  billChanged$ = new EventEmitter();

  dataChanged$ = new EventEmitter<{ type: object, data: any }>();


  constructor(http: HttpClient,
    private _router: Router,
    private _storageMap: StorageMap) {
    const rootUrl = 'https://api-v2.aiira.co';
    const baseUrl = '/suite-api/';
    super(http, baseUrl, rootUrl);
  }


  getDataFromSuite(name: string) {
    return this.suiteData[name];
  }

  getDexApps(): IGroup[] {
    return <IGroup[]>dexApps;
  }
  getAssetTypes(): IGroup[] {
    return <IGroup[]>assetType;
  }


  async getBusinessEntity(businessEntityId: number): Promise<IServerResponse<IBusinessEntity>> {
    const et = await this
      .setParams(null, businessEntityId.toString())
      .get(BusinessEntity);

    return firstValueFrom(et);
  }



  getActiveBusinessSubscription(): IBusinessSubscription {
    const biz = this.getActiveBusiness();
    if (biz.subscriptions?.length > 0) {

      return biz.subscriptions[0];
    }

    this.today.setFullYear(this.today.getFullYear() + 1);

    this.defaultStarterPlan.expireDate = this.today.getTime() / 1000;

    return this.defaultStarterPlan;
  }


  verifyAppClaim(appName: string): boolean {
    let appInstalled = false;

    for (let index = 0; index < this.businessAppClaims.length; index++) {
      if (this.businessAppClaims[index].app.name === appName) {
        appInstalled = true;

        console.log('appFound!')
        break;
      }
    }

    console.log('status!  ', appInstalled)
    return appInstalled;
  }

  verifyBusinessSubscription(totalClient: number = 0): {
    hasExpired: boolean,
    appLimitReached: boolean,
    clientLimitReached: boolean,
    limitReached: boolean
  } {


    // for dev
    // if (!this.enableProd) // disabled temp for piloting. would bill based on usage
    return {
      hasExpired: false,
      appLimitReached: false,
      clientLimitReached: false,
      limitReached: false
    };


    // update total client count of business if posible
    if (this.defaultActiveBusiness.entitiesCount < totalClient) {
      this.defaultActiveBusiness.entitiesCount = totalClient;
    }


    const plan = this.getActiveBusinessSubscription();
    // console.log('exp date vs today is ',  new Date(plan.expireDate * 1000).toISOString() , new Date(new Date().getTime()).toISOString(), plan.expireDate * 1000 < new Date().getTime())
    const verify = {
      hasExpired: plan.expireDate * 1000 <= new Date().getTime(),
      appLimitReached: this.businessAppClaims.length >= plan.subscription.appLimit,
      clientLimitReached: plan.subscription.clientLimit >= this.defaultActiveBusiness.entitiesCount,
      limitReached: true
    };

    verify.limitReached = this.businessAppClaims.length > plan.subscription.appLimit ||
      plan.subscription.clientLimit > this.defaultActiveBusiness.entitiesCount ||
      verify.hasExpired;

    // console.log(verify,plan.subscription.appLimit,this.businessAppClaims.length);

    return verify;

  }




  getBusinessCategory(): IGroup[] {
    return <IGroup[]>businessCategory;
  }
  getBusinessSubCategory(): IGroup[] {
    return <IGroup[]>businessSubCategory;
  }



  addBussinessToLocal(data: IBusiness) {
    data.branch = null;
    this.myBusiness.push(data);
    this.myBusinessList.emit(this.myBusiness);

  }

  setLocalBusiness(data: IBusiness[]) {
    this.myBusiness = data;
  }

  getLocalBusiness(): IBusiness[] {
    return this.myBusiness;
  }



  setActiveBusiness(data: IBusiness) {
    this.defaultActiveBusiness = data;
    localStorage.setItem('act_biz', JSON.stringify(data));
    this.activeBusiness.emit(data);
  }

  getActiveBusiness(): IBusiness {
    if (this.defaultActiveBusiness) {
      return this.defaultActiveBusiness;
    }

    const biz = localStorage.getItem('act_biz');
    if (biz) {
      return this.defaultActiveBusiness = JSON.parse(biz);
    }

    this._router.navigateByUrl('/');
    return;
  }

  setBusinessAppClaims(businessAppClaims: IClaim[]) {
    this.businessAppClaims = businessAppClaims;
    this.businessAppClaims$.emit(this.businessAppClaims);

  }
  getBusinessAppClaims(): IClaim[] {
    return this.businessAppClaims;
  }

  setActiveRole(data: IRoleGrant) {
    this.defaultActiveRole = data;
    this._storageMap.set('act_role', JSON.stringify(data)).subscribe(() => {
      this.activeRole.emit(data);
    })
  }

  getActiveRole(): IRoleGrant {
    if (this.defaultActiveRole) {
      return this.defaultActiveRole;
    }

    const role = localStorage.getItem('act_role');
    if (role) {
      return this.defaultActiveRole = JSON.parse(role);
    }

    // this._router.navigateByUrl('/');
    // return;
  }


  setActiveSemester(data: ISemester) {
    this.semester = data;
  }


  getActiveSemester(): ISemester {
    return this.semester;
  }




  setAtiveTeacherCourseCode(data: ITeacherCourseCode) {
    this.activeTeacherCourseCode = data;
    this.activeTeacherCourseCode$.emit(data);
  }

  getActiveTeacherCourseCode(): ITeacherCourseCode {
    return this.activeTeacherCourseCode;
  }




  setDataToSuite(name: string, data: any) {
    this.suiteData[name] = data;
  }

}
