
export enum DeliveryItemTypeEnum {
  ELECTRONIC = 1,
  CLOTHING = 2,
  FILES_DOCUMENTS = 3,
  FURNITURE = 4,
  TOOLS = 5,
  VEHICLE = 6,
  FOOD = 7,
  MEDICINE = 8,
  ORGANIC_LIVESTOCKS = 9,
  OTHER = 10,
}
