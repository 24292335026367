
export enum ActivityTypeEnum {
  CREATE = 1,
  LIKE = 2, //24
  COMMENT = 3,
  REPOST = 4,
  SHARE = 5,
  VIEWED = 6, //26
  FOLLOW = 7,
  VOTE = 8, //75
}
