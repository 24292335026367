<!-- {{ data | json }} -->
<div text-center>
  <div class="adx-divider">
    Choose Image for {{ fileAlias }}
  </div>
</div>
<div class="clearfix"></div>
<div class="media-grid" *ngIf="data.length > 0" [style.height]="minHeight + 'px'">
  <galaxy-person-media-item *ngFor="let item of data" [data]="item" (click)="onMediaSelected(item.path)">
  </galaxy-person-media-item>
</div>
<div *ngIf="isLoading" class="adx-card adx-flat is-loading">
  <p></p>
</div>
<div *ngIf="!isLoading && data.length === 0" class="adx-card adx-flat text-center">
  <div style="display: flex; align-items: center; justify-content: center;" [style.height]="minHeight + 'px'">
    <p>
      <i class="axl ax-photos ax-5x" style="opacity: 0.5;"></i>
      <br />
      <br />
      No Media Found
    </p>
  </div>
</div>
