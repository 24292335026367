<div>&nbsp; Share</div>
<div *ngIf="entity" class="adx-card adx-full bg-darker adx-round adx-shadows adx-flat">
  <galaxy-media-preview *ngIf="entity && !isLoading" [entity]="entity"></galaxy-media-preview>
</div>
<br />
<div class="share-grid">
  <div>
    <a target="_blank" [href]="
        'http://pinterest.com/pin/create/button/?url=' +
        link +
        '&description=' +
        title
      " class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-red">
      <i class="axr ax-brand-pinterest"></i>
    </a>
    <small> Pinterest </small>
  </div>
  <div>
    <a target="_blank" [href]="'https://web.whatsapp.com/send?text=' + title + ' ' + link"
      class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-green">
      <i class="axr ax-brand-whatsapp"></i>
    </a>
    <small> WhatsApp </small>
  </div>
  <div>
    <a target="_blank" [href]="'https://www.facebook.com/sharer.php?u=' + link"
      class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-blue">
      <i class="axr ax-brand-facebook"></i>
    </a>
    <small> Facebook </small>
  </div>

  <div>
    <a target="_blank" [href]="'https://twitter.com/intent/tweet?text=' + title + '&url=' + link"
      class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-prim">
      <i class="axr ax-brand-twitter"></i>
    </a>
    <small> Twitter </small>
  </div>

  <div>
    <a target="_blank" [href]="
        'https://www.linkedin.com/shareArticle?mini=true&url=' +
        link +
        '&title=' +
        title
      " class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-blue">
      <i class="axr ax-brand-linkedin"></i>
    </a>
    <small> LinkedIn </small>
  </div>
  <div>
    <a target="_blank" [href]="'http://www.reddit.com/submit?url=' + link"
      class="adx-btn adx-md adx-round adx-shadows adx-icon outline adx-flat btn-red">
      <i class="axr ax-brand-reddit"></i>
    </a>
    <small> Reddit </small>
  </div>

  <div>
    <button class="adx-btn adx-md btn-grey adx-round adx-shadows adx-icon outline adx-flat" (click)='onCopy()'>
      <i class="axr ax-link"></i>
    </button>
    <small> Copy link </small>
  </div>
  <div>
    <button class="adx-btn adx-md btn-grey adx-round adx-shadows adx-icon outline adx-flat">
      <i class="axr ax-mail"></i>
    </button>
    <small> Email </small>
  </div>
</div>
<p></p>
