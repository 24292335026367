<div>
  <h3 class="title text-normal">Analytics</h3>

  <app-post-mini [data]='data'></app-post-mini>

  <div class="adx-list adx-flat">
    <div class="adx-item">
      <h3>Impressions</h3>
      <p>times people saw this post on aiira</p>

      <span class="float-right">{{ data.hit }}</span>
    </div>
    <div class="adx-item">
      <h3>Total Engagements</h3>
      <p>times people interacted this post</p>

      <span class="float-right">{{ data.engagement }}</span>
    </div>

    <hr />
    <!-- <div class="adx-item">
      <h3>Media engagements</h3>
      <p>
        number of clicks on your media counted across videos, vines, gifs and
        images
      </p>

      <span class="float-right">35</span>
    </div> -->
    <div class="adx-item">
      <h3>Comments</h3>
      <p>number discussions generated from this post</p>

      <span class="float-right">{{ data.commentCount }}</span>
    </div>

    <div class="adx-item">
      <h3>Likes</h3>
      <p>times people liked this post</p>

      <span class="float-right">{{ data.likeCount }}</span>
    </div>

    <div class="adx-item">
      <h3>Reposts</h3>
      <p>times people reposted this post</p>

      <span class="float-right">{{ data.repostCount }}</span>
    </div>
  </div>
</div>
