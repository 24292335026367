<div class="{{ bgColor }} banner">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="text-size24">
            <h2 class="title text-normal">
              <span class="logo" *ngIf="showLogo">
                <img src="/assets/images/logo/{{ logo }}.png" alt="" />
              </span>
              <!-- <br /> -->

              <b>{{ title }}</b>
            </h2>
          </div>
          <p class="text-size16" style="opacity: 0.7;">
            {{ description }}
          </p>
          <!-- <p></p> -->

          <button
            *ngFor="let item of routes"
            class="adx-btn adx-round {{
              isBgLite ? 'bg-dark' : 'btn-default'
            }} clear adx-sm adx-flat"
          >
            <i [class]="item.icon"></i>
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
