import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmbedService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-entity-image',
  templateUrl: './entity-image.component.html',
  styleUrls: ['./entity-image.component.scss']
})
export class EntityImageComponent implements OnInit {

  @Input() image: string = '';
  @Input() isRound: boolean = false;
  @Input() isAvatar: boolean = false;
  @Input() size: string = 'sm';

  srcData: SafeResourceUrl = '';
  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this.srcData = this._embed.imgUrl(this.image, this.size);
  }

}
