import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService, AuthService, SuiteApiService, EmbedService, NotifyService, IBusinessEntity, BusinessProduct, StatusEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-product-newx',
  templateUrl: './product-new.component.html',
  styleUrls: ['./product-new.component.scss']
})
export class ProductNewComponent implements OnInit {


  businessId: number = 0;

  @Input() isCountable: boolean = true;

  data: IBusinessEntity = new BusinessProduct;
  photo: SafeResourceUrl = '';

  isLoading: boolean = false;

  isDataReady = false;
  isEdit: boolean = false;

  viewerId: number = 0;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);
    if (this.businessId !== 0) {
      // this.onReloadData();
    }

    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;


    // check params to make sure it isnt an edit
    this._aRoute.params.subscribe(res => {
      if (res['id']) {
        // console.log('getting entity');
        this.getData(res['id']);
      } else {
        // console.log('creating entity');
        this.newDataInit();
      }
    });

  }


  getData(id: string) {

    this.isLoading = true;

    this._suiteApi
      .setParams(null, id)
      .get(BusinessProduct)
      .subscribe(
        {
          next: (res) => {

            this._suiteApi.DataMap({ from: res.data, to: this.data });
            // console.log('bus entity is ', res, this.data);
            this.isEdit = true;
            this.photo = this._embed.imgUrl(res.data.product.art, 'md');
            this.isLoading = false;
            this.isDataReady = true;
            // _$(res).MapTo(this.album);
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  newDataInit() {
    this.data = new BusinessProduct();

    // set user info
    this.data.business.id = this.businessId;

    //  init status to published
    this.data.status = StatusEnum.PUBLISHED;

    // is countable
    this.data.product.isCountable = this.isCountable;


    this.isLoading = false;
    this.isDataReady = true;

  }

  onGetImage(image: string) {
    this.data.product.art = image;
    this.photo = this._embed.imgUrl(image, 'md');
  }


  onSubmit(status: number) {
    this.data.status = status;

    // set creator/modifier
    this.data.createdBy.id = this.viewerId;

    this.isLoading = true;

    this.isEdit ? this.updateEntity() : this.createEntity();

  }

  updateEntity() {
    this._suiteApi
      .setParams({}, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  createEntity() {

    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

}
