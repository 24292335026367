import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private _notify:NotifyService) { }

  handleError(error: HttpErrorResponse, customErrorMessage:string = 'Something went wrong, please try again.') {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Server-side error: ${error.status} ${error.message}`;
    }

    // Optionally log to the console or send to logging infrastructure
    console.error(errorMessage);

    // Optionally show a user-friendly message
    this._notify.error(customErrorMessage); // If you are using a library like ngx-toastr

    return throwError(() => new Error(errorMessage));
  }
}
