import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss']
})
export class PlatformsComponent implements OnInit, AfterViewInit {

  activeSection = '/assets/images/aiira-bg-mini.jpg';

  @ViewChild('introSection') introSection: ElementRef;
  @ViewChild('qompassSection') qompassSection: ElementRef;
  @ViewChild('airSuiteSection') airSuiteSection: ElementRef;
  @ViewChild('connectSection') connectSection: ElementRef;
  constructor() { }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
    console.log('section reading is -> ', this.qompassSection);

    this.switchMobileView()
  }

  ngOnInit() {
  }


  switchMobileView() {
    console.log((this.qompassSection.nativeElement as HTMLDivElement).offsetTop)
  }
}
