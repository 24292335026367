import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-placeholder',
  templateUrl: './activity-placeholder.component.html',
  styleUrls: ['./activity-placeholder.component.scss']
})
export class ActivityPlaceholderComponent implements OnInit {


  @Input() showImage: boolean = true;
  @Input() isNotification: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
