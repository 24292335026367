<div class="post-wrapper {{ theme }} " [class.comic-flex]='isComic'>
  <div class="media" (click)="onViewEntity()" [class.hd]="entityType === 'product'" [class.square]="isSquare"
    [class.potrait]="isPotrait">
    <div class="thumbnail">
      <img *ngIf="!showArtworks" [src]="art" alt="" class="img-responsive" />
    </div>
    <div class="p-overlay">
      <div class="p-collection">
        <!-- collection/album/playlist -->
        <!--
          <galaxy-media-collection-select

          ></galaxy-media-collection-select>
        -->
        <div style="padding: 0 8rem;">
          <!-- add to collection -->
          <span class="float-right">
            <button *ngIf="showBookmark" (click)="onCollectionClicked($event)"
              class="adx-btn btn-tgreen adx-round adx-icon">
              <!-- <i class="fa fa-cabinet-filing"></i> -->
              <i class="axr ax-bookmark"></i>
            </button>
            <br />
            <!-- add to cart -->
            <button *ngIf="entityType === 'product'" class="adx-btn btn-dark adx-round adx-icon"
              (click)="onAddToCart($event)">
              <i class="axr ax-cart-plus"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="p-footer">
        <div class="float-left">
          <!-- artwork -->

          <!-- <span class="type" *ngIf="entityType === 'photo'">
            <i class="fa fa-images"></i> &nbsp;
            <i class="fa fa-cube"></i> &nbsp;
            <i class="fa fa-play"></i>
          </span> -->

          <!-- video -->
          <span class="type" *ngIf="entityType === 'video'">
            <i class="axs ax-movie"></i> &nbsp; 23:00
          </span>

          <!-- audiio -->
          <span class="type" *ngIf="entityType === 'audio'">
            <i class="axs ax-music"></i> &nbsp; 23:00
          </span>

          <!-- product -->
          <!--
            <span class="type" *ngIf="entityType === 'product'">
              <i class="fa fa-price"></i> &nbsp; ${{ entity.price }}
            </span>
          -->
        </div>

        <button class="adx-btn btn-dark float-right adx-round adx-icon adx-trigger share-btn" (click)="onShare($event)">
          <i class="axr ax-share"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="p-detail">
    <!-- artwork -->
    <!-- <div>
      <span class="et-type" *ngIf="entityType === 'photo'">
        <i class="fa fa-clone fa-rotate-180"></i> &nbsp;
        <i class="fa fa-cube"></i> &nbsp; <i class="fa fa-play"></i>
      </span>
    </div> -->
    <!-- <div class="tags" *ngIf="entityType !== 'photo'">
      <small> <i class="fa fa-tag"></i> &nbsp; {{ entity.type.name }} </small>
    </div> -->

    <!--
      <h2 class="text-normal text-size16 no-margin">{{ entity.title }}</h2>
    -->

    <div class="person">
      <!--
        <div class="adx-img" *ngIf="showAvatar">
          <img [lazyLoad]="avatar" class="img-responsive" alt="" />
        </div>
      -->

      <div class="detail">
        <div class="defaultDetail" *ngIf="showDetail">
          <div class="title {{ entityType }}" [class.no-margin]='isComic'>{{ (isComic || entity.isComic ?
            entity.album?.title :
            entity.title | maxLength: 32 ) }}</div>
          <div *ngIf="showPerson" class="name" [routerLink]="[
              '/profile/' + entity.person.username + personMediaProfileRoute
            ]" style="font-weight: normal;">
            {{ isComic || entity.isComic ? entity.title +' | '+ (entity.modified*1000 | date) :entity.person.username }}
          </div>
        </div>
        <div class="price" *ngIf="entityType !== 'photo'">
          <div class="amount">
            <b style="font-weight: 500;">
              {{
              salesPrice | currency:'USD':true
              }}
            </b>
            <del *ngIf="showDiscount">
              <small>
                {{ entity.price | currency:'USD':true }}
              </small>
            </del>
          </div>
          <!-- <div class="discount bg-bluex text-right">
            <span class="" *ngIf="showDiscount">
              <small style="padding:4px; border-radius: 4px;" class='bg-yellow float-right'>
                -{{ entity.discount }}%
              </small>
            </span>
          </div> -->
        </div>
      </div>
      <button *ngIf="showDetail" class="adx-btn adx-round btn-grey outline adx-flat adx-xs adx-icon" style="margin: 0;">
        <i class="axs ax-ellipsis-h"></i>
      </button>
    </div>
  </div>
</div>
