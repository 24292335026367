<div class="banner">
  <div class="content">
    <div class="text-center text-size20">
      <span style="letter-spacing: 10px;">
        Connected Together.
      </span>
      <br>
      <span style="font-size: 40px;">
        Innovate. Connect. Grow.
      </span>
    </div>
    <div class="platform-grid">
      <app-platform-card *ngFor="let item of data" [data]="item"></app-platform-card>
    </div>

  </div>
</div>
<div class="suite-banner">
  <app-home-qompass></app-home-qompass>
  <app-home-suite></app-home-suite>
</div>
<div class="ark"></div>
<div class="interverse-banner bg-black">
  <app-home-connect></app-home-connect>
  <app-home-interverse></app-home-interverse>
</div>
