<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
<galaxy-select-item></galaxy-select-item>
