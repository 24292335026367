import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppSettingService, IMessage, Message, AuthService, EmbedService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-adx-chat',
  templateUrl: './ad-chat.component.html',
  styleUrls: ['./ad-chat.component.scss']
})
export class AdChatComponent implements OnInit, OnDestroy {
  @Input() data: IMessage = new Message;

  @Input() isMe: boolean = false;
  @Input() bgColor: string = '';
  @Input() customColor: boolean = false;
  @Input() showImage: boolean = true;
  @Input() isGroupChat: boolean = false;
  theme: string = 'light';
  themeUpdated$!: Subscription;
  avatar: SafeResourceUrl = '';

  personId: number = 0;


  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _embed: EmbedService,
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
      if (!this.customColor) {
        this.bgColor = res === 'light' ? 'bg-blue' : 'bg-tgreen'
      }
    });

    this.personId = this._authService.currentUser.gid.social.u;

    if (this.personId === this.data.person.id) {
      this.isMe = true;
      this.customColor = true;
    } else {
      this.isMe = false;
    }
    this.showImage = !this.isMe;

    if (this.showImage) {
      this.avatar = this._embed.imgUrl(this.data.person.image, 'sm');
    }

  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
