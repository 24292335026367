import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService, SocialApiService, IPersonMedia, PersonMedia, ErrorHandlerService } from '@galaxy/entity-api';


@Component({
  selector: 'galaxy-person-media',
  templateUrl: './person-media.component.html',
  styleUrls: ['./person-media.component.scss']
})
export class PersonMediaComponent implements OnInit {

  @Input() personId: number = 0;
  @Input() fileAlias: string = 'avatar';
  @Input() sortPersonMediaByAlias: boolean = true;
  @Input() minHeight: number = 500;

  @Output() mediaSelected = new EventEmitter<string>();
  data: IPersonMedia[] = [];

  isLoading: boolean = false;
  constructor(
    private _socialApi: SocialApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.getData();
  }


  getData() {
    const params: any = {
      id: this.personId,
      page: 1,
      pageSize: 50

    }

    if (this.sortPersonMediaByAlias) {
      params.category = this.fileAlias;
    }

    this._socialApi
      .setParams(params)
      .getPageOf(PersonMedia)
      .subscribe(
        {
          next: (res) => {
            this.data = <IPersonMedia[]>res.data;
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


  onMediaSelected(path: string) {
    // console.log('sending path of ', path)
    this.mediaSelected.emit(path);
  }

}
