<div class="banner">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <div class="banner-flex">
          <div class="post">
            <div class="adx-card bg-white">
              <p>
                something here
              </p>
            </div>
          </div>
          <div class="live">
            <!-- <galaxy-media-card
              [entityType]="'video'"
            ></galaxy-media-card> -->
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
