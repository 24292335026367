<div class="img" [lazyLoad]="cover" (click)="onViewEntity()"></div>
<div class="detail" (click)="onViewEntity()">
  <span class="name">{{ data.title | maxLength: 20 }}</span>
  <!-- <span class="person" style="opacity: 0.5;">
    {{ data.person.username | maxLength: 20 }}
  </span> -->
  <span class="price">
    <b>
      {{ salesPrice * (data.myOrderCount??1) | currency:'USD':true }}
    </b>
    &nbsp;
    <del *ngIf="showDiscount" style="display: block;">
      <small> {{ data.price | currency:'USD':true }} </small>
    </del>
  </span>
</div>
<div class="actions">
  <!-- product actions -->
  <span *ngIf="!isCart">
    <button (click)="onCollectionClicked($event)" class="adx-btn adx-rounder adx-icon adx-flat" [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-grey': theme == 'light'
      }">
      <i class="color-tgreen axr ax-bookmark"></i>
    </button>
    <br />
    <button (click)="onAddToCart($event)" class="adx-btn adx-rounder adx-icon adx-flat" [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-grey': theme == 'light'
      }">
      <i class="color-blue axr ax-cart-plus"></i>
    </button>
  </span>

  <!-- cart actions -->
  <span *ngIf="isCart">
    <button class="adx-btn adx-rounder adx-icon adx-flat" [ngClass]="{
      'btn-dark': theme == 'dark',
      'btn-grey': theme == 'light'
    }" (click)='onChangeMyOrderCount(false)' [disabled]='data.myOrderCount <= data.minOrderCount'>
      <i class="color-red axr ax-minus"></i>
    </button>

    <button class="adx-btn btn-tgreen adx-icon adx-rounder">
      {{data.myOrderCount}}
    </button>

    <button class="adx-btn adx-rounder adx-icon adx-flat" [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-grey': theme == 'light'
      }" (click)='onChangeMyOrderCount(true)'>
      <i class="color-tgreen axr ax-plus"></i>
    </button>

    &nbsp;

    <button class="adx-btn adx-rounder adx-icon adx-flat" [ngClass]="{
        'btn-dark': theme == 'dark',
        'btn-grey': theme == 'light'
      }" (click)='onShowDelete()'>
      <i class="color-pink axr ax-trash"></i>
    </button>
  </span>
</div>

<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showModal">
  <div class='text-center'>
    <div class="del-pr-image " [lazyLoad]="cover">
      <span>
        <i class="axr ax-shopping-cart-x ax-2x color-pink"></i>
      </span>
    </div>
    <br>
    <h1 class='title text-normal'>Remove Product From My Cart</h1>
    <p class='text-size18' style="opacity: .7;">
      Do you want to remove the product <b class='color-pink'>{{data.title}}</b> from your cart?
    </p>
  </div>


  <div>
    <button class="adx-btn adx-round adx-sm btn-default" (click)='onCloseClicked(false)'>
      Cancel
    </button>

    <button class='adx-btn adx-round adx-sm btn-pink float-right' (click)='onRemoveProductFromCart()'>
      Remove
    </button>
  </div>
</galaxy-modal>
