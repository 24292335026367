import { StatusEnum, TransactionTypeEnum } from "../../enums";
import { Controller } from "../decorators";
import { Business, IBusiness } from "./business.model";

export interface ISubscription {
  id: number;
  name: string;
  description: string;
  appLimit: number;
  clientLimit: number;
  amount: number;
  status: StatusEnum;
  attributes?: any[];
  created: number;
  modified?: number;
  businessSubscriptions?: IBusinessSubscription[];
}

@Controller({ name: 'Subscription' })
export class Subscription implements ISubscription {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public appLimit = 0,
    public clientLimit = 0,
    public amount = 0,
    public status = StatusEnum.PUBLISHED,
    public attributes = [],
    public created = 0,
  ) {

  }
}



export interface IPromoCode {
  id: number;
  code: string;
  description: string;
  discount: number;
  useLimit: number;
  status: StatusEnum;
  expireDate: number;
  created: number;
  businessSubscriptions?: IBusinessSubscription[];
}


@Controller({ name: 'PromoCode' })
export class PromoCode implements IPromoCode {
  constructor(
    public id = 0,
    public code = '',
    public description = '',
    public discount = 0,
    public useLimit = 0,
    public expireDate = 0,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
  ) {


  }
}


export interface IBusinessSubscription {
  id: number;
  business: IBusiness;
  subscription: ISubscription;
  amount: number;
  paymentGateway: string;
  paymentToken: string;
  currency: string;
  promoCode?: IPromoCode;
  status: StatusEnum;
  paymentPlan: number;
  expireDate: number;
  created: number;
  modified?: number;
}


@Controller({ name: 'BusinessSubscription' })
export class BusinessSubscription implements IBusinessSubscription {
  constructor(
    public id = 0,
    public business = new Business,
    public subscription = new Subscription,
    public amount = 0,
    public paymentGateway = 'GooglePay',
    public paymentToken = '',
    public currency = '',
    public promoCode = new PromoCode,
    public status = StatusEnum.PUBLISHED,
    public paymentPlan = 3,
    public expireDate = 0,
    public created = 0,
  ) { }
}






export interface IBusinessPaymentAccount {
  id: number;
  business: IBusiness;
  amount: number;
  currentBalance: number;
  type: TransactionTypeEnum;
  paymentGateway: string;
  paymentToken: string;
  currency: string;
  promoCode?: IPromoCode;
  status: StatusEnum;
  created: number;
  modified?: number;
}


@Controller({ name: 'BusinessPaymentAccount' })
export class BusinessPaymentAccount implements IBusinessPaymentAccount {
  constructor(
    public id = 0,
    public business = new Business,
    public amount = 0,
    public currentBalance = 0,
    public paymentGateway = 'GooglePay',
    public paymentToken = '',
    public type = TransactionTypeEnum.DEBIT,
    public currency = '',
    public promoCode = new PromoCode,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
  ) { }
}
