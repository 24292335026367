import { Component, Input, OnInit } from '@angular/core';
import { OriDbService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-ori-shortcut',
  templateUrl: './ori-shortcut.component.html',
  styleUrls: ['./ori-shortcut.component.scss']
})
export class OriShortcutComponent implements OnInit {

  cartCount: number = 0;
  @Input() isMini = false;
  @Input() theme = 'light';
  constructor(private _oriDB: OriDbService) { }

  ngOnInit() {
    // store cart
    this.cartCount = this._oriDB.defaultCart.length;
    this._oriDB.cart.subscribe(res => this.cartCount = res.length)
  }

}
