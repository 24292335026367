import { Component, OnInit, Input } from '@angular/core';
import { Group, IGroup } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent implements OnInit {

  @Input() group: IGroup = new Group;
  @Input() isSelected: boolean = false
  constructor() { }

  ngOnInit() {
  }

}
