import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IClient, Client, AppSettingService, SuiteApiService, Dashboard, GenderEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-person-actvity',
  templateUrl: './person-actvity.component.html',
  styleUrls: ['./person-actvity.component.scss']
})
export class PersonActvityComponent implements OnInit, OnChanges {


  @Input() person: IClient = new Client;
  @Input() appId: number = 58;

  theme: string = '';
  themeUpdated$!: Subscription;

  metric: { label: string, icon: string, value: string }[] = [
    { label: '--- --------', icon: 'axl ax-list', value: '----' },
    { label: '--- --------', icon: 'axl ax-list', value: '----' },
  ];

  isMetricLoading = false;
  currency: string;

  today = (new Date()).getTime();


  genderEnum: typeof GenderEnum = GenderEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const appId = changes.appId;
    const person = changes.person;

    if (
      !appId.isFirstChange() &&
      appId.currentValue !== appId.previousValue
    ) {
      this.getProfileMetric();
    }

    if (!person.isFirstChange() && person.currentValue !== person.previousValue) {
      this.person = person.currentValue;
    }
  }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.getProfileMetric();

  }

  getProfileMetric() {
    if (this.person.businessEntityId <= 0) {
      return;
    }

    this.isMetricLoading = true;
    this._suiteApi
      .setParams({}, 'profile/' + this.appId + '/' + this.person.businessEntityId)
      .get(Dashboard).subscribe(
        {
          next: (res) => {

            if (res)
              this.metric = res.data as any;
            this.isMetricLoading = false;
          },
          error: (err) => {
            this.isMetricLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }



}
