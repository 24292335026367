<div class="adx-card adx-flat adx-round bg-dark padding manga-flex  {{theme}}">
  <div class="manga-browse">
    <div class="adx-divider">
      Browse Latest Updates
    </div>
    <div class="manga-grid">
      <div class='manga-item' *ngFor="let item of data; index as i" style="margin-right: 1rem;">
        <galaxy-media-card (addToCollection)="onShowModalClicked($event, 2)"
          (shareEntity)="onShowModalClicked($event, 1)" (viewEntity)="onImageClicked($event, i)" [isComic]=true
          [showDetail]="showDetail" [entity]="item" [isPotrait]="true" [showBookmark]="showBookmark">
        </galaxy-media-card>

      </div>
    </div>

  </div>
  <div class="manga-popular {{theme}}">
    <div class="adx-divider">
      Popular Mangas
    </div>

    <div class="popular-grid">
      <galaxy-media-toon-item *ngFor="let item of popularData ; index as i" [data]='item'
        (viewEntity)="onImageClicked(item.id, i)">
      </galaxy-media-toon-item>
    </div>

  </div>

</div>


<!-- view image -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showImageModal" [fixedWidth]="false"
  [width]="70" [showPadding]="false">
  <div class="paddingx">
    <galaxy-media-card-modal *ngIf="showImageModal" [entity]="projectSelected"></galaxy-media-card-modal>
  </div>

</galaxy-modal>
