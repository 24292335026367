import { Component, OnInit, Input } from '@angular/core';
import { IEntity, Entity, AppSettingService, ArtistApiService, Project, IGroup, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'galaxy-media-featured',
  templateUrl: './media-featured.component.html',
  styleUrls: ['./media-featured.component.scss']
})
export class MediaFeaturedComponent implements OnInit {

  @Input() searchValue: string = '';
  @Input() personId: number = 0;
  @Input() dataLimit: number = 3;
  @Input() maxTitleLength: number = 40;
  @Input() orderBy: string = 'featured, likeCount, commentCount, engagement, id';

  @Input() isList: boolean = false;
  @Input() isMini: boolean = false;
  isLoading: boolean = true;
  data: IEntity[] = [];

  pageSize: number = 3;
  page: number = 1;
  totalData: number = 0;
  groupType: number = 62;
  categorySelected: number = 0;
  categories: IGroup[] = [];


  // for pop-up


  showModal: boolean = false;
  showEntityModal: boolean = false;
  modalNumber: number = 1;

  entity: IEntity = new Entity;
  entityId: number = 30;
  prevProject?: IEntity = null;
  prevProjectIndex: number = 0;
  nextProject?: IEntity = null;
  nextProjectIndex: number = 0;
  entitySelected: IEntity;
  isFirstData: boolean;


  constructor(
    private _appSetting: AppSettingService,
    private _aRoute: ActivatedRoute,
    private _artistApi: ArtistApiService,
    private _storageMap: StorageMap,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.pageSize = this.dataLimit;
    this.page = 1;
    this.categorySelected = 0;

    // get category
    this.categories = this._appSetting.getBlogType();
    this._aRoute.queryParams
      .subscribe(res => {
        // console.log('qery maps are ', res);
        this.searchValue = res['q'];
        this._getStorageData();
        this.getData();
      })
  }


  private _resetData() {
    this.data = [];
    this.isFirstData = true;

  }
  private _getStorageData() {
    if (this.personId > 0) return;
    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_featBnr';

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        if (res) {
          this.data = res.get('photo') ?? [];
        }
      });

  }


  private _storeStorageData() {
    if (this.personId > 0) return;

    const domain = '_featBnr';
    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        let dataMap = new Map<string, IEntity[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set('photo', this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }

  onReload() {
    this.isLoading = true;
    this.pageSize = 50;
    this.page = 1;
    this.getData();

  }


  getData(): any {
    // get the id of the person
    this._artistApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.categorySelected,
        gid: this._appSetting.getAppId(),
        id: this.personId,
        orderBy: [this.orderBy]

      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = res.data as IEntity[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            if (this.isFirstData) {
              this._storeStorageData();
              this.isFirstData = false;
            }

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onReloadData() {
    // reset limit value to default if its lower than 5
    this.pageSize = this.dataLimit;
    this.page = 1;
    this.getData();
  }




  onShowModalClicked(entity: IEntity, type: number) {
    this.entity = entity;
    this.modalNumber = type;
    this.showModal = true;
  }
  onCloseClicked($event) {
    this.showModal = false;
    this.showEntityModal = false;
  }
  getEntityLink() {
    return 'localhost:4200/store/product/alias/id';
  }



  onEntityClicked(entity: IEntity, index: number = null) {
    // console.log('id @ masonry is ', entity.id)
    // this.showImageModal = false;
    this.entitySelected = entity;
    this.entityId = entity.id;
    const len = this.data.length;
    if (index !== null && len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProject = this.data[this.prevProjectIndex];

      this.nextProjectIndex = (index + 1) % len;
      this.nextProject = this.data[this.nextProjectIndex];
      if (index === 0) {
        this.prevProject = null;

      }
      if (index == len - 1) {
        this.nextProject = null;
      }

    }

    // check if there is a prevous & next
    this.showEntityModal = true;
  }

}
