<!-- <div class="single-ad"> -->
<div
  class="adx-card  adx-round adx-flat single-ad"
  [ngClass]="{
    'bg-dark': theme == 'dark',
    'bg-white': theme == 'light'
  }"
>
  <div class="adx-card adx-round bg-dark adx-img">
    <img
      src="assets/images/spring.jpg"
      class="img-responsive"
      alt=""
    />
  </div>
  <div class="info-flex">
    <div class="person">
      <div class="space"></div>
      <div class="adx-img">
        <img
          src="
        assets/images/olivia-black.jpg"
          alt=""
          class="img-responsive"
        />
      </div>
    </div>
    <p>
      <strong>Lorem</strong>
      <span> ipsum dolor sit amet consectetur adipisicing elit. </span>
    </p>
  </div>
</div>
<!-- </div> -->
