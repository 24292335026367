import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IStoreInvoiceItem, StoreInvoiceItem } from '@galaxy/entity-api';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() theme = 'light';
  @Input() allowTrash = true;
  @Input() item: IStoreInvoiceItem = new StoreInvoiceItem;
  @Input() currency: string;
  @Output() removeItem = new EventEmitter<IStoreInvoiceItem>();
  constructor() { }

  ngOnInit() {

  }

  onRemove() {
    this.removeItem.emit(this.item);
  }
}
