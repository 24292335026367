<div class="cover-pad count-{{ count }}">
  <div *ngIf="count === 1; then singleImage; else multipleImage"></div>
  <div class="blur-board" *ngIf="isSensitive">
    <div class="message-alert text-left">
      <div class="text-center">
        <i class="axl ax-eye-off ax-3x"></i>
      </div>
      <br>
      <div>
        <div>
          <strong>Content warning: Sensitive content</strong>
        </div>
        <br>
        <div>
          Connect author flagged this post as showing sensitive content.
        </div>
      </div>


      <div class="text-right">
        <button class="adx-btn adx-round btn-pink adx-flat" (click)=" onShowSensitiveContent($event)">Show</button>
      </div>
    </div>
  </div>

  <div class="post-label" *ngIf="type">
    <span class="icon">
      <i [class]="type.icon"></i>
    </span>
    <span class="label">
      {{ type.label}}
    </span>
  </div>
</div>

<ng-template #singleImage>
  <div [class.is-loading]='isLoading'>
    <img #imgLoad [lazyLoad]="imageData[0]" [defaultImage]="defaultImage" (onStateChange)="onImageLoadState($event)"
      (load)="onImageLoaded()" class="img-responsive" alt="photo" (contextmenu)="$event.preventDefault()" />
  </div>
  <div *ngIf="loadError" class='reload' (click)='onReloadImage($event)'>
    <div>
      <i class="axl ax-refresh"></i>
      <br>
      <br>
      Tap to reload
    </div>
  </div>
</ng-template>

<ng-template #multipleImage>
  <div [class.is-loading]='isLoading' class="tile-grid count-{{ count }}">
    <div class="one" *ngFor="let item of imageData" [defaultImage]="defaultImage" [lazyLoad]="item"></div>
  </div>
</ng-template>
