import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { IBusinessEntity, BusinessEntity, AppSettingService, AuthService, SuiteApiService, EmbedService, NotifyService, IBusinessBranchDepartment, BusinessBranchDepartment, Reservation, IReservation, ITransaction, BusinessSocketService, IBusinessRecord, StatusEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute, Router } from '@angular/router';
import { empty, Subscription } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-reservation-new',
  templateUrl: './reservation-new.component.html',
  styleUrls: ['./reservation-new.component.scss']
})
export class ReservationNewComponent implements OnInit, OnChanges, OnDestroy {

  person: IBusinessEntity = new BusinessEntity;
  data: IReservation = new Reservation();
  businessId: number = 0;
  @Input() id: number = 0;
  @Input() appId: number = 60; // 60 - airCare, 63 - airMeet
  @Input() businessEntityId: number = 0;
  @Input() isEdit: boolean = false;

  branchDepartments: IBusinessBranchDepartment[] = [];
  isLoading: boolean = true;
  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  categorySelected: number = 2; // status - 2 published, 3 - trashed
  searchValue: string = '';
  orderBy: string = 'id';

  assignRole: boolean = false;
  branchId: string | number = 0;
  dateTimeNow: string = '';
  appointmentDate: string = '';

  totalAmount: number = 0;
  viewerId: number = 0;


  showCheckOutModal = false;
  theme: string;

  themeUpdated$!:Subscription;


  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _businessSocket: BusinessSocketService,
    private _errorHandlerService: ErrorHandlerService

  ) { }


  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);

    // console.log('current User', this._authService.currentUser);
    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;

    // set Date
    this.dateTimeNow = new Date().toISOString().split('.')[0];
    this.appointmentDate = this.dateTimeNow;





    this.isLoading = true;
    // check params to make sure it isnt an edit
    if (this.businessEntityId !== 0) {
      this.getPersonDetail(this.businessEntityId);
      // console.log('creating entity with bEid', this.businessEntityId);
      this.newDataInit();
    } else {
      // check route param for edit
      this.checkActivatedRoute();

    }

  }



  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.businessEntityId;

    if (!this.isEdit && !id.firstChange && id.currentValue !== 0 && id.previousValue !== id.currentValue) {
      this.isLoading = true;
      this.getPersonDetail(this.businessEntityId);
      // console.log('creating entity with bEid', this.businessEntityId);
      this.newDataInit();
    }

  }




  checkActivatedRoute() {
    // check params to make sure it isnt an edit
    this._aRoute.params.subscribe(res => {
      if (res['id']) {
        // console.log('getting entity');
        const reservationId = res['id']
        this.getData(reservationId);

      } else {
        this.onCloseDetail();
      }
    });
  }

  getData(appointmentId: number): any {
    // get the id of the person
    this.isLoading = true;


    this._suiteApi
      .setParams(null, appointmentId.toString())
      .get(Reservation)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            this._suiteApi.DataMap({ from: res.data, to: this.data });
            this.person.entity = res.data.businessEntity.entity;
            this.isEdit = true;

            // set appointmentDateTime
            this.appointmentDate = new Date(this.data.reservedDate * 1000).toISOString().split('.')[0];

            this.onReloadDepartmentData();

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  newDataInit() {
    this.data = new Reservation();

    // set user info
    this.data.businessEntity.id = this.businessEntityId;

    //  init status to published
    this.data.status = StatusEnum.PUBLISHED;
    this.data.app.id = this.appId;

    this.isLoading = false;


  }


  getPersonDetail(businessEntityId: number) {
    this.isLoading = true;
    this.person.id = businessEntityId;

    this.person.entity = this._suiteApi.getDataFromSuite('client');

    // this._suiteApi.setParams(null, businessEntityId.toString())
    //   .get(BusinessEntity).subscribe(res => {
    //     this.person.entity = res.data.entity;

    // only reload department if its empty, else reset it
    if (this.branchDepartments.length === 0) {
      this.onReloadDepartmentData();
    } else {
      this.data.businessBranchDepartment.id = this.branchDepartments[0].id;
      this.data.businessBranchDepartment.businessDepartment.name = this.branchDepartments[0].businessDepartment.name;
    }

    // });

  }


  onReloadDepartmentData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getDepartmentData();

  }



  getDepartmentData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.branchId,
        orderBy: [this.orderBy]

      })
      .getPageOf(BusinessBranchDepartment)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.branchDepartments = <IBusinessBranchDepartment[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            // set default
            if (!this.isEdit) {
              this.data.businessBranchDepartment.id = this.branchDepartments[0].id;
            }

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getDepartmentData();

  }

  onCloseModal(value) {
    this.showCheckOutModal = false;
  }


  onSubmit(statusId: number) {

    // set creator/modifier
    this.data.createdBy.id = this.viewerId;

    // appointment datetime
    this.data.reservedDate = new Date(this.appointmentDate).getTime();

    this.data.status = statusId;

    // this.data.branch = [this.businessBranch];

    // console.log(this.data);
    // return;


    if (this.isEdit) {
      // this.isLoading = true;
      this.updateEntity()
    } else {
      // set department name
      const index = this.branchDepartments.findIndex(x => +x.id === +this.data.businessBranchDepartment.id);
      this.data.businessBranchDepartment.businessDepartment.name = this.branchDepartments[index].businessDepartment.name;

      this.showCheckOutModal = true;
    }

  }



  updateEntity() {
    this.isLoading = true;

    this._suiteApi
      .setParams(null, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  createEntity(transaction: ITransaction) {

    this.isLoading = true;

    this.data.transaction = transaction;

    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              // web socket
              this._businessSocket.sendRecord(this.data);


              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }


}
