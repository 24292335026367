import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainAppComponent } from './main-app.component';
import { HomeComponent } from './home/home.component';
import { MainAppRoutingModule } from './main-app-routing.module';
import { RouterModule } from '@angular/router';
import { DirectoryComponent } from './directory/directory.component';
import { ToolComponent } from './tool/tool.component';
import { BusinessModule } from '@galaxy/suite/business';
import { SuiteUiModule } from '@galaxy/suite/ui';
import { PlayComponent } from './play/play.component';
import { CashComponent } from './cash/cash.component';
import { BusinessComponent } from './business/business.component';
import { AppxComponent } from './appx/appx.component';
import { DeveloperComponent } from './developer/developer.component';
import { NewsComponent } from './news/news.component';
import { StudioComponent } from './studio/studio.component';
import { PageBannerComponent } from './shared/page-banner/page-banner.component';
import { ClientsComponent } from './shared/clients/clients.component';
import { ServiceCardComponent } from './shared/service-card/service-card.component';
import { SupportComponent } from './support/support.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UiModule } from '@galaxy/ui';
import { FormsModule } from '@angular/forms';
import { PlatformsComponent } from './home/platforms/platforms.component';
import { SuiteAppsComponent } from './shared/suite-apps/suite-apps.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { BannerComponent } from './home/banner/banner.component';
import { PlatformShowcaseComponent } from './home/platform-showcase/platform-showcase.component';
import { HomeInteractiveComponent } from './home-interactive/home-interactive.component';
import { PlatformCardComponent } from './home-interactive/platform-card/platform-card.component';
import { ShowcaseComponent } from './home-interactive/showcase/showcase.component';
import { HomeQompassComponent } from './home-interactive/home-qompass/home-qompass.component';
import { HomeSuiteComponent } from './home-interactive/home-suite/home-suite.component';
import { HomeConnectComponent } from './home-interactive/home-connect/home-connect.component';
import { HomeInterverseComponent } from './home-interactive/home-interverse/home-interverse.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MainAppRoutingModule,
    UiModule,
    SuiteUiModule
  ],
  declarations: [
    MainAppComponent,
    HomeComponent,
    PlatformsComponent,
    DirectoryComponent,
    ToolComponent,
    PlayComponent,
    CashComponent,
    BusinessComponent,
    AppxComponent,
    DeveloperComponent,
    NewsComponent,
    StudioComponent,
    PageBannerComponent,
    ClientsComponent,
    SupportComponent,
    ServiceCardComponent,
    ResetPasswordComponent,
    SuiteAppsComponent,
    TosComponent,
    PrivacyComponent,
    DeleteAccountComponent,
    BannerComponent,
    PlatformShowcaseComponent,
    HomeInteractiveComponent,
    PlatformCardComponent,
    ShowcaseComponent,
    HomeQompassComponent,
    HomeSuiteComponent,
    HomeConnectComponent,
    HomeInterverseComponent
  ]
})
export class MainAppModule { }
