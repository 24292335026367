<div class="grid-et">
  <galaxy-entity-card *ngFor="let item of data" [isImageRound]="true" [image]="item.image" [name]="item.name"
    [code]="item.personId" [description]="item.phoneOne" [alias]="item.gender.name" [price]="''">
    <button class="adx-btn adx-icon adx-flat clear"
      (click)="item.businessEntityId == 7 ? null :onDetailRoute('edit/' + item.id, 'Edit Person Info')">
      <i class="axl ax-edit"></i>
    </button>

    <button class="adx-btn adx-icon adx-flat clear" *ngIf="!(item.businessEntityId == 7)"
      (click)="onDetailRoute('edit/' + item.id, 'Print Id Card')">
      <i class="axl ax-id"></i>
    </button>

    <div>
      <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear" (click)="
        onDetailRoute(
          btnItem.route + (btnItem.objectName ? '' : item.businessEntityId),
          btnItem.title,
          btnItem.objectName,
          item
        )
        ">
        <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
      </button>
    </div>
  </galaxy-entity-card>
</div>

<ng-content></ng-content>
