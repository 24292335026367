
<app-person-job-description></app-person-job-description>

<div style="display: flex;">
  <div class="adx-divider" style="flex:1">My Apps
  </div>
  <div style="margin:1.6rem">
    <small class="subscription bg-special float-right">{{businessSubscription.subscription.name}}</small>
  </div>
</div>


<div class="gridi" [class.is-loading]='isLoading'>
  <!-- <galaxy-adx-flex [title]="'My Apps'"> -->
  <div class="dex-app" *ngFor="let item of data; index as i">
    <galaxy-adaptive-card [isFlat]=true [showPadding]=false [classes]="'no-margin'" [shape]="'rounded'" [autoDimBlack]="true"
      (click)='onShowAccessModal(item)'>
      <div class="app-con">
        <div class="app-logo">
          <img src="/assets/images/logo/{{item.app.name}}.png" alt="" />
        </div>
        <div class="text-center">
          <h2 class="title text-normal">{{ item.app.name }}</h2>
          <p>{{ item.app.description }}</p>
        </div>

      </div>
    </galaxy-adaptive-card>
  </div>

</div>
<div class="adx-card adx-flat">
  <!-- {{ claims | json }} -->
</div>



<galaxy-modal [showModal]='showAccessModal' [showPadding]="false" (onCloseModalClicked)='onCloseAccessModal($event)'>
  <app-access-level-bar *ngIf="showAccessModal" [appClaim]='selectedAppClaim' [appClaimRoles]='selectedAppClaimRoles'
    (onCloseModal)='onCloseAccessModal($event)'>
  </app-access-level-bar>
</galaxy-modal>
