<div class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat ">
          <div class="topix text-center">
            <h1 class="text-normal text-size34">Privacy Policy</h1>
          </div>

          <p class="text-size18">
            Aiira Technologies is committed to protecting the privacy of its users. This Privacy Policy explains how we
            collect, use, and share information through the Aiira Technologies app (the "App") and our
            websites. By accessing or using the App or our websites, you acknowledge that you have read and understand
            this Privacy Policy and agree to the collection, use, and sharing of your information as described in this
            Privacy Policy.

          </p>
          <h2 class="title text-normal">

            Information We Collect
          </h2>

          <p class="text-size18">
            We may collect the following types of information through the App and our websites:
            <br>
            <br>
            <strong>Personal information</strong>: This includes information that can be used to identify you, such as
            your name, email
            address, and phone number.
            <br>
            <strong>Demographic information</strong>: This includes information about your age, gender, and location.
            <br>
            <strong>Business information</strong>: This includes information about your business, such as the name,
            address, and contact
            information.
            <br>
            <strong> User content</strong>: This includes any content that you post, upload, or otherwise contribute to
            the App or our
            websites, such as text, photos, and videos.
            <br>
            <strong>Usage data</strong>: This includes information about how you use the App and our websites, such as
            the features you
            access and the actions you take.
            <br>
            <strong>Device information</strong>: This includes information about the device you use to access the App
            and our websites,
            such as the make and model, operating system, and IP address.
            <br>
          <h2 class="title text-normal">
            How We Use Your Information
          </h2>
          <p class="text-size18">
            We may use the information we collect through the App and our websites for the following purposes:
            <br>
            <br>
            <strong>To provide and improve the App and our websites</strong>: We may use your information to operate and
            maintain the App
            and our websites, and to develop new features and functionality.
            <br>
            <strong>To communicate with you</strong>: We may use your information to send you updates, notifications,
            and other
            communications related to the App and our websites.
            <br>
            <strong>To personalize your experience</strong>: We may use your information to personalize the content and
            experiences you
            have through the App and our websites.
            <br>
            <strong>To analyze and understand usage</strong>: We may use your information to understand how users
            interact with the App
            and our websites, and to analyze and improve the App and our websites.
            <br>
            <strong>For legal purposes</strong>: We may use your information as required or permitted by law, such as to
            comply with
            legal process or to protect the rights, property, or safety of Aiira Technologies, our users, or others.
          </p>
          <h2 class="title text-normal">

            How We Share Your Information
          </h2>
          <p class="text-size18">
            We may share your information in the following ways:
            <br>
            <br>
            <strong>With third-party service providers</strong>: We may share your information with third parties that
            provide services
            on our behalf, such as hosting, analytics, and payment processing.
            <br>
            <strong>With other users</strong>: Your User Content and certain other information that you provide through
            the App or our
            websites may be visible to other users of the App or our websites.
            <br>
            <strong>With third-party advertisers</strong>: We may share anonymous or aggregated information with
            third-party advertisers
            for the purpose of delivering targeted advertising.
            <br>
            <strong>As required or permitted by law</strong>: We may disclose your information as required or permitted
            by law, such as
            to comply with legal process or to protect the rights, property, or safety of Aiira Technologies, our users,
            or others.
          </p>
          <h2 class="title text-normal">
            Your Choices </h2>
          <p class="text-size18">
            You have the following choices regarding the information we collect and how it is used:
            <br>
            <br>
            <strong>Opting out of communications</strong>: You can opt out of receiving communications from us by
            following the
            unsubscribe instructions in the communications you receive.
            <br>
            <strong>Controlling your personal information</strong>: You can access, update, or delete your personal
            information by
            logging into your account and accessing your account settings. You may also be able to modify your
            preferences for certain types of data collection, such as location data, through the settings on your
            device.
            <br>

            <strong>Controlling cookies and tracking technologies</strong>: You can control the use of cookies and other
            tracking
            technologies through your browser settings or by using opt-out tools provided by third parties. Please note
            that some features of the App and our websites may not function properly if you disable cookies.
            <br>
            <strong>Exercise your rights under applicable laws</strong>: Depending on your location, you may have
            certain rights under
            data protection laws, such as the right to access, rectify, erase, or object to the processing of your
            personal information. You may also have the right to object to the processing of your personal information
            for direct marketing purposes. To exercise these rights, please contact us using the contact information
            provided in Section 8 of this Privacy Policy.

          </p>
          <h2 class="title text-normal">
            Data Security
          </h2>
          <p class="text-size18">
            We use reasonable security measures to protect the information we collect through the App and our websites.
            However, no method of transmission over the internet, or method of electronic storage, is 100% secure.
            Therefore, we cannot guarantee the absolute security of your information.
          </p>
          <h2 class="title text-normal">
            Children's Privacy
          </h2>
          <p class="text-size18">
            The App and our websites are not intended for children under the age of 13. We do not knowingly collect
            personal information from children under the age of 13. If you are a parent or guardian and you believe that
            your child under the age of 13 has provided personal information to us, please contact us using the contact
            information provided in Section 8 of this Privacy Policy.
          </p>
          <h2 class="title text-normal">
            Third-Party Services
          </h2>
          <p class="text-size18">
            The App and our websites may contain links to third-party websites and services. We are not responsible for
            the privacy practices of these third parties. We encourage you to review the privacy policies of any
            third-party websites or services that you access.
          </p>
          <h2 class="title text-normal">
            Contact Us
          </h2>
          <p class="text-size18">
            If you have any questions or concerns about this Privacy Policy, or if you would like to exercise your
            rights under applicable data protection laws, please contact us at support&#64;aiira.co
          </p>

        </div>
      </div>
    </div>
  </div>
</div>
