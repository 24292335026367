import { Component, OnInit, Input } from '@angular/core';
import { IEntity, ArtistApiService, Project } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-artist',
  templateUrl: './adx-artist.component.html',
  styleUrls: ['./adx-artist.component.scss']
})
export class AdxArtistComponent implements OnInit {
  @Input() id: number = 0;
  @Input() entity: IEntity = new Project;
  isLoading: boolean = true;
  showEntity: boolean = false;
  constructor(
    private _artistApi: ArtistApiService
  ) { }


  ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      this.getEntity();
    } else {
      this.isLoading = false;
    }
  }

  getEntity() {
    this.isLoading = true;
    this._artistApi.get(Project).subscribe(res => {
      this.entity = res.data;
      this.isLoading = false;
    })
  }


  onViewEntity() {
    this.showEntity = true;
  }

  onCloseEntity() {
    this.showEntity = false;
  }
}
