import { Component, OnInit, Input, SecurityContext, ViewChild, ElementRef } from '@angular/core';
import { IArtwork, EmbedService, MediaTypeEnum } from '@galaxy/entity-api';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'galaxy-media-tile',
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss']
})
export class MediaTileComponent implements OnInit {


  @Input() artworks: IArtwork[] = [];
  @Input() type!: { label: string, icon: string, route?: string };
  @Input() image: string = '';
  @Input() isSingleImage: boolean = false;
  @Input() isComic: boolean = false;
  @Input() isSensitive: boolean = false;
  imageData: string[] = [];
  count: number = 0;
  loadError: boolean = false;
  isLoading: boolean = false;

  imageToShowOnError = '/assets/images/no-image-found.jpg';
  defaultImage = '/assets/images/default-image.jpg';

  @ViewChild('imgLoad') imgLoad: ElementRef<HTMLImageElement>;

  firstImageLoad = false;

  constructor(private _embed: EmbedService, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.isSingleImage) {
      this.processSingleImage()
    }
    else if (this.isComic) {
      this.processComicArt();
    }
    else {
      this.processArtwork();
    }

  }

  processSingleImage() {
    this.count = 1;
    const thumbnail =
      this._sanitizer.sanitize(
        SecurityContext.URL, this._embed.imgUrl(
          this.image, 'md'
        )
      );

    this.imageData.push(thumbnail);
  }



  processComicArt() {
    this.count = 3;
    const thumbnail =
      this._sanitizer.sanitize(
        SecurityContext.URL, this._embed.imgUrl(
          this.image, 'md'
        )
      );

    this.imageData.push(thumbnail);

    for (let index = 1; index < this.count; index++) {

      const thumbnail =
        this._sanitizer.sanitize(
          SecurityContext.URL, this._embed.imgUrl(
            this.artworks[index].type === MediaTypeEnum.IMAGE ?
              this.artworks[index].path :
              this.artworks[index].thumbnail.replace(/['"]+/g, ''),

            'md'
          )
        );

      this.imageData.push(thumbnail);

    }

  }



  processArtwork() {
    this.count = this.artworks.length;
    if (this.artworks.length > 6) {
      this.count = 5;
    }

    for (let index = 0; index < this.count; index++) {

      const thumbnail =
        this._sanitizer.sanitize(
          SecurityContext.URL, this._embed.imgUrl(
            this.artworks[index].type === MediaTypeEnum.IMAGE ?
              this.artworks[index].path :
              this.artworks[index].thumbnail.replace(/['"]+/g, ''),

            'md'
          )
        );

      this.imageData.push(thumbnail);

    }
  }



  onImageLoadState(event: StateChange) {
    switch (event.reason) {
      case 'setup':
        // The lib has been instantiated but we have not done anything yet.
        break;
      case 'observer-emit':
        // The image observer (intersection/scroll/custom observer) has emit a value so we
        // should check if the image is in the viewport.
        // `event.data` is the event in this case.
        break;
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        break;
      case 'mount-image':
        // The image has been loaded successfully so lets put it into the DOM
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the
        this.onImageLoaded();
        break;
      case 'loading-failed':
        // The image could not be loaded for some reason.
        // `event.data` is the error in this case
        this.onImageError();
        break;
      case 'finally':
        // The last event before cleaning up
        break;
    }

    // set image to no-image img
    // $event.target.src = '/assets/image/no-image-found.jpg';
  }

  onImageError() {
    this.loadError = true;
    this.isLoading = false;
    // console.log('error occured loading image');
  }
  onImageLoaded() {
    this.loadError = false;
    this.isLoading = !this.firstImageLoad;

    // console.log('cu to class', this.imgLoad.nativeElement.currentSrc, this.imageToShowOnError);


    if (this.firstImageLoad && this.imgLoad.nativeElement.currentSrc.endsWith(this.defaultImage)) {
      this.onImageError();
    }

    this.firstImageLoad = true;

  }

  onReloadImage($event) {
    $event.stopPropagation();
    this.processSingleImage();

    this.isLoading = true;
    this.loadError = true;

    const newImage = new Image;
    newImage.src = this.imageData[0];
    newImage.onload = () => {
      // console.log(newImage.currentSrc);
      this.imgLoad.nativeElement.src = newImage.currentSrc;
      this.isLoading = false;
    };

    this.imgLoad.nativeElement.onerror = () => this.onImageError();
  }



  onShowSensitiveContent(event:MouseEvent){
    event.stopPropagation();
    this.isSensitive = false;
  }
}
