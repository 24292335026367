<div class="adx-notify" [class.top]="notifyIsTop">
  <div class="slide-up" *ngFor="let alert of data">
    <!-- <div class="color-bar bg-{{ onGetDataColor(alert.status) }}"></div> -->
    <div class="main">
      <galaxy-adaptive-card (click)="onAlertRoute(alert.route)" [showPadding]="false" [reverseTheme]="reverseConstrast"
        [shape]="'rounded'">
        <div class="activity">
          <!-- <div class="bg-color bg-{{ onGetDataColor(alert.status) }}"></div> -->
          <div class="icon">
            <button *ngIf="alert.type === 0" style="position: absolute; left:0; top:0;"
              class="adx-btn adx-round adx-flat adx-icon btn-{{ onGetDataColor(alert.status) }}">
              <i class="axl ax-{{
                onGetDataIcon(alert.status)
              }}"></i>
            </button>
            <!-- <i *ngIf="alert.type === 0" class="color-{{ onGetDataColor(alert.status) }} axr ax-{{
                onGetDataIcon(alert.status)
              }}"></i> -->

            <div *ngIf="alert.type !== 0" class="adx-img " [class.is-round]="alert.type === 1">
              <img [src]="onSanitizeImage(alert.img)" alt="" />
            </div>
            <div *ngIf="alert.type !== 0" class="adx-img-cover bg-special">

            </div>
          </div>
          <div class="message">{{ alert.message }}</div>
          <div class="action" (click)="onCloseAlert(alert, $event)">
            <button class="adx-btn adx-round adx-icon btn-grey shine-pink" style="transform: scale(.6);">
              <i class="axr ax-clear-all"></i>
            </button>
          </div>
        </div>
      </galaxy-adaptive-card>
    </div>
  </div>
</div>
