import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppSettingService, BusinessEntity, CustomerService, CustomerServiceRequest, CustomerServiceStatusEnum, ErrorHandlerService, IBusinessEntity, ICustomerService, ICustomerServiceRequest, NotifyService, PaymentTypeEnum, StatusEnum, SuiteApiService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-request-view',
  templateUrl: './customer-request-view.component.html',
  styleUrls: ['./customer-request-view.component.scss']
})
export class CustomerRequestViewComponent implements OnInit, OnDestroy {

  @Input() appId: number;
  @Input() customerServiceId: number;

  data: ICustomerService = new CustomerService;
  isLoading: boolean = false;
  person: IBusinessEntity = new BusinessEntity;
  avatar: SafeResourceUrl | string = '';

  theme: string = '';
themeUpdated$!: Subscription;

  currency: string;

  selectedCustomerServiceRequest: ICustomerServiceRequest = new CustomerServiceRequest;
  showRequestModal = false;
  isUpdatingRequest = false;


  customerCareStatus: typeof CustomerServiceStatusEnum = CustomerServiceStatusEnum;
  paymentPlanEnum: typeof PaymentTypeEnum = PaymentTypeEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.currency = this._suiteApi.getActiveBusiness().currency;

    // check params to make sure it isnt an edit

    if (this.customerServiceId > 0) {
      this.data = new CustomerService;
      this.getData(this.customerServiceId);

    } else {
      this.onCloseDetail();
    }

  }

  getData(profileId: number): any {
    // get the id of the person

    this.isLoading = true;

    this._suiteApi
      .setParams(null, profileId.toString())
      .get(CustomerService)
      .subscribe(
        {
          next: (res) => {

            // console.log('responds', res)
            this._suiteApi.DataMap({ from: res.data, to: this.data });
            this.person = res.data.businessEntity;
            // this.avatar = this.person.entity.image.trim() !== '' ? this._embed.imgUrl(this.person.entity.image, 'md') : '';

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }


  onEditRequestStatus(request: ICustomerServiceRequest) {
    this.selectedCustomerServiceRequest = request;


    if (this.selectedCustomerServiceRequest.attributes) {
      const attr = [];
      request.businessBranchService.businessService.attributes?.forEach(x => {
        attr.push({
          label: x,
          value: ''
        });
      })
      this.selectedCustomerServiceRequest.attributes = attr;

    } {
      const attr = [];
      request.businessBranchService.businessService.attributes?.forEach(x => {

        let value = ''
        request.attributes?.forEach(element => {
          if (element.label === x) {
            value = element.value;
          }
        });

        attr.push({
          label: x,
          value: value
        });
      });

      request.attributes = attr;
    }



    this.showRequestModal = true;
  }

  onUpdateCustomerServiceRequest() {

    this.isUpdatingRequest = true;
    this._suiteApi.put(this.selectedCustomerServiceRequest).subscribe(res => {
      if (res.success) {

        this._notify.success(res.message);
        this.showRequestModal = false;

        this.selectedCustomerServiceRequest = new CustomerServiceRequest;

        switch (this.selectedCustomerServiceRequest.status) {
          case 1:
            this.selectedCustomerServiceRequest.status = StatusEnum.UNPUBLISHED;// 'Pending';
            break;
          case 2:
            this.selectedCustomerServiceRequest.status = StatusEnum.PUBLISHED;// 'Completed';
            break;
          case 3:

            this.selectedCustomerServiceRequest.status = StatusEnum.TRASHED;// 'Cancelled';
            break;
          default:
            break;
        }


        // already checked if service is all done or canceled
        this.data.status = res['customerServiceStatus'];

      } else {
        this._notify.error(res.message)
      }

      this.isUpdatingRequest = false;

    })
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
