import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'galaxy-app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss']
})
export class AppNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
