<div class="adx-mini-post" [class.bg]='showBg' [class.dark]='theme ==="dark"'>
  <div class="post-art bg-black" [lazyLoad]="art" *ngIf="data.art"></div>
  <div class="post-detail">
    <div class="name">
      {{ data.person.name }} <span>&#64;{{ data.person.username }}</span>
    </div>
    <div class="content">
      <quill-view-html class="no-padding" [content]="data.title || data.description | maxLength: 80">
      </quill-view-html>
    </div>
    <br>

    <small>
      {{data.created * 1000 | amTimeAgo}} - {{data.created * 1000 | amLocal}}
    </small>
  </div>
</div>
