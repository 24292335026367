import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmbedService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-media',
  templateUrl: './adx-media.component.html',
  styleUrls: ['./adx-media.component.scss']
})
export class AdxMediaComponent implements OnInit {

  @Input() path: string = '';
  @Input() type: number = 0;

  image: SafeResourceUrl = '';
  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    if (this.type === 45) {
      this.image = this._embed.imgUrl(this.path, 'md');
    } else {
      // this.media = this._embed.
    }
  }

}
