<div class="adx-divider">Human Resource Summary</div>
<div class="hr-grid">
  <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFlat]="true">
    <div class="adx-divider">
      <span><i class="axl ax-bell"></i> Lastest Memos</span>
      <div class="space"></div>
      <div class="adx-input adx-round">
        <input type="text" placeholder="Search for Memo..." [(ngModel)]="data.memo.searchValue"
          (keyup.enter)="onReloadMemoData()">
      </div>
    </div>
    <div class="adx-list adx-ellipse {{theme}}" [class.is-loading]="data.memo.isLoading">

      <div class="adx-avatar adx-sm adx-flat adx-button" *ngFor="let item of data.memo.data">
        <div class="adx-img adx-icon-letter bg-black">
          <i class="axl ax-notes"></i>
        </div>
        <h2>{{item.title}}</h2>
        <p>
          <small class="adx-highlight bg-dark" style="color: black;" [ngClass]="{
            'bg-dark': item.priority === 1,
            'bg-tgreen': item.priority === 2,
            'bg-yellow': item.priority === 3,
            'bg-pink': item.priority === 4
          }">
            {{ item.priority | enumKey:priorityEnum }} Priority
          </small>

        </p>

        <button class="adx-btn adx-round btn-default shine-pink" (click)="onViewMemo(item)">
          Read
        </button>
      </div>


      <!-- data ends -->
      <app-no-data *ngIf="!data.memo.isLoading && data.memo.data.length === 0" [label]="'Memo'"></app-no-data>

    </div>
  </galaxy-adaptive-card>
  <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [isFlat]="true">
    <div class="adx-divider">
      <span><i class="axl ax-users"></i> Team Mates</span>
      <div class="space"></div>
      <div class="adx-input adx-round">
        <input type="text" placeholder="Search for by name..." [(ngModel)]="data.team.searchValue"
          (keyup.enter)="onReloadTeamData()">
      </div>
    </div>

    <div class="adx-list adx-ellipse  {{theme}}" [class.is-loading]="data.team.isLoading">
      <div class="adx-avatar adx-sm adx-flat" *ngFor="let item of data.team.data">
        <div class="adx-img bg-smoke">
          <img [src]="item.image | sanitizeImage">
        </div>
        <h2>{{item.name}}</h2>
        <p>{{item.personId}}</p>
      </div>



      <!-- data ends -->
      <app-no-data *ngIf="!data.team.isLoading && data.team.data.length === 0" [label]="'Team mates'"></app-no-data>

    </div>
  </galaxy-adaptive-card>
</div>

<galaxy-modal [showModal]="showModal" [showPadding]="false" (onCloseModalClicked)="showModal = false">
  <div class="text-center">
    <h2 class="title text-normal">{{selectedMemo.title}}</h2>
    <span class="adx-highlight bg-dark" style="color:black" [ngClass]="{
      'bg-dark': selectedMemo.priority === 1,
      'bg-tgreen': selectedMemo.priority === 2,
      'bg-yellow': selectedMemo.priority === 3,
      'bg-pink': selectedMemo.priority === 4
    }">
      {{ selectedMemo.priority | enumKey:priorityEnum }} Priority
    </span>
  </div>
  <br>
  <div class="text-size16 padding-sm"
    style=" padding-top:1.6rem; opacity: .8; border-radius: 16px; background-color: rgba(0, 0, 0, 0.1); min-height: 300px; max-height: 500px; overflow-y: auto;">
    {{selectedMemo.description}}
  </div>

  <div class="padding">
    <small>
      Date: {{selectedMemo.created | date}}
      <br>
      Author: {{selectedMemo.createdBy?.entity?.name}}
    </small>
  </div>

  <div>
    <button (click)="showModal = false" class="adx-btn adx-round btn-default shine-pink">
      Close
    </button>
  </div>
</galaxy-modal>
