
export enum AcademicYearSegmentEnum {
  FULL_YEAR = 1,
  FIRST_HALF = 2,
  SECOND_HALF = 3,
  ONE_THIRD = 4,
  TWO_THIRD = 5,
  THREE_THIRD = 6,
  FIRST_QUATER = 7,
  SECOND_QUATER = 8,
  THIR_QUATER = 9,
  FOURTH_QUATER = 10,
}
