<div class="adx-card adx-img bg-black">
  <img [src]="art" class="img-responsive" alt="" />
</div>
<div class="adx-list">
  <div class="adx-item">
    {{ entity.title }}
    <br />
    <p>{{ entity.person.name }}</p>
  </div>
</div>
