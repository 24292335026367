import { Component, OnInit } from '@angular/core';
import { AppSettingService, AuthService, IBusiness, IClaim, Claim, SuiteApiService, IRoleGrant, Business, Subscription, BusinessSubscription, IBusinessSubscription, ErrorHandlerService } from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'galaxy-app-role-claims',
  templateUrl: './app-role-claims.component.html',
  styleUrls: ['./app-role-claims.component.scss']
})
export class AppRoleClaimsComponent implements OnInit {

  claims: IRoleGrant[] = [];
  business: IBusiness = new Business;

  roles: any[] = [];
  selectedAppClaimRoles: any[] = [];

  data: IClaim[] = [];

  isLoading: boolean = false;

  showAccessModal = false;

  businessSubscription: IBusinessSubscription = new BusinessSubscription;



  page: number = 1;
  pageSize: number = 20;
  totalData: number = 10;
  selectedAppClaim: any;
  isFirstData = false;
  constructor(
    private _storageMap: StorageMap,
    private _authService: AuthService,
    private _suiteApi: SuiteApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.claims = this._authService.currentUser.claims;

    this.business = this._suiteApi.getActiveBusiness();

    this._suiteApi.activeBusiness.subscribe(res => {
      this.business.id = res.id;
      this._getStorageData();
      this.onReloadData();
    });

    if (this.business.id !== 0) {
      if (this.roles.length === 0 && this.data.length === 0) {
        this._getStorageData();
        this.onReloadData();
      }
    }


    this.businessSubscription = this._suiteApi.getActiveBusinessSubscription();
  }


  private _resetData() {
    this.data = [];
    this.isFirstData = true;

  }

  private _getStorageData() {
    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_bAps';
    const key = this.business.id + '.p' + this._authService.currentUser?.unique;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClaim[]>) => {
        if (res) {
          this.data = res.get(key) ?? [];
        }
      });

  }

  private _storeStorageData() {

    const domain = '_bAps';
    const key = this.business.id + '.p' + this._authService.currentUser.unique;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClaim[]>) => {
        let dataMap = new Map<string, IClaim[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(key, this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }





  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getBusinessAppClaims();

  }


  getBusinessAppClaims(): any {
    // get the id of the person
    this.isLoading = true;

    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        id: this.business.id

      })
      .getPageOf(Claim)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;
            this.data = [];
            const appClaims = res.data as Claim[];
            this._suiteApi.setBusinessAppClaims(appClaims); // set business app claims
            // get my roles --> filter from BusinessAppClaims to get my appClaims
            for (let index = 0; index < appClaims.length; index++) {
              const element = appClaims[index];
              if (this.isAppGranted(element.app.id)) {
                // this.data = appClaims.filter((value) => value !== element);
                this.data.push(element);
                // console.log('not part of grants --< ', element);
              }
            }

            if (this.isFirstData) {
              this._storeStorageData();
              this.isFirstData = false;
            }


            // this.data = <IClaim[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;



            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  isAppGranted(appId: number): boolean {
    let hasAccess = false;
    const grants: any[] = [];

    for (let index = 0; index < this.claims.length; index++) {
      const element = this.claims[index];
      // console.log('comparing', element, appId)
      if (element.appRole.app.id === appId) {
        hasAccess = true;
        grants.push({
          'id': element.id,
          'roleName': element.appRole.name,
          'appRole': element.appRole,
          'businessBranchDepartment': element.businessBranchDepartment,
        })
      }
    }
    if (grants.length > 0) {
      // console.log('grants --> ', grants)
      this.roles[appId] = grants;
    }
    // console.log(this.roles);
    return hasAccess;
  }

  getRoles(appId: number) {

    // console.log('rols get the ', this.roles);
    // setTimeout(() => {
    //   return this.roles[appId] ? this.roles[appId] : [];
    // }, 1000);
    return this.roles[appId] ? this.roles[appId] : [];


  }

  onAccessLevelSelected() {
    // set current level

    // close modal
    this.showAccessModal = false;
    // route to app

  }

  onShowAccessModal(item: any) {
    // console.log(item);
    this.selectedAppClaim = item;
    this.selectedAppClaimRoles = this.roles[item.app.id]
    // console.log(this.roles)
    this.showAccessModal = true;
  }

  onCloseAccessModal($event) {
    this.showAccessModal = false;
  }


}
