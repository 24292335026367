<div class="adx-card adx-flat is-loading" *ngIf="isLoading">
  <p></p>
</div>
<div #topMark></div>
<div class="artwork-grid" *ngIf="!isLoading" [class.comic]='entity.isComic'>
  <div class="image-area" [class.comic]='entity.isComic' [class.is-loading]='reloadEntity'>
    <span class="save-btn">
      <button (click)="onShowModal(0)" class="adx-btn btn-tgreen adx-rounder adx-shadow adx-icon">
        <!-- <i class="fa fa-cabinet-filing"></i> -->
        <i class="axr ax-bookmark"></i>
      </button>
    </span>
    <!-- artwork -->
    <div *ngIf="!reloadEntity" class="adx-card no-margin no-padding adx-flat adx-round bg-black">
      <galaxy-media-artwork *ngFor="let item of entity.artwork" [artwork]="item" [isSingleImage]='hasSingleArtwork'>
      </galaxy-media-artwork>
    </div>

    <!-- entity art if no artwork found -->
    <img *ngIf="entity.artwork.length === 0" [src]="art" class='img-responsive' alt="">
  </div>
  <div class="detail-area" [class.comic]='entity.isComic'>
    <!-- <div
      class="adx-card adx-flat no-margin padding adx-round"
      [ngClass]="{ 'bg-darker': theme == 'dark', 'bg-smoke': theme == 'light' }"
      style="border-radius: 20px;"
    > -->
    <galaxy-adaptive-card [shape]="'rounded'" [isFull]="true" [isFlat]="true" [showPadding]="false"
      [autoDimBlack]="true">

      <div class="padding-sm">
        <galaxy-person-avatar [data]="entity" [showAnalytics]="showAnalytics"></galaxy-person-avatar>
        <h3 class="title text-normal text-size15" style="font-weight: 500; ">
          {{ entity.isComic? entity.album.title : entity.title }}
        </h3>

        <p class="text-size" (click)='onToggleReadMore()' [ngClass]="{'is-comic': isComic, show:showFullDescription}">
          <quill-view-html [content]="entity.description"></quill-view-html>
          <!-- {{ entity.description }} -->
        </p>
        <galaxy-post-acions *ngIf="!reloadEntity" [data]="entity" [showAnalytics]="showAnalytics" [entityId]="entity.id"
          [entityTypeName]='"artist"' (likeClicked)="onLikeRepostClick($event, 2)"
          (repostClicked)="onLikeRepostClick($event, 4)" (countUpdated)='updateEntityCount($event)'
          (shareClicked)="onShowModal(1)"></galaxy-post-acions>
      </div>

    </galaxy-adaptive-card>

    <!-- comic chaptures -->
    <galaxy-media-album-chapter (chapterClicked)='onChapterSelected($event)' [albumId]='albumId'
      [selectedChapterId]='entity.id' *ngIf="entity.isComic || entity.type === 2">

    </galaxy-media-album-chapter>
    <!-- </div> -->
    <!-- <div>
      <galaxy-adaptive-card [showPadding]="false" class="comments-flex" [shape]="'rounded'" [isFull]="true"
        [isFlat]="true" [autoDimBlack]="true">
        Sponsored
      </galaxy-adaptive-card>
    </div> -->

    <!-- Comment  -->
    <galaxy-adaptive-card class="comments-flex" [showPadding]="false" [shape]="'rounded'" [isFull]="true"
      [isHeightFull]="true" [isFlat]="true" [autoDimBlack]="true">
      <div class="padding-sm">
        <small class="adx-divider">Comments</small>
        <galaxy-comment-system *ngIf="!(isLoading && reloadEntity)" [entityId]="entity.id" [entityTypeId]="entityTypeId"
          (commentAdded)="increaseCommentCount($event)" class="comment-system-flex"></galaxy-comment-system>
      </div>
    </galaxy-adaptive-card>
  </div>
</div>

<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showModal" [showPadding]="false">
  <div *ngIf="modalNumber === 1; then shareModal; else collectionModal"></div>
</galaxy-modal>

<ng-template #shareModal>
  <galaxy-media-share [title]="entity.title" [link]="entityPageUrl"></galaxy-media-share>
</ng-template>

<ng-template #collectionModal>
  <galaxy-media-collection-select *ngIf="showModal" [collectionType]="84" [entity]="entity" [entityType]="'photo'"
    [personId]="personId">
    Save To Photo Collection</galaxy-media-collection-select>
</ng-template>
