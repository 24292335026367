import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-category-container',
  templateUrl: './category-container.component.html',
  styleUrls: ['./category-container.component.scss']
})
export class CategoryContainerComponent implements OnInit, OnDestroy {

  @Input() title: string = 'Category';
  @Input() description: string = 'Short note on category';
  @Input() icon: string = 'star';
  @Input() buttonName: string = 'Show More >>';
  @Input() buttonRoute: string = '';
  @Input() styles: string = '';

  theme: string = 'light';themeUpdated$!: Subscription;
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res)
  }

  onMoreClicked() {

  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
