import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService, AuthService, SuiteApiService, BusinessProduct, NotifyService, ErrorHandlerService, SuiteProductTypeEnum, ProductCategoryEnum, SuiteProductCategoryEnum } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-productx',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];
  @Input() title: string = 'List Of Products'
  @Input() baseUrl: string = '/u/persons/';
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Product Detail';
  detailTitle: string = 'Product Detail';
  isLoading: boolean = false;

  data: BusinessProduct[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;

  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  theme: string = '';

  currency: string;

  sumTotal = {
    stocks: 0,
    units: 0,
    packs: 0,
    cost: 0,
    retail: 0
  }

  productTypeEnum: typeof SuiteProductTypeEnum = SuiteProductTypeEnum;
  productCategoryEnum: typeof SuiteProductCategoryEnum = SuiteProductCategoryEnum;

  dataUpdated$!: Subscription;
  themeUpdated$!: Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    this.dataUpdated$ = this._suiteApi.dataChanged$.subscribe(res => {
      if (res.type instanceof BusinessProduct) {
        this.onReloadData();
      }
    })


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);

    this.currency = this._suiteApi.getActiveBusiness().currency;

    if (this.businessId !== 0) {
      this.onReloadData();
    }
    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });
  }

  isSubscriptionActive(): boolean {
    return true;
    if (this._suiteApi.verifyBusinessSubscription().limitReached) {
      this._notify.error('Your Subscription has expired and/or app/client limit reached');

      return false;
    }

    return true
  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.businessId,
        gid: this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id,
        orderBy: [this.orderBy]

      })
      .getPageOf(BusinessProduct)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <BusinessProduct[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.calSum()

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }

  calSum() {
    this.sumTotal.stocks = 0;
    this.sumTotal.units = 0;
    this.sumTotal.packs = 0;
    this.sumTotal.cost = 0;
    this.sumTotal.retail = 0;

    this.data.forEach(x => {
      const totalUnits = x.stocks.units + (x.stocks.packs * x.product.unitsInPack);

      this.sumTotal.packs += x.stocks.packs;
      this.sumTotal.units += x.stocks.units;
      this.sumTotal.stocks += totalUnits;

      this.sumTotal.cost += (x.stocks.packs * x.prices[0].packCostPrice) + (x.stocks.units * x.prices[0].unitCostPrice);
      this.sumTotal.retail += (x.stocks.packs * x.prices[0].packRetailPrice) + (x.stocks.units * x.prices[0].unitRetailPrice);


    })
  }


  onDetailRoute(link: string, title: string = 'View Detail') {
    this.showDetail = true;
    this.detailTitle = title;
    // console.log(link);
    this._router.navigateByUrl(this.baseUrl + link);

  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }

  ngOnDestroy(): void {
    this.dataUpdated$.unsubscribe();
    this.themeUpdated$.unsubscribe();
  }

}
