import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adx-blog-side',
  templateUrl: './ad-blog-side.component.html',
  styleUrls: ['./ad-blog-side.component.scss']
})
export class AdBlogSideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
