import { Injectable, EventEmitter } from '@angular/core';
import { Person, IActivity, IConversation, ICommunity, Product, ICommunityChannel, IEntity, Community } from '../../model';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NotifyService } from '../notify.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class OriDbService {
  // use to store mesages and downloaded media in db
  // pending messages, comments and reactions
  // but store cart in localStorage

  coldStartDone: boolean = false;

  lastCheckedTime: string = '';
  // Social
  relationship: Person[] = [];
  relationshipIds: string = '';
  feedItems: IActivity[] = [];
  communities: ICommunity[] = [];
  communitiesIds: string = '';
  conversations: IConversation[] = [];
  private _personId: number = 0;
  notifications = new EventEmitter();

  activeCommunity = new EventEmitter<ICommunity>();
  defaultCommunity: ICommunity = new Community;
  activeCommunityChannel = new EventEmitter<ICommunityChannel>();
  defaultCommunityChannel: ICommunityChannel;


  // Store
  recentViewedProducts: Product[] = [];
  defaultCart: Product[] = [];
  cart = new EventEmitter<Product[]>();

  //feedInterval
  feedCheckerInterval?: any;
  private _pingTimer = 0; // 3sec, 12sec, 20sec, 26sec, 30sec ...


  constructor(
    private _authService: AuthService,
    private _storageMap: StorageMap,
    private _notify: NotifyService,
  ) {

    if (this._authService.loggedIn) {
      this._personId = this._authService.currentUser.gid.social.u;
    }


    this._authService.loggedOut.subscribe(res => {
      if (!res) {
        this._personId = this._authService.currentUser.gid.social.u;
      } else {
        this.communities = [];
      }
      // this.coldStartDone = !res;
    });

  }


  getPingTimer() {
    // console.log('interval timmer  is --> ', this._pingTimer);
    switch (this._pingTimer) {
      case -1:
        this._pingTimer = 0;
        break;
      case 0:
        this._pingTimer = 3000;
        break;

      case 3000:
        this._pingTimer = 12000;
        break;
      case 12000:
        this._pingTimer = 19000;
        break;
      case 19000:
        this._pingTimer = 26000;
        break;

      default:
        this._pingTimer = 30000;
        break;
    }

    return this._pingTimer;
  }

  continuePingTimer() {
    this._pingTimer = 3000;
  }

  beginPingTimer() {
    this._pingTimer = 0;
  }
  resetPingTimer() {
    clearTimeout(this.feedCheckerInterval);
    this._pingTimer = -1;
  }

  setRelationships(res: Person[]) {
    this.relationship = res;
    // set relationshipIds
    let relationshipIds: string = this._personId.toString();
    res.forEach(element => {
      relationshipIds += ',' + element.id.toString();
    });

    this.relationshipIds = relationshipIds.trim();
  }
  setCommunities(res: ICommunity[]) {
    this.communities = res;
    // set relationshipIds
    let communitiesIds: string = '1';
    res.forEach(element => {
      communitiesIds += ',' + element.id.toString();
    });

    this.communitiesIds = communitiesIds.trim();
  }



  setFeedItems(items: IActivity[]) {
    this.feedItems = items;
  }


  updateFeedItems(items: IActivity[], page: number) {
    // update feedItems
    this.feedItems = items;
    // updat
    this._storageMap.set('feed.items', this.feedItems).subscribe(() => {
      // update count
      this._storageMap.set('feed.visibleCount', this.feedItems.length).subscribe(() => {

      });
    });

  }

  updateFeedReactions(item: IEntity, entityTypeId: number) {
    const postIndex = this.feedItems.findIndex((post => post.entityType === entityTypeId && post.subject.id == item.id));

    if (postIndex !== -1) {

      this.feedItems[postIndex].subject.likeCount = item.likeCount;
      this.feedItems[postIndex].subject.commentCount = item.commentCount;
      this.feedItems[postIndex].subject.repostCount = item.repostCount;
      this.feedItems[postIndex].subject.hit = item.hit;
      this.feedItems[postIndex].subject.engagement = item.engagement;
      this.feedItems[postIndex].subject.liked = item.liked;
      this.feedItems[postIndex].subject.reposted = item.reposted;

      // updat
      this._storageMap.set('feed.items', this.feedItems).subscribe(() => {
        // update count
        this._storageMap.set('feed.visibleCount', this.feedItems.length).subscribe(() => {

        });
      });
    }



  }


  setCommunity(res: ICommunity[]) {
    this.communities = res;
    this._storageMap.set('community.items', res).subscribe(() => { })
  }


  addToCart(product: Product): boolean {
    let productExists = false;

    for (let index = 0; index < this.defaultCart.length; index++) {
      const element = this.defaultCart[index];
      if (product.id === element.id) {
        productExists = true;
        break;
      }
    }

    if (!productExists) {
      this.defaultCart.unshift(product);
      this._storageMap.set('store.cart', this.defaultCart).subscribe(res => {
        this.cart.emit(this.defaultCart);
      });
    }

    return !productExists;

  }

  getCart() {
    this._storageMap.get('store.cart').subscribe(res => {
      if (res) {
        this.defaultCart = <Product[]>res;
        this.cart.emit(<Product[]>res);
      }
    });

  }


  updateCart(products: Product[]) {
    this._storageMap.set('store.cart', products).subscribe(res => {
      if (res) {
        this.defaultCart = <Product[]>res;
        this.cart.emit(<Product[]>res);
      }
    });

  }


  getRecentViewedProduct() {
    this._storageMap.get('store.recent').subscribe(res => {
      if (res) {
        this.recentViewedProducts = <Product[]>res;
      }
    })
  }

  addToRecentViewedProduct(product: Product) {
    // recent viewed products should not be more than 10
    if (this.recentViewedProducts.length >= 10) {
      this.recentViewedProducts = this.recentViewedProducts.slice(0, 8);
    }

    // if product already exists in this.recentViewedProducts, push to top

    let productExists = false;

    for (let index = 0; index < this.recentViewedProducts.length; index++) {
      const element = this.recentViewedProducts[index];
      if (+element.id === +product.id) {
        this.recentViewedProducts.splice(index, 1);
        this.recentViewedProducts.unshift(element);
        productExists = true;
        break;
      }

    }

    // if products is not is this.recentViewedProducts, then push it to the first index
    if (!productExists) {
      this.recentViewedProducts.unshift(product);
    }

    // store to StorageMap
    this._storageMap.set('store.recent', this.recentViewedProducts).subscribe(res => {

    });

  }


  setActiveCommunity(data: ICommunity) {
    this.defaultCommunity = data;
    this.defaultCommunityChannel = data.activeChannel;
    this.activeCommunity.emit(data);
    // console.log('active community changed!!!!');
  }

  setActiveCommunityChannel(channel: ICommunityChannel) {
    this.defaultCommunityChannel = channel;
    this.defaultCommunity.activeChannel = channel;

    this.activeCommunityChannel.emit(channel);

    // console.log('active channel changed!!!!');

  }

}
