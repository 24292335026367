import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArtistApiService, ErrorHandlerService, IEntity, Project } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-media-album-chapter',
  templateUrl: './media-album-chapter.component.html',
  styleUrls: ['./media-album-chapter.component.scss']
})
export class MediaAlbumChapterComponent implements OnInit {

  @Input() albumId: number = 0;
  @Input() selectedChapterId: number = 0;
  @Output() chapterClicked = new EventEmitter();

  data: IEntity[] = [];
  isLoading: boolean = false;
  page: number = 1;
  pageSize: number = 50;
  totalData: number = 50;

  searchValue: string = '';
  firstChapterId: number = 0;
  lastChapterId: number = 0;
  constructor(
    private _artistApi: ArtistApiService,
    private _errorHandlerService: ErrorHandlerService


  ) { }

  ngOnInit() {
    if (this.albumId !== 0) {
      this.isLoading = true;
      this.getData();
    }
  }


  getData() {

    this._artistApi
      .setParams({
        page: 1,
        pageSize: 50,
        searchValue: this.searchValue,
        searchFields: ['chapter'],
        categoryType: this.albumId
      },
        'stand/open/')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            // this.isLoading = false;

            this.data = <IEntity[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            // order is DESC
            this.firstChapterId = this.data[(this.data.length - 1)].id;
            this.lastChapterId = this.data[0].id;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onReload() {
    this.isLoading = true;
    this.getData();
  }


  onChaptureSelected(chapter: IEntity) {
    this.selectedChapterId = chapter.id;

    this.chapterClicked.emit(chapter.id)
  }

  previousChapter() {

    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id === this.selectedChapterId) {
        this.onChaptureSelected(this.data[index + 1])
        break;
      }

    }

  }
  nextChapter() {

    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].id === this.selectedChapterId) {
        this.onChaptureSelected(this.data[index - 1])
        break;
      }

    }


  }
}
