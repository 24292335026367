import { Injectable, EventEmitter } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from '../auth.service';
import { NotifyService } from '../notify.service';
import { MessengerSocketService } from './messenger-socket.service';
import { IMessage, IConversation, Conversation } from '../../model';
import { OriDbService } from '../db/ori-db.service';
import { EntitySocketService } from '@galaxy/entity-api';


@Injectable({
  providedIn: 'root'
})
export class OriInitSocketService {

  private isLoggedIn: boolean = false;
  conversationUpdated = new EventEmitter<IConversation[]>();
  constructor(
    private _authService: AuthService,
    private _storageMap: StorageMap,
    private _notify: NotifyService,
    private _messegeSocket: MessengerSocketService,
    private _entitySocket: EntitySocketService,
    private _oriDB: OriDbService
  ) {

    // // check for authentication
    // if (this._authService.loggedIn) {
    //   this.isLoggedIn = true;
    //   this.initiateMessageSocket();
    // } else {
    //   this._authService.initLocalToken().subscribe(res => {
    //     if (res) {
    //       this.initiateMessageSocket();
    //     }
    //   })
    // }

  }

  socketMessenger() {
    this.initiateMessageSocket();
    // this._entitySocket.connect();

    // disconnect from messegeSocket when user loggs out
    this._authService.loggedOut.subscribe(res => {
      // console.log('loggedOut triggered', res);
      if (res) {
        this._messegeSocket.disconnect();
        this._entitySocket.disconnect();
      } else {
        // this._messegeSocket.connect();
        this.initiateMessageSocket();

        // this._entitySocket.connect();
      }
    })
  }

  initiateMessageSocket() {
    //connect socket
    // this._messegeSocket.connect();

    if (!this._authService.loggedIn) {
      return;
    }

    // socket my local conversation
    this.socketConversations();

    // for checking if im online
    this._messegeSocket.listen('online').subscribe(res => {
      const msg = <any>res;
      // this._notify.info(msg.text);
    });


    this.newConversationSocket();
  }


  socketConversations() {

    if (this._oriDB.conversations) {

      const conversationIds = [];
      this._oriDB.conversations.forEach(element => conversationIds.push(element.id));
      // join conversation room
      this._messegeSocket.emit('join',
        {
          username: this._authService.currentUser.username,
          conversationIds: conversationIds
        }
      );
      // now listen
      this.listToMessengesSent()

    }

  }


  private newConversationSocket() {
    // for new conversation alert
    this._messegeSocket.listen('newConRoom').subscribe(res => {
      const msg = <any>res;


      if (msg.userId === this._authService.currentUser.gid.social.u) {
        // get new conversation
        let newConversation: IConversation = new Conversation;
        newConversation = <IConversation>msg.conversation;

        // join that conversation
        this._messegeSocket.emit('newConversation', { userId: 0, conversationId: msg.conversationId, conversation: null });


        // add to storage
        this._storageMap.get('messenger.conversations')
          .subscribe(res => {
            let conversation: IConversation[] = [];
            if (res) {
              conversation = <IConversation[]>res;
            }
            conversation.unshift(newConversation);
            this._storageMap.set('messenger.conversations', conversation).subscribe(() => { })
            // emit
            this._oriDB.conversations = conversation;
            this.conversationUpdated.emit(conversation);

          });


        this._notify.info('You have a new messege!');
      }
    });
  }


  private listToMessengesSent() {
    // register socket
    this._messegeSocket.listen('sendMessage').subscribe(resx => {

      // console.log('global msg in -- ', resx);

      const newMessage = <IMessage>resx;
      this._notify.info('New message!  @' + newMessage.person.username + ' : ' + newMessage.description);

      // update local conversation
      this._storageMap.get('messenger.conversations')
        .subscribe(res => {
          if (res) {

            // console.log('sorting conversation');

            const conversationData = <IConversation[]>res;
            for (let index = 0; index < conversationData.length; index++) {
              const element = conversationData[index];
              if (+element.id === +newMessage.conversation.id) {
                element.lastMessage = newMessage.conversation.lastMessage;
                element.lastActive = newMessage.conversation.lastActive;

                conversationData.splice(index, 1);
                conversationData.unshift(element);
                // break;
              }

            }

            this._storageMap.set('messenger.conversations', conversationData)
              .subscribe(() => {
                // console.log('messeage conversation stored!', conversationData);
                // emit
                this._oriDB.conversations = conversationData;
                this.conversationUpdated.emit(conversationData);
              });
          }
        });
    });
  }





}
