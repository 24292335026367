<ui-menu
  [isMini]="closed"
  [theme]="theme"
  [appName]="app"
  [menu]="menu"
  [aRouterLink]="aRouterLink"
></ui-menu>

<div
  class="air-right"
  [class.adx-closed]="closed"
  [ngClass]="{
    'bg-darker': theme == 'dark',
    'bg-white': theme == 'light'
  }"
>
  <ui-header
    [theme]="theme"
    [appName]="app"
    [aRouterLink]="aRouterLink"
    [showSearch]="showSearch"
  ></ui-header>
  <div class="adx-workspace" #contentArea>
    <ng-content></ng-content>
  </div>
  <div class="clearfix"></div>
  <ui-footer [app]="app"></ui-footer>
</div>
<galaxy-notify></galaxy-notify>

