import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { IEntity, EmbedService, Entity, Community } from '@galaxy/entity-api';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-person-avatar',
  templateUrl: './person-avatar.component.html',
  styleUrls: ['./person-avatar.component.scss']
})
export class PersonAvatarComponent implements OnInit {

  @Input() data: IEntity = new Entity;

  @Input() community: Community = new Community;
  @Input() showAnalytics: boolean = false;
  @Input() showLocation: boolean = false;

  isCommunity: boolean = false;
  personImage: string = '';
  avatar: string = '';

  routeLink: string = '';

  defaultImage = '/assets/images/default-image.jpg';
  constructor(
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    // this.showLocation = this.data.createdLocation !== null;
    this.isCommunity = this.community?.id > 0;
    if (this.isCommunity) {
      this.avatar = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.community.image, 'sm'));
      this.personImage = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.person.image, 'sm'));
      this.routeLink = '/community/c/' + this.community.name + '/' + this.community.id + '/';
    } else {
      this.avatar = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(this.data.person.image, 'sm'));
      this.routeLink = '/profile/' + this.data.person.username;
    }
  }

}
