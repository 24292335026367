import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService, ArtistApiService, AuthService, Entity, ErrorHandlerService, IEntity, IGroup, NotifyService, Project } from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-toon-section',
  templateUrl: './media-toon-section.component.html',
  styleUrls: ['./media-toon-section.component.scss']
})
export class MediaToonSectionComponent implements OnInit, OnDestroy {


  @Input() title: string = 'Latest Update';
  @Input() showTitle: boolean = true;

  @Input() showDetail: boolean = true;
  @Input() isMini: boolean = false;
  @Input() isLoading: boolean = true;

  @Input() showBookmark: boolean = true;
  @Input() data: IEntity[] = [];

  @Input() sortBy: string = 'latest';
  @Input() searchValue: string = '';
  @Input() personId: number = 0;
  @Input() dataLimit: number = 16;

  pageUrl: string = 'https://aiira.app/artwork/?id=';
  entityPageUrl: string = '';



  showModal: boolean = false;
  showImageModal: boolean = false;
  modalNumber: number = 1;

  pageSize: number = 50;
  page: number = 1;
  totalData: number = 0;

  entity: IEntity = new Entity;
  currentMenu: string = 'discover'
  projectId: number = 30;
  prevProject?: IEntity = null;
  prevProjectIndex: number = 0;
  nextProject?: IEntity = null;
  nextProjectIndex: number = 0;

  isLoggedIn: boolean = false;
  theme: string = 'dark';
  themeUpdated$!: Subscription;

  orderByArr = {
    newEntity: 'featured, likeCount, commentCount, id',
    hotEntity: 'featured, hit, id',
    topEntity: 'hit, id, created'

  };
  @Input() orderBy: string = 'id';
  @Input() mediumId: number = 0;
  @Input() subjectId: number = 0;
  subjectMatter: IGroup[] = [];


  viewerId: number = 0;
  menu: { name: string; icon?: string; route?: string }[] = [];
  popularData: Project[] = [];
  projectSelected: IEntity;

  isFirstData = { all: false, popular: false };

  constructor(
    private _artistApi: ArtistApiService,
    private _authService: AuthService,
    private _notify: NotifyService,
    private _appSetting: AppSettingService,
    private _storageMap: StorageMap,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoggedIn = this._authService.loggedIn;
    if (this.isLoggedIn) {
      this.viewerId = this._authService.currentUser.gid.social.u;
    }

    this.pageSize = this.dataLimit;

    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    if (window.innerWidth <= 990) {
      this.dataLimit = 8;
    }


    if (this.data.length === 0) {
      this._getStorageData('all')
      this.onReload();

    } else {
      this.page = this.data.length;
      this.totalData = this.page;
      this.isLoading = false;
    }

    this._getStorageData('popular')
    this.getPopularData();



  }




  private _resetData(index: string) {
    this.data = [];

    if (index === 'all') {

      this.isFirstData.all = true;
    } else {
      this.isFirstData.popular = true;
    }

  }
  private _getStorageData(index: string) {
    this._resetData(index);

    // console.log('personId is ', this.personId)

    const domain = '_arTn';

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        if (res) {
          this.data = res.get(index) ?? [];
        }
      });

  }


  private _storeStorageData(index: string) {

    const domain = '_arTn';
    this._storageMap.get(domain)
      .subscribe((res: Map<string, IEntity[]>) => {
        let dataMap = new Map<string, IEntity[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(index, this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }


  onReload() {
    this.isLoading = true;
    this.pageSize = this.dataLimit;
    this.page = 1;



    this.getData();

  }


  getData(): any {
    this._artistApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        category: this.subjectId.toString(),
        categoryType: this.mediumId,
        searchFields: [this.sortBy],
        searchValue: this.searchValue,
        id: this.personId,
        orderBy: [this.orderBy]
      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            const data = res.data as Project[];
            this.data.push(...data);
            this.page = res.meta.pagination.currentPage;

            if (this.isFirstData.all) {
              this._storeStorageData('all');
              this.isFirstData.all = false;
            }
            // this.pageSize = res.meta.pagination.pageSize;
           this.totalData = res.meta.pagination.totalItems;;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  getPopularData() {

    const popularSizeLimit = (this.dataLimit >= 8) ? 6 : this.dataLimit;

    this._artistApi
      .setParams({
        page: this.page,
        pageSize: popularSizeLimit,
        category: this.subjectId.toString(),
        categoryType: this.mediumId,
        searchFields: ['popular'],
        searchValue: this.searchValue,
        id: this.personId,
        orderBy: [this.orderBy]
      }, 'stand/open')
      .getPageOf(Project)
      .subscribe(
        {
          next: (res) => {
            this.popularData = res.data as Project[];
            // this.data.push(...data);
            // this.page = res.meta.pagination.currentPage;
            if (this.isFirstData.popular) {
              this._storeStorageData('popular');
              this.isFirstData.popular = false;
            }
            // // this.pageSize = res.meta.pagination.pageSize;
            //this.totalData = res.meta.pagination.totalItems;;

            // this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onPaginateData(page: number) {
    this.isLoading = true;
    this.data = [];

    this.page = page;
    this.getData();

  }



  onShowModalClicked(entity: IEntity, type: number) {
    this.entity = entity;
    this.modalNumber = type;
    if (type === 2) {
      // if its collection
      if (this.isLoggedIn) {
        this.showModal = true;
      } else {
        this._notify.error('Please login to continue');
      }

    } else {
      // web share api
      if (type === 1 && navigator.share) {
        navigator.share({
          title: entity.title,
          text: entity.description,
          url: window.location.href
        }).then(() => {
          // console.log('Thanks for sharing!');
        })
          .catch(err => {
            // console.log(`Couldn't share because of`, err.message);
          });
      } else {
        // console.log('web share not supported');
        this.showModal = true;
      }

    }
  }

  onCloseClicked($event) {
    this.showModal = false;
    this.showImageModal = false;
  }
  getEntityLink() {
    return this.pageUrl + this.projectId;
  }

  onImageClicked(entity: IEntity, index: number) {
    // console.log('id @ masonry is ', entity.id)
    // this.showImageModal = false;
    this.projectSelected = entity;
    this.projectId = entity.id;
    const len = this.data.length;
    if (len !== 1) {
      // first get the index of the project id

      this.prevProjectIndex = (index + len - 1) % len;
      this.prevProject = this.data[this.prevProjectIndex];

      this.nextProjectIndex = (index + 1) % len;
      this.nextProject = this.data[this.nextProjectIndex];
      if (index === 0) {
        this.prevProject = null;

      }
      if (index == len - 1) {
        this.nextProject = null;
      }

    }

    // check if there is a prevous & next
    this.showImageModal = true;
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
