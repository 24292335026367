export class NotificationScheduler {
  private intervalId: number | null = null;

  constructor(
    private message: string,
    private interval: number,
    private options?: NotificationOptions
  ) { }

  private showNotification(): void {
    if (Notification.permission === 'granted') {
      new Notification(this.message, this.options);
    } else if (Notification.permission !== 'denied') {
      // Request permission if not already granted or denied
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(this.message, this.options);
        }
      }).catch((error) => {
        console.error('Notification permission request failed:', error);
      });
    }
  }

  public startNotifications(): void {
    if (!this.intervalId) {
      this.intervalId = window.setInterval(() => {
        this.showNotification();
      }, this.interval);
    }
  }

  public stopNotifications(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
