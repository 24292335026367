import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-code-scanner',
  templateUrl: './code-scanner.component.html',
  styleUrls: ['./code-scanner.component.scss']
})
export class CodeScannerComponent implements OnInit {

  showCodeScanner: boolean = false;

  @Output() codeScanned = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  show() {
    this.showCodeScanner = true;
  }

  hide() {
    this.showCodeScanner = false;
  }

  onCodeScannedEvent(code: string) {
    this.codeScanned.emit(code);
    this.hide();
  }

}
