import { Component, OnInit, Input } from '@angular/core';
import { Group, IGroup } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  @Input() data: IGroup[] = [];
  @Input() selectedData: IGroup[] = [];
  constructor() { }

  ngOnInit() {
  }

}
