import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galaxy-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent implements OnInit {
  @Input() size: string = 'sm';
  @Input() theme: string = 'dark';
  @Input() placeholder: string = 'Say something nice...';
  @Input() showRating: boolean = false;
  constructor() { }

  ngOnInit() { }
}
