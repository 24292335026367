<galaxy-adaptive-card [isFlat]="true" [showPadding]="false" [autoDimBlack]='true' [shape]="'rounded'">
  <div class="post-wrapper">
    <div class="media">
      <div class="thumbnail {{ theme }}" [ngClass]="{
          'complex-thubnail': !showThumbnailSimple,
          'simple-thumbnail': showThumbnailSimple
        }" [class.size-images]="isMasonry" [class.even]="isEven">
        <div *ngFor="let item of imageData; index as i" [class]="'th-' + (i + 1)" [defaultImage]="defaultImage"
          [lazyLoad]="item"></div>
        <div *ngFor="let item of placeHolderData" [class]="'th-' + (item + 1)"></div>
        <!-- <div class="two"></div>
        <div class="three"></div>
        <div *ngIf="isMasonry" class="four"></div>
        <div *ngIf="isMasonry" class="five"></div>
        <div *ngIf="isMasonry" class="six"></div> -->
        <!-- <img src="assets/images/galaxy.jpg" alt="" class="img-responsive" /> -->
      </div>
      <div class="p-overlay">
        <button *ngIf="showEditBtn" (click)="onEditClicked($event)" class="adx-btn adx-icon adx-round btn-dark edit-btn">
          <i class="axr ax-edit"></i>
        </button>
      </div>
    </div>
    <div class="p-detail">
      <div class="person">
        <div class="detail">
          <div class="name">
            <span *ngIf="data.status === 1" style="opacity: 0.5">
              <i class="axr ax-eye-slash"></i>
              &nbsp;
            </span>
            {{ data.title }}
          </div>
        </div>
        <div class=" color-tgreen">
          {{ data.entityCount }} &nbsp;
        </div>
      </div>
    </div>
  </div>
</galaxy-adaptive-card>
