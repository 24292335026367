import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Comment, AppSettingService, EmbedService, IMessage, NotifyService, SocialApiService, Person, EntitySocketService, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-comment',
  templateUrl: './media-comment.component.html',
  styleUrls: ['./media-comment.component.scss']
})
export class MediaCommentComponent implements OnInit, OnDestroy {
  @Input() data: Comment = new Comment;
  @Input() isChildComment: boolean = false;
  @Input() isLoggedIn: boolean = false;
  @Input() personInfo: Person = new Person;


  @Input() entityId: number = 0;

  /**
   * 53 lifeshot/social entity
   * 54 play media entity
   * 55 artwork
   * 56 product
   * 57 blog
   * 58 message
   * 59 forum thread
   * 60 rent space entity
   */

  @Input() entityTypeId: number = 0;

  @Output() commentAdded = new EventEmitter<number>();

  repliesData: Comment[] = [];
  page = 1;
  pageSize: string | number = 10;
  totalData: number = 0;

  showReplyData: boolean = false;


  showReply: boolean = false;
  personImage: SafeResourceUrl = '';
  isLoading: boolean = false;
  theme: string = '';
  themeUpdated$!: Subscription;
  constructor(
    private _socialApi: SocialApiService,
    private _embed: EmbedService,
    private _notify: NotifyService,
    private _appSetting: AppSettingService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.showReplyData = false;

    this.personImage = this._embed.imgUrl(this.data.person.image, 'sm');

    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

  }


  // get replies to this comment
  getData() {
    this.isLoading = true;
    this._socialApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        id: this.entityId.toString(),
        category: this.entityTypeId.toString(),
        categoryType: this.data.id.toString() // parent
      })
      .getPageOf(Comment)
      .subscribe(
        {
          next: (res) => {
            this.repliesData = res.data as Comment[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
            this.showReplyData = true;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


  onReply(newComment: Comment) {

    this.isLoading = true;

    let comment = new Comment;
    comment = newComment;

    // const description = comment.description;
    // set entity
    comment.parent = this.data;
    comment.entityId = this.entityId;
    // type of entity .. blog, artwork, product, forum thread etc
    comment.entityType = this.entityTypeId;
    // set person too
    comment.person.id = this.personInfo.id;

    // console.log('incoming comments', comment);

    // show a loading for comment being inited

    this._socialApi
      .post(comment)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if ((res as any).success) {
              // console.log('tell me it was successfull', res);
              this.data.commentCount += 1;
              this.commentAdded.emit(res['total']);
              this._notify.success(res.message);
              // addTo it
              this.addToComment(res['data']);


            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
              // show a resend on the comment added
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );


    this._notify.success('Your comment has successfull been saved');
  }



  addToComment(comment) {
    // append to comments
    comment.person.name = this.personInfo.name;
    comment.person.image = this.personInfo.image;
    if (comment.description.trim().length == 0) {
      comment.description = 'description disappeared!';

    }
    this.repliesData.push(comment);


  }


  onLike() {
    if (!this.isLoggedIn) {
      this._notify.error('Please login to comment');
      return;
    }

  }

  onReport() {
    if (!this.isLoggedIn) {
      this._notify.error('Please login to comment');
      return;
    }

  }

  onToggleReply() {
    if (!this.isLoggedIn) {
      this._notify.error('Please login to comment');
      return;
    }
    this.showReply = !this.showReply;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }



}
