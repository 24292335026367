<div class="air-left bg-dark-light">
  <!-- [class.bg-dark]="theme === 'dark'"
  [class.bg-white]="theme === 'light'" -->
  <div class="adx-logo" (click)="onMenuClicked()">
    <div class="adx-item adx-icon"><i class="fa fa-bars"></i></div>
  </div>

  <div class="adx-menu  ">
    <div class="adx-list adx-flat adx-sm">
      <div
        *ngFor="let item of menu"
        class="adx-item"
        [routerLink]="item.route"
        [routerLinkActive]="'color-' + aRouterLink + ' ' + ' active'"
      >
        <i class="fal fa-{{ item.icon }}  default"></i>
        <i class="fa fa-{{ item.icon }}  active"></i>
        <span class="dark"> {{ item.menu }}</span>
      </div>

      <!--
        <div class="adx-item" routerLink="news" routerLinkActive="color-tgreen active">
          <i class="fal fa-star"></i>
          <span> airNews</span>
        </div>
      -->
    </div>
  </div>

  <div class="adx-user-profile" routerLink="profile" routerLinkActive="active">
    <div class="user-pic">
      <img
        src="http://127.0.0.1/airCloud/app/images/olivia-deriva.jpg"
        class="img-responsive"
        alt=""
      />
    </div>
  </div>
</div>
