<section class="adx-section bg-white bg-x">
  <div class="bg-special2">

  </div>
</section>

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center" style="margin-top:-150px">
          <a href="https://suite.aiira.co" target="_blank" class="adx-logo">
            <img src="assets/images/airSuite/airSuite.png" class="img-responsive" alt="" />
          </a>
          <h2 class=" text-normal text-size30">Apps for Business</h2>
          <!-- <div>
            <img src="assets/images/play/aiira-apps-home.jpg" class="img-responsive" alt="" />
          </div> -->
          <div class="text-center bg-pinkx">
            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <a class="adx-btn adx-round adx-sm btn-blue" href="https://qompass.aiira.co" target="_blank">
              &nbsp;&nbsp;&nbsp; Sign In &nbsp;&nbsp;&nbsp;
            </a>
            <a class="adx-btn adx-round adx-sm outline outline-blue btn-dark" href="https://suite.aiira.co"
              target="_blank">
              Get airSuite
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <!-- <div class="topix">

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <a class="adx-btn adx-round adx-sm btn-blue" href="https://qompass.aiira.co" target="_blank">
              &nbsp;&nbsp;&nbsp; Sign In &nbsp;&nbsp;&nbsp;
            </a>
            <a class="adx-btn adx-round adx-sm outline outline-blue btn-dark" href="https://suite.aiira.co"
              target="_blank">
              Get airSuite
            </a>
          </div>
          <div class="adx-card adx-img adx-round adx-flat" style="border-radius: 24px">
            <img src="/assets/images/home/airSuite.jpg" class="img-responsive" />
          </div> -->
          <p class="text-size18">
            airSuite is a comprehensive platform that offers a wide range of tools and apps for managing various
            aspects of your business. With airSuite, you can easily and efficiently manage your client relationships,
            track and optimize your finances, manage your hospital or healthcare facility, support your school or
            educational institution, and much more.
            <br>
            <br>
            airSuite includes powerful apps for client management, financial
            management, hospital management, school management, expense report management, shipping management,
            laboratory management, and many other key areas. Whether you're a small business owner, a healthcare
            professional, an educator, or a researcher, airSuite has the tools you need to succeed. Try airSuite today
            and discover the benefits of our integrated approach to business management.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-suite-apps></app-suite-apps>

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="/assets/images/airSuite/airStore-banner.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> airStore </span>
                <h1 class="text-normal">Inventory Management System</h1>
                <span class="text-size18">
                  Comes with airStore for Inventory and airFinance for payment.
                </span>
                <br />
                <a class="adx-btn adx-round adx-sm btn-blue" href="https://qompass.aiira.co" target="_blank">
                  Get Apps
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img">
              <img src="/assets/images/airSuite/airCare-banner.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> airCare </span>
                <h1 class="text-normal">Hospital Managment System</h1>
                <span class="text-size18">
                  Comes with airCare for Hospital, airFinance for Payments and
                  airStore for Pharmacy Management
                </span>
                <br />
                <a class="adx-btn adx-round adx-sm btn-blue" href="https://qompass.aiira.co" target="_blank">
                  Get Apps
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img">
              <img src="/assets/images/airSuite/airSchool-banner.jpg" class="img-responsive" alt="" />
            </div>
            <div class="adx-detail">
              <div class="padding">
                <span> airSchool </span>
                <h1 class="text-normal">School Management System</h1>
                <span class="text-size18">
                  Comes with airShool for Academic Management, airFinance for
                  Fees and Payments and airStore for School's BookShop.
                </span>
                <br />
                <a class="adx-btn adx-round adx-sm btn-blue" href="https://qompass.aiira.co" target="_blank">
                  Get Apps
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div
          class="adx-card adx-flat text-center bg-grey"
          style="border-radius: 24px"
        >
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">
              Which Mac notebook is right for you?
            </h1>

              <span class="text-size18">
                Our most powerful pro lineup ever — MacBook Pro, Mac Pro, and Pro
                Display XDR. Ready for your best work ever.
              </span>

          </div>
          <p></p>
          <div class="offer-flex text-left">
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black">
                <img
                  src="/assets/images/airSuite/airStore-banner.jpg"
                  class="img-responsive"
                  alt=""
                />
              </div>
              <div class="adx-item adx-flat">
                <h2>Inventory Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black"></div>
              <div class="adx-item adx-flat">
                <h2>Hospital Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black"></div>
              <div class="adx-item adx-flat">
                <h2>School Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
          </div>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-grey">
          <div class="flexx">
            <div class="adx-img"></div>
            <div class="adx-detail" style="width: 60%">
              <div class="padding">
                <span> <br /> </span>
                <h1 class="text-normal">
                  Save 6% on holiday <br />
                  gifts at Apple.
                </h1>
                <span class="text-size18">
                  Get 6% Daily Cash back when you pay with Apple <br />
                  Card from now until December 31.*
                </span>
                <br />

                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="grid-2 padding-sm">
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <div class="topix">

                    <h1 class="text-normal text-size50">Mac for Education</h1>


                  <span class="text-size18">
                    Play extraordinary. <br />
                    Find it on the App Store.
                  </span>
                  <br />
                  <button class="adx-btn btn-default clear">
                    Try it free5 >
                  </button>
                  <button class="adx-btn btn-default clear">Learn more ></button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <div class="topix">
                  <!--
                    <h1 class="text-normal text-size50">Mac for Education</h1>


                  <span class="text-size18">
                    Everything you love about News. Plus.
                  </span>
                  <br />
                  <button class="adx-btn btn-default clear">
                    Try Apple News+ free6 >
                  </button>
                  <br />
                  <button class="adx-btn btn-default clear">Learn more ></button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <div class="topix">
                  <h1 class="text-normal text-size36">Mac for Education</h1>

                  <span class="text-size18">
                    Power to make big things happen in the classroom.
                  </span>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about Mac in education >
                  </button>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about special pricing for institutions >
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <div class="topix">
                  <h1 class="text-normal text-size36">
                    Mac for Higher Education
                  </h1>

                  <span class="text-size18">
                    Ready for everything college has to offer.
                  </span>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about Mac in higher education >
                  </button>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Shop with Apple Education Pricing >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div
          class="adx-card adx-flat text-center bg-black"
          style="border-radius: 24px"
        >
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">Viyo for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear">
              Learn about Mac in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear">
              Shop for your business >
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section> -->
