import { Component, OnInit, Input, Output, EventEmitter, SecurityContext, OnDestroy } from '@angular/core';
import { ICollection, EmbedService, AppSettingService, Collection } from '@galaxy/entity-api';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-collection-item',
  templateUrl: './media-collection-item.component.html',
  styleUrls: ['./media-collection-item.component.scss']
})
export class MediaCollectionItemComponent implements OnInit, OnDestroy {

  @Input() data: ICollection = new Collection;
  @Input() size: string = 'xs';
  @Output() addToCollection = new EventEmitter<ICollection>();

  thumbnail: string = '';

  imageData: string[] = [];
  placeHolderData: number[] = [];

  theme: string = 'light'; 
themeUpdated$!:Subscription;
  constructor(
    private _appSetting: AppSettingService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // set theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    if (this.size === 'xs') {

      if (this.data.thumbnails?.length > 0) {

        this.thumbnail = this._sanitizer.sanitize(
          SecurityContext.URL, this._embed.imgUrl(this.data.thumbnails[0]
            , 'sm')
        )
      }
    } else {
      this.getThumbnailGrid();
    }
  }


  getThumbnailGrid() {
    this.data.thumbnails.forEach(element => {
      // limits to 4 images
      if (this.imageData.length < 4) {
        this.imageData.push(
          this._sanitizer.sanitize(
            SecurityContext.URL, this._embed.imgUrl(element, 'sm')
          )
        );
      }

    });

    const imageDataCount = this.imageData.length;
    const placeHolderDataCount = 4 - imageDataCount;


    for (let index = 0; index < placeHolderDataCount; index++) {
      this.placeHolderData.push(imageDataCount + index);

    }

  }
  onSaveClicked() {
    this.addToCollection.emit(this.data)
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
