<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat bg-smoke text-center adx-image adx-round"
          style=' background-color: grey; border-radius: 24px; max-height: 385px;'>
          <!-- <p style="padding:150px 16px; color: white; opacity: 0;">
            <small>THIS IS AIIRA</small>
            <br>
            <br>
            <span class="text-size34">
              We are building a new kind of technology
            </span>
          </p> -->
          <img src="/assets/images/aiira-bg-mini.jpg" alt="aiira" class="img-responsive">
        </div>

      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-6">
        <div class="adx-card adx-flat adx-round">
          <div class="adx-divider color-tgreen">COMPANY OVERVIEW</div>
          <h1 class="title text-normal text-size34">
            AIIRA, from
            <br>
            ground up
          </h1>
        </div>

      </div>
      <div class="adx-cols-6">
        <div class="adx-card adx-flat adx-round">
          <p>
            Aiira is dedicated to providing innovative solutions for businesses and individuals. With our all-in-one
            platform, you can manage your customer relationships, connect with your audience on social media, and enjoy
            a wide range of games for entertainment and leisure.
          </p>
          <p>
            Our powerful CRM app allows you to organize, track, and optimize your interactions with customers, while our
            social media platform offers a seamless and engaging experience for sharing content and building
            connections. And our gaming platform offers a diverse selection of titles for you to enjoy on your own or
            with friends.

          </p>
          <p>
            Our products are designed to be easy to use and highly effective, and we have a proven track record of
            success with a wide range of businesses and organizations. We believe that Aiira is
            well-positioned to support and enhance your operations, and we would be honored to have the opportunity to
            earn your trust and business.
          </p>
        </div>

      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <br>
        <br>
        <h1 class="text-center text-normal">Meet the Team</h1>
        <br>
        <br>
        <div class="team-grid">
          <div *ngFor="let item of team">
            <div class="adx-img">
              <img [src]="item.photo" alt="">
            </div>
            <div>
              <span class="name">{{item.name}}</span>
              <small></small>
              <small style="opacity: .5;">{{item.position}}</small>
            </div>
          </div>
        </div>

        <div style="height: 100px; text-align: center;">
        <i class="axl ax-circle"></i> &nbsp;
        <i class="axl ax-circle"></i> &nbsp;
        <i class="axl ax-circle"></i>
        </div>

        <div class="adx-card adx-flat text-center">
          <div class="adx-my-image"></div>
          <div>
            <div class="text-normal text-size20">Kofi Owusu-Afriyie</div>
            <small style="opacity: .5;">Founder & CEO</small>
          </div>
          <br>
          <br>

          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-special" href="https://connect.aiira.co/profile/majesty"
            target="_blank"><i class="axr ax-brand-aiira"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-prim" href="https://twitter.com/projectaiir"
            target="_blank"><i class="axr ax-brand-twitter"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-red" href="https://instagram.com/_aiira.co"
            target="_blank"><i class="axr ax-brand-instagram"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-blue" href="https://facebook.com/thecedi"
            target="_blank"><i class="axr ax-brand-facebook"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-dark" href="https://github.com/aiira-co"
            target="_blank"><i class="axr ax-brand-github"></i></a>
          <p>
            Hello, my name is Kofi Owusu-Afriyie and I am the Founder and CEO of AIIRA, a software company that is
            dedicated to
            developing innovative and user-friendly solutions for businesses and individuals.
            We
            have been working on our suite of platforms for over five years, and I am excited to see them grow and
            evolve as we continue to provide valuable and useful products and services.
            <br>
            <br>
            With Qompass, airSuite, Client
            Portal, Connect, and airPlay, aiira offers a range of products that support and enhance a wide range of
            activities, and I am committed to continuing to innovate and improve our offerings in order to better serve
            our customers.
            <br>
            <br>
            Thank you for considering aiira and our products, and we look forward to working with you.
          </p>
          <!-- <p>
            After 10 years of practical experience in Design and Programming, I
            was satisfied to call myself a Full-Stack Web Developer. I created
            Frameworks for both Client and Server-Side Programming:
            <a class="color-blue" href="https://github.com/aiira-co" target="_blank">https://github.com/aiira-co</a>
            at the same time, keeping my passion for Computer Graphics in check
            through Media Softwares like Affinity Photo, Affinity Designer,
            Davinchi Resolve, After Effects, Blender, Unreal Engine ... just to
            mention a few.
          </p>
          <p>
            Now the question about my profession, Who Am I? FullStack Developer,
            3D Artist and Game Developer. Creator of Project-AIIR(Web
            Frameworks, OpenAir Projects and Enterprise Suite) now
            <b>aiira.co</b> . Also a 3D Generalist at <i>Hashem Studioz</i> now
            <b>Aiira Interactive Studios</b>
          </p>

          <p>
            Its been more than 5 years of development on my frameworks and at
            least 13 years of experience as a developer and a 3D Artist. What I
            wanted as a programmer was to stay organized and consistent
            separating my Logics from Database Queries and Views. I needed a
            modular framework that works similar to other emerging frameworks
            like Angular, dotNet Core, and Material.
          </p>
          <p>
            So I created ProjectAIIR, Web frameworks and real-life softwares to
            prove my frameworks were production ready. One of the first OpenAIIR
            project I embarked on was <b>airSuite</b>: An Enterprise Solution
            Software which has evolved from my framework (coreFramework PHP) to
            (Angular with Spatial Framework) over 3 years to what it is today.
          </p> -->

        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-white">
  <div class='wrapper'>
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="adx-card adx-img adx-round adx-flat" style="border-radius: 24px" routerLink="/suite">
            <img src="/assets/images/home/airSuite.jpg" class="img-responsive" />
          </div>
          <p>
            <!-- What started as a quest to fill my portfolio has birthed Aiira. In
            the mean time, I handle all works from management to production of
            the platforms ->
            <a class="color-blue" href="https://suite.aiira.co" target="_blank">airSuite</a>
            : Enterprise Solution,
            <a class="color-blue" href="https://business.aiira.co" target="_blank">Business Platform</a>
            : Business & Job Directory,
            <a class="color-blue" href="https://connect.aiira.co" target="_blank">Social Platform</a>
            : Media, Marketing & Distribution Platform.
            <br /> -->
            We are open for help and support from you. You can support us by going
            through the apps and reporting bugs and issues you encountered in
            the app. You can also contact me directly via mail: admin&#64;aiira.co
            or through our social handles
          </p>

          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-special" href="https://connect.aiira.co/profile/majesty"
            target="_blank"><i class="axr ax-brand-aiira"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-prim" href="https://twitter.com/aiira_co"
            target="_blank"><i class="axr ax-brand-twitter"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-red" href="https://instagram.com/_aiira.co"
            target="_blank"><i class="axr ax-brand-instagram"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-blue" href="https://facebook.com/aiira.co"
            target="_blank"><i class="axr ax-brand-facebook"></i></a>
          <a class="adx-btn adx-rounder adx-sm adx-icon adx-flat btn-dark" href="https://github.com/aiira-co"
            target="_blank"><i class="axr ax-brand-github"></i></a>
        </div>

      </div>
    </div>
  </div>
</section>
