import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  OriDbService,
  OriInitSocketService,
  AppSettingService,
  ColdBootService,
  IActivity,
  Person,
  IConversation,
  ICommunity
} from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-cold-boot',
  templateUrl: './cold-boot.component.html',
  styleUrls: ['./cold-boot.component.scss']
})
export class ColdBootComponent implements OnInit {

  theme: string = 'light'; 
themeUpdated$!:Subscription;
  app: string = 'artist';

  redirectTo: string = 'home';

  isUserNew: boolean = false;
  coldBootDone = new EventEmitter<boolean>();

  showError = false;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _oriDB: OriDbService,
    private _oriSocket: OriInitSocketService,
    private _appSetting: AppSettingService,
    private _storageMap: StorageMap,

    private _coldBoot: ColdBootService,
    private _location: Location
  ) { }

  ngOnInit() {
    // App Setting
    this.theme = this._appSetting.defaultTheme ?? this.theme;
    // this.color = this._appSetting.defaultColor;
    this.app = this._appSetting.app;

    switch (this._authService.redirectToOnLoggin) {

      case undefined:
        this.redirectTo = '/home';
        break;

      case '':
        this.redirectTo = '/home';
        break;

      case '/welcome':
        this.redirectTo = '/home';
        break;

      default:
        this.redirectTo = this._authService.redirectToOnLoggin;
        break;

    }
    this._coldBoot.coldBootError.subscribe(res => this.showError = res);
    this.initColdBoot();

  }

  initColdBoot() {
    this.showError = false;

    if (this._oriDB.coldStartDone) {
      // console.log('already cold started, i have to redirectTo -- - - - ', this.redirectTo);
      this._router.navigateByUrl(this.redirectTo);
    }

    else {
      // console.log('start coldboot');

      this.coldBootApp();

      this.coldBootDone.subscribe(res => {
        // console.log('coldstart triggered, i have to redirectTo -- - - - ', this.redirectTo);
        if (res) {
          this._oriDB.coldStartDone = true;
          this._router.navigateByUrl(this.redirectTo);
          this.showError = false;
          // this._location.back();
        }
      });



    }
  }

  private coldBootApp() {
    // console.log('cold botting');

    // set app theme
    const localTheme = localStorage.getItem('ori.theme');
    this.theme = localTheme ? localTheme : this.theme;
    this._appSetting.setTheme(this.theme);


    // this._appSetting.setColor(this.aRouterLink);

    // get user location

    // get ori:push.enbaled
    const pushNotifs = localStorage.getItem('ori.pushNotifs');
    if (pushNotifs === null) {
      localStorage.setItem('ori.pushNotifs', JSON.stringify({ data: false, created: Math.floor((+new Date) / 1000) }));
    }


    // get ori:pwa
    const pwa = localStorage.getItem('ori.pwa');
    if (pwa === null) {

      localStorage.setItem('ori.pwa', JSON.stringify({ data: false, created: Math.floor((+new Date) / 1000) }));
    }

    // store cart
    this._oriDB.getCart();

    // store recent
    this._oriDB.getRecentViewedProduct();

    // console.log('check for auth');

    if (!this._authService.loggedIn) {

      this.authApp();
      return;
    }



    // console.log('already auth. so trying to move on, maybe check viewerId changes');

    // user need to auth
    if (this._authService.currentUser.requestConfirmation) {
      // console.log('2-way auth active');
      this._authService.redirectToOnLoggin = this.redirectTo;
      this._router.navigateByUrl('/confirm');
      return;
    }

    this.checkUserViewerId();

    // this.coldBootDone.emit(true);


  }

  private authApp() {
    // console.log('trying to auth');

    this._authService.initLocalToken().subscribe(res => {
      if (res) {
        // get people he follow
        this.checkUserViewerId();
      } else {
        this.coldBootDone.emit(true);
      }
    });

  }

  private checkUserViewerId() {
    // console.log('checking user change');

    this._storageMap.get('users.viewerId').subscribe(res => {

      if (res) {

        if (res === this._authService.currentUser.unique) {
          // console.log('same user');
          // same user
          // check if cold boot data is expired and get new data again
          this._storageMap.get('lastColdBootTimestamp').subscribe(exp => {
            // console.log('checking cold boot exp', exp);
            const time = exp ? +exp : 0;
            this._coldBoot.isColdBootExpired(time) ?
              this.initNewUserData() : this.intUserData();

          });

        } else {
          // console.log('user change');
          // user changed
          this.initNewUserData();
        }





      } else {
        // first time auth app is loading here
        this.initNewUserData();
      }

    });

  }

  private intUserData() {
    const page = 1;
    const pageSize = 100;
    // initMessage;
    // load conversation & connect to db
    this.checkConversation();
    // check if res is up to 100 i.e relationship.total;
    // get second page. page = 2, pageSize = relatationship.users.length:
    this._storageMap.get('relationship.users').subscribe(res => {
      if (res && (<any[]>res).length > 0) {
        // set relationship
        this._oriDB.setRelationships(<Person[]>res);
        // load feed
        this.checkViewerFeed(this._oriDB.relationshipIds);

        this._storageMap.get('relationship.total').subscribe(resTotal => {

          // +resTotal === (<any[]>res).length ?
          this.coldBootDone.emit(true)
          // :
          // this.getRelationships(2, (<any[]>res).length);
        })
      } else {
        // init users info
        // this.getRelationships(page, pageSize);
        this.loadNewConversation();
      }

      this.checkCommunity();
    });

    this.coldBootDone.emit(true)
  }

  private initNewUserData() {
    this.isUserNew = true;
    this._storageMap.set('users.viewerId', this._authService.currentUser.unique).subscribe(() => { });
    // this.getRelationships(1, 100);
    this.loadNewConversation();
    this.loadCommunity();

    this.coldBootDone.emit(true)

  }


  private getRelationships(page: number = 1, pageSize: number = 100) {
    // console.log('getting relationship');

    this._coldBoot.getRelationship(page, pageSize).subscribe(res => {
      if (res) {
        // set relation and its ids in local db
        this._oriDB.setRelationships(<Person[]>res);

        this.checkViewerFeed(this._oriDB.relationshipIds);
      }
    });


  }


  private checkCommunity() {
    this._storageMap.get('community.items').subscribe(res => {
      if (res) {
        this._oriDB.setCommunities(<ICommunity[]>res);
      } else {
        this._coldBoot.getCommunity().subscribe(res => {
          this._oriDB.setCommunities(res);
        });
      }
    })

  }

  private loadCommunity() {
    this._coldBoot.getCommunity().subscribe(res => {
      this._oriDB.setCommunities(res);
    });
  }


  private checkViewerFeed(relatationshipIds: string) {
    if (this.isUserNew) {
      this.getStorageFeed(relatationshipIds);
    } else {
      this._storageMap.get('feed.items').subscribe(res => {
        if (res) {
          this._oriDB.setFeedItems(<IActivity[]>res);
          this.coldBootDone.emit(true);
        } else {
          this.getStorageFeed(relatationshipIds);
        }

      });
    }
  }


  private getStorageFeed(relatationshipIds: string) {
    // console.log('getting feed');
    this._coldBoot.getFeed(1, 12, relatationshipIds)
      .subscribe(res => {
        if (res) {
          this._oriDB.setFeedItems(<IActivity[]>res);
          this.coldBootDone.emit(true);
        }
      });
  }


  private checkConversation() {
    this._storageMap.get('messenger.conversations').subscribe(res => {
      if (res) {
        this._oriDB.conversations = <IConversation[]>res;
        this._oriSocket.socketConversations();
      } else {
        this._coldBoot.getConversation().subscribe(res => {
          this._oriDB.conversations = res;
          this._oriSocket.socketConversations();
        });
      }

      // socket them
    })
  }


  private loadNewConversation() {
    this._coldBoot.getConversation().subscribe(res => {
      this._oriDB.conversations = res;
      this._oriSocket.socketConversations();
    });
  }



}
