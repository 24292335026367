import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingService, EmbedService, ErrorHandlerService, IdentityApiService, IResetPassword, NotifyService, ResetPassword } from '@galaxy/entity-api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  data: IResetPassword = new ResetPassword();

  activeUserAvatar: SafeResourceUrl = '';

  showForgot: boolean = true;
  isLoading: boolean = false;

  userEmail: string = '';

  newPass: string = '';
  newPass1: string = '';
  emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  token: string = '';

  constructor(
    private _appSetting: AppSettingService,
    private _aRoute: ActivatedRoute,

    private _router: Router,
    private _identityApi: IdentityApiService,
    private _notify: NotifyService,
    private _embed: EmbedService,
    private _errorHandlerService: ErrorHandlerService

  ) {

  }

  ngOnInit() {
    this._aRoute.params.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        this.isLoading = true;
        this.showForgot = false;
        this.getData();

      } else {
        this.showForgot = true;
      }
    });
  }

  getData() {
    this._identityApi
      .setParams({}, this.token)
      .get(ResetPassword)
      .subscribe(
        {
          next: (res) => {
            if (res.data.id) {

              this._identityApi.DataMap({ from: res, to: this.data });
              this.activeUserAvatar = this._embed.imgUrl(this.data.person.image, 'sm');
              this.showForgot = false;

            } else {
              this._notify.error('Invalid token');
              this.showForgot = true;
            }
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  resetPass() {
    if (this.newPass.trim() !== this.newPass1.trim()) {
      this._notify.error('Passwords does not match. Please try again.');
      return;
    }

    this.isLoading = true;
    this.data.password = this.newPass;
    this.data.resetIp = this._appSetting.ipAddress;

    this._identityApi
      .setParams({}, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.success(res.message);
              // redirect to home
              this._router.navigate(['/']);
            } else {
              this._notify.info(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );


  }



  onToggleForgotPassword(value: boolean) {
    this.showForgot = value;
  }

  onRequestReset() {
    const req = new ResetPassword;
    req.email = this.userEmail;
    req.requestedIp = this._appSetting.ipAddress;

    this.isLoading = true;
    this._identityApi.post(req).subscribe(
      {
        next: (res) => {
          this._notify.info(res.message);

          this.userEmail = '';
          this.isLoading = false;
          this.showForgot = false;

          // redirect to home
          this._router.navigate(['/']);
        },
        error: (err) => {
          this.isLoading = false;
          this._errorHandlerService.handleError(err)
        },
      }
    )
  }


}
