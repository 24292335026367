import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() theme: string = 'dark';
  @Input() aRouterLink: string = '';
  @Input() appName: string = '';
  @Input() menu: any[] = [];
  @Input() isMini: boolean = false;
  constructor() { }

  ngOnInit() { }

  onMenuClicked() { }
}
