import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  EmbedService,
  IArtwork,
  Artwork,
  IEntity,
  NotifyService,
  AppSettingService,
  IdentityApiService,
  Entity,
} from '@galaxy/entity-api';

import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const RootURL = 'https://cdn-v2.aiira.co';
const URL = '/cloud-api/upload/image';
@Component({
  selector: 'galaxy-slider-builder',
  templateUrl: './slider-builder.component.html',
  styleUrls: ['./slider-builder.component.scss'],
})
export class SliderBuilderComponent implements OnInit {
  @Input() data: IArtwork[] = []; //slydes of images/video/3d
  @Input() project: string = 'artwork';
  @Input() entity: IEntity = new Entity();
  @Input() allowVideo: boolean = true;
  @Input() allow3D: boolean = true;
  @Input() miniView: boolean = false;
  @Input() maxFileSize: number = 5 * 1024 * 1024;
  @Output() artworkSelected = new EventEmitter<string>();
  @Output() mediaChanged = new EventEmitter<IArtwork[]>();
  @Output() delArtwork = new EventEmitter<number>();
  @Output() projectThumbnail = new EventEmitter();
  sliderData: IArtwork[] = []; //slydes of images/video/3d
  uploadType = 'image/jpeg,image/png,image/jpg,image/webp,image/gif';
  hasBaseDropZoneOver: boolean = false;
  hasAnotherDropZoneOver: boolean = false;

  new3DPath: string = '';
  newImagePath: string = '';
  newImagePathThumbnail: string = '';
  newVideoPath: string = '';

  imageDataFile?: any;

  itemType = 1; //45image, 46-audio, 47-video, 48-3D
  showCreate: boolean = false;
  showDeleteModal: boolean = false;
  uploader: FileUploader;
  newImageData: any;
  activeArtwork: Artwork = new Artwork();
  canAddArtwork: boolean = true;
  theme: string = '';
  deleteArtwork: IArtwork = new Artwork();

  // dummyData = [1,2,3,4,5,6,7]

  constructor(
    private _sanitizer: DomSanitizer,
    private _embed: EmbedService,
    private _notifyService: NotifyService,
    private _appSettings: AppSettingService,

    private _identityApi: IdentityApiService
  ) {
    this.theme = this._appSettings.defaultTheme;
    this._appSettings.theme.subscribe((res) => {
      this.theme = res;
    });

    this.uploader = new FileUploader({
      url: this._identityApi.enableProd ? RootURL + URL : '/cdn' + URL,
      headers: [{ name: 'Accept', value: 'application/json' }],
      disableMultipart: false,
      autoUpload: true,
      method: 'post',
      itemAlias: this.project,
      allowedFileType: ['image'],
      maxFileSize:this.maxFileSize
    });
  }

  ngOnInit() {
    // set file alias again
    // this.uploader.
    // this.uploader.setOptions({
    //   itemAlias: this.project,
    //   url: ''
    // });
    // console.log('uploader options are spit here', this.uploader.options);

    this.sliderData = this.data;
    // autoUpload Artwork Status

    // error when adding file
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Warning! The uploaded file ' + item.name + 'of size ' + this._appSettings.formatBytes(item.size) + ' exceeds the maximun allowed size of ' + this._appSettings.formatBytes(this.maxFileSize);
          break;

        default:
          message = 'Error trying to upload file ' + item.name
          break;
      }

      this._notifyService.error(message);
    }

    this.uploader.onErrorItem = (item, response, status, headers) =>
      this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) =>
      this.onSuccessItem(item, response, status, headers);
  }
  // ng2-file-upload mehtods
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];

    // console.log(file);

    this._embed
      .readBase64(file)
      .then((data) => (this.newImageData.path = data));
    // this.mediaChanged.emit(this.sliderData);
  }
  onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    const res = JSON.parse(response);
    if (res.success) {
      this.onAddArtwork(1, res.url[0]);
      // this.newImagePath = url;
      // this.newImagePathThumbnail = this._embed.imgUrl(url, 'md');

      this._notifyService.success('Your Image is sucessfully uploaded');
    } else {
      this._notifyService.error(res.message);
    }
  }

  onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let error = JSON.parse(response); //error server response
    this._notifyService.error(error.message);

    this.uploader.isUploading = false;
  }

  onAddArtwork(dataType: number, path: string) {
    const data = new Artwork();
    // data.entity = <Entity>this.entity;
    data.entity = null;
    data.type = dataType;
    data.path = path.trim();

    switch (dataType) {
      case 1:
        // image
        // use the uploaded image url as thumbnail
        data.thumbnail = this._embed.imgUrlString(path.trim(), 'md') as string;

        break;
      case 3:
        // video
        data.thumbnail = this._embed.getVideoImage(path.trim());

        break;
      case 4:
        // sketchfab
        // use the uploaded image url as thumbnail
        this._embed.getSketchfabImage(path.trim()).subscribe((res) => {
          data.thumbnail = res['thumbnails']['images'][1]['url'];
        });
        // console.log('see the data thumbnail', data.thumbnail);

        break;

      default:
        data.thumbnail = this._embed.imgUrlString(path.trim(), 'md') as string;
        break;
    }

    // if (this.data.length === 1) {
    //   this.projectThumbnail.emit(data.thumbnail);
    // }

    this.data.push(data);
    // console.log('all data is', this.data);
    this.mediaChanged.emit(this.data);

    // clean the path & close modal
    this.onCancelArtwork();
  }

  onCancelArtwork() {
    // first clean up the inputs
    this.newVideoPath = '';
    this.new3DPath = '';
    this.newImagePath = ';';
    // now close the modal
    this.showCreate = false;
    this.showDeleteModal = false;
    this.canAddArtwork = true;
  }

  onShowArtwork(dataType: number, item: Artwork) {
    this.itemType = dataType;
    this.activeArtwork = item;
    this.canAddArtwork = false;
    this.showCreate = true;
  }
  onReorderArtwork() {
    // somethein here
  }

  onShowDeleteModal(artwork: IArtwork) {
    this.deleteArtwork = artwork;
    this.showDeleteModal = true;
  }

  onRemoveArtwork() {
    // console.log('deleting a row from the data', this.deleteArtwork);
    // this.data = this.data.slice(index, 1);
    this.data = this.data.filter((obj) => {
      // lets see the data
      // console.log('in the filder', obj)
      return obj !== this.deleteArtwork;
    });

    // console.log('data has been reduced', this.data)
    // update the removed data
    this.mediaChanged.emit(this.data);
    this.sliderData = this.data;

    // if its an edit & id != 0
    if (this.deleteArtwork.id !== 0) {
      this.delArtwork.emit(this.deleteArtwork.id);
    }
    this.showDeleteModal = false;
  }

  // Embed

  onConvertLink(link: string, isVideo = true) {
    // makle sure the link is correct before attempt embed
    if (isVideo) {
      if (
        link.includes('youtube.com/watch?v=') ||
        link.includes('vimeo.com/')
      ) {
        return this._embed.onConvertVideoLink(link);
      }
    } else if (link.includes('sketchfab.com/')) {
      return this._embed.onConvertSkectfabLink(link);
    }
    // this.new3DPath = '';
    // this.newVideoPath = '';
    return null;
  }

  onDrop(event: CdkDragDrop<IArtwork[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  onCloseClicked($event) {
    this.showCreate = false;
    this.showDeleteModal = false;
  }

  onAddMedia(type) {
    this.itemType = type;
    this.showCreate = true;
  }
}
