<div class="active-stream" [class.active]='isActive'>
  <div id="video-wrapper">
    <video #remoteVideo id="remote-video" autoplay playsinline></video>
    <video #viewerVideo id="viewer-video" autoplay playsinline [muted]=true></video>
  </div>

  <div class="controls">
    <div class='center'>
      <button (click)='muteMicrophone()' class="adx-btn adx-round adx-icon">
        <div class="axr ax-microphone"></div>
      </button>
      <button (click)='ToggleVideo()' class="adx-btn adx-round adx-icon">
        <div class="axr ax-video-off"></div>
      </button>
      <button (click)='addParticipant()' class="adx-btn adx-round adx-icon btn-tgreen">
        <div class="axr ax-video-plus"></div>
      </button>
      <button (click)='endCall()' class="adx-btn adx-round adx-icon btn-pink">
        <div class="axr ax-phone-off"></div>
      </button>
    </div>
  </div>

  <div *ngIf="!callAnswered" class='calling-person'>
    <div class='img-component'>
      <div class="adx-img">
        <img [src]="rAvatar" class='img-responsive' alt="">
      </div>
      <div class='anime-div bg-pink'></div>
    </div>
    <br>
    <br>
    <h2 class="text-normal">
      Calling {{reciepient.name| maxLength:20}}
      <br>
      <span style="opacity: .5;"> &#64;{{reciepient.username | maxLength:8}}</span>
    </h2>
  </div>

</div>


<div class='call-notification' [class.show]='showAnswerNotification'>
  <galaxy-adaptive-card [showPadding]=false [shape]='"rounded"'>
    <div class='img-component'>
      <div class="adx-img">
        <img [src]="avatar" class='img-responsive' alt="">
      </div>
      <div class='anime-div bg-pink'></div>
    </div>

    Call From {{caller.name| maxLength:8}} <span style="opacity: .5;"> &#64;{{caller.username | maxLength:8}}</span>
    <div>
      <button (click)='acceptCall()' class="adx-btn adx-round btn-tgreen adx-sm adx-flat">
        Answer
      </button>

      <button (click)='cancelCall' class="adx-btn adx-round btn-pink adx-sm adx-flat">
        Cancel
      </button>
    </div>

  </galaxy-adaptive-card>
  <audio src="/assets/audio/skype-call-tone.mp3" loop #localAudioAlert id="localAudioAlert"></audio>
</div>
