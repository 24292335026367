<div class="suite-flex">

  <div class="top">
    <div>
      <div class="" style="border-radius: 20px; overflow: hidden; font-size: none; position: relative;">
        <img src="/assets/images/home/airSuite.jpg" class="img-responsive">
        <a href="https://www.youtube.com/watch?v=_mjATO3JYYU" target="_blank"
          class="adx-btn adx-round adx-icon btn-special adx-sm adx-flat"
          style="position: absolute; top:50%; left:50%; translate: -50% -50%;">
          <i class="axl ax-play"></i>
        </a>
      </div>
      <br>
      <div class="text-size16">
        <strong class="text-size18">
          airSuite: Enterprise Management Apps
        </strong>
        <p>
          Get the power to take your business to the next level.
        </p>
        <small class="color-blue">
          https://suite.aiira.co
        </small>
      </div>
      <div>
        <a routerLink="/suite" class="adx-btn adx-ellipse adx-sm btn-dark">Learn More</a>
        <a href="https://suite.aiira.co" class="adx-btn adx-ellipse adx-sm btn-dark">Visit Suite</a>
      </div>

    </div>
  </div>




  <div class="bottom">
    <div class="video ">
      <img src="assets/images/airSuite/airSuite.png" class="img-responsive" alt="" />
    </div>
    <div class="text-center text-size18">
      <span class="text-size20 color-blue">
        Management Platforms for

      </span>
      <br>
      HR, School, Finance, Hospital, Labouratory, Delivery, Fleet, Expesnse Report, Church and
      many more.
    </div>
    <br>
    <br>
  </div>
</div>
