import { Component, OnInit, SecurityContext, Input } from '@angular/core';
import { EmbedService, NotifyService } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'galaxy-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {

  @Input() isTop: boolean = false;
  @Input() reverseConstrast: boolean = false;
  data: {
    type: number; // 0 ->alert, 1 ->avatar, 2->thumbnail
    status: number; // 0 ->error, 1 ->success, 2->info
    img?: string;
    route?: string;
    message: string;
  }[] = [];

  alert: {
    type: number; // 0 ->alert, 1 ->avatar, 2->thumbnail
    status: number; // 0 ->error, 1 ->success, 2->info
    img?: string;
    route?: string;
    message: string;
  };

  notifyIsTop = false;

  constructor(
    private _notifyService: NotifyService,
    private _router: Router,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    // register here
    this._notifyService._notify.subscribe(res => {
      this.data.push(res);
      setTimeout(() => {
        this.onCloseAlert(res, null);
      }, 10000);
    });


    if (window.innerWidth <= 512) {
      this.notifyIsTop = true;
    } else {
      this.notifyIsTop = this.isTop;
    }

    // this.data = [
    //   {
    //     type: 0,
    //     status: 0,
    //     message: 'Error alert!'
    //   },
    //   {
    //     type: 0,
    //     status: 1,
    //     message: 'Success alert!'
    //   },
    //   {
    //     type: 0,
    //     status: 2,
    //     message: 'Info alert!'
    //   },
    //   {
    //     type: 1,
    //     status: 0,
    //     message: 'Success Avatar!',
    //     route: 'Success Avatar!'
    //   },
    //   {
    //     type: 2,
    //     status: 0,
    //     message: 'Success Thumbnail!'
    //   }
    // ];

    // setTimeout(() => {
    //   this.data.push({
    //     type: 0,
    //     status: 2,
    //     message: 'Testing!'
    //   });
    // }, 5000);
  }
  addAlert() {
    const alt = this.alert;

    this.data.push(alt);

    setTimeout(() => {
      this.onCloseAlert(alt, null);
    }, 10000);
  }

  onGetDataIcon(status: number) {
    let icon = 'circle';
    switch (status) {
      case 0:
        icon = 'urgent';
        break;
      case 1:
        icon = 'checks';
        break;
      case 2:
        icon = 'bell-ringing';
        break;

      default:
        icon = 'brand-aiira';
        break;
    }

    return icon;
  }


  onGetDataColor(status: number) {
    let color = 'special';
    switch (status) {
      case 0:
        color = 'pink';
        break;
      case 1:
        color = 'tgreen';
        break;
      case 2:
        color = 'special';
        break;

      default:
        color = 'special';
        break;
    }

    return color;
  }

  onAlertRoute(route?: string) {
    if (route) {
      // console.log('rout to --->', route);
      this._router.navigateByUrl('/' + route);
    }

    // console.log('no routing found');
  }
  onCloseAlert(alert, e: Event) {
    if (e) e.stopPropagation();
    // this.data = this.data.slice(index, 1);
    this.data = this.data.filter(obj => obj !== alert);
  }

  private embedImage(path: string, size = 'default') {
    return this._embed.imgUrl(path, size);
  }

  onSanitizeImage(image): SafeResourceUrl {
    return this._sanitizer.sanitize(SecurityContext.URL, image);
  }
}
