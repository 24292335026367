<div class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat ">
          <div class="topix">
            <h1 class="text-normal text-size34 text-center">Terms Of Service</h1>
          </div>

          <p class="text-size18">
            These Terms & Conditions ("Terms") govern your access and use of the Aiira Technologies app (the "App") and
            our websites. By accessing or using the App or our websites, you acknowledge that you have read and
            understand these Terms and agree to be bound by them. If you do not agree to these Terms, do not access or
            use the App or our websites.
          </p>
          <h2 class="title text-normal">
            Use of the App and Our Websites
          </h2>
          <p class="text-size18">
            The App and our websites are intended for personal, non-commercial use only. You may not use the App or our
            websites for any unlawful or unauthorized purpose, or in any way that infringes the rights of Aiira
            Technologies or any third party.
            <br>
            <br>
            You may not use the App or our websites in any way that could damage, disable, overburden, or impair the App
            or our websites, or interfere with any other party's use and enjoyment of the App or our websites.
            You may not attempt to gain unauthorized access to the App or our websites, or any other systems or networks
            connected to the App or our websites, or to any of the services offered on or through the App or our
            websites, by hacking, password mining, or any other illegitimate means.
            <br>
            <br>
            You may not use the App or our websites to transmit any worms, viruses, or other malicious software.
            You may not use the App or our websites to harvest, collect, gather, or compile information or data
            regarding other users without their consent.
          </p>
          <h2 class="title text-normal">
            User Content
          </h2>
          <p class="text-size18">
            The App and our websites may allow you to post, upload, or otherwise contribute content, such as text,
            photos, and videos (“User Content”).
            <br>
            <br>
            You are solely responsible for your User Content and the consequences of posting or publishing it. By
            posting or publishing your User Content, you represent and warrant that: (a) you have the right to do so,
            and (b) your User Content does not violate the rights of any third party, including intellectual property
            rights, privacy rights, or any other legal rights.
            <br>
            <br>
            You retain all of your ownership rights in your User Content. However, by posting or publishing your User
            Content on or through the App or our websites, you grant Aiira Technologies a worldwide, non-exclusive,
            royalty-free, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt,
            publish, translate, create derivative works from, distribute, and display your User Content in any media or
            platform.
            <br>
            <br>
            You acknowledge and agree that Aiira Technologies has no obligation to review, monitor, or moderate User
            Content, and we are not responsible for the accuracy, completeness, appropriateness, or legality of User
            Content. However, we reserve the right to remove or disable access to any User Content that we determine, in
            our sole discretion, violates these Terms or is otherwise harmful or inappropriate.
          </p>
          <h2 class="title text-normal">
            Intellectual Property
          </h2>
          <p class="text-size18">
            The App and our websites, and all of the content and materials included on or available through the App and
            our websites, including text, graphics, logos, images, software, and other intellectual property, are the
            property of Aiira Technologies or our licensors, and are protected by copyright and other intellectual
            property laws.
            <br>
            <br>
            You may not use any content or materials on the App or our websites for any commercial purpose without the
            express written consent of Aiira Technologies.
            <br>
            <br>
            You may not modify, reproduce, distribute, display, or create derivative works from any content or materials
            on the App or our websites without the express written consent of Aiira Technologies.
            You may not use any trademarks, logos, or other distinctive branding elements of Aiira Technologies or our
            licensors without the express written consent of Aiira Technologies.
            You may not remove or alter any copyright, trademark, or other proprietary notices on the App or our
            websites.
            <br>
            <br>
            You may not use any content or materials on the App or our websites for any purpose that infringes the
            intellectual property rights of Aiira Technologies or any third party.
            <br>
            <br>
            If you believe that your intellectual property rights have been violated by the App or our websites, or by
            any content or materials on the App or our websites, please contact us using the contact information
            provided in Section 8 of these Terms.
          </p>
          <h2 class="title text-normal">
            Disclaimer of Warranties
          </h2>
          <p class="text-size18">
            The App and our websites are provided on an "as is" and "as available" basis. Aiira Technologies makes no
            representations or warranties of any kind, express or implied, as to the operation of the App or our
            websites, or the information, content, materials, or products included on or available through the App or
            our websites.
            <br>
            <br>
            Aiira Technologies does not warrant that the App or our websites will be uninterrupted or error-free, and we
            will not be liable for any interruptions or errors.
            <br>
            <br>
            Aiira Technologies does not warrant that the App or our websites, or any content or materials on the App or
            our websites, will be free from viruses or other harmful components.
            <br>
            <br>
            Aiira Technologies does not warrant the accuracy, completeness, timeliness, reliability, suitability, or
            availability of the App or our websites, or the information, content, materials, or products included on or
            available through the App or our websites.
            <br>
            <br>
            Aiira Technologies does not endorse or assume any liability for any content, materials, products, or
            services offered by third parties through the App or our websites.
            You acknowledge that you use the App and our websites at your own risk.
          </p>
          <h2 class="title text-normal">
            Limitation of Liability
          </h2>
          <p class="text-size18">
            Aiira Technologies will not be liable for any damages of any kind arising from the use of the App or our
            websites, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.
            <br>
            <br>
            Aiira Technologies will not be liable for any loss of use, data, profits, or any other intangible losses
            resulting from the use or inability to use the App or our websites.
            <br>
            <br>
            Aiira Technologies will not be liable for any unauthorized access to or alteration of your transmissions or
            data, or any material or data sent or received, or not sent or received, through the App or our websites.
            <br>
            <br>
            Aiira Technologies will not be liable for any conduct of any third party on or through the App or our
            websites, including, but not limited to, any defamatory, offensive, or illegal conduct.
            <br>
            <br>
            Aiira Technologies will not be liable for any errors or omissions in the App or our websites, or for any
            loss or damage of any kind arising from the use of the App or our websites.
            <br>
            <br>
            Aiira Technologies will not be liable for any problems or technical malfunction of any telephone network or
            lines, computer systems, servers, providers, or software, or for any failure of email or players on account
            of technical problems or traffic congestion on the internet or on the App or our websites.
            <br>
            <br>
            You acknowledge that Aiira Technologies is not responsible for the availability or accuracy of any
            third-party websites or materials linked to or from the App or our websites, and that we do not endorse any
            of these websites or materials.
            <br>
            <br>
            You acknowledge that Aiira Technologies is not responsible for any damage or loss caused by or in connection
            with your use of or reliance on any content, materials, products, or services available on or through any
            third-party websites or materials linked to or from the App or our websites.
          </p>
          <h2 class="title text-normal">
            Indemnification
          </h2>
          <p class="text-size18">
            You agree to indemnify and hold Aiira Technologies and our affiliates, officers, agents, and employees
            harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or
            arising out of your use of the App or our websites, your violation of these Terms, or your violation of any
            rights of another.
          </p>
          <h2 class="title text-normal">
            Governing Law and Dispute Resolution
          </h2>
          <p class="text-size18">
            These Terms and your use of the App and our websites will be governed by and construed in accordance with
            the laws of the Ghana, without giving effect to any principles of conflicts of law.
            Any dispute arising out of or relating to these Terms or the App or our websites will be resolved through
            binding arbitration in accordance with the [arbitration association] Rules. The arbitration will be
            conducted in Accra, Ghana.
            <br>
            <br>
            The foregoing provisions of this Section 7 do not apply to any legal action taken by Aiira Technologies to
            seek an injunction or other equitable relief in connection with any loss, damage, or injury to Aiira
            Technologies' intellectual property rights.
          </p>
          <h2 class="title text-normal">
            Miscellaneous
          </h2>
          <p class="text-size18">
            These Terms constitute the entire agreement between you and Aiira Technologies regarding the use of the App
            and our websites.
            <br>
            <br>
            These Terms supersede any prior agreements or understandings, whether written or oral, regarding the use of
            the App and our websites.
            If any provision of these Terms is found to be invalid or unenforceable, that provision will be enforced to
            the maximum extent possible, and the remaining provisions will remain in full force and effect.
            <br>
            <br>
            Aiira Technologies' failure to enforce any right or provision of these Terms will not be deemed a waiver of
            such right or provision.
            Aiira Technologies may assign these Terms, in whole or in part, at any time without your consent. You may
            not assign these Terms or transfer any rights to use the App or our websites without the prior written
            consent of Aiira Technologies.
            <br>
            <br>
            The headings in these Terms are for convenience only and have no legal or contractual effect.
            These Terms do not create any agency, partnership, joint venture, employment, or franchise relationship
            between you and Aiira Technologies.
            <br>
            <br>
            You acknowledge that you have read these Terms, understand them, and agree to be bound by them.
            You may contact Aiira Technologies at the following address: support&#64;aiira.co
          </p>
          <h2 class="title text-normal">
            Changes to These Terms
          </h2>
          <p class="text-size18">
            Aiira Technologies reserves the right to update and revise these Terms at any time without notice. Any
            changes to these Terms will be effective immediately upon posting on the App or our websites. You are
            responsible for regularly reviewing these Terms to stay informed of any changes. Your continued use of the
            App or our websites after any changes to these Terms will constitute your acceptance of those changes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
