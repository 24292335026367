<div *ngIf="showModal" class="show-modal">
  <div (click)="onCloseModal($event)" class="m-overlay">
    <div class="m-modal" [class.fixed-wdith]="fixedWidth" [style.width]="width + '%'">
      <galaxy-adaptive-card [isBlur]="true" [shape]="'rounded'" [isFull]="true" [autoTransparent]="autoTransparent"
        [showPadding]="showPadding" [overflow]=overflow (click)="$event.stopPropagation()">
        <ng-content></ng-content>
      </galaxy-adaptive-card>
    </div>
  </div>
  <div class="close-btn">
    <button (click)="onCloseModal($event)" class="adx-btn adx-round adx-icon btn-default clear adx-sm">
      <i class="axl ax-chevron-left"></i>
    </button>
  </div>
</div>
