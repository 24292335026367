<div class="card-container" (click)="cardClicked()">
  <div class="bg-img" [style.backgroundImage]="'url('+data.image+')'">

  </div>
  <div class="adx-head">
    <div class="logo">
      <img [src]="data.logo">
    </div>
    <div class="detail">
      <strong>{{data.name}}</strong>
      <span>
        {{data.description}}
      </span>
    </div>
  </div>
  <div class="adx-space"></div>
  <div class="adx-foot">
    {{data.link}}
  </div>
</div>
