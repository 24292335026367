import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit, OnDestroy {

  @Input() label = 'Data';
  @Input() icon = 'axl ax-database-off';
  theme: string = 'light'; 
  themeUpdated$!:Subscription;

  constructor(
    private _appSetting: AppSettingService
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
