<div class='x-container' #contentContainer>
  <ng-content></ng-content>
</div>


<button class="adx-btn adx-icon btn-dark  adx-round left-btn" (click)="$event.stopPropagation(); onScroll(-1)">
  <i class="axl ax-chevron-left"></i>
</button>
<button class="adx-btn adx-icon btn-dark  adx-round right-btn" (click)="$event.stopPropagation(); onScroll(1)">
  <i class="axl ax-chevron-right"></i>
</button>
