<div class="install-app text-center" [class.show]="showInstall || showUpdate">
  <div class="logo">
    <img
      src="/assets/images/pwa/manifest-icon-192.png"
      class="img-responsive"
      alt="logo"
    />
  </div>
  <button
    class="adx-btn adx-round btn-pink hideInstallBtn"
    *ngIf="showInstall"
    (click)="onInstallApp()"
  >
    Install App
  </button>
  <button
    class="adx-btn adx-round btn-pink"
    *ngIf="showUpdate"
    (click)="onUpdateApp()"
  >
    Update App
  </button>
  <!-- <br /> -->
  <button class="adx-btn adx-round btn-default" (click)="onCloseNoti()">
    Not Now
  </button>
</div>
