<!-- <app-page-banner [title]="'Developer'" [bgColor]="'bg-smoke'"></app-page-banner> -->

<div class="adx-section bg-darker">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size50">Developed with you in mind.</h1>
            <span class="text-size18">
              Our most powerful pro-level libraries ever — Spatial Framework,
              Designa UI.
            </span>
            <br />
            <a
              href="https://github.com/aiira-co"
              target="_blank"
              class="adx-btn adx-round btn-tgreen outline xadx-flat adx-sm"
            >
              <!-- <i class="fab fa-github"></i> -->
              Get from GitHub >
            </a>

            <a
              href="https://gitlab.com/projectair"
              target="_blank"
              class="adx-btn adx-round btn-tgreen outline xadx-flat adx-sm"
            >
              <!-- <i class="fab fa-gitlab"></i> -->
              Get from GitLab >
            </a>

            <a
              href="https://www.youtube.com/channel/UC0NZZA9ceYfck0XWMA6I4SA"
              target="_blank"
              class="adx-btn adx-round btn-tgreen outline xadx-flat adx-sm"
            >
              <!-- <i class="fab fa-youtube"></i> -->
              Watch Tutorials >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size34">Designa</h1>

            <span class="text-size18">
              PRO Designa UI/UX Kit for the web - CSS enhanced for web apps!
            </span>
            <br />
            <a
              href="https://github.com/aiira-co/designa"
              target="_blank"
              class="adx-btn btn-prim adx-smz adx-round clear"
              >Learn about Designa ></a
            >
          </div>
          <div
            class="adx-card adx-img adx-round adx-flat"
            style="border-radius: 24px"
          >
            <img
              src="assets/images/developer/designa.jpg"
              class="img-responsive"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size34">Spatial WebAPI</h1>

            <span class="text-size18">
              A PHP 8.0+ Onion/Clean Architecture for Mono-repo (Multi-API) Api
              Projects with Swoole & Psr7.
            </span>
            <br />
            <a
              href="https://github.com/aiira-co/spatial"
              target="_blank"
              class="adx-btn btn-prim clear"
              >Learn about Spatial ></a
            >
          </div>
        </div>

        <div class="adx-card adx-round adx-flat bg-black outline outline-prim">
          <div class="padding" style="border-radius: 12px; overflow: hidden">
            <img
              src="assets/images/developer/spatial-modules-1.png"
              alt=""
              class="img-responsive"
            />
          </div>
          <h2 class="title text-normal">
            <b>
              Spatial Framework registers modules, controllers, & providers.
            </b>
          </h2>
          <p class="text-size16">
            The index.php file takes the 'default' AppModule::class which then
            imports other modules to bootstrap the app.
            <br />
            With the recent move to Swoole as Server, all the necessary
            calculations are done and cached before the server starts.
            <br />
            You can choose to bootstrap the app from a different module via
            $app->boot('---module here---') which represents an entire
            presentation layer. Please try your hands on Swoole with PHP.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="grid-2 padding-sm">
          <div
            class="adx-card adx-rounder adx-flat bg-black"
            style="
              background-image: url('/assets/images/developer/controller.png');
            "
          >
            <h2 class="title text-normal">
              Controller Uses Attributes for Routing
              <br />
              <small>
                Action/Method for routes returns Psr7/Message/ResponseInterface
              </small>
            </h2>
          </div>
          <div
            class="adx-card adx-rounder adx-flat bg-black"
            style="background-image: url('/assets/images/developer/action.png')"
          >
            <h2 class="title text-normal">
              HTTP Attributes ataches a request method to a controller's
              method/action.
              <br />
              <br />
              <small>
                Binding Attributes like #[FromRoute] and #[FromBody] takes
                values from the request and pass them as parameters to the
                method.
              </small>
            </h2>
          </div>
          <div class="adx-card adx-rounder adx-flat bg-black">
            <div class="text-size18 text-normal">
              <h2 class="title">Quick Intro</h2>
              <div class="adx-list">
                <div class="adx-item">
                  <h3>Start with composer</h3>
                  <small>
                    composer create-project spatial/spatial webapi
                  </small>
                </div>

                <div class="adx-item">
                  <h3>Http Verb</h3>
                  <small>
                    #[HttpGet] #[HttpPost] #[HttpPut] #[HttpDelete] #[HttpHead]
                    #[HttpPatch]
                  </small>
                </div>
                <div class="adx-item">
                  <h3>Reserved routing names</h3>
                  <small> action area controller handler page </small>
                </div>
                <div class="adx-item">
                  <h3>Route templates</h3>
                  <small>
                    All the HTTP verb templates are route templates.
                    <br />
                    #[Route]
                  </small>
                  <!-- api/{controller}/{id} -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="adx-card adx-rounder adx-flat bg-black"
            style="
              background-image: url('/assets/images/developer/request.png');
            "
          >
            <h2 class="title text-normal">A Quick Http Requests</h2>
          </div>
          <div
            class="adx-card adx-rounder adx-flat bg-black"
            style="
              background-image: url('/assets/images/developer/response.png');
            "
          >
            <h2 class="title text-normal">
              Responses - Spatial's BridgeManager converts the Psr7 response to
              Swoole Response
            </h2>
          </div>
          <div class="adx-card adx-rounder adx-flat bg-black">
            <div class="text-size18 text-normal">
              <h2 class="title">Binding Attributes</h2>
              <div class="adx-list">
                <div class="adx-item">
                  <h3>#[FromBody]</h3>
                  passes the http request post content
                </div>
                <div class="adx-item">
                  <h3>#[FromQuery]</h3>
                  passes the http request url params. eg ?page=1
                </div>
                <div class="adx-item">
                  <h3>#[FromRoute]</h3>
                  passes the route template params. eg
                  <!-- api/{controller}/{id} -->
                </div>
                <div class="adx-item">
                  <h3>#[FromForm]</h3>
                  passes the http request Form / File
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div
          class="adx-card adx-flat text-center bg-black"
          style="border-radius: 24px"
        >
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">airSuite for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/suite">
              Learn about airSuite in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear" routerLink="/business">
              Add your business to the directory>
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
