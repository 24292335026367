<section class="adx-section ">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-divider">
          Sponsored
        </div>
        <div class="sponsor-cover">
          <div class="adx-card adx-ellipse sponsor-grid" >
            <div class="bg-dark">

            </div>
            <div class="bg-dark">

            </div>
            <div class="bg-dark">

            </div>
            <div class="bg-dark">

            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</section>
