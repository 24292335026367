<div class="bg-pinkx category {{ theme }} padding">
  <div class="category-icon bg-special">
    <i class="axr ax-{{ icon }} ax-2x"></i>
  </div>

  <header class="adx-header adx-flat">
    <h2 class="text-normal" style='font-weight:500;'>{{ title }}</h2>
    <p>{{ description }}</p>
  </header>

  <div class="category-data">
    <ng-content></ng-content>
  </div>
  <footer>
    <button (click)="onMoreClicked()" class="adx-btn adx-sm adx-round btn-pink outline">
      {{ buttonName }}
    </button>
  </footer>
</div>
