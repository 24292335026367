import { Injectable, EventEmitter } from '@angular/core';
import { Profile, SocialProfile } from '../model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  defaultProfileInfo = new Profile();
  profileInfo = new EventEmitter<Profile>();
  defaultSocialProfile = new SocialProfile();
  socialProfile = new EventEmitter<SocialProfile>();
  constructor() { }



  setProfileInfo(data: Profile) {
    this.defaultProfileInfo = data;
    this.profileInfo.emit(data);
  }
  setSocialProfile(data: SocialProfile) {
    this.defaultSocialProfile = data;
    this.socialProfile.emit(data);
  }

}
