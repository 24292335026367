<div class="ui-avatar {{ size }}">
  <div class="image" [routerLink]="['/profile/', data.person.username]">
    <div  class="adx-img">
      <img [lazyLoad]="userImage" [alt]="data.person.username" />
    </div>
  </div>
  <div class="person" [routerLink]="['/profile/', data.person.username]">
    <span class="title"> {{ tagline }} </span>
    <span class="value"> {{ data.person.name }} </span>
  </div>
  <div class="other" *ngIf="size === 'lg' && showOptions.comment">
    <span class="title"> {{ data.commentCount }} Comments </span>
    <span class="value">
      Join the Conversation <i class="fa fa-arrow-right color-special"></i>
    </span>
  </div>
  <div class="date" *ngIf="size === 'lg' && showOptions.created">
    <span class="title"> Published </span>
    <span class="value"> {{ data.created * 1000 | date }} </span>
  </div>
  <div class="date" *ngIf="size === 'lg' && showOptions.modified">
    <span class="title"> Updated </span>
    <span class="value"> {{ data.modified * 1000 | date }} </span>
  </div>
</div>
<!-- {{ data | json }} -->
