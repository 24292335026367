<div class="adx-avatar adx-flat adx-block">
  <div class="adx-img bg-black" style="border-radius: 6px;">
    <img [src]="photo" alt="" />
  </div>

  <h2>{{ data.businessProduct.product.name }}</h2>
  <p>
    {{ data.businessProduct.product.code }}
  </p>
</div>
<div>
  <form #entityForm="ngForm" class="margin:0; padding:0;" [class.is-loading]="isLoading">
    <div class="adx-divider">Cost Price</div>
    <div class="clearfix"></div>

    <div class="adx-row">
      <div class="adx-colx-6">
        <div>
          <div class="adx-input adx-label adx-round">
            <label for="pack_cost">Pack Cost Price</label>
            <input type="number" [(ngModel)]="data.packCostPrice" name="pack_cost" step=".01" />
          </div>
        </div>
      </div>
      <div class="adx-colx-6">
        <div class="adx-input adx-label adx-round">
          <label for="unit_cost">Unit Cost Price</label>
          <input type="number" [(ngModel)]="data.unitCostPrice" name="unit_cost" step=".01" />
        </div>
      </div>
    </div>


    <div class="clearfix"></div>
    <div class="adx-divider">Selling Price</div>
    <div class="adx-row">
      <div class="adx-colx-6">
        <div class="adx-input adx-label adx-round">
          <label for="pack_cost">(Retail) Pack Price</label>
          <input type="number" [(ngModel)]="data.packRetailPrice" name="retail_pack_price" step=".01" />
        </div>
      </div>
      <div class="adx-colx-6">
        <div class="adx-input adx-label adx-round">
          <label for="pack_cost">(Retail) Unit Price</label>
          <input type="number" [(ngModel)]="data.unitRetailPrice" name="retail_unit_price" step=".01" />
        </div>
      </div>
    </div>
    <div class="adx-row">
      <div class="adx-colx-6">
        <div class="adx-input adx-label adx-round">
          <label for="wholesale_pack_price">(Wholesale) Pack Price</label>
          <input type="number" [(ngModel)]="data.packWholesalePrice" name="wholesale_pack_price" step=".01" />
        </div>
      </div>
      <div class="adx-colx-6">
        <div class="adx-input adx-label adx-round">
          <label for="wholesale_unit_price">(Wholesale) Unit Price</label>
          <input type="number" [(ngModel)]="data.unitWholesalePrice" name="wholesale_unit_price" step=".01" />
        </div>
      </div>
    </div>

    <br />
    <br />

    <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="onCloseDetail()">
      Cancel
    </button>

    <span class="float-right">
      <button *ngIf="isEdit" [disabled]="entityForm.invalid || isLoading" class="adx-btn adx-round adx-sm btn-blue"
        (click)="onSubmit(false)" style="margin-right: 0; margin-left: 0;">
        Edit Price
      </button>
      <button [disabled]="entityForm.invalid || isLoading" class="adx-btn adx-round adx-sm btn-tgreen"
        (click)="onSubmit(true)">
        Set <span *ngIf="!isEdit">New</span> Price
      </button>
    </span>
  </form>
</div>
