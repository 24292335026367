import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppSettingService, BusinessProduct, IPrice, NotifyService, SuiteApiService, SuiteProductCategoryEnum, SuiteProductTypeEnum } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {

  @Input() theme = 'light'
  @Input() data: BusinessProduct[] = [];
  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];

  @Output() detailRoute = new EventEmitter();
  @Output() addToCart = new EventEmitter<BusinessProduct>();

  @Input() storePriceType: string = 'retail';
  @Input() shopType: string = 'sales';
  currency: string;

  productTypeEnum: typeof SuiteProductTypeEnum = SuiteProductTypeEnum;
  productCategoryEnum: typeof SuiteProductCategoryEnum = SuiteProductCategoryEnum;

  constructor(
    private _appSetting: AppSettingService,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService
  ) { }

  ngOnInit() {

    this.currency = this._suiteApi.getActiveBusiness().currency;

    this._appSetting.storePriceChange.subscribe(res => {
      this.storePriceType = res;
      this._resetProductUnitsAndPacks();
    });



  }

  private _resetProductUnitsAndPacks() {
    this.data.forEach(x => {
      x.units = 0;
      x.packs = 0;
    })
  }

  onSetPrice(price?: IPrice, storePriceType?: string) {
    let defaultPrice = 0;
    if (price) {
      switch (storePriceType) {
        case 'cost':
        case 'stock':
        case 'damages':
          defaultPrice = price.unitCostPrice;
          break;

        case 'wholesale':
          defaultPrice = price.unitWholesalePrice;
          break;

        default:
          defaultPrice = price.unitRetailPrice;
          break;
      }
    }

    return defaultPrice;

  }

  onDetailRoute(link: string, title: string = 'View Detail') {
    const routeData = { link: link, title: title };
    this.detailRoute.emit(routeData);

  }

  onAddToCart(item: BusinessProduct) {
    if (
      (this.shopType == 'sales' || this.shopType == 'damages')  &&
      (item.stocks.units < 1 && item.stocks.packs < 1)
    ) {
      this._notify.error('Product is out of stock');
      return;
    }

    item.units = 1;
    if (item.units === 0 && item.packs === 0) {
      this._notify.error('Please add item units or packs to add to cart');
      return;
    }

    if (this.storePriceType !== 'stock') {
      if (item.stocks.units < item.units) {
        if (item.stocks.packs <= 0) {
          this._notify.error('There are no packs to extract products units from!');
          return;
        }
        item.stocks.units += item.product.unitsInPack;
        item.stocks.packs--;
      }
      item.stocks.units -= item.units;

      if (item.stocks.packs < item.packs) {
        this._notify.error('Available packs are lesser than what are being sold');
        return;
      }
      item.stocks.packs -= item.packs;
    } else {

      item.stocks.units += item.units;
      item.stocks.packs += item.packs;
    }

    this.addToCart.emit(item);
  }

}
