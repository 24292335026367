import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';


@Component({
  selector: 'galaxy-adx-btn-group',
  templateUrl: './ad-btn-group.component.html',
  styleUrls: ['./ad-btn-group.component.scss']
})
export class AdBtnGroupComponent implements OnInit, OnDestroy {

  @Input() menu: { name: string; icon?: string; route?: string }[] = [];
  @Input() activeClass: string = 'adx-shadow';
  @Input() shape: 'block' | 'round' | 'rounder' = 'round';
  @Input() showBackgroundColor: boolean = false;
  @Input() showIcons: boolean = true;
  @Input() autoShowIcons: boolean = false;
  @Input() reverseActiveColor: boolean = true;
  @Input() isIconsOnly: boolean = false;
  @Input() routeByEvent: boolean = false;
  @Input() currentMenu?: string = null;

  @Output() menuClicked = new EventEmitter();

  activeId: number = 0;

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    // get theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

    // set this.currentMenu
    this.currentMenu = this.currentMenu ?? this.menu[0].name.toLowerCase();

  }


  onMenuClicked(item: { name: string; icon: string; route: string }, index: number) {
    this.activeId = index;
    this.menuClicked.emit(item);
  }


  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
