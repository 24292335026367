<div class="wrapper-fluid" *ngIf="showOptions">
  <div class="adx-row">
    <div class="adx-colx-12">
      <div class="grid-header">
        <div class="adx-card adx-flat">
          <div class="menu-flex">
            <div class="menu">
              <galaxy-adx-btn-group [menu]="menu" [routeByEvent]="true" [reverseActiveColor]="false" [showIcons]="false"
                (menuClicked)="onSortData($event.name.toLowerCase())"></galaxy-adx-btn-group>
            </div>
            <div class="action remove-xxs">
              <span class="float-right input-flex">
                <button class="adx-btn adx-round adx-icon outline adx-flat" (click)="onSortData(currentMenu)" [ngClass]="{
                    'btn-default': theme == 'dark',
                    'btn-dark': theme == 'light'
                  }">
                  <i class="fa fa-repeat"></i>
                </button>
                <div class="adx-input adx-round">
                  <select (change)="onReload()" [(ngModel)]="mediumId" name="mediumId" id="">
                    <option value="0">All Media</option>
                    <option value="79">Digital 2D</option>
                    <option value="80">Digital 3D</option>
                    <option value="81">Traditional 2D</option>
                    <option value="82">Traditional 3D</option>
                  </select>
                </div>
                <div class="adx-input adx-round">
                  <select (change)="onReload()" [(ngModel)]="subjectId" name="subjectId" id="">
                    <option value="0">All Subject Matter</option>
                    <option *ngFor="let item of subjectMatter" [value]="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div [class.is-loading]="isLoading">
  <div *ngIf="isGrid; then grid; else masonry"></div>
</div>
<div class="clearfix" *ngIf="isLoading"></div>
<div class="adx-card adx-flat is-loading" *ngIf="isLoading && page > 1">
  <p></p>
</div>

<div class="adx-card adx-flat" *ngIf="!isLoading && data.length === 0">
  <p class="text-center text-size18" style="opacity: 0.4">
    <i class="axr ax-photos ax-2x"></i> <br />
    <br />
    No Artwork Found
  </p>
</div>

<app-pagination *ngIf="!isLoading" [page]="page" [total]="total" [limit]="pageSize" [infinitScroll]="true"
  [infinitScrollContainer]="'.menu-flex'" (pageClicked)="onPaginateData($event)"></app-pagination>

<ng-template #grid>
  <div class="adx-grid" [class.mini]="isMini">
    <div *ngFor="let item of data; index as i">
      <galaxy-media-card (addToCollection)="onShowModalClicked($event, 2)" (shareEntity)="onShowModalClicked($event, 1)"
        (viewEntity)="onImageClicked($event, i)" [showDetail]="showDetail" [entity]="item" [isPotrait]="true"
        [showBookmark]="showBookmark"></galaxy-media-card>
    </div>
  </div>
</ng-template>

<ng-template #masonry>
  <!-- <div class="adx-masonry" [class.mini]="isMini"> -->
  <div class="ngx-masonry" [class.mini]="isMini">
    <ngx-masonry [options]="masonryOptions">
      <div ngxMasonryItem class="masonry-item" *ngFor="let item of data; index as i" [class.mini]="isMini">
        <galaxy-media-card (addToCollection)="onShowModalClicked($event, 2)"
          (shareEntity)="onShowModalClicked($event, 1)" (viewEntity)="onImageClicked($event, i)"
          [showDetail]="showDetail" [entity]="item" [showBookmark]="showBookmark"></galaxy-media-card>
      </div>
    </ngx-masonry>
  </div>
  <!-- </div> -->
</ng-template>

<!-- modals -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showModal" [showPadding]="false">
  <div *ngIf="modalNumber === 1; then shareModal; else collectionModal"></div>
</galaxy-modal>

<!-- share & add to collection -->
<ng-template #shareModal>
  <galaxy-media-share *ngIf="showModal" [title]="entity.title" [entity]="entity" [link]="getEntityLink()">
  </galaxy-media-share>
</ng-template>

<ng-template #collectionModal>
  <galaxy-media-collection-select *ngIf="showModal" [entity]="entity" [collectionType]="84" [entityType]="'photo'"
    [personId]="viewerId"></galaxy-media-collection-select>
</ng-template>

<!-- view image -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showImageModal" [fixedWidth]="false"
  [width]="70" [showPadding]="false">
  <div class="paddingx">
    <galaxy-media-card-modal *ngIf="showImageModal" [entity]="entitySelected"></galaxy-media-card-modal>
  </div>

  <galaxy-media-navigator [showNavBack]="prevProject" [showNavNext]="nextProject"
    (navBack)="onImageClicked(prevProject, prevProjectIndex)" (navNext)="onImageClicked(nextProject, nextProjectIndex)">
  </galaxy-media-navigator>
</galaxy-modal>
