<div class="adx-card adx-flat adx-ellipse text-center">
  <h2 class="text-normal" style="font-size: 30px; margin-bottom: 0;">
    Create your profile
  </h2>
  <p class="text-size18">
    This is how you will be presented to other people.
    <br />
    <small class="color-pink x">
      More personalization options are available later.
    </small>
  </p>
  <div class="clearfix"></div>
  <form
    class="adx-form input-pink center-form"
    #profile="ngForm"
    (ngSubmit)="onUpdateInfo()"
  >
    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span>
        <i class="axr ax-user"></i>
      </span>
      <input
        type="text"
        placeholder="Enter First name*"
        min="4"
        [(ngModel)]="user.othername"
        name="user.othername"
        required
        [readOnly]="isLoading"
      />
    </div>

    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span>
        <i class="axr ax-user"></i>
      </span>
      <input
        type="text"
        placeholder="Enter Last name*"
        min="4"
        [(ngModel)]="user.surname"
        name="user.surname"
        required
        [readOnly]="isLoading"
      />
    </div>

    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span>
        <i class="axr ax-circle"></i>
      </span>
      <select
        [(ngModel)]="user.gender"
        name="user.gender"
        required
        [disabled]="isLoading"
      >
        <option value="">Choose Your Gender*</option>
        <option value="1">Male</option>
        <option value="2">Female</option>
        <option value="3">Other</option>
      </select>
    </div>

    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span>
        <i class="axr ax-calendar"></i>
      </span>
      <input
        type="date"
        placeholder="Birth Date"
        [(ngModel)]="user.birthDate"
        name="user.birthDate"
        required
        [readOnly]="isLoading"
      />
    </div>

    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span>
        <i class="axr ax-phone"></i>
      </span>
      <input
        type="tel"
        placeholder="Phone Number"
        [(ngModel)]="user.phoneOne"
        name="user.phoneOne"
        required
        [readOnly]="isLoading"
      />
    </div>

    <p style="padding: 0 16px;">
      <button
        class="adx-btn adx-full adx-sm btn-pink adx-round"
        [style.is-loading]="isLoading"
        type="submit"
        [disabled]="isLoading || !profile.valid"
      >
        Continue
      </button>
    </p>
  </form>
</div>
