import { Component, OnInit } from '@angular/core';
import {  IGroup, SuiteApiService } from '@galaxy/entity-api';

@Component({
  selector: 'app-suite-apps',
  templateUrl: './suite-apps.component.html',
  styleUrls: ['./suite-apps.component.scss']
})
export class SuiteAppsComponent implements OnInit {

  apps: IGroup[] = [];
  constructor(private _suiteApi: SuiteApiService) { }

  ngOnInit() {

    this.apps = this._suiteApi.getDexApps();
  }

}
