<app-ui-entity-activity>

  <div id="entity" *ngIf="isAccount">
    <app-person-actvity [person]="client" [appId]="appId"></app-person-actvity>
  </div>

  <!-- account transactions -->
  <ui-data-grid [title]="title" (searchClicked)="onSearchData($event)" (closeDetail)="onCloseRoute()"
    [showDetail]="showDetail" [detail]="detailTitle" [paginatorPage]="page" [paginatorTotal]="totalData"
    [paginatorLimit]="pageSize" (pageinateData)="onPaginateData($event)">
    <div class="header-btns">
      <button class="adx-btn btn-default adx-icon adx-round" (click)="onReloadData()">
        <i class="axl ax-refresh"></i>
      </button>
    </div>
    <div class="data">
      <galaxy-adaptive-card [classes]="'adx-table adx-sm'" [miniPadding]=true [autoFull]=true [shape]="'rounded'"
        [isFlat]="true">
        <div *ngIf="isLoading" class="adx-card adx-flat is-loading">
          <p></p>
        </div>

        <!-- data here -->
        <table [class]="theme">
          <tr *ngFor="let item of data">

            <td *ngIf="!isAccount">
              <div style="display: flex">
                <galaxy-entity-image [image]="item.businessEntity.entity.image" [isRound]="true"></galaxy-entity-image>
                <div>
                  {{ item.businessEntity.entity.name | maxLength: 32 }}
                  <br />
                  <small> <i class="axr ax-phone"></i> : {{ item.businessEntity.entity.phoneOne }} </small>
                </div>
              </div>
            </td>

            <td>
              {{ item.created *1000 | date }}
              <br />
              <small>
                <i class="axl ax-clock"></i>
                {{ item.created *1000 | date :'h:m:s' }}
              </small>
            </td>

            <td *ngIf="!isAttend">
              <b>
                {{ item.businessBranchDepartment.businessDepartment.name }}
              </b>
              <br />
              <small [class.color-pink]="businessBranchId > 0">
                {{ businessBranchId === 0 ?
                item.businessBranchDepartment.businessBranch.name :
                '@' + (item.reservedDate * 1000
                | date: 'EEE, MMM dd, yyyy - hh:mm')
                }}
              </small>
            </td>

            <td *ngIf="businessBranchId === 0">
              <i class="axr ax-bell "></i> {{ item.type }}
              <br />
              <small class="color-pink">
                <i class="axl ax-corner-right" style="opacity: 0.3;"></i>
                &#64; {{
                item.reservedDate * 1000
                | date: 'EEE, MMM dd, yyyy - hh:mm'
                }}
              </small>
            </td>

            <!-- show transaction on account and generall view not when attending -->
            <td *ngIf="!isAttend && item.transaction">
              <!-- <i class="axr ax-circle color-blue"></i> -->
              <b class="color-pink">
                {{currency}} {{ (item.transaction.amount | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.transaction.amount | number: '1.2-2').split('.')[1] }}</sup>
              </b>

              <br />
              <small>
                <i class="fal fa-long-arrow-right" style="opacity: 0.3;"></i>
                <!-- {{ item.city }}, {{ item.country }} -->
                {{ (item.transaction.paid | number: '1.2-2').split('.')[0] }}
                <sup>{{ (item.transaction.paid | number: '1.2-2').split('.')[1] }}</sup> paid
              </small>
            </td>

            <td class="action">
              <app-action-btns>
                <!-- button to signified if appointment has been completed -->
                <button *ngIf="!isAttend" class="adx-btn active adx-ellipse adx-icon " [ngClass]="{
                  'btn-tgreen': item.hasAttend,
                  'btn-yellow': !item.hasAttend
              }" [title]="item.hasAttend  ? 'Served':'Pending'">
                  <i class="axr" [ngClass]="{
                    'ax-checks': item.hasAttend,
                    'ax-loader': !item.hasAttend
                }"></i>
                </button>

                <!-- edit appointment/ profile if its a doctor -->

                <button *ngIf="
                    (!isAttend && !item.hasAttend) ||
                    (isAttend && item.hasAttend)
                  " class="adx-btn adx-icon adx-flat clear" (click)="
                    onDetailRoute('edit/' + item.id, 'Edit Appointment')
                  ">
                  <i class="axl ax-edit"></i>
                </button>

                <!-- appointment action btns -->
                <span *ngIf="(isAttend && !item.hasAttend) || !isAttend">
                  <button *ngFor="let btnItem of actionButtons" class="adx-btn adx-icon adx-flat clear" (click)="
                    onDetailRoute(btnItem.route + item.id, btnItem.title)
                  ">
                    <i class="axl ax-{{ btnItem.icon }} color-pink"></i>
                  </button>
                </span>

              </app-action-btns>

            </td>
          </tr>
        </table>

        <!-- data ends -->
        <app-no-data *ngIf="!isLoading && data.length === 0" [label]="'Reservations'"></app-no-data>

      </galaxy-adaptive-card>
      <br>
      <!-- pagination start -->
      <div style="display: flex;">
        <div style="flex: 1;">
          <button class="adx-btn btn-dark adx-round adx-flat" style="margin-top: 0;">
            Total: {{ totalData }}
          </button>
        </div>
        <galaxy-loadx-pagination *ngIf="!isLoading" [page]="page" [total]="totalData" [limit]="pageSize"
          (pageClicked)="onPaginate($event)"></galaxy-loadx-pagination>
      </div>
      <!-- pagination ends -->
    </div>

    <div class="detail">
      <router-outlet></router-outlet>
    </div>
  </ui-data-grid>

</app-ui-entity-activity>
