import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranchDepartment, BusinessBranchDepartment } from './business-department.model';
import { IGroup, Group } from '../ori/group.model';
import { Controller } from '../decorators';
import { ITransaction, Transaction } from './transaction.model';
import { AppointmentTypeEnum, StatusEnum } from '../../enums';

export interface IReservation {

  id: number;
  businessEntity: IBusinessEntity;
  type: AppointmentTypeEnum;
  businessBranchDepartment: IBusinessBranchDepartment;
  reservedDate: number;
  status: StatusEnum;
  app: IGroup;
  hasAttend: boolean;
  transaction: ITransaction;
  created: number;
  modified?: number;
  createdBy: BusinessEntity;
  modifiedBy?: BusinessEntity;

}

@Controller({ name: 'Reservation' })
export class Reservation implements IReservation {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public type = AppointmentTypeEnum.NEW,
    public businessBranchDepartment = new BusinessBranchDepartment,
    public reservedDate = 0,
    public status =  StatusEnum.PUBLISHED,
    public app = new Group,
    public hasAttend = false,
    public transaction = new Transaction,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {
    // this.status.id = 2; //published
    // this.type.id = 388;//new appointment
    this.app.id = 13; // hospital appointment

  }
}


export interface IReservationProfile {

  id: number;
  title: string;
  description: string;
  testRequest?: { lab: boolean; xray: boolean; physio: boolean, ent: boolean };
  solution?: { prescription: boolean; admission: boolean; sugery: boolean, referral: boolean };
  lab?: string;
  xray?: string;
  exam?: string;
  remark: string;
  isClassified: boolean;

  reservation: IReservation;
  businessEntity: IBusinessEntity;
  transaction?: IBusinessEntity;
  created?: number;
  modified?: number;
  createdBy: BusinessEntity;
  modifiedBy?: BusinessEntity;

}


export class ReservationProfile implements IReservationProfile {
  constructor(
    public id = 0,
    public title = '',
    public description = '',
    public remark = '',
    public isClassified = false,
    public reservation = new Reservation,
    public businessEntity = new BusinessEntity,
    public createdBy = new BusinessEntity,
  ) { }
}
