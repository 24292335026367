<button (click)='onToggleShowBtns()' class="adx-btn adx-round adx-icon btn-dark adx-flat action-btn" [ngClass]="{
  'btn-dark': theme === 'dark',
  'btn-default': theme === 'light'
}">
  <div class="axr " [ngClass]="{'ax-menu':!showBtns, 'ax-chevron-right':showBtns}" >
  </div>
</button>
<div class='adx-action-btns adx-content' [ngClass]="{
  'show':showBtns,
  'bg-dark': theme === 'dark',
  'bg-white': theme === 'light'
}">
  <ng-content></ng-content>
</div>
