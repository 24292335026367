import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-adaptive-card',
  templateUrl: './adaptive-card.component.html',
  styleUrls: ['./adaptive-card.component.scss']
})
export class AdaptiveCardComponent implements OnInit, OnDestroy {
  @Input() theme: string = '';
  @Input() class: string = '';
  @Input() classes: string = '';
  @Input() showPadding: boolean = true;
  @Input() miniPadding: boolean = false;
  @Input() shape: string = 'round';
  @Input() transparentBg: boolean = false;
  @Input() overflow: boolean = false;
  @Input() isFlow: boolean = false;
  @Input() isFull: boolean = false;
  @Input() isHeightFull: boolean = false;
  @Input() isBlur: boolean = false;
  @Input() blurX: boolean = false;
  @Input() isFlat: boolean = false;
  @Input() isNeu: boolean = false;
  @Input() softShadow: boolean = true;
  @Input() showShadowOnHover: boolean = false;
  @Input() reverseTheme: boolean = false;
  @Input() overflowVisible: boolean = false;
  @Input() autoTransparent: boolean = false;
  @Input() autoDimBlack: boolean = false;
  @Input() autoFull: boolean = false;
  @Input() autoMiniPadding: boolean = false;
  @Input() autoFlat: boolean = false;
  themeUpdated$!: Subscription;
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
