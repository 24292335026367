import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifyService } from '@galaxy/entity-api';

import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string = '';
  connectionStatus: string = '';

  constructor(private _notify: NotifyService) { }

  ngOnInit() {
    /**
  * Get the online/offline status from browser window
  */
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      // console.log('Online...');
      this._notify.success(this.connectionStatusMessage);
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      // console.log('Offline...');
      this._notify.info(this.connectionStatusMessage);
    }));
  }


  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
