
        <div class="adx-btn-groupp bg-gradient">
          <button class="adx-btn  adx-xs btn-dark adx-icon adx-block ">
            All
          </button>
          <!-- &nbsp; -->
          <button
            class="adx-btn  adx-xs btn-dark adx-icon adx-block "
            title="Activity"
          >
            <i class="fal fa-heart-rate"></i>
          </button>
          <!-- &nbsp; -->
          <button
            class="adx-btn  adx-xs btn-dark adx-icon adx-block "
            title="Music"
          >
            <i class="fal fa-music"></i>
          </button>
          <!-- &nbsp; -->
          <button
            class="adx-btn  adx-xs btn-dark adx-icon adx-block "
            title="Movie"
          >
            <i class="fal fa-video"></i>
          </button>
          <!-- &nbsp; -->
          <button class="adx-btn  adx-xs btn-dark adx-icon adx-block " title="Blog">
            <i class="fal fa-book"></i>
          </button>
          <!-- &nbsp; -->
          <button
            class="adx-btn  adx-xs btn-dark adx-icon adx-block "
            title="Follows"
          >
            <i class="fal fa-users"></i>
          </button>
          <!-- &nbsp; -->
          <button class="adx-btn  adx-xs btn-dark adx-icon adx-block " title="Game">
            <i class="fal fa-gamepad"></i>
          </button>
        </div>

        <div class="adx-list standard">
          <!-- <div class="adx-item">Hello</div> -->
          <div class="adx-avatar adx-xs adx-block">
            <div class="adx-img bg-pink"></div>
            <h2>Melt released new track</h2>
          </div>

          <div class="adx-avatar adx-xs adx-block">
            <div class="adx-img bg-pink"></div>
            <h2>Melt released new track</h2>
          </div>

          <div class="adx-avatar adx-xs adx-block">
            <div class="adx-img bg-pink"></div>
            <h2>Melt released new track</h2>
          </div>

          <div class="adx-avatar adx-xs adx-block">
            <div class="adx-img bg-pink"></div>
            <h2>Melt released new track</h2>
          </div>

          <div class="adx-avatar adx-xs adx-block">
            <div class="adx-img bg-pink"></div>
            <h2>Melt released new track</h2>
          </div>
        </div>
