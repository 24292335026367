import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppSettingService, CallService, Conversation, EmbedService, IMessage, MessengerSocketService, Person, StreamSocketService } from '@galaxy/entity-api';

@Component({
  selector: 'app-socket-stream',
  templateUrl: './socket-stream.component.html',
  styleUrls: ['./socket-stream.component.scss']
})
export class SocketStreamComponent implements OnInit {
  theme: string;


  @ViewChild('viewerVideo') viewerVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('localAudioAlert') localAudioAlert: ElementRef<HTMLAudioElement>;
  isActive: boolean = false;

  showAnswerNotification = false;

  offerData;

  signalMessageData: IMessage;


  avatar: SafeResourceUrl = '';
  caller: Person = new Person;

  rAvatar: SafeResourceUrl = '';
  reciepient: Person = new Person;

  callAnswered = false;



  constructor(
    private _appSetting: AppSettingService,
    private _signalingService: MessengerSocketService,
    private _embed: EmbedService,
    private _callService: CallService,
  ) {
  }

  ngOnInit() {
    this._callService.initiateCall.subscribe(res => {
      this.isActive = res.status;
      if (this.isActive) {
        this.signalMessageData = res.message;
        this.reciepient = res.message.conversation.person2;
        this.rAvatar = this._embed.imgUrl(this.reciepient.image, 'md');
        this.makeCall();
      }
    });

    this._signalingService.fromEvent('newMessage').subscribe(payload => {
      if (payload['type'] === 1) {
        this._handleMessage(payload);
        // console.log(payload);
      }
      // console.log(payload);
    });
  }



  async makeCall(): Promise<void> {
    this.callAnswered = false;
    await this._callService.makeCall(this.signalMessageData, this.viewerVideo, this.remoteVideo);
  }

  async acceptCall() {
    this.showAnswerNotification = false;
    this.callAnswered = true;
    this.localAudioAlert.nativeElement.pause();
    this.isActive = true;

    await this._callService.handleOffer(this.offerData, this.viewerVideo, this.remoteVideo);
  }

  cancelCall() {
    this.showAnswerNotification = false;
    this.localAudioAlert.nativeElement.pause();
    this.isActive = false;
    this._callService.initiateCall.emit({ status: false });

    this._callService.terminateCall();
    // send busy
  }

  endCall() {
    this.isActive = false;
    this._callService.initiateCall.emit({ status: false });
    this._callService.terminateCall();
  }

  addParticipant() {

  }

  muteMicrophone() {
    this.viewerVideo.nativeElement.volume = this.viewerVideo.nativeElement.muted ? 1 : 0;
  }

  ToggleVideo() {

  }


  notifiyCall(messagePayload: IMessage) {
    this.avatar = this._embed.imgUrl(messagePayload.person.image, 'sm');
    this.caller = messagePayload.person;

    this.showAnswerNotification = true;
    this.localAudioAlert.nativeElement.play();

    // set Conversation
    this._callService.conversationMessageSignal = messagePayload;

    setTimeout(() => {
      if (this.showAnswerNotification) {
        this.cancelCall();
      }
    }, 36000); // 1 min call
  }

  private async _handleMessage(messagePayload): Promise<void> {
    switch (messagePayload.data.type) {
      case 'offer':
        // await this._callService.handleOffer(data.offer, this.remoteVideo);
        if (this.isActive) {
          // tell person that person is in call
        } else {
          this.offerData = messagePayload.data.offer;
          this.notifiyCall(messagePayload);
        }
        break;
      case 'answer':
        // alert('call answered!');
        this.callAnswered = true;
        await this._callService.handleAnswer(messagePayload.data.answer);

        break;

      case 'candidate':
        await this._callService.handleCandidate(messagePayload.data.candidate);
        break;

      case 'close':
        if (this.isActive) {
          // wiat a minute to make sure that the state is still disconnected, so that in case of bad NetworkInformation, call can resume
          this.isActive = false;
          await this._callService.terminateCall();
        }
        break;

      default:
        break;
    }
  }


}
