import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FeedPoll, IPoll } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-poll-builder',
  templateUrl: './poll-builder.component.html',
  styleUrls: ['./poll-builder.component.scss']
})
export class PollBuilderComponent implements OnInit {

  days: number[] = [];
  hours: number[] = [];
  minutes: number[] = [];
  defaultMinutes: number[] = [];
  daysSelected: number = 1;
  hoursSelected: number = 0;
  minutesSelected: number = 0;

  polls: FeedPoll[] = [];

  minPollLength: number = 2;
  maxPollLength: number = 4;

  @Output() pollChanged = new EventEmitter<IPoll[]>();
  @Output() pollLengthChanged = new EventEmitter();
  constructor() { }

  ngOnInit() {
    // polls
    for (let index = 0; index < this.minPollLength; index++) {
      this.polls.push(new FeedPoll);
    }
    this.pollChanged.emit(this.polls);

    // days
    for (let index = 0; index < 7; index++) {
      this.days.push(index);
    }

    // this.hours
    for (let index = 0; index < 24; index++) {
      this.hours.push(index);

      // minues
      for (let index = 0; index < 60; index++) {
        this.defaultMinutes.push(index);
      }
      this.minutes = this.defaultMinutes;

    }


    this.onPollLengthChange('days');



    // console.log(this.days);
  }

  onAddPoll() {
    this.polls.push(new FeedPoll);
    this.pollChanged.emit(this.polls);

  }
  onPollUpdated() {
    this.pollChanged.emit(this.polls);
  }
  onRemovePull(item) {
    if (this.polls.length >= this.minPollLength) {
      // console.log('removing poll of index ', item);
      this.polls = this.polls.filter((obj) => {
        // lets see the data
        // console.log('in the filder', obj)
        return obj !== item
      });

      this.pollChanged.emit(this.polls);
    }
  }

  onPollLengthChange(whoChanged: string) {
    // console.log('poll length changed');

    const isPollLengthZero =
      this.daysSelected.toString() === '0' &&
      this.hoursSelected.toString() === '0' &&
      this.minutesSelected.toString() === '0';

    const isMinutesLessThan5 = this.daysSelected.toString() === '0' &&
      this.hoursSelected.toString() === '0' && this.minutesSelected < 5;

    if (whoChanged === 'days' && isPollLengthZero) {
      this.hoursSelected = 1;
    } if (whoChanged === 'hours' && (isPollLengthZero || isMinutesLessThan5)) {
      this.minutes = [];
      // minues
      for (let index = 5; index < 60; index++) {
        this.minutes.push(index);
      }
      this.minutesSelected = 5;
    } else {
      this.minutes = this.defaultMinutes;
    }

    const pollLength = {
      days: this.daysSelected,
      hours: this.hoursSelected,
      minutes: this.minutesSelected
    };

    // console.log(pollLength);

    this.pollLengthChanged.emit(pollLength);
  }


}
