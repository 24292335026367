<div class="cold-boot-bg" [ngClass]="{
    'bg-white': theme === 'light',
    'bg-darker': theme === 'dark'
  }"></div>
<!-- <p>
  cold-boot works!
</p> -->

<div class="cold-logo bg-pinkx" [class.invert]="theme === 'darkx'">
  <img src="assets/images/logo/artist-logo.png" class="img-responsive" alt="" />
</div>

<div *ngIf="showError" class="error-alert {{theme}}">
  <p class='text-center'>
    Something went wrong, but don’t fret — let’s give it another shot.
  </p>
  <button class="adx-btn adx-round btn-tgreen" (click)='initColdBoot()'>
    Try Again
  </button>
</div>
