<div class="adx-card adx-{{ shape }} {{ classes }}" [ngClass]="{
    'bg-dark outline':
      (theme == 'dark' && !reverseTheme) || (theme == 'light' && reverseTheme),
    'bg-white':
      (theme == 'light' && !reverseTheme) || (theme == 'dark' && reverseTheme),
    overflow: overflow,
    'adx-full': isFull,
    'adx-full-height': isHeightFull,
    flower: isFlow,
    'x-blur': blurX,
    transparent: transparentBg,
    'adx-flat': isFlat,
    'auto-flat': autoFlat,
    'soft-shadow': softShadow,
    'hover-shadow': showShadowOnHover,
    'adx-neu': isNeu,
    'auto-transparent': autoTransparent,
    'auto-dim': autoDimBlack,
    'auto-full': autoFull
  }">
  <div [ngClass]="{
      padding: showPadding && !miniPadding,
      'padding-sm': miniPadding,
      'auto-padding': autoMiniPadding,
      'auto-full': autoFull
    }">
    <ng-content></ng-content>
  </div>
</div>
