import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-platform-showcase',
  templateUrl: './platform-showcase.component.html',
  styleUrls: ['./platform-showcase.component.scss']
})
export class PlatformShowcaseComponent implements OnInit {

  @Input() index = 0;
  @Input() title: string = 'Aiira';
  @Input() data: {
    label: string,
    description: string,
    image: string,
    link: string,
    isExternal: boolean
  }[] = [];


  scrollAmount = 300;

  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
  }

  onScroll(direction: number) {
    const scrollableDiv: HTMLDivElement = document.querySelector('#CardScroller-' + this.index) as HTMLDivElement;
    if (scrollableDiv)
      scrollableDiv.scrollBy({
        left: this.scrollAmount * direction,
        behavior: 'smooth'
      })
  }

  linkClicked(item: {
    label: string,
    description: string,
    image: string,
    link: string,
    isExternal: boolean
  }) {
    if (item.isExternal) {
      window.open(item.link, '_blank').focus();
      return;
    }

    this._router.navigateByUrl(item.link);
  }
}
