import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// import { NxModule } from '@nx/angular';
import { AppSettingService, EntityApiModule } from '@galaxy/entity-api';
import { AppComponent } from './app.component';

import { MainAppModule } from './main-app/main-app.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
// import { DeviceDetectorModule } from 'ngx-device-detector';

import { LoginModule } from '@galaxy/login';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    MainAppModule,
    EntityApiModule,
    LoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    // DeviceDetectorModule

  ],
  providers: [
    AppSettingService,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
