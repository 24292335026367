import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { GenericService } from './GenericService';

@Injectable()
export class StudioService extends GenericService {
  constructor(http: HttpClient) {
    const baseUrl = '//127.0.0.1/airStudioAPI/';
    super(http, baseUrl);
  }
}
