<div class="{{ size }}">
  <div
    class="cover-img bg-dark"
    style="background-image:url(assets/images/galaxy.jpg)"
    [routerLink]="['/profile/username']"
  >
    <!-- imageInPost -->
    <div class="adx-card adx-flat adx-full text-center">
      <div class="adx-img">
        <img
          src="http://127.0.0.1/airCloud/media/images/avatar/default/default.jpg"
          alt=""
        />
      </div>
      <div class="detail">
        <span class="text-size18 name"> Julien Kaspar </span>
        <div *ngIf="!isMini">
          <span> User Status </span> <span>Armstadarm, Netherland</span>
        </div>
      </div>
    </div>
    <!-- imageInPost -->
  </div>

  <div class="reaction bg-gradient padding">
    <span class="float-left">
      <button
        (click)="onFollowClick()"
        class="adx-btn adx-round  {{
          following ? 'btn-default outline' : 'btn-' + color
        }} {{ isMini ? 'adx-icon  adx-xs' : '' }} no-margin"
      >
        <span *ngIf="!following">
          <i class="fal fa-user-plus"></i> <span class="caption"> Follow </span>
        </span>
        <span *ngIf="following">
          <i class="fal fa-check-double color-tgreen"></i>
          <span class="caption"> Following </span>
        </span>
      </button>
    </span>
    <small class="float-right">
      <span> <i class="fal fa-users"></i> &nbsp; 215 </span>
      <span *ngIf="showLikes">
        <i class="fal fa-thumbs-up"></i> &nbsp; 44
      </span>
    </small>
  </div>
</div>
