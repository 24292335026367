<!-- <galaxy-adx-flex [showTitle]="false"> -->
<!-- <div *ngFor="let item of featuredData; index as i" class="featured-item"> -->
<!-- <galaxy-media-card-lg
    *ngFor="let item of data; index as i"
    [entity]="item"
    (shareEntity)="onShowModalClicked(item, 1)"
    (viewEntity)="onEntityClicked(item.id, i)"
  ></galaxy-media-card-lg> -->
<!-- </div> -->
<!-- </galaxy-adx-flex> -->

<galaxy-media-carousel [data]='data' (viewEntity)="onEntityClicked($event.entity, $event.index)">
</galaxy-media-carousel>

<!-- view image -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showEntityModal" [fixedWidth]="false"
  [width]="70" [showPadding]="false">
  <div class="paddingx">
    <galaxy-media-card-modal *ngIf="showEntityModal" [entity]="entitySelected"></galaxy-media-card-modal>
  </div>

  <galaxy-media-navigator [showNavBack]="prevProject" [showNavNext]="nextProject"
    (navBack)="onEntityClicked(prevProject, prevProjectIndex)"
    (navNext)="onEntityClicked(nextProject, nextProjectIndex)"></galaxy-media-navigator>
</galaxy-modal>
