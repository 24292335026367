
export enum LogEnum {
  LOGIN = 1,
  LOGOUT = 2,
  LOGIN_FAILED = 3,
  TOKEN_EXPIRED = 4,
  TOKEN_GENERATION_FAILED = 5,
  TOKEN_REGENERATION = 6,
  PROFILE_UPDATE = 7,
  ACCOUNT_VERIFIED = 8,
  APP_ACTIVATED = 9,
  APP_CONNECTED = 10,
}
