<div class="adx-card adx-flat text-center">
  <h2 class=" text-normal" style="font-size: 30px;">Hello {{ username }}</h2>
  <p class="text-size18">
    <span>
      Connected to friends and family you know.
      <br />
      Share photos, videos and artworks, send messages and get updates.
    </span>
    <br />
    <br />
    <span class="color-pink color-special">
      Play . Connect . Enjoy
    </span>
  </p>

  <div class="adx-row">
    <div class="adx-cols-4 adx-colx-12">
      <div
        class="adx-card earn-card padding adx-shadow adx-round outlinev outline-pinkx"
      >
        <div class="icon text-center">
          <i class="axr ax-play ax-5x  color-special "></i>
        </div>
        <div>
          <h3 class="text-normal">
            Upload your Music and Movies
          </h3>
          <p>
            Promote and Earn from your digital media by uploading your content
            to the platform. Statistics and Utilitise are available empower
            artists and curators.
          </p>
        </div>
      </div>
    </div>
    <div class="adx-cols-4 adx-colx-12">
      <div
        class="adx-card earn-card padding adx-shadow adx-round outlinev outline-pinkx"
      >
        <div class="icon text-center">
          <i class="axr ax-shopping-bag ax-5x  color-special"></i>
        </div>
        <div>
          <h3 class="text-normal">
            E-Commerce for your business.
          </h3>
          <p>
            Sell your products to anyone anywhere with a secured payment system
            in place. You just need to care for your business, while we sell
            your products for you.
          </p>
        </div>
      </div>
    </div>
    <div class="adx-cols-4 adx-colx-12">
      <div
        class="adx-card earn-card padding adx-shadow adx-round outlinev outline-pinkx"
      >
        <div class="icon text-center">
          <i class="axr ax-calendar ax-5x  color-special"></i>
        </div>
        <div>
          <h3 class="text-normal">
            Event Management System.
          </h3>
          <p>
            Organize, Sell and Manage your event with ease. You can sell tickets
            with the platform to an already available community of potential
            attendees.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <form>
    <br />
    <br />
    <br />
    <br />
    <button
      routerLink="/setup/discover"
      class="adx-btn adx-md btn-pink adx-flat adx-round"
    >
      &nbsp; Continue &nbsp;
    </button>
  </form>
</div>
