<div class="adx-card adx-flat adx-ellipse bg-smoke text-center"
  [ngClass]="{'bg-smoke': theme=='light','bg-darker':theme=='dark'}">
  <p>
    <i class="{{icon}} ax-2x"></i>
    <br>
    No {{label}} Found
  </p>

  <ng-content></ng-content>
</div>
