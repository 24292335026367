<!-- <div class="adx-header bg-black adx-flat">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="header-flex">
          <div class="title" [routerLink]="['/home']">
            <div class="adx-logo">
              <img src="assets/images/logo/logo-o.png" alt="aiira" />
            </div>
          </div>
          <div class="nav">
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/suite']">
              Suite
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/studio']">
              Studio
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/business']">
              Business
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/play']">
              Connect
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/developer']">
              Developer
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/about']">
              About
            </button>
            <button class="adx-btn btn-default clear adx-flat" [routerLink]="['/support']">
              Support
            </button>
          </div>
          <div class="action">
            <button class="adx-btn btn-default adx-icon adx-round clear">
              <i class="axl ax-search"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round clear" [routerLink]="['/u']"
              routerLinkActive="router-link-active">
              <i class="axl ax-shopping-bag"></i>
            </button>
            <button class="adx-btn btn-default adx-icon adx-round clear menu-icon" (click)="onToggleMenu()">
              <i class="axl ax-menu"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<header class="i-header adx-shadow" [ngClass]="{ 'bg-white' :theme==='light', 'bg-black' :theme==='dark' }">
  <div>
    <button class="adx-btn  clear adx-flat remove-x" [routerLink]="['/connect']">
      Connect
    </button>
    <button class="adx-btn  clear adx-flat remove-x" [routerLink]="['/suite']">
      suite
    </button>
  </div>
  <div class='logo'>
    <button class="adx-btn adx-flat" [routerLink]="['/home']">

      <!-- <i class="axr ax-brand-aiira"></i> i i r a -->
      <img src="assets/images/logo/logo.svg" alt="" style="width: 70px ;">

    </button>
  </div>
  <div>
    <button class="adx-btn  clear adx-flat remove-x" [routerLink]="['/support']">
      support
    </button>
    <button class="adx-btn  clear adx-flat" (click)="onToggleMenu()">
      menu &nbsp;<span><i class="axl ax-menu"></i></span>
    </button>
  </div>
</header>

<div [ngClass]="{ 'bg-white' :theme==='light', 'bg-black' :theme==='dark' }" style="height: 80px;"></div>

<galaxy-modal (onCloseModalClicked)="onToggleMenu()" [showModal]="showMenu">
  <div class="menu-logo text-center {{theme}}">
    <img src="assets/images/logo/logo.svg" alt="" style="width: 70px;">
  </div>
  <div class="adx-list adx-hover text-center" style="min-width: 240px; margin:0 !important; border-radius: 22px; border: 1px solid rgba(128, 128, 128, 0.435);" (click)="onToggleMenu()">
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/home']">Home</div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/suite']">
      Suite
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/studio']">
      Studio
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/business']">
      Business
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/connect']">
      Connect
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/developer']">
      Developer
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/about']">
      About
    </div>
    <div class="adx-item adx-round adx-full adx-sm" [routerLink]="['/support']">
      Support
    </div>
  </div>
</galaxy-modal>
