<div class="qompass text-center" style="position: relative;">
  <div class="ad-logo">
    <img src="assets/images/logo/play.png" class="img-responsive" alt="">
  </div>
  <div class="text-size16">
    <strong class="text-size18">
      Web Interverse
    </strong>
    <p>
      WebGPU Game Engine
    </p>
    <small class="color-tgreen">
      https://play.aiira.co
    </small>
  </div>
  <div>
    <a routerLink="/studio"  class="adx-btn adx-ellipse adx-sm btn-default">Learn More</a>
    <a href="https://play.aiira.co" target="_blank" class="adx-btn adx-ellipse adx-sm btn-default">Visit Play</a>
  </div>
  <div class="qompass-slider">
    <iframe width="100%" height="500px"
    src="https://www.youtube.com/embed/VSR06vSNnJc?si=7HGUQmw3bJIW85LC&amp;controls=0"
    title="YouTube video player" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen></iframe>
  </div>
</div>

