<!-- {{ data | json }} -->
<div *ngIf="isLoading" class="adx-card text-center adx-flat is-loading">
  <p></p>
</div>
<div
  class="medias adx-card adx-round  adx-flat"
  [ngClass]="{
    'bg-black': theme === 'dark',
    'bg-grey': theme === 'light'
  }"
  *ngIf="!isLoading && data.length"
>
  <div
    class="media adx-card adx-shadow  adx-img"
    *ngFor="let item of data; index as i"
    (click)="onImageClicked(item.id, i)"
  >
    > <img [src]="onEmbed(item.art)" alt="" class="img-responsive" />
    <div class="detail">
      <span> {{ item.title }} </span>
    </div>
  </div>
</div>

<!-- view image -->
<galaxy-modal
  (onCloseModalClicked)="onCloseClicked($event)"
  [showModal]="showImageModal"
  [fixedWidth]="false"
  [width]="70"
>
  <div
    class="navigate left"
    *ngIf="prevProjectId"
    (click)="onImageClicked(prevProjectId, prevProjectIndex)"
  >
    <i class="ax ax-angle-left ax-2x"></i>
  </div>
  <div class="padding">
    <galaxy-media-card-modal
      *ngIf="showImageModal"
      [entityId]="projectId"
    ></galaxy-media-card-modal>
  </div>
  <div
    class="navigate right"
    *ngIf="nextProjectId"
    (click)="onImageClicked(nextProjectId, nextProjectIndex)"
  >
    <i class="axr ax-angle-right ax-2x"></i>
  </div>
</galaxy-modal>
