import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IReservationProfile, ReservationProfile, IBusinessEntity, BusinessEntity, AppSettingService, AuthService, SuiteApiService, EmbedService, NotifyService, Reservation, IReservation, ErrorHandlerService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-reservation-profile',
  templateUrl: './reservation-profile.component.html',
  styleUrls: ['./reservation-profile.component.scss']
})
export class ReservationProfileComponent implements OnInit, OnChanges {


  person: IBusinessEntity = new BusinessEntity;
  data: IReservationProfile = new ReservationProfile();
  businessId: number = 0;
  @Input() appId: number = 60; // airCare, 63 - airMeet
  @Input() reservationId: number = 0;
  @Input() reservationProfileId: number = 0;
  @Input() businessEntityId: number = 0;
  @Input() isEdit: boolean = false;

  isLoading: boolean = true;
  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  categorySelected: number = 2; // status - 2 published, 3 - trashed
  searchValue: string;
  orderBy: string = 'id';

  assignRole: boolean = false;
  branchId: string | number = 0;
  dateTimeNow: string = '';
  appointmentDate: string = '';


  viewerId: number = 0;




  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }


  ngOnInit() {
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);

    // console.log('current User', this._authService.currentUser);
    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;

    // set Date
    this.dateTimeNow = new Date().toISOString().split('.')[0];
    this.appointmentDate = this.dateTimeNow;





    this.isLoading = true;
    // check params to make sure it isnt an edit
    if (this.reservationId !== 0) {
      this.getReservation(this.reservationId);
      // console.log('creating entity with bEid', this.businessEntityId);

    } else if (this.reservationProfileId !== 0) {
      this.getProfile(this.reservationId);

    }
    else {
      // this.onCloseDetail();

    }

  }



  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.reservationId;

    if (!this.isEdit && !id.firstChange && id.currentValue !== 0 && id.previousValue !== id.currentValue) {
      this.isLoading = true;
      this.getReservation(this.reservationId);
      // console.log('creating entity with bEid', this.reservationId);
    }

  }



  getReservation(reservationId: number): any {
    // get the id of the person
    this.isLoading = true;


    this._suiteApi
      .setParams(null, reservationId.toString())
      .get(Reservation)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            this.person.entity = res.data.businessEntity.entity;
            this.newDataInit(res.data);

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  getProfile(profileId: number): any {
    // get the id of the person
    this.isLoading = true;


    this._suiteApi
      .setParams(null, profileId.toString())
      .get(ReservationProfile)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            this._suiteApi.DataMap({ from: res.data, to: this.data });
            this.person.entity = res.data.businessEntity.entity;
            this.isEdit = true;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  newDataInit(reservation: IReservation) {
    this.data = new ReservationProfile();

    // set user info
    this.data.businessEntity.id = reservation.businessEntity.id;

    //  init status to published
    this.data.reservation.id = this.reservationId;

    // if its airCare
    if (this.appId === 60) {
      this.data.testRequest = { lab: false, xray: false, physio: false, ent: false };
      this.data.solution = { prescription: false, admission: false, sugery: false, referral: false };
    }

    this.isLoading = false;


  }


  getPersonDetail(businessEntityId: number) {
    this.isLoading = true;
    this.person.id = businessEntityId;

    this._suiteApi.setParams(null, businessEntityId.toString())
      .get(BusinessEntity).subscribe(
        {
          next: (res) => {
            this.person.entity = res.data.entity;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );

  }



  onSubmit(statusId: number) {

    // set creator/modifier
    this.data.createdBy.id = this.viewerId;

    // this.data.branch = [this.businessBranch];

    // console.log(this.data);
    // return;

    this.isLoading = true;

    this.isEdit ? this.updateEntity() : this.createEntity();


  }



  updateEntity() {
    this._suiteApi
      .setParams({}, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  createEntity() {

    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }

}
