<!-- <video style="width: 100%;" controls src="assets/videos/connect.webm" [autoplay]="true"></video> -->
<div class="slider">

  <div class="nav-list">
    <div class="nav-item" *ngFor="let item of slider" (click)="linkClicked(item.link)" [data-title]="item.label">
      <span>
        <img [src]="item.image" alt="">
      </span>
    </div>
  </div>
</div>
