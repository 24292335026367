import { Component, OnInit, Input, SecurityContext, OnChanges, SimpleChanges } from '@angular/core';
import { IArtwork, EmbedService, Artwork, MediaTypeEnum } from '@galaxy/entity-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'galaxy-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {

  @Input() data: IArtwork[] = [];
  @Input() height: number = 0;

  isLoading: boolean = true;
  selectedArtwork: IArtwork = new Artwork;

  processedData: string[] = [];

  imageToShowOnError = '/assets/images/no-image-found.jpg';

  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    // navSpeed: 700,
    // navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      // 480: {
      //   items: 1
      // },
      // 767: {
      //   items: 1
      // },
      // 1024: {
      //   items: 1
      // }
    },
    // nav: true
  }
  constructor(

    private _sanitizer: DomSanitizer,
    private _embed: EmbedService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    const artworkData = changes.data;
    if (!artworkData.firstChange && artworkData.currentValue !== artworkData.previousValue) {
      this.processArtwork();

    }
  }

  ngOnInit() {
    this.isLoading = true;
    this.processArtwork();
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }


  processArtwork() {
    this.processedData = [];

    this.selectedArtwork = this.data[0];
    for (let index = 0; index < this.data.length; index++) {


      // image
      const thumbnail = this._sanitizer
        .sanitize(
          SecurityContext.URL,
          this._embed.imgUrl(
            this.data[index].type === MediaTypeEnum.IMAGE ?
              this.data[index].path :
              this.data[index].thumbnail,

            'md')
        );



      this.processedData.push(thumbnail);

    }
  }
  onArtworkSelect(artwork: IArtwork) {
    // console.log('changed', artwork);
    this.isLoading = true;
    this.selectedArtwork = artwork;

    setTimeout(() => {
      this.isLoading = false;
    }, 500)

  }

}
