import { StatusEnum } from '../../enums';
import { Controller } from '../decorators';

export interface IEmailTemplate {

  id: number;
  name: string;
  description: string;
  status: StatusEnum;
  created?: number;
  modified?: number;

}

@Controller({ name: 'EmailTemplate' })
export class EmailTemplate implements IEmailTemplate {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public status =  StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;
  }
}
