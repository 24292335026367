import { Component, OnInit, Input, OnChanges, SecurityContext, SimpleChanges, SimpleChange } from '@angular/core';
import { AppSettingService, EmbedService } from '@galaxy/entity-api';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-fade-bg',
  templateUrl: './fade-bg.component.html',
  styleUrls: ['./fade-bg.component.scss']
})
export class FadeBgComponent implements OnInit, OnChanges {
  @Input() image: string = '';

  cover: string = '';
  defaultImage = 'assets/images/galaxy.jpg';
  constructor(private _appSetting: AppSettingService,
    private _embed: EmbedService,

    private _sanitizer: DomSanitizer
  ) {
    // this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
    //   this.theme = res;
    // });
  }

  ngOnInit() {
    this.cover = this._sanitizer.sanitize(SecurityContext.URL, this.embedImage(this.image))
      ||
      this.defaultImage;
  }

  onEmbedImage(path: string) {
    // console.log('fb bg trigering ', path);
    return this._embed.imgUrl(path);
  }

  ngOnChanges(changes: SimpleChanges) {
    const cover: SimpleChange = changes.image;
    // console.log('prev value: ', art.previousValue);
    // console.log('got art: ', art.currentValue);
    // console.log('first chamndes art: ', art.firstChange);
    if (!cover.firstChange && cover.previousValue !== cover.currentValue) {
      // setting current value
      // console.log('seeting value');
      this.cover = this._sanitizer.sanitize(SecurityContext.URL, this.embedImage(cover.currentValue, 'default'));
    }

  }

  private embedImage(path: string, size = 'default') {
    return this._embed.imgUrl(path, size);
  }

  swapCoverImage(image): SafeResourceUrl {
    // this.image = this.embedImage(this.image);
    return (
      this._sanitizer.sanitize(SecurityContext.URL, this.embedImage(this.image))
      ||
      this.defaultImage
    );
  }


}
