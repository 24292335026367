import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number = 1;
  @Input() total: number = 0;
  @Input() limit: number = 10;

  @Input() infinitScroll: boolean = false;
  @Input() infinitScrollContainer: string = 'body';
  @Input() infinitScrollDistance: number = 2;

  @Input() pagingType: 'button' | 'combo' = 'button';

  lastPage: number = 0;

  visible: number = 5;

  data: number[] = [];

  scrollContainerHeight = window.innerHeight;

  @Output() pageClicked = new EventEmitter();
  scrollDistanceToTriggerPagination: number = 0;
  containerDistanceFromWindow: { x: number; y: number; };

  constructor() { }

  ngOnInit() {


    let newPage = 0;
    // const count =  Math.round(this.total / this.limit);
    // console.log('please tell me my parameters', this.total, this.limit);
    // console.log(
    //   Math.round(this.total / this.limit),
    //   Math.floor(this.total / this.limit),
    //   Math.ceil(this.total / this.limit),
    //   this.total / this.limit
    // );
    this.lastPage = Math.ceil(this.total / this.limit);
    let init = 0;
    if (this.page < this.visible - 1) {
      init = 0;
    } else if (this.page >= this.visible - 1 && this.lastPage > this.visible) {
      init = 4;
    }

    if (this.total > this.limit) {
      for (let i = init; i < this.lastPage; i++) {
        newPage = i + 1;
        this.data.push(newPage);
        if (this.data.length >= this.visible) {
          break;
        }
      }
    }





    // infiniteScroll
    this.scrollDistance(this.infinitScrollContainer);

    if (this.infinitScroll && this.page == 1 &&  this.total > 0) {
      setTimeout(() => {
        if (this.infinitScroll) this.onPageClicked(2)
      }, 2000);
    }
  }

  scrollDistance(scrollContainer: string) {
    const containerElement = document.querySelector(scrollContainer);
    this.containerDistanceFromWindow = this.getElementPosition(containerElement);

    this.scrollContainerHeight = containerElement.clientHeight;
    this.scrollDistanceToTriggerPagination = this.scrollContainerHeight + this.containerDistanceFromWindow.y - (((this.infinitScrollDistance * 20) / 100) * this.scrollContainerHeight)

  }

  getElementPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
      xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
      element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }

  onPageClicked(page) {
    this.pageClicked.emit(page);
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.scrollDistance(this.infinitScrollContainer);
  }

  @HostListener('window:scroll', ['$event'])
  onScrollY(event) {
    // console.log(window.scrollY, '-- ', this.scrollContainerHeight, '---', this.scrollDistanceToTriggerPagination);

    if (
      this.infinitScroll &&
      window.scrollY >= this.scrollDistanceToTriggerPagination &&
      this.lastPage > 1 && this.page < this.lastPage
    ) {
      // console.log('trigger pagination');
      this.onPageClicked(this.page + 1);
    }
  }


}
