import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IClient, Client, AppSettingService, AuthService, SuiteApiService, NotifyService, BusinessEntity, ErrorHandlerService } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-personx',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit, OnDestroy {

  @Input() appId: number = 0;
  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];
  @Input() title: string = 'List Of Persons'
  @Input() baseUrl: string = '/u/persons/';
  @Input() accountType: number = 0; //0 - all , 41 - users only , 42 - clients only
  @Input() branchId: number = 0; // to only show person registered in branch
  showDetail: boolean = false;
  defaultDetailTitle: string = 'Person Detail';
  detailTitle: string = 'Person Detail';
  isLoading: boolean = false;

  isList: boolean = true;

  data: IClient[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;
  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  theme: string = '';


  isFirstData = false;

  dataUpdated$!:Subscription;
  themeUpdated$!: Subscription;

  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _storageMap: StorageMap,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {

    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => {
      this.businessId = res.id;
      this._getStorageData();
    });
    if (this.businessId !== 0) {
      this._getStorageData();
      this.onReloadData();
    }
    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });



    this.dataUpdated$ = this._suiteApi.dataChanged$.subscribe(res => {
      if (res.type instanceof BusinessEntity) {
        this.onReloadData();
      }
    });
  }



  private _resetData() {
    this.data = [];
    this.isFirstData = true;

  }

  private _getStorageData() {
    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.branchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        if (res) {
          this.data = res.get(key) ?? [];
        }
      });

  }

  private _storeStorageData() {

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.branchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        let dataMap = new Map<string, IClient[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(key, this.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }



  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.accountType,
        id: this.businessId,
        categoryType: this.branchId,
        orderBy: [this.orderBy]

      })
      .getPageOf(Client)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <IClient[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            if (this.isFirstData) {
              this._storeStorageData();
              this.isFirstData = false;
            }

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }


  isSubscriptionActive(): boolean {
    const subcriptionPlan = this._suiteApi.verifyBusinessSubscription(this.totalData);
    if (subcriptionPlan.hasExpired || subcriptionPlan.clientLimitReached) {
      this._notify.info('Your Subscription Plan has expired and/or reached its limit');
      return false;
    }

    return true;
  }
  onDetailRoute(routeData: { link: string, title: string, objectName?: string, object?: any }) {

    // console.log('routeData', routeData)

    if (routeData.object) {
      this._suiteApi.setDataToSuite(routeData.objectName ?? 'client', routeData.object);
    }

    if (routeData.link[0] === '/') {
      const route = '/' + routeData.link;
      // console.log('route', route);
      this._router.navigateByUrl(route);
      return;
    }


    this.showDetail = true;
    this.detailTitle = routeData.title;
    // console.log(routeData.link);

    this._router.navigateByUrl(this.baseUrl + routeData.link);

  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }


  ngOnDestroy(): void {
    this.dataUpdated$.unsubscribe();
    this.themeUpdated$.unsubscribe();
  }
}
