<app-adx-client-avatar [client]="person.entity"></app-adx-client-avatar>
<form #inputForm="ngForm" [class.is-loading]="isLoading">
  <div class="adx-input adx-label adx-round">
    <label for="fee">{{ appId === 60 ? 'Diagnosis' : 'Topic' }}</label>
    <input type="text" required name="title" [(ngModel)]="data.title" />
  </div>
  <div class="adx-input adx-label adx-round">
    <label for="fee">{{
      appId === 60 ? 'Symptom / Description' : 'Details'
      }}</label>
    <textarea name="description" [(ngModel)]="data.description"></textarea>
  </div>
  <div *ngIf="appId === 60">
    <!-- request -->
    <div class="adx-card adx-flat bg-black adx-full adx-round card-fill">
      <div class="padding">
        <h3 class="title text-normal">
          Request Test
        </h3>
        <div class="list-flex">
          <div class="adx-input">
            <label for="lab">Lab Test</label>
            <input type="checkbox" style="visibility: collapse;" name="lab_request" id="lab_request"
              [(ngModel)]="data.testRequest.lab" />
            <label for="lab_request" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="xray">X-Ray</label>
            <input type="checkbox" style="visibility: collapse;" name="xray_request" id="xray_request"
              [(ngModel)]="data.testRequest.xray" />
            <label for="xray_request" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="physio">Physio</label>
            <input type="checkbox" style="visibility: collapse;" name="physio_request" id="physio_request"
              [(ngModel)]="data.testRequest.physio" />
            <label for="physio_request" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="ent">E&T Test</label>
            <input type="checkbox" style="visibility: collapse;" name="ent_request" id="ent_request"
              [(ngModel)]="data.testRequest.ent" />
            <label for="ent_request" class="adx-toggle" style="margin: 0;"></label>
          </div>
        </div>
      </div>
    </div>

    <!-- solution -->
    <div class="adx-card adx-flat bg-blue adx-full adx-round card-fill">
      <div class="padding">
        <h3 class="title text-normal">
          Solution
        </h3>

        <div class="list-flex">
          <div class="adx-input">
            <label for="Prescription">Prescription</label>
            <input type="checkbox" style="visibility: collapse;" name="Prescription" id="Prescription"
              [(ngModel)]="data.solution.prescription" />
            <label for="Prescription" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="admission">Admission</label>
            <input type="checkbox" style="visibility: collapse;" name="admission" id="admission"
              [(ngModel)]="data.solution.admission" />
            <label for="admission" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="surgery">Surgery</label>
            <input type="checkbox" style="visibility: collapse;" name="surgery" id="surgery"
              [(ngModel)]="data.solution.sugery" />
            <label for="surgery" class="adx-toggle" style="margin: 0;"></label>
          </div>
          <div class="adx-input">
            <label for="referral">Referral</label>
            <input type="checkbox" style="visibility: collapse;" name="referral" id="referral"
              [(ngModel)]="data.solution.referral" />
            <label for="referral" class="adx-toggle" style="margin: 0;"></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="adx-input adx-label adx-round">
    <label for="fee">Exam Results</label>
    <textarea></textarea>
  </div>

  <div class="adx-input adx-label adx-round">
    <label for="fee">Remark</label>
    <input type="text" required name="fee" />
  </div> -->

  <div class="adx-input">
    <label for="classified">Classified</label>
    <input type="checkbox" style="visibility: collapse;" name="classified" id="classified"
      [(ngModel)]="data.isClassified" />
    <label for="classified" class="adx-toggle" style="margin: 0;"></label>
  </div>

  <!-- <div class="adx-row">
    <div class="adx-cols-12">
      <div class="adx-input adx-label adx-round">
        <label for="date">Date</label>
        <input type="datetime-local" name="data" required [readOnly]="isLoading" />
      </div>
    </div>
    <div class="adx-cols-5">
      <div class="adx-input adx-label adx-round">
        <label for="deaprtment">Time</label>
        <input type="time" name="time" required [readOnly]="isLoading" />
      </div>
    </div>
  </div> -->
  <!-- <div class="clearfix"></div>
  <div class="adx-input adx-label adx-round">
    <label for="fee">Appoinment Fee</label>
    <input type="number" required name="fee" />
  </div> -->

  <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="onCloseDetail()">
    Cancel
  </button>

  <button *ngIf="!isLoading" [disabled]="isLoading || inputForm.invalid" (click)="onSubmit(2)"
    class="adx-btn adx-round adx-sm btn-tgreen float-right">
    Save Profile
  </button>
</form>
