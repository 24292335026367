import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'galaxy-media-navigator',
  templateUrl: './media-navigator.component.html',
  styleUrls: ['./media-navigator.component.scss']
})
export class MediaNavigatorComponent implements OnInit {

  @Input() showNavBack: boolean = false;
  @Input() showNavNext: boolean = false;
  @Output() navBack = new EventEmitter();
  @Output() navNext = new EventEmitter();

  ngOnInit() {
  }

  onNav(isNext: boolean) {
    if (isNext) {
      this.navNext.emit();
    } else {

      this.navBack.emit();
    }
  }
}
