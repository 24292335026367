<galaxy-adaptive-card class="comments-flex" [miniPadding]="true" [shape]="'rounded'" [isFull]="true"
  [isHeightFull]="true" [isFlat]="true" [autoMiniPadding]="true" [autoDimBlack]="true">
  <div style="display: flex;">
    <div class="adx-divider" style="flex:1; padding:8px">
      Chapters
    </div>
    <div class="adx-btn-group adx-round" style="margin:8px;">
      <button (click)='previousChapter()' class="adx-btn" *ngIf="selectedChapterId !== firstChapterId">
        <i class="axr ax-chevron-left"></i>
      </button>
      <button (click)='nextChapter()' class="adx-btn" *ngIf="selectedChapterId !== lastChapterId">
        <i class="axr ax-chevron-right"></i>
      </button>
    </div>
  </div>
  <!-- <div class="adx-input adx-round">
    <input type="search" [(ngModel)]="searchValue" (keypress)='onReload()'>
  </div> -->
  <div class="adx-list adx-flat album-list" [class.is-loading]='isLoading'>
    <div class='adx-item' *ngFor="let item of data" (click)='onChaptureSelected(item)'
      [class.bg-special]='item.id === selectedChapterId'>
      {{item.title}} <span class="float-right">{{item.created*1000 | date}}</span>
    </div>
  </div>
</galaxy-adaptive-card>
