<!-- <div class="adx-section bg-black">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-6">
        <div class="adx-card adx-flat">
          <div class="logo">
            <img
              src="/assets/images/logo/aiira_white.png"
              class="img-responsive"
              alt=""
              srcset=""
            />
          </div>
          <p class="text-size16">
            Nine out of ten doctors recommend Laracasts over competing brands.
            Come inside, see for yourself, and massively level up your
            development skills in the process.
          </p>
          <button class="adx-btn adx-icon adx-round btn-default clear">
            <i class="fab fa-youtube"></i>
          </button>
          <button class="adx-btn adx-icon adx-round btn-default clear">
            <i class="fab fa-twitter"></i>
          </button>
          <button class="adx-btn adx-icon adx-round btn-default clear">
            <i class="fab fa-github"></i>
          </button>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <h3 class="title text-normal">Enterprise Apps</h3>
          <div class="adx-list adx-flat adx-sm adx-sm" style="opacity: 0.7">
            <div class="adx-item">Financial Management System</div>
            <div class="adx-item">Hospital Management System</div>
            <div class="adx-item">School Management System</div>
            <div class="adx-item">Hotel Management System</div>
            <div class="adx-item">Client Management System</div>
            <div class="adx-item">Appointment Management System</div>
            <div class="adx-item">Laboritory Management System</div>
          </div>
        </div>
      </div>
      <div class="adx-cols-3">
        <div class="adx-card adx-flat">
          <h3 class="title text-normal">Extras</h3>
          <div class="adx-list adx-flat adx-sm adx-sm" style="opacity: 0.7">
            <div class="adx-item">Business Directory</div>
            <div class="adx-item">Testimonials</div>
            <div class="adx-item">FAQ</div>
            <div class="adx-item">Suport</div>
            <div class="adx-item">Get a Job</div>
            <div class="adx-item">Privacy</div>
            <div class="adx-item">Terms</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class=" bg-special2 i-footer">
  <div class="top " [ngClass]="{ 'bg-white' :theme==='light', 'bg-black' :theme==='dark' }"></div>
  <div class="clearfix"></div>
  <div class='i-footer-menu'>
    <div class="wrapper">
      <div class="adx-row">
        <div class="adx-cols-12">
          <div class="adx-card adx-flat footer-menu">
            <div style="margin-top:100px">
              <!-- <i class="axl ax-brand-aiira ax-5x"></i>
              <br> -->
              <!-- Aiira Technologies -->

              <img src="assets/images/logo/logo.svg" alt="" style="width: 120px ;">
            </div>
            <div>
              <div class="adx-divider">
                Site Menu
              </div>
              <div class="adx-list adx-flat adx-sm">
                <div class="adx-item" routerLink="/suite">
                  <i class="axl ax-chevron-right"></i>Suite - Enterprise Solution
                </div>
                <div class="adx-item" routerLink="/busienss">
                  <i class="axl ax-chevron-right"></i>Business & Services Hb
                </div>
                <div class="adx-item" routerLink="/connect">
                  <i class="axl ax-chevron-right"></i>Play, Connect & Enjoy
                </div>
              </div>
            </div>
            <div>
              <div class="adx-divider">
                Apps & Platfroms
              </div>
              <div class="adx-list adx-flat adx-sm">
                <div class="adx-item">
                  <a href='https://connect.aiira.co' target="_blank">
                    <i class="axl ax-chevron-right"></i>Social Platform
                  </a>
                </div>
                <div class="adx-item">
                  <a href='https://business.aiira.co' target="_blank">

                    <i class="axl ax-chevron-right"></i>Business Platform
                  </a>
                </div>
                <div class="adx-item">
                  <a href='https://suite.aiira.co' target="_blank">
                    <i class="axl ax-chevron-right"></i>Enterprise Suite
                  </a>
                </div>
              </div>


            </div>
            <div>
              <div class="adx-divider">
                Company Info
              </div>
              <div class="adx-list adx-flat adx-sm">
                <div class="adx-item" routerLink="/studio">
                  <i class="axl ax-chevron-right"></i>Interative Studios
                </div>
                <div class="adx-item" routerLink="/about">
                  <i class="axl ax-chevron-right"></i>About Company
                </div>
                <div class="adx-item" routerLink="/support">
                  <i class="axl ax-chevron-right"></i>Support Center
                </div>
              </div>
            </div>
          </div>
          <div class='padding' [ngClass]="{ 'bg-white' :theme==='light', 'bg-black' :theme==='dark' }"
            style="background-color: transparent; position: relative; top:20px; z-index: 10;">
            <!-- <hr style="opacity: .1;"> -->
            <div style="display:flex">
              <span style="flex:1;">
                Aiira Technologies
                &nbsp; &nbsp; <a routerLink="/tos">Terms Of Service</a>
                &nbsp; &nbsp; <a routerLink="/privacy">Privacy Policy</a>
              </span>
              <span>
                <a class="adx-btn adx-round adx-xs adx-icon adx-flat btn-special"
                  href="https://connect.aiira.co/profile/aiira" target="_blank"><i class="axr ax-brand-aiira"></i></a>
                <a class="adx-btn adx-round adx-xs adx-icon adx-flat btn-prim" href="https://twitter.com/aiira_co"
                  target="_blank"><i class="axr ax-brand-twitter"></i></a>

                <a class="adx-btn adx-round adx-xs adx-icon adx-flat btn-pink"
                  href="https://www.youtube.com/channel/UC0NZZA9ceYfck0XWMA6I4SA" target="_blank"><i
                    class="axr ax-brand-youtube"></i></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="copyright" [ngClass]="{ 'bg-white' :theme==='light', 'bg-black' :theme==='dark' }">
    <span style="position: relative; z-index: 10;">
      Copyright &copy; {{currentYear}}. Aiira LLC.
    </span>
  </div>

</div>
