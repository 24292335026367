import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'galaxy-adx-sponsor',
  templateUrl: './ad-sponsor.component.html',
  styleUrls: ['./ad-sponsor.component.scss']
})
export class AdSponsorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
