import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from '../GenericService';

@Injectable({
  providedIn: 'root'
})
export class ControlApiService extends GenericService {
  constructor(http: HttpClient) {
    const rootUrl = 'https://api-v2.aiira.co';
    const baseUrl = '/control-api/';
    super(http, baseUrl, rootUrl);
  }

  controlData: Map<string, any> = new Map;
}
