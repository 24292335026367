<!-- <div class="adx-card adx-flat discover">
    <div class="adx-divider">Discover</div>
    <adx-carousel [preset]="'person'" [showTitle]="false" [data]="discoverData">
</adx-carousel>
  </div> -->
<br />
<galaxy-single-ad [theme]="theme"></galaxy-single-ad>
<br />
<galaxy-single-ad [theme]="theme"></galaxy-single-ad>
<br />
<br />

<adx-footer></adx-footer>
