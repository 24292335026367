import { IGroup, Group } from './group.model';
import { IEntity, Entity } from './entity.model';
import { Person } from './user.model';
import { Controller } from '../decorators';
import { ActivityTypeEnum, StatusEnum } from '../../enums';

export interface IHistory {

  id: number;
  person: Person;
  entity?: IEntity;
  activityType?: ActivityTypeEnum;
  status?: StatusEnum;
  params?: string;
  created?: string;
  gid?: number;
}

@Controller({ name: 'History'})
export class History implements IHistory {
  constructor(
    public id = 0,
    public person = new Person(),
    public entity = new Entity(),
    public activityType = ActivityTypeEnum.VIEWED,
    public status = StatusEnum.PUBLISHED,
    public params = null,
    public created = null,
    public gid = 0,
  ) {
    // this.status.id = 2; // published
    // this.activityType.id = 26; // means flag, but we will default it to view
    // 24 like
    // 25 dislike
    // 26 flag / view
    // 27 smile
    // 28 laugh
    // 29 wow
    // 30 angry
    // 31 sad
    // 32 heart / love
  }
}
