<!-- entity collection -->
<div class="entity-collection" [ngClass]="{
    'show-ets': showEntities,
    'bg-white': theme === 'light',
    'bg-dark': theme === 'dark'
  }">
  <div>
    <div class="et-cl-title bg-special padding-sm" style='font-weight:500;'>
      &nbsp; <i class="axr ax-{{ icon }} "></i> &nbsp;
      {{ collection?.title }} &nbsp;

      <button *ngIf="entityType === 'media'" class="adx-btn adx-round adx-icon shine-tgreen" [ngClass]="{
          'btn-default': theme === 'dark',
          'btn-dark': theme === 'light'
        }">
        <i class="axr ax-play"></i>
      </button>
    </div>
    <button (click)="onCloseViewCollection()" class="adx-btn adx-round adx-icon adx-flat clear float-right ">
      <i class="axr ax-chevron-down"></i>
    </button>
  </div>

  <div class="adx-card adx-flat is-loading" *ngIf="isLoading">
    <p></p>
  </div>

  <div *ngIf="!isLoading" [ngSwitch]="entityType">
    <div *ngSwitchCase="'photo'">
      <galaxy-masonry-grid [data]="entityData" [showBookmark]="false">
      </galaxy-masonry-grid>
    </div>
    <div *ngSwitchCase="'media'">
      <div class="containr" style="max-width: 1600px; margin:0 auto">
        <div class="wrapper-fluid">
          <div class="adx-row">
            <div class="adx-cols-12">
              <div class="play-grid">
                <div class='media-item' *ngFor="let item of entityData; index as i">
                  <galaxy-media-track [data]='item' [size]='"sm"' [index]='i' [isResponsive]=false [showBookmark]=false
                    [isTransparent]=true>
                  </galaxy-media-track>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div *ngSwitchDefault>
      <!-- for both products and media -->
      <galaxy-media-grid [entityType]="entityType" [data]="entityData" [showBookmark]="false"></galaxy-media-grid>
    </div>
  </div>
</div>
