import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranch, BusinessBranch } from './business-branch.model';
import { ITransaction, Transaction } from './transaction.model';
import { Controller } from '../decorators';
import { IBusiness, Business } from "./business.model";
import { AssetPaymentPlanEnum, AssetTypeEnum, FuelTypeEnum, MediaTypeEnum, StatusEnum, VehicleActivityStateEnum, VehicleClassEnum } from "../../enums";
import { BusinessBranchDepartment, IBusinessBranchDepartment } from "./business-department.model";



export interface IBusinessVehicleMedia {
  // Basic Info
  id: number;
  title: string;
  vehicle?: IBusinessVehicle;

  // VideoInfo
  description: string;
  thumbnail: string;

  path: string;
  file?;
  // music/video
  type: MediaTypeEnum;
  status: StatusEnum;

  orderNumber: number;

  created?: number;
}

@Controller({ name: 'VehicleMedia' })
export class BusinessVehicleMedia implements IBusinessVehicleMedia {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public vehicle = new BusinessVehicle(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public status = StatusEnum.PUBLISHED,
    public orderNumber = 1,
    public file = ''
  ) { }
}

export interface IBusinessVehicle {
  id: number;
  image: string;
  name: string;
  manufacturer: string;
  model: string;
  licensedNumber: string;
  referenceNumber: string;
  makeYear: string;
  purchaseYear: string;
  fuelType: FuelTypeEnum; // super, deseil etc
  vehicleClass: VehicleClassEnum;
  activityState: VehicleActivityStateEnum;
  averageSpeed: string;
  lastGeoLocation: { longitude: number, latitude: number }
  status: StatusEnum;
  media?: IBusinessVehicleMedia[];
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  businessBranchDepartment: IBusinessBranchDepartment;

}

@Controller({ name: 'Vehicle' })
export class BusinessVehicle implements IBusinessVehicle {
  constructor(
    public id = 0,
    public image = '',
    public name = '',
    public manufacturer = '',
    public model = '',
    public licensedNumber = '',
    public referenceNumber = '',
    public makeYear = '',
    public purchaseYear = '',
    public fuelType = FuelTypeEnum.DESEIL,
    public vehicleClass = VehicleClassEnum.A,
    public activityState = VehicleActivityStateEnum.PARKED,
    public averageSpeed = '',
    public lastGeoLocation = { longitude: -1.6230107134574407, latitude: 6.694190456933313 },
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
    public businessBranchDepartment = new BusinessBranchDepartment,

  ) {
  }
}


export interface IBusinessVehicleReservationItem {
  id: number;
  VehicleReservation: IBusinessVehicleReservation;
  vehicle: IBusinessVehicle;
  status: StatusEnum;

}

export class BusinessVehicleReservationItem implements IBusinessVehicleReservationItem {
  constructor(
    public id = 0,
    public VehicleReservation = new BusinessVehicleReservation,
    public vehicle = new BusinessVehicle,
    public status = StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;
  }

}

export interface IBusinessVehicleReservation {
  id: number;
  businessEntity: IBusinessEntity;
  transaction: ITransaction;
  VehicleReservationItems: IBusinessVehicleReservationItem[];
  VehicleReservationItemsCount?: number;
  amount: number;
  rentFromDate: number;
  rentToDate: number;
  checkInDate?: number;
  checkOutDate?: number;
  description: string;
  status: StatusEnum;
  businessBranch: IBusinessBranch;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'VehicleReservation' })
export class BusinessVehicleReservation implements IBusinessVehicleReservation {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public transaction = new Transaction,
    public rentFromDate = 0,
    public rentToDate = 0,
    public VehicleReservationItems = [],
    public amount = 0,
    public description = '',
    public status = StatusEnum.PUBLISHED,
    public businessBranch = new BusinessBranch,
    public created = 0,
    public createdBy = new BusinessEntity,

  ) { }
}


export interface IBusinessGarage {
  id: number;
  name: string;
  reference: string;
  description: string;
  location: string;
  businessBranch: IBusinessBranch,
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'Garage' })
export class BusinessGarage implements IBusinessGarage {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public reference = '',
    public location = '',
    public businessBranch = new BusinessBranch,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
  }
}



export interface IVehicleGarage {
  id: number;
  garage: IBusinessGarage;
  vehicle: IBusinessVehicle;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'VehicleGarage' })
export class VehicleGarage implements IVehicleGarage {
  constructor(
    public id = 0,
    public garage = new BusinessGarage,
    public vehicle = new BusinessVehicle,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {
  }
}

export interface IBusinessFuelStation {
  id: number,
  name: string,
  description: string,
  geoLocation: { longitude: number, latitude: number },
  location: string,
  phoneNumber: string,
  email: string,
  businessBranch: IBusinessBranch,
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'FuelStation' })
export class BusinessFuelStation implements IBusinessFuelStation {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public geoLocation = { longitude: 0, latitude: 0 },
    public location = '',
    public phoneNumber = '',
    public email = '',
    public businessBranch = new BusinessBranch,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}


export interface IBusinessAreaStop {
  id: number,
  area: IBusinessArea,
  name: string,
  order: number,
  description: string,
  geoLocation: { longitude: number, latitude: number },
  audioPath: string,
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'AreaStop' })
export class BusinessAreaStop implements IBusinessAreaStop {
  constructor(
    public id = 0,
    public area = new BusinessArea,
    public name = '',
    public order = 1,
    public description = '',
    public geoLocation = { longitude: 0, latitude: 0 },
    public audioPath = '',
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}

export interface IBusinessArea {
  id: number,
  business: IBusiness,
  name: string,
  description: string,
  audioPath: string,
  geoLocation: { longitude: number, latitude: number },
  radius: number;
  stops: IBusinessAreaStop[],
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'Area' })
export class BusinessArea implements IBusinessArea {
  constructor(
    public id = 0,
    public business = new Business,
    public name = '',
    public description = '',
    public geoLocation = { longitude: 0, latitude: 0 },
    public radius = 10,
    public audioPath = '',
    public stops = [],
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}



export interface IBusinessRoute {
  id: number,
  business: IBusiness,
  name: string,
  description: string,
  city: string,
  state: string,
  country: string,
  routeAreas: IBusinessRouteArea[],
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'Route' })
export class BusinessRoute implements IBusinessRoute {
  constructor(
    public id = 0,
    public business = new Business,
    public name = '',
    public description = '',
    public city = '',
    public state = '',
    public country = '',
    public routeAreas = [],
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}




export interface IBusinessRouteArea {
  id: number,
  area: IBusinessArea,
  route: IBusinessRoute,
  order: number;
  rates: IBusinessRouteAreaRate[];
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'RouteArea' })
export class BusinessRouteArea implements IBusinessRouteArea {
  constructor(
    public id = 0,
    public area = new BusinessArea,
    public route = new BusinessRoute,
    public rates = [],
    public order = 0,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}



export interface IBusinessRouteAreaRate {
  id: number,
  routeArea: IBusinessRouteArea,
  unitFee: number;
  unitDistance: number;
  type: number;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'RouteArea' })
export class BusinessRouteAreaRate implements IBusinessRouteAreaRate {
  constructor(
    public id = 0,
    public routeArea = new BusinessRouteArea,
    public unitFee = 0,
    public unitDistance = 0,
    public type = 0,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity
  ) {

  }
}
