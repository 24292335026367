<galaxy-adaptive-card [transparentBg]="isReply" [shape]="'rounded'">
  <div class="comment-flex {{ size }} adx-card adx-flat" [class.reply]="isReply">
    <div class="img"><div class="adx-img"></div></div>
    <div class="detail">
      <div class="person padding-sm">
        <span class="name"> Kwesi Melt </span>
        <span class="date">
          <i class="color-{{ color }}">#</i> January 20, 2019
        </span>
      </div>
      <p>
        This is a great topic to study. It’s so easy to accidentally change an
        endpoint and can result in a large impact. In the last few years I
        changed a string status to an object to include a bit more information.
        We missed the change but the customer was quick to let us know. I kind
        of like Frisby as it has support for JSON schema, headers and status
        code with a straight forward dsl.
      </p>
    </div>
    <div class="action">
      <button
        (click)="onToggleReply()"
        *ngIf="!isReply"
        class="adx-btn btn-tgreen btn-special adx-icon adx-round"
        [class.adx-md]="size == 'lg'"
        [class.adx-sm]="size == 'sm'"
      >
        <i class="fal fa-{{ showReply ? 'times' : 'reply' }}"></i>
      </button>
      <br />
      <button class="adx-btn btn-default clear adx-icon adx-round" title="Like">
        <i class="fal fa-thumbs-up"></i> 54
      </button>
      <br />
      <button class="adx-btn btn-default clear adx-icon adx-round" title="Report">
        <i class="fal fa-flag"></i>
      </button>
    </div>
  </div>
</galaxy-adaptive-card>

<galaxy-post-comment
  *ngIf="showReply"
  [placeholder]="'Reply to Kwasi Melt s comment'"
></galaxy-post-comment>
