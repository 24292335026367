<galaxy-media-comment-post *ngIf="isLoggedIn" (addComment)="postComment($event)"></galaxy-media-comment-post>

<small class="padding-sm" *ngIf="!isLoggedIn">
  Please login to comment
</small>

<div class="adx-card adx-flat" *ngIf="!isLoading && data.length === 0">
  <p class="text-center text-size16" style="opacity: 0.4">
    <i class="axr ax-message-2 ax-2x"></i> <br />
    <br />
    Be first to comment.
  </p>
</div>

<div class="comment-list" *ngIf="!isLoading" [ngClass]="{'bg-white': theme !=='dark'}">
  <galaxy-media-comment *ngFor="let item of data" [data]="item" [isLoggedIn]="isLoggedIn" [personInfo]="personInfo"
    [entityId]="entityId" [entityTypeId]="entityTypeId" (commentAdded)="increaseCommentCount($event)">
  </galaxy-media-comment>
</div>

<div *ngIf="isLoading || isLoadingNewCommment" class="adx-card adx-flat is-loading">
  <p></p>
</div>
