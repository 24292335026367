import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthService, AppSettingService, MaxLengthPipe, EntityApiModule } from '@galaxy/entity-api';

import { WorkspaceComponent } from './workspace/workspace.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './designa-ui/header/header.component';
import { OriHeaderComponent } from './designa-ui/ori-header/ori-header.component';
import { PaginationComponent } from './designa-ui/pagination/pagination.component';
import { PersonaComponent } from './persona/persona.component';
import { PersonItemComponent } from './person-item/person-item.component';
import { GalleryGridComponent } from './gallery-grid/gallery-grid.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AvatarComponent } from './designa-ui/avatar/avatar.component';
import { SingleAdComponent } from './single-ad/single-ad.component';
import { FadeBgComponent } from './fade-bg/fade-bg.component';
import { CommentComponent } from './comment/comment.component';
import { PostCommentComponent } from './post-comment/post-comment.component';
import { PageHeaderComponent } from './designa-ui/page-header/page-header.component';
import { AdaptiveCardComponent } from './designa-ui/adaptive-card/adaptive-card.component';
import { NotifyComponent } from './designa-ui/notify/notify.component';
import { AnnouncementComponent } from './designa-ui/announcement/announcement.component';
import { PhotoCardComponent } from './photo-card/photo-card.component';
import { QuickMenuComponent } from './quick-menu/quick-menu.component';
import { PhotoArtComponent } from './photo-art/photo-art.component';
import { NewFabComponent } from './new-fab/new-fab.component';
import { UiFooterComponent } from './ui-footer/ui-footer.component';
import { MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppNotificationComponent } from './designa-ui/app-notification/app-notification.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';

import { ModalComponent } from './designa-ui/modal/modal.component';

import { MultiSelectComponent } from './multi-select/multi-select.component';
import { SelectItemComponent } from './multi-select/select-item/select-item.component';
import { SearchOptionsComponent } from './search-options/search-options.component';

import { NgxMasonryModule } from 'ngx-masonry';

import { PostAcionsComponent } from './designa-ui/post-acions/post-acions.component';
import { PersonAvatarComponent } from './designa-ui/person-avatar/person-avatar.component';

import { ImageToolComponent } from './designa-ui/image-tool/image-tool.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { NgxEchartsModule } from 'ngx-echarts';
import { ChartToolComponent } from './designa-ui/chart-tool/chart-tool.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import { OriShortcutComponent } from './designa-ui/ori-shortcut/ori-shortcut.component';

import { PersonMediaComponent } from './designa-ui/person-media/person-media.component';
import { PersonMediaItemComponent } from './designa-ui/person-media/person-media-item/person-media-item.component';
import { QuillEditorToolComponent } from './designa-ui/quill-editor-tool/quill-editor-tool.component';
import { QuillModule } from 'ngx-quill';
import { CategoryContainerComponent } from './designa-ui/category-container/category-container.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { MapToolComponent } from './designa-ui/map-tool/map-tool.component';
import { LoadPaginationComponent } from './designa-ui/load-pagination/load-pagination.component';
import { SponserSectionComponent } from './designa-ui/sponser-section/sponser-section.component';
import { MediaNavigatorComponent } from './media-navigator/media-navigator.component';
import { AdFlexComponent } from './ad-flex/ad-flex.component';
import { AdGroupComponent } from './ad-group/ad-group.component';
import { MediaShareComponent } from './media-share/media-share.component';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { AdBtnGroupComponent } from './ad-btn-group/ad-btn-group.component';
import { MediaBannerComponent } from './media-banner/media-banner.component';
import { PostAnalyticComponent } from './designa-ui/post-analytic/post-analytic.component';
import { Title } from '@angular/platform-browser';
import { ContentSlideComponent } from './designa-ui/content-slide/content-slide.component';
import { PostMiniComponent } from './designa-ui/post-mini/post-mini.component';
import { WebcamComponent } from './designa-ui/webcam/webcam.component';
@NgModule({ declarations: [
        MenuComponent,
        HeaderComponent,
        WorkspaceComponent,
        PaginationComponent,
        PersonaComponent,
        PersonItemComponent,
        GalleryGridComponent,
        PaginationComponent,
        AvatarComponent,
        SingleAdComponent,
        FadeBgComponent,
        CommentComponent,
        PostCommentComponent,
        PageHeaderComponent,
        AdaptiveCardComponent,
        NotifyComponent,
        AnnouncementComponent,
        PhotoCardComponent,
        QuickMenuComponent,
        PhotoArtComponent,
        NewFabComponent,
        UiFooterComponent,
        AppNotificationComponent,
        HomeBannerComponent,
        ModalComponent,
        MultiSelectComponent,
        SelectItemComponent,
        SearchOptionsComponent,
        OriHeaderComponent,
        PostAcionsComponent,
        PersonAvatarComponent,
        ImageToolComponent,
        ChartToolComponent,
        OriShortcutComponent,
        PersonMediaComponent,
        PersonMediaItemComponent,
        QuillEditorToolComponent,
        CategoryContainerComponent,
        MapToolComponent,
        LoadPaginationComponent,
        SponserSectionComponent,
        MediaNavigatorComponent,
        AdFlexComponent,
        AdGroupComponent,
        MediaShareComponent,
        MediaPreviewComponent,
        MediaBannerComponent,
        AdBtnGroupComponent,
        PostAnalyticComponent,
        PostMiniComponent,
        ContentSlideComponent,
        WebcamComponent
        // MaxLengthPipe
    ],
    exports: [
        WorkspaceComponent,
        HeaderComponent,
        PersonaComponent,
        PersonItemComponent,
        GalleryGridComponent,
        PaginationComponent,
        AvatarComponent,
        SingleAdComponent,
        FadeBgComponent,
        CommentComponent,
        PostCommentComponent,
        PageHeaderComponent,
        AdaptiveCardComponent,
        NotifyComponent,
        PhotoCardComponent,
        QuickMenuComponent,
        PhotoArtComponent,
        NewFabComponent,
        UiFooterComponent,
        HomeBannerComponent,
        ModalComponent,
        MultiSelectComponent,
        OriHeaderComponent,
        PostAcionsComponent,
        PersonAvatarComponent,
        ImageToolComponent,
        ChartToolComponent,
        OriShortcutComponent,
        PersonMediaComponent,
        QuillEditorToolComponent,
        CategoryContainerComponent,
        MapToolComponent,
        LoadPaginationComponent,
        SponserSectionComponent,
        MediaNavigatorComponent,
        AdFlexComponent,
        AdGroupComponent,
        MediaShareComponent,
        MediaPreviewComponent,
        MediaBannerComponent,
        AdBtnGroupComponent,
        PostAnalyticComponent,
        ContentSlideComponent,
        PostMiniComponent
        // MaxLengthPipe
    ], imports: [CommonModule,
        RouterModule,
        FormsModule,
        EntityApiModule,
        MomentModule,
        DragDropModule,
        FileUploadModule,
        LazyLoadImageModule,
        NgxMasonryModule,
        WebcamModule,
        ImageCropperModule,
        EmojiModule,
        QuillModule.forRoot(),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        })], providers: [AuthService, AppSettingService, Title, provideHttpClient(withInterceptorsFromDi())] })
export class UiModule { }
