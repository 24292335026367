<section class="adx-section apps">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="adx-btn-group">
            <button class="adx-btn">
              <span> <i class="fal fa-shopping-cart fa-2x"></i> </span> <br />
              <small> Iventory </small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-spade fa-2x"></i> </span> <br />
              <small> Finance </small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-stethoscope fa-2x"></i> </span> <br />
              <small> HealthCare </small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-clock fa-2x"></i> </span> <br />
              <small> Appointment </small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-id-card-alt fa-2x"></i> </span> <br />
              <small> Client System</small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-user-circle fa-2x"></i> </span> <br />
              <small> HR System </small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-bed fa-2x"></i> </span> <br />
              <small> Hotel Management</small>
            </button>
            <button class="adx-btn">
              <span> <i class="fal fa-church fa-2x"></i> </span> <br />
              <small> Church System</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white" style="bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="topix">
            <h1 class="text-normal text-size50">Calling all pros.</h1>
            <span class="text-size18">
              Our most powerful pro lineup ever — MacBook Pro, Mac Pro, and Pro
              Display XDR. Ready for your best work ever.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx">
            <div class="adx-img"></div>
            <div class="adx-detail">
              <div class="padding">
                <span> 16-inch model </span>
                <h1 class="text-normal">MacBook Pro</h1>
                <span class="text-size18">
                  The best for the <br />
                  brightest.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Buy&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx reverse">
            <div class="adx-img"></div>
            <div class="adx-detail">
              <div class="padding">
                <!-- <span> 16-inch model </span> -->
                <h1 class="text-normal">Mac Pro</h1>
                <span class="text-size18">
                  Power to change <br />
                  everything.
                </span>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Buy&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="flexx   ">
            <div class="adx-img"></div>
            <div class="adx-detail">
              <div class="padding">
                <span> <br /> </span>
                <h1 class="text-normal">Pro Display XDR</h1>
                <span class="text-size18"> Believing is seeing. </span> <br />
                <button class="adx-btn btn-blue adx-round adx-sm">
                  &nbsp;Buy&nbsp;
                </button>
                <br />
                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-grey">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">
              Which Mac notebook is right for you?
            </h1>
            <!--
              <span class="text-size18">
                Our most powerful pro lineup ever — MacBook Pro, Mac Pro, and Pro
                Display XDR. Ready for your best work ever.
              </span>
            -->
          </div>
          <p></p>
          <div class="offer-flex text-left">
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black"></div>
              <div class="adx-item adx-flat">
                <h2>Inventory Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black"></div>
              <div class="adx-item adx-flat">
                <h2>Hospital Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
            <div class="adx-card adx-flat">
              <div class="adx-img bg-black"></div>
              <div class="adx-item adx-flat">
                <h2>School Suite</h2>
                <p>Starting at $1099</p>
              </div>
              <div class="adx-list adx-flat">
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
                <div class="adx-item">Retina display1 2-core</div>
              </div>
              <button class="adx-btn btn-blue adx-round">&nbsp;Buy&nbsp;</button>
              <br />
              <button class="adx-btn btn-blue adx-round clear">
                &nbsp;Learn More >> &nbsp;
              </button>
            </div>
          </div>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-grey">
          <div class="flexx   ">
            <div class="adx-img"></div>
            <div class="adx-detail" style="width: 60%;">
              <div class="padding">
                <span> <br /> </span>
                <h1 class="text-normal">
                  Save 6% on holiday <br />
                  gifts at Apple.
                </h1>
                <span class="text-size18">
                  Get 6% Daily Cash back when you pay with Apple <br />
                  Card from now until December 31.*
                </span>
                <br />

                <button class="adx-btn btn-blue adx-round adx-sm clear">
                  &nbsp;Learn More >> &nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center">
          <div class="grid-2 padding-sm">
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <div class="topix">
                  <!--
                    <h1 class="text-normal text-size50">Mac for Education</h1>
                  -->

                  <span class="text-size18">
                    Play extraordinary. <br />
                    Find it on the App Store.
                  </span>
                  <br />
                  <button class="adx-btn btn-default clear">
                    Try it free5 >
                  </button>
                  <button class="adx-btn btn-default clear">Learn more ></button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <div class="topix">
                  <!--
                    <h1 class="text-normal text-size50">Mac for Education</h1>
                  -->

                  <span class="text-size18">
                    Everything you love about News. Plus.
                  </span>
                  <br />
                  <button class="adx-btn btn-default clear">
                    Try Apple News+ free6 >
                  </button>
                  <br />
                  <button class="adx-btn btn-default clear">Learn more ></button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <div class="topix">
                  <h1 class="text-normal text-size36">Mac for Education</h1>

                  <span class="text-size18">
                    Power to make big things happen in the classroom.
                  </span>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about Mac in education >
                  </button>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about special pricing for institutions >
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-black">
              <div class="adx-card adx-flat">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <div class="topix">
                  <h1 class="text-normal text-size36">
                    Mac for Higher Education
                  </h1>

                  <span class="text-size18">
                    Ready for everything college has to offer.
                  </span>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Learn about Mac in higher education >
                  </button>
                  <br />
                  <button class="adx-btn btn-blue clear">
                    Shop with Apple Education Pricing >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="adx-section bg-white">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat text-center bg-black">
          <p></p>
          <p></p>
          <div class="topix">
            <h1 class="text-normal text-size50">Viyo for Business</h1>

            <span class="text-size18">
              Get the power to take your business to the next level.
            </span>
            <br />
            <button class="adx-btn btn-blue clear">
              Learn about Mac in business >
            </button>
            <br />
            <button class="adx-btn btn-blue clear">
              Shop for your business >
            </button>
          </div>

          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
