<div class="adx-card adx-block adx-flat ">
  <div class="adx-avatar adx-flat adx-button">
    <div class="adx-img bg-dark" [routerLink]="['/profile/' + data.username]">
      <img [lazyLoad]="avatar" alt="" />
    </div>

    <h2 [routerLink]="['/profile/' + data.username]">
      {{ data.name }}&nbsp;
      <i *ngIf="data.isVerified" class="axr ax-shield-check color-tgreen"></i>
      <!-- <small style="opacity:0.5">@{{ data.username }}</small> -->
    </h2>
    <p>&#64;{{ data.username }}</p>

    <button
      *ngIf="data.id !== viewerId"
      (click)="onRelateToPerson(data.followedByViewer ? 2 : 1)"
      class="adx-btn adx-rounder adx-sm "
      [ngClass]="{
        'btn-pink outline': !data.followedByViewer,
        'btn-dark bg-black': data.followedByViewer
      }"
    >
      {{
        data.followedByViewer
          ? 'Following'
          : data.followsViewer
          ? 'Follow Back'
          : 'Follow'
      }}
    </button>
  </div>

  <!-- media here -->
  <div class="medias" *ngIf="showMedia">galaxy-media-person</div>

  <!-- artowrk -->
  <div class="artwork" *ngIf="showArtwork">
    <galaxy-media-person [personId]="profolioId"></galaxy-media-person>
  </div>

  <div class="adx-item adx-flat adx-sm">
    <p style="width: 100%;">
      {{ data.tagline }}
      <span class="float-right">
        <i *ngIf="isCurator" class="fal fa-music"></i>
        <!--
          {{ data.followers }} &nbsp; <i class="fal fa-users"></i>
          {{ data.followers }}
        -->
      </span>
    </p>
  </div>
</div>
