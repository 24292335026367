import { Person, Community, ICommunityChannel } from './user.model';
import { IEntity, Entity } from './entity.model';
import { Group } from './group.model';
import { Controller } from '../decorators';
import { ActivityTypeEnum, SocialConnectionTypeEnum, SocialEntityTypeEnum, StatusEnum } from '../../enums';

export interface IMessagex {
  id: number;
  person: Person;
  description: string;
  created: number;
  modified: number;
  entityId?: number;
  entityType?: SocialEntityTypeEnum; // could be blog, artwork, product, forum etc.
  parent?: Message;
}
class Message implements IMessagex {
  constructor(
    public id = 1,
    public person = new Person,
    public description = '',
    public entityId = 0,
    public entityTypeId = 0,
    public created = Math.round(new Date().getTime() / 1000),
    public modified = Math.round(new Date().getTime() / 1000)
  ) { }
}
@Controller({ name: 'Comment' })
export class Comment implements IMessagex {
  constructor(
    public id = 1,
    public person = new Person,
    public description = '',
    public entityId = 0,
    public entityType = SocialEntityTypeEnum.ARTIST,
    public rate = 0,
    public likeCount = 0,
    public commentCount = 0,
    public parent = null,
    public created = Math.round(new Date().getTime() / 1000),
    public modified = Math.round(new Date().getTime() / 1000)
  ) { }
}




export interface IActivity {
  id: number;
  activityType: ActivityTypeEnum;
  activity: string;
  location?: string;
  ipAddress?: string;
  person: Person;
  persons: Person[];
  community?: Community;
  communityChannel?: ICommunityChannel;
  personsCount?: number;
  subject?: IEntity;
  entityType?: SocialEntityTypeEnum;
  isPromo?: boolean;
  pinned?: boolean;
}
@Controller({ name: 'Activity' })
export class Activity implements IActivity {
  constructor(
    public id = 0,
    public activityType = ActivityTypeEnum.CREATE,
    public entityType = SocialEntityTypeEnum.ARTIST,
    public activity = '',
    public location = '',
    public ipAddress = '',
    public person = new Person(),
    public persons = [person],
    public subject = new Entity(),
    public isPromo = false
  ) { }
}




/**
 * Reactions to record
 * Likes, Laugh, Dislike etc
 * of a particular entity
 */
export class Reaction {
  constructor(
    public id = 0,
    public entityId = 1,
    public entityType = SocialEntityTypeEnum.ARTIST,
    public status = StatusEnum.PUBLISHED
  ) { }
}


@Controller({ name: 'Connection' })
export class Connection {
  constructor(
    public id = 0,
    public viewer = new Person,
    public person = new Person,
    public status = SocialConnectionTypeEnum.FOLLOW
  ) {

  }
}



@Controller({ name: 'SendMail' })
export class SendMail {
  constructor(
    public title = '',
    public description = '',
    public from = new Person,
    public to = []
  ) {

  }
}
