<div class="adx-btn-group" *ngIf="!infinitScroll" style="margin-top: 0px">
  <span *ngIf="page > 1">
    <button (click)="onPageClicked(1)" class="adx-btn btn-dark adx-round adx-icon" style="margin-right: 6px">
      <i class="fal fa-angle-double-left"></i>
    </button>
    <button (click)="onPageClicked(page - 1)" class="adx-btn btn-dark adx-round adx-icon" style="margin-right: 6px">
      <i class="fal fa-angle-left"></i>
    </button>
  </span>

  <span *ngIf="pagingType == 'button'; then buttonPager; else comboPager">
  </span>

  <button *ngIf="lastPage > visible" (click)="onPageClicked(visible)" class="adx-btn btn-dark adx-round adx-icon"
    style="margin-right: 6px">
    <i class="fal fa-ellipsis-h-alt"></i>
  </button>

  <button *ngIf="lastPage > 1 && page < lastPage" (click)="onPageClicked(page + 1)"
    class="adx-btn btn-dark adx-round adx-icon" style="margin-right: 6px">
    <i class="fal fa-angle-right"></i>
  </button>

  <button *ngIf="lastPage > page" (click)="onPageClicked(lastPage)"
    class="adx-btn btn-{{ page == lastPage ? 'blue' : 'dark' }} adx-round adx-icon" style="margin-right: 6px">
    <i class="fal fa-angle-double-right"></i>
  </button>
</div>

<ng-template #buttonPager>
  <button *ngFor="let pager of data" (click)="onPageClicked(pager)"
    class="adx-btn btn-{{ page == pager ? 'blue' : 'dark' }} adx-round adx-icon" style="margin-right: 6px">
    {{ pager }}
  </button>
</ng-template>

<ng-template #comboPager>
  <div *ngIf="data.length" class="adx-input adx-round no-margin no-padding" style="
      width: 50px;
      padding-top: 0;
      margin-top: 0;
      display: inline-block;
      vertical-align: top;
    ">
    <select (change)="onPageClicked($event.target.value)" [value]="page">
      <option *ngFor="let pager of data">
        {{ pager }}
      </option>
    </select>
  </div>
</ng-template>

<!-- infinite scroll -->

<div class="text-center">
  <button *ngIf="lastPage > 1 && page < lastPage" class="adx-btn adx-round btn-dark" (click)="onPageClicked(page + 1)">
    Load More
  </button>
</div>
