<section class="main-banner bg-black">
  <!-- style="background-image:url(assets/images/spring.jpg);" -->
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <!-- <div class=" content adx-card adx-flat">
          <h1 class="text-normal text-size16">
            <small class="adx-spread" style="font-weight: normal">
              {{ title }}
            </small>
            <br />
            <span style="font-size:30px;"> {{ description }} </span>
          </h1>
        </div> -->
        <!-- <galaxy-adaptive-card [isFlat]="true"> -->
          <ng-content></ng-content>
        <!-- </galaxy-adaptive-card> -->
      </div>
    </div>
  </div>
</section>
