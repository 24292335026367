<!-- <div *ngIf="(isLoading && !data.metrics) then dummy; else realData"></div> -->

<app-adx-quick-stat [data]='data.metrics'></app-adx-quick-stat>

<div class="adx-section no-padding">
  <div class="wrapper">
    <div class="adx-row">
      <!-- Menus -->
      <!-- <div class="adx-cols-12">
      <div class="text-center">
        <div class="adx-btn adx-round btn-dark">
          Records
        </div>
        <div class="adx-btn adx-round btn-dark">
          Transactions
        </div>
        <div class="adx-btn adx-round btn-dark">
          Appointment
        </div>
      </div>
    </div> -->
      <!-- Graphs -->
      <div class="adx-cols-12">
        <div class="graph-flex">
          <div class="graph">
            <galaxy-adaptive-card [isFlat]=true [autoFull]=true [showPadding]=false [shape]="'rounded'">
              <div class='topic' style="position: absolute; top:0; left:0; right: 0;">
                <div class="adx-divider">
                  Graph
                </div>
                <div>
                  <button class='adx-btn adx-round adx-iconx adx-flat btn-dark' style="margin:0"
                    (click)='onShowFilter()'>
                    <i class="axr ax-filter"></i> {{title}}
                  </button>
                </div>
              </div>

              <!-- <p>

            </p> -->
              <galaxy-chart-tool [class.is-loading]="isLoading" [isLoading]="isLoading" *ngIf="!isLoading"
                [showTitle]=false [data]='graphData' [theme]="theme" [xAxisData]='xAxisData'>
              </galaxy-chart-tool>

            </galaxy-adaptive-card>

          </div>
          <div class="numbers">
            <galaxy-adaptive-card [isFlat]=true [showPadding]=false [shape]="'rounded'" [classes]='"adx-table"'>
              <div style="height: 400px; overflow-y: auto;">
                <div *ngFor="let chart of data.graphs">
                  <div class="adx-divider">
                    {{chart.name | titlecase}} Records
                  </div>
                  <table [class]='theme'>
                    <thead>
                      <th>Date</th>
                      <th>Count</th>
                      <th>Amount</th>
                    </thead>
                    <tr *ngFor="let item of chart.raw">
                      <td>{{item.xAxis}}</td>
                      <td>{{item.count}}</td>
                      <td>GHS {{item.yAxis}}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <!-- {{rawData | json}} -->
            </galaxy-adaptive-card>
          </div>
        </div>
      </div>

    </div>


    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-menu">My Access Menus</div>
        <div class="menu-grid">
          <galaxy-adaptive-card [shape]="'rounded'" [showPadding]="false" [isFlat]="true" *ngFor="let item of menu"
            [routerLink]="'../'+item.route">
            <div class="menu-item">
              <div class="icon">
                <i [class]="item.icon"></i>
              </div>
              <div class="name">
                {{item.name}}
              </div>
            </div>
          </galaxy-adaptive-card>
        </div>
      </div>
    </div>


  </div>
</div>

<ng-content></ng-content>

<ng-template #dummy>
  <app-adx-quick-stat [data]='stats'></app-adx-quick-stat>

  <div class="adx-section no-padding">
    <div class="wrapper">
      <div class="adx-row">
        <!-- Graphs -->
        <div class="adx-cols-12">
          <div class="graph-flex">
            <div class="graph">
              <galaxy-adaptive-card [class.is-loading]="isLoading" [isFlat]=true [showPadding]=false
                [shape]="'rounded'">
                <div class='topic'>
                  <div class="adx-divider">
                    Graph
                  </div>
                  <div class="adx-input adx-round">
                    <select name="cat" [value]="1">
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Monthly</option>
                    </select>
                  </div>
                </div>

                <galaxy-chart-tool [class.is-loading]="isLoading" [showTitle]=false [data]='graphData' [theme]="theme"
                  [xAxisData]='xAxisData'>
                </galaxy-chart-tool>

              </galaxy-adaptive-card>

            </div>
            <div class="numbers">
              <galaxy-adaptive-card [isFlat]=true [showPadding]=false [shape]="'rounded'">

                <div class="adx-divider">
                  Numbers
                </div>

                <p>

                </p>
                <p>

                </p>
                <p>

                </p>
              </galaxy-adaptive-card>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>





<ng-template #realData>
  <app-adx-quick-stat [data]='data.metrics'></app-adx-quick-stat>

  <div class="adx-section no-padding">
    <div class="wrapper">
      <div class="adx-row">
        <!-- Menus -->
        <!-- <div class="adx-cols-12">
        <div class="text-center">
          <div class="adx-btn adx-round btn-dark">
            Records
          </div>
          <div class="adx-btn adx-round btn-dark">
            Transactions
          </div>
          <div class="adx-btn adx-round btn-dark">
            Appointment
          </div>
        </div>
      </div> -->
        <!-- Graphs -->
        <div class="adx-cols-12">
          <div class="graph-flex">
            <div class="graph">
              <galaxy-adaptive-card [isFlat]=true [autoFull]=true [showPadding]=false [shape]="'rounded'">
                <div class='topic' style="position: absolute; top:0; left:0; right: 0;">
                  <div class="adx-divider">
                    Graph
                  </div>
                  <div>
                    <button class='adx-btn adx-round adx-iconx adx-flat btn-dark' style="margin:0"
                      (click)='onShowFilter()'>
                      <i class="axr ax-filter"></i> {{title}}
                    </button>
                  </div>
                </div>

                <!-- <p>

              </p> -->
                <galaxy-chart-tool [class.is-loading]="isLoading" [isLoading]="isLoading" *ngIf="!isLoading"
                  [showTitle]=false [data]='graphData' [theme]="theme" [xAxisData]='xAxisData'>
                </galaxy-chart-tool>

              </galaxy-adaptive-card>

            </div>
            <div class="numbers">
              <galaxy-adaptive-card [isFlat]=true [showPadding]=false [shape]="'rounded'" [classes]='"adx-table"'>
                <div style="height: 400px; overflow-y: auto;">
                  <div *ngFor="let chart of data.graphs">
                    <div class="adx-divider">
                      {{chart.name | titlecase}} Records
                    </div>
                    <table [class]='theme'>
                      <thead>
                        <th>Date</th>
                        <th>Count</th>
                        <th>Amount</th>
                      </thead>
                      <tr *ngFor="let item of chart.raw">
                        <td>{{item.xAxis}}</td>
                        <td>{{item.count}}</td>
                        <td>GHS {{item.yAxis}}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- {{rawData | json}} -->
              </galaxy-adaptive-card>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>





<!-- modal -->
<galaxy-modal (onCloseModalClicked)='onCloseModal()' [showPadding]='false' [showModal]='showModal'>
  <div class="adx-divider">
    Filter Report
  </div>
  <div class="adx-input adx-round">
    <select name="categoryType" style="min-width: 98%;" [(ngModel)]='filterParam.categoryTypeSelected'
      (change)='onFilterChanged()'>
      <option value="0">Daily</option>
      <!-- <option value="1">Weekly</option> -->
      <option value="2">Monthly</option>
      <option value="3">Yearly</option>
    </select>
  </div>

  <!-- enalbe only for daily -->
  <!-- <div class="adx-input adx-round adx-label" *ngIf="+filterParam.categorySelected === 0">
    <label for="">Select Week Of Month</label>
    <select name="categoryType" [(ngModel)]='filterParam.weekSelected'>
      <option value="1">Week 1</option>
      <option value="2">Week 2</option>
      <option value="3">Week 3</option>
      <option value="4">Week 4</option>
    </select>
  </div> -->

  <!-- enable only for weekly -->
  <div class="adx-input adx-round adx-label" *ngIf="+filterParam.categoryTypeSelected <= 1">
    <label for="">Select Month Of Year</label>
    <input type="month" name="monthOfYear" [(ngModel)]='monthOfYearInput'>
    <!-- <select name="categoryType" [(ngModel)]='filterParam.categoryTypeSelected' (change)='onReloadData()'>
      <option value="1">January</option>
      <option value="2">Feburay</option>
      <option value="2">March</option>
      <option value="2">April</option>
      <option value="2">May</option>
      <option value="2">June</option>
      <option value="2">July</option>
    </select> -->
  </div>

  <!-- enable only for months -->
  <div class="adx-input adx-round adx-label" *ngIf="+filterParam.categoryTypeSelected === 2">
    <label for="">Filter By Year</label>
    <input type="number" name="yearSelected" [(ngModel)]='filterParam.yearSelected' min='1980' maxlength="4">
  </div>


  <!-- enable only for yearly -->
  <div *ngIf="+filterParam.categoryTypeSelected === 3">
    <div class="adx-input adx-round adx-label">
      <label for="">Filter From The Year</label>
      <input type="number" min='1980' maxlength="4" name="romYearSelected" [(ngModel)]='filterParam.fromYearSelected'>
    </div>
    <div class="adx-input adx-round adx-label">
      <label for="">Filter To The Year</label>
      <input type="number" min='1980' maxlength="4" name="romYearSelected" [(ngModel)]='filterParam.toYearSelected'>
    </div>
  </div>

  <div>
    <button class='adx-btn adx-round adx-sm btn-default' (click)='onCloseModal()'>
      Cancel
    </button>

    <button class='adx-btn adx-round adx-sm btn-tgreen float-right' (click)='onFilterData()'>
      Filter
    </button>
  </div>

</galaxy-modal>
