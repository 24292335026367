import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { EntityApiModule } from '@galaxy/entity-api';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { UiSpaceComponent } from './ui-space/ui-space.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { UiModule } from '@galaxy/ui';
import { FooterComponent } from './company/footer/footer.component';
import { HeaderComponent } from './company/header/header.component';
import { UiHeaderComponent } from './ui-header/ui-header.component';
import { PersonComponent } from './person/person.component';
import { PersonNewComponent } from './person/person-new/person-new.component';
import { AppRoleClaimsComponent } from './app-role-claims/app-role-claims.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationNewComponent } from './reservation/reservation-new/reservation-new.component';
import { ReservationProfileComponent } from './reservation/reservation-profile/reservation-profile.component';
import { ProductComponent } from './store-components/product/product.component';
import { ProductNewComponent } from './store-components/product/product-new/product-new.component';
import { ProductPriceComponent } from './store-components/product/product-price/product-price.component';
import { EntityImageComponent } from './entity-image/entity-image.component';
import { StoreCenterComponent } from './store-components/store-center/store-center.component';
import { StoreCartComponent } from './store-components/store-center/store-cart/store-cart.component';
import { StoreListComponent } from './store-components/store-center/store-list/store-list.component';
import { StoreGridComponent } from './store-components/store-center/store-grid/store-grid.component';
import { PersonListComponent } from './person/shared/person-list/person-list.component';
import { PersonGridComponent } from './person/shared/person-grid/person-grid.component';
import { EntityCardComponent } from './entity-card/entity-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PersonCustomerCareComponent } from './person/person-customer-care/person-customer-care.component';
import { AdQuickStatComponent } from './ad-quick-stat/ad-quick-stat.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { AccessLevelBarComponent } from './access-level-bar/access-level-bar.component';
import { ActionBtnsComponent } from './action-btns/action-btns.component';
import { UiEntityActivityComponent } from './ui-entity-activity/ui-entity-activity.component';
import { PersonSelectComponent } from './person/person-select/person-select.component';
import { PersonActvityComponent } from './person/person-actvity/person-actvity.component';
import { CartItemComponent } from './store-components/store-center/store-cart/cart-item/cart-item.component';
import { QrCodeModule  } from 'ng-qrcode';
import { InvoiceReceiptComponent } from './invoice-receipt/invoice-receipt.component';
import { ReservationViewComponent } from './reservation/reservation-view/reservation-view.component';
import { ProfileViewContainerComponent } from './profile-view-container/profile-view-container.component';
import { CodeScannerComponent } from './code-scanner/code-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AppDashboardMetricComponent } from './app-dashboard-metric/app-dashboard-metric.component';
import { AdClientAvatarComponent } from './ad-client-avatar/ad-client-avatar.component';
import { BusinessBranchServicesSelectComponent } from './person/person-customer-care/business-branch-services-select/business-branch-services-select.component';
import { CustomerRequestComponent } from './customer-request/customer-request.component';
import { CustomerRequestViewComponent } from './customer-request/customer-request-view/customer-request-view.component';
import { NoDataComponent } from './no-data/no-data.component';
import { PersonJobDescriptionComponent } from './person-job-description/person-job-description.component';
import { HrWidgetComponent } from './hr-widget/hr-widget.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MomentModule,
    EntityApiModule,
    UiModule,
    LazyLoadImageModule,
    QrCodeModule ,
    ZXingScannerModule

  ],
  declarations: [
    WorkspaceComponent,
    SideMenuComponent,
    DataGridComponent,
    UiSpaceComponent,
    HeaderComponent,
    FooterComponent,
    UiHeaderComponent,
    PersonComponent,
    PersonListComponent,
    PersonGridComponent,
    PersonNewComponent,
    PersonCustomerCareComponent,
    AppRoleClaimsComponent,
    ReservationComponent,
    ReservationNewComponent,
    ReservationProfileComponent,
    ReservationViewComponent,
    ProductComponent,
    ProductNewComponent,
    ProductPriceComponent,
    EntityImageComponent,
    StoreCenterComponent,
    StoreListComponent,
    StoreGridComponent,
    StoreCartComponent,
    EntityCardComponent,
    AdQuickStatComponent,
    StatusBarComponent,
    AccessLevelBarComponent,
    ActionBtnsComponent,
    UiEntityActivityComponent,
    PersonSelectComponent,
    PersonActvityComponent,
    CartItemComponent,
    InvoiceReceiptComponent,
    ProfileViewContainerComponent,
    CodeScannerComponent,
    AppDashboardMetricComponent,
    AdClientAvatarComponent,
    BusinessBranchServicesSelectComponent,
    CustomerRequestComponent,
    CustomerRequestViewComponent,
    NoDataComponent,
    PersonJobDescriptionComponent,
    HrWidgetComponent
  ],
  exports: [
    WorkspaceComponent,
    DataGridComponent,
    UiSpaceComponent,
    HeaderComponent,
    FooterComponent,
    PersonComponent,
    PersonCustomerCareComponent,
    PersonNewComponent,
    AppRoleClaimsComponent,
    ReservationComponent,
    ReservationNewComponent,
    ReservationProfileComponent,
    ReservationViewComponent,
    ProductComponent,
    ProductNewComponent,
    ProductPriceComponent,
    EntityImageComponent,
    StoreCenterComponent,
    AdQuickStatComponent,
    StatusBarComponent,
    AccessLevelBarComponent,
    ActionBtnsComponent,
    UiEntityActivityComponent,
    PersonSelectComponent,
    PersonActvityComponent,
    InvoiceReceiptComponent,
    CartItemComponent,
    ProfileViewContainerComponent,
    CodeScannerComponent,
    AppDashboardMetricComponent,
    AdClientAvatarComponent,
    CustomerRequestComponent,
    CustomerRequestViewComponent,
    NoDataComponent,
    HrWidgetComponent
  ]
})
export class SuiteUiModule { }
