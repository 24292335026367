<div class="post-wrapper {[theme}}">
  <div class="media">
    <div class="thumbnail" *ngIf="!isLoading">
      <div *ngFor="let item of images; index as i" (click)="onImageClicked(data[i], i)">
        <img [src]="item" alt="item" />
      </div>

      <div class="bg-black" *ngFor="let item of placeholder"></div>

      <!--
        <img src="assets/images/galaxy.jpg" alt="" class="img-responsive" />
      -->
    </div>
    <div class="p-overlay"></div>
  </div>
</div>

<!-- view image -->
<galaxy-modal (onCloseModalClicked)="onCloseClicked($event)" [showModal]="showImageModal" [fixedWidth]="false"
  [width]="70" [showPadding]="false">

  <div class="paddingx">
    <galaxy-media-card-modal *ngIf="showImageModal" [entity]="projectSelected"></galaxy-media-card-modal>
  </div>

  <galaxy-media-navigator [showNavBack]="prevProject" [showNavNext]="nextProject"
    (navBack)="onImageClicked(prevProject, prevProjectIndex)" (navNext)="onImageClicked(nextProject, nextProjectIndex)">
  </galaxy-media-navigator>
</galaxy-modal>
