<div class="account-flex">
  <div>
    <galaxy-adaptive-card [autoMiniPadding]=true [isFull]=true [shape]="'rounded'" [showPadding]="false"
      [isFlat]="true">
      <!-- <div class="adx-divider">Basic Info</div> -->
      <div style="padding:12px; display: flex;">
        <div>
          <galaxy-entity-image [image]="person.image" [size]="'md'" [isRound]="true"></galaxy-entity-image>
        </div>
        <div class="text-centerx" style="padding-left: 16px;">


          <h2 class="text-normal" style="margin-bottom: 0; font-weight: 500;">{{person.name}}</h2>
          <small style="font-size: 11px;">
            {{ person.gender | enumKey:genderEnum}} - {{today | amDifference: (person.birthdate * 1000) :'years'}} years
          </small>
        </div>


      </div>

    </galaxy-adaptive-card>
    <div class="adx-card adx-full adx-flat" *ngFor="let item of metric">
      <div class="adx-item bg-special adx-full adx-flat profile-metric">
        <span class="metric-icon" [class.is-loading]="isMetricLoading">
          <i [class]="item.icon"></i>
        </span>
        <small class="color-pinkx">{{item.label}} :</small>
        <span style="font-family: monospace;">
          {{item.value}}
        </span>
      </div>
    </div>
  </div>

  <div>
    <galaxy-adaptive-card [showPadding]=false [isFull]=true [shape]="'rounded'" [showPadding]="false" [isFlat]="true">
      <div class="adx-divider">Basic Info</div>
      <div class="adx-list adx-flat adx-ellipse">
        <div class="adx-item {{theme}}">
          <small class="color-pink"> PersonID :</small>

          {{person.personId ?? '---'}}
        </div>
        <div class="adx-item {{theme}}">
          <small class="color-pink">Phone One :</small>

          {{person.phoneOne ?? '---'}}
        </div>
        <div class="adx-item {{theme}}">
          <small class="color-pink">Email :</small>

          {{person.email ?? '---'}}
        </div>

      </div>
    </galaxy-adaptive-card>

  </div>
  <div>
    <galaxy-adaptive-card [showPadding]=false [isFull]=true [shape]="'rounded'" [showPadding]="false" [isFlat]="true">
      <div class="adx-divider">Location Info</div>
      <div class="adx-list adx-flat adx-ellipse">
        <div class="adx-item {{theme}}">
          <small class="color-pink">City :</small>

          {{person.city ?? '---'}}
        </div>
        <div class="adx-item {{theme}}">
          <small class="color-pink">State :</small>

          {{person.state ?? '---'}}
        </div>
        <div class="adx-item {{theme}}">
          <small class="color-pink">Country :</small>

          {{person.country ?? '---'}}
        </div>
      </div>
    </galaxy-adaptive-card>



  </div>

</div>
