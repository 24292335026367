<small style="opacity: .6;" *ngIf="viewingEntity">
  <span class="float-right " style="padding-right: 16px;">
    {{ data.created * 1000 | date: 'shortTime' }} - {{ data.created * 1000 | date }}
  </span>
</small>
<div class="clearfix"></div>
<div class="action-flex">
  <div>
    <!-- analytics if its mine -->
    <button class="adx-btn adx-round clear" [ngClass]="{
      'btn-dark': theme === 'light',
      'btn-grey': theme === 'dark'
    }" (click)="onViewAnalysis(true)">
      <i class="axl ax-brand-google-analytics"></i>
      <span *ngIf="data.hit">{{ data.hit | countSuffix: 2}}</span>
    </button>
  </div>

  <div>
    <!-- comment -->
    <button class="adx-btn adx-round clear message-btn" [ngClass]="{
        'btn-dark': theme === 'light',
        'btn-grey': theme === 'dark'
      }" (click)="onComment()">
      <i class="axl ax-message-dots"></i>
      <span *ngIf="data.commentCount">{{ data.commentCount | countSuffix: 2 }}</span>
    </button>
  </div>

  <div>
    <!-- retweet -->
    <button class="adx-btn adx-round clear repost-btn" [ngClass]="{
        'btn-dark': theme === 'light',
        'btn-grey': theme === 'dark'
      }" [class.btn-green]="data.reposted" (click)="onRepostModalToggle()">
      <i [ngClass]="{ axs: data.reposted, axl: !data.reposted }" class="ax-reshare"></i>
      <span *ngIf="data.repostCount">{{ data.repostCount | countSuffix: 2 }}</span>
    </button>
  </div>

  <div>
    <!-- like -->
    <button class="adx-btn adx-round clear like-btn" [ngClass]="{
        'btn-dark': theme === 'light',
        'btn-grey': theme === 'dark'
      }" [class.btn-pink]="data.liked" (click)="onLike()">
      <i [ngClass]="{ axs: data.liked, axl: !data.liked }" class="ax-heart"></i>
      <span *ngIf="data.likeCount">{{ data.likeCount | countSuffix: 2 }}</span>
    </button>
  </div>

  <div>
    <!-- share -->
    <button class="adx-btn adx-round clear" [ngClass]="{
      'btn-dark': theme === 'light',
      'btn-grey': theme === 'dark'
      }" (click)="onShare()">
      <i class="axl ax-share"></i>
    </button>
  </div>


</div>

<!--
  <div class="default-flex" *ngIf="false">
    <div class="btns">
      <button
        class="adx-btn adx-icon adx-round clear "
        [ngClass]="{
          'btn-dark': theme === 'light',
          'btn-grey': theme === 'dark'
        }"
        [class.btn-pink]="isUserLike"
        (click)="onLike()"
      >
        <i [ngClass]="{ fa: isUserLike, fal: !isUserLike }" class=" fa-heart"></i>
      </button>

      <button
        class="adx-btn adx-icon adx-round clear "
        [ngClass]="{
          'btn-dark': theme === 'light',
          'btn-grey': theme === 'dark'
        }"
        (click)="onComment()"
      >
        <i class="fal fa-comment-alt"></i>
      </button>
      <button
        class="adx-btn adx-icon adx-round clear "
        [ngClass]="{
          'btn-dark': theme === 'light',
          'btn-grey': theme === 'dark'
        }"
        (click)="onShare()"
      >
        <i class="fal fa-upload"></i>
      </button>
    </div>

    <div class="counts padding-sm text-right">
      <span> {{ likeCount }} &nbsp;likes </span>
      <span> {{ commentCount }} &nbsp;comment </span>
    </div>
  </!
-->
<galaxy-modal (onCloseModalClicked)="onViewAnalysis(false)" [showModal]="viewAnalytics" [showPadding]="false">
  <div *ngIf="!showAnalytics">
    <div class="padding">
      <h3 class="title text-normal">Views</h3>
      <p style="opacity: .6;">
        Times this post was seen on Connect. It can take a few minutes dor the view count to appear.
      </p>
    </div>
    <div class="text-center">
      <button class="adx-btn adx-round btn-darker adx-sm adx-flat" (click)="onViewAnalysis(false)">
        &nbsp; &nbsp; &nbsp; Dismiss &nbsp; &nbsp; &nbsp;
      </button>
    </div>

  </div>



  <app-post-analytic *ngIf="showAnalytics" [data]="data"></app-post-analytic>
</galaxy-modal>


<!-- Repost modal -->
<galaxy-modal (onCloseModalClicked)="onRepostModalToggle()" [showModal]="showRepostModal" [showPadding]="false">

  <app-post-mini [data]='data'></app-post-mini>

  <p class='text-size18 text-center'>
    You are about to {{data.reposted?'Undo':''}} Repost?
  </p>

  <div>

    <button (click)="onRepostModalToggle()" class="adx-btn adx-round adx-sm btn-default">
      Close
    </button>

    <button (click)="onRetweet()" class="adx-btn adx-round adx-sm float-right btn-{{data.reposted?'pink':'tgreen'}}">
      {{data.reposted?'Undo ':''}} Repost
    </button>
  </div>

</galaxy-modal>
