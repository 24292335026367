import { Component, OnInit, Input } from '@angular/core';
import { IEntity, EmbedService, Entity } from '@galaxy/entity-api';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() data: IEntity = new Entity();
  @Input() tagline: string = 'tagline';

  @Input()
  showOptions?: { comment: boolean; created: boolean; modified: boolean } = {
    comment: true,
    created: true,
    modified: true
  };

  @Input() size: 'lg' | 'sm' | 'xs' = 'lg';
  userImage: SafeResourceUrl = '';
  constructor(private _embed: EmbedService) { }

  ngOnInit() {
    this.userImage = this.embedImage(this.data.person.image);
    // console.log('this is the user image in the avatar comp', this.userImage, this.data);
  }

  private embedImage(path: string) {
    // console.log('user avatar is running');

    // const size = this.size === 'lg' ? 'md' : this.size;

    return this._embed.imgUrl(path, 'sm');
  }
}
