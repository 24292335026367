<galaxy-adaptive-card>
  <div class="adx-divider">Topic</div>
  <div class="adx-item">
    General
  </div>
  <div class="adx-item">
    Sports
  </div>
  <div class="adx-item">
    Politics
  </div>
  <div class="adx-item">
    Hello
  </div>
  <div class="adx-item">
    Hello
  </div>
</galaxy-adaptive-card>
<br />
<br />
<galaxy-adaptive-card>
  <div class="adx-divider">Popular Articles</div>
  <adx-blog></adx-blog>
  <adx-blog></adx-blog>
</galaxy-adaptive-card>

<br />
<br />
<adx-footer></adx-footer>
