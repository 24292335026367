import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Business, BusinessEntity, IBusiness, IBusinessEntity, ITransaction, SuiteApiService } from '@galaxy/entity-api';

@Component({
  selector: 'app-profile-view-container',
  templateUrl: './profile-view-container.component.html',
  styleUrls: ['./profile-view-container.component.scss']
})
export class ProfileViewContainerComponent implements OnInit {

  @Input() businessEntity: IBusinessEntity = new BusinessEntity;
  @Input() data: any;
  @Input() transaction?: ITransaction;

  @Input() isLoading: boolean = false;
  @Output() closeProfile = new EventEmitter();
  currency: string;


  showModal = false;
  business: IBusiness = new Business;

  today = (new Date).getTime()


  constructor(private _suiteApi: SuiteApiService) { }

  ngOnInit(): void {
    // get business
    this.business = this._suiteApi.getActiveBusiness();
    this.currency = this._suiteApi.getActiveBusiness().currency;
  }



  onCloseClicked() {
    this.closeProfile.emit();
  }

  onPrint() {
    const printStyle = `
    .reciept {
      font-family: monospace;

    }
    .reciept .cart-item {
        display: flex;
        padding: 4px 16px;


      }

      .reciept .cart-item div {
        flex: 1;
      }

      .text-center{
        text-align:center
      }

      `;

    const mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style>' + printStyle + '</style>');
    mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(document.getElementById('ref-print').outerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }
}
