import { Component, OnInit } from '@angular/core';
import { AuthService, SocialApiService, Person, IUser, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {
  isLoading: boolean = true;
  data: IUser[] = [];
  searchValue: string = '';

  pageSize: number = 50;
  page: number = 1;
  totalData: number = 0;
  groupType: number = 62;
  categorySelected: number = 39;

  viewerId: number = 0;
  constructor(
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,

    private _socialApi: SocialApiService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.pageSize = 50;
    this.page = 1;

    // logedin user id
    this.viewerId = this._authService.loggedIn ? this._authService.currentUser.gid.social?.u : 0;
    this.getData();
  }

  onSearch() {
    this.onReloadData();
  }


  getData(): any {
    // get the id of the person
    this._socialApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.viewerId,

      })
      .getPageOf(Person)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            // this.isLoading = false;

            this.data = <Person[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onReloadData() {
    // reset limit value to default if its lower than 5
    this.pageSize = this.pageSize > 20 ? this.pageSize : 20;
    this.page = 1;
    this.getData();
  }
}
