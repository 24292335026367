import { StatusEnum } from '../../enums';
import { Controller } from '../decorators';

export interface ISponsor {

  id: number;
  name: string;
  description: string;
  image?: string;
  postId?: string;
  status: StatusEnum;
  created?: number;
  modified?: number;

}

@Controller({ name: 'Sponsor' })
export class Sponsor implements ISponsor {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public status =  StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;
  }
}
