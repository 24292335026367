import { Injectable, EventEmitter } from '@angular/core';
import { WebSocketService } from './web-socket.service';

import { Person } from '../../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamSocketService extends WebSocketService {

  callStatus = new EventEmitter<number>()
  constructor() {
    super({ namespace: 'stream', port: 5000 });
  }


  getMessages(): Observable<any> {
    return this.fromEvent('message');
  }

  sendMessage(payload: any): void {
    this.emit('send-message', payload);
  }

  makeCall(isVideo: boolean, from: Person, to: Person) {

  }

  establishConnection() {

  }

  toggleVideo() {

  }

  endCall() {

  }

}
