import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';

const rootUrl = 'https://socket-api.aiira.co';
const socketUri = '//127.0.0.1:8080';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends Socket {

  constructor(route: { namespace: string, port: number }) {

    const port = route.port ? route.port : 5000;
    const enableProd = true;
    // const uri = socketUri + ':' + port + '/' + route.namespace;
    const uri = (enableProd ? rootUrl : socketUri) + '/' + route.namespace;

    super({
      url: uri
    });
  }

  /**
   * use for posts/ messages/ comments/ likeCount/ commentCount / repostCount
   * @param eventName
   */
  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.on(eventName, (data) => {
        subscriber.next(data);
      });

    })
  }

}
