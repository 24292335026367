<div class="adx-blog {{ size }}">
  <div class="adx-img" (click)="onViewEntity()" [defaultImage]="defaultImage" [lazyLoad]="cover"></div>
  <div class="adx-detail">
    <div style="display: flex;">
      <div *ngIf="showPerson"  class="adx-avatar adx-xs adx-flat type"
        [routerLink]="['/profile/' + data.person.username + '/blog']">
        <div class="adx-img" style="background-color: black;">
          <img [defaultImage]="defaultImage" [src]="userImage" [alt]="data.person.username" />
        </div>
        <h2>
          {{ data.person.username || 'person name' }}
        </h2>
      </div>

        <div *ngIf="!isFeed" class="type color-{{ color }}">
          {{ data.type | enumKey:blogTypeEnum | maxLength:8 }}
        </div>


    </div>

    <!-- [routerLink]="['/blogs/article/', data.alias, data.id]" -->
    <h2 class="blog-title text-normal" (click)="onViewEntity()" [class.reset-font]="isFeed">
      {{ data.title | maxLength: maxTitleLength || 'Title of Article' }}
    </h2>
    <div *ngIf="showDetail" class="description zero-p {{ size }}" [innerHTML]="data.description | maxLength: 200"></div>
  </div>
</div>
<div class="adx-item adx-flat adx-sm" *ngIf="showCounts">
  <span> <i class="axs ax-brand-google-analytics"></i> {{ data.hit }} </span>
  <span>&nbsp; <i class="axs ax-heart"></i> {{ data.likeCount }} </span>
  <span>&nbsp; <i class="axs ax-message-dots"></i> {{ data.commentCount }}
  </span>
  <span class="date">
    {{ data.created * 1000 | amTimeAgo: true }} ago &nbsp; &nbsp;</span>
</div>
