
export enum BlogTypeEnum
{
    ARTICLE = 1,//50;
    GENERAL = 2,//51;
    MAKING_OF = 3,//52;
    NEWS = 4,//53;
    TUTORIAL = 5,//54;
    WORK_IN_PROGRESS = 6,//55;

}

