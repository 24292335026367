import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { AuthService, ArtistApiService, StoreApiService, PlayApiService, NotifyService, ICollection, Collection, IEntityCollection, EntityCollection, AppSettingService, IEntity, ErrorHandlerService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-collection',
  templateUrl: './media-collection.component.html',
  styleUrls: ['./media-collection.component.scss']
})
export class MediaCollectionComponent implements OnInit, OnDestroy {

  @Input() title = 'Collection';
  @Input() icon = 'bookmark';
  @Input() collectionType = 84; // album = 83, collection = 84

  @Input() entityType: 'photo' | 'media' | 'product' = 'photo';

  activeCollection: ICollection;
  editCollection = new Collection;
  data: ICollection[] = [];
  entityData: IEntity[] = [];
  entityCollectionData: IEntityCollection[] = [];
  isLoading: boolean = true;
  showCollectionEntities: boolean = false;


  pageSize: number = 50;
  page: number = 1;
  totalData: number = 0;

  searchValue: string = '';
  searchValueEt: string = '';

  showEntityCollection: boolean = false;
  showEditModal: boolean = false;
  isUpdating: boolean = false;
  private _xApi;

  viewerId: number = 0;
  totalEntityData?: any;

  theme: string = 'light'; 
themeUpdated$!:Subscription;
  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _artistApi: ArtistApiService,
    private _storeApi: StoreApiService,
    private _playApi: PlayApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // set theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.viewerId = this._authService.currentUser.gid.social.u;


    this.isLoading = true;
    this.showEntityCollection = false;
    this.searchValue = '';

    // console.log('entity type is --> ', this.entityType);

    this.getApi(this.entityType);
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const type: SimpleChange = changes.entityType;

  //   if (!type.firstChange && type.previousValue !== type.currentValue) {
  //     this.isLoading = true;
  //     this.getApi(type.currentValue);
  //   }

  // }

  getApi(entityType: string) {

    if (this._authService.loggedIn) {
      switch (entityType) {

        case 'photo':
          this._xApi = this._artistApi;
          break;

        case 'media':
          this._xApi = this._playApi;
          break;

        case 'product':
          this._xApi = this._storeApi;
          break;

        default:
          this._xApi = this._artistApi;
          break;
      }
      this.getData();
    } else {
      this._notify.error('Please login to continue');
      this.isLoading = false;
    }
  }

  onReload() {
    if (this.searchValue.trim() === '' && this.totalData === 0) {
      return;
    }

    this.isLoading = true;
    this.pageSize = 10;
    this.page = 1;
    this.getData();

  }


  getData(): any {
    this._xApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        id: this.viewerId,
        category: this.collectionType
      })
      .getPageOf(Collection)
      .subscribe(
        {
          next: (res) => {
            this.data = res.data as ICollection[];
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  // viewing the entities of a  collection

  onViewCollection(item: ICollection) {

    if (item.entityCount === 0) {
      this._notify.info('Sorry, ' + item.title + ' Collection is empty');
      return;
    }
    this.activeCollection = item;
    this.showEntityCollection = true;

  }

  onCloseClicked() {
    this.showEntityCollection = false;
    this.showEditModal = false;
  }

  onEditCollection(item: ICollection) {
    this.editCollection.id = item.id;
    this.editCollection.title = item.title;
    this.editCollection.description = item.description;
    this.editCollection.status = item.status;

    this.showEditModal = true;

  }


  createCollection() {

    if (this.searchValue.trim() === '') {
      this._notify.info('Search Value cannot be empty')
      return;
    }
    const newCollection = new Collection;
    newCollection.title = this.searchValue.trim();
    newCollection.tag = this.searchValue.trim().toLowerCase().replace(' ', ',');
    newCollection.person.id = this.viewerId;
    // newCollection.mediaType = this.collectionType;

    this._xApi
      .post(newCollection)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.success('New collection created');
              newCollection.id = res.data.id;
              this.data.unshift(newCollection);
            } else {
              this._notify.error('Something happened, please try again.');
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }


  onUpdateCollection() {
    this.isUpdating = true;
    this._xApi.setParams({}, this.editCollection.id.toString())
      .put(this.editCollection)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              this._notify.success('Collection updated');
              // this is temporal, will find a better way to update the collection that has been changed
              this.getData();
            } else {
              this._notify.error('Something happened, please try again.');
            }
            this.isUpdating = false;
            this.onCloseClicked();
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      )
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
