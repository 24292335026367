<br />
<br />
<div class="wrapper-fluid">
  <!-- wrapper starts -->
  <div class="slide-cover" (window:resize)="onResize($event.currentTarget.innerWidth)">
    <div class="adx-row" [class.show-detail]="showDetail">
      <div class="adx-cols-8" [class.adx-colx-12-1]="miniMode">
        <div class="adx-header adx-flat">
          <h2 class="title text-normal" style="font-weight: 500;">{{ title }}</h2>
          <span class="flex-space"></span>
          <div class="actions" style="max-width: 500px">
            <span *ngIf="showSearch" class="search-content">
              <div class="adx-input adx-round" style="width: 200px; transform: scale(0.9)">
                <input type="text" placeholder="Search ..." name="searchValue" [(ngModel)]="searchValue"
                  (keydown.enter)="onSearchTriggered()" />
              </div>
            </span>
            <button class="adx-btn adx-icon  adx-round search-btn {{
                showSearch ? 'btn-pink' : 'btn-default'
              }}" (click)="onToggleSearch()">
              <i class="axl ax-{{ showSearch ? 'x' : 'search' }}"></i>
            </button>
            <!-- extra inputs here/ -->
            <div>
              <ng-content select=".header-inputs"></ng-content>
            </div>

            <!-- action buttons -->
            <div *ngIf="(miniMode && !showSearch) || (!miniMode && showSearch)">
              <ng-content select=".header-btns"></ng-content>
            </div>

            <span class="constant-btns" *ngIf="showConstantBtns">
              <!-- <button class="adx-btn adx-shadow adx-icon adx-round">
                <i class="axl ax-printer"></i>
              </button> -->
            </span>
          </div>
        </div>
        <!-- data -->
        <div class="clearfix"></div>
        <ng-content select=".data"></ng-content>

        <!-- <app-pagination
          [page]="paginatorPage"
          [total]="paginatorTotal"
          [limit]="paginatorLimit"
          (pageClicked)="onPaginateData($event)"
        >
        </app-pagination> -->
      </div>
      <div class="adx-cols-4" [class.adx-colx-12-1]="miniMode">
        <div class="detail-card">
          <div class="adx-header adx-flat">
            <h2 class="title text-normal" style="font-weight: 500">{{ detail }}</h2>
            <span class="float-right">
              <button *ngIf="showDetail" class="adx-btn adx-icon adx-round" style="transform: rotateZ(45deg);"
                (click)="onShowDetail()">
                <i class="axl ax-plus"></i>
              </button>
            </span>
          </div>
          <div style="margin-top:-4px">
            <galaxy-adaptive-card [softShadow]="true" [shape]='"rounded"' [showPadding]="false" [autoMiniPadding]=true
              [autoFull]=true [overflow]="true">
              <div class="clearfix"></div>
              <ng-content select=".detail"></ng-content>
            </galaxy-adaptive-card>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- wrapper ends -->
</div>
