<galaxy-adaptive-card [isFlat]="true" [shape]="'rounded'" [overflow]="true" [isFull]="true" [autoDimBlack]="true"
  [showPadding]="false">
  <div class="post-padding">

    <div class="adx-card adx-block adx-flat post" style="min-height: 150px; overflow: visible">
      <div class="content">
        <div *ngIf="!isNotification" class="adx-avatar adx-sm adx-flat">
          <div class="adx-img"></div>
          <h2>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</h2>
          <p>&nbsp;&nbsp; &nbsp;</p>
        </div>
        <div *ngIf='showImage' class="img-placeholder is-loading">

        </div>
        <div class='description-placeholder'></div>
        <div class='description-placeholder second'></div>
      </div>
    </div>
  </div>
</galaxy-adaptive-card>
