<div class="adx-section bg-white">
  <div class="centered">
    <i class="axl ax-brand-aiira ax-5x"></i>
    <br>
    <span class="text-size18">
      One Account For All
    </span>
    <br>
    <br>
    <galaxy-adaptive-card [autoDimBlack]='true' [shape]='"rounded"'>
      <div class="adx-card adx-flat" [class.is-loading]="isLoading">
        <form (ngSubmit)="onRequestReset()" #requestForm="ngForm" class="adx-form input-tgreen">
          <p>
            Enter your email to request for Account Deletion
            <br>
            <small class="color-pink">
              Account deletion will take 7 working days to take effect. You have that duration to revert your action.
            </small>
          </p>
          <div class="adx-input adx-icon adx-round">
            <!-- <label for="email">Username/Email</label> -->
            <span> <i class="axr ax-user"></i> </span>
            <input type="string" [(ngModel)]="userName" name="username" placeholder="Enter your username" required
              [readOnly]="isLoading" />
          </div>
          <div class="adx-input adx-icon adx-round">
            <!-- <label for="email">Username/Email</label> -->
            <span> <i class="axr ax-mail"></i> </span>
            <input type="email" [(ngModel)]="userEmail" name="email" placeholder="Enter your email"
              [pattern]="emailPattern" required [readOnly]="isLoading" />
          </div>

          <div class="adx-input adx-icon adx-round">
            <!-- <label for="email">Username/Email</label> -->
            <span> <i class="axr ax-lock"></i> </span>
            <input type="password" [(ngModel)]="userPassword" name="userPassword" placeholder="Enter your password" required
              [readOnly]="isLoading" />
          </div>

          <div class="adx-input adx-icon adx-round">
            <!-- <label for="email">Username/Email</label> -->
            <span> <i class="axr ax-pencil"></i> </span>
            <textarea [(ngModel)]="reason" name="reason" placeholder="Reason" required
              [readOnly]="isLoading" > </textarea>
          </div>

          <p style="padding: 0 16px">
            <button class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
              [disabled]="isLoading || !requestForm.valid">
              Delete Account
            </button>

          </p>
        </form>
      </div>
    </galaxy-adaptive-card>
  </div>
</div>
