import { Injectable } from '@angular/core';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class MessengerSocketService extends WebSocketService {

  constructor() {
    super({ namespace: 'messenger', port: 5000 });
  }

}
