import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-person-placeholder',
  templateUrl: './person-placeholder.component.html',
  styleUrls: ['./person-placeholder.component.scss']
})
export class PersonPlaceholderComponent implements OnInit, OnDestroy {

  @Input() isMini: boolean = false;
  theme: string = '';themeUpdated$!: Subscription;
  constructor(
    private _appSetting: AppSettingService
  ) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);

  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
