import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() size: string = 'sm';
  @Input() theme: string = 'dark';
  @Input() isReply: boolean = false;
  showReply: boolean = false;
  @Input() color: string = '';

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;
  }
  onToggleReply() {
    this.showReply = !this.showReply;
  }
}
