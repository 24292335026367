import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppSettingService, Group, IGroup } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-adx-group',
  templateUrl: './ad-group.component.html',
  styleUrls: ['./ad-group.component.scss']
})
export class AdGroupComponent implements OnInit, OnDestroy {

  @Input() isList: boolean = true;
  @Input() isMini: boolean = true;
  @Input() showImage: boolean = true;

  @Input() data: IGroup = new Group;

  @Output() itemClicked = new EventEmitter<number>();

  theme: string = 'dark';themeUpdated$!: Subscription;
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);
  }


  onItemRoute() {
    this.itemClicked.emit(this.data.id);
  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
