import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEntity } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-entity',
  templateUrl: './ad-entity.component.html',
  styleUrls: ['./ad-entity.component.scss']
})
export class AdEntityComponent implements OnInit {

  @Input() defaultEntityType: number = 54;

  @Output() entitySelected: EventEmitter<IEntity> = new EventEmitter<IEntity>();
  @Output() entityTypeChanged: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  onChangeType(type: number) {
    this.defaultEntityType = type;
    this.entityTypeChanged.emit(type);
    // this.get
  }

  onEntitySelected(data: IEntity) {
    this.entitySelected.emit(data)
  }
}
