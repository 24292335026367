import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { AppSettingService, AuthService, SuiteApiService, EmbedService, NotifyService, IPrice, Price, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'galaxy-product-pricex',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit, OnChanges {

  @Input() businessProductId: number = 0;
  @Input() isEdit: boolean = false;
  isLoading: boolean = false;

  data: IPrice = new Price;
  photo: SafeResourceUrl = '';

  viewerId: number = 0;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _embed: EmbedService,
    private _sanitizer: DomSanitizer,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService

  ) { }

  ngOnInit() {
    // set this.viewerId
    this.viewerId = +this._authService.currentUser.unique;

    if (this.businessProductId !== 0) {
      this.getBusinessProductPrice(this.businessProductId);
    } else {
      this.onCloseDetail();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.businessProductId;

    if (!id.firstChange && id.currentValue !== 0 && id.previousValue !== id.currentValue) {
      this.isLoading = true;
      this.getBusinessProductPrice(this.businessProductId);

    }

  }





  getBusinessProductPrice(businessProductId: number) {

    this.isLoading = true;
    this.data = new Price;

    this._suiteApi
      .setParams(null, businessProductId.toString())
      .get(Price)
      .subscribe(
        {
          next: (res) => {
            this._suiteApi.DataMap({ from: res.data, to: this.data });

            // console.log('businessProduct price is ', res, this.data);

            if (this.data.id) {
              this.isEdit = true;
            }
            this.photo = this._embed.imgUrl(res.data.businessProduct.product.art, 'md');
            this.isLoading = false;
            // _$(res).MapTo(this.album);

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );

  }
  onSubmit(isNewPrice: boolean) {

    if (this.data.packRetailPrice === 0 && this.data.packWholesalePrice === 0 && this.data.unitRetailPrice === 0 && this.data.unitWholesalePrice === 0) {
      this._notify.error('Please enter price');
      return;
    }

    // set the createdBy
    this.data.createdBy.id = this.viewerId;


    // this.data.branch = [this.businessBranch];

    // console.log(this.data);
    // return;

    this.isLoading = true;

    !isNewPrice ? this.updateEntity() : this.createEntity();

  }

  updateEntity() {
    this._suiteApi
      .setParams(null, this.data.id.toString())
      .put(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  createEntity() {

    this._suiteApi
      .setParams(null, null)
      .post(this.data)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds',res)
            this.isLoading = false;

            if (res.success) {
              // console.log('tell me it was successfull', res);
              this._notify.success(res.message);
              this.data.id = res.data.id

              this._suiteApi.dataChanged$.emit({ type: this.data, data: res.data });
              this.onCloseDetail();
            } else {
              // console.log('Somthing went wrong. Please try again.', res);
              this._notify.error(res.message);
            }
            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onCloseDetail() {
    this._appSetting.closeDataDetail.emit(true);
  }
}
