import { Business, IBusiness } from './business.model';
import { BusinessBranch } from './business-branch.model';
import { Client, IClient } from './client.model';
import { IProductx, Productx } from './product.model';
import { IPrice } from './price.model';
import { Controller } from '../decorators';
import { ITransaction, Transaction } from './transaction.model';
import { StatusEnum, SuiteAccountTypeEnum } from '../../enums';

export interface IBusinessEntity {
  id: number;
  business: IBusiness;
  entity?: IClient;
  transaction?: ITransaction;
  point?: number;
  product?: IProductx;
  created?: number;
  modified?: number;
  createdBy?: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  accountType?: SuiteAccountTypeEnum;
  status: StatusEnum,
  registeredBusinessBranch?: BusinessBranch;
  appClaims?: any[];
  prices?: IPrice[];



  units?: number;
  packs?: number;
}

@Controller({ name: 'BusinessEntity' })
export class BusinessEntity implements IBusinessEntity {
  constructor(
    public id = 0,
    public business = new Business,
    public entity = new Client,
    // public transaction = new Transaction,
    public accountType = SuiteAccountTypeEnum.CLIENT,
    public status = StatusEnum.PUBLISHED,
  ) {
    // this.accountType.id = 2;
    // this.status.id = 2;
    // this.transaction.businessEntity = null;
  }
}

@Controller({ name: 'BusinessProduct' })
export class BusinessProduct implements IBusinessEntity {
  constructor(
    public id = 0,
    public business = new Business,
    public product = new Productx,
    public point = 0,
    public isPriced = false,
    public units = 0,
    public packs = 0,
    public prices = [],
    public stocks = { id: 0, units: 0, packs: 0 },
    public accountType = SuiteAccountTypeEnum.CLIENT,
    public status = StatusEnum.PUBLISHED,
    public createdBy = new BusinessEntity,
  ) {
    // this.accountType.id = 42;  //41 - users only , 42 - clients only
    // this.status.id = 2;
  }
}


export interface IBusinessEntityResetPassword {

  id: number;
  requestedIp: string;
  resetIp: string;
  email: string;
  businessEntity: IBusinessEntity;
  password: string;
}

@Controller({ name: 'account/iforgot' })
export class BusinessEntityResetPassword implements IBusinessEntityResetPassword {
  constructor(
    public id = 0,
    public requestedIp = '',
    public resetIp = '',
    public businessEntity = new BusinessEntity,
    public email = '',
    public password = '',
  ) { }
}
