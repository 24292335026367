import { Component, OnInit, Input } from '@angular/core';
import { Entity, IEntity } from '@galaxy/entity-api';
import { ShareService } from '@ngx-share/core';

@Component({
  selector: 'galaxy-media-share',
  templateUrl: './media-share.component.html',
  styleUrls: ['./media-share.component.scss']
})
export class MediaShareComponent implements OnInit {

  @Input() entity: IEntity = new Entity;
  @Input() title: string = '';
  @Input() link: string = '';
  isLoading: boolean = false;
  constructor(
    private _shareService: ShareService,
  ) { }

  ngOnInit() {

  }

  onCopy() {
    navigator.clipboard.writeText(this.link);
  }

}
