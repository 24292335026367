<div
  class="adx-left bg-dark"
  [class.adx-closed]="closed"
  (click)="onToggleClose()"
>
  <ui-side-menu [isMini]="closed" [appName]="app" [menu]="menu"></ui-side-menu>
</div>
<div class="adx-right bg-pin" [class.adx-closed]="closed">
  <!-- <ui-header></ui-header> -->
  <br />
<br />
  <div class="adx-workspace"><ng-content></ng-content></div>
</div>
