import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clients: { name: string; logo: string, url: string }[] = [];
  @Input() bgClass = 'bg-white';

  constructor() { }

  ngOnInit() {
    this.clients = [
      {
        name: 'INSPIRERS Network',
        logo: '/assets/images/clients/inspirers.png',
        url: ''
      },
      {
        name: 'Nap-Koff Abibirem Adepam',
        logo: '/assets/images/clients/napkoff.png',
        url: 'https://web.facebook.com/napkoffabibirem/'
      },
      {
        name: 'Prosicurezza Services',
        logo: '/assets/images/clients/prosicurezza.jpg',
        url: ''
      },
      {
        name: 'Conoria Beauty Palace',
        logo: '/assets/images/clients/conoria.png',
        url: 'https://web.facebook.com/Conoria-Beauty-Palace-152216725191029/'
      },
      {
        name: 'VANMED Diagnostic Services',
        logo: '/assets/images/clients/vanmed.png',
        url: ''
      },
      {
        name: 'RosNet Security Technology',
        logo: '/assets/images/clients/rosnet.jpg',
        url: 'https://web.facebook.com/rosjazzy.com.uk'
      },
      // {
      //   name: 'Perfect TV',
      //   logo: 'https://scontent.facc6-1.fna.fbcdn.net/v/t1.6435-9/46056254_1142948312509928_2256889173837348864_n.png?_nc_cat=105&ccb=1-3&_nc_sid=730e14&_nc_eui2=AeHDrkAkaGNJfD71DxNxQydgN7VC9HLhgLE3tUL0cuGAsRsjO_G_FcGOrrk2KWUvUJ8EqKjFXcmfV7DpXKP-cdRr&_nc_ohc=qTTUMSrXWcYAX91bSLi&_nc_ht=scontent.facc6-1.fna&oh=996db7fc641ea04ac35989136bcc1c01&oe=60B850B8',
      //   url: 'https://perfecttvapp.com'
      // },
      {
        name: 'VANNOL Lubricants & Auto-Parts',
        logo: '/assets/images/clients/vanol.png',
        url: ''
      },
      {
        name: 'Ritcharl Stiching Home',
        logo: '/assets/images/clients/ritcharl.jpg',
        url: 'https://twitter.com/Ritcharl_SH'
      }
    ]
  }

}
