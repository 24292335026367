<div class="adx-card footer adx-flat" style="max-width: 300px; margin:auto">
  <galaxy-adaptive-card>
    <p>
      &copy; 2018 Air Media Platform (Galaxy) <a href="#">About</a>
      <a href="#">Help Center</a> <a href="#">Terms</a>
      <a href="#">Privacy policy</a> <a href="#">Cookies</a>
      <a href="#">Ads info</a> <a href="#">Brand</a> <a href="#">Blog</a>
      <a href="#">Status</a> <a href="#">Apps</a> <a href="#">Jobs</a>
      <a href="#">Marketing</a> <a href="#">Businesses</a>
      <a href="#">Developers</a>
    </p>
    <div class="text-center">
      <a href="#" class="adx-btn adx-sm btn-default outline">
        Advertise with Galaxy
      </a>
    </div>
  </galaxy-adaptive-card>
</div>
