import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-new-fab',
  templateUrl: './new-fab.component.html',
  styleUrls: ['./new-fab.component.scss']
})
export class NewFabComponent implements OnInit, OnDestroy {
  @Input() icon: string = 'plus';
  @Input() title: string = 'Create New';
  @Input() route: string = 'new';

  showCreate: boolean = false;

  theme: string = '';themeUpdated$!: Subscription;
  constructor(private _appSetting: AppSettingService,
    private _router: Router) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
  }

  onAddClicked() {
    this.showCreate = true;
  }

  onCloseClicked($event) {
    this.showCreate = false;
  }

  onRoute(url) {
    this.showCreate = false
    this._router.navigateByUrl(url);

  }


  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
