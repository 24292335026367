import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { AppStatusSwService } from '@galaxy/entity-api';

@Component({
  selector: 'app-pwa-noti',
  templateUrl: './pwa-noti.component.html',
  styleUrls: ['./pwa-noti.component.scss']
})
export class PwaNotiComponent implements OnInit {



  readonly VAPID_PUBLIC_KEY: string =
    'BNKBgKwN3pzqUKhhIODIBifNtUel6eNGs-Bm7NGL6ru35I-CsYCjE8k4-zdbA1RgWzxhWKFJUlT8eGNrVyiPiz8';

  showInstall: boolean = false;
  showUpdate: boolean = false;

  constructor(
    private _swPush: SwPush,
    private _appStatus: AppStatusSwService, // private newsletterService: NewsletterService
    private _router: Router
  ) {
    // ServiceWorker - PWA
    this.installAppPrompt();
    // this.subscribeToNotifications();
  }

  ngOnInit() {
  }



  // Service Worker
  subscribeToNotifications() {
    this._swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
      .then(sub => {
        // console.log('Notification is ', sub);
      })
      .catch(err => console.error('Could not subscribe to notifications', err));
  }

  installAppPrompt() {
    // console.log('checking install prompt');

    this._appStatus.installBTN.subscribe(res => {
      this.showInstall = res;
    });
    this._appStatus.updateBTN.subscribe(res => {
      this.showUpdate = res;
    });
  }

  onInstallApp() {
    // console.log('install btn clicked');
    this._appStatus.installPWA();
  }
  onUpdateApp() {
    // console.log('updating app');
    // this.appStatus.installPWA();
    this.showUpdate = false;
    window.location.reload();
  }

  onCloseNoti() {
    this.showInstall = false;
    this.showUpdate = false;
  }
}
