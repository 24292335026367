import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  theme: string = 'light'; 
themeUpdated$!:Subscription;

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);
  }

  onSetTheme(value: string) {
    this._appSetting.setTheme(value);
  }
  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
