import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  theme: string = '';
  color: string = '';
  themeUpdated$!: Subscription;
  @Input() title: string = '';
  @Input() bg: string = '';
  @Input() icon: string = '';
  @Input() description: string = '';
  @Input() searchPlaceholder: string = 'Search ...';
  @Input() showSearhInput: boolean = false;
  @Input() button: { menu: string; route: string }[] = [];

  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
    this.color = this._appSetting.defaultColor;
    this._appSetting.color.subscribe(res => {
      this.color = res;
    });
  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
