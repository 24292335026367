import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { BusinessProduct, NotifyService, AppSettingService, AuthService, SuiteApiService, StoreInvoiceItem, IPrice, ErrorHandlerService } from '@galaxy/entity-api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-store-center',
  templateUrl: './store-center.component.html',
  styleUrls: ['./store-center.component.scss']
})
export class StoreCenterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() shopType: string = 'sales';

  @Input() appId: number = 0;
  @Input() actionButtons: { route: string, title: string, icon: string }[] = [];
  @Input() title: string = 'Sales Invoice'
  @Input() baseUrl: string = '/airStore/shop/sales';
  @Input() isAttend = false;
  @Input() accountType: number = 0; //0 - all , 41 - users only , 42 - clients only
  showDetail: boolean = false;
  defaultDetailTitle: string = 'My Cart';
  detailTitle: string = 'My Cart';
  isLoading: boolean = false;

  isList: boolean = false;

  data: BusinessProduct[] = [];

  page: number = 1;
  pageSize: number = 20;
  totalData: number = 20;

  dataMaxLength: number = 10;

  searchValue: string = '';
  businessId: number = 0;
  orderBy: string = 'id';
  theme: string = '';
  themeUpdated$!: Subscription;

  isRetailPrice = true;

  storePriceType = 'retail';


  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _errorHandlerService: ErrorHandlerService


  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const storeType = changes.shopType;

    if (!storeType.firstChange && storeType.currentValue !== storeType.previousValue) {
      this.setParams();
    }
  }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    this.setParams();

    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;

    this._suiteApi.activeBusiness.subscribe(res => this.businessId = res.id);
    if (this.businessId !== 0) {

      this.onReloadData();
    }
    this._appSetting.closeDataDetail.subscribe(res => {
      this.onCloseRoute();
    });
  }


  onSalePriceChange() {
    // changes between retail and wholesale price
    if (this.shopType == 'stock' || this.shopType == 'damages') return;

    this.isRetailPrice = !this.isRetailPrice;
    this.setParams();

    this._notify.info('Price changed to ' + (this.isRetailPrice ? 'Retail Price' : 'Wholesale Price'));
  }

  private setParams() {
    this.searchValue = '';

    if (this.shopType === 'stock' || this.shopType === 'damages') {
      this.storePriceType = this.shopType.toLowerCase();
      // alert(this.shopType)
    } else {
      this.storePriceType = this.isRetailPrice ? 'retail' : 'wholesale';
    }

    // alert('hyper shop: '+this.shopType + ' price: '+ this.storePriceType);
    setTimeout(() => {
      // console.log('emitting changes ', this.shopType, this.storePriceType)
      this._appSetting.shopType.emit(this.shopType); // send to cart store type
      this._appSetting.storePriceChange.emit(this.storePriceType); // send to list/ cards pricing type

    }, 500)
    this.title = this.shopType.toUpperCase() + ' Invoice';
  }


  priceType(priceType: any) {
    throw new Error('Method not implemented.');
  }

  onReloadData() {
    this.isLoading = true;
    this.pageSize = 20;
    this.page = 1;
    this.getData();

  }
  onPaginateData(page: number) {
    this.isLoading = true;
    this.page = page;
    this.getData();

  }

  getData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.page,
        pageSize: this.pageSize,
        searchValue: this.searchValue,
        category: this.accountType,
        id: this.businessId,
        gid: this._suiteApi.getActiveRole().businessBranchDepartment.businessBranch.id,
        orderBy: [this.orderBy]

      }, 'store-center')
      .getPageOf(BusinessProduct)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data = <BusinessProduct[]>res.data;
            this.page = res.meta.pagination.currentPage;
            this.pageSize = res.meta.pagination.pageSize;
            this.totalData = res.meta.pagination.totalItems;

            this.isLoading = false;

          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onSearchData(query: string) {
    query = query.trim();
    if (this.searchValue !== query && query !== '') {
      this.searchValue = query;
      this.onReloadData();
    }
  }

  onPaginate($event: number) {
    this.isLoading = true;
    this.page = $event;
    this.pageSize = this.dataMaxLength;
    this.getData();

  }


  onAddToCart(item: BusinessProduct) {

    if (item.prices.length === 0) {
      this._notify.error('No price found for this product');
      return;
    }

    const activePrice = this.getPrices(item.prices[0]);

    const storeItem = new StoreInvoiceItem;
    storeItem.businessProduct = item;
    storeItem.units = item.units;
    storeItem.packs = item.packs;



    storeItem.amount = (item.packs * activePrice.packPrice) + (item.units * activePrice.unitPrice);


    this._appSetting.addToStoreCart(storeItem);
  }

  private getPrices(itemPrice: IPrice) {
    const price = {
      packPrice: 0,
      unitPrice: 0
    };

    if (this.shopType === 'stock' || this.shopType === 'damages') {
      price.packPrice = itemPrice.packCostPrice;
      price.unitPrice = itemPrice.unitCostPrice;
      return price;
    }

    if (this.isRetailPrice) {
      price.packPrice = itemPrice.packRetailPrice;
      price.unitPrice = itemPrice.unitRetailPrice;

    } else {
      price.packPrice = itemPrice.packWholesalePrice;
      price.unitPrice = itemPrice.unitWholesalePrice;

    }
    return price;
  }

  onDetailRoute(routeData: { link: string, title: string }) {
    this.showDetail = true;
    this.detailTitle = routeData.title;
    // console.log(routeData.link);
    this._router.navigateByUrl(this.baseUrl + routeData.link);


  }
  onCloseRoute() {
    this.detailTitle = this.defaultDetailTitle;
    this.showDetail = false;
    this._router.navigateByUrl(this.baseUrl + 'default');

  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }


}
