<div class="qompass text-center">
  <div class="ad-logo">
    <img src="assets/images/logo/qompass.png" class="img-responsive" alt="">
  </div>
  <div class="text-size16">
    <strong class="text-size18">
      Qompass
    </strong>
    <p>
      Business Directory & Rental Platform
    </p>
    <small class="color-pink">
      https://qompass.aiira.co
    </small>
  </div>
  <div>
    <a routerLink="/business" class="adx-btn adx-ellipse adx-sm btn-dark">Learn More</a>
    <a href="https://qompass.aiira.co" target="_blank" class="adx-btn adx-ellipse adx-sm btn-dark">Visit Qompass</a>
  </div>
  <br>
  <br>
  <div class="qompass-slider">
    <div>
      <img src="assets/images/business/business-apps-home.jpg" class="img-responsive" alt="">
    </div>
  </div>
</div>
