import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-footer',
  templateUrl: './ui-footer.component.html',
  styleUrls: ['./ui-footer.component.scss']
})
export class UiFooterComponent implements OnInit {
  @Input() app: string = '';
  constructor() { }

  ngOnInit() { }
}
