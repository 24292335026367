<div class="grid-et">
  <galaxy-entity-card *ngFor="let item of data" [image]="item.product.art" [name]="item.product.name"
    [code]="item.product.code" [description]="item.product.category | enumKey:categoryEnum" [alias]="item.point + 'pt'"
    [price]="item.prices[0] ?? null" [isProduct]="true" [currency]="currency">

    <div class="back-flip">
      <div>
        <div style="transform: scale(.8) translateY(-16px); width:100%">

          <div class="input-flexx">

            <div class="name">
              <strong>
                Units
              </strong>
              <br>
              <i>

                {{ storePriceType==='stock'?item.stocks.units +
                item.units:item.stocks.units - item.units}} in-stock
              </i>
            </div>

            <div class="adx-input adx-round">
              <input type="number" name='units' min="0" step="1" [(ngModel)]="item.units"
                [max]="storePriceType === 'stock'?null:(item.stocks.units + (item.stocks.packs > 0 ? item.product.unitsInPack :0))">
            </div>




          </div>

          <div class="input-flexx">


            <div class="name">
              <strong>
                Packs
              </strong>
              <br>
              <i>

                {{ storePriceType==='stock'?item.stocks.packs +
                item.packs:item.stocks.packs - item.packs}} in-stock
              </i>
            </div>

            <div class="adx-input adx-round">
              <input type="number" name='packs' min="0" step="1" [(ngModel)]="item.packs"
                [max]="storePriceType === 'stock'?null:item.stocks.packs">
            </div>

          </div>

        </div>
      </div>


      <button class="adx-btn adx-round btn-tgreen" (click)="onAddToCart(item)">
        <i class="axr ax-cart-plus"></i> Add To Cart
      </button>
    </div>

  </galaxy-entity-card>
</div>

<ng-content></ng-content>
