import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from '../../GenericService';

@Injectable({
  providedIn: 'root'
})
export class PerfectMediaApiService extends GenericService {
  constructor(http: HttpClient) {
    // const baseUrl = '//127.0.0.1:8080/media-api/';
    const rootUrl = 'https://perfectcloud.xyz';
    const baseUrl = 'https://perfectcloud.xyz/media-api/';
    super(http, baseUrl, rootUrl);
  }

}
