import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService, Client, ErrorHandlerService, IClient, IMemo, Memo, NotifyService, PriorityEnum, SuiteApiService } from '@galaxy/entity-api';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hr-widget',
  templateUrl: './hr-widget.component.html',
  styleUrls: ['./hr-widget.component.scss']
})
export class HrWidgetComponent implements OnInit, OnDestroy {

  theme: string = '';
  themeUpdated$!: Subscription;

  businessId: number;
  isLoading: boolean;

  data: {
    team: {
      data: IClient[],
      page: number,
      pageSize: number,
      totalData: number,
      searchValue: string,
      isFirstData: boolean,
      isLoading:boolean
    },
    memo: {
      data: IMemo[],
      page: number,
      pageSize: number,
      totalData: number,
      searchValue: string,
      isFirstData: boolean,
      isLoading:boolean
    }
  } = {
      team: {
        data: [],
        page: 1,
        pageSize: 10,
        totalData: 10,
        searchValue: '',
        isFirstData: false,
        isLoading: false
      },
      memo: {
        data: [],
        page: 1,
        pageSize: 10,
        totalData: 10,
        searchValue: '',
        isFirstData: false,
        isLoading: false
      }
    }
  branchId = 0;
  accountType = 1;

  priorityEnum:typeof PriorityEnum = PriorityEnum;
  selectedMemo: IMemo = new Memo;
  showModal: boolean = false;

  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _suiteApi: SuiteApiService,
    private _notify: NotifyService,
    private _storageMap: StorageMap,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    // theme
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    // business
    this.businessId = this._suiteApi.getActiveBusiness().id;
    this._suiteApi.activeBusiness.subscribe(res => {
      this.businessId = res.id;
      this._getStorageData();
    });
    if (this.businessId !== 0) {
      this._getStorageData();
      this.onReloadData();
    }
  }

  private _resetData() {
    this.data.team.data = [];
    this.data.team.isFirstData = true;

  }


  private _getStorageData() {
    this._resetData();

    // console.log('personId is ', this.personId)

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.branchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        if (res) {
          this.data.team.data = res.get(key) ?? [];
        }
      });

  }

  private _storeStorageData() {

    const domain = '_bpc';
    const key = this.businessId + '.b' + this.branchId + '.t' + this.accountType;

    this._storageMap.get(domain)
      .subscribe((res: Map<string, IClient[]>) => {
        let dataMap = new Map<string, IClient[]>();

        if (res) {
          dataMap = res;
        }

        dataMap.set(key, this.data.team.data);

        this._storageMap.set(domain, dataMap).subscribe(() => { });
      });


  }


  onReloadData(){
    this.onReloadMemoData();
    this.onReloadTeamData();
  }
  onReloadTeamData() {
    this.data.team.isLoading = true;
    this.getTeamData();

  }


  getTeamData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.data.team.page,
        pageSize:  this.data.team.pageSize,
        searchValue: this.data.team.searchValue,
        category: 1,
        id: this.businessId,
        categoryType: 0,
        orderBy: ['id']

      })
      .getPageOf(Client)
      .subscribe(
        {
          next: (res) => {
            // console.log('responds', res)
            // this.isLoading = false;

            this.data.team.data = <IClient[]>res.data;
            this.data.team.page = res.meta.pagination.currentPage;
            this.data.team.pageSize = res.meta.pagination.pageSize;
            this.data.team.totalData = res.meta.pagination.totalItems;

            if (this.data.team.isFirstData) {
              this._storeStorageData();
              this.data.team.isFirstData = false;
            }

            this.data.team.isLoading = false;

          },
          error: (err) => {
            this.data.team.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  onReloadMemoData() {
    this.data.memo.isLoading = true;
    this.getMemoData();

  }


  getMemoData(): any {
    // get the id of the person
    this._suiteApi
      .setParams({
        page: this.data.memo.page,
        pageSize:  this.data.memo.pageSize,
        searchValue: this.data.memo.searchValue,
        id: this.businessId,
        orderBy: ['id']

      })
      .getPageOf(Memo)
      .subscribe(
        {
          next: (res) => {

            this.data.memo.data = <IMemo[]>res.data;
            this.data.memo.page = res.meta.pagination.currentPage;
            this.data.memo.pageSize = res.meta.pagination.pageSize;
            this.data.memo.totalData = res.meta.pagination.totalItems;


            this.data.memo.isLoading = false;

          },
          error: (err) => {
            this.data.memo.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  onViewMemo(item:IMemo){
    this.selectedMemo = item;
    this.showModal = true;
  }

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }
}
