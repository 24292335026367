<div class="cover adx-shadow bg-special">
  <div class="adx-logo">
    <div class="logo"></div>
  </div>
  <div class="detail">
    <div class="adx-divider ">
      <span class="color-specialx">

        {{title}}
      </span>
    </div>
    <p>
      {{description}}
      <br>
      <br>
      <span class="color-specialx">
        <i class="axr ax-external-link color-specialx"></i> &nbsp; <a [href]="link" target="_blank"
          rel="noopener noreferrer">{{link}}</a>
      </span>

    </p>
  </div>
</div>
