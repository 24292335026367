import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService, AuthService, EmbedService } from '@galaxy/entity-api';

@Component({
  selector: 'ui-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() appName: string = '';

  @Input() menu: { name: string; icon: string; route: string; }[] = [];
  @Input() isMini: boolean = false;

  @Input() theme: string = '';
  @Input() image: any = '';
  showMenu: boolean = false;
  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this._appSetting.showMenu.subscribe(res => this.showMenu = res);
    this.image = this._embed.imgUrl(this._authService.currentUser.image, 'md');
  }

  onToggleMenu() {
    this.showMenu = !this.showMenu;
    this._appSetting.showMenu.emit(this.showMenu)
  }
  onMenuClicked(e) {
    e.stopPropagation();
    this.onToggleMenu();
  }
}
