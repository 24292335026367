import { Group, IGroup } from "../ori/group.model";
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusiness, Business } from './business.model';
import { IBusinessBranchDepartment, BusinessBranchDepartment, } from './business-department.model';
import { IBusinessService, BusinessService, } from './business-service.model';
import { ITransaction, Transaction } from "./transaction.model";
import { Controller } from '../decorators';
import { AcademicTypeEnum, AcademicYearSegmentEnum, QuizSectionTypeEnum, QuizTypeEnum, StatusEnum } from "../../enums";

// Class or Year in Department

export interface ICourse {
  id: number;
  name: string;
  business: IBusiness;
  description: string;
  status: StatusEnum;
  created: number;
  modified: number;
  courseCodes?: ICourseCode[];
  courseCodesCount?: number;
}


@Controller({ name: 'course' })
export class Course implements ICourse {
  constructor(
    public id = 0,
    public name = '',
    public business = new Business,
    public description = '',
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0
  ) {
  }
}


export interface ICourseCode {
  id: number;
  code: string;
  course: ICourse;
  description: string;
  academicYearType: IGroup;
  academicYearSegment: IGroup;
  amount: number;
  status: StatusEnum,
  created: number;
  modified: number;
}

@Controller({ name: 'coursecode' })
export class CourseCode implements ICourseCode {
  constructor(
    public id = 0,
    public code = '',
    public course = new Course,
    public description = '',
    public academicYearType = new Group,
    public academicYearSegment = new Group,
    public amount = 0,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0
  ) {
    // this.status.id = 2;
    this.academicYearType.id = 426; // one semester a year
    this.academicYearSegment.id = 430; // full year semester
  }
}

export interface IProgrammeClass {
  id: number;
  name: string;
  businessService: IBusinessService;
  nextClass?: IProgrammeClass;
  status: StatusEnum;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
  programmeClassCourseCodes?: IProgrammeClassCourseCode[];

}


@Controller({ name: 'ProgrammeClass' })
export class ProgrammeClass implements IProgrammeClass {
  constructor(
    public id = 0,
    public name = '',
    public businessService = new BusinessService,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
    public programmeClassCourseCodes = [],

  ) {

  }
}

// Courses in a Class
export interface IProgrammeClassCourseCode {
  id: number;
  programmeClass: IProgrammeClass;
  courseCode: ICourseCode;
  status: StatusEnum,
  created: number;
  modified?: number;

}

@Controller({ name: 'ProgrammeClassCourseCode' })
export class ProgrammeClassCourseCode implements IProgrammeClassCourseCode {
  constructor(
    public id = 0,
    public programmeClass = new ProgrammeClass,
    public courseCode = new CourseCode,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,

  ) {
  }
}

// Department Courses
export interface IDepartmentProgramme {
  id: number;
  amount: number;
  businessBranchDepartment: IBusinessBranchDepartment;
  businessService: IBusinessService;
  status: StatusEnum;
  created: number;
  modified?: number;
}


@Controller({ name: 'DepartmentProgramme' })
export class DepartmentProgramme implements IDepartmentProgramme {
  constructor(
    public id = 0,
    public amount = 0,
    public businessBranchDepartment = new BusinessBranchDepartment,
    public businessService = new BusinessService,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,

  ) {

  }
}



export interface IDepartmentProgrammeEnrollmentCourseCode {
  id: number;
  transaction?: ITransaction;
  departmentProgrammeEnrollment: IDepartmentProgrammeEnrollment;
  courseCode: ICourseCode;
  totalScore: number;
  semester: ISemester;
  isSubmitted: boolean;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'CourseEnrollment' })
export class DepartmentProgrammeEnrollmentCourseCode implements IDepartmentProgrammeEnrollmentCourseCode {
  constructor(
    public id = 0,
    public transaction = new Transaction,
    public departmentProgrammeEnrollment = new DepartmentProgrammeEnrollment,
    public courseCode = new CourseCode,
    public totalScore = 0,
    public semester = new Semester,
    public isSubmitted = false,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
    public modifiedBy = new BusinessEntity,
  ) {

  }
}

// Course Enrollment

export interface IDepartmentProgrammeEnrollment {
  id: number;
  transaction?: ITransaction;
  businessEntity: IBusinessEntity;
  departmentProgramme: IDepartmentProgramme;
  programmeClass?: IProgrammeClass;
  isGraduated: boolean;
  status: StatusEnum;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
  programmeCourseCodeEnrollments?: IDepartmentProgrammeEnrollmentCourseCode[];
}


@Controller({ name: 'Enrollment' })
export class DepartmentProgrammeEnrollment implements IDepartmentProgrammeEnrollment {
  constructor(
    public id = 0,
    public transaction = new Transaction,
    public businessEntity = new BusinessEntity,
    public departmentProgramme = new DepartmentProgramme,
    public programmeClass = new ProgrammeClass,
    public isGraduated = false,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,

  ) {

  }
}



// Grade System
export interface IGrade {
  id: number;
  grade: string;
  point: number;
  minScore: number;
  maxScore: number;
  remarks: string;
  description: string;
  semester: IGroup;
  created: number;
  modified?: number;
}

@Controller({ name: 'Grade' })
export class Grade implements IGrade {
  constructor(
    public id = 0,
    public grade = '',
    public point = 0,
    public minScore = 0,
    public maxScore = 0,
    public remarks = '',
    public description = '',
    public semester = new Group,
    public created = 0,
    public modified = 0,
  ) { }
}




export interface IGradeSystem {
  id: number;
  name: string;
  classScore: number;
  examScore: number;
  business: IBusiness;
  status: StatusEnum;
  created: number;
  modified?: number;
  grades?: IGrade[];
}


@Controller({ name: 'GradeSystem' })
export class GradeSystem implements IGradeSystem {
  constructor(
    public id = 0,
    public name = '',
    public classScore = 50,
    public examScore = 50,
    public business = new Business,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,
    public grades = [],
  ) {

  }
}



// Semester

export interface ISemester {
  id: number;
  name: string;
  year: number;
  academicYearType: AcademicTypeEnum;
  academicYearSegment: AcademicYearSegmentEnum;
  gradeSystem: IGradeSystem;
  business: IBusiness;
  status: StatusEnum;
  created: number;
  modified?: number;
}


@Controller({ name: 'Semester' })
export class Semester implements ISemester {
  constructor(
    public id = 0,
    public name = '',
    public year = 0,
    public academicYearType = AcademicTypeEnum.ONE_SEMESTER,
    public academicYearSegment = AcademicYearSegmentEnum.FULL_YEAR,
    public gradeSystem = new GradeSystem,
    public business = new Business,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,
  ) {

    // this.academicYearType.id = 426; // one semester a year
    // this.academicYearSegment.id = 430; // full year semester

    // get current year
    this.year = (new Date).getFullYear();
  }
}



// Course Quizes/Exams

export interface ICouseCodeQuiz {
  id: number;
  title: string;
  code: string;
  totalScore: number;
  isExam: boolean;
  type: QuizTypeEnum;
  status: StatusEnum;
  courseCode: ICourseCode;
  semester: ISemester;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
  quizSections?: ICourseCodeQuizSection[];
  quizScores?: ICourseCodeQuizScore[];

}

@Controller({ name: 'Quiz' })
export class CourseCodeQuiz implements ICouseCodeQuiz {
  constructor(
    public id = 0,
    public title = '',
    public code = '',
    public totalScore = 0,
    public isExam = false,
    public type = QuizTypeEnum.WRITTEN,
    public status = StatusEnum.PUBLISHED,
    public courseCode = new CourseCode,
    public semester = new Semester,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
    public quizSections = [],
    public quizScores = [],
  ) {

  }
}


export interface ICourseCodeQuizSection {
  id: number;
  title: string;
  numberOfQuestions: number;
  score: number;
  type: QuizSectionTypeEnum;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
}



export class CourseCodeQuizSection implements ICourseCodeQuizSection {
  constructor(
    public id = 0,
    public title = '',
    public numberOfQuestions = 0,
    public score = 0,
    public type = QuizSectionTypeEnum.MULTIPLE_CHOICE,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
  ) { }
}


// Quizes/Exams Scores

export interface ICourseCodeQuizScore {
  id: number;
  businessEntity: IBusinessEntity;
  academicServiceQuiz: ICouseCodeQuiz;
  quizSectionScore: object;
  totalScore: number;
  created: number;
  createdBy: IBusinessEntity;
  modified?: number;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'QuizScore' })
export class CourseCodeQuizScore implements ICourseCodeQuizScore {
  constructor(
    public id = 0,
    public businessEntity = new BusinessEntity,
    public academicServiceQuiz = new CourseCodeQuiz,
    public quizSectionScore = new Object,
    public totalScore = 0,
    public created = 0,
    public createdBy = new BusinessEntity,
    public modified = 0,
    public modifiedBy = new BusinessEntity,
  ) { }
}
