import { Controller } from '../decorators';
import { Feed } from './entity.model';

export interface IPoll {
  // Basic Info
  id: number;
  title: string;
  feed?: Feed;

  orderNumber: number;

  voteCount?: number;
  created?: number;
}

@Controller({ name: 'Poll' })
export class Poll implements IPoll {
  constructor(
    public id = 0,
    public title = '',
    public feed = new Feed(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public orderNumber = 1,
  ) { }

}

@Controller({ name: 'FeedPoll' })
export class FeedPoll implements IPoll {
  constructor(
    public id = 0,
    public title = '',
    public feed = null,
    public orderNumber = 1,
    public voteCount = 0
  ) { }
}
