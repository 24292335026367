<div class="adx-cardx adx-flat" style="overflow: visible;">
  <div class="adx-img"><img [src]="photo" alt="" /></div>
  <div class="file-btn">
    <galaxy-image-tool
      class="btn-positon"
      (imageCapture)="onGetImage($event)"
      [fileAlias]="'suite-product'"
      [icropAspectRatio]="1 / 1"
      [icropResizeToWidth]="300"
      [format]="'jpeg'"
      [saveMedia]="false"
    ></galaxy-image-tool>
  </div>
  <form #entityForm="ngForm" [class.is-loading]="isLoading">
    <div class="form-wrapper">
      <!-- Start of wrapper -->
      <!-- <div class="adx-divider">Basic Info</div> -->
      <div class="adx-input adx-round adx-label">
        <label for="name">Product Name</label>
        <input
          type="text"
          name="name"
          [(ngModel)]="data.product.name"
          required
          [disabled]="isLoading"
        />
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="code">Product Code</label>
        <input
          type="text"
          name="code"
          [(ngModel)]="data.product.code"
          required
          [disabled]="isLoading"
        />
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="code">Product Point</label>
        <input
          type="number"
          name="point"
          [(ngModel)]="data.product.point"
          required
          [disabled]="isLoading"
        />
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="tag">Tag</label>
        <input
          type="text"
          name="tag"
          [(ngModel)]="data.product.tag"
          required
          [disabled]="isLoading"
        />
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="Category">Product Category</label>
        <select
          [disabled]="isLoading"
          name="category"
          [(ngModel)]="data.product.category"
        >
          <option value="1">Grocery</option>
          <option value="2">Phones & Tablets</option>
          <option value="3">Health & Beauty</option>
          <option value="4">Home & Office</option>
          <option value="5">Electronics</option>
          <option value="6">Computing</option>
          <option value="7">Fashion</option>
          <option value="8">Sporting Goods</option>
          <option value="9">Baby Products</option>
          <option value="10">Gaming</option>
          <option value="11">Other Categories</option>
        </select>
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="type">Product Type</label>
        <select
          [disabled]="isLoading"
          name="type"
          [(ngModel)]="data.product.type"
        >
          <option value="1">Single Unit</option>
          <option value="2">Box</option>
          <option value="3">Carton</option>
        </select>
      </div>
      <div class="adx-input adx-round adx-label">
        <label for="minPack">Minimum Pack</label>
        <input
          type="number"
          name="minPack"
          [(ngModel)]="data.product.minPack"
          required
          [disabled]="isLoading"
        />
      </div>

      <div class="adx-input adx-round adx-label">
        <label for="minPack"># Units In Pack</label>
        <input
          type="number"
          name="unitsInPack"
          [(ngModel)]="data.product.unitsInPack"
          required
          [disabled]="isLoading"
        />
      </div>

      <!-- App Sepcific -->
      <ng-content></ng-content>
      <!-- End of wrapper -->
    </div>
    <br />
    <br />

    <button
      [disabled]="isLoading"
      class="adx-btn adx-round adx-sm btn-grey"
      (click)="onCloseDetail()"
    >
      Cancel
    </button>

    <button
      [disabled]="entityForm.invalid || isLoading"
      class="adx-btn adx-round adx-sm btn-tgreen float-right"
      (click)="onSubmit(2)"
    >
      Save
    </button>
  </form>
</div>
