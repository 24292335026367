import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'galaxy-media-album-card',
  templateUrl: './media-album-card.component.html',
  styleUrls: ['./media-album-card.component.scss']
})
export class MediaAlbumCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
