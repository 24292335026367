
export enum TransactionTypeEnum {
  SALES = 1,
  EXPENSE_PURCHASES = 2,
  UTITLITY_BILLS = 3,
  SALARY = 4,
  ALLOWANCE_BONUS = 5,
  TAX = 6,
  MAINTENANCE = 7,
  DONATION = 8,
  DEBIT = 9,
  CREDIT = 10,

  // isCredit(): boolean
  //   {
  //   if (this.value === 1 || this.value === 10) {
  //     return true;
  //   }

  //   return false;
  // }
}
