<div class="person-flex">
  <div class="ax-avatar adx-sm adx-flat" style="padding-left: 0" [routerLink]="[routeLink]"
    [class.is-community]='isCommunity'>
    <div class="adx-img" [defaultImage]='defaultImage' [lazyLoad]="avatar">

      <div *ngIf="isCommunity" class="community-person-avatar" [defaultImage]='defaultImage' [lazyLoad]="personImage">

      </div>
    </div>
    <div class="adx-detail">
      <h2 class="bg-redx">
        <b>
          {{
          isCommunity ?
          community.name :
          data.person.name
          }}
          <small>
            <i *ngIf="data.person.isVerified" class="axr ax-shield-check color-tgreen" style="margin-top: -1.6rem"></i>
          </small> </b>&nbsp;

        <!-- <span class="axr ax-point" style="margin-top: -16px; opacity: 0.5"></span>
        &nbsp;
        <span class="color-pink">Follow</span> -->
        <!-- <span style="opacity: 0.6;"> @{{ data.person.username }} </span> -->
      </h2>
      <p *ngIf="!isCommunity">
        <span *ngIf="showLocation">
          {{ data.createdLocation }}
        </span>
        <span *ngIf="!showLocation">
          <!-- <span class="remove-xxs"> {{ data.created * 1000 | date }} . </span> -->
          {{'@'+data.person.username}} - {{ data.created * 1000 | amTimeAgo: true }} ago
        </span>
      </p>
      <p *ngIf="isCommunity">
        {{'@'+ data.person.username }} - {{ data.created * 1000 | date }}
      </p>
    </div>
  </div>

  <div class="bg-re">
    <!-- <span class="x-remove-xxs" style="opacity: 0.6; font-size: 11px; padding-top: -8px">
      Seen by {{ data.hit | countSuffix: 2 }}
    </span> -->

    <div class="adx-dropdown">
      <button class="adx-btn adx-icon adx-flat clear adx-trigger adx-xs">
        <i class="axl ax-dots-vertical"></i>
      </button>
      <div class="adx-content" style="margin-left: -150px">
        <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'">

          <!-- show analytics -->
          <div *ngIf="showAnalytics" class="adx-list adx-flat adx-sm adx-hover hover-pink">
            <div class="adx-item color-pink">
              <i class="axl ax-trash"></i> Delete
            </div>
            <div class="adx-item"><i class="axl ax-pin"></i> Pin To Profile</div>
            <!-- <div class="adx-item"><i class="axl ax-link"></i> Copy Link</div> -->
            <div class="adx-item">
              <i class="axl ax-brand-google-analytics"></i> View Activity
            </div>
          </div>
          <!-- do not show analytics -->
          <div *ngIf="!showAnalytics" class="adx-list adx-flat  adx-sm adx-hover hover-pink" style="width: 200px">
            <!-- <div class="adx-item"><i class="axl ax-link"></i> Copy Link</div> -->
            <!--
            <div class="adx-item">
                <i class="axl ax-user-x"></i> Unfollow @
              {{ data.person.username | maxLength: 6 }}
            </div>
            <div class="adx-item">
              <i class="axl ax-volume-3"></i> Mute @
              {{ data.person.username | maxLength: 6 }}
            </div> -->
            <div class="adx-item">
              <i class="axl ax-ban"></i> Block &#64;
              {{ data.person.username | maxLength: 6 }}
            </div>
            <div class="adx-item"><i class="axl ax-flag"></i> Report Post</div>
          </div>
        </galaxy-adaptive-card>
      </div>
    </div>
  </div>
</div>
