<div class="adx-card adx-flat is-loading" *ngIf="isList && isLoading">
  <p></p>
</div>
<div *ngIf="!isLoading || data.length > 0">
  <div *ngIf="isList; then listItem; else flexItem"></div>
</div>

<div class="adx-card adx-flat" *ngIf="isList && !isLoading && data.length === 0">
  <p class="text-center text-size18" style="opacity: 0.4">
    <i class="axl ax-folder ax-2x"></i> <br />
    <br />
    No Collection Found
  </p>
</div>

<!-- list template -->
<ng-template #listItem>
  <div class="adx-list adx-card adx-full adx-flat adx-roundx adx-shadow collection-item">
    <galaxy-media-collection-item *ngFor="let item of data" [data]="item" [size]="listSize"
      (addToCollection)="onViewCollection($event)"></galaxy-media-collection-item>
  </div>
</ng-template>

<!-- flex template -->
<ng-template #flexItem>
  <galaxy-adx-flex [showTitle]="false">
    <div class="board" *ngFor="let item of data; index as i">
      <galaxy-media-folder (click)="onViewCollection(item)" [data]="item" [index]="i" [showEditBtn]="false">
      </galaxy-media-folder>
    </div>
  </galaxy-adx-flex>
</ng-template>

<!-- entity collection -->

<galaxy-media-collection-entities *ngIf="showEntityCollection" [icon]="icon" [entityType]="entityType"
  [collection]="activeCollection" [showEntities]="showEntityCollection" (closeColletion)="onCloseClicked()">
</galaxy-media-collection-entities>
