import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galaxy-adx-grid',
  templateUrl: './ad-grid.component.html',
  styleUrls: ['./ad-grid.component.scss']
})
export class AdGridComponent implements OnInit {


  @Input() title: string = '';

  @Input() showTitle: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
