import { Injectable, EventEmitter } from '@angular/core';
import { IProfileMenu, IHeaderMenu, NotifyService } from '@galaxy/entity-api';
import { IBusiness, IGroup, IStoreInvoiceItem, IBusinessRecruit, IBusinessAsset } from '../model';
import { StorageMap } from '@ngx-pwa/local-storage';
// import * as blogType from 'ssets/data/blog-type.json';

import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';



const software = [
  '3D-Coat',
  '3Ds Max',
  'Blender',
  'Cinema 4D',
  'DAZ Studio',
  'Maya',
  'Modo',
  'Mudbox',
  'Terragen',
  'ZBrush',
  'Other'
]

@Injectable({
  providedIn: 'root'
})

export class AppSettingService {

  production: boolean = false;

  defaultInnerWidth: number = 0;
  innerWidth = new EventEmitter<number>();

  defaultWindowScrollY: number = 0;
  windowScrollY = new EventEmitter<number>();
  app: string = '';
  defaultTheme: string = '';
  theme = new EventEmitter<string>();
  defaultColor: string = '';
  color = new EventEmitter<string>();

  profileMenu?: IProfileMenu;
  header?: IHeaderMenu;

  headerTransparent: boolean = false;

  profile?: any;

  search?: any;
  defaultSearch = new EventEmitter();

  searchOptions?: any;
  newOptions?: any;
  showMenu = new EventEmitter<boolean>();


  tiggerNewCommunityModal = new EventEmitter<number>();

  // Dex
  browseDetail = new EventEmitter<{
    id: number,
    name: string;
    placeholder: string;
    about: string;
  }>();


  private personId: number = 0;

  closeDataDetail = new EventEmitter<boolean>();
  genDeviceId: string = ''
  emitDeviceId = new EventEmitter<string>();



  isViewingEntity = new EventEmitter<boolean>();
  isFeaturedViewingEntity = new EventEmitter<boolean>();


  modalStatus = new EventEmitter<boolean>();
  businessData = new EventEmitter<IBusiness[]>();
  jobData = new EventEmitter<IBusinessRecruit[]>();
  rentData = new EventEmitter<IBusinessAsset[]>();
  showBusinessDetail = new EventEmitter<number>();
  showAssetDetail = new EventEmitter<number>();
  showJobDetail = new EventEmitter<number>();
  showOnMap$ = new EventEmitter<{ longitude: number, latitude: number }>();


  userLocation: { longitude: number, latitude: number } = { longitude: 0, latitude: 0 }






  storeCart = new EventEmitter<IStoreInvoiceItem>();
  storePriceChange = new EventEmitter<string>();


  shopType = new EventEmitter<string>();
  ipAddress: string = '';
  ipInfo: any = {};



  defaultCurrencyCode = 'USD'; // only ghana gets to pay in GHS


  private maxIdleDuration = 30000;
  private _lastActiveTime = Date.now();
  public idleTimeout = new EventEmitter();



  public qompassFloatShow = new EventEmitter();


  constructor(
    private _storageMap: StorageMap,
    private _deviceService: DeviceDetectorService,
    private _http: HttpClient,
    private _notify: NotifyService


  ) {
    this.checkDeviceId();

    this.getIPAddress();


    // console.log('blog type ', blogType);
  }



  activateAutoLogout() {
    this._lastActiveTime = Date.now();

    window.addEventListener('mousemove', () => {
      this._lastActiveTime = Date.now();
      // console.log('date updated with mouse over', this._lastActiveTime)
    });

    window.addEventListener('keypress', () => {
      this._lastActiveTime = Date.now();
    });

    this._idleTimmer();
  }

  private _idleTimmer() {
    setTimeout(() => {
      // console.log(Date.now() - this._lastActiveTime);
      if (Date.now() - this._lastActiveTime > 1800000) // 30 mins, 300000 - 5 minutes,  18000 -> 3 mins
       {
        // console.log('logout at ', Date.now(), this._lastActiveTime);
        this._notify.info('Session timeout!')
        this.idleTimeout.emit()
      } else {
        this._idleTimmer()
      }
    }, 5000);
  }

  getIPAddress() {
    this._http.get("https://freeipapi.com/api/json")
      .subscribe((res: any) => {
        this.ipAddress = res.ipAddress;
        this.ipInfo = res;

        localStorage.setItem('clientIp', this.ipAddress);
        localStorage.setItem('clientLocation', this.ipInfo.cityName + ', ' + this.ipInfo.countryCode);
      });
  }


  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;

        // console.log('coords is ', position);

        this.callApi(longitude, latitude);
      });
    } else {
      // console.log("No support for geolocation")
    }
  }

  callApi(longitude: number, latitude: number) {
    // const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`
    this.userLocation = { longitude, latitude };

    // console.log(this.userLocation);
    this._storageMap.set('userLoc', this.userLocation).subscribe();

    //Call API
  }


  checkDeviceId() {
    this._storageMap.get('ori_did').subscribe(res => {
      if (!res) {
        this.generateDeviceId();
        // console.log('device id found is ', res);
      } else {
        this.genDeviceId = <string>res;
        this.emitDeviceId.emit(this.genDeviceId);
        // console.log('device id found is ', res);
      }
    })
  }
  generateDeviceId() {
    const time = new Date;
    const deviceInfo = this._deviceService.getDeviceInfo();
    // device.os.browser.app.person
    const deviceId = deviceInfo.device + '.' + deviceInfo.os + '.' + deviceInfo.browser + '.' + this.getAppId() + '.' + time.getTime();
    const encodeDeviceId = btoa(deviceId);

    this.emitDeviceId.emit(encodeDeviceId);

    this._storageMap.set('ori_did', encodeDeviceId).subscribe();

    // console.log('device id is ', encodeDeviceId, deviceId);


  }


  setInnerWidth(width: number) {
    this.defaultInnerWidth = width;
    this.innerWidth.emit(width);
    // console.log('page resized', width);
  }
  setWindowScrollY(position: number) {
    this.defaultWindowScrollY = position;
    this.windowScrollY.emit(position);
    // console.log('page scroll position', position);
  }

  getDeviceId(): string {
    return this.genDeviceId;
  }

  getAppId(): number {
    let appId = 0;
    switch (this.app) {
      case 'lyfeshot':
        appId = 58;
        break;
      case 'play':
        appId = 59;
        break;

      case 'artist':
        appId = 60;
        break;

      case 'store':
        appId = 61;
        break;

      case 'christ':
        appId = 62;
        break;
      case 'messenger':
        appId = 63;
        break;
      case 'suite':
        appId = 64;
        break;
      case 'rent':
        appId = 65;
        break;

      default:
        break;
    }
    return appId;
  }

  setTheme(theme: string) {
    this.defaultTheme = theme;
    localStorage.setItem('ori.theme', theme);
    this.theme.emit(theme);

    // localStorage.setItem('playlist', JSON.stringify(myPlaylist));

  }
  setColor(color: string) {
    this.defaultColor = color;
    this.color.emit(color);
  }


  setMenu(showMenu: boolean) {
    this.showMenu.emit(showMenu);
  }

  setDefaultSearch(option) {
    this.defaultSearch.emit(option);
  }

  getCoummunityRole(): IGroup[] {
    return [
      {
        id: 1,
        name: 'Admin',
        alias: '',
      },
      {
        id: 2,
        name: 'Moderator',
        alias: '',

      },
      {
        id: 3,
        name: 'Editor',
        alias: '',

      },
      {
        id: 4,
        name: 'Member',
        alias: '',

      }
    ];
  }
  getCoummunityType(): IGroup[] {
    return [
      {
        id: 1,
        name: 'Outdoors & Adventure',
        alias: 'outdoors',
      },
      {
        id: 2,
        name: 'Tech',
        alias: 'tech',
      },
      {
        id: 3,
        name: 'Family',
        alias: 'family',
      },
      {
        id: 4,
        name: 'Health & Wellness',
        alias: 'health',
      },
      {
        id: 5,
        name: 'Sports & Fitness',
        alias: 'sports',
      },
      {
        id: 6,
        name: 'Learning',
        alias: 'learning',
      },
      {
        id: 7,
        name: 'Photography',
        alias: 'photography',
      },
      {
        id: 8,
        name: 'Food & Drink',
        alias: 'food',
      },
      {
        id: 9,
        name: 'Writing',
        alias: 'writing',
      },
      {
        id: 10,
        name: 'Languge & Culture',
        alias: 'language',
      },
      {
        id: 11,
        name: 'Music',
        alias: 'music',
      },
      {
        id: 12,
        name: 'Movement',
        alias: 'movement',
      },
      // {
      //   id: 13,
      //   name: 'LGBTQ',
      //   alias: '',

      // },
      {
        id: 14,
        name: 'Film',
        alias: 'film',
      },
      {
        id: 15,
        name: 'Sci-Fi & Games',
        alias: 'games',
      },
      {
        id: 16,
        name: 'Beliefs',
        alias: 'beliefs',
      },
      {
        id: 17,
        name: 'Arts',
        alias: 'arts',
      },
      {
        id: 18,
        name: 'Book Clubs',
        alias: 'book',
      },
      {
        id: 19,
        name: 'Dance',
        alias: 'dance',
      },
      {
        id: 20,
        name: 'Pets',
        alias: 'pets',
      },
      {
        id: 21,
        name: 'Hobbies & Crafts',
        alias: 'hobbies',
      },
      {
        id: 22,
        name: 'Fashion & Beauty',
        alias: 'style',
      },
      {
        id: 23,
        name: 'Social',
        alias: 'social',
      },
      {
        id: 24,
        name: 'Career & Business',
        alias: 'career',
      },
    ];
  }

  getBlogType(): IGroup[] {
    return [
      { id: 1, name: "Article", alias: "Article", description: "article" },
      { id: 2, name: "General", alias: "General", description: "general" },
      { id: 3, name: "Making Of", alias: "Making_Of", description: "making of" },
      { id: 4, name: "News", alias: "News", description: "news" },
      { id: 5, name: "Tutorial", alias: "Tutorial", description: "tutorial" },
      { id: 6, name: "Work In Progress", alias: "Work_In_Progress", description: "Work In Progress" }
    ];
  }
  getProductType(): IGroup[] {
    return [
      {
        id: 45,
        name: "Fashion",
        alias: "fashion",
        description: "Artwork & Photos"
      },
      {
        id: 46,
        name: "Phones & Tablets",
        alias: "phones",
        description: "3D Assets"
      },
      {
        id: 47,
        name: "Computer, Office & Security",
        alias: "computer",
        description: "Brushes"
      },
      {
        id: 48,
        name: "Consumer Electronics",
        alias: "electronics",
        description: "E-Books & Commics"
      },

      {
        id: 49,
        name: "Jewelry & Watches",
        alias: "jewelry",
        description: "HDR Images"
      },
      {
        id: 50,
        name: "Home, Pet & Appliances",
        alias: "home",
        description: "Materials"
      },
      {
        id: 51,
        name: "Bags & Shoes",
        alias: "bags",
        description: "References"
      },
      {
        id: 52,
        name: "Toys, Kids & Babies",
        alias: "toys",
        description: "Textures"
      },
      {
        id: 53,
        name: "Outdoor Fun & Sports",
        alias: "outdoor",
        description: "Tools, Addons, Scripts, Plugins, Software"
      },
      {
        id: 54,
        name: "Beauty, Health & Hair",
        alias: "beauty",
        description: "Tutorials"
      },
      {
        id: 55,
        name: "Automobiles & Motocycles",
        alias: "automobile",
        description: "Other"
      },
      {
        id: 56,
        name: "Home Improvement & Tools",
        alias: "tools",
        description: "Game Assets"
      }
    ];
  }

  getEventType(): IGroup[] {
    return [
      {
        id: 1,
        name: 'Outdoors & Adventure',
        alias: '',

      },
      {
        id: 2,
        name: 'Tech',
        alias: '',

      },
      {
        id: 3,
        name: 'Family',
        alias: '',

      },
      {
        id: 4,
        name: 'Health & Wellness',
        alias: '',

      },
      {
        id: 5,
        name: 'Sports & Fitness',
        alias: '',

      },
      {
        id: 6,
        name: 'Learning',
        alias: '',

      },
      {
        id: 7,
        name: 'Photography',
        alias: '',

      },
      {
        id: 8,
        name: 'Food & Drink',
        alias: '',

      },
      {
        id: 9,
        name: 'Writing',
        alias: '',

      },
      {
        id: 10,
        name: 'Languge & Culture',
        alias: '',

      },
      {
        id: 11,
        name: 'Music',
        alias: '',

      },
      {
        id: 12,
        name: 'Movement',
        alias: '',

      },
      {
        id: 13,
        name: 'LGBTQ',
        alias: '',

      },
      {
        id: 14,
        name: 'Film',
        alias: '',

      },
      {
        id: 15,
        name: 'Sci-Fi & Games',
        alias: '',

      },
      {
        id: 16,
        name: 'Beliefs',
        alias: '',

      },
      {
        id: 17,
        name: 'Arts',
        alias: '',

      },
      {
        id: 18,
        name: 'Book Clubs',
        alias: '',

      },
      {
        id: 67,
        name: 'Dance',
        alias: '',

      },
      {
        id: 68,
        name: 'Pets',
        alias: '',

      },
      {
        id: 69,
        name: 'Hobbies & Crafts',
        alias: '',

      },
      {
        id: 70,
        name: 'Fashion & Beauty',
        alias: '',

      },
      {
        id: 71,
        name: 'Social',
        alias: '',

      },
      {
        id: 72,
        name: 'Career & Business',
        alias: '',

      },
    ]
  }

  getAppForum(): IGroup[] {
    // return forums[this.app];
    return [];
  }


  addToStoreCart(item: IStoreInvoiceItem) {
    this.storeCart.emit(item);
  }



  limitCharactersWithHtml(input: string, maxChars: number): string {
    // Regular expression to identify HTML tags
    const stripTagRegex = /<[^>]*>/g;
    // Calculate text length excluding tags
    const textWithoutTags = input.replace(stripTagRegex, (matchStr) => {
      if (matchStr.startsWith('<')) return ''; // Exclude HTML tags
      // Consider emoji length as 1
      return matchStr.replace(/[\u{1F600}-\u{1F6FF}]/gu, '').length === 0 ? '' : matchStr;
    });

    const textLength = textWithoutTags.length;

    if (textLength <= maxChars) {
      return input; // Return original input if within range
    }

    // /(<\/?[a-z][\s\S]*>)|([^\s<>]+)/gi;
    const tagRegex = /<[^>]+>|[^<]+/gi
    const charactersToTrim = textLength > maxChars ? textLength - maxChars : 0;
    const segments = input.match(tagRegex) || [];
    let charCount = 0;
    let result = '';

    for (const segment of segments) {

      if (charCount + segment.length <= maxChars) {
        result += segment; // Add segment if it fits
        charCount += segment.length;
      } else if (segment.startsWith('<')) {

        result += segment; // Append complete HTML tags even if exceeding limit
        break;
      } else {
        // Trim text content within the segment while preserving tags
        const remainingChars = maxChars - charCount;
        const textSegment = segment.substring(0, remainingChars);
        result += textSegment;
        break;
      }

    }

    return result + '...'; // Append ellipsis to indicate trimmed content

  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PT', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];

  }


}
