import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EventEntity, EmbedService, IEntity } from '@galaxy/entity-api';

@Component({
  selector: 'adx-event',
  templateUrl: './ad-event.component.html',
  styleUrls: ['./ad-event.component.scss']
})
export class AdEventComponent implements OnInit {


  @Input() entity: IEntity = new EventEntity;

  art: SafeResourceUrl = '';

  constructor(
    private _embed: EmbedService
  ) { }

  ngOnInit() {
    this.art = this._embed.imgUrl(this.entity.art, 'md');
  }

}
