import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IServerResponse } from '@galaxy/entity-api';
export interface IParam {
  page?: number | string;
  pageSize?: number | string;
  id?: number | string;
  gid?: number | string;
  category?: string | number;
  categoryType?: string | number;
  searchValue?: string;
  searchFields?: string[];
  orderBy?: string[]
}

interface IRequestOption {
  headers?:
  | HttpHeaders
  | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?:
  | HttpParams
  | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

@Injectable()
export class GenericService {
  params: HttpParams = new HttpParams();
  route: string = '';

  requestOption?: IRequestOption;
  authOption?: IRequestOption;

  enableProd: boolean = true;
  rootUrl: string = '';
  private baseServiceUrl: string = '';
  constructor(
    private _http: HttpClient,
    baseServiceUrl: string = '',
    rootUrl: string = '',
  ) {
    this.baseServiceUrl = baseServiceUrl;
    this.rootUrl = rootUrl;
  }

  private creatUrl<T>(constructor: any) {
    const metadata = (new constructor).__controller();

    let url = this.enableProd ? this.rootUrl : ''; // production

    url +=
      this.baseServiceUrl +
      metadata.name +
      '/' +
      (this.route ? this.route : '');

    // reset HttpParams & route to null
    this.route = null;
    this.params = new HttpParams();

    return url.toLowerCase();
  }

  ConvertToHttpParam(paramsObject: IParam) {
    let httpParams = new HttpParams();
    if (paramsObject) {
      const fields = Object.getOwnPropertyNames(paramsObject);

      fields.forEach(field => {
        const value = paramsObject[field];
        if (value) {
          httpParams = httpParams.set(field, `${value}`);
        }
      });
    }
    return httpParams;
  }

  DataMap(data: { from: object; to: object }) {
    if (data.from && data.to) {
      const toFields = Object.getOwnPropertyNames(data.to);
      const fromFields = Object.getOwnPropertyNames(data.from);
      toFields.forEach(i => {
        if (fromFields.map(f => f.toLowerCase()).includes(i.toLowerCase())) {
          data.to[i] = data.from[i];
        }
      });
      return data.to;
    }
  }

  setRequestOptions(options: IRequestOption, headers?: any): IRequestOption {
    // add authorization if available
    // if (this._authService.authHeaders) {
    //   options.headers = new HttpHeaders();
    //   options.headers.append(
    //     this._authService.authHeaders.keys[0],
    //     this._authService.authHeaders.get(this._authService.authHeaders.keys[0])
    //   );
    // } else {
    //   options.headers = null;
    // }

    return options;
  }

  setParams(paramsObject: IParam, routes?: string): GenericService {
    this.params = this.ConvertToHttpParam(paramsObject);
    // console.log('Params OOOOOOOOOOOOOOOOO', this.params);

    this.requestOption = this.setRequestOptions({ params: this.params, });

    this.route = routes;
    return this;
  }

  get<T>(constructor: new () => T): Observable<IServerResponse<T>> {

    const url = this.creatUrl<T>(constructor);

    return this._http.get<IServerResponse<T>>(url, this.requestOption).pipe(
      map(res => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  getAs<t, T>(constructor: new () => T): Observable<IServerResponse<t>> {
    return (this.get(constructor) as any) as Observable<IServerResponse<t>>;
  }

  getAsPageOf<t, T>(constructor: new () => T): Observable<IServerResponse<t[]>> {
    return (this.get(constructor) as any) as Observable<IServerResponse<t[]>>;
  }
  getPageOf<T>(constructor: new () => T): Observable<IServerResponse<T[]>> {
    return (this.get(constructor) as any) as Observable<IServerResponse<T[]>>;
  }

  post<T>(data: T): Observable<IServerResponse<T>> {
    const constructor = (data as Object).constructor;

    const url = this.creatUrl(constructor);
    return this._http.post<IServerResponse<T>>(url, JSON.stringify(data), this.requestOption);
  }

  postAs<t, T>(constructor: new () => T, data: t): Observable<IServerResponse<T>> {
    const url = this.creatUrl(constructor);
    return this._http.post<IServerResponse<T>>(url, JSON.stringify(data), this.requestOption);
  }

  put<T>(data: T): Observable<IServerResponse<T>> {
    const constructor = (data as Object).constructor;
    const url = this.creatUrl(constructor);
    return this._http.put<IServerResponse<T>>(url, JSON.stringify(data), this.requestOption);
  }

  putAs<t, T>(constructor: new () => T, data: t): Observable<IServerResponse<T>> {
    const url = this.creatUrl(constructor);
    return this._http.put<IServerResponse<T>>(url, JSON.stringify(data), this.requestOption);
  }

  patch<T>(data: T): Observable<IServerResponse<T>> {
    const constructor = (data as Object).constructor;
    const url = this.creatUrl(constructor);
    return this._http.patch<IServerResponse<T>>(url, JSON.stringify(data), this.requestOption);
  }

  delete<T>(constructor: new () => T): Observable<IServerResponse<T>> {
    const url = this.creatUrl(constructor);
    return this._http.delete<IServerResponse<T>>(url, this.requestOption);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => error.message || 'Server Error');
  }
}
