import { Component, OnInit } from '@angular/core';
import { AppSettingService, IClaim, IRoleGrant, SuiteApiService } from '@galaxy/entity-api';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {

  showAccessModal = false;

  appClaim: IClaim;
  appRoles: IRoleGrant[] = [];

  activeRole: IRoleGrant;
  constructor(private _suiteApi: SuiteApiService) { }

  ngOnInit() {
    this.appRoles = this._suiteApi.activeAppRoles;
    this.appClaim = this._suiteApi.activeAppClaim;

    this.activeRole = this._suiteApi.getActiveRole();

  }


  onShowAccessModal() {
    this.showAccessModal = true;
  }

  onCloseAccessModal($event) {
    this.showAccessModal = false;
  }

}
