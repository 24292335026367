<div class="text-center" style="margin-top: 10%;">
  <h1 class="text-normal" style="font-size: 30px;">Choose your theme</h1>
  <p class="text-size18">
    Pick a theme that works for you. You can always <br />
    change themes in your app settings.
  </p>

  <button
    (click)="onSetTheme('light')"
    class="adx-btn adx-shadow adx-icon adx-rounder btn-default adx-lg"
  >
    <i class="axr ax-check" *ngIf="theme === 'light'"></i>
  </button>
  <button
    (click)="onSetTheme('dark')"
    class="adx-btn adx-shadow adx-icon adx-rounder btn-dark adx-lg"
  >
    <i class="axr ax-check" *ngIf="theme === 'dark'"></i>
  </button>
</div>
<div class="adx-card adx-flat text-center">
  <!-- <h2 class="title text-normal">Getting Started</h2> -->
  <!-- <p>
    Lets take a minute to get the most out of GalaxyPlay
  </!-->
  <div class="padding" style="max-width: 400px; margin: 0 auto;">
    <div class="adx-row">
      <div class="adx-colx-3">
        <div class="adx-card adx-flat text-center">
          <p>
            <i class="axr ax-play color-special" style="opacity: 1;"></i>

            <br />
            <br />
            Play
          </p>
        </div>
      </div>
      <div class="adx-colx-3">
        <div class="adx-card adx-flat text-center">
          <p>
            <i class="axr ax-photo color-special" style="opacity: 1;"></i>
            <br />
            <br />
            Artwork
          </p>
        </div>
      </div>
      <div class="adx-colx-3">
        <div class="adx-card adx-flat text-center">
          <p>
            <i
              class="axr ax-shopping-bag color-special"
              style="opacity: 1;"
            ></i>
            <br />
            <br />
            Store
          </p>
        </div>
      </div>
      <div class="adx-colx-3">
        <div class="adx-card adx-flat text-center">
          <p>
            <i class="axr ax-blog color-special" style="opacity: 1;"></i>
            <br />
            <br />
            News
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
  <p>
    <button
      routerLink="/setup/profile"
      class="adx-btn adx-md adx-flat adx-round btn-pink"
    >
      &nbsp; Get Started &nbsp;
    </button>
  </p>
</div>
