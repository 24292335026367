<!-- <div class="photo-grid">
  <div *ngFor="let item of backImage"></div>
</div> -->

<div class="connect-flex">
  <div class="left">
    <div class="top">
      <div class="greetings">
        <img src="assets/images/home/avatars.png" alt="">
      </div>
      <div style="position: relative;">
        <div class="ad-logo">
          <img src="assets/images/logo/connect.png" class="img-responsive">
        </div>
        <div class="text-size16">
          <strong class="text-size18">
            Connect Social &
            <br>
            E-Commerce Platform
          </strong>
          <p>

          </p>
          <small class="color-pink">
            https://connect.aiira.co
          </small>
        </div>
        <div>
          <a routerLink="/connect" class="adx-btn adx-ellipse adx-sm btn-dark">Learn More</a>
          <a href="https://connect.aiira.co" target="_blank" class="adx-btn adx-ellipse adx-sm btn-dark">Get
            Connected</a>
        </div>

      </div>
    </div>
  </div>
  <div class="right">
    <div class="post">
      <div class="post-person">
        <div class="img"></div>
        <div class="name">
          <div></div>
        </div>
      </div>
      <div class="post-content">
        <img src="assets/images/home/post2.jpg" class="img-responsive" alt="">
      </div>
      <div class="post-actions">
        <div>
          <i class="axl ax-message"></i>
        </div>
        <div>
          <i class="axl ax-repeat"></i>

        </div>
        <div>

          <i class="axl ax-heart"></i>
        </div>
        <div>

          <i class="axl ax-share"></i>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="module-flex">
  <div class="item">
    <a href="https://connect.aiira.co" target="_blank" class="img">
      <img src="assets/images/play/connect.jpg" alt="">
    </a>
    <span>
      Follow & Interact
    </span>
  </div>
  <div class="item">
    <a href="https://connect.aiira.co/play" target="_blank" class="img">
      <img src="assets/images/play/play.jpg" alt="">
    </a>
    <span>
      Stream Music
    </span>
  </div>
  <div class="item">
    <a href="https://connect.aiira.co/store" target="_blank" class="img">
      <img src="assets/images/play/shopping.jpg" alt="">
    </a>
    <span>
      Start Shopping
    </span>
  </div>
  <div href="https://play.google.com/store/apps/details?id=app.aiira.twa" target="_blank" class="item">
    <a class="img">
      <img src="assets/images/play/aiira-apps-home.jpg" alt="">
    </a>
    <span>
      Available on PlayStore
    </span>
  </div>
</div>
