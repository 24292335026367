import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'galaxy-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  @Input() color: string = '';

  show: boolean = false;
  constructor() { }

  ngOnInit() { }

  onTurnOff() {
    this.show = false;
  }
}
