import { Injectable, EventEmitter } from '@angular/core';
import { GenericService } from '../GenericService';
import { HttpClient } from '@angular/common/http';
import { Person, IConversation, Conversation } from '../../model';


@Injectable({
  providedIn: 'root'
})
export class MessegeApiService extends GenericService {

  currentConversation;
  defaultcurrentConversation?: IConversation;
  initiatedData: Person[];
  pendingData;

  constructor(http: HttpClient) {
    const rootUrl = 'https://api-v2.aiira.co';
    const baseUrl = '/messenger-api/';
    super(http, baseUrl, rootUrl);

    this.initiatedData = [];
    this.pendingData = new EventEmitter<Person[]>();
    this.currentConversation = new EventEmitter<IConversation | null>();
  }


  initiateConversation(person: Person) {
    this.addInitatedConversation(person);
    // last for e 100 minutes and remove initaited
    setTimeout(() => {
      this.removeInitatedConversation(person);
      this.pendingData.emit(this.initiatedData);
      if (this.currentConversation.person2?.id === person.id) {
        this.currentConversation.emit(null);
      }
      if (this.defaultcurrentConversation?.person2.id === person.id) {
        this.defaultcurrentConversation = null;
      }
    }, 100000);

  }



  addInitatedConversation(person: Person) {
    this.removeInitatedConversation(person);
    this.initiatedData.unshift(person);

    const newConversation: IConversation = new Conversation;
    newConversation.person2 = person;
    this.defaultcurrentConversation = newConversation;
    this.currentConversation.emit(newConversation);

    this.pendingData.emit(this.initiatedData);
  }

  removeInitatedConversation(person: Person) {
    // this.data = this.data.slice(index, 1);
    this.initiatedData = this.initiatedData.filter(obj => obj !== person);
    this.pendingData.emit(this.initiatedData);
  }




}
