import { Group, IGroup } from "../ori/group.model";
import { BusinessEntity } from './business-entity.model';
import { BusinessBranch } from './business-branch.model';
import { Controller } from '../decorators';
import { FinancialAccountTypeEnum, StatusEnum } from "../../enums";
export interface IFinancialAccount {

  id: number;
  name: string;
  accountNumber: string;
  bank: string;
  branch: string;
  micrNumber: string;
  ifscCode: string;
  created: number;
  modified: number;
  type: FinancialAccountTypeEnum;
  status: StatusEnum,
  createdBy: BusinessEntity;
  modifiedBy?: BusinessEntity;
  businessBranch: BusinessBranch;
}


export interface IAuthorizer {

  id: number;
  title: string;
  businessEntity: BusinessEntity;
  status: StatusEnum;
  created: number;
  modified?: number;
  createdBy: BusinessEntity;
  modifiedBy?: BusinessEntity;
  businessBranch: BusinessBranch;
}

@Controller({ name: 'FinancialAccount' })
export class FinancialAccount implements IFinancialAccount {
  constructor(
    public id = 0,
    public name = '',
    public accountNumber = '',
    public bank = '',
    public branch = '',
    public type = FinancialAccountTypeEnum.FINANCIAL,
    public status = StatusEnum.PUBLISHED,
    public micrNumber = '0',
    public ifscCode = '0',
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity(),
    public businessBranch = new BusinessBranch(),
  ) {
    // this.status.id = 2;
    // this.type.id = 440;

  }
}


@Controller({ name: 'BusinessAuthorizer' })
export class Authorizer implements IAuthorizer {
  constructor(
    public id = 0,
    public title = '',
    public businessEntity = new BusinessEntity,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
    public businessBranch = new BusinessBranch,
  ) {

  }
}
