import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IMessageFile, EmbedService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-chat-file',
  templateUrl: './ad-chat-file.component.html',
  styleUrls: ['./ad-chat-file.component.scss']
})
export class AdChatFileComponent implements OnInit {

  @Input() data: IMessageFile[] = [];
  constructor(private _embed: EmbedService) { }


  ngOnInit() {
  }

  embedImage(src: string): SafeResourceUrl {
    return this._embed.imgUrl(src);
  }



}
