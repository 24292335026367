import { SuiteProductCategoryEnum, SuiteProductTypeEnum } from '../../enums';
import { Controller } from '../decorators';
import { Group } from '../ori/group.model';
import { Business } from './business.model';

export interface IProductx {

  id: number;
  code: string;
  name: string;
  tag: string;
  art: string;
  description: string;
  isCountable: boolean;
  category: SuiteProductCategoryEnum;
  type: SuiteProductTypeEnum;
  minPack: number;
  unitsInPack: number;
  point?: number;
  created?: number;
  modified?: number;
  business?: Business[];

}
@Controller({ name: 'Productx'})
export class Productx implements IProductx {
  constructor(
    public id = 0,
    public code = '',
    public name = '',
    public tag = '',
    public art = '',
    public description = '',
    public isCountable = true,
    public category = SuiteProductCategoryEnum.GROCERY,
    public type = SuiteProductTypeEnum.SINGLE_UNIT,
    public minPack = 5,
    public unitsInPack = 1,

  ) {
    // this.type.id = 392; // single unit pack
    // this.category.id = 395; // grocery


  }
}
