<div style="height: 100vh; overflow-y: auto; margin:0;padding:0;" [ngClass]="{
    'bg-whitish': theme == 'light',
    'bg-darker bg-black': theme == 'dark'
  }">
  <ui-side-menu class="side-menu" [theme]="theme" [isMini]="closed" [appName]="app" [menu]="menu" ></ui-side-menu>


  <div class="main-pageX" [class.adx-closed]="closed">
    <galaxy-uix-header class="header" [appName]="app" [logo]="logo" [theme]="theme" (logoClicked)="onLogoClicked()">
      <ng-content select=".extra"></ng-content>
    </galaxy-uix-header>
    <div class="adx-workspace">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="clearfix"></div>
  <!--
  <div
    class="color-block"
    [ngClass]="{
      'bg-dark': theme == 'light',
      'bg-white': theme == 'dark'
    }"
  ></div> -->
</div>


<!-- <div class="workplace" [class.mini]="closed">
  <div class="menu">
    <div class="menu-btn">
      <button class="adx-btn adx-icon adx-rounder shine-green btn-dark" (click)="closed = !closed">
        <i class="axl ax-menu"></i>
      </button>
    </div>

    <div>
      <div class="adx-logo" routerLink="/welcome">
        <img src="assets/images/logo/{{app}}.png" class="img-responsive" alt="" />
      </div>
      <h3 class="text-normal text-center no-margin app-name" style="translate: 0 -40px">
        {{ app }}
      </h3>
    </div>
    <div class="adx-menu adx-list adx-sm adx-flat adx-ellipse">
      <div *ngFor="let item of menu" class="adx-item" routerLinkActive="bg-white" [routerLink]="item.route">
        <i [class]="item.icon"></i>
        {{ item.name }}
      </div>
    </div>

    <div class="adx-person">
      <div class="adx-img"></div>
      <div class="detail">
        <strong>Kofi Owusu-Afriye</strong>
        <small>kofi@primustechgh.net</small>
      </div>
    </div>
  </div>

  <div class="adx-card adx-ellipse adx-flat bg-white">
    <ng-content></ng-content>
  </div>
</div> -->


