import { Component, OnInit, Input } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-photo-card',
  templateUrl: './photo-card.component.html',
  styleUrls: ['./photo-card.component.scss']
})
export class PhotoCardComponent implements OnInit {
  @Input() theme: string = 'dark';
  @Input() isRound = true;
  @Input() isFlat = false;
  @Input() showPerson = true;

  @Input() showDetail = false;

  color: string = '';
  constructor(private _appSetting: AppSettingService) { }

  ngOnInit() {
    this.color = this._appSetting.defaultColor;
  }
}
