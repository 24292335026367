<div class="adx-card text-center">
  <h2 class="text-normal" style="font-size: 30px">Discover people</h2>
  <div style="max-width: 320px; margin: 0 auto">
    <!-- <p>
      Here are some recommendations for you.
    </p> -->
    <div class="adx-input adx-round input-pink adx-button">
      <span>
        <i class="axr ax-search"></i>
      </span>
      <input
        type="search"
        name="search"
        [(ngModel)]="searchValue"
        placeholder="Search for people"
        (keydown.enter)="onReloadData()"
      />
      <button (click)="onReloadData()" class="adx-btn adx-icon adx-round btn-pink">
        <i class="axr ax-chevron-right" style="margin-top: 6px"></i>
      </button>
    </div>
  </div>

  <!-- loading card -->
  <div *ngIf="isLoading" class="adx-card adx-flat is-loading">
    <p></p>
  </div>
  <!-- {{ data | json }} -->
  <!-- div.clearfix -->
  <!-- data -->
  <section>
    <div class="wrapper-fluid" *ngIf="!isLoading">
      <div class="adx-row">
        <div class="adx-colx-12">
          <div class="discover-grid">
            <div class="contact-item" *ngFor="let item of data">
              <adx-person [data]="item"></adx-person>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
  <p></p>
  <p></p>
  <button
    routerLink="/"
    class="adx-btn adx-md btn-tgreen btn-special adx-flat adx-round"
  >
    You are ready, let's go!
  </button>
</div>
