<app-profile-view-container [businessEntity]="person" [data]="data" [transaction]="data.transaction"
  [isLoading]="isLoading" (closeProfile)="onCloseDetail()">

  <div class="adx-list adx-flat adx-ellipse padding-sm" [class.is-loading]="isLoading">
    <div style="display: flex;">
      <div class="adx-item bg-dim {{theme}}">
        <small class="color-pink">Status</small>
        <br>
        <span class="" [ngClass]="{
          'color-prim': data.status === 1,
          'color-tgreen': data.status === 2,
          'color-pink': data.status === 3,
          'color-yellow': data.status === 4
        }" style="margin: 0 !important" [title]="data.status">
          <i class="axr ax-tools"></i>
        </span>
        {{data.status | enumKey:customerCareStatus}}
      </div>

      <div class="adx-item bg-dim {{theme}}">
        <small class="color-pink">Payment Plan</small>
        <br>
        {{data.paymentPlan | enumKey:paymentPlanEnum}}
      </div>
    </div>

    <div class="adx-item bg-dim {{theme}}">
      <small class="color-pink">Description</small>
      <br>
      {{data.description}}
    </div>
    <div class="adx-item bg-dim {{theme}}">
      <small class="color-pink">Expected Date Of Completion</small>
      <br>
      {{data.expectedDate | date: 'EEE - MMM dd, yyyy @ h:m' }}
    </div>
  </div>


  <div class="adx-divider">{{appId === 59? 'Service' :'Test'}} Requests ({{data.customerServiceRequests.length}}) <span
      class="float-right" style="font-family: monospace;">{{currency}} {{data.amount |
      number: '1.2-2'}}</span> </div>
  <div class="clearfix"></div>
  <div style="min-height: 100px; max-height: 300px; overflow-y: auto;">

    <div class="adx-list adx-sm adx-flat adx-ellipse" style="padding:0 8px;">
      <div class="adx-item {{theme}}" *ngFor="let item of data.customerServiceRequests">
        <div class="item-flex ">
          <div class="item-name">
            <strong>{{item.businessBranchService.businessService.name}}</strong>
            <br>
            <small>
              status: {{ item.status | enumKey:customerCareStatus }}
            </small>
          </div>
          <div class="amount padding-sm ">
            {{currency}} {{item.businessBranchService.businessService.price | number: '1.2-2'}}
          </div>

          <button *ngIf="data.status !== 2 && data.status !== 3"
            class="adx-btn adx-round btn-blue adx-icon outline adx-flat " style="margin:0; margin-top:-2px;"
            (click)="onEditRequestStatus(item)">
            <i class="axr ax-edit"></i>
          </button>

        </div>

      </div>
    </div>
  </div>

</app-profile-view-container>


<galaxy-modal [showModal]="showRequestModal" [showPadding]="false">
  <div class="adx-divider">Update Request : {{selectedCustomerServiceRequest.businessBranchService.businessService.name}}
  </div>

  <!-- {{selectedCustomerServiceRequest.attributes|json}} -->
  <!-- attributes -->
  <div *ngIf="selectedCustomerServiceRequest.attributes">
    <div *ngFor="let item of selectedCustomerServiceRequest.attributes" class="adx-input adx-label adx-round">
      <label for="">{{item.label}}</label>
      <input type="text" [disabled]="isUpdatingRequest" [(ngModel)]="item.value" />
    </div>
  </div>

  <div class="adx-input adx-label adx-round">
    <label for="">Status</label>
    <select name="status" [disabled]="isUpdatingRequest" [(ngModel)]="selectedCustomerServiceRequest.status" id="">
      <option value='1'> Pending</option>
      <option value='2'> Completed</option>
      <option value='3'> Cancel Request</option>
    </select>
  </div>


  <div>
    <button class="adx-btn adx-round adx-flat" [disabled]="isUpdatingRequest" (click)="showRequestModal = false">
      Cancel
    </button>

    <button class="adx-btn adx-round btn-tgreen float-right" [disabled]="isUpdatingRequest"
      [class.is-loading]='isUpdatingRequest' (click)="onUpdateCustomerServiceRequest()">
      Update
    </button>
  </div>

</galaxy-modal>
