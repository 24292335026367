import { Component, OnInit, Input } from '@angular/core';
import { IEntity, StoreApiService, Product } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-adx-store',
  templateUrl: './adx-store.component.html',
  styleUrls: ['./adx-store.component.scss']
})
export class AdxStoreComponent implements OnInit {

  @Input() id: number = 0;
  @Input() entity: IEntity = new Product;

  isLoading: boolean = true;
  showEntity: boolean = false;
  constructor(
    private _storeApi: StoreApiService
  ) { }

  ngOnInit() {
    if (this.id) {
      this.isLoading = true;
      this.getEntity();
    } else {
      this.isLoading = false;
    }
  }

  getEntity() {
    this.isLoading = true;
    this._storeApi.get(Product).subscribe(res => {
      this.entity = res.data;
      this.isLoading = false;
    })
  }

  onViewEntity() {
    this.showEntity = true;
  }

  onCloseEntity() {
    this.showEntity = false;
  }

}
