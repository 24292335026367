<div class="bg bg-darker"></div>
<div class="centered" [class.auto-login]="isSelectAccount">
  <galaxy-adaptive-card [autoDimBlack]='true' [shape]='"rounded"' [showPadding]="false">
    <div class="adx-card adx-flat" [class.is-loading]="isLoading">

      <div class="cover">

      </div>
      <!-- login signup template mode -->
      <div *ngIf="!isSelectAccount">
        <div class="adx-logo" [routerLink]="['/']" style="cursor: pointer; position: relative; z-index: 100">
          <img src="assets/images/logo/{{ app }}-logo-color.png" alt="" />
        </div>
        <!-- <h1 class="adx-title adx-spread">AIR MEDIA PLATFORM</h1> -->
        <br />
        <small class="adx-spread">Lets Get
          <span class="color-special" style="font-weight: bold"> Connected </span>
        </small>
        <div *ngIf="showForgot; then iforgot; else signApp"></div>

      </div>

      <!-- airUser Mode -->
      <div *ngIf="isSelectAccount" class="one-touch-log">
        <!-- <div class="adx-card adx-flat"> -->
        <div class="user-flex2">
          <div class="user-circle">
            <div class="user-image">
              <img [src]="activeUserAvater" alt="" />
            </div>
          </div>
          <span class="name">
            <!-- {{ activeAirUser.name }} -->
            <i class="color-tgreen">&#64;</i>{{ activeAirUser.username }}
          </span>
          <div class="adx-input adx-round adx-icon">
            <span> <i class="axr ax-lock"></i> </span>
            <input [(ngModel)]="userPass" (keyup.enter)="passwordLogin()" placeholder="Your password" #airPassword
              name="userPass" type="password" />
          </div>
          <p style="padding: 0 16px">
            <button (click)="passwordLogin()" class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
              [disabled]="isLoading || airPassword.value.length < 4">
              LOGIN
            </button>
          </p>
          <p style="padding: 4px 16px">
            <button class="adx-btn adx-full adx-sm btn-yellow adx-round outline adx-spread" (click)="onRemoveAirUser()"
              [disabled]="isLoading">
              REMOVE USER
            </button>
          </p>
          <!--
            <div *ngIf="!isLoading" class="detail text-left">
            </div>
          -->
        </div>
        <!--
          <p>
            <span>
              <i class="fal fa-music"></i>
            </span>
            <span>
              <i class="fal fa-video"></i>
            </span>
            <span>
              <i class="fal fa-share-alt"></i>
            </span>
            <span>
              <i class="fal fa-gamepad"></i>
            </span>
          </p>
        -->

        <p>
          <small class="adx-spread">Lets Get
            <span class="color-special" style="font-weight: bold">
              Connected
            </span>
          </small>
        </p>
        <!-- </div> -->
      </div>
    </div>
    <br />
    <span class="padding-sm" style="opacity: 0.4; display: inline-block; margin-bottom: 8px;">

      <a class="brand" href="https://aiira.co" target="_blank"><i style="transform: scale(1) translateY(4px)"
          class="axl ax-brand-aiira"></i>
        I I R A</a>
    </span>
  </galaxy-adaptive-card>
  <div class="adx-btn-group adx-round shotcuts" *ngIf="appId === 60">
    <button class="adx-btn adx-icon">
      <i class="axr ax-play" title="Play Music & Videos" [routerLink]="['/media']"></i>
    </button>
    <button class="adx-btn adx-icon" title="Artworks & Photos" [routerLink]="['/artwork']">
      <i class="axr ax-photo"></i>
    </button>
    <button class="adx-btn adx-icon" title="Events" [routerLink]="['/events']">
      <i class="axr ax-calendar-event"></i>
    </button>
    <button class="adx-btn adx-icon" title="Start Shopping" [routerLink]="['/store']">
      <i class="axr ax-shopping-bag"></i>
    </button>
    <button class="adx-btn adx-icon" title="Read Blogs" [routerLink]="['/blogs']">
      <i class="axr ax-blog"></i>
    </button>
  </div>
</div>

<ng-template #login>
  <form (ngSubmit)="passwordLogin()" #lf="ngForm" class="adx-form input-tgreen">
    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span> <i class="axr ax-user"></i> </span>
      <input type="text" [(ngModel)]="userName" name="userName" placeholder="Aiira ID" [pattern]="usernamePattern"
        required [readOnly]="isLoading" />
    </div>

    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Password</label> -->
      <span> <i class="axr ax-lock"></i> </span>
      <input type="password" [(ngModel)]="userPass" name="userPass" placeholder="Password" required
        [readOnly]="isLoading" />
      <small class="color-pink">{{ message.loginError }}</small>
    </div>
    <p style="padding: 0 16px">
      <button class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
        [disabled]="isLoading || !lf.valid">
        LOGIN
      </button>
      <button class="adx-btn adx-full adx-sm btn-tgreen clear adx-flat adx-round " (click)="onToggleForgotPassword(true)"
        [disabled]="isLoading">
        Forgot password?
      </button>
    </p>
  </form>

  <!--
    <div class="g-signin2" data-onsuccess="onSignIn">

    </div>
  -->

  <!-- <div class="adx-card adx-flat">
    <div class="adx-row">
      <div class="adx-colx-4">
        <button
          (click)="socialSignIn('google')"
          class="adx-btn adx-round adx-sm adx-icon btn-default"
        >
          <i class="fab fa-google"></i>
        </button>
      </div>
      <div class="adx-colx-4">
        <button
          (click)="socialSignIn('facebook')"
          class="adx-btn adx-round adx-sm adx-icon btn-blue"
        >
          <i class="fab fa-facebook"></i>
        </button>
      </div>

      <div class="adx-colx-4">
        <button class="adx-btn adx-round adx-sm adx-icon btn-prim">
          <i class="fab fa-twitter"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="clearfix"></div> -->
  ----------- or -----------
  <p style="padding: 0 16px">
    <span class="social-btns">
      <button class="adx-btn adx-round adx-md adx-full btn-blue" (click)="socialSignIn('google')" style="background-color:#3a7cec;">
        <div class="google-logo"><img src="assets/images/google-logo.jpg" alt="" class="img-responsive"></div> <b
          style="font-weight: 400;">Sign in with Google</b>
      </button>
      <!-- <button class="adx-btn adx-round adx-iconx btn-default" disabled (click)="socialSignIn('apple')">
        &nbsp;&nbsp;&nbsp;<i class="axr ax-brand-apple"></i>
      </button>
      <button class="adx-btn adx-round adx-iconx btn-default" (click)="socialSignIn('twitter')">
        &nbsp;&nbsp;&nbsp;<i class="axr ax-brand-twitter"></i>
      </button> -->
      <!-- <button class="adx-btn adx-round adx-iconx btn-default" (click)="socialSignIn('microsoft')">
        &nbsp;&nbsp;&nbsp;<i class="axr ax-brand-windows"></i>
      </button> -->
    </span>

    <button class="adx-btn adx-full adx-sm btn-yellow adx-round outline adx-spread" (click)="switchMode()"
      [ngClass]="{'btn-blue': theme ==='light'}" [disabled]="isLoading">
      SIGNUP
    </button>
  </p>
</ng-template>

<ng-template #signup>
  <form #f="ngForm" (ngSubmit)="onToggleTermsModal()" class="adx-form input-yellow">
    <div class="adx-input adx-icon adx-round" [class.is-loading]="isCheckingUsername"
      [class.adx-success]="message.usernameChecked && !message.usernameAvailable"
      [class.adx-error]="message.usernameChecked && message.usernameAvailable">
      <!-- <label for="email">Username/Email</label> -->
      <span> <i class="axr ax-user"></i> </span>
      <input type="text" placeholder="Enter Aiira ID" min="4" maxlength="15" [(ngModel)]="user.username"
        name="user.username" [pattern]="usernamePattern" (blur)="onCheckValue('username', user.username)" required
        [readOnly]="isLoading || isCheckingUsername" />
      <small>{{ message.username }}</small>
    </div>
    <div class="adx-input adx-icon adx-round" [class.is-loading]="isCheckingEmail"
      [class.adx-success]="message.emailChecked && !message.emailAvailable"
      [class.adx-error]="message.emailChecked && message.emailAvailable">
      <!-- <label for="email">Username/Email</label> -->
      <span> <i class="axr ax-mail"></i> </span>
      <input type="email" [(ngModel)]="user.email" min="4" maxlength="32" name="user.email" placeholder="Enter Email"
        [pattern]="emailPattern" (blur)="onCheckValue('email', user.email)" required
        [readOnly]="isLoading || isCheckingEmail" />
      <small [class.color-pink]="message.emailAvailable" [class.color-tgreen]="!message.emailAvailable">
        {{ message.email }}
      </small>
    </div>

    <div class="adx-input adx-icon adx-round" [class.adx-error]="message.pass1Error">
      <!-- <label for="email">Password</label> -->
      <span> <i class="axr ax-lock"></i> </span>
      <input type="password" min="6" [(ngModel)]="pass1" name="pass1" (blur)="onCheckPassLength()"
        placeholder="Enter Password" required [readOnly]="isLoading" />
      <small [class.color-pink]="message.passError">
        {{ message.pass1 }}
      </small>
    </div>

    <div class="adx-input adx-icon adx-round" [class.adx-error]="message.passError">
      <!-- <label for="email">Password</label> -->
      <span> <i class="axr ax-lock"></i> </span>
      <input type="password" min="4" [(ngModel)]="pass2" name="pass2" placeholder="Confirm Password" required
        [readOnly]="isLoading" />
      <small [class.color-pink]="message.passError"> {{ message.pass }} </small>
    </div>
    <p style="padding: 0 16px">
      <button class="adx-btn adx-full adx-sm btn-yellow adx-round adx-spread" type="submit"
        [disabled]="isLoading || !f.valid">
        SIGNUP
      </button>
    </p>
  </form>
  ----------------------
  <p style="padding: 0 16px">
    <button class="adx-btn adx-sm adx-full btn-tgreen adx-round outline adx-spread" (click)="switchMode()"
      [disabled]="isLoading">
      LOGIN
    </button>
  </p>
</ng-template>

<div class="users-footer">
  <button *ngFor="let user of airUsers" (click)="onSelectAccount(user)"
    class="adx-btn adx-round adx-icon btn-dark text-upper" style="background-color: black"
    [style.background-image]="'url(' + swapCoverImage(user.avatar) + ')'">
    {{ user.avatar.trim === '' ? user.username[0] : '' }}
  </button>

  <button *ngIf="isSelectAccount" (click)="onSwitchToLogin()" class="adx-btn adx-round adx-icon btn-pink btn-special">
    <i class="axl ax-login"></i>
  </button>
</div>
<galaxy-notify [reverseConstrast]="true"></galaxy-notify>

<!-- Modal -->
<galaxy-modal [showModal]="showTermsModal" [showPadding]="false" [width]="50">
  <div *ngIf="modalType == 0; then terms; else verification"></div>
</galaxy-modal>

<!-- Templates -->

<ng-template #verification>
  <div class="text-center">
    <h2 class="text-normal">Verification Code</h2>
    <div class="adx-input adx-round">
      <input type="number" [(ngModel)]="enteredCode" placeholder="Enter Code Here" />
    </div>
    <p style="opacity: 0.6">
      <small>
        Verification Code has been sent to your mail {{ user.email }}. If you
        dont recieve the mail, please
        <button class="adx-btn adx-flat clear btn-blue">resend</button>
      </small>
    </p>
  </div>

  <div>
    <button class="adx-btn adx-round btn-default" (click)="onToggleTermsModal()">
      Cancel
    </button>
    <button class="adx-btn adx-round btn-blue float-right" (click)="onVerify()">
      Verfiy
    </button>
  </div>
</ng-template>

<ng-template #terms>
  <!-- terms & Conditioins -->
  <!-- <div class="adx-btn-group adx-round">
    <button (click)="switchTerms(0)" [class.active]="termsValue === 0" class="adx-btn adx-round btn-default">
      Terms & Conditions
    </button>
    <button (click)="switchTerms(1)" [class.active]="termsValue === 1" class="adx-btn adx-round btn-default">
      Privacy Policy
    </button>
  </div>
  <div style="height: 80vh; overflow-y: scroll">
    <app-terms-conditions *ngIf="termsValue === 0"></app-terms-conditions>
    <app-privacy *ngIf="termsValue === 1"></app-privacy>
  </div> -->

  <!-- <section class="adx-section">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-cols-12">
        <div class="adx-card adx-flat">
          <div class="center-box">
            <h1 class="text-normal">Contact Us</h1>
            <p style="font-size: 17px">
              If you have questions regarding your data privacy rights, or
              would like to submit a related data privacy right request,
              please email us at privacy@aiira.co. Please allow up to
              30 days for requests to be processed. Aiira Inc.
              reserves the right to charge a reasonable fee to process
              excessive or repeat requests.
            </p>
            <p style="font-size: 17px">
              If you have general questions concerning this Privacy Policy
              please contact us at: <br />
              <br />
              support@aiira.co <br />
              <br />
              +233-553-007-658. <br />
              <br />
              Aiira Inc.<br />
              <br />
              Kumasi, Ghana
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
  <div class="adx-divider">Agreement</div>
  <div class="adx-list padding-sm adx-flat adx-ellipse">
    <div class="adx-item item-flex {{theme}}">
      <div>
        <i class="axl ax-2x ax-shield-check"></i>
      </div>
      <div class="detail">
        <h3>Terms & Conditions</h3>
        <p>
          https://aiira.co/tos
        </p>
      </div>
      <div>

        <a href="https://aiira.co/tos" target="_blank" class="adx-btn adx-round btn-default adx-flat adx-round">Read</a>
      </div>
    </div>

    <div class="adx-item item-flex {{theme}}">
      <div>
        <i class="axl ax-2x ax-user-check"></i>
      </div>
      <div class="detail">

        <h3>Privacy Policy</h3>
        <p>
          https://aiira.co/privacy
        </p>
      </div>
      <div>

        <a href="https://aiira.co/privacy" target="_blank" class="adx-btn adx-round btn-default adx-flat adx-round">
          Read
        </a>
      </div>
    </div>
  </div>

  <div>
    <button class="adx-btn adx-round btn-default" (click)="onToggleTermsModal()">
      Cancel
    </button>
    <button class="adx-btn adx-round btn-tgreen float-right" (click)="onAgreeTerms()">
      Agree
    </button>
  </div>
  <div class="clearfix"></div>
</ng-template>


<!-- login and signup -->
<ng-template #signApp>
  <div *ngIf="loginMode; then login; else signup"></div>
</ng-template>


<!-- reset password request -->
<ng-template #iforgot>
  <form (ngSubmit)="sendPassWordReset()" #requestForm="ngForm" class="adx-form input-tgreen">
    <p>
      Enter your email to request for password reset
    </p>
    <div class="adx-input adx-icon adx-round">
      <!-- <label for="email">Username/Email</label> -->
      <span> <i class="axr ax-mail"></i> </span>
      <input type="email" [(ngModel)]="userName" name="email" placeholder="Enter your email" [pattern]="emailPattern"
        required [readOnly]="isLoading" />
    </div>

    <p style="padding: 0 16px">
      <button class="adx-btn adx-sm adx-full btn-tgreen btn-special adx-round adx-spread"
        [disabled]="isLoading || !requestForm.valid" (click)="onRequestReset()">
        Send Request
      </button>
      <button class="adx-btn adx-sm adx-full btn-tgreen btn-grey adx-round adx-spread" [disabled]="isLoading "
        (click)="onToggleForgotPassword(false)">
        Cancel
      </button>
    </p>
  </form>
</ng-template>
