<galaxy-adaptive-card [showPadding]="false" [shape]="'round'">
  <div class="adx-img">
    <img
      src="assets/images/logo/artist-logo-dark.png"
      alt=""
      class="img-responsive"
    />
  </div>
</galaxy-adaptive-card>
<div class="padding-sm">
  name/title
</div>
