import { Component, OnInit, Input } from '@angular/core';
import { EmbedService, User, AppSettingService, Person, SocialApiService, AuthService, NotifyService, Connection, ErrorHandlerService } from '@galaxy/entity-api';
// import { Media } from '../model/media';

@Component({
  selector: 'ui-person-item',
  templateUrl: './person-item.component.html',
  styleUrls: ['./person-item.component.scss']
})
export class PersonItemComponent implements OnInit {
  @Input() data: Person = new Person;

  following: boolean = false;
  // media = new Media();

  isCurator: boolean = true;
  avatar?: any;


  showPhotos: boolean = false;
  showMedia: boolean = false;
  showArtwork: boolean = false;


  profolioId: number = 0;

  viewerId: number = 0;

  gids: any[] = [];
  constructor(

    private _socialApi: SocialApiService,
    private _authService: AuthService,
    private _notify: NotifyService,
    private _embed: EmbedService,
    private _appSettings: AppSettingService,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {

    this.viewerId = this._authService.loggedIn ? this._authService.currentUser.gid.social.u : 0;
    // this.media.title = 'My Track';
    // this.media.mediaTypedId = 63;

    this.avatar = this._embed.imgUrl(this.data.image, 'sm');

    // if (this.data.entityType === 'Member') {
    //   this.isCurator = false;
    // }

    const appId = this._appSettings.getAppId();

    this.gids = this.data.gid;
    // if the app is artist = show artowkrs, play show media, else show nothing
    switch (appId) {
      case 58:
        this.showPhotos = true;
        this.profolioId = this.gids['lyfeshot']['u'];
        break;
      case 59:
        this.showMedia = true;
        this.profolioId = this.gids['play']['u'];
        break;
      case 60:
        this.showArtwork = true;
        this.profolioId = this.gids['artist']['u'];
        break;

      default:
        break;
    }

  }

  onFollowClick() {
    this.following = !this.following;
  }



  // action keys
  onRelateToPerson(statusId: number = null) {

    if (!this._authService.loggedIn) {
      this._notify.error('Please login to continue action');
      return null;
    }

    let connectQuery = new Connection;
    connectQuery.person.id = this.data.id;
    connectQuery.viewer.id = <number>this.viewerId;
    connectQuery.status = statusId;

    // console.log('connectin query goinggggggg', connectQuery);

    this._socialApi
      .setParams({}, null)
      .post(connectQuery)
      .subscribe(
        {
          next: (res) => {
            if ((res as any)['success']) {
              let message = '';
              switch (statusId) {
                case 1:
                  message = 'You are now following @' + this.data.username;
                  this.data.followedByViewer = true;
                  break;
                case 2:
                  message = 'You unfollowed @' + this.data.username;
                  this.data.followedByViewer = false;

                  break;

                default:
                  message = 'Something went wrong! Follow @' + this.data.username;
                  break;
              }

              this._notify.success(message);
            } else {
              this._notify.error('Something went wrong. Please try again');

            }
          },
          error: (err) => {
            // this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
    // 61 - to follow
    // 62 - to unfollow
    // 63 - to follow + notify
    // 64 - to block

  }
}
