import { IBusiness, Business } from './business.model';
import { IGroup, Group } from '../ori/group.model';
import { IBusinessEntity, BusinessEntity } from './business-entity.model';
import { IBusinessBranch, BusinessBranch } from './business-branch.model';
import { ITransaction, Transaction } from './transaction.model';
import { Controller } from '../decorators';
import { IProgrammeClass, Person } from '..';
import { MediaTypeEnum, PaymentTypeEnum, StatusEnum } from '../../enums';


export interface IBusinessServiceMedia {
  // Basic Info
  id: number;
  title: string;
  businessService?: IBusinessService;

  // VideoInfo
  description: string;
  thumbnail: string;

  path: string;
  file?;
  // music/video
  type: MediaTypeEnum;
  status: StatusEnum;

  orderNumber: number;

  created?: number;
}

@Controller({ name: 'BusinessServiceMedia' })
export class BusinessServiceMedia implements IBusinessServiceMedia {
  constructor(
    public id = 0,
    public title = '',
    public thumbnail = '',
    public businessService = new BusinessService(),
    public description = '',
    public path = 'assets/images/spring.jpg',
    public type = MediaTypeEnum.IMAGE, // image // video // 3d
    public status = StatusEnum.PUBLISHED,
    public orderNumber = 1,
    public file = ''
  ) { }
}

export interface IBusinessService {

  id: number;
  image?: string;
  name: string;
  business: IBusiness;
  description: string;
  price: number;
  attributes: string[];
  service?: string; // these are actually products of the business
  status: StatusEnum;
  created?: number;
  modified?: number;
  createdBy?: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  programmeClasses?: IProgrammeClass[];
  media?: IBusinessServiceMedia[];
  businessBranchServices?: IBusinessBranchService[];
  bookmarks?: IBusinessServiceBookmark[];
  programmeClassesCount?: number;
  viewCount?: number;
  rating?: number;
  bookmarkCount?: number;
  isBookmarked?: boolean;

}

@Controller({ name: 'BusinessService' })
export class BusinessService implements IBusinessService {
  constructor(
    public id = 0,
    public image = '',
    public name = '',
    public business = new Business,
    public description = '',
    public price = 0.0,
    public attributes = [],
    public media = [],
    public businessBranchServices = [],
    public status = StatusEnum.PUBLISHED,
    public createdBy = new BusinessEntity,
    public rating = 0,
  ) { }
}


export interface IBusinessBranchService {
  id: number;
  created?: number;
  modified?: number;
  status: StatusEnum,
  businessBranch: IBusinessBranch;
  businessService: IBusinessService;
}


@Controller({ name: 'BusinessBranchService' })
export class BusinessBranchService implements IBusinessBranchService {
  constructor(
    public id = 0,
    public status = StatusEnum.PUBLISHED,
    public businessBranch = new BusinessBranch(),
    public businessService = new BusinessService(),
  ) { }

}



export interface IBusinessServiceBookmark{
  id: number;

  person: Person;
  businessService: IBusinessService;
  status: StatusEnum;

  created: number;
  modified?: number;
}

@Controller({ name: 'Bookmark/Service' })
export class BusinessServiceBookmark implements IBusinessServiceBookmark{
constructor(
  public id = 0,
  public person = new Person,
  public businessService = new BusinessService,
  public status = StatusEnum.PUBLISHED,
  public created = 0,
){}
}

export interface ICustomerService {

  id: number;
  app: IGroup;
  businessBranch: IBusinessBranch,
  businessEntity: IBusinessEntity,
  transaction: ITransaction;
  description: string;
  amount: number;
  downPayment: number;
  paymentPlan: PaymentTypeEnum;
  status: StatusEnum;
  customerServiceRequests: ICustomerServiceRequest[];
  expectedDate?: number;
  commerceDate?: number;
  completedDate?: number;
  created?: number;
  modified?: number;
  createdBy?: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
  customerServiceRequestsCount?: number;

}

@Controller({ name: 'CustomerService' })
export class CustomerService implements ICustomerService {
  constructor(
    public id = 0,
    public app = new Group,
    public businessBranch = new BusinessBranch,
    public businessEntity = new BusinessEntity,
    public transaction = new Transaction,

    public description = '',
    public amount = 0,
    public downPayment = 0,

    public customerServiceRequests = [],

    public expectedDate = 0,
    public commerceDate = 0,
    public completedDate = 0,
    public paymentPlan = PaymentTypeEnum.ONE_TYPE_PAYMENT,
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public createdBy = new BusinessEntity,
  ) {
    this.app.id = 59; // 59 - airClient, 487 - airLab
    // this.status.id = 4; // 2 - published/ attend, 1 - unpublished/ongoing, 3 - trash/deleted, 4 - pending
    // this.paymentPlan.id = 406; // one-time payment

  }
}



export interface ICustomerServiceRequest {
  id: number;
  customerService: ICustomerService;
  businessBranchService: IBusinessBranchService;
  status: StatusEnum;
  attributes?: any
}


@Controller({ name: 'CustomerServiceRequest' })
export class CustomerServiceRequest implements ICustomerServiceRequest {
  constructor(
    public id = 0,
    public customerService = new CustomerService,
    public businessBranchService = new BusinessBranchService,
    public status = StatusEnum.UNPUBLISHED,

  ) {
    // this.status.id = 1;
    // this.status.name = 'Pending';
    // 1 => 'Pending',
    // 2 => 'Done',
    // 3 => 'Cancelled'
  }
}
