import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SecurityContext, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppSettingService, SocialApiService, AuthService, History, IEntity, EntityReaction, OriDbService, Entity, EntitySocketService, EmbedService, ErrorHandlerService } from '@galaxy/entity-api';

import { Analytics, logEvent } from '@angular/fire/analytics';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-post-acions',
  templateUrl: './post-acions.component.html',
  styleUrls: ['./post-acions.component.scss']
})
export class PostAcionsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: IEntity = new Entity;
  @Input() entityId: number = 0;
  @Input() entityTypeId: number = 0;
  @Input() entityTypeName: string = '';
  @Input() viewingEntity = true;

  @Output() likeClicked = new EventEmitter();
  @Output() repostClicked = new EventEmitter();
  @Output() shareClicked = new EventEmitter();
  @Output() commentClicked = new EventEmitter();
  @Output() countUpdated = new EventEmitter<IEntity>();

  theme: string = '';themeUpdated$!: Subscription;
  personId: number = 0;
  personUsername: string = 'guest';

  @Input() isUserLike: boolean = false;
  @Input() isUserRepost: boolean = false;

  @Input() showAnalytics: boolean = false;
  viewAnalytics: boolean = false;
  showRepostModal: boolean = false;


  sockectConnected = false;




  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _socialApi: SocialApiService,
    private _oriDB: OriDbService,
    private _entitySocket: EntitySocketService,
    private _analytics: Analytics,
    private _errorHandlerService: ErrorHandlerService

  ) {

  }

  ngOnInit() {

    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => this.theme = res);


    if (this._authService.loggedIn) {
      this.personId = this._authService.currentUser.gid.social.u ?? 0;
      // this.userReaction(this.personId, 24);
      // this.userReaction(this.personId, 32);
    }


    this.entityTypeId = this.getEntityTypeId(this.entityTypeName);

    // this._appSetting.isViewingEntity.subscribe(res => {
    //   // regiister websocket for reactions on entity
    if (this.viewingEntity) {
      this._entitySocket.connect();
      this.sockectConnected = true;
      this.registerSocket();
    }
    // })

  }

  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.entityId;
    // const hit: SimpleChange = changes.data;

    if (id && !id.firstChange && id.previousValue !== id.currentValue) {
      this.resetSocket();
    }
  }


  resetSocket() {
    if (this.sockectConnected) {
      this._entitySocket.disconnect();
      this.sockectConnected = false;
    }

    setTimeout(() => {
      this._entitySocket.connect();
      this.sockectConnected = true;
      this.registerSocket();
    }, 1000);

  }


  getEntityTypeId(type: string): number {
    let typeId = 0;

    switch (type) {
      case 'media':
        typeId = 54

        break;
      case 'artist':
        typeId = 55

        break;
      case 'store':
        typeId = 56

        break;
      case 'blog':
        typeId = 57

        break;
      case 'event':
        typeId = 60

        break;
      case 'social':
        typeId = 59

        break;

      default:
        typeId = 0
        break;
    }

    return typeId;
  }


  // getLiveUpdates() {
  //   this._entitySocket.connect();
  //   this._entitySocket.emit('join',
  //     {
  //       username: this.personUsername,
  //       entityIds: [this.entityTypeName + '-' + this.data.id]
  //     })

  // }


  registerSocket() {

    // register socket, join this entity room
    this._entitySocket.emit(
      'newEntityRoom',
      { userId: 0, entityId: this.entityTypeName + '-' + this.data.id }
    );

    // emit via socket & mark as gone if only it not a new conversation
    this._entitySocket.emit('sendEntityReaction', { type: this.entityTypeName, data: this.data });

    // list to socket
    this._entitySocket.listen('newEntityReaction').subscribe(res => {

      const entityTypeId = this.getEntityTypeId(res['type']);
      const newUpdate = <IEntity>res['data'];

      if (entityTypeId === this.entityTypeId && newUpdate.id === this.data.id) {
        if (this.data.hit < newUpdate.hit) {
          // update new data with my status to data
          newUpdate.liked = this.data.liked;
          newUpdate.reposted = this.data.reposted;

          this.data = newUpdate;
          this._oriDB.updateFeedReactions(this.data, this.entityTypeId);
          this.countUpdated.emit(this.data);
        }

      }
    });

    // register seen socket


  }

  updatePostReactions() {
    if (this.entityTypeId === 0) {
      return;
    }

    // console.log('reading db')

    setTimeout(() => {
      this._socialApi.setParams({
        id: this.data.id, //entity
        searchValue: this.entityTypeName,
      })
        .get(EntityReaction)
        .subscribe(
          {
            next: (res) => {
              if (res.data.likeCount) {
                this.data.likeCount = res.data.likeCount;
                this.data.commentCount = res.data.commentCount;
                this.data.repostCount = res.data.repostCount;
                this.data.hit = res.data.hit;
                this.data.engagement = res.data.engagement;

                // emit via socket & mark as gone if only it not a new conversation
                this._entitySocket.emit('sendEntityReaction', { type: this.entityTypeName, data: this.data });

                this._oriDB.updateFeedReactions(this.data, this.entityTypeId);
                this.countUpdated.emit(this.data);
              }
            },
            error: (err) => {
              // this.isLoading = false;
              this._errorHandlerService.handleError(err)
            },
          }
        );

    }, 3000);

  }

  onRetweet() {
    logEvent(this._analytics, 'connect_post_reaction', {
      reactionType: 'repost',
      post: {
        id: this.data.id,
        type: this.entityTypeName
      }
    });


    this.data.reposted = !this.data.reposted;
    this.repostClicked.emit(this.data.reposted);
    this.updatePostReactions();

  }

  onShare() {
    logEvent(this._analytics, 'connect_post_reaction', {
      reactionType: 'share',
      post: {
        id: this.data.id,
        type: this.entityTypeName
      }
    });


    this.shareClicked.emit(true);
  }

  onComment() {
    // this.updatePostReactions();
    logEvent(this._analytics, 'connect_post_reaction', {
      reactionType: 'comment',
      post: {
        id: this.data.id,
        type: this.entityTypeName
      }
    });

    this.commentClicked.emit(true);
  }

  onLike() {

    logEvent(this._analytics, 'connect_post_reaction', {
      reactionType: 'like',
      post: {
        id: this.data.id,
        type: this.entityTypeName
      }
    });


    this.data.liked = !this.data.liked;
    this.likeClicked.emit(this.data.liked);
    this.updatePostReactions();
  }

  onViewAnalysis(value: boolean) {
    this.viewAnalytics = value;
  }

  onRepostModalToggle() {
    this.showRepostModal = !this.showRepostModal;
  }

  ngOnDestroy() {
    // disconnect from live updates
    this._entitySocket.disconnect();
    this.sockectConnected = false;
      this.themeUpdated$.unsubscribe();
    }
  
}


