<app-adx-client-avatar *ngIf="businessEntity.id > 0" [client]="businessEntity.entity" [isLoading]="isLoading">
</app-adx-client-avatar>


<ng-content></ng-content>

<div class="adx-card bg-black adx-ellipse no-margin transaction">
  <div class="adx-item">
    <small class="color-tgreen">Created On: </small>
    <span>
      {{data.created*1000 | date:'MMM dd, yyyy @ h:m'}}
    </span>
  </div>
  <div class="adx-item" *ngIf="transaction?.referenceNumber">
    <small class="color-tgreen">Reference Number: </small>
    <qr-code [value]="transaction.referenceNumber" title="Click" style="cursor: pointer;" (click)="showModal=true"
      size="32" level='Q'></qr-code>
    <span style='padding-left:8px;'>
      {{transaction.referenceNumber}}
    </span>
  </div>
  <div class="adx-item" *ngIf="transaction?.paid">
    <small class="color-tgreen">Total Paid: </small>
    <span>
      {{currency}} {{transaction.paid | number: '1.2-2'}}
    </span>
  </div>

  <div class="padding-sm">
    <button class="adx-btn  btn-dark adx-round ">
      <b>
        Amount: {{currency}} {{ ((transaction?.amount ?? data?.amount ?? 0) | number: '1.2-2').split('.')[0] }}
        <sup>{{ ((transaction?.amount ?? data?.amount ?? 0) | number: '1.2-2').split('.')[1] }}</sup>
      </b>
    </button>
    <button class="adx-btn btn-grey outline adx-round float-right" (click)="onCloseClicked()">
      Close
    </button>
  </div>

  <!-- info on the person who created or modified the entity -->
  <div class="user-info" *ngIf="data.createdBy">
    <div class="adx-dropdown">
      <button class="adx-btn adx-round adx-trigger adx-icon adx-xs btn-prim adx-shadow">
        <i class="axr ax-question-mark"></i>
      </button>
      <div class="adx-content" style="margin-left:-210px; margin-top:-50px; width:250px;">
        <galaxy-adaptive-card [showPadding]="false" [shape]="'rounded'" [autoDimBlack]="true">
          <!-- created -->
          <div>
            <div class="adx-avatar adx-sm" [class.adx-flat]="data.created === data.modified">
              <small class="color-blue">Created By</small>
              <div class="adx-img bg-black adx-icon-letter">
                {{data.createdBy.entity.name[0]}}
              </div>
              <h2>{{data.createdBy.entity.name}}</h2>
              <p>
                on {{data.created * 1000 | date:'MMM dd, yyyy @ h:m:s' }}
              </p>
            </div>
          </div>

          <!-- modified -->
          <div *ngIf="+data.created < +data.modified">
            <div class="adx-avatar adx-sm adx-flat">
              <small class="color-blue">Modified By</small>
              <div class="adx-img bg-black adx-icon-letter">
                {{data.modifiedBy?.entity?.name[0]}}
              </div>
              <h2>{{data.modifiedBy?.entity?.name}}</h2>
              <p>
                on {{data.modified * 1000 | date:'MMM dd, yyyy @ h:m:s' }}
              </p>
            </div>
          </div>

        </galaxy-adaptive-card>
      </div>
    </div>
  </div>
</div>


<galaxy-modal [showModal]="showModal" (onCloseModalClicked)="showModal=false" [showPadding]="false">
  <div class="reciept" id="ref-print">
    <div class="text-center">
      <div class="adx-divider">
        Transaction Reciept
        <br>
        <div style="font-size: 17px; max-width: 200px; margin:0 auto;">
          {{business.name}}
        </div>
      </div>
    </div>

    <div class="cart-item">
      <div>
        Date
      </div>
      <b>
        {{data.created*1000 | date}}
      </b>
    </div>
    <div class="cart-item" *ngIf="businessEntity">
      <div>
        Client
      </div>
      <b>
        {{businessEntity?.entity?.name}}
      </b>
    </div>
    <div class="text-center">
      _______________________________________
      _______________________________________
    </div>

    <div class="cart-item">
      <div>
        Total
      </div>
      <b>
        {{currency}} {{transaction?.amount??0 | number: '1.2-2'}}
      </b>
    </div>
    <div *ngIf="+transaction?.paid > 0">
      <div class="cart-item">
        <div>
          Amount Paid
        </div>
        <b>
          {{currency}} {{transaction?.paid??0 | number: '1.2-2'}}
        </b>
      </div>
      <div class="cart-item" *ngIf="transaction?.amount > transaction?.paid">
        <div>
          Balance
        </div>
        <b>
          {{currency}} {{(transaction?.amount - transaction?.paid) | number: '1.2-2'}}
        </b>
      </div>
    </div>

    <br>
    <br>

    <div class="text-center">

      <!-- <div class="adx-divider">Transaction Reference</div> -->
      <qr-code [value]="transaction?.referenceNumber??0" size="256" errorCorrectionLevel='Q'></qr-code>
      <br>
      <br>
      <small>
        {{transaction?.referenceNumber}}
      </small>
      <br>
      <br>
      <small>
        Powered by: [ AIIRA - airSuite : www.aiira.co/suite ]
        <br>
        Printed Date: {{today|date}}
      </small>
    </div>
  </div>
  <div>
    <button class="adx-btn btn-grey adx-round " (click)="showModal=false">
      Close
    </button>
    <button class="adx-btn btn-tgreen adx-round float-right" (click)="onPrint()">
      Print
    </button>
  </div>
</galaxy-modal>
