import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, OnDestroy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { IEntity, Product, AppSettingService, AuthService, EmbedService, StoreApiService, OriDbService, NotifyService, History, MetatagService, ActivityTypeEnum, StatusEnum, SuiteProductTypeEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-product-modal',
  templateUrl: './media-product-modal.component.html',
  styleUrls: ['./media-product-modal.component.scss']
})
export class MediaProductModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() entityId: number = 0;
  entityTypeId: number = 56; //product
  @Input() entity: Product = new Product;
  @ViewChild('topMark') topMark: ElementRef<HTMLDivElement>;
  @Output() entityCountUpdated = new EventEmitter<IEntity>();
  descriptionFullMode: boolean = true;


  description: string;

  modalNumber: number = 1;
  showModal: boolean = false;
  isLoading: boolean = true;

  price: number = 0;

  theme: string = 'dark';
  themeUpdated$!: Subscription;
  salesPrice: number = 0;
  showDiscount: boolean = false;
  avatar: string = '';


  personId: number = 0;
  liked: boolean = false;
  reposted: boolean = false;
  showAnalytics: boolean = false;
  currentPageTitle: string = '';

  pageUrl: string = 'https://connect.aiira.co/store/?id=';
  entityPageUrl: string = '';

  orderCount = 1;

  productType: { id: number, name: string } = this.entity.type as any;


  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _embed: EmbedService,
    private _aRoute: ActivatedRoute,
    private _storeApi: StoreApiService,

    private _oriDB: OriDbService,
    private _notify: NotifyService,
    private _titleService: Title,
    private _metatagService: MetatagService,
    private _errorHandlerService: ErrorHandlerService


  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });

    // set current page title
    this.currentPageTitle = this._titleService.getTitle();


    // console.log('id at card is ', this.entityId)

    if (this.entity.id !== 0) {
      this.initEntityData();
      this.productType = this.entity.type as any;
    } else {

      this.getData(this.entityId);
    }


  }

  processPice() {
    if (this.entity.discount != 0) {
      this.salesPrice = this.entity.price - (this.entity.discount / 100 * this.entity.price);
      this.showDiscount = true;
    } else {
      this.salesPrice = this.entity.price;
      this.showDiscount = false;
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.entityId;
    const entity: SimpleChange = changes.entity;

    // if (!id.firstChange && id.previousValue !== id.currentValue) {
    //   this.isLoading = true;
    //   this.getData(id.currentValue);
    // }


    // change to entity input object
    if (!entity.firstChange && entity.previousValue !== entity.currentValue) {
      if (this.entityId !== this.entity.id) {
        this.initEntityData();
      }
    }

  }

  getData(id: number) {
    this._storeApi
      .setParams({}, 'stand/open/' + id)
      .get(Product)
      .subscribe(
        {
          next: (res) => {
            // console.log('this is the reply', res);
            this.entity = res.data;
            this.initEntityData();
            this.productType = this.entity.type as any;
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }

  updateEntityCount(entity: IEntity) {
    this.entity.commentCount = entity.commentCount;
    this.entity.likeCount = entity.likeCount;
    this.entity.repostCount = entity.commentCount;
    this.entity['engagement'] = entity.engagement;
    this.entity['hit'] = entity.hit;

    this.entityCountUpdated.emit(this.entity);
  }


  initEntityData() {
    // scroll to top of the artwork
    this.topMark?.nativeElement.scrollIntoView();

    this.price = 0;
    this.orderCount = this.entity.minOrderCount;
    // this.avatar = this._embed.imgUrl(this.entity.person.image, 'xs');
    this.isLoading = false;

    this.processPice();
    // console.log(this.entity);

    // add to history if he is loggedIn
    if (this._authService.loggedIn) {

      const history = new History();
      history.entity.id = this.entity.id;
      history.person.id = this._authService.currentUser.gid.social.u;

      this._storeApi
        .post(history)
        .subscribe(
          {
            next: (res) => {
              if (res.data.entity) {
                this.updateEntityCount(res.data.entity);
              }
            },
            error: (err) => {
              this.isLoading = false;
              this._errorHandlerService.handleError(err)
            },
          }
        );


      this.personId = this._authService.currentUser.gid.social.u;

      // does this entity belongs to me?
      // this will allow me to delete and view analytics
      this.showAnalytics = this.personId === this.entity.person.id;

      // this.userReaction(this.personId, 24); // check like
      // this.userReaction(this.personId, 32);
    }

    this.setTags(this.entity);
  }


  setTags(data: IEntity) {
    this.entityPageUrl = this.pageUrl + data.id;

    // set title to page tab title
    this._titleService.setTitle('Store - ' + data.title ?? 'Connect - Store');
    // set open graph metatag
    this._metatagService.setOpenGraphTags(
      this.entityPageUrl, // Your Page URL
      data.title, //Your Page Title
      data.description.replace(/(<([^>]+)>)/gi, "").slice(0, 80) + '...', // Your Page Description
      this._embed.imgUrlString(data.art, 'md') // Your Page Image
    );
  }


  getEntityLink() {
    return 'localhost:4200/artwork/id';
  }


  onShowModalClicked(type: number) {
    this.modalNumber = type;
    // web share api
    if (type === 1 && navigator.share) {
      navigator.share({
        title: this.entity.title,
        text: this.entity.description,
        url: window.location.href
      }).then(() => {
        // console.log('Thanks for sharing!');
      })
        .catch(err => {
          // console.log(`Couldn't share because of`, err.message);
        });
    } else {
      // console.log('web share not supported');
      this.showModal = true;
    }
  }

  onCloseClicked($event) {
    this.showModal = false;
  }


  getImage(link) {
    return this._embed.imgUrl(link);
  }

  // comment & reaction system
  increaseCommentCount(commentCount: number) {
    this.entity.commentCount = commentCount;

    const countData = new Product;
    countData.commentCount = commentCount;
    countData['countType'] = 'comment';

    this._storeApi.setParams(
      {},
      'count/' + this.entity.id
    )
      .put(countData)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              // console.log(res);
            } else {
              // console.log('error ----->', res);
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }





  userReaction(personId: number, activityId: ActivityTypeEnum) {
    this._storeApi.setParams({
      id: this.entity.id, //entity
      gid: personId, //person
      category: activityId, //activity
      searchFields: ['single']
    }, 'check')
      .get(History)
      .subscribe(
        {
          next: (res) => {
            if (res.data.id && res.data.status === StatusEnum.PUBLISHED) {

              if (activityId === ActivityTypeEnum.LIKE) {
                this.liked = true;
              } else if (activityId === ActivityTypeEnum.REPOST) {
                this.reposted = true;

              }
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );
  }


  onLikeRepostClick($event, activityId: ActivityTypeEnum = ActivityTypeEnum.LIKE) {
    this.entity.liked = activityId === ActivityTypeEnum.LIKE ? $event : this.entity.liked;
    this.entity.reposted = activityId === ActivityTypeEnum.REPOST ? $event : this.entity.reposted;

    const history = new History();
    history.person.id = this.personId;
    history.entity.id = this.entity.id;
    history.activityType = activityId; // like
    history.gid = this._authService.currentUser.gid.social.u;

    this._storeApi
      .post(history)
      .subscribe(
        {
          next: (res) => {
            if (res.data.entity) {
              this.updateEntityCount(res.data.entity);
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }

      );

  }


  onShowModal(type: number) {
    this.modalNumber = type;
    // web share api
    if (type === 1 && navigator.share) {
      navigator.share({
        title: this.entity.title,
        text: this.entity.description.replace(/(<([^>]+)>)/gi, "").slice(0, 80) + '...',
        url: this.entityPageUrl
      }).then(() => {
        // console.log('Thanks for sharing!');
      })
        .catch(err => {
          // console.log(`Couldn't share because of`, err.message);
        });
    } else {
      // console.log('web share not supported');
      this.showModal = true;
    }
  }


  onAddToCart() {
    // $event.stopPropagation();
    this.entity.myOrderCount = this.orderCount;

    if (this._oriDB.addToCart(this.entity as Product)) {

      this.orderCount = this.entity.minOrderCount;

      this._notify.success('Product added to cart');
    } else {
      this._notify.success('Product already exists in cart')
    }


  }

  ngOnDestroy() {
    this._titleService.setTitle(this.currentPageTitle);
      this.themeUpdated$.unsubscribe();
    }
  

}
