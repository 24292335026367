import { Injectable, EventEmitter, SecurityContext } from '@angular/core';
// import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { IEntity, Entity, Media, PlayApiService, AuthService, EmbedService, AccessEnum, MediaTypeEnum } from '@galaxy/entity-api';
// import '@types/wicg-mediasession';
import { StorageMap } from '@ngx-pwa/local-storage';
// import "shaka-player";

declare let shaka: any;

import { DomSanitizer, enableDebugTools } from '@angular/platform-browser';

// declare let shaka: any;

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  mediaPlayer: HTMLVideoElement;
  seeking: boolean = false;
  mouseMoved: boolean = false;
  seekSlider: HTMLInputElement;
  volumeSlider: HTMLInputElement;
  seekto;
  curTimeText: HTMLElement;
  durTimeText: HTMLElement;

  isLive = new EventEmitter<boolean>();
  currentMediaData = new EventEmitter<IEntity>();
  playlist = new EventEmitter<any>();
  seekSliderDiv: HTMLElement;
  volumeSliderDiv: HTMLElement;

  isPlaying = new EventEmitter<boolean>();
  playingId: any;
  private playingIndex: number;
  initNowPlayng: IEntity;
  myPlaylist: IEntity[] = [];
  loopType = 0;
  shakaPlayer: any;
  manifestUri = '/cdn/src/assets/media/temp/video/example.mpd';
  audioManifestUri =
    '/cdn/src/assets/media/temp/audio/fe1ef8bc48925a5b6bf68739f1f6dfc4.mp3';
  mediaIsBuffering = new EventEmitter<boolean>();


  personId = 0;
  constructor
    (
      private _authService: AuthService,
      private _playApi: PlayApiService,
      private _storage: StorageMap,
      private _embed: EmbedService,
      private _sanitizer: DomSanitizer
    ) { }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    console.error('Error code', error.code, 'object', error);
  }

  /**
   * Initialize the Audio API
   */
  initAudioPlayer() {
    if (this._authService.loggedIn) {
      this.personId = this._authService.currentUser.gid.social.u;
      this.initHTMLPlayerElement();
    } else {
      this._authService.initLocalToken().subscribe(res => {
        if (res) {
          this.personId = this._authService.currentUser.gid.social.u;
        } else {
          this.personId = 0;
        }

        this.initHTMLPlayerElement();
      });
    }


  }


  private initHTMLPlayerElement() {
    // console.log('localstrage playing', localStorage.getItem('nowPlaying'));

    // this.mediaPlayer = new Audio();
    this.mediaPlayer = <HTMLVideoElement>(
      document.getElementById('airMediaPlayer')
    );
    this.mediaPlayer.removeAttribute('controls');

    // Shake Player
    // Install built-in polyfills to patch browser incompatibilities.
    shaka.polyfill.installAll();
    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      this.shakaPlayer = new shaka.Player(this.mediaPlayer);

      // Attach player to the window to make it easy to access in the JS console.
      (window as any).player = this.shakaPlayer;

      // Listen for error events.
      this.shakaPlayer.addEventListener('error', this.onErrorEvent);
    } else {
      this.shakaPlayer = this.mediaPlayer;
      // This browser does not have the minimum set of APIs we need.
      console.error('Browser not supported!');
    }

    // looping and source will have independent fxs
    this.mediaPlayer.loop = true;

    // this.audio.play();
    // Seekbar
    this.seekSlider = <HTMLInputElement>document.getElementById('seekslider');
    this.seekSliderDiv = <HTMLElement>document.getElementById('seeksliderdiv');

    // Volume
    this.volumeSlider = <HTMLInputElement>(
      document.getElementById('volumeslider')
    );

    this.volumeSliderDiv = <HTMLElement>(
      document.getElementById('volumesliderdiv')
    );

    this.curTimeText = document.getElementById('curtimetext');
    // this.durTimeText = document.getElementById('durtimetext');

    this.mediaPlayer.addEventListener('timeupdate', () =>
      this.seekTimeUpdate()
    );

    this.mediaPlayer.addEventListener('waiting', () =>
      this._bufferingMedia(true)
    );
    this.mediaPlayer.addEventListener('waiting', () =>
      this._bufferingMedia(false)
    );

    this.seekSlider.addEventListener('mousedown', e => {
      this.seeking = true;
      this.seek(e);
    });
    // this.seekSlider.addEventListener('mousemove', e => {
    //   this.seek(e);
    // });
    this.seekSlider.addEventListener('mouseup', e => {
      this.seeking = false;
      this.seek(e);
    });
    this.volumeSlider.addEventListener('mousemove', () => {
      this.setVolume();
    });

    this.initAudioData();
  }
  /**
   * Method to check localStorage for nowPlaying data, and playlist
   */
  initAudioData() {

    let nowPlaying: IEntity = new Media;
    this._storage.get('oriPlay:' + this.personId + '.playing').subscribe(res => {
      // Check nowPlaying at Storage
      if (res) {
        nowPlaying = <IEntity>res;
      } else {
        nowPlaying.access = AccessEnum.EVERYONE;
        nowPlaying.alias = "my-everything"
        nowPlaying.art = "play/912142a6304af2617a2c5ed55eca5a6c.jpg"
        nowPlaying.commentCount = 10;
        nowPlaying.created = 1572415736;
        nowPlaying.hit = 0;
        nowPlaying.id = 8;
        nowPlaying.likeCount = 10;
        nowPlaying.mediaType = MediaTypeEnum.AUDIO;
        nowPlaying.person.image = "avatar/e8dafc0abc6b98d276f9734a5d437581.jpg";
        nowPlaying.person.name = "Martin Miles";
        nowPlaying.person.username = "martin";
        nowPlaying.title = "My Everything";


        // init localStorage to the nowPlaying
        this._storage.set('oriPlay:' + this.personId + '.playing', nowPlaying).subscribe(() => { });
        // set playlist
        const myPlaylist = [];
        this.playingIndex = myPlaylist.push(nowPlaying) - 1;

        this._storage.set('oriPlay:' + this.personId + '.queue', myPlaylist);
      }

      this.initNowPaying(nowPlaying);

    });


  }

  private initNowPaying(nowPlaying: IEntity) {

    this.initNowPlayng = nowPlaying;
    this.currentMediaData.emit(nowPlaying);
    this.playingId = nowPlaying.id;

    // default playlist
    console.log('getting the playlist');
    this._storage.get('oriPlay:' + this.personId + '.queue').subscribe(res => {
      console.log('storage playlist', res);
      if (res) {

        this.myPlaylist = <IEntity[]>res;
        this.playlist.emit(this.myPlaylist);
      } else {
        this.myPlaylist = [];
      }
    });

    // check volume
    const oriPlayerVolume = localStorage.getItem('oriPlayer.volume');
    if (oriPlayerVolume === null) {
      localStorage.setItem(
        'oriPlayer.volume',
        JSON.stringify({
          data: { volume: 100, muted: false },
          created: Math.floor((+new Date) / 1000)
        }
        ));
    } else {
      // set media volume
      // this.mediaPlayer.volume = parseFloat(oriPlayerVolume);
      // update slider level
      const vol = JSON.parse(oriPlayerVolume);
      this.volumeSlider.value = vol.data.volume;
      this.setVolume();
    }
    this._loadAudio(nowPlaying.id, nowPlaying.mediaType, false);

    // this.durTimeText.innerText =
    //   nowPlaying.duration !== '' ? nowPlaying.duration : '00:00';

    if (this.isPlaying) {
      this.mediaPlayer.pause();
      this.isPlaying.emit(false);
    }

    // console.log("playlist group", JSON.parse(localStorage.getItem("playlist")));

    // also check for playlist
  }

  addToPlaylist(data: IEntity, playlist: string = null) {
    // console.log('playlist parameter is ', playlist);

    if (playlist === null) {
      this._storage.get('oriPlay:' + this.personId + '.queue').subscribe(res => {
        let mediaExistInPlaylist = false;
        let nowPlaylistData = [];

        if (res) {
          nowPlaylistData = <IEntity[]>res;
          // check it its already added to the playlist or just add and give option to resolve it later
          for (let i = 0; i < nowPlaylistData.length; i++) {
            if (nowPlaylistData[i].id === data.id) {
              mediaExistInPlaylist = true;
              // console.log('media is already part of the playlist');
              break;
            }
          }

        }
        if (!mediaExistInPlaylist) {
          // console.log('type is ', typeof nowPlaylistData);
          nowPlaylistData.push(data);
          this._storage.set('oriPlay:' + this.personId + '.queue', nowPlaylistData).subscribe(() => { });
          this.playlist.emit(nowPlaylistData);
        }
      });

    } else {
      // send data to server to add to playlist on DB
      this.addToDatabasePlaylist(data, playlist);
    }
  }

  removeFromPlaylist(data: IEntity) {
    this._storage.get('oriPlay:' + this.personId + '.queue').subscribe(res => {
      let index = -1;
      let nowPlaylistData = [];

      if (res) {
        nowPlaylistData = <IEntity[]>res;
        // check it its already added to the playlist or just add and give option to resolve it later
        index = nowPlaylistData.findIndex(x => +x.id === +data.id);
        // console.log('see - ', index, nowPlaylistData, data.id)
      }
      if (index != -1) {
        nowPlaylistData.splice(index, 1);
        this._storage.set('oriPlay:' + this.personId + '.queue', nowPlaylistData).subscribe(() => { });
        this.playlist.emit(nowPlaylistData);
      }
    });
  }
  updateLocalPlaylist(playlist: any) {
    this._storage.set('oriPlay:' + this.personId + '.queue', playlist).subscribe(() => { });
  }

  addToDatabasePlaylist(data: IEntity, playlist: string) { }

  loadAudioInfo(data: IEntity, isLive = false): boolean {
    this.isLive.emit(isLive);
    // this.currentMediaData.emit(data);
    this.playingId = data.id;

    // add to playlist
    if (this.playingId > 0) {
      this.addToPlaylist(data);
    }

    // Register Now Playing
    this._storage.set('oriPlay:' + this.personId + '.playing', data).subscribe(() => { });

    //  load data path from database
    this._setMediaSessionData(data);
    return data.id === -1 ?
      this._streamIPTV(data.path) :
      this._loadAudio(data.id, data.mediaType, true);
  }

  private _streamIPTV(url: string): boolean {


    // } else {
    this.shakaPlayer
      .load(url)
      .then(() => {
        // This runs if the asynchronous load is successful.
        this.mediaPlayer.play();
        this.isPlaying.emit(true);

        console.log('The video has now been loaded!');
      })
      .catch(this.onError);

    return true;
  }

  private _loadAudio(id: number | string, mediaTypeId: number, autoPlay = true): boolean {

    // load audio file with id
    this._playApi.setParams(null, 'stand/open/' + id.toString()).get(Media).subscribe(res => {
      this.currentMediaData.emit(res.data);
      // this.isPlaying.emit(false);
      // this.mediaPlayer.pause();
      // if (mediaTypeId === 46) {
      //   this.mediaPlayer.src = '/cdn/' + res.path;
      // } else {
      this.shakaPlayer
        .load('/cdn/' + res.data.path)
        .then(() => {
          // This runs if the asynchronous load is successful.
          this.mediaPlayer.play();
          this.isPlaying.emit(true);

          // if autoPlay is enableDebugTools, continue to play else pause
          if (!autoPlay) {
            this.mediaPlayer.pause();
            this.isPlaying.emit(false);

          }



          console.log('The video has now been loaded!');
        })
        .catch(this.onError);

      // this.mediaPlayer.src = 'assets/video/media.mp4';
      // }
    });

    // get last played length to continue from there
    this.seekSliderDiv.style.width = '0';
    // setTimeout(() => {
    this.seekTimeUpdate(); // To get the duration set

    // if (autoPlay) {
    //   this.mediaPlayer.play();
    // }

    // }, 500);



    return true;
  }

  loop(loopType: number) {
    // if (this.audio.loop) {
    //   this.audio.loop = false;
    //   return false;
    // } else {
    //   this.audio.loop = true;
    //   return true;
    // }

    this.loopType = loopType;
    if (this.loopType === 0) {
      return false;
    }
    return true;
  }

  playPause(): boolean {
    if (this.mediaPlayer.paused) {
      this.mediaPlayer.play();
      this.isPlaying.emit(true);
      return true;
    } else {
      this.mediaPlayer.pause();
      this.isPlaying.emit(false);
      return false;
    }
  }

  fullScreen() {
    this.mediaPlayer.requestFullscreen();
  }

  mute(): boolean {
    this.mediaPlayer.muted = !this.mediaPlayer.muted;
    localStorage.setItem(
      'oriPlayer.volume',
      JSON.stringify({
        data: { volume: this.volumeSlider.value, muted: this.mediaPlayer.muted },
        created: Math.floor((+new Date) / 1000)
      }
      ));
    return this.mediaPlayer.muted;
  }

  seek(event) {
    console.log('init value', this.seekSlider.value);
    console.log('seeker offset', this.seekSlider.offsetLeft, event.clientX);
    // this.seekSlider.value = (event.clientX - 50).toString();
    this.seekSlider.value = this.seekSlider.value.toString();
    // console.log('after value', this.seekSlider.value);

    if (!this.seeking) {
      this.seekto =
        this.mediaPlayer.duration *
        (parseInt(this.seekSlider.value, 10) / 1000);
      this.mediaPlayer.currentTime = this.seekto;
    }
  }

  setVolume() {
    this.mediaPlayer.volume = parseInt(this.volumeSlider.value, 10) / 100;
    this.volumeSliderDiv.style.width = this.volumeSlider.value + '%';
    localStorage.setItem('oriPlayer.volume', JSON.stringify({ data: { volume: this.volumeSlider.value, muted: this.mediaPlayer.muted }, created: Math.floor((+new Date) / 1000) }));
  }

  _bufferingMedia(state: boolean) {
    console.log('waiting media state -> ', state);
    this.mediaIsBuffering.emit(state);
  }
  // not live stream
  seekTimeUpdate() {
    const newTime =
      this.mediaPlayer.currentTime * (1000 / this.mediaPlayer.duration);

    if (!this.seeking) {
      this.seekSlider.value = newTime.toString();
      this.seekSliderDiv.style.width =
        (
          this.mediaPlayer.currentTime *
          (100 / this.mediaPlayer.duration)
        ).toString() + '%';
    }

    let curmins = Math.floor(this.mediaPlayer.currentTime / 60).toString();
    let cursecs = Math.floor(
      this.mediaPlayer.currentTime - parseInt(curmins, 10) * 60
    ).toString();
    let durmins = Math.floor(this.mediaPlayer.duration / 60).toString();
    let dursecs = Math.floor(
      this.mediaPlayer.duration - parseInt(durmins, 10) * 60
    ).toString();

    if (parseInt(cursecs, 10) < 10) {
      cursecs = '0' + cursecs;
    }
    if (parseInt(dursecs, 10) < 10) {
      dursecs = '0' + dursecs;
    }
    if (parseInt(curmins, 10) < 10) {
      curmins = '0' + curmins;
    }
    if (parseInt(durmins, 10) < 10) {
      durmins = '0' + durmins;
    }

    this.curTimeText.innerHTML = curmins + ':' + cursecs;
    // this.durTimeText.innerHTML = durmins + ':' + dursecs;

    // PlayNext When Music Ends
    // console.log("durationss", this.audio.currentTime, this.audio.duration);
    if (this.mediaPlayer.currentTime > this.mediaPlayer.duration - 2) {
      // console.log("THERE ARE THE SAME, PLAY NEXT PLEASE");
      this.onPlayPrevNext('forward', true);
    }
  }

  getSoundAndFadeAudio(audioId) {
    console.log('fade audio called');
    const sound = audioId;
    // Set the point in playback that fadeout begins. this is for 2 seconds
    const fadePoint = sound.duration - 2;
    const fadeAudio = setInterval(() => {
      console.log('interval', sound.currentTime, fadePoint, sound.volume);
      // Only fade is past the fadeout point or not at zero already
      if (sound.volume !== 0.0) {
        console.log('lowering', sound);
        sound.volume -= 0.1;
      }

      // When Volume at Zero, stop interval
      if (sound.volume <= 0.1) {
        sound.volume = 0.0;
        clearInterval(fadeAudio);
      }
    }, 200);
  }

  storeNowPlaying() {
    const data = {};
    this._storage.set('oriPlay:' + this.personId + '.playing', data);
  }

  onPlayPrevNext(playDirection: string, automaticLoop = false) {
    // console.log("play next is", playDirection, this.myPlaylist);
    let nextIndex = 0;

    for (let i = 0; i < this.myPlaylist.length; i++) {
      if (this.myPlaylist[i].id === this.playingId) {
        nextIndex = playDirection === 'forward' ? i + 1 : i - 1;
        // fixed for loop playt

        if (nextIndex < 0) {
          nextIndex = this.myPlaylist.length - 1;
        } else if (nextIndex === this.myPlaylist.length) {
          nextIndex = 0;
        }
        break;
        // console.log("direction is ", playDirection, i, nextIndex);
      }
    }

    // console.log("play next is", playNext, this.myPlaylist[nextIndex]);
    if (automaticLoop) {
      console.log('auto request prevNext');
      switch (this.loopType) {
        case 0:
          console.log('no loop');
          // No Loop. hence if its forward and the last played. stop.
          this.mediaPlayer.loop = false;
          this.mediaPlayer.pause();
          this.mediaPlayer.currentTime = 0;
          this.isPlaying.emit(false);
          break;

        case 1:
          console.log(' loop all');
          // Loop All.
          this.loadAudioInfo(this.myPlaylist[nextIndex]);
          break;

        case 2:
          console.log('loop single');
          // Loop Sigle, hence allow it to replay.
          this.mediaPlayer.loop = true;
          break;

        default:
          console.log('loop All Default');
          this.loadAudioInfo(this.myPlaylist[nextIndex]);
          break;
      }
    } else {
      console.log('manuel request prevNext');
      this.loadAudioInfo(this.myPlaylist[nextIndex]);
    }
  }

  // static get SUPPORT_MEDIA_SESSION() {
  //   return 'mediaSession' in navigator;
  // }
  _setMediaSessionData(data: IEntity) {
    const myVideoPlayer = new VideoPlayer();

    if (!myVideoPlayer.isSupportMediaSession()) {
      return;
    }

    const art = this._sanitizer.sanitize(SecurityContext.URL, this._embed.imgUrl(data.art, 'md'));
    const mediaSession = myVideoPlayer._setMediaSessionData(data, art);

    mediaSession.setActionHandler('play', () => {
      this.playPause();
    });
    mediaSession.setActionHandler('pause', () => {
      this.playPause();
    });
    mediaSession.setActionHandler('previoustrack', () => {
      this.onPlayPrevNext('backward');
    });
    mediaSession.setActionHandler('nexttrack', () => {
      this.onPlayPrevNext('forward');
    });
  }

  // CHROMECAST
  _startChromecastWatch() {
    if (!('remote' in HTMLMediaElement.prototype)) {
      // hide chromecast control
      return;
    }
  }
}

// @ts-ignore
class VideoPlayer {

  static get SUPPORT_MEDIA_SESSION() {
    return 'mediaSession' in navigator;
  }

  isSupportMediaSession(): boolean {
    // navigator.mediaSession.metadata
    return VideoPlayer.SUPPORT_MEDIA_SESSION;
  }
  _setMediaSessionData(data: IEntity, art: string): MediaSession {

    console.log('media session here:::', data, art);

    const mediaSession = navigator.mediaSession;

    // const artUrl = this._embed.imgUrl(data.art, 'md');



    mediaSession.metadata = new MediaMetadata(
      {
        title: data.title,
        artist: data.person.name,
        artwork: [
          { src: art }
        ]
      }
    );
    return mediaSession;
    // { src: '//96x96', sizes: '96x96', type: 'image/jpg' },
    // { src: '//128x128', sizes: '128x128', type: 'image/jpg' },
    // { src: '//192x192', sizes: '192x192', type: 'image/jpg' },
    // { src: '//256x256', sizes: '256x256', type: 'image/jpg' },
    // { src: '//384x384', sizes: '384x384', type: 'image/jpg' },
  }

}
