<section *ngIf="show" class="adx-section no-padding bg-{{ color }}">
  <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <button
          (click)="onTurnOff()"
          class="adx-btn adx-icon btn-default adx-md clear close-btn"
        >
          <i class="fal fa-times"></i>
        </button>
        <div class="adx-card adx-flat text-center">
          <div class="padding">
            <h1 class="">This is a General Announcement</h1>
            <button class="adx-btn adx-block btn-dark adx-sm">
              Action BTN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
