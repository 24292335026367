import { EmploymentTypeEnum, LeaveTypeEnum, PriorityEnum, StatusEnum, TicketCategoryEnum } from "../../enums";
import { Controller } from "../decorators";
import { IBusinessBranch } from "./business-branch.model";
import { BusinessBranchDepartment, IBusinessBranchDepartment } from "./business-department.model";
import { BusinessEntity, IBusinessEntity } from "./business-entity.model";
import { Business, IBusiness } from "./business.model";

export interface IBusinessEntityRemark {
  id: number;
  title: string; // job interview, exit interview, general meeting
  remarks: string;
  rate: number;

  hrRemark: string;
  status: StatusEnum;
  businessEntity: IBusinessEntity;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'StaffBusinessEntityRemark' })
export class BusinessEntityRemark implements IBusinessEntityRemark {
  constructor(
    public id = 0,
    public title = '',
    public remarks = '',
    public rate = 0,
    public status = StatusEnum.UNPUBLISHED,
    public hrRemark = '',
    public businessEntity = new BusinessEntity,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}
export interface IJobDescription {
  id: number;
  title: string;
  abbreviation: string;
  description: string;
  status: StatusEnum; // draft, pending, approved, rejected!
  salary: number;
  evaluationTemplate: string[];
  business: IBusiness;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'StaffJobDescription' })
export class JobDescription implements IJobDescription {
  constructor(
    public id = 0,
    public title = '',
    public abbreviation = '',
    public description = '',
    public status = StatusEnum.UNPUBLISHED,
    public salary = 0,
    public evaluationTemplate = [
      'Teamwork and support',
      'Obligation and competence',
      'Attitude and comportment',
      'Efficiency and quality of work',
      'Flexibility',
      'Communication and interpersonal skills',
      'Creativeness and innovation',
      'Attendance',
      'Customer service',
      'Dependability',
      'Leadership',
    ],
    public business = new Business,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}


export interface IBusinessEntityJobDescription {
  id: number;
  jobDescription: IJobDescription;
  businessEntity: IBusinessEntity;
  supervisor?: IBusinessEntity;
  businessBranchDepartment: IBusinessBranchDepartment;
  status: StatusEnum; // draft, pending, approved, rejected!
  employmentType: EmploymentTypeEnum; // draft, pending, approved, rejected!
  evaluationTemplate: string[],
  salary: number;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'BusinessEntityJobDescription' })
export class BusinessEntityJobDescription implements IBusinessEntityJobDescription {
  constructor(
    public id = 0,
    public jobDescription = new JobDescription,
    public businessEntity = new BusinessEntity,
    public supervisor = new BusinessEntity,
    public businessBranchDepartment = new BusinessBranchDepartment,
    public status = StatusEnum.UNPUBLISHED,
    public employmentType = EmploymentTypeEnum.FullTime,
    public evaluationTemplate = [],
    public salary = 0,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}


export interface ICompensation {
  id: number;
  title: string;
  description: string;
  status: StatusEnum; // draft, pending, approved, rejected!
  amount: number; // max reserved amount
  business: IBusiness;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'StaffCompensation' })
export class Compensation implements ICompensation {
  constructor(
    public id = 0,
    public title = '',
    public description = '',
    public status = StatusEnum.UNPUBLISHED,
    public amount = 0,
    public business = new Business,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}

export interface IBusinessEntityCompensation {
  id: number;
  compensation: ICompensation;
  businessEntity: IBusinessEntity;
  comment: string;
  status: StatusEnum; // draft, pending, approved, rejected!
  amount: number; // max reserved amount
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'BusinessEntityCompensation' })
export class BusinessEntityCompensation implements IBusinessEntityCompensation {
  constructor(
    public id = 0,
    public compensation = new Compensation,
    public businessEntity = new BusinessEntity,
    public comment = '',
    public status = StatusEnum.UNPUBLISHED,
    public amount = 0,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}

export interface IMemo {
  id: number;
  title: string;
  description: string;
  attachment: string[];
  businessBranchDepartment?: IBusinessBranchDepartment;
  business: IBusiness;
  status: StatusEnum; // draft, pending, approved, rejected!
  priority: PriorityEnum;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'StaffMemo' })
export class Memo implements IMemo {
  constructor(
    public id = 0,
    public title = '',
    public description = '',
    public attachment = [],
    public businessBranchDepartment = new BusinessBranchDepartment,
    public business = new Business,
    public status = StatusEnum.UNPUBLISHED,
    public priority = PriorityEnum.Low,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}


export interface IEvaluation {
  id: number;
  businessEntityJobDescription: IBusinessEntityJobDescription;
  evaluation: string[];
  remarks: string;
  status: StatusEnum; // draft, pending, approved, rejected!
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}

@Controller({ name: 'StaffEvaluation' })
export class Evaluation implements IEvaluation {
  constructor(
    public id = 0,
    public businessEntityJobDescription = new BusinessEntityJobDescription,
    public evaluation = [],
    public remarks = '',
    public status = StatusEnum.UNPUBLISHED,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}



export interface IIssueTicket {
  id: number;
  subject: string;

  status: StatusEnum; // draft, pending, approved, rejected!
  priority: PriorityEnum; // low, medium, normal, high!
  category: TicketCategoryEnum;
  businessEntity: IBusinessEntity;
  assignedTo?: IBusinessEntity;
  created: number;
  modified: number;
  createdBy: IBusinessEntity;

  modifiedBy?: IBusinessEntity;
  conversations: IIssueTicketConversation[];
}


@Controller({ name: 'IssueTicket' })
export class IssueTicket implements IIssueTicket {
  constructor(
    public id = 0,
    public subject = '',
    public status = StatusEnum.UNPUBLISHED, // draft, pending, approved, rejected!
    public priority = PriorityEnum.Low, // low, medium, normal, high!
    public category = TicketCategoryEnum.Operations, // low, medium, normal, high!
    public businessEntity = new BusinessEntity,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
    public conversations = [],
  ) { }
}


export interface IIssueTicketConversation {
  id: number;
  ticket: IIssueTicket;
  description: string;
  files: string[];
  created: number;
  createdBy: IBusinessEntity;
}


@Controller({ name: 'IssueTicketConversation' })
export class IssueTicketConversation implements IIssueTicketConversation {
  constructor(
    public id = 0,
    public ticket = new IssueTicket,
    public description = '',
    public files = [],
    public created = 0,
    public createdBy = new BusinessEntity,
  ) { }
}


export interface IStaffLeave {
  id: number;
  name: string;
  description: string;
  type: LeaveTypeEnum;
  startTime: number;
  endTime: number;
  businessEntity: IBusinessEntity;
  status: StatusEnum; // draft, pending, approved, rejected:number!
  created: number;
  modified: number;
  createdBy: IBusinessEntity;
  modifiedBy?: IBusinessEntity;
}


@Controller({ name: 'StaffLeave' })
export class StaffLeave implements IStaffLeave {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public type = LeaveTypeEnum.Casual,
    public startTime = 0,
    public endTime = 0,
    public businessEntity = new BusinessEntity,
    public status = StatusEnum.UNPUBLISHED,
    public created = 0,
    public modified = 0,
    public createdBy = new BusinessEntity,
  ) { }
}



export interface IStaffAttendance {

  id: number;
  ipAddress: string;
  geoLocation: { longitude: number, latitude: number };
  businessEntity: IBusinessEntity;
  branch?: IBusinessBranch;
  created: number;
}

@Controller({ name: 'StaffAttendance' })
export class StaffAttendance implements IStaffAttendance {
  constructor(
    public id = 0,
    public ipAddress = '',
    public businessEntity = new BusinessEntity,
    public geoLocation = { longitude: -1.6230107134574407, latitude: 6.694190456933313 },
    public created = 0,
  ) { }
}
