import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUser,
  User,
  AuthService,
  AppSettingService,
  IProfileMenu,
  IHeaderMenu,
  EmbedService,
  OriDbService,
  IGalaxyParams
} from '@galaxy/entity-api';
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-ori-header',
  templateUrl: './ori-header.component.html',
  styleUrls: ['./ori-header.component.scss']
})
export class OriHeaderComponent implements OnInit, OnDestroy {
  @Input() appName: string = '';
  @Input() theme: string = '';themeUpdated$!: Subscription;
  @Input() aRouterLink: string = '';
  @Input() isFull: boolean = false;
  @Input() showGenres: boolean = false;
  @Input() showSearch: boolean = false;
  transparent: boolean = false;
  searchQuery: string = '';
  showSearchBar: boolean = false;

  profileMenu?: IProfileMenu;
  header?: IHeaderMenu;

  userInfo: IUser = new User();
  isCurator: boolean = false;

  isLoggedIn: boolean = false;
  xsAvatar: SafeResourceUrl = '';
  smAvatar: SafeResourceUrl = '';
  searchOptions: { name: string; route: string; }[] = [];
  defaultSearchOption: {
    name: string,
    icon: string,
    route: string,
    placeholder: string
  };

  showSearchOptions: boolean = false;
  newOptions: any[] = [];

  navTopClass: string = '';
  lastScrollTop: number = 0;
  delta: number = 56;
  navbarHeight: number = 56;

  innerWidth: number = 0;
  scrollY: number = 0;

  showMobileSearch: boolean = false;

  showSideMenu: boolean = false;

  // notifications
  cartCount: number = 0;
  msgCount: number = 0;
  newFeed: boolean = true;
  isEventNear: boolean = false;
  viewerClaims?: IGalaxyParams;
  constructor(
    private _appSetting: AppSettingService,
    private _router: Router,
    private _authService: AuthService,
    private _embed: EmbedService,
    private _oriDB: OriDbService,
    private _titleService: Title
  ) { }

  ngOnInit() {

    // get windows width
    this.innerWidth = this._appSetting.defaultInnerWidth;
    this._appSetting.innerWidth.subscribe(res => this.innerWidth = res);
    // get windows scroll positon
    this.scrollY = this._appSetting.defaultWindowScrollY;
    this._appSetting.windowScrollY.subscribe(res => {
      if (this.scrollY < res && this.delta < res) {

        // hide navs
        // console.log('hide nave bars');
        this.navTopClass = 'hide-nav';

      } else {
        // console.log('show nave bars');
        this.navTopClass = '';
      }
      this.scrollY = res;
    });


    // AppSettings
    this.header = this._appSetting.header;
    this.profileMenu = this._appSetting.profileMenu;
    this.transparent = this._appSetting.headerTransparent;




    // Is this.isLoggedIn
    this.isLoggedIn = this._authService.loggedIn;

    if (!this.isLoggedIn) {
      this._authService.initLocalToken().subscribe(res => {
        this.isLoggedIn = res;
        this.setAuthUserInfo();


      });
    }
    // this.isLoggedIn = true;

    // correct this
    if (this.isLoggedIn) {
      this.setAuthUserInfo();


      // claims
      this.viewerClaims = this._authService.currentUser.gid;

      this.newOptions = this._appSetting.newOptions;

      this.newOptions.forEach(element => {
        if (!element.allowed) {
          if ((element.name as string).toLowerCase() === 'media') {

            element.allowed = this.viewerClaims.social.p === 3;
          }

          else if ((element.name as string).toLowerCase() === 'products') {
            element.allowed = this.viewerClaims.social.p === 2;
          }
        }
      });

      // route to login if loggedOut
      this._authService.loggedOut.subscribe(res => {
        if (res) this._router.navigateByUrl('/login');
      })

    }


    this.defaultSearchOption = this._appSetting.searchOptions[0];
    this._appSetting.defaultSearch.subscribe(res => { this.defaultSearchOption = res; });
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => { this.theme = res; });


    // store cart
    this.cartCount = this._oriDB.defaultCart.length;
    this._oriDB.cart.subscribe(res => this.cartCount = res.length)


  }


  setAuthUserInfo() {
    this.userInfo.name = this._authService.currentUser.name;
    this.userInfo.username = this._authService.currentUser.username;

    // if (this._authService.currentUser.curator.length > 0) {
    //   this.isCurator = true;
    //   this.userInfo.curator = this._authService.currentUser.curator;
    // }

    // embed needed images once!
    // this.xsAvatar = this.embedImage(this._authService.currentUser.image, 'xs');
    this.smAvatar = this.embedImage(this._authService.currentUser.image, 'sm');

    // subscribe for changes in userinfo
    this._authService.currentUserLive.subscribe(res => {
      this.userInfo.name = res.name;
      this.userInfo.username = res.username;

      // this.xsAvatar = this.embedImage(res.image, 'xs');
      this.smAvatar = this.embedImage(res.image, 'sm');
    });
  }

  log() {
    // console.log('clicked, but somtgnsvncv');
  }

  onToggleSearchOptions(value: boolean) {
    this.showSearchOptions = value;
  }

  onUploadClicked() {
    switch (this.appName) {
      case 'artist':
        this._router.navigateByUrl('/myartwork');
        break;
      case 'play':
        this._router.navigateByUrl('/creator');
        break;
      case 'lyfeshot':
        this._router.navigateByUrl('/my-pix');
        break;

      default:
        this._router.navigateByUrl('/new');
        break;
    }
  }
  onToggleGenre() {
    this.showGenres = !this.showGenres;
  }


  // search
  onSearch() {
    // check search not emtpy
    if (this.searchQuery.trim() !== '') {
      // get this.defaultSearchOption

      // generate routes

      this._router.navigateByUrl(this.defaultSearchOption.route + '?q=' + this.searchQuery);
      // this._router.
    }
  }

  onSearchChange(searchValue: string) {
    if (searchValue.trim().length > 4) {
      this.onSearch();
    }
  }

  onSearchOptionChanged(option) {
    // set to default search route
    this.defaultSearchOption = option;
    // now route to that place
    this._router.navigateByUrl(this.defaultSearchOption.route + '?q=' + this.searchQuery);
  }


  onLogOut() {
    this._authService.SignOut();
    this._oriDB.coldStartDone = false;
    // this._router.navigateByUrl('/login');
  }
  onSwitchAccount() {
    this._authService.switchAccount();
    this._oriDB.coldStartDone = false;
    // this._router.navigateByUrl('/login');
  }

  onShowMiniSocial(navTo: string) { }

  onShowSearchBar() {
    this.showSearchBar = !this.showSearchBar;
  }

  onWindowResize() { }
  onToggleTheme() {
    if (this.theme === 'dark') {
      this._appSetting.setTheme('light');
    } else {
      this._appSetting.setTheme('dark');
    }
  }

  private embedImage(path: string, size = 'default'): SafeResourceUrl {
    return this._embed.imgUrl(path, size);
  }


  // @HostListener(window.scrollY.toString(), ['$event'])
  // onScrollEvent(event) {
  //   // If current position > last position AND scrolled past navbar...
  //   if (+event > this.lastScrollTop && +event > this.navbarHeight) {
  //     // Scroll Down
  //     this.navTopClass = '';
  //   } else {
  //     // Scroll Up
  //     // If did not scroll past the document (possible on mac)...
  //     this.navTopClass = 'nav-up';

  //     // if (+event + window.innerHeight < document.out) {
  //     //   this.navTopClass = '';
  //     //   // $(‘header’).removeClass(‘nav - up’).addClass(‘nav - down’);
  //     // }
  //   }
  // }

  onProfileClicked() {
    if (this.innerWidth > 512) {
      this._router.navigateByUrl('/profile/' + this.userInfo.username);
    } else {
      this.showSideMenu = true;
    }
  }


  onShowViewerProfile() {
    this.showSideMenu = false;
    setTimeout(() => {

      this._router.navigateByUrl('/profile/' + this.userInfo.username);
    }, 500);
  }
  onCloseSideMenu(value: boolean) {
    this.showSideMenu = value;

  }


  lastUrl = '';

  onScrollToTop() {
    if (this.lastUrl === this._router.url) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.lastUrl = this._router.url;
  }

  

  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
