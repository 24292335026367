<section class="adx-section bg-dark">
  <!-- <div class="wrapper">
    <div class="adx-row">
      <div class="adx-colx-12">
        <div class="adx-card adx-flat adx-full text-center">
          <h2 class="text-normal section-header">Success starts here</h2>
        </div>
      </div>
    </div>
  </div> -->

  <div class="show-grid">
    <div
      class="store"
      [class.active]="activeCard === 1"
      [class.inactive]="activeCard !== 0 && activeCard !== 1"
      (mouseenter)="onHoverCard(1)"
      (mouseleave)="onHoverCard(0)"
    >
      <galaxy-adaptive-card>
        <div class="text-center">
          <i class="color-special fal fa-shopping-cart fa-3x"> </i>
          <br />
          <div class="adx-item adx-flat">
            <h3>
              Store
            </h3>
            <p>Shope oneline for plugins and3d models</p>
          </div>
          <br />
          <div class="is-loading"></div>
          <button [routerLink]="['/store']" class="adx-btn btn-{{ color }}">
            Start shoping
          </button>
        </div>
      </galaxy-adaptive-card>
    </div>
    <div
      class="learn"
      [class.active]="activeCard === 2"
      [class.inactive]="activeCard !== 0 && activeCard !== 2"
      (mouseenter)="onHoverCard(2)"
      (mouseleave)="onHoverCard(0)"
    >
      <galaxy-adaptive-card>
        <div class="text-center">
          <i class="color-special fal fa-trophy fa-3x"> </i>
          <br />
          <div class="adx-item adx-flat">
            <h3>
              Learn
            </h3>
            <p>Shope oneline for plugins and3d models</p>
          </div>
          <br />
          <div class="is-loading"></div>
          <button [routerLink]="['/acedemy']" class="adx-btn btn-{{ color }}">
            Get started
          </button>
        </div>
      </galaxy-adaptive-card>
    </div>
    <div
      class="forum"
      [class.active]="activeCard === 3"
      [class.inactive]="activeCard !== 0 && activeCard !== 3"
      (mouseenter)="onHoverCard(3)"
      (mouseleave)="onHoverCard(0)"
    >
      <galaxy-adaptive-card>
        <div class="text-center">
          <i class="color-special fal fa-comments-alt fa-3x"> </i>
          <br />
          <div class="adx-item adx-flat">
            <h3>
              Forum
            </h3>
            <p>Shope oneline for plugins and3d models</p>
          </div>
          <br />
          <div class="is-loading"></div>
          <button [routerLink]="['/forum']" class="adx-btn btn-{{ color }}">
            Join the discussion
          </button>
        </div>
      </galaxy-adaptive-card>
    </div>
  </div>
</section>
