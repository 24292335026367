import { Component, OnInit } from '@angular/core';
import { IGroup, AuthService, Group } from '@galaxy/entity-api';

@Component({
  selector: 'app-interest',
  templateUrl: './interest.component.html',
  styleUrls: ['./interest.component.scss']
})
export class InterestComponent implements OnInit {
  genres: IGroup = new Group;

  username: string = '';
  constructor(private _authService: AuthService) // private _genreService: GenreService
  { }

  ngOnInit() {
    this.username = this._authService.currentUser.username;
    // this._genreService.getData().subscribe(res => {
    //   this.genres = res.data;
    // });
  }
}
