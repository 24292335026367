import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IEntity, Entity, AppSettingService, AuthService, EmbedService, ArtistApiService, Project, History, MetatagService, ActivityTypeEnum, StatusEnum, ErrorHandlerService } from '@galaxy/entity-api';
import { ActivatedRoute } from '@angular/router';

import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-media-card-modal',
  templateUrl: './media-card-modal.component.html',
  styleUrls: ['./media-card-modal.component.scss']
})
export class MediaCardModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() entityType: 'photo' | 'video' | 'audio' | 'product' = 'photo';
  @Input() entityId: number = 0;
  entityTypeId: number = 55; //artwork

  @ViewChild('topMark') topMark: ElementRef<HTMLDivElement>;


  @Input() entity: IEntity = new Entity;
  @Output() entityCountUpdated = new EventEmitter<IEntity>();
  descriptionFullMode: boolean = true;

  description: string;

  modalNumber: number = 1;
  showModal: boolean = false;
  isLoading: boolean = true;

  theme: string = 'dark';
  themeUpdated$!: Subscription;
  avatar: SafeResourceUrl = '';

  //  general
  personId: number = 0;
  liked: boolean = false;
  reposted: boolean = false;
  showAnalytics: boolean = false;


  currentPageTitle?: string;

  pageUrl: string = 'https://connect.aiira.co/artwork/?id=';
  entityPageUrl: string = '';
  albumId: number = 0;
  reloadEntity: boolean = false;

  showFullDescription: boolean = false;
  art: SafeResourceUrl = '';

  hasSingleArtwork = false;

  constructor(
    private _appSetting: AppSettingService,
    private _authService: AuthService,
    private _embed: EmbedService,
    private _aRoute: ActivatedRoute,
    private _artistApi: ArtistApiService,
    private _titleService: Title,
    private _metatagService: MetatagService,
    private _errorHandlerService: ErrorHandlerService


  ) {
  }

  ngOnInit() {

    this.isLoading = true;
    this.theme = this._appSetting.defaultTheme;

    this.themeUpdated$ = this._appSetting.theme.subscribe(res => {
      this.theme = res;
    });
    // console.log('id at card is ', this.entityId)


    // set current page title
    this.currentPageTitle = this._titleService.getTitle();


    if (this.entity.id !== 0) {
      this.initEntityData();
    } else {

      this.getData(this.entityId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const id: SimpleChange = changes.entityId;
    const entity: SimpleChange = changes.entity;

    // if (!id.firstChange && id.previousValue !== id.currentValue) {
    //   this.isLoading = true;
    //   this.getData(id.currentValue);
    // }


    // change to entity input object
    if (!entity.firstChange && entity.previousValue !== entity.currentValue) {
      if (this.entityId !== this.entity.id) {
        this.initEntityData();
      }
    }

  }

  getData(id: number) {
    this._artistApi
      .setParams({}, 'stand/open/' + id)
      .get(Project)
      .subscribe(
        {
          next: (res) => {
            // console.log('this is the reply', res);
            this.entity = res.data;
            this.initEntityData();
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  initEntityData() {
    // scroll to top of the artwork
    this.topMark?.nativeElement.scrollIntoView();

    this.entityId = this.entity.id;


    this.hasSingleArtwork = this.entity.artwork.length === 1;
    // this.avatar = this._embed.imgUrl(this.entity.person.image, 'xs');
    this.isLoading = false;
    this.reloadEntity = false;
    // console.log(this.entity);

    // record to history that it was viewed
    // add to history if he is loggedIn
    if (this._authService.loggedIn) {

      const history = new History();
      history.entity.id = this.entity.id;
      history.person.id = this._authService.currentUser.gid.social.u;

      this._artistApi
        .post(history)
        .subscribe(
          {
            next: (res) => {
              // console.log('res ', res);
              if (res.data.entity) {
                // console.log('gets update of data here', res)
                this.updateEntityCount(res.data.entity);

              }
            },
            error: (err) => {
              this.isLoading = false;
              this._errorHandlerService.handleError(err)
            },
          }

        );


      // check stufs
      this.personId = this._authService.currentUser.gid.social.u;

      // does this entity belongs to me?
      // this will allow me to delete and view analytics
      this.showAnalytics = this.personId === this.entity.person.id;

      // this.userReaction(this.personId, 24); // check like
      // this.userReaction(this.personId, 32);

    } else {
      this.entity.hit++;
    }

    if (this.entity.isComic) {
      this.albumId = this.entity.album.id;
    } else {
      this.albumId = this.entity.id;
    }

    this.art = this._embed.imgUrl(this.entity.art, 'md')



    this.setTags(this.entity);
  }


  updateEntityCount(entity: IEntity) {

    this.entity.commentCount = entity.commentCount;
    this.entity.likeCount = entity.likeCount;
    this.entity.repostCount = entity.commentCount;
    this.entity.engagement = entity.engagement;
    this.entity.hit = entity.hit;

    // console.log('after update', entity.hit);

    this.entityCountUpdated.emit(this.entity);
  }


  onChapterSelected(id: number) {
    this.reloadEntity = true;
    this.getData(id);

    // scroll to top of the artwork
    this.topMark.nativeElement.scrollIntoView();
  }

  setTags(data: IEntity) {
    this.entityPageUrl = this.pageUrl + data.id;

    // set title to page tab title
    this._titleService.setTitle('Artwork - ' + data.title ?? 'Connect - Artwork');
    // set open graph metatag
    this._metatagService.setOpenGraphTags(
      this.entityPageUrl, // Your Page URL
      data.title, //Your Page Title
      data.description.replace(/(<([^>]+)>)/gi, "").slice(0, 80) + '...', // Your Page Description
      this._embed.imgUrlString(data.art, 'md')// Your Page Image
    );

    // console.log(this._embed.imgUrlString(data.art, 'md'));
  }


  onToggleReadMore() {
    this.showFullDescription = !this.showFullDescription;
  }
  getEntityLink() {
    return 'localhost:4200/artwork/id';
  }


  onShowModalClicked(type: number) {
    this.modalNumber = type;
    // web share api
    if (type === 1 && navigator.share) {
      navigator.share({
        title: this.entity.title,
        text: this.entity.description,
        url: this.entityPageUrl
      }).then(() => {
        // console.log('Thanks for sharing!');
      })
        .catch(err => {
          // console.log(`Couldn't share because of`, err.message);
        });
    } else {
      // console.log('web share not supported');
      this.showModal = true;
    }
  }

  onCloseClicked($event) {
    this.showModal = false;
  }

  onFormatDescription() {
    if (!this.descriptionFullMode) {
      this.description = this.entity.description;
      this.descriptionFullMode = true;
    } else {
      if (this.entity.description.length > 200) {
        this.description = this.entity.description.slice(0, 200) + '...';
        this.descriptionFullMode = false;
      } else {
        this.description = this.entity.description;
      }
    }

    // console.log('from et to d', this.entity.description, this.description);
  }


  getImage(link) {
    return this._embed.imgUrl(link);
  }



  // comment & reaction system
  increaseCommentCount(commentCount: number) {
    this.entity.commentCount = commentCount;

    const countData = new Entity;
    countData.commentCount = commentCount;
    countData['countType'] = 'comment';

    this._artistApi.setParams(
      {},
      'count/' + this.entity.id
    )
      .put(countData)
      .subscribe(
        {
          next: (res) => {
            if (res.success) {
              // console.log(res);
            } else {
              // console.log('error ----->', res);
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }



  userReaction(personId: number, activityId: ActivityTypeEnum) {
    this._artistApi.setParams({
      id: this.entity.id, //entity
      gid: personId, //person
      category: activityId, //activity
      searchFields: ['single']
    }, 'check')
      .get(History)
      .subscribe(
        {
          next: (res) => {
            if (res.data.id && res.data.status === StatusEnum.PUBLISHED) {

              if (activityId === ActivityTypeEnum.LIKE) {
                this.liked = true;
              } else if (activityId === ActivityTypeEnum.REPOST) {
                this.reposted = true;

              }
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );
  }


  onLikeRepostClick($event, activityId = ActivityTypeEnum.LIKE) {
    this.entity.liked = activityId === ActivityTypeEnum.LIKE ? $event : this.entity.liked;
    this.entity.reposted = activityId === ActivityTypeEnum.REPOST ? $event : this.entity.reposted;

    const history = new History();
    history.person.id = this.personId;
    history.entity.id = this.entity.id;
    history.activityType = activityId; // like
    history.gid = this._authService.currentUser.gid.social.u;


    if (activityId === ActivityTypeEnum.LIKE) {

      this.entity.likeCount = this.entity.liked ? this.entity.likeCount + 1 : this.entity.likeCount - 1;
    } else {
      this.entity.repostCount = this.entity.reposted ? this.entity.repostCount + 1 : this.entity.repostCount - 1;

    }

    this._artistApi
      .setParams({}, null)
      .post(history)
      .subscribe(
        {
          next: (res) => {
            // console.log('what happed to historyyyyyyyyyyyyy', res);
            if (res.data.entity) {
              this.updateEntityCount(res.data.entity);
            }
          },
          error: (err) => {
            this.isLoading = false;
            this._errorHandlerService.handleError(err)
          },
        }
      );

  }

  onShowModal(type: number) {
    this.modalNumber = type;
    // web share api
    if (type === 1 && navigator.share) {
      navigator.share({
        title: this.entity.title,
        text: this.entity.description.replace(/(<([^>]+)>)/gi, "").slice(0, 80) + '...',
        url: this.entityPageUrl
      }).then(() => {
        // console.log('Thanks for sharing!');
      })
        .catch(err => {
          // console.log(`Couldn't share because of`, err.message);
        });
    } else {
      // console.log('web share not supported');
      this.showModal = true;
    }
  }


  ngOnDestroy() {
    this._titleService.setTitle(this.currentPageTitle);
      this.themeUpdated$.unsubscribe();
    }
  

}
