
export enum ReactionEnum {
  LIKE = 1,
  DISLIKE = 2,
  FLAG = 3,
  SMILE = 4,
  LAUGH = 5,
  WOW = 6,
  ANGRY = 7,
  SAD = 8,
  HEART = 9,
  VOTE = 10,
}
