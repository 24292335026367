<div class="adx-comment">
  <div class="person-image">
    <div class="adx-img" [routerLink]="'/profile/'+data.person.username">
      <img [src]="personImage" alt="" />
    </div>
    <div class="space"></div>
  </div>
  <div class="comment-detail">
    <small class="name" style="opacity: 0.4;">
      &#64;{{ data.person.username }} &nbsp;-&nbsp;
      {{ data.created * 1000 | amTimeAgo }}
    </small>
    <div class="comment-message" [ngClass]="{
        'bg-light': theme === 'light',
        'bg-dark': theme === 'dark'
      }">
      <div class="msg">
        <!-- {{ data.description }} -->
        <quill-view-html [content]="data.description"></quill-view-html>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div style="padding: 0 48px;">
  <!-- Action -->
  <div class="comment-actions" style="opacity: 0.4;">
    <div class="btns">
      <button class="adx-btn adx-xs adx-round clear adx-flat">
        <i class="axl ax-heart"></i>
        <span *ngIf="data.likeCount > 0"> {{ data.likeCount }} </span>
      </button>
      <button *ngIf="!isChildComment" (click)="onToggleReply()" class="adx-btn adx-xs adx-round clear adx-flat">
        <i class="axl ax-message"></i>
        <span *ngIf="data.likeCount > 0"> {{ data.commentCount }} </span>
      </button>
      <button class="adx-btn adx-xs adx-icon adx-round clear adx-flat">
        <i class="axl ax-dots"></i>
      </button>
    </div>
  </div>

  <!-- is Child Component -->
  <div *ngIf="!isChildComment">
    <button class="adx-btn adx-sm clear adx-flat adx-full no-margin" (click)="getData()"
      *ngIf="!showReplyData && data.commentCount">
      view {{ data.commentCount }} replies
    </button>
    <div *ngIf="showReplyData">
      <galaxy-media-comment *ngFor="let item of repliesData" [data]="item" [isChildComment]="true"
        [isLoggedIn]="isLoggedIn" [personInfo]="personInfo" [entityId]="entityId" [entityTypeId]="entityTypeId">
      </galaxy-media-comment>
    </div>
  </div>
  <div class="adx-card adx-flat is-loading" *ngIf="isLoading">
    <p></p>
  </div>
  <galaxy-media-comment-post (addComment)="onReply($event)" *ngIf="isLoggedIn && showReply"></galaxy-media-comment-post>
  <!-- <small>
    {{ data.created * 1000 | amTimeAgo: true }}
  </small> -->
</div>

<!-- <div class="actions">
        <div class="btns">
          <button class="adx-btn adx-round clear adx-flat">
            <i class="axr ax-heart"></i>
            <span *ngIf="data.likeCount > 0"> {{ data.likeCount }} </span>
          </button>
          <button
            *ngIf="!isChildComment"
            (click)="onToggleReply()"
            class="adx-btn adx-round clear adx-flat"
          >
            <i class="axr ax-message"></i>
            <span *ngIf="data.likeCount > 0"> {{ data.commentCount }} </span>
          </button>
          <button class="adx-btn adx-icon adx-round clear adx-flat">
            <i class="fal fa-ellipsis-h"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!isChildComment">
        <button
          class="adx-btn adx-sm clear adx-flat adx-full no-margin"
          (click)="getData()"
          *ngIf="!showReplyData && data.commentCount"
        >
          View {{ data.commentCount }} replies
        </button>
        <div *ngIf="showReplyData">
          <galaxy-media-comment
            *ngFor="let item of repliesData"
            [data]="item"
            [isChildComment]="true"
            [isLoggedIn]="isLoggedIn"
            [personInfo]="personInfo"
            [entityId]="entityId"
            [entityTypeId]="entityTypeId"
          ></galaxy-media-comment>
        </div>
      </div>
      <div class="adx-card adx-flat is-loading" *ngIf="isLoading"><p></p></div>
      <galaxy-media-comment-post
        (addComment)="onReply($event)"
        *ngIf="isLoggedIn && showReply"
      ></galaxy-media-comment-post>
    </div>
  </div>
</div> -->
