<app-profile-view-container [businessEntity]="person" [data]="data" [transaction]="data.transaction"
  [isLoading]="isLoading" (closeProfile)="onCloseDetail()">

  <div class="adx-list adx-flat adx-ellipse padding-sm">
    <div style="display: flex;">
      <div class="adx-item bg-dim {{theme}}">
        <small class="color-pink">Department</small>
        <br>
        {{data.businessBranchDepartment.businessDepartment.name}}
      </div>

      <div class="adx-item bg-dim {{theme}}" style="margin-left: 8px;">
        <small class="color-pink">Branch</small>
        <br>
        {{data.businessBranchDepartment.businessBranch.name}}
      </div>
    </div>

    <div class="adx-item bg-dim {{theme}}">
      <small class="color-pink">Appointment Time</small>
      <br>
      {{data.type.toString()}} &nbsp;
      <i class="axr ax-calendar-event"></i>
      {{
      data.reservedDate * 1000
      | date: 'EEE, MMM dd, yyyy - hh:mm'
      }}
    </div>
  </div>
</app-profile-view-container>
