import { Controller } from '../decorators';
import { Group } from '../ori/group.model';
import { BusinessBranch } from './business-branch.model';
import { BusinessEntity } from './business-entity.model';
export class CertificateParty {
  public constructor(
    public id: number = 0,
    public surname: string = '',
    public othername: string = '',
    public ageStatus = new Group,

    public condition: string = '',
    public profession: string = '',

    public residence: string = '',

    public fatherName: string = '',

    public fatherProfession: string = '',

  ) { }
}

export class CertificateWitness {
  public constructor(
    public id: number = 0,
    public surname: string = '',
    public othername: string = ''

  ) { }
}



@Controller({ name: 'BusinessBranchStorage' })
export class BusinessBranchStorage {
  constructor(
    public id: number = 0,
    public title: string = 'Ordinance Certificate',
    public locationNumber: string = '',
    public coveringDates: string = '',
    public businessBranch: BusinessBranch = new BusinessBranch,
    public created: string = '',
    public modified: string = '',
    public status: Group = new Group,
  ) { }
}



@Controller({ name: 'BusinessCertificate' })
export class BusinessMarriageCertificate {
  constructor(

    public id: number = 0,


    public image: string = '',
    public licenseNumber: string = '',


    public certificateId: string = '',



    public businessBranchStorage: BusinessBranchStorage = new BusinessBranchStorage,



    public status = new Group,



    public created: string = '',


    public modified: string = '',


    public createdBy: BusinessEntity = new BusinessEntity,


    public modifiedBy: BusinessEntity = new BusinessEntity,


    public dateOfMarriage: number = 0,


    public placeOfMarriage: string = '',


    public parties: CertificateParty[] = [new CertificateParty, new CertificateParty],


    public witness: CertificateWitness[] = [new CertificateWitness, new CertificateWitness, new CertificateWitness, new CertificateWitness]

  ) {

  }

}





@Controller({ name: 'Appointment' })
export class Appointment {
  constructor(
    public id: number = 0,
    public name: string = '',
    public type: Group = new Group,
    public dueDate: number = 0,
    public created: number = 0,
    public modified: number = 0,
    public createdBy: BusinessEntity = new BusinessEntity,
    public modifiedBy: BusinessEntity = new BusinessEntity,
  ) {
    this.type.id = 416;
  }
}


@Controller({ name: 'Transaction' })
export class MarriageTransaction {
  constructor(
    public id: number = 0,
    public referenceNumber: number = 0,
    public amount: number = 0.00,
    public type: Group = new Group,
    public paymentMode: Group = new Group,
    public bankDate: number = 0,
    public momoNumber: string = '',
    public remarks: string = '',
    public created: number = 0,
    public modified: number = 0,
    public createdBy: BusinessEntity = new BusinessEntity,
    public modifiedBy: BusinessEntity = new BusinessEntity,
  ) {
    this.type.id = 413;
    this.paymentMode.id = 408;

  }


}

@Controller({ name: 'Report' })
export class Report {
  constructor(
    public count: number = 0,
    public x: string = '',
    public y: number = 0
  ) { }
}
