<input type="file" accept="image/jpg" #imageToolFile (change)="onFileSeleted($event)" style="display: none;"
  name="inputfile" [(ngModel)]="inputFile" />
<!-- Dropdown -->
<div class="adx-dropdown">
  <button class="adx-btn adx-{{ btnShape }} btn-default" [ngClass]="{
      'adx-icon': isButtonIcon,
      'adx-trigger': !uploadOnly,
      'btn-dark': theme === 'dark'
    }" (click)="uploadOnly ? imageToolFile.click() : ''">
    <i [class]="btnIcon"></i>
    <span *ngIf="!isButtonIcon">{{ btnName }}</span>
  </button>
  <div class="adx-content" [class.adx-show]="showOptions" style="width:70px; {{ styles }} ">
    <galaxy-adaptive-card [showPadding]="false">
      <div class="adx-list adx-sm text-left">
        <div *ngIf="allowWebCam" class="adx-item" (click)="onShowCamera(true)">
          <i class="axl ax-camera"></i> Snap Picture
        </div>
        <div *ngIf="saveMedia" class="adx-item" (click)="onShowPersonMedia()">
          <i class="axl ax-photos"></i> Choose Picture
        </div>
        <div class="adx-item adx-flat" (click)="imageToolFile.click()">
          <i class="axl ax-upload"></i> Upload Image
        </div>
      </div>
    </galaxy-adaptive-card>
  </div>
</div>

<!-- WebCamp -->
<galaxy-modal [showModal]="showCameraModal" [showPadding]=false (onCloseModalClicked)="onShowCamera($event)">
  <div class="adx-card adx-flat adx-round bg-black no-margin"
    style="font-size: 0; margin:0 auto; display: flex; align-items: center;" [style.width]="'300px'"
    [style.height]=" '300px'">
    <img *ngIf="showCamPhoto" [src]="photo" alt="" class="img-responsive" />
    <webcam *ngIf="showCameraModal && !showCamPhoto" [width]="300" [height]="300" [allowCameraSwitch]="true"
      [switchCamera]="switchCamera" [trigger]="webcamCapture" (initError)="handleInitError($event)"
      (imageCapture)="onImageCaptured($event)"></webcam>
    <!-- <app-webcam *ngIf="showCameraModal && !showCamPhoto" [width]="300" [height]="300" [allowCameraSwitch]="true"
      [switchCamera]="switchCamera" [trigger]="webcamCapture" (initError)="handleInitError($event)"
      (imageCapture)="onImageCaptured($event)"></app-webcam> -->
  </div>
  <div class="controls">
    <span *ngIf="!showCamPhoto">
      <button (click)="onSwitchCamera()" class="adx-btn adx-round adx-icon">
        <i class="axr ax-repeat"></i>
      </button>
      <button (click)="onCaptureWebcamImage()" class="adx-btn adx-round adx-icon btn-blue">
        <i class="axr ax-camera"></i>
      </button>
    </span>
    <span *ngIf="showCamPhoto">
      <button (click)="onShowCamera(false)" class="adx-btn adx-round btn-pink adx-icon">
        <i class="axr ax-trash"></i>
      </button>
      <button (click)="showCamPhoto = false" class="adx-btn adx-round adx-icon btn-blue">
        <i class="axr ax-camera"></i>
      </button>
    </span>
    <button [disabled]="!enableSaveImage" (click)="onSaveCamImage()" class="adx-btn adx-round btn-tgreen adx-icon">
      <i class="axr ax-floppy-disk"></i>
    </button>
  </div>
</galaxy-modal>

<!-- Image Cropper -->
<galaxy-modal [showModal]="showICropModal" [showPadding]=false (onCloseModalClicked)="onCloseICropModal($event)"
  [fixedWidth]="false" [width]="40 * icropAspectRatio">
  <div class="min-width:300px;">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="icropMaintainAspectRatio"
      [aspectRatio]="icropAspectRatio" [resizeToWidth]="icropResizeToWidth" format="format"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>

  <div class='controls'>
    <span style="flex: 1; text-align: left;">
      <button (click)="onCloseICropModal(false)" class="adx-btn adx-icon adx-round btn-default adx-iconx">
        <i class="axr ax-trash"></i>
      </button>
      <button (click)="imageToolFile.click()" class="adx-btn adx-icon adx-round adx-iconx btn-{{ theme }}">
        <i class="axr ax-upload"></i>
      </button>
    </span>
    <span>
      <button [disabled]="!enableSaveImage" (click)="onSaveCroppedImage()"
        class="adx-btn adx-icon adx-round btn-tgreen adx-iconx">
        <i class="axr ax-floppy-disk"></i>
      </button>
    </span>
  </div>

</galaxy-modal>

<!-- Person Media -->

<galaxy-modal [showModal]="showPersonMediaModal" [showPadding]=false (onCloseModalClicked)="onCloseICropModal($event)"
  [fixedWidth]="false" [width]="50">
  <galaxy-person-media *ngIf="saveMedia" [personId]="personId" [fileAlias]="fileAlias"
    [sortPersonMediaByAlias]="sortPersonMediaByAlias" (mediaSelected)="onPersonMediaSelected($event)">
  </galaxy-person-media>
</galaxy-modal>
