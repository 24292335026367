
export enum CommunityTypeEnum {
  OUTDOOR_ADVENTURE = 1, //80
  TECH = 2, //81
  FAMILY = 3, //82
  HEALTH_WELLNESS = 4, //83
  SPORTS_FITNESS = 5, //84
  LEARNING = 6, //85
  PHOTOGRAPHY = 7, //86
  FOOD_DRINK = 8, //87
  WRITING = 9,//88
  LANGUAGE_CULTURE = 10,//89
  MUSIC = 11,//90
  MOVEMENT = 12,//91
  LGBTQ = 13,//92
  FILM = 14,//93
  SCIFI_GAMES = 15,//94
  BELIEF = 16,//95
  ARTS = 17,//96
  BOOK_CLUBS = 18,//97
  DANCE = 19,//98
  PETS = 20,//99
  HOBBIES_CRAFT = 21,//100
  FASHION_BEAUTY = 22,//101
  SOCIAL = 23,//102
  CAREER_BUSINESS = 24,//103
}
