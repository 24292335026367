import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'galaxy-search-options',
  templateUrl: './search-options.component.html',
  styleUrls: ['./search-options.component.scss']
})
export class SearchOptionsComponent implements OnInit, OnDestroy {

  @Output() optionSelected = new EventEmitter<{
    name: string,
    icon: string,
    route: string
  }>();
  options: {
    name: string,
    icon: string,
    route: string
  }[] = [];

  selected: {
    name: string,
    icon: string,
    route: string
  } = { name: 'entity', icon: 'fa fa-circle', route: '' };

  showOptions: boolean = false;
  theme: string = '';themeUpdated$!: Subscription;
  constructor(
    private _appSetting: AppSettingService
  ) {
    this.theme = this._appSetting.defaultTheme;
    this.themeUpdated$ = this._appSetting.theme.subscribe(res => { this.theme = res; });
  }

  ngOnInit() {
    this.options = this._appSetting.searchOptions;
    this.selected = this.options[0];

  }

  onOptionSeleted(item: {
    name: string,
    icon: string,
    route: string
  }) {
    this.selected = item;
    this.optionSelected.emit(item);
    this.showOptions = false;
  }

  onShowOptions() {
    this.showOptions = true;
  }



  ngOnDestroy() {
    this.themeUpdated$.unsubscribe();
  }

}
