<app-adx-client-avatar [client]="person.entity"></app-adx-client-avatar>

<form #inputForm="ngForm" [class.is-loading]="isLoading">
  <!-- <div class="adx-input adx-label adx-round">
    <label for="deaprtment">Select Service</label>
    <select name="service" [disabled]="isLoading" [(ngModel)]="data.businessBranchService.id" required
      (change)="onServiceChange($event.target.value)">
      <option *ngFor="let item of branchServices" [value]="item.id">{{
        item.businessService.name
        }}</option>
    </select>
  </div> -->
  <div class="adx-input adx-label adx-round">
    <label for="description">Description</label>
    <textarea name="description" [(ngModel)]="data.description"></textarea>
  </div>
  <div class="adx-input adx-label adx-round">
    <label for="date">Expected Date Of Completion</label>
    <input type="datetime-local" name="data" [min]="dateTimeNow" [(ngModel)]="expectedCompleteDate" required
      [readOnly]="isLoading" />
  </div>
  <!-- <div class="adx-row">
    <div class="adx-cols-12">
      <div class="adx-input adx-label adx-round">
        <label for="date">Date</label>
        <input type="datetime-local" name="data" required [readOnly]="isLoading" />
      </div>
    </div>
    <div class="adx-cols-5">
      <div class="adx-input adx-label adx-round">
        <label for="deaprtment">Time</label>
        <input type="time" name="time" required [readOnly]="isLoading" />
      </div>
    </div>
  </div> -->
  <div class="clearfix"></div>
  <div *ngIf="appId === 59">
    <div class="adx-row">
      <div class="adx-cols-6">
        <div class="adx-input adx-label adx-round">
          <label for="fee">Total Fee</label>
          <input type="number" step=".01" required name="amount" [(ngModel)]="data.amount" />
        </div>
      </div>
      <div class="adx-cols-6">
        <div class="adx-input adx-label adx-round">
          <label for="fee">Down Payment</label>
          <input type="number" step=".01" required name="downpayment" [(ngModel)]="data.downPayment"
            [max]="data.amount" />
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div *ngIf="appId === 59" class="adx-input adx-label adx-round">
    <label for="purpose">Payment Plan</label>
    <select name="purpose" [disabled]="isLoading" required [(ngModel)]="data.paymentPlan">
      <option value="1">One-Time Payment</option>
      <option value="2">Subscription Payment Plan</option>
    </select>
  </div>


  <div class="adx-divider">{{appId === 59? 'Service' :'Test'}} Requests ({{data.customerServiceRequests.length}}) <span
      class="float-right" style="font-family: monospace;">GHS {{data.amount |
      number: '1.2-2'}}</span> </div>
  <div class="clearfix"></div>
  <div style="min-height: 100px; max-height: 300px; overflow-y: auto;">

    <div class="adx-list adx-sm adx-flat adx-ellipse" style="padding:0 8px;">
      <div class="adx-item {{theme}}" *ngFor="let item of data.customerServiceRequests">
        <div class="item-flex ">
          <div class="item-name">
            <strong>{{item.businessBranchService.businessService.name}}</strong>
            <br>
            <small>
              status: {{item.status.name}}
            </small>
          </div>
          <div class="amount padding-sm ">
            GHS {{item.businessBranchService.businessService.price | number: '1.2-2'}}
          </div>

          <button *ngIf="!isEdit" class="adx-btn adx-round btn-pink adx-icon outline adx-flat "
            style="margin:0; margin-top:-2px;" (click)="onRemoveService(item)">
            <i class="axr ax-trash"></i>
          </button>

        </div>

      </div>
    </div>

    <div class="text-center">
      <button class="adx-btn adx-icon adx-ellipse" (click)="showServiceModal = true">
        <i class="axl ax-plus"></i>
      </button>
    </div>
  </div>

  <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="onCloseDetail()">
    Cancel
  </button>

  <button [disabled]="isLoading || +data.customerServiceRequests.length === 0 || inputForm.invalid"
    (click)="onSubmit(1)" class="adx-btn adx-round adx-sm btn-tgreen float-right">
    Save Request
  </button>
</form>


<!-- Business Branch Services -->
<galaxy-modal [showModal]="showServiceModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">
  <app-business-branch-services-select *ngIf="showServiceModal" [businessBranchId]="branchId"
    (serviceSelected)="onAddService($event)">
  </app-business-branch-services-select>
  <button [disabled]="isLoading" class="adx-btn adx-round adx-sm btn-grey" (click)="showServiceModal =false">
    Close
  </button>
</galaxy-modal>




<!-- Invoice -->
<galaxy-modal [showModal]="showCheckOutModal" [showPadding]="false" (onCloseModalClicked)="onCloseModal($event)">

  <app-invoice-receipt *ngIf="showCheckOutModal" [appId]="appId" [clientName]="person.entity.name"
    [amount]="data.amount" [items]="requests"
    [theme]="theme" (onCancel)="onCloseModal($event)" (onSubmit)="createServiceRequest($event)">
  </app-invoice-receipt>


</galaxy-modal>
