import { Person, Community } from './user.model';
import { Group, IGroup } from './group.model';
import { Controller } from '../decorators';
import { MediaTypeEnum, StatusEnum } from '../../enums';

export interface IConversation {

  id: number;
  creator: Person;
  person2?: Person;
  community?: Community;
  status: StatusEnum;
  created: number;
  lastMessage?: string;
  lastActive: number;
  messages?: IMessage[];
}

@Controller({ name: 'Conversation' })
export class Conversation implements IConversation {
  constructor(
    public id = 0,
    public creator = new Person(),
    public status = StatusEnum.PUBLISHED,
    public created = 0,
    public lastActive = 0
  ) {
  }
}

export interface IMessage {

  id: number;
  conversation: IConversation;
  person: Person;
  description: string;
  type?: number;
  data?: { type: string, offer?: any, answer?: any, candidate?: any };
  status: StatusEnum;
  created: number;
  messageFiles: IMessageFile[];
}

@Controller({ name: 'Message' })
export class Message implements IMessage {
  constructor(
    public id = 0,
    public conversation = new Conversation(),
    public person = new Person(),
    public description = '',
    public status = StatusEnum.PUBLISHED,
    public type = 0, // text message, 1 - call
    public created = 0,
    public messageFiles = []
  ) {
  }
}



export interface IMessageFile {
  id: number;
  path: string;
  message?: IMessage;
  status?: StatusEnum;
  mediaType: MediaTypeEnum;
  created?: number;
}

@Controller({ name: 'MessageFile' })
export class MessageFile implements IMessageFile {
  constructor(
    public id = 0,
    public path = '',
    public mediaType = MediaTypeEnum.IMAGE,
  ) {
    // 45 image, 46-audio, 47-video, 48-3D
    // this.mediaType.id = 45;
    // 54-play media  55-Artwork  56 - Store Product  57 - Blog 53 - Event
  }
}
