import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IPersonMedia, EmbedService, PersonMedia } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-person-media-item',
  templateUrl: './person-media-item.component.html',
  styleUrls: ['./person-media-item.component.scss']
})
export class PersonMediaItemComponent implements OnInit {

  @Input() data: IPersonMedia = new PersonMedia;
  image: SafeResourceUrl = '';
  constructor(
    private _embed: EmbedService,

  ) { }

  ngOnInit() {
    this.image = this._embed.imgUrl(this.data.path, 'md');

  }

}
