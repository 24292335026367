import { StatusEnum } from '../../enums';
import { Controller } from '../decorators';
import { IGroup, Group } from '../ori/group.model';
import { IRole, Role } from './role.model';

export interface IDocument {

  id: number;
  name: string;
  description: string;
  app: IGroup;
  status: StatusEnum;
  created?: number;
  modified?: number;
}

@Controller({ name: 'Document' })
export class Document implements IDocument {
  constructor(
    public id = 0,
    public name = '',
    public description = '',
    public app = new Group(),
    public status = StatusEnum.PUBLISHED,
  ) {
    // this.status.id = 2;

  }

}


export interface IDocumentClaim {

  id: number;
  isActive: boolean;
  appRole: IRole;
  document: IDocument;
  permissions: IDocumentPermissions[];
}

@Controller({ name: 'DocumentClaim' })
export class DocumentClaim implements IDocumentClaim {
  constructor(
    public id = 0,
    public isActive = true,
    public appRole = new Role,
    public document = new Document,
    public permissions = []
  ) {

  }

}


export interface IDocumentPermissions {
  id: number;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  appDocumentClaim: IDocumentClaim;
}

@Controller({ name: 'DocumentPermission' })
export class DocumentPermissions implements IDocumentPermissions {
  constructor(
    public id = 0,
    public canCreate = true,
    public canRead = true,
    public canUpdate = true,
    public canDelete = false,
    public appDocumentClaim = new DocumentClaim()
  ) {

  }
}
