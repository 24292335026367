import { LocationStrategy } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AppSettingService } from '@galaxy/entity-api';

@Component({
  selector: 'galaxy-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showModal: boolean = false;
  @Input() fixedWidth: boolean = true;
  @Input() width: number = null;
  @Input() showPadding: boolean = true;

  @Input() overflow: boolean = false;
  @Input() autoTransparent: boolean = false;
  @Output() onCloseModalClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _location: LocationStrategy,
    private _appSetting: AppSettingService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal) {
      this._appSetting.modalStatus.emit(true);
    }
  }

  ngOnInit() {

    // check if back or forward button is pressed.
    this._location.onPopState(() => {
      // set isBackButtonClicked to true.
      this.onCloseModalClicked.emit(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    });


  }

  onCloseModal($event) {
    $event.stopPropagation();
    // this.showModal = false;
    this.onCloseModalClicked.emit(false);
    this._appSetting.modalStatus.emit(false);
  }


  ngOnDestroy() {
    // this._location.onPopState(() => { return false })
  }

}
